import React from 'react';
import { Card, Col, Image, Input, Row, Table as Tabel, Tabs, Typography } from 'antd';
import { BorderOutlined, DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import Table from '../../../components/Table/Table.component';
import TYPES from '../../../redux-v1/types';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { capsFirstLetter } from '../../../utils-v1/string.utils';

import parentConfig from '../DashboardGoogleAnalytics.component.config';
import config from './Ga4Page.component.config';
// import './Ga4Page.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_OVERVIEW_STATS);
const tablesConfig = [
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_USER_ACQUISITION,
    keyTitlePair: { key: 'channel_group', title: 'Channel Group' },
    tableKey: 'table_user_acquisition',
    tableTitle: 'User Acquisition (by channel group)',
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_TRAFFIC_ACQUISITION,
    keyTitlePair: { key: 'source_medium', title: 'Source / Medium' },
    tableKey: 'table_traffic_acquisition',
    tableTitle: 'Traffic Acquisition (by source/medium)',
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_PAGES_SCREEN,
    keyTitlePair: { key: 'page_screen', title: 'Page' },
    tableKey: 'table_pages_screen',
    tableTitle: 'Pages & Screen',
  },
];
const chartDonutColors = [
  '#018FFC',
  '#55B4FD',
  '#59BC7D',
  '#90D2A8',
  '#F5BD00',
  '#F8D354',
  '#FF683A',
  '#FF9A7B',
  '#3C41CC',
  '#7C80DD',
];

const Ga4Page = (props) => {
  const [searchText, setSearchText] = React.useState('');
  const [collapseDeviceCategory, setCollapseDeviceCategory] = React.useState(false);

  const getValueFormatter = getNumberFormatterByFormatCode;

  const statsListProps = {
    middle: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: false, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        // adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          Overview Activity
        </>,
        // values: {
        //   current: props?.data?.ga4?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        //   previous: props?.data?.ga4?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        //   percentage: props?.data?.ga4?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        // },
        // valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt),
      },
      loading: props?.data?.ga4?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(0)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.ga4?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.ga4?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.ga4?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  };
  const getChartBarTopCountriesProps = () => {
    const show = 10;
    const currentTopCountries = Object.entries(props?.data?.ga4?.chart_bar_top_countries?.data?.current || {}).sort((a,b) => b?.[1] - a?.[1]).slice(0, show).map(el => el?.[0]);
    const currentCountriesArr = []; const currentCountriesObj = {};
    const previousCountriesArr = []; const previousCountriesObj = {};
    const percentageCountriesArr = []; const percentageCountriesObj = {};
    currentTopCountries.forEach((item, idx) => {
      currentCountriesArr.push([item, props?.data?.ga4?.chart_bar_top_countries?.data?.current?.[item]]);
      previousCountriesArr.push([item, props?.data?.ga4?.chart_bar_top_countries?.data?.previous?.[item]]);
      percentageCountriesArr.push([item, props?.data?.ga4?.chart_bar_top_countries?.data?.percentage?.[item]]);
      currentCountriesObj[item] = props?.data?.ga4?.chart_bar_top_countries?.data?.current?.[item];
      previousCountriesObj[item] = props?.data?.ga4?.chart_bar_top_countries?.data?.previous?.[item];
      percentageCountriesObj[item] = props?.data?.ga4?.chart_bar_top_countries?.data?.percentage?.[item];
    });

    return {
      ...sharedPerformanceChartProps,
      advancedConfig: { ...sharedPerformanceChartProps, withAltDisplay: true },
      data: {
        bar: [
          { entries: currentCountriesArr, label: 'Current', color: '#51ab72' },
          ...(props?.daterange?.use_compare && [{ entries: previousCountriesArr, label: 'Compare', color: '#f5bd00' }] || []),
        ],
        table: {
          current: currentCountriesObj,
          previous: previousCountriesObj,
          percentage: percentageCountriesObj,
        },
      },
      legend: { enabled: false },
      loading: props?.data?.ga4?.chart_bar_top_countries?.loading || props?.uiLoading,
      title: 'Top Countries',
    }
  };
  const getChartBarTopCitiesProps = () => {
    const show = 10;
    const currentTopCities = Object.entries(props?.data?.ga4?.chart_bar_top_cities?.data?.current || {}).sort((a,b) => b?.[1] - a?.[1]).slice(0, show).map(el => el?.[0]);
    const currentCitiesArr = []; const currentCitiesObj = {};
    const previousCitiesArr = []; const previousCitiesObj = {};
    const percentageCitiesArr = []; const percentageCitiesObj = {};
    currentTopCities.forEach((item, idx) => {
      currentCitiesArr.push([item, props?.data?.ga4?.chart_bar_top_cities?.data?.current?.[item]]);
      previousCitiesArr.push([item, props?.data?.ga4?.chart_bar_top_cities?.data?.previous?.[item]]);
      percentageCitiesArr.push([item, props?.data?.ga4?.chart_bar_top_cities?.data?.percentage?.[item]]);
      currentCitiesObj[item] = props?.data?.ga4?.chart_bar_top_cities?.data?.current?.[item];
      previousCitiesObj[item] = props?.data?.ga4?.chart_bar_top_cities?.data?.previous?.[item];
      percentageCitiesObj[item] = props?.data?.ga4?.chart_bar_top_cities?.data?.percentage?.[item];
    });

    return {
      ...sharedPerformanceChartProps,
      advancedConfig: { ...sharedPerformanceChartProps, withAltDisplay: true },
      data: {
        bar: [
          { entries: currentCitiesArr, label: 'Current', color: '#51ab72' },
          ...(props?.daterange?.use_compare && [{ entries: previousCitiesArr, label: 'Compare', color: '#f5bd00' }] || []),
        ],
        table: {
          current: currentCitiesObj,
          previous: previousCitiesObj,
          percentage: percentageCitiesObj,
        },
      },
      legend: { enabled: false },
      loading: props?.data?.ga4?.chart_bar_top_cities?.loading || props?.uiLoading,
      title: 'Top Cities',
    }
  };

  const chartDonutGenderProps = {
    // ...sharedPerformanceChartProps,
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    daterange: { ...sharedPerformanceChartProps.daterange },
    advancedConfig: { showValuesInChart: false },
    centerContent: `<div style="text-align:center">
      ${[0, ...Object.values(props?.data?.ga4?.chart_donut_gender?.data?.current || {})].reduce((s,e) => s+e) ?
        `<p style="color:#7D8085">Total</p>
        <br />
        <p style="font-size:1.2em;font-weight:600">
          ${getValueFormatter('int')([0, ...Object.values(props?.data?.ga4?.chart_donut_gender?.data?.current || {})].reduce((s,e) => s+e))}
        </p>` : ``
      }
    </div>`,
    hover: { enabled: true },
    legend: { enabled: true },
    loading: props?.data?.ga4?.chart_donut_gender?.loading || props?.uiLoading,
    size: 300,
    tooltip: { enabled: true, shared: false },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    data: {
      donut: Object.keys(props?.data?.ga4?.chart_donut_gender?.data?.current || {}).map((e, i) => ({
        label: capsFirstLetter(e),
        value: props?.data?.ga4?.chart_donut_gender?.data?.current?.[e] || 0,
        color: chartDonutColors?.[i],
      })),
      table: props?.data?.ga4?.chart_donut_gender?.data,
      total: {
        current: [0, ...Object.values(props?.data?.ga4?.chart_donut_gender?.data?.current || {})].reduce((s,e) => s+e),
        previous: [0, ...Object.values(props?.data?.ga4?.chart_donut_gender?.data?.previous || {})].reduce((s,e) => s+e),
      },
    },
    listTitle: 'Total User for Gender',
    title: 'Gender',
  };
  const chartDonutAgeProps = {
    // ...sharedPerformanceChartProps,
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    daterange: { ...sharedPerformanceChartProps.daterange },
    advancedConfig: { showValuesInChart: false },
    centerContent: `<div style="text-align:center">
      ${[0, ...Object.values(props?.data?.ga4?.chart_donut_age?.data?.current || {})].reduce((s,e) => s+e) ?
        `<p style="color:#7D8085">Total</p>
        <br />
        <p style="font-size:1.2em;font-weight:600">
          ${getValueFormatter('int')([0, ...Object.values(props?.data?.ga4?.chart_donut_age?.data?.current || {})].reduce((s,e) => s+e))}
        </p>` : ``
      }
    </div>`,
    hover: { enabled: true },
    legend: { enabled: true },
    loading: props?.data?.ga4?.chart_donut_age?.loading || props?.uiLoading,
    size: 300,
    tooltip: { enabled: true, shared: false },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    data: {
      donut: Object.keys(props?.data?.ga4?.chart_donut_age?.data?.current || {}).map((e, i) => ({
        label: capsFirstLetter(e),
        value: props?.data?.ga4?.chart_donut_age?.data?.current?.[e] || 0,
        color: chartDonutColors?.[i],
      })),
      table: props?.data?.ga4?.chart_donut_age?.data,
      total: {
        current: [0, ...Object.values(props?.data?.ga4?.chart_donut_age?.data?.current || {})].reduce((s,e) => s+e),
        previous: [0, ...Object.values(props?.data?.ga4?.chart_donut_age?.data?.previous || {})].reduce((s,e) => s+e),
      },
    },
    listTitle: 'Total User for Age',
    title: 'Age',
  };
  const chartGenderAgeTabsProps = {
    defaultActiveKey: '0',
    items: [
      { key: `0`, label: 'Gender', children: <Chart.Donut { ...chartDonutGenderProps } /> },
      { key: `1`, label: 'Age', children: <Chart.Donut { ...chartDonutAgeProps } /> },
    ],
  };

  const chartDonutDeviceProps = {
    // ...sharedPerformanceChartProps,
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    daterange: { ...sharedPerformanceChartProps.daterange },
    advancedConfig: { showValuesInChart: false },
    centerContent: `<div style="text-align:center">
      ${[0, ...Object.values(props?.data?.ga4?.chart_donut_device?.data?.current || {})].reduce((s,e) => s+e) ?
        `<p style="color:#7D8085">Total</p>
        <br />
        <p style="font-size:1.2em;font-weight:600">
          ${getValueFormatter('int')([0, ...Object.values(props?.data?.ga4?.chart_donut_device?.data?.current || {})].reduce((s,e) => s+e))}
        </p>` : ``
      }
    </div>`,
    hover: { enabled: true },
    legend: { enabled: true },
    loading: props?.data?.ga4?.chart_donut_device?.loading || props?.uiLoading,
    size: 300,
    tooltip: { enabled: true, shared: false },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    data: {
      donut: Object.keys(props?.data?.ga4?.chart_donut_device?.data?.current || {}).map((e, i) => ({
        label: capsFirstLetter(e),
        value: props?.data?.ga4?.chart_donut_device?.data?.current?.[e] || 0,
        color: chartDonutColors?.[i],
      })),
      table: props?.data?.ga4?.chart_donut_device?.data,
      total: {
        current: [0, ...Object.values(props?.data?.ga4?.chart_donut_device?.data?.current || {})].reduce((s,e) => s+e),
        previous: [0, ...Object.values(props?.data?.ga4?.chart_donut_device?.data?.previous || {})].reduce((s,e) => s+e),
      },
    },
    listTitle: 'Total User for Device',
    title: 'Device',
  };

  const getTableColumnsAndData = (
    tableKey='',
    dispatchType='',
    firstCol={ key: 'campaign', title: 'Campaign' },
  ) => ({
    columns: [{ ...firstCol, width: '300px', fixed: 'left' }]
      .concat(requestConfig.find(e => e.type === dispatchType)
        .reqparams.metricsConfig.map(e => ({ key: e.key, title: e.lbl, width: '300px' }))),
    data: (Array.isArray(props?.data?.ga4?.[tableKey]?.data) && props?.data?.ga4?.[tableKey]?.data || [])
      .map(e => {
        const rowData = { ...e };
        const metricKeys = Object.keys(e);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const matchingMetricConfig = requestConfig.find(f => f.type === dispatchType)
            .reqparams.metricsConfig.find(f => f.key === metricKey);
          rowData[metricKey] = getValueFormatter(matchingMetricConfig?.fmt)(rowData[metricKey]);
        };
        return rowData;
      }),
    loading: props?.data?.ga4?.[tableKey]?.loading || props?.uiLoading,
  });
  const tabbedTablesDataBasedOnProps = {
    allowSort: true,
    tables: tablesConfig.map(e => ({ title: e.tableTitle, ...getTableColumnsAndData(e.tableKey, e.dispatchType, e.keyTitlePair) })),
    title: <b>Data Based On</b>,
    titlePlacement: 'out',
  };

  const generateUserPurchaseJourneyPanelConfig = () => {
    const stepNames = ['Session Start', 'View Product', 'Add to Cart', 'Begin Checkout', 'Purchase'];
    const funnelChartData = Object.values(props?.data?.ga4?.funnel_chart?.data || {});
    const funnelTableData = Array.isArray(props?.data?.ga4?.funnel_table?.data) ? props?.data?.ga4?.funnel_table?.data : [];
    const chartYValues = funnelChartData.map(el => el?.active_users || 0);
    const chartYMax = chartYValues.sort((a,b) => b-a)?.[0] || 0;

    const funnelChartLoading = props?.data?.ga4?.funnel_chart?.loading;
    const funnelTableLoading = props?.data?.ga4?.funnel_table?.loading;

    const topTableDataArranger = {
      header: () => funnelChartData.map(el => el?.funnel_step_completion_rate),
      footer: () => {
        const output = { rowLeft: null };
        funnelChartData.forEach((el, idx) => {
          output[`step${idx+1}`] = { ...el };
        });
        return output;
      },
    };
    const bottomTableDataArranger = {
      header: () => funnelTableData.filter(el => el?.device_category === 'RESERVED_TOTAL')
        .map((el, idx) => ({ ...el, idx: idx+1, key: `step${idx+1}` }))
        .sort((a,b) => a?.idx - b?.idx),
      dataset: () => {
        const output = [];
        const deviceCategories = [ ...new Set(funnelTableData.map(el => el?.device_category).filter(el => el !== 'RESERVED_TOTAL')) ];
        deviceCategories.forEach(category => {
          const rowEntry = { device_category: typeof category === 'string' ? category?.[0]?.toUpperCase()+category?.slice(1) : ''};
          funnelTableData.filter(el => el?.device_category === category).forEach((el, idx) => {
            rowEntry[`step${idx+1}`] = getValueFormatter('int')(el?.active_users || 0);
          })
          output.push(rowEntry)
        });
        return output?.filter(el => el?.device_category?.toLowerCase()?.includes(searchText?.toLowerCase()));
      },
    };

    const topTableFormatters = {
      title: (stepName='', idx=0) => (<div>
        <div className="thead-step-indicator" style={{ color: '#A6AAB1', fontSize: 14 }}>Step {idx+1}</div>
        <div className="thead-step-name" style={{ color: '#222222' }}>
          {stepName}<span className="thead-step-value" style={{ float: 'right', fontSize: 16 }}>
            {getValueFormatter('pct')(topTableDataArranger.header()?.[idx] || 0)}
          </span>
        </div>
      </div>),
      row1: (idx=0) => {
        const barChartProps = {
          actions: { copy: { enabled: true }, expand: { enabled: true } },
          advancedConfig: { showValuesInChart: true, defaultShowChart: true },
          data: [{ entries: [['Value', chartYValues?.[idx]]], label: 'Active Users', color: '#51AB72' }],
          daterange: {}, // props?.daterange,
          hover: { enabled: true },
          legend: { enabled: false },
          loading: funnelChartLoading,
          size: 200,
          tooltip: { enabled: true, shared: true },
          xAxis: { title: '', visible: false },
          yAxis: { ticked: true, title: '', visible: false, max: chartYMax },
          backgroundDefault: '#ffffff',
          backgroundDrawer: '#F8F9FA'
        };
        return(<Chart.Bar { ...barChartProps } />);
      },
      row2: (value=0, percentage=0) => (<div style={{ padding: '5px 10px' }}>
        <div style={{ color: '#A6AAB1', fontSize: 14 }}>Abandonment rate</div>
        <div style={{ color: '#222222', fontSize: 16 }}>
          <span style={{ fontWeight: 700 }}>{value}</span>
          &nbsp;&nbsp;
          <span style={{ color: '#A6AAB1' }}>{getValueFormatter('pct')(percentage)}</span>
        </div>
      </div>),
    };
    const bottomTableFormatters = {
      title: (stepName='', idx=0) => (<div>
        <div style={{ color: '#222222', fontSize: 14 }}>{idx+1}. {stepName}</div>
        <div style={{ color: '#A6AAB1', fontSize: 14 }}>User Count</div>
        <div style={{ backgroundColor: '#FFFFFF', border: '1px solid #E9E9E9', borderRadius: 6, fontSize: 14, padding: '5px 10px', marginTop: 8 }}>
          <div style={{ fontWeight: 700 }}>{getValueFormatter('int')(bottomTableDataArranger?.header()?.[idx]?.active_users || 0)}</div>
          <div style={{ color: '#999999' }}>{getValueFormatter('pct')(bottomTableDataArranger?.header()?.[idx]?.funnel_step_completion_rate || 0)} of total</div>
        </div>
      </div>),
      headerContent: (dataset=[]) => {
        const data = Array.isArray(dataset) ? data : [];
        const filteredData = data.filter(datum => datum?.device_category === 'RESERVED_TOTAL');
        const output = {};
        filteredData.forEach((datum, idx) => {
          output[`step${idx+1}`] = { ...datum, key: `step${idx+1}` };
        });
        return output;
      },
    };

    const topTableProps = {
      id: 'TopTable',
      bordered: true,
      columns: [{ key: 'rowleft', title: null, width: '70px', fixed: true, sorter: null },
        ...stepNames.map((stepName, idx) => ({
          key: `step${idx+1}`,
          title: topTableFormatters.title(stepName, idx),
          sorter: null,
          width: '200px',
        })),
      ],
      data: [
        {
          rowleft: <ul style={{ color: '#9CA0A6', display: 'table', tableLayout: 'fixed', width: '100%', MozBoxSizing: 'border-box', boxSizing: 'border-box', marginBottom: 0, minHeight: 200, transform: 'translateY(20px)' }}>
            <li style={{ textAlign: 'center', display: 'table-row' }}><span style={{ display: 'table-cell', verticalAlign: 'middle' }}>{getValueFormatter('big')(chartYMax)}</span></li>
            <li style={{ textAlign: 'center', display: 'table-row' }}><span style={{ display: 'table-cell', verticalAlign: 'middle' }}>{getValueFormatter('big')(chartYMax/2)}</span></li>
            <li style={{ textAlign: 'center', display: 'table-row' }}><span style={{ display: 'table-cell', verticalAlign: 'middle' }}>0</span></li>
          </ul>,
          step1: topTableFormatters.row1(0),
          step2: topTableFormatters.row1(1),
          step3: topTableFormatters.row1(2),
          step4: topTableFormatters.row1(3),
          step5: topTableFormatters.row1(4),
        },
        {
          rowleft: null,
          step1: topTableFormatters.row2(getValueFormatter('big')(topTableDataArranger.footer()?.step1?.funnel_step_abandonments || 0), topTableDataArranger.footer()?.step1?.funnel_step_abandonment_rate || 0),
          step2: topTableFormatters.row2(getValueFormatter('big')(topTableDataArranger.footer()?.step2?.funnel_step_abandonments || 0), topTableDataArranger.footer()?.step2?.funnel_step_abandonment_rate || 0),
          step3: topTableFormatters.row2(getValueFormatter('big')(topTableDataArranger.footer()?.step3?.funnel_step_abandonments || 0), topTableDataArranger.footer()?.step3?.funnel_step_abandonment_rate || 0),
          step4: topTableFormatters.row2(getValueFormatter('big')(topTableDataArranger.footer()?.step4?.funnel_step_abandonments || 0), topTableDataArranger.footer()?.step4?.funnel_step_abandonment_rate || 0),
          step5: topTableFormatters.row2(getValueFormatter('big')(topTableDataArranger.footer()?.step5?.funnel_step_abandonments || 0), topTableDataArranger.footer()?.step5?.funnel_step_abandonment_rate || 0),
        },
      ],
      noFilter: true,
    };
    const bottomTableProps = {
      id: 'BottomTable',
      bordered: false,
      columns: [
        {
          key: 'device_category',
          title: <div
            style={{ cursor: 'pointer', textAlign: 'center' }}
            onClick={() => setCollapseDeviceCategory(!collapseDeviceCategory)}
          >{collapseDeviceCategory ? <DownOutlined /> : <UpOutlined />}&nbsp;&nbsp;Device Category</div>,
          sorter: null,
          width: '200px',
          fixed: true,
        },
        ...stepNames.map((stepName, idx) => ({
          key: `step${idx+1}`,
          title: bottomTableFormatters.title(stepName, idx),
          sorter: null,
          width: '200px',
        }))
      ],
      data: [ ...bottomTableDataArranger.dataset() ],
      loading: funnelTableLoading,
      noFilter: true,
    };
    return { topTableProps, bottomTableProps };
  };
  const userPurchaseJourneyPanelConfig = generateUserPurchaseJourneyPanelConfig();

  return(
    <div id="Ga4">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>Google Analytics (GA4)</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Paragraph className="section-title" style={{ fontWeight: 700 }}>Overview Activity</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.middle } />}
        </Col>
      </Row>
      <Paragraph className="section-title" style={{ fontWeight: 700, marginTop: 15 }}>Users Demography</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12}>
          <Chart.Bar { ...getChartBarTopCountriesProps() } />
        </Col>
        <Col sm={24} md={24} lg={12}>
          <Chart.Bar { ...getChartBarTopCitiesProps() } />
        </Col>
        <Col sm={24} md={24} lg={12} style={{ display: 'flex' }}>
          <Card style={{ borderRadius: 10, marginBottom: 0 }} className="chart-gender-age-tabs">
            <Tabs { ...chartGenderAgeTabsProps } />
          </Card>
        </Col>
        <Col sm={24} md={24} lg={12} className="chart-donut-device">
          <Chart.Donut { ...chartDonutDeviceProps } />
        </Col>
      </Row>
      <Paragraph hidden className="section-title">Data Based On</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          <Table.Tabbed { ...tabbedTablesDataBasedOnProps } />
        </Col>
      </Row>
      <Paragraph className="section-title" style={{ fontWeight: 700 }}>Users Purchase Journey</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }} id="userPurchaseJourneyPanel">
            <div id="TopTable">
              <Table.Single { ...userPurchaseJourneyPanelConfig.topTableProps } />
            </div>
            <div style={{ padding: '15px 100px' }}>
              <Input
                prefix={<SearchOutlined style={{ color: '#A6AAB1', paddingLeft: 12 }} />}
                placeholder="Search..."
                onChange={(e) => setSearchText(e.target.value)}
                style={{ height: 36, width: 249}}
              />
            </div>
            <div id="BottomTable" className={`bottom-table-${collapseDeviceCategory ? 'collapsed' : 'expanded'}`}>
              <Table.Single { ...userPurchaseJourneyPanelConfig.bottomTableProps } />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

Ga4Page.displayName = config.displayName;
Ga4Page.propTypes = config.propTypes;
Ga4Page.defaultProps = config.defaultProps;

export default Ga4Page;