import React from 'react';
import {
  Button,
  Form,
  Image,
  Input,
  Radio,
  Select,
  Space,
  Typography,
} from 'antd';
import {
  AVATAR_LAZADA,
  AVATAR_SHOPEE,
  AVATAR_TOKOPEDIA,
} from '../../../assets-v1/figma-icons';

import config from './BindingForms.component.config';
// import './BindingForms.component.styles.less';

const { Option } = Select;
const { Title, Text } = Typography;

const BindingForms = ({
  account={},
  command='',
  onAdd,
  onEdit,
  platformKey='',
}) => {
  const renderForms = () => ({
    marketplace: (<Form
      name="add_marketplace_form"
      initialValues={{
        type: null,
      }}
      layout="vertical"
      onFinish={(values) => {
        if (command === 'add' && typeof onAdd === 'function') onAdd(values);
      }}
    >
      <Form.Item
        label="Choose Marketplace"
        name="type"
        rules={[
          { required: true },
        ]}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value="shopee">
              <Image src={AVATAR_SHOPEE} alt="avatar_shopee" preview={false} />&nbsp;&nbsp;Shopee
            </Radio><br /><br />
            {/* <Radio value="tokopedia">
              <Image src={AVATAR_TOKOPEDIA} alt="avatar_tokopedia" preview={false} />&nbsp;&nbsp;Tokopedia
            </Radio><br /><br />
            <Radio value="lazada">
              <Image src={AVATAR_LAZADA} alt="avatar_lazada" preview={false} />&nbsp;&nbsp;Lazada
            </Radio><br /><br /> */}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <div style={{ textAlign: 'center' }}>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
          >
            <Text style={{ color: 'white' }} strong>Generate Authentication Link</Text>
          </Button>
        </div>   
      </Form.Item>
    </Form>),

    website: (<Form
      initialValues={{
        type: account?.details?.type,
        account_name: account?.account_name,
        account_id: account?.account_id,
        web_api_key: account?.details?.web_api_key,
        web_api_password: account?.details?.web_api_password,
      }}
      name={command === 'edit' ? 'edit_website_form' : 'add_website_form'}
      layout="vertical"
      onFinish={(values) => {
        const updatedAccount = {
          ...(account || {}),
          account_id: values.account_id,
          account_name: values.account_name,
          details: {
            ...(account?.details || {}),
            type: values.type,
            web_api_key: values.web_api_key,
            web_api_password: values.web_api_password
          },
        };
        if (command === 'add' && typeof onAdd === 'function') onAdd(updatedAccount);
        if (command === 'edit' && typeof onEdit === 'function') onEdit(updatedAccount);
      }}
    >
      <Form.Item
        label="Website Category"
        name="type"
        rules={[
          { required: false },
        ]}
      >
        <Select placeholder="website category" disabled={command === 'edit'}>
          <Option value="shopify">Shopify</Option>
          <Option value="presta_shop">Presta Shop</Option>
          <Option value="woo_commerce">Woo Commerce</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Website Name"
        name="account_name"
        rules={[
          { required: true },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Website Link Address"
        name="account_id"
        rules={[
          { required: true },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Website API Key"
        name="web_api_key"
        rules={[
          { required: true },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Website API Password"
        name="web_api_password"
        rules={[
          { required: true },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <div style={{ textAlign: 'center' }}>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
          >
            <Text style={{ color: 'white' }} strong>{command === 'edit' ? 'Save Changes' : 'Bind Website'}</Text>
          </Button>
        </div>   
      </Form.Item>
    </Form>),
  });

  return(<>{renderForms()?.[platformKey] || null}</>);
};

BindingForms.displayName = config.displayName;
BindingForms.propTypes = config.propTypes;
BindingForms.defaultProps = config.defaultProps;

export default BindingForms;