/* eslint-disable no-restricted-globals */
import { format, parse } from 'date-fns';

export const doesStrIncludeSubstr = (str='', substr='', caseSensitive=false) => {
  const output = caseSensitive ? str.includes(substr) : str.toLowerCase().includes(substr.toLowerCase());
  return output;
};

export const capsEveryWordStart = (string) => {
  let stringArr = string.split(' ');
  stringArr = stringArr.map((el) => el.charAt(0).toUpperCase() + el.slice(1));
  const returnString = stringArr.join().replaceAll(',', ' ');
  return returnString;
};

export const capsFirstLetter = (string) => {
  const firstLetterCaps = string.charAt(0).toUpperCase() + string.slice(1);
  return firstLetterCaps;
};

export const capsFirstLetterOnly = (string) => {
  const firstLetterCaps = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  return firstLetterCaps;
};

export const formatAvatar = (words='') => {
  let output = '';
  if (typeof words === 'string') {
    output = words.split(' ').map(word => word[0]).join('').substring(0, 2);
  }
  return output;
};

export const getLastUrlTitleSegment = (title) => {
  let returnedTitle = title.split('/').slice(-1)[0].replace(' ','');
  if (returnedTitle.includes(':')) returnedTitle = returnedTitle.split(':').slice(-1)[0].replace(' ','');
  return returnedTitle;
};

export const removeHtmlTags = (str='') => {
  if (typeof str === 'string') return str.replaceAll(/(<([^>]+)>)/ig, '');
  return '';
};
