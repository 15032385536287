import { connect } from 'react-redux';
import Summary from './Summary.component';
import {
  updateIntroUserGuide,
  getStatusIntroGuide,
  getSummaryConfig,
  getAccountConfig,
  getUserData,
  updateCoachmarkLocal,
} from '../../redux-v1/actions/user/__common.actions';
import {
  setDashboardSummaryAccounts,
  setDashboardSummaryState,
  getDashboardSummaryData,
} from '../../redux-v1/actions/dashboard/summary.actions';


const mapStateToProps = (state) => ({
  ...state.dashboard.summary,
  accounts_by_platform: state.user.accounts_by_platform,
  me: state.user.me,
  onBoarding: state.user.me.onBoarding,
  coachmark_local: state.user.coachmark_local,
});

const mapDispatchToProps = {
  updateIntroUserGuide,
  getStatusIntroGuide,
  getSummaryConfig,
  getAccountConfig,
  getUserData,
  updateCoachmarkLocal,

  setDashboardSummaryAccounts,
  setDashboardSummaryState,
  getDashboardSummaryData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
