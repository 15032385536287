import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import Table from '../../../components/Table/Table.component';
import CarouselContentPreview from '../../../components/CarouselContentPreview/CarouselContentPreview.component';
import TYPES from '../../../redux-v1/types';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { ICON_IMAGE, ICON_PIESLICE, ICON_WALLET } from '../../../assets-v1/figma-icons';
import metrics from '../../../constants-v1/metrics-config/tiktok';

import parentConfig from '../DashboardTiktok.component.config';
import config from './CommunityInteractionPage.component.config';
import './CommunityInteractionPage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_TIKTOK_CI_OVERVIEW_STATS);
const contentPreviewMetrics = {
  followers: [
    metrics.community_interaction.spend,
    metrics.community_interaction.follows_community_interaction,
    metrics.community_interaction.cost_per_paid_followers,
  ],
  profile_visits: [
    metrics.community_interaction.spend,
    metrics.community_interaction.profile_visits_community_interaction,
    metrics.community_interaction.cost_per_paid_profile_visits,
  ],
};
const performanceChartConfig = [
  {
    pair: [
      [metrics.community_interaction.spend, 'Total Cost'],
    ],
    title: 'Total Cost',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.community_interaction.profile_visits_community_interaction, 'Paid Profile Visits'],
      [metrics.community_interaction.follows_community_interaction, 'Paid Followers'],
    ],
    title: 'Paid Profile Visits & Paid Followers',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    pair: [
      [metrics.community_interaction.likes_community_interaction, 'Paid Likes'],
      [metrics.community_interaction.comments_community_interaction, 'Paid Comments'],
      [metrics.community_interaction.shares_community_interaction, 'Paid Shares'],
    ],
    title: 'Paid Likes, Paid Comments & Paid Shares',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
];

const CommunityInteractionPage = (props) => {
  const getValueFormatter = (fmt='') => getNumberFormatterByFormatCode(fmt, props?.account?.details?.currency);

  const statsListProps = {
    left: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_WALLET} alt="icon_wallet" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[0].lbl}
        </>,
        values: {
          current: props?.data?.community_interaction?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          previous: props?.data?.community_interaction?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          percentage: props?.data?.community_interaction?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt),
      },
      loading: props?.data?.community_interaction?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(1, 12)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.community_interaction?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.community_interaction?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.community_interaction?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
    right: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[12]?.adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_PIESLICE} alt="icon_pieslice" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[12].lbl}
        </>,
        values: {
          current: props?.data?.community_interaction?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[12]?.key],
          previous: props?.data?.community_interaction?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[12]?.key],
          percentage: props?.data?.community_interaction?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[12]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[12]?.fmt),
      },
      loading: props?.data?.community_interaction?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(13)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.community_interaction?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.community_interaction?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.community_interaction?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  }
  const chartState = props?.data?.community_interaction?.charts;
  const formatChartTitle = (title='') => <><b>{title}</b></>
  const formatChartData = (keyLabelPairs=[]) => {
    const output = [];
    for (let i=0; i<keyLabelPairs.length; i+=1) {
      const keyLabelPair = keyLabelPairs[i];
      output.push({ entries: chartState?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1] });
      if (props?.daterange?.use_compare) {
        output.push({ entries: chartState?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare` });
      };
    };
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    data: formatChartData(performanceChartConfig[i].pair),
    title: formatChartTitle(performanceChartConfig[i].title),
    valueFormatter: performanceChartConfig[i].valueFormatter,
  }))
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => ({ key: `${i}`, label: performanceChartConfig[i].title, children: <Chart.Line { ...e } /> })),
  };

  const funnelProps = {
    chart: {
      ...sharedPerformanceChartProps,
      data: [{
        entries: Object.entries(props?.data?.community_interaction?.funnel_chart?.data?.current || {})
          .map(entry => ([metrics.community_interaction[entry?.[0]].lbl, entry?.[1]])),
        label: 'Funnel',
        color: '#51ab72'
      }],
      title: <>Funnel Chart</>,
      loading: props?.data?.community_interaction?.funnel_chart?.loading || props?.uiLoading,
    },
    table: {
      columns: [
        { key: 'index', title: 'Index', width: 50 },
        { key: 'current', title: 'Current', width: 50 },
        { key: 'previous', title: 'Previous', width: 50 },
      ],
      data: !props?.data?.community_interaction?.funnel_table?.data?.current ? [] :
        Object.keys(props?.data?.community_interaction?.funnel_table?.data?.current).map((e, idx) => ({
          index: requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_TIKTOK_CI_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].lbl,
          current: getValueFormatter(requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_TIKTOK_CI_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].fmt)(props?.data?.community_interaction?.funnel_table?.data?.current?.[e]),
          previous: getValueFormatter(requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_TIKTOK_CI_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].fmt)(props?.data?.community_interaction?.funnel_table?.data?.previous?.[e]),
        })),
      loading: props?.data?.community_interaction?.funnel_table?.loading || props?.uiLoading,
      noFilter: true,
      title: <>Funnel Table</>,
    },
  };

  const getTableColumnsAndData = (
    tableKey='',
    dispatchType='',
    firstCol={ key: 'campaign', title: 'Campaign' },
  ) => ({
    columns: [{ ...firstCol, width: '200px', fixed: 'left' }]
      .concat(requestConfig.find(e => e.type === dispatchType)
        .reqparams.metricsConfig.map(e => ({ key: e.key, title: e.lbl, width: '200px' }))),
    data: (Array.isArray(props?.data?.community_interaction?.[tableKey]?.data) && props?.data?.community_interaction?.[tableKey]?.data || [])
      .map(e => {
        const rowData = { ...e };
        const metricKeys = Object.keys(e);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const matchingMetricConfig = requestConfig.find(f => f.type === dispatchType)
            .reqparams.metricsConfig.find(f => f.key === metricKey);
          rowData[metricKey] = getValueFormatter(matchingMetricConfig?.fmt)(rowData[metricKey]);
        };
        return rowData;
      }),
    loading: props?.data?.community_interaction?.[tableKey]?.loading || props?.uiLoading,
  });

  const tabbedTablesDataBasedOnProps = {
    allowSort: true,
    tables: [
      {
        title: 'Campaign',
        ...getTableColumnsAndData(
          'table_campaign', TYPES.SET_DASHBOARD_TIKTOK_CI_TABLE_CAMPAIGN, { key: 'campaign', title: 'Campaign' }
        ),
      },
      {
        title: 'Audience',
        ...getTableColumnsAndData(
          'table_audience', TYPES.SET_DASHBOARD_TIKTOK_CI_TABLE_AUDIENCE, { key: 'campaign', title: 'Campaign' }
        ),
      },
    ],
    title: <b>Data Based On</b>,
    titlePlacement: 'out',
  };

  const contentPreviewProps = {
    followers: {
      data: (props?.data?.community_interaction?.preview_followers?.data || [])?.map((e, i) => ({
        src: { ...e?.previews },
        statsList: contentPreviewMetrics.followers?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.community_interaction?.preview_followers?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
        title: e?.ad_name,
      })),
      loading: props?.data?.community_interaction?.preview_followers?.loading || props?.uiLoading,
    },
    profile_visits: {
      data: (props?.data?.community_interaction?.preview_profile_visits?.data || [])?.map((e, i) => ({
        src: { ...e?.previews },
        statsList: contentPreviewMetrics.profile_visits?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.community_interaction?.preview_profile_visits?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
        title: e?.ad_name,
      })),
      loading: props?.data?.community_interaction?.preview_profile_visits?.loading || props?.uiLoading,
    },
  };
  const contentPreviewTabsProps = {
    defaultActiveKey: 'purchase',
    items: [
      { key: 'followers', label: 'Paid Followers', children: <CarouselContentPreview { ...contentPreviewProps.followers } /> },
      { key: 'profile_visits', label: 'Paid Profile Visits', children: <CarouselContentPreview { ...contentPreviewProps.profile_visits } /> },
    ],
  };

  return(
    <div id="TiktokCommunityInteraction">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>Community Interaction</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.left } />}
        </Col>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.right } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.table_purchase?.loading ? null : <Table.Tabbed { ...tabbedTablesDataBasedOnProps } />}
        </Col>
        <Col sm={24} md={24} lg={24} id="ContentPreviewTabs">
          <Paragraph style={{ fontSize: 16, fontWeight: 700 }}>Content Based On</Paragraph>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...contentPreviewTabsProps } />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

CommunityInteractionPage.displayName = config.displayName;
CommunityInteractionPage.propTypes = config.propTypes;
CommunityInteractionPage.defaultProps = config.defaultProps;

export default CommunityInteractionPage;
