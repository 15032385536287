import React from 'react';
import { get } from 'lodash';
import { Switch, Route, Redirect } from 'react-router-dom';

import { DASHBOARD_ROUTE } from '../../navigation/dashboard.route';
import SideMenu from '../../components/layouts-backup/DashboardLayout/SideMenu/SideMenu.component';
import ModalNotification from '../../components/ModalNotification/ModalNotification.component';

import { BDD_LOGO_MINI as sessionExpiredIcon } from '../../assets-v1/prev-assets';

import { getToken, destroyToken } from '../../services/token.service';

import config from './LandingDashboard.component.config';

class LandingDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isTimeoutModalVisible: false,
    }
  }

  _redirectToLoginPage = () => {
    destroyToken();
    window.location.replace('/login');
  }

  _getUserFailed = () => {
    if (getToken()) destroyToken();
    this.setState({isTimeoutModalVisible: true});
  }

  _onUndefinedTokenId = () => {
    this.setState({isTimeoutModalVisible: true});
  }

  componentDidMount() {
    // console.log('onmount')
    const { getUserData, getAccountConfig } = this.props;
    getUserData()
      .then(() => {
        this.props.getUserNotifications();
      }).catch(
        this._onUndefinedTokenId,
        this._getUserFailed
      )
  }

  componentDidUpdate = (prevProps) => {
    // console.log('onupdate')
    if (this.props.location !== prevProps.location) this.props.getUserNotifications();
  };

  renderModalNotification = () => (
    <ModalNotification
      visible={this.state.isTimeoutModalVisible}
      config={{
        icon: sessionExpiredIcon,
        title: "Session Expired",
        description: "Your session has expired. Please re-login.",
        btnFollowUpText: "Re-login",
      }}
      onFollowUp={this._redirectToLoginPage}
    />
  );

  showSideMenuCondition = () => {
    let output = true;
    if (window.location.pathname.includes('custom-report/workspace')) output = false;
    // console.log(output)
    return output;
  };
      
  render() {
    return (<>
      {this.showSideMenuCondition() ? (
        <SideMenu
          history={this.props.history}
          location={this.props.location}
        />
      ) : null}
      <Switch>
        {DASHBOARD_ROUTE.map((route) => (
          <Route key={route.id} {...route} />
        ))}
        <Redirect to="/login" />
        {get(getToken(), 'id') !== undefined || getToken() === null ? "" : this._onUndefinedTokenId()}
      </Switch>
      {this.renderModalNotification()}
    </>);
  }
}

LandingDashboard.displayName = config.displayName;
LandingDashboard.defaultProps = config.defaultProps;
LandingDashboard.propTypes = config.propTypes;

export default LandingDashboard;
