import axios from 'axios';
import { get } from 'lodash';
import { getToken } from './token.service';

class ApiService2 {
  static INSTANCE;

  static getInstance = () => {
    if (!ApiService2.INSTANCE)
      ApiService2.INSTANCE = new ApiService2();
    return ApiService2.INSTANCE;
  };

  constructor() {
    this.ApiService2 = axios.create({
      baseURL: process.env.REST_SERVICE_URL,
      timeout: 100000,
    });
  }

  setApi2ReqHeaders = (token) => {
    let token_ = null;
    let uid_ = null;
    if (token) {
      token_ = token.token;
      uid_ = token.uid;
    }
    this.ApiService2.defaults.headers.common = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token_}`,
      userID: uid_ || get(getToken(), 'id'),
    }
  }
}

export default ApiService2;
