import { connect } from 'react-redux';
import Dashboard from './LandingDashboard.component';
import {
  getUserData,
  getAccountConfig,
  updateIntroUserGuide,
  getUserNotifications,
} from '../../redux-v1/actions/user/__common.actions';

const mapStateToProps = (state) => ({
  ...state.user,
  // synchronizingAccounts: state.user.synchronizingAccounts,
});

const mapDispatchToProps = {
  getUserData,
  getAccountConfig,
  updateIntroUserGuide,
  getUserNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
