import PropTypes from 'prop-types';
import TYPES from '../../redux-v1/types';
import metrics from '../../constants-v1/metrics-config/instagram';
import { reqFormat as rpf } from '../../utils-v1/request.utils';

const displayName = 'Instagram';
const propTypes = {};
const defaultProps = { dateRangeValue: PropTypes.object };

const requestConfig = [
  {
    endpoint: 'instagram/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        // Account Overview
        metrics.instagram.profile_views,
        metrics.instagram.impressions,
        metrics.instagram.reach,
        metrics.instagram.total_followers,
        metrics.instagram.engagement_rate,
        // Overview Activity
        metrics.instagram.email_contacts,
        metrics.instagram.get_directions_clicks,
        metrics.instagram.text_message_clicks,
        metrics.instagram.phone_call_clicks,
        metrics.instagram.website_clicks,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_OVERVIEW_STATS,
  },
  {
    endpoint: 'instagram/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.instagram.total_followers,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_GROWTH,
  },
  {
    endpoint: 'instagram/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.instagram.profile_views,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_PROFILE_VIEWS,
  },
  {
    endpoint: 'instagram/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.instagram.reach,
        metrics.instagram.impressions,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_REACH_IMPRESSIONS,
  },
  {
    endpoint: 'instagram/data/periodic/engagements',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_LIKE_COMMENT_SAVED,
  },
  {
    endpoint: 'instagram/data/periodic/reach-post-media',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_REACH_BY_POST_MEDIA,
  },
  {
    endpoint: 'instagram/data/heatmap/followers-online',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_HEATMAP_FOLLOWERS_ONLINE,
  },
  {
    endpoint: 'instagram/data/periodic/city',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_BAR_TOP_CITIES,
  },
  {
    endpoint: 'instagram/data/periodic/age-gender',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_BAR_AGE_GENDER,
  },
  {
    endpoint: 'instagram/data/map/country',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_CHART_MAP_TOP_COUNTRIES,
  },
  {
    endpoint: 'instagram/data/preview/engagements',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_PREVIEW_ENGAGEMENTS,
  },
  {
    endpoint: 'instagram/data/preview/reach',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_PREVIEW_REACH,
  },
  {
    endpoint: 'instagram/data/preview/stories',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_INSTAGRAM_PREVIEW_STORIES,
  },
];

export default { displayName, propTypes, defaultProps, requestConfig };
