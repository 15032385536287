import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  account: { ...globalStateConstants.account.meta },
  daterange: { ...globalStateConstants.daterange },
  filter: { ...globalStateConstants.filter.meta },
  latest_sync: 0,

  data: {
    conversion: {
      overview_stats: { data: {}, error: null, loading: false },
      budget_chart: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
      funnel_chart: { data: {}, error: null, loading: false },
      funnel_table: { data: [], error: null, loading: false },
      table_purchase: { data: [], error: null, loading: false },
      table_objective: { data: [], error: null, loading: false },
      table_link_clicks: { data: [], error: null, loading: false },
      table_engagement: { data: [], error: null, loading: false },
      table_placement: { data: [], error: null, loading: false },
      table_delivery_platform: { data: [], error: null, loading: false },
      table_region: { data: [], error: null, loading: false },
      table_age: { data: [], error: null, loading: false },
      table_device_platform: { data: [], error: null, loading: false },
      preview_purchase_ads: { data: [], error: null, loading: false },
      preview_engagement_ads: { data: [], error: null, loading: false },
      preview_link_clicks_ads: { data: [], error: null, loading: false },
    },
    collaborative: {
      overview_stats: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
      funnel_chart: { data: {}, error: null, loading: false },
      funnel_table: { data: [], error: null, loading: false },
      table_campaign: { data: [], error: null, loading: false },
      table_objective: { data: [], error: null, loading: false },
      preview_purchase_ads: { data: [], error: null, loading: false },
      preview_content_views: { data: [], error: null, loading: false },
    },
    lead: {
      overview_stats: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
      table_conversion: { data: [], error: null, loading: false },
      table_traffic: { data: [], error: null, loading: false },
      table_leads: { data: [], error: null, loading: false },
      table_brand_awareness: { data: [], error: null, loading: false },
      preview_lead_ads: { data: [], error: null, loading: false },
    },
    messages: {
      overview_stats: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
      table_campaign: { data: [], error: null, loading: false },
      table_objective: { data: [], error: null, loading: false },
      preview_messages: { data: [], error: null, loading: false },
    },
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_DASHBOARD_META_VISITED: return {
      ...state, visited: true,
    };

    case TYPES.SET_DASHBOARD_META_ACCOUNT: return {
      ...state, account: { /* ...state.account, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_META_DATERANGE: return {
      ...state, daterange: { /* ...state.daterange, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_META_FILTER: return {
      ...state, filter: { /* ...state.filter, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_META_LATEST_SYNC: return {
      ...state, latest_sync: action.payload || 0,
    };

    case TYPES.SET_DASHBOARD_META_ACCOUNT_DETAILS_CURRENCY: return {
      ...state,
      account: {
        ...state.account,
        details: { ...state.account.details, currency: action.payload?.data?.currency || state.account.details.currency },
      },
    };

      // CONVERSION GROUP

    case TYPES.SET_DASHBOARD_META_CONVERSION_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          overview_stats: { /* ...state.data.conversion.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          charts: { /* ...state.data.conversion.charts, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_FUNNEL_CHART: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          funnel_chart: { /* ...state.data.conversion.funnel_chart, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_FUNNEL_TABLE: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          funnel_table: { /* ...state.data.conversion.funnel_table, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_PURCHASE: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_purchase: { /* ...state.data.conversion.table_purchase, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_OBJECTIVE: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_objective: { /* ...state.data.conversion.table_objective, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_LINK_CLICKS: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_link_clicks: { /* ...state.data.conversion.table_link_clicks, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_ENGAGEMENT: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_engagement: { /* ...state.data.conversion.table_engagement, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_PLACEMENT: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_placement: { /* ...state.data.conversion.table_placement, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_DELIVERY_PLATFORM: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_delivery_platform: { /* ...state.data.conversion.table_delivery_platform, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_REGION: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_region: { /* ...state.data.conversion.table_region, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_AGE: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_age: { /* ...state.data.conversion.table_age, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_DEVICE_PLATFORM: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          table_device_platform: { /* ...state.data.conversion.table_device_platform, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_PREVIEW_PURCHASE_ADS: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          preview_purchase_ads: { /* ...state.data.conversion.preview_purchase_ads, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_PREVIEW_ENGAGEMENT_ADS: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          preview_engagement_ads: { /* ...state.data.conversion.preview_engagement_ads, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_CONVERSION_PREVIEW_LINK_CLICK_ADS: return {
      ...state,
      data: {
        ...state.data,
        conversion: {
          ...state.data.conversion,
          preview_link_clicks_ads: { /* ...state.data.conversion.preview_link_clicks_ads, */ ...action.payload },
        },
      },
    };

      // COLLABORATIVE GROUP

    case TYPES.SET_DASHBOARD_META_COLLABORATIVE_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        collaborative: {
          ...state.data.collaborative,
          overview_stats: { /* ...state.data.collaborative.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_COLLABORATIVE_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        collaborative: {
          ...state.data.collaborative,
          charts: { /* ...state.data.collaborative.charts, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_COLLABORATIVE_FUNNEL_CHART: return {
      ...state,
      data: {
        ...state.data,
        collaborative: {
          ...state.data.collaborative,
          funnel_chart: { /* ...state.data.collaborative.funnel_chart, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_COLLABORATIVE_FUNNEL_TABLE: return {
      ...state,
      data: {
        ...state.data,
        collaborative: {
          ...state.data.collaborative,
          funnel_table: { /* ...state.data.collaborative.funnel_table, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_COLLABORATIVE_TABLE_CAMPAIGN: return {
      ...state,
      data: {
        ...state.data,
        collaborative: {
          ...state.data.collaborative,
          table_campaign: { /* ...state.data.collaborative.table_campaign, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_COLLABORATIVE_TABLE_OBJECTIVE: return {
      ...state,
      data: {
        ...state.data,
        collaborative: {
          ...state.data.collaborative,
          table_objective: { /* ...state.data.collaborative.table_objective, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_COLLABORATIVE_PREVIEW_PURCHASE_ADS: return {
      ...state,
      data: {
        ...state.data,
        collaborative: {
          ...state.data.collaborative,
          preview_purchase_ads: { /* ...state.data.collaborative.preview_purchase_ads, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_COLLABORATIVE_PREVIEW_CONTENT_VIEWS: return {
      ...state,
      data: {
        ...state.data,
        collaborative: {
          ...state.data.collaborative,
          preview_content_views: { /* ...state.data.collaborative.preview_content_views, */ ...action.payload },
        },
      },
    };

      // LEAD GROUP

    case TYPES.SET_DASHBOARD_META_LEAD_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        lead: {
          ...state.data.lead,
          overview_stats: { /* ...state.data.lead.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_LEAD_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        lead: {
          ...state.data.lead,
          charts: { /* ...state.data.lead.charts, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_LEAD_TABLE_CONVERSION: return {
      ...state,
      data: {
        ...state.data,
        lead: {
          ...state.data.lead,
          table_conversion: { /* ...state.data.lead.table_conversion, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_LEAD_TABLE_TRAFFIC: return {
      ...state,
      data: {
        ...state.data,
        lead: {
          ...state.data.lead,
          table_traffic: { /* ...state.data.lead.table_traffic, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_LEAD_TABLE_LEADS: return {
      ...state,
      data: {
        ...state.data,
        lead: {
          ...state.data.lead,
          table_leads: { /* ...state.data.lead.table_leads, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_LEAD_TABLE_BRAND_AWARENESS: return {
      ...state,
      data: {
        ...state.data,
        lead: {
          ...state.data.lead,
          table_brand_awareness: { /* ...state.data.lead.table_brand_awareness, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_LEAD_PREVIEW_LEAD_ADS: return {
      ...state,
      data: {
        ...state.data,
        lead: {
          ...state.data.lead,
          preview_lead_ads: { /* ...state.data.lead.preview_lead_ads, */ ...action.payload },
        },
      },
    };

      // MESSAGES GROUP

    case TYPES.SET_DASHBOARD_META_MESSAGES_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        messages: {
          ...state.data.messages,
          overview_stats: { /* ...state.data.messages.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_MESSAGES_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        messages: {
          ...state.data.messages,
          charts: { /* ...state.data.messages.charts, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_MESSAGES_TABLE_CAMPAIGN: return {
      ...state,
      data: {
        ...state.data,
        messages: {
          ...state.data.messages,
          table_campaign: { /* ...state.data.messages.table_campaign, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_MESSAGES_TABLE_OBJECTIVE: return {
      ...state,
      data: {
        ...state.data,
        messages: {
          ...state.data.messages,
          table_objective: { /* ...state.data.messages.table_objective, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_META_MESSAGES_PREVIEW_MESSAGES: return {
      ...state,
      data: {
        ...state.data,
        messages: {
          ...state.data.messages,
          preview_messages: { /* ...state.data.messages.preview_messages, */ ...action.payload },
        },
      },
    };

      // RESET DATA REDUCER

    case TYPES.RESET_DASHBOARD_META_DATA: return {
      ...state, data: initialState.data,
    };

    case TYPES.RESET_DASHBOARD_META_REDUCER: return {
      ...initialState,
    };
  };
};
