import PropTypes from 'prop-types';
import TYPES from '../../redux-v1/types';
import metrics from '../../constants-v1/metrics-config/tiktok';
import { reqFormat as rpf } from '../../utils-v1/request.utils';

const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `DashboardTiktok`;
const propTypes = {
  title: pt.node,
  text: pt.node,
};
const defaultProps = {};

const requestConfig = [
  {
    endpoint: 'tiktok/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        { key: 'currency' },
      ],
    },
    type: TYPES.SET_DASHBOARD_TIKTOK_ACCOUNT_DETAILS_CURRENCY,
  },

  // CONVERSION_TIKTOK (CT)
  {
    endpoint: 'tiktok/data/overview',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        // left
        metrics.conversion_tiktok.spend,
        metrics.conversion_tiktok.impressions,
        metrics.conversion_tiktok.reach,
        metrics.conversion_tiktok.frequency,
        metrics.conversion_tiktok.clicks,
        metrics.conversion_tiktok.cpc,
        metrics.conversion_tiktok.cpm,
        metrics.conversion_tiktok.cost_per_1000_reached,
        metrics.conversion_tiktok.ctr,
        metrics.conversion_tiktok.video_play_actions,
        metrics.conversion_tiktok.average_video_play,
        metrics.conversion_tiktok.average_video_play_per_user,
        metrics.conversion_tiktok.engagements,
        metrics.conversion_tiktok.follows_community_interaction,
        metrics.conversion_tiktok.profile_visits_community_interaction,
        // right
        metrics.conversion_tiktok.roas_complete_payment_onsite,
        metrics.conversion_tiktok.product_detail_page_view_onsite,
        metrics.conversion_tiktok.add_to_cart_onsite,
        metrics.conversion_tiktok.add_to_cart_value_onsite,
        metrics.conversion_tiktok.cost_per_add_to_cart_onsite,
        metrics.conversion_tiktok.initiate_checkout_onsite,
        metrics.conversion_tiktok.cost_per_initiate_checkout_onsite,
        metrics.conversion_tiktok.initiate_checkout_value_onsite,
        metrics.conversion_tiktok.complete_payment_onsite,
        metrics.conversion_tiktok.complete_payment_value_onsite,
        metrics.conversion_tiktok.cost_per_complete_payment_onsite,
        metrics.conversion_tiktok.product_clicks_onsite,
        metrics.conversion_tiktok.live_effective_views_onsite,
        metrics.conversion_tiktok.live_product_clicks_onsite,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_OVERVIEW_STATS,
  },
  {
    endpoint: 'tiktok/data/periodic',
    method: 'post',
    reqparams: {
      format: 'periodic',
      metricsConfig: [
        metrics.conversion_tiktok.spend,
        metrics.conversion_tiktok.cpm,
        metrics.conversion_tiktok.cpc,
        metrics.conversion_tiktok.product_clicks_onsite,
        metrics.conversion_tiktok.product_detail_page_view_onsite,
        metrics.conversion_tiktok.roas_complete_payment_onsite,
        metrics.conversion_tiktok.cvr,
        metrics.conversion_tiktok.add_to_cart_onsite,
        metrics.conversion_tiktok.complete_payment_onsite,
        metrics.conversion_tiktok.initiate_checkout_onsite,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_CHARTS,
  },
  {
    endpoint: 'tiktok/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_tiktok.product_detail_page_view_onsite,
        metrics.conversion_tiktok.add_to_cart_onsite,
        metrics.conversion_tiktok.initiate_checkout_onsite,
        metrics.conversion_tiktok.complete_payment_onsite,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_FUNNEL_CHART,
  },
  {
    endpoint: 'tiktok/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_tiktok.funnel_page_views_onsite_to_add_to_cart_onsite,
        metrics.conversion_tiktok.funnel_add_to_cart_onsite_to_initiate_checkout_onsite,
        metrics.conversion_tiktok.funnel_initiate_checkout_onsite_to_complete_payment_onsite,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_FUNNEL_TABLE,
  },
  {
    endpoint: 'tiktok/data/table',
    method: 'post',
    reqparams: {
      format: 'table',
      group_by: 'campaign',
      metricsConfig: [
        metrics.conversion_tiktok.spend,
        metrics.conversion_tiktok.impressions,
        metrics.conversion_tiktok.reach,
        metrics.conversion_tiktok.frequency,
        metrics.conversion_tiktok.clicks,
        metrics.conversion_tiktok.cpc,
        metrics.conversion_tiktok.cpm,
        metrics.conversion_tiktok.cost_per_1000_reached,
        metrics.conversion_tiktok.ctr,
        metrics.conversion_tiktok.video_play_actions,
        metrics.conversion_tiktok.average_video_play,
        metrics.conversion_tiktok.average_video_play_per_user,
        metrics.conversion_tiktok.engagements,
        metrics.conversion_tiktok.follows_community_interaction,
        metrics.conversion_tiktok.profile_visits_community_interaction,
        metrics.conversion_tiktok.page_views_onsite,
        metrics.conversion_tiktok.add_to_cart_onsite,
        metrics.conversion_tiktok.add_to_cart_value_onsite,
        metrics.conversion_tiktok.cost_per_add_to_cart_onsite,
        metrics.conversion_tiktok.initiate_checkout_onsite,
        metrics.conversion_tiktok.initiate_checkout_value_onsite,
        metrics.conversion_tiktok.cost_per_initiate_checkout_onsite,
        metrics.conversion_tiktok.complete_payment_onsite,
        metrics.conversion_tiktok.complete_payment_value_onsite,
        metrics.conversion_tiktok.cost_per_complete_payment_onsite,
        metrics.conversion_tiktok.roas_complete_payment_onsite,
        metrics.conversion_tiktok.conversion,
        metrics.conversion_tiktok.cost_per_conversion,
        metrics.conversion_tiktok.cvr,
        metrics.conversion_tiktok.product_clicks_onsite,
        metrics.conversion_tiktok.product_detail_page_view_onsite,
        metrics.conversion_tiktok.live_views_onsite,
        metrics.conversion_tiktok.live_product_clicks_onsite
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_CAMPAIGN,
  },
  {
    endpoint: 'tiktok/data/table',
    method: 'post',
    reqparams: {
      format: 'table',
      group_by: 'objective',
      metricsConfig: [
        metrics.conversion_tiktok.spend,
        metrics.conversion_tiktok.impressions,
        metrics.conversion_tiktok.reach,
        metrics.conversion_tiktok.frequency,
        metrics.conversion_tiktok.clicks,
        metrics.conversion_tiktok.cpc,
        metrics.conversion_tiktok.cpm,
        metrics.conversion_tiktok.cost_per_1000_reached,
        metrics.conversion_tiktok.ctr,
        metrics.conversion_tiktok.video_play_actions,
        metrics.conversion_tiktok.average_video_play,
        metrics.conversion_tiktok.average_video_play_per_user,
        metrics.conversion_tiktok.engagements,
        metrics.conversion_tiktok.follows_community_interaction,
        metrics.conversion_tiktok.profile_visits_community_interaction,
        metrics.conversion_tiktok.page_views_onsite,
        metrics.conversion_tiktok.add_to_cart_onsite,
        metrics.conversion_tiktok.add_to_cart_value_onsite,
        metrics.conversion_tiktok.cost_per_add_to_cart_onsite,
        metrics.conversion_tiktok.initiate_checkout_onsite,
        metrics.conversion_tiktok.initiate_checkout_value_onsite,
        metrics.conversion_tiktok.cost_per_initiate_checkout_onsite,
        metrics.conversion_tiktok.complete_payment_onsite,
        metrics.conversion_tiktok.complete_payment_value_onsite,
        metrics.conversion_tiktok.cost_per_complete_payment_onsite,
        metrics.conversion_tiktok.roas_complete_payment_onsite,
        metrics.conversion_tiktok.conversion,
        metrics.conversion_tiktok.cpa,
        metrics.conversion_tiktok.cvr,
        metrics.conversion_tiktok.product_clicks_onsite,
        metrics.conversion_tiktok.product_detail_page_view_onsite,
        metrics.conversion_tiktok.live_views_onsite,
        metrics.conversion_tiktok.live_product_clicks_onsite
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_OBJECTIVE,
  },
  {
    endpoint: 'tiktok/data/table/audience',
    method: 'post',
    reqparams: {
      format: 'table',
      group_by: 'campaign',
      metricsConfig: [
        metrics.conversion_tiktok.spend,
        metrics.conversion_tiktok.clicks,
        metrics.conversion_tiktok.cpc,
        metrics.conversion_tiktok.cpm,
        metrics.conversion_tiktok.average_video_play_per_user,
        metrics.conversion_tiktok.conversion,
        metrics.conversion_tiktok.cost_per_conversion,
        metrics.conversion_tiktok.conversion_rate,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_AUDIENCE,
  },
  {
    endpoint: 'tiktok/data/preview/clicks',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_tiktok.spend,
        metrics.conversion_tiktok.clicks,
        metrics.conversion_tiktok.cpc,
        metrics.conversion_tiktok.ctr,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_PREVIEW_CLICKS,
  },
  {
    endpoint: 'tiktok/data/preview/purchase',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_tiktok.spend,
        metrics.conversion_tiktok.complete_payment_onsite,
        metrics.conversion_tiktok.complete_payment_value_onsite,
        metrics.conversion_tiktok.roas_complete_payment_onsite,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CT_PREVIEW_PURCHASE,
  },

  // CONVERSION_WEBSITE (CW)
  {
    endpoint: 'tiktok/data/overview',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        // left
        metrics.conversion_website.spend,
        metrics.conversion_website.impressions,
        metrics.conversion_website.reach,
        metrics.conversion_website.frequency,
        metrics.conversion_website.clicks,
        metrics.conversion_website.cpc,
        metrics.conversion_website.cpm,
        metrics.conversion_website.cost_per_1000_reached,
        metrics.conversion_website.ctr,
        metrics.conversion_website.video_play_actions,
        metrics.conversion_website.average_video_play,
        metrics.conversion_website.average_video_play_per_user,
        metrics.conversion_website.engagements,
        metrics.conversion_website.follows_community_interaction,
        metrics.conversion_website.profile_visits_community_interaction,
        // right
        metrics.conversion_website.roas_complete_payment_website,
        metrics.conversion_website.page_views_website,
        metrics.conversion_website.add_to_cart_website,
        metrics.conversion_website.add_to_cart_value_website,
        metrics.conversion_website.cost_per_add_to_cart_website,
        metrics.conversion_website.place_an_order_website,
        metrics.conversion_website.initiate_checkout_website,
        metrics.conversion_website.complete_payment_website,
        metrics.conversion_website.complete_payment_value_website,
        metrics.conversion_website.cost_per_complete_payment_website,
        metrics.conversion_website.view_content_website,
        metrics.conversion_website.button_clicks_website,
        metrics.conversion_website.cost_per_button_clicks_website,
        metrics.conversion_website.button_clicks_value_website,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_OVERVIEW_STATS,
  },
  {
    endpoint: 'tiktok/data/periodic',
    method: 'post',
    reqparams: {
      format: 'periodic',
      metricsConfig: [
        metrics.conversion_website.spend,
        metrics.conversion_website.roas_complete_payment_website,
        metrics.conversion_website.cpc,
        metrics.conversion_website.cpm,
        metrics.conversion_website.add_to_cart_website,
        metrics.conversion_website.complete_payment_website,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_CHARTS,
  },
  {
    endpoint: 'tiktok/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_website.page_views_website,
        metrics.conversion_website.add_to_cart_website,
        metrics.conversion_website.place_an_order_website,
        metrics.conversion_website.initiate_checkout_website,
        metrics.conversion_website.complete_payment_website,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_FUNNEL_CHART,
  },
  {
    endpoint: 'tiktok/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_website.funnel_page_views_website_to_add_to_cart_website,
        metrics.conversion_website.funnel_add_to_cart_website_to_place_an_order_website,
        metrics.conversion_website.funnel_place_an_order_website_to_complete_payment_website,
        metrics.conversion_website.funnel_initiate_checkout_website_to_complete_payment_website,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_FUNNEL_TABLE,
  },
  {
    endpoint: 'tiktok/data/table',
    method: 'post',
    reqparams: {
      format: 'table',
      group_by: 'campaign',
      metricsConfig: [
        metrics.conversion_website.spend,
        metrics.conversion_website.impressions,
        metrics.conversion_website.reach,
        metrics.conversion_website.frequency,
        metrics.conversion_website.clicks,
        metrics.conversion_website.cpc,
        metrics.conversion_website.cpm,
        metrics.conversion_website.cost_per_1000_reached,
        metrics.conversion_website.ctr,
        metrics.conversion_website.video_play_actions,
        metrics.conversion_website.average_video_play,
        metrics.conversion_website.average_video_play_per_user,
        metrics.conversion_website.engagements,
        metrics.conversion_website.follows_community_interaction,
        metrics.conversion_website.profile_visits_community_interaction,
        metrics.conversion_website.button_clicks_website,
        metrics.conversion_website.button_clicks_value_website,
        metrics.conversion_website.cost_per_button_clicks_website,
        metrics.conversion_website.page_views_website,
        metrics.conversion_website.add_to_cart_website,
        metrics.conversion_website.add_to_cart_value_website,
        metrics.conversion_website.cost_per_add_to_cart_website,
        metrics.conversion_website.place_an_order_website,
        metrics.conversion_website.initiate_checkout_website,
        metrics.conversion_website.complete_payment_website,
        metrics.conversion_website.complete_payment_value_website,
        metrics.conversion_website.cost_per_complete_payment_website,
        metrics.conversion_website.roas_complete_payment_website,
        metrics.conversion_website.view_content_website,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_TABLE_CAMPAIGN,
  },
  {
    endpoint: 'tiktok/data/table',
    method: 'post',
    reqparams: {
      format: 'table',
      group_by: 'campaign',
      metricsConfig: [
        metrics.conversion_website.spend,
        metrics.conversion_website.impressions,
        metrics.conversion_website.reach,
        metrics.conversion_website.frequency,
        metrics.conversion_website.clicks,
        metrics.conversion_website.cpc,
        metrics.conversion_website.cpm,
        metrics.conversion_website.cost_per_1000_reached,
        metrics.conversion_website.ctr,
        metrics.conversion_website.video_play_actions,
        metrics.conversion_website.average_video_play,
        metrics.conversion_website.average_video_play_per_user,
        metrics.conversion_website.engagements,
        metrics.conversion_website.follows_community_interaction,
        metrics.conversion_website.profile_visits_community_interaction,
        metrics.conversion_website.button_clicks_website,
        metrics.conversion_website.button_clicks_value_website,
        metrics.conversion_website.cost_per_button_clicks_website,
        metrics.conversion_website.page_views_website,
        metrics.conversion_website.add_to_cart_website,
        metrics.conversion_website.add_to_cart_value_website,
        metrics.conversion_website.cost_per_add_to_cart_website,
        metrics.conversion_website.place_an_order_website,
        metrics.conversion_website.initiate_checkout_website,
        metrics.conversion_website.complete_payment_website,
        metrics.conversion_website.complete_payment_value_website,
        metrics.conversion_website.cost_per_complete_payment_website,
        metrics.conversion_website.roas_complete_payment_website,
        metrics.conversion_website.view_content_website,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_TABLE_OBJECTIVE,
  },
  {
    endpoint: 'tiktok/data/table',
    method: 'post',
    reqparams: {
      format: 'table',
      group_by: 'campaign',
      metricsConfig: [
        metrics.conversion_website.spend,
        metrics.conversion_website.impressions,
        metrics.conversion_website.clicks,
        metrics.conversion_website.cpc,
        metrics.conversion_website.cpm,
        metrics.conversion_website.ctr,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_TABLE_AUDIENCE,
  },
  {
    endpoint: 'tiktok/data/preview/reach',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_website.spend,
        metrics.conversion_website.impressions,
        metrics.conversion_website.reach,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_PREVIEW_REACH,
  },
  {
    endpoint: 'tiktok/data/preview/traffic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_website.spend,
        metrics.conversion_website.clicks,
        metrics.conversion_website.ctr,
        metrics.conversion_website.cpc,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_PREVIEW_TRAFFIC,
  },
  {
    endpoint: 'tiktok/data/preview/conversion',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion_website.spend,
        metrics.conversion_website.complete_payment_website,
        metrics.conversion_website.complete_payment_value_website,
        metrics.conversion_website.roas_complete_payment_website,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CW_PREVIEW_CONVERSION,
  },

  // COMMUNITY_INTERACTION (CI)
  {
    endpoint: 'tiktok/data/overview',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        // left
        metrics.community_interaction.spend,
        metrics.community_interaction.impressions,
        metrics.community_interaction.reach,
        metrics.community_interaction.frequency,
        metrics.community_interaction.clicks,
        metrics.community_interaction.cpc,
        metrics.community_interaction.cpm,
        metrics.community_interaction.cost_per_1000_reached,
        metrics.community_interaction.ctr,
        metrics.community_interaction.video_play_actions,
        metrics.community_interaction.average_video_play,
        metrics.community_interaction.average_video_play_per_user,
        // right
        metrics.community_interaction.engagements,
        metrics.community_interaction.engagement_rate,
        metrics.community_interaction.follows_community_interaction,
        metrics.community_interaction.cost_per_paid_followers,
        metrics.community_interaction.likes_community_interaction,
        metrics.community_interaction.comments_community_interaction,
        metrics.community_interaction.shares_community_interaction,
        metrics.community_interaction.profile_visits_community_interaction,
        metrics.community_interaction.profile_visit_rate,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CI_OVERVIEW_STATS,
  },
  {
    endpoint: 'tiktok/data/periodic',
    method: 'post',
    reqparams: {
      format: 'periodic',
      metricsConfig: [
        metrics.community_interaction.spend,
        metrics.community_interaction.profile_visits_community_interaction,
        metrics.community_interaction.follows_community_interaction,
        metrics.community_interaction.likes_community_interaction,
        metrics.community_interaction.comments_community_interaction,
        metrics.community_interaction.shares_community_interaction,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_TIKTOK_CI_CHARTS,
  },
  {
    endpoint: 'tiktok/data/table',
    method: 'post',
    reqparams: {
      format: 'table',
      group_by: 'campaign',
      metricsConfig: [
        metrics.community_interaction.spend,
        metrics.community_interaction.impressions,
        metrics.community_interaction.reach,
        metrics.community_interaction.frequency,
        metrics.community_interaction.clicks,
        metrics.community_interaction.cpc,
        metrics.community_interaction.cpm,
        metrics.community_interaction.cost_per_1000_reached,
        metrics.community_interaction.ctr,
        metrics.community_interaction.video_play_actions,
        metrics.community_interaction.average_video_play,
        metrics.community_interaction.average_video_play_per_user,
        metrics.community_interaction.engagements,
        metrics.community_interaction.follows_community_interaction,
        metrics.community_interaction.likes_community_interaction,
        metrics.community_interaction.shares_community_interaction,
        metrics.community_interaction.profile_visits_community_interaction,
        metrics.community_interaction.profile_visit_rate,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_TIKTOK_CI_TABLE_CAMPAIGN,
  },
  {
    endpoint: 'tiktok/data/table',
    method: 'post',
    reqparams: {
      format: 'table',
      group_by: 'campaign',
      metricsConfig: [
        metrics.community_interaction.spend,
        metrics.community_interaction.impressions,
        metrics.community_interaction.clicks,
        metrics.community_interaction.cpc,
        metrics.community_interaction.cpm,
        metrics.community_interaction.ctr,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_TIKTOK_CI_TABLE_AUDIENCE,
  },
  {
    endpoint: 'tiktok/data/preview/paid-followers',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.community_interaction.spend,
        metrics.community_interaction.follows_community_interaction,
        metrics.community_interaction.cost_per_paid_followers,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CI_PREVIEW_FOLLOWERS,
  },
  {
    endpoint: 'tiktok/data/preview/paid-profile-visits',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.community_interaction.spend,
        metrics.community_interaction.profile_visits_community_interaction,
        metrics.community_interaction.cost_per_paid_profile_visits,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_TIKTOK_CI_PREVIEW_PROFILE_VISITS,
  },
];

export default { displayName, propTypes, defaultProps, requestConfig };
