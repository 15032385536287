import React from 'react';
import { Tooltip } from 'antd';

import config from './MultiColorProgress.component.config';
import './MultiColorProgress.component.styles.less';

const MultiColorProgress = ({
  chunks = [],
  height = 10,
}) => {
  const formatChunks = () => {
    const output = [];
    const chunkTotalArray = Array.isArray(chunks) ? chunks?.map(e => e?.total || 0) : [0];
    const totalValue = [0, ...chunkTotalArray]?.reduce((s,e) => s+e);
    let totalPercent = 0;
    for (let i=0; i<chunks?.length; i+=1) {
      const chunk = chunks[i];
      const increment = 100*chunk?.value/(totalValue || 1);
      if (increment > 0) {
        totalPercent += increment;
        output.push({ color: chunk?.color || 'transparent', label: chunk?.label || '', increment, percent: totalPercent });
        output?.sort((a,b) => b?.percent - a?.percent);
      };
    }
    return output;
  };
  const formattedChunks = formatChunks();

  return(
    <div className="multicolor-progress multicolor-progress-line">
      <div className="multicolor-progress-outer">
        <div className="multicolor-progress-inner">
          {formattedChunks?.map(el => (<Tooltip title={el?.label} color={el?.color} placement="topRight" overlayInnerStyle={{ fontSize: 14, padding: 6 }}>
            <div
              className="multicolor-progress-chunk-bg"
              style={{ backgroundColor: el?.color, height, left: 0, position: 'absolute', top: 0, width: `${el?.percent}%`, maxWidth: `100%` }}
            />
          </Tooltip>))}
        </div>
      </div>
    </div>
  )
}

MultiColorProgress.displayName = config.displayName;
MultiColorProgress.propTypes = config.propTypes;
MultiColorProgress.defaultProps = config.defaultProps;

export default MultiColorProgress;