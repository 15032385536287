import PropTypes from 'prop-types';
import TYPES from '../../redux-v1/types';
import metrics from '../../constants-v1/metrics-config/meta';
import { reqFormat as rpf } from '../../utils-v1/request.utils';

const displayName = 'Facebook';
const propTypes = {};
const defaultProps = { dateRangeValue: PropTypes.object };

const requestConfig = [
  {
    endpoint: 'meta/currency',
    method: 'post',
    reqparams: {},
    type: TYPES.SET_DASHBOARD_META_ACCOUNT_DETAILS_CURRENCY,
  },

  // CONVERSION
  {
    endpoint: 'meta/data/overview',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        // left
        metrics.conversion.spend,
        metrics.conversion.reach,
        metrics.conversion.impressions,
        metrics.conversion.frequency,
        metrics.conversion.ctr_all,
        metrics.conversion.cpm,
        metrics.conversion.link_click,
        metrics.conversion.clicks_all,
        metrics.conversion.cpc_all,
        metrics.conversion.landing_page_view,
        metrics.conversion.cost_per_landing_page_view,
        // right
        metrics.conversion.roas,
        metrics.conversion.purchase,
        metrics.conversion.purchase_value,
        metrics.conversion.cost_per_purchase,
        metrics.conversion.add_to_cart,
        metrics.conversion.add_to_cart_value,
        metrics.conversion.cost_per_add_to_cart,
        metrics.conversion.conversion_rate,
        metrics.conversion.avg_basket_price,
        // bottom
        metrics.conversion.post_engagement,
        metrics.conversion.post_saves,
        metrics.conversion.post_reaction,
        metrics.conversion.post_comment,
        metrics.conversion.thruplays,
        metrics.conversion.video_play,
        metrics.conversion.video_play_100,
        metrics.conversion.video_avg_time_watched_actions,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_OVERVIEW_STATS,
  },
  {
    endpoint: 'meta/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion.cpm,
        metrics.conversion.cpc_link_click,
        metrics.conversion.landing_page_view,
        metrics.conversion.link_click,
        metrics.conversion.lead,
        metrics.conversion.add_to_cart,
        metrics.conversion.purchase,
        metrics.conversion.purchase_value,
        metrics.conversion.roas,
        metrics.conversion.spend,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_CHARTS,
  },
  {
    endpoint: 'meta/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion.landing_page_view,
        metrics.conversion.add_to_cart,
        metrics.conversion.initiate_checkout,
        metrics.conversion.purchase,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_FUNNEL_CHART,
  },
  {
    endpoint: 'meta/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion.funnel_landing_page_view_to_add_to_cart,
        metrics.conversion.funnel_add_to_cart_to_initiate_checkout,
        metrics.conversion.funnel_initiate_checkout_to_purchase,
        metrics.conversion.funnel_add_to_cart_to_purchase,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_FUNNEL_TABLE,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.conversion.spend,
        metrics.conversion.add_to_cart,
        metrics.conversion.add_to_cart_value,
        metrics.conversion.cost_per_add_to_cart,
        metrics.conversion.purchase,
        metrics.conversion.purchase_value,
        metrics.conversion.cost_per_purchase,
        metrics.conversion.roas,
        metrics.conversion.lead,
        metrics.conversion.cost_per_lead,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_PURCHASE,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'objective',
      metricsConfig: [
        metrics.conversion.reach,
        metrics.conversion.impressions,
        metrics.conversion.spend,
        metrics.conversion.link_click,
        metrics.conversion.cpc_link_click,
        metrics.conversion.ctr_rate,
        metrics.conversion.clicks_all,
        metrics.conversion.cpc_all,
        metrics.conversion.ctr_all,
        metrics.conversion.cpm,
        metrics.conversion.purchase,
        metrics.conversion.purchase_value,
        metrics.conversion.roas,
        metrics.conversion.add_to_cart,
        metrics.conversion.add_to_cart_value,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_OBJECTIVE,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.conversion.frequency,
        metrics.conversion.link_click,
        metrics.conversion.cpc_link_click,
        metrics.conversion.ctr_rate,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_LINK_CLICKS,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.conversion.spend,
        metrics.conversion.reach,
        metrics.conversion.impressions,
        metrics.conversion.frequency,
        metrics.conversion.link_click,
        metrics.conversion.cpc_link_click,
        metrics.conversion.ctr_rate,
        metrics.conversion.cpm,
        metrics.conversion.post_engagement,
        metrics.conversion.post_comment,
        metrics.conversion.post_share,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_ENGAGEMENT,
  },
  {
    endpoint: 'meta/data/table/placement',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion.link_click,
        metrics.conversion.ctr_rate,
        metrics.conversion.reach,
        metrics.conversion.impressions,
        metrics.conversion.post_engagement,
        metrics.conversion.post_comment,
        metrics.conversion.post_share,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_PLACEMENT,
  },
  {
    endpoint: 'meta/data/table/delivery_platform',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion.spend,
        metrics.conversion.add_to_cart,
        metrics.conversion.cost_per_add_to_cart,
        metrics.conversion.add_to_cart_value,
        metrics.conversion.purchase,
        metrics.conversion.cost_per_purchase,
        metrics.conversion.purchase_value,
        metrics.conversion.roas,
        metrics.conversion.clicks_all,
        metrics.conversion.ctr_rate,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_DELIVERY_PLATFORM,
  },
  {
    endpoint: 'meta/data/table/region',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion.reach,
        metrics.conversion.impressions,
        metrics.conversion.clicks,
        metrics.conversion.ctr,
        metrics.conversion.frequency,
        metrics.conversion.cpm,
        metrics.conversion.cost_per_link_click,
        metrics.conversion.link_click,
        metrics.conversion.post_engagement,
        metrics.conversion.comment,
        metrics.conversion.post,
        metrics.conversion.spend,
        metrics.conversion.lead,
        metrics.conversion.cost_per_lead,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_REGION,
  },
  {
    endpoint: 'meta/data/table/age',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion.reach,
        metrics.conversion.impressions,
        metrics.conversion.frequency,
        metrics.conversion.link_click,
        metrics.conversion.cpc,
        metrics.conversion.spend,
        metrics.conversion.cpm,
        metrics.conversion.ctr,
        metrics.conversion.messaging_conv_started,
        metrics.conversion.cost_per_messaging_conv_started,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_AGE,
  },
  {
    endpoint: 'meta/data/table/device_platform',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.conversion.spend,
        metrics.conversion.add_to_cart,
        metrics.conversion.cost_per_add_to_cart,
        metrics.conversion.add_to_cart_value,
        metrics.conversion.purchase,
        metrics.conversion.cost_per_purchase,
        metrics.conversion.purchase_value,
        metrics.conversion.roas,
        metrics.conversion.clicks_all,
        metrics.conversion.ctr_all,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_DEVICE_PLATFORM,
  },
  {
    endpoint: 'meta/data/preview/purchase_ads',
    method: 'post',
    reqparams: {},
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_PREVIEW_PURCHASE_ADS,
  },
  {
    endpoint: 'meta/data/preview/engagement_ads',
    method: 'post',
    reqparams: {},
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_PREVIEW_ENGAGEMENT_ADS,
  },
  {
    endpoint: 'meta/data/preview/link_click_ads',
    method: 'post',
    reqparams: {},
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_CONVERSION_PREVIEW_LINK_CLICK_ADS,
  },

  // COLLABORATIVE
  {
    endpoint: 'meta/data/overview',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        // left
        metrics.collaborative.spend,
        metrics.collaborative.reach,
        metrics.collaborative.impressions,
        metrics.collaborative.ctr_all,
        metrics.collaborative.cpm,
        metrics.collaborative.link_click,
        metrics.collaborative.clicks_all,
        metrics.collaborative.cpc_all,
        // right
        metrics.collaborative.roas_shared_item,
        metrics.collaborative.purchase_shared_item,
        metrics.collaborative.purchase_value_shared_item,
        metrics.collaborative.cost_per_purchase_shared_item,
        metrics.collaborative.add_to_cart_shared_item,
        metrics.collaborative.add_to_cart_value_shared_item,
        metrics.collaborative.cost_per_add_to_cart_shared_item,
        metrics.collaborative.content_view_shared_item,
        metrics.collaborative.cost_per_content_view_shared_item,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_COLLABORATIVE_OVERVIEW_STATS,
  },
  {
    endpoint: 'meta/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.collaborative.cpm,
        metrics.collaborative.add_to_cart_shared_item,
        metrics.collaborative.purchase_shared_item,
        metrics.collaborative.content_view_shared_item,
        metrics.collaborative.roas_shared_item,
        metrics.collaborative.spend,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_META_COLLABORATIVE_CHARTS,
  },
  {
    endpoint: 'meta/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.collaborative.content_view_shared_item,
        metrics.collaborative.add_to_cart_shared_item,
        metrics.collaborative.purchase_shared_item,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_COLLABORATIVE_FUNNEL_CHART,
  },
  {
    endpoint: 'meta/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.collaborative.funnel_content_view_to_add_to_cart_shared_item,
        metrics.collaborative.funnel_add_to_cart_to_purchase_shared_item,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_COLLABORATIVE_FUNNEL_TABLE,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.collaborative.spend,
        metrics.collaborative.reach,
        metrics.collaborative.impressions,
        metrics.collaborative.clicks_all,
        metrics.collaborative.link_click,
        metrics.collaborative.cpc_all,
        metrics.collaborative.cpm,
        metrics.collaborative.ctr_all,
        metrics.collaborative.add_to_cart_shared_item,
        metrics.collaborative.add_to_cart_value_shared_item,
        metrics.collaborative.purchase_shared_item,
        metrics.collaborative.purchase_value_shared_item,
        metrics.collaborative.content_view_shared_item,
        metrics.collaborative.roas_shared_item,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_COLLABORATIVE_TABLE_CAMPAIGN,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'objective',
      metricsConfig: [
        metrics.collaborative.spend,
        metrics.collaborative.reach,
        metrics.collaborative.impressions,
        metrics.collaborative.clicks_all,
        metrics.collaborative.link_click,
        metrics.collaborative.cpc_all,
        metrics.collaborative.cpm,
        metrics.collaborative.ctr_all,
        metrics.collaborative.add_to_cart_shared_item,
        metrics.collaborative.add_to_cart_value_shared_item,
        metrics.collaborative.purchase_shared_item,
        metrics.collaborative.purchase_value_shared_item,
        metrics.collaborative.content_view_shared_item,
        metrics.collaborative.roas_shared_item,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_COLLABORATIVE_TABLE_OBJECTIVE,
  },
  {
    endpoint: 'meta/data/preview/cpas_purchase_ads',
    method: 'post',
    reqparams: {},
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_COLLABORATIVE_PREVIEW_PURCHASE_ADS,
  },
  {
    endpoint: 'meta/data/preview/content_views_cpas',
    method: 'post',
    reqparams: {},
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_COLLABORATIVE_PREVIEW_CONTENT_VIEWS,
  },

  // LEAD
  {
    endpoint: 'meta/data/overview',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        // left
        metrics.lead.lead,
        metrics.lead.spend,
        metrics.lead.reach,
        metrics.lead.impressions,
        metrics.lead.frequency,
        metrics.lead.link_click,
        metrics.lead.cpm,
        metrics.lead.cpc_all,
        metrics.lead.ctr_all,
        // right
        metrics.lead.cost_per_lead,
        metrics.lead.landing_page_view,
        metrics.lead.cost_per_landing_page_view,
        metrics.lead.estimated_recall_lift_rate,
        // bottom
        metrics.lead.post_engagement,
        metrics.lead.post_saves,
        metrics.lead.post_reaction,
        metrics.lead.post_comment,
        metrics.lead.thruplays,
        metrics.lead.video_play,
        metrics.lead.video_play_100,
        metrics.lead.video_avg_time_watched_actions,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_LEAD_OVERVIEW_STATS,
  },
  {
    endpoint: 'meta/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.lead.lead,
        metrics.lead.clicks_all,
        metrics.lead.cost_per_lead,
        metrics.lead.cpc_all,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_META_LEAD_CHARTS,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.lead.spend,
        metrics.lead.reach,
        metrics.lead.impressions,
        metrics.lead.frequency,
        metrics.lead.lead,
        metrics.lead.cost_per_lead,
        metrics.lead.cpc_all,
        metrics.lead.cpc_link_click,
        metrics.lead.cpm,
        metrics.lead.ctr_all,
        metrics.lead.ctr_rate,
        metrics.lead.clicks_all,
        metrics.lead.landing_page_view,
        metrics.lead.cost_per_landing_page_view,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_LEAD_TABLE_CONVERSION,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.lead.spend,
        metrics.lead.reach,
        metrics.lead.impressions,
        metrics.lead.frequency,
        metrics.lead.lead,
        metrics.lead.cost_per_lead,
        metrics.lead.cpc_all,
        metrics.lead.cpc_link_click,
        metrics.lead.cpm,
        metrics.lead.ctr_all,
        metrics.lead.ctr_rate,
        metrics.lead.clicks_all,
        metrics.lead.unique_link_click,
        metrics.lead.outbound_click,
        metrics.lead.unique_outbound_click,
        metrics.lead.unique_ctr,
        metrics.lead.outbound_ctr,
        metrics.lead.unique_outbound_ctr,
        metrics.lead.cost_per_unique_link_click,
        metrics.lead.cost_per_outbound_click,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_LEAD_TABLE_TRAFFIC,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.lead.spend,
        metrics.lead.reach,
        metrics.lead.impressions,
        metrics.lead.frequency,
        metrics.lead.lead,
        metrics.lead.cost_per_lead,
        metrics.lead.cpc_all,
        metrics.lead.cpc_link_click,
        metrics.lead.cpm,
        metrics.lead.ctr_all,
        metrics.lead.ctr_rate,
        metrics.lead.clicks_all,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_LEAD_TABLE_LEADS,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.lead.spend,
        metrics.lead.reach,
        metrics.lead.impressions,
        metrics.lead.frequency,
        metrics.lead.lead,
        metrics.lead.cost_per_1000_people_reach,
        metrics.lead.cpc_all,
        metrics.lead.cpc_link_click,
        metrics.lead.cpm,
        metrics.lead.ctr_all,
        metrics.lead.ctr_rate,
        metrics.lead.clicks_all,
        metrics.lead.post_engagement,
        metrics.lead.photo_views,
        metrics.lead.thruplays,
        { ...metrics.lead.estimated_recall_lift_rate, lbl: 'Estimated Ad Recall Lift (%)' },
        metrics.lead.estimated_ad_recallers,
        metrics.lead.cost_per_estimated_recall_people,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_LEAD_TABLE_BRAND_AWARENESS,
  },
  {
    endpoint: 'meta/data/preview/lead_ads',
    method: 'post',
    reqparams: {},
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_LEAD_PREVIEW_LEAD_ADS,
  },

  // MESSAGES
  {
    endpoint: 'meta/data/overview',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        // left
        metrics.messages.spend,
        metrics.messages.reach,
        metrics.messages.impressions,
        metrics.messages.frequency,
        metrics.messages.link_click,
        metrics.messages.clicks_all,
        metrics.messages.cpm,
        metrics.messages.cpc_all,
        metrics.messages.ctr_all,
        // right
        metrics.messages.messaging_conv_started,
        metrics.messages.cost_per_messaging_conv_started,
        metrics.messages.messaging_new,
        metrics.messages.cost_per_messaging_new,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_MESSAGES_OVERVIEW_STATS,
  },
  {
    endpoint: 'meta/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.messages.spend,
        metrics.messages.messaging_new,
        metrics.messages.messaging_conv_started,
        metrics.messages.cost_per_messaging_new,
        metrics.messages.cost_per_messaging_conv_started,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_META_MESSAGES_CHARTS,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'campaign',
      metricsConfig: [
        metrics.messages.spend,
        metrics.messages.reach,
        metrics.messages.impressions,
        metrics.messages.cpc_all,
        metrics.messages.cpm,
        metrics.messages.ctr_all,
        metrics.messages.messaging_conv_started,
        metrics.messages.cost_per_messaging_conv_started,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_MESSAGES_TABLE_CAMPAIGN,
  },
  {
    endpoint: 'meta/data/table',
    method: 'post',
    reqparams: {
      group_by: 'objective',
      metricsConfig: [
        metrics.messages.spend,
        metrics.messages.reach,
        metrics.messages.impressions,
        metrics.messages.cpc_all,
        metrics.messages.cpm,
        metrics.messages.ctr_all,
        metrics.messages.messaging_conv_started,
        metrics.messages.cost_per_messaging_conv_started,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_META_MESSAGES_TABLE_OBJECTIVE,
  },
  {
    endpoint: 'meta/data/preview/messages',
    method: 'post',
    reqparams: {},
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_META_MESSAGES_PREVIEW_MESSAGES,
  },
];

export default { displayName, propTypes, defaultProps, requestConfig };
