import TYPES from '../../types';
import config from './__config';

const initialState = config.bindingStructure.marketplace;

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_BINDING_BIND_MARKETPLACE: return {
      ...state,
      ...action.payload,
    };
  };
}