import React from 'react';
import { Col, Image, Input, List, Popover, Radio, Row, Space, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_META,
  AVATAR_TIKTOK,
  CHECK_SELECTED_ACCOUNT,
  CHECK_SELECTED_ACCOUNT_PLACEHOLDER,
  ICON_PLUS_ACCOUNT_SELECTION_ALT,
} from '../../../assets-v1/figma-icons';

import config from './AccountSelectionBudgetTracker.component.config';
import './AccountSelectionBudgetTracker.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const AccountSelectionBudgetTracker = ({
  accountsByPlatform = {},
  children = null,
  onSelectAccount = (acct) => console.log(acct),
  placement = 'right',
  selectedAccounts = [{ account_id: 'act_1005227226299354' }],
  trigger = 'click',
  ...props
}) => {
  const [platform, setPlatform] = React.useState('meta');
  const [searchText, setSearchText] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const platformSelections = [
    { key: 'meta', avatar: AVATAR_META, text: 'Facebook' },
    { key: 'tiktok', avatar: AVATAR_TIKTOK, text: 'TikTok' },
    { key: 'googleAds', avatar: AVATAR_GOOGLE_ADS, text: 'Google Ads' },
  ];
  const getPopoverContent = () => (
    <div id="AccountSelectionBudgetTracker">
      <Paragraph className="section-title">Selection Account</Paragraph>
      <Radio.Group name="platformSelection" defaultValue={platform} onChange={(e) => setPlatform(e.target.value)}>
        <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
          {platformSelections.map(el => <Col sm={24} md={24} lg={12} key={el.key}>
            <div className="platform-selection-cols">
              <Radio value={el.key}>
                <Image src={el.avatar} alt="platform_avatar" preview={false} width={25} />
                &nbsp;&nbsp;{el.text}
              </Radio>
            </div>
          </Col>)}
        </Row>
      </Radio.Group>
      <Paragraph className="section-title">Select Ad Account</Paragraph>
      <Input
        placeholder="Search or enter Ad Account"
        prefix={<SearchOutlined style={{ color: '#A6AAB1', paddingLeft: 10 }} />}
        defaultValue={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Text style={{ color: '#999999', fontSize: '0.9rem' }}>
        {accountsByPlatform?.[platform]?.length} Ad Account on {platformSelections?.find(el => el?.key === platform)?.text}
      </Text>
      <br />
      <List
        dataSource={accountsByPlatform?.[platform]
          ?.filter(el => el?.account_name?.toLowerCase()?.includes(searchText?.toLowerCase()))
        }
        renderItem={(item) => (
          <List.Item
            className="account-selection-list-item"
            onClick={() => { onSelectAccount(item); setOpen(false) }}
          >
            <Space direction="horizontal" size={16}>
              <Image
                src={selectedAccounts?.map(account => account?.account_id)?.includes(item?.account_id) ?
                  CHECK_SELECTED_ACCOUNT : CHECK_SELECTED_ACCOUNT_PLACEHOLDER
                }
                alt={selectedAccounts?.map(account => account?.account_id)?.includes(item?.account_id) ?
                  'checked_account' : 'unchecked_account'
                }
                preview={false}
                width={46}
              />
              <Space direction="vertical" size={1}>
                <Text>{item?.account_name}</Text>
                <Text style={{ fontSize: '0.8rem', color: '#999999' }}>{item?.account_id}</Text>
              </Space>
            </Space>
          </List.Item>
        )}
        style={{ height: 300, overflowY: 'scroll' }}
      />
    </div>
  );

  const constructPopoverProps = () => ({
    children,
    content: getPopoverContent(),
    placement,
    trigger,
    open,
    onOpenChange: (opened=false) => setOpen(opened),
  })

  return(
    <Popover { ...constructPopoverProps() } />
  )
}

AccountSelectionBudgetTracker.displayName = config.displayName;
AccountSelectionBudgetTracker.propTypes = config.propTypes;
AccountSelectionBudgetTracker.defaultProps = config.defaultProps;

export default AccountSelectionBudgetTracker;