import { cur, flt, int, pct } from './__config';
export default {
  marketplace: {
    aov: { key: 'aov', lbl: 'Avg. Order', fmt: flt, adv: 0 },
    order: { key: 'order', lbl: 'Order', fmt: int, adv: 0 },
    order_cancel: { key: 'order_cancel', lbl: 'Order Cancel', fmt: int, adv: 1 },
    order_paid: { key: 'order_paid', lbl: 'Order Paid', fmt: int, adv: 0 },
    order_pending: { key: 'order_pending', lbl: 'Order Pending', fmt: int, adv: 1 },
    sales: { key: 'sales', lbl: 'Sales', fmt: cur, adv: 0 },
    today_sales: { key: 'today_sales', lbl: 'Total Sales', fmt: int, adv: 0 },
    total_sales: { key: 'total_sales', lbl: 'Total Sales', fmt: int, adv: 0 },
    total_cancel: { key: 'total_cancel', lbl: 'Total Cancel', fmt: int, adv: 1 },
    total_pending: { key: 'total_pending', lbl: 'Total Pending', fmt: int, adv: 0 },
    total_shipping: { key: 'total_shipping', lbl: 'Total Shipping', fmt: int, adv: 0 }
  }
};