import React from 'react';
import { Card, Col, Image, Menu, Row, Typography } from 'antd';
import animations from '../../../utils-v1/animations';
import { DocsLayout } from '../../../components/layouts-backup';

import config from './DocsContent.component.config';
import './DocsContent.component.style.less';

const { Title, Text } = Typography;
const { contentData } = config;

class DocsContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: props.location.pathname.slice(1).split('/'),
      content: {},
    };
  }

  componentDidMount() {
    console.log(`DocsContent is mounted...`);
    const selectionContent = contentData.find((content) => content.key === this.state.page[1]);
    this.setState((prevState) =>  ({ ...prevState, content: selectionContent }) );
  }

  componentWillUnmount() {
    console.log(`DocsContent is unmounted...`);
  }

  constructLayoutProps = () => {
    const { location, history, user } = this.props;
    const { title, url } = config; console.log({ title, url })
    return { location, history, title, url, user };
  }

  renderTableContent = () => {
    const content = this.state.content?.tableContent;

    let renderingContent = [];
    let renderingtableContent = [];

    if (content !== undefined) {
      renderingContent = content.map((item, index) => (
        <div key={item.key} id={item.key}>
          {item.title && <Title level={3}>{item?.index || index}. {item.title}</Title>}

          {item.description && <Text className="description-content">{item.description}</Text>}

          {item.image && (
            <Card id="card-image">
              <div className="image-content">
                <Image src={item.image} preview={false} />
              </div>
              <div className="content-journey">
                <Row gutter={24}>
                  {item.imageContent.length !== 0 && item.imageContent.map((icontent, key) => (
                    <>
                      <Col md={1}>
                        <div className="numbering">{icontent?.index || key + 1}</div>
                      </Col>
                      <Col md={23}>
                        <Title level={3}>{icontent.title}</Title>
                        <Text>{icontent.subtitle}</Text>
                      </Col>
                    </>
                  ))}
                </Row>
              </div>
            </Card>
          )}
        </div>
      ));

      renderingtableContent = (
        <div id="menu-table-of-content">
          <Title level={3}>Table of Content</Title>
          <div>
            <Menu
              onClick={(e) => {
                console.log(e);

                window.scrollTo({
                  behavior: 'smooth',
                  top:
                    document.querySelector(`#${e.key}`).getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    120,
                })

              }}
              mode="inline"
            >
              {content.map((item, index) => (
                <Menu.Item
                  hidden={!item.isNavigation}
                  key={item.key}
                >
                  <Title level={4}>{(item?.index || ((index + 1) - 1))}. {item.title}</Title>
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </div>
      );
    }

    return {
      content: renderingContent,
      tableContent: renderingtableContent
    };
  };

  retrieveContent = () => {
    const data = this.state.content;
    return (
      <Row gutter={24}>
        <Col xs={24} md={18} xl={18} lg={18} xxl={18}>
          <Title level={2}>{data?.title}</Title>
          {this.renderTableContent().content}
        </Col>
        <Col xs={24} md={6} xl={6} lg={6} xxl={6}>
          {this.renderTableContent().tableContent}
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <DocsLayout {...this.constructLayoutProps()}>
        <div style={{ animation: animations.fadeIn }}>
          {this.retrieveContent()}
        </div>
      </DocsLayout>
    )
  }
}

DocsContent.displayName = config.displayName;
DocsContent.propTypes = config.propTypes;
DocsContent.defaultProps = config.defaultProps;

export default DocsContent;
