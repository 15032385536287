import { connect } from 'react-redux';
import RangePicker from './RangePicker.component';

import {
  updateCoachmarkLocal,
} from '../../../redux-v1/actions/user/__common.actions';

const mapStateToProps = (state) => ({
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});

const mapDispatchToProps = {
  updateCoachmarkLocal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RangePicker);
