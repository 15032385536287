import PropTypes from 'prop-types';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_GOOGLE_ANALYTICS,
  AVATAR_INSTAGRAM,
  AVATAR_MARKETPLACE,
  AVATAR_META,
  AVATAR_SHOPEE,
  AVATAR_TIKTOK,
  AVATAR_TOKOPEDIA,
  AVATAR_WEBSITE,
} from '../../assets-v1/figma-icons';

const displayName = 'OnboardingTour';
const propTypes = {};
const defaultProps = {};

const onboardPlatform = [
  {
    key: 'meta',
    icon: AVATAR_META,
    platform: 'Meta Ads',
    description: 'Meta Ads are advertisements displayed on platforms owned by Meta, Inc., like Facebook, Instagram, and Messenger, using targeted data to reach specific audiences and achieve marketing goals.',
  },
  {
    key: 'tiktok',
    icon: AVATAR_TIKTOK,
    platform: 'TikTok Ads',
    description: 'TikTok Ads are campaigns on the short-form video platform TikTok, enabling businesses to engage millions of users through various ad formats and reach a younger, digitally-savvy audience.',
  },
  {
    key: 'google-ads',
    icon: AVATAR_GOOGLE_ADS,
    platform: 'Google Ads',
    description: `Google Ads is an online advertising platform by Google that lets businesses display ads on Google's search results and partner websites, targeting specific keywords and demographics with a pay-per-click model.`,
  },
  {
    key: 'google-analytics',
    icon: AVATAR_GOOGLE_ANALYTICS,
    platform: 'Google Analytics',
    description: 'Google Analytics offers detailed web traffic and user behavior data, helping businesses understand their online audience, make data-driven decisions, and improve digital marketing efforts for better results.',
  },
  {
    key: 'instagram',
    icon: AVATAR_INSTAGRAM,
    platform: 'Instagram Insights',
    description: 'Instagram Insights is an analytics tool for business accounts, providing valuable data on post reach, engagement, and audience demographics to optimize marketing strategies.',
  },
  {
    key: 'website',
    icon: AVATAR_WEBSITE,
    platform: 'Website Data',
    description: `Website data, such as Shopify's analytics, comprises valuable information on online store performance, including sales, visitor traffic, customer behavior, and more.`,
  },
  {
    key: 'marketplace',
    icon: AVATAR_MARKETPLACE,
    platform: 'Marketplace Data',
    description: 'Marketplace data from platforms like Shopee and Tokopedia encompasses crucial insights into product listings, consumer behavior, seller performance, and sales trends within their respective ecosystems.',
  },
];

const onboardMarketplace = [
  {
    key: 'shopee',
    icon: AVATAR_SHOPEE,
    platform: 'Shopee',
    description: 'Marketplace data encompasses crucial insights into product listings, consumer behavior, seller performance, and sales trends within their respective ecosystems.',
  },
  // {
  //   key: 'tokopedia',
  //   icon: AVATAR_TOKOPEDIA,
  //   platform: 'Tokopedia',
  //   description: '#',
  // },
];

export default {
  displayName,
  propTypes,
  defaultProps,
  onboardPlatform,
  onboardMarketplace,
};
