import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';
import TYPES from '../../types';

const { BindingApiService, setBindingApiReqHeaders } = apiBinding.getInstance();

export const bindWebsite = (value) => async (dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_WEBSITE,
    payload: { loadings: { bind: true } },
  });

  let response;
  try {
    response = await BindingApiService.post(
      `/binding/website`,
      { ...value, user_id: parseInt(getToken()?.id, 10) },
      { ...setBindingApiReqHeaders(getToken()) },
    );
    dispatch({
      type: TYPES.SET_BINDING_BIND_WEBSITE,
      payload: { loadings: { bind: false } },
    });
  } catch (err) {
    response = new Error("failed");
  };

  return response;
};

export const updateWebsiteDetails = (account={}) => async(dispatch) => {
  // dispatch({
  //   type: TYPES.SET_BINDING_BIND_WEBSITE,
  //   payload: { loadings: { bind: true } },
  // });

  let response;
  try {
    const { status, data } = await BindingApiService.put(
      `/account-configuration/update-website`,
      { ...account, id: account?.account_id },
      { ...setBindingApiReqHeaders(getToken()) },
    );

    if (status === 200) {
      response = Promise.resolve("Success");
      // dispatch({
      //   type: TYPES.SET_BINDING_BIND_WEBSITE,
      //   payload: { loadings: { bind: false } },
      // });
      // dispatch(getAccountConfig());
    }

    // dispatch({
    //   type: TYPES.SET_BINDING_BIND_WEBSITE,
    //   payload: { loadings: { bind: false } },
    // });
  } catch (err) {
    response = Promise.reject(new Error("failed"));
    // dispatch({
    //   type: TYPES.SET_BINDING_BIND_WEBSITE,
    //   payload: { loadings: { bind: false } },
    // });
  };
  return response;
};