import PropTypes from 'prop-types';
import stringConstants from '../../../constants-v1/string.constants';

const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `Filter`;
const propTypes = {
  title: pt.node,
  text: pt.node,
};
const defaultProps = {};
const getObjectivesByPlatform = (platform='') => {
  switch(platform) {
    case stringConstants.meta.key: return [
      { key: 'CONVERSIONS', label: 'Conversion' },
      { key: 'BRAND_AWARENESS', label: 'Awareness' },
      { key: 'LINK_CLICKS', label: 'Consideration' },
      { key: 'LEAD_GENERATION', label: 'Lead Generation' },
      { key: 'PRODUCT_CATALOG_SALES', label: 'Product Catalog Sales' },
      { key: 'MESSAGES', label: 'Messages' },
    ];

    case stringConstants.tiktok.key: return [
      { key: 'REACH', label: 'Reach' },
      { key: 'TRAFFIC', label: 'Traffic' },
      { key: 'VIDEO_VIEWS', label: 'Video Views' },
      { key: 'ENGAGEMENT', label: 'Engagement' },
      { key: 'WEB_CONVERSIONS', label: 'Website Conversion' },
      { key: 'PRODUCT_SALES', label: 'Product Sales' },
      { key: 'SHOP_PURCHASES', label: 'Shop Purchase' },
      // { key: 'community_interaction', label: 'Community Interaction' },
      // { key: 'app_promotion', label: 'App Promotion' },
      // { key: 'lead_generation', label: 'Lead Generation' },
      // { key: 'website_conversions', label: 'Website Conversions' },
      // { key: 'product_sales', label: 'Product Sales' },
    ];

    default: return [];
  }
}

export default { displayName, propTypes, defaultProps, getObjectivesByPlatform };