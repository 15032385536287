import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  account: { ...globalStateConstants.account.instagram },
  daterange: { ...globalStateConstants.daterange },
  filter: { ...globalStateConstants.filter.instagram },
  latest_sync: 0,

  data: {
    instagram: {
      overview_stats: { data: {}, error: null, loading: false },
      chart_line_growth: { data: [], error: null, loading: false },
      chart_line_profile_views: { data: [], error: null, loading: false },
      chart_line_reach_impressions: { data: [], error: null, loading: false },
      chart_line_like_comment_saved: { data: [], error: null, loading: false },
      chart_line_reach_by_post_media: { data: [], error: null, loading: false },
      chart_heatmap_followers_online: { data: [], error: null, loading: false },
      chart_bar_top_cities: { data: [], error: null, loading: false },
      chart_bar_age_gender: { data: {}, error: null, loading: false },
      chart_map_top_countries: { data: [], error: null, loading: false },
      preview_engagements: { data: [], error: null, loading: false },
      preview_reach: { data: [], error: null, loading: false },
      preview_stories: { data: [], error: null, loading: false },
    },
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_DASHBOARD_INSTAGRAM_VISITED: return {
      ...state, visited: true,
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_ACCOUNT: return {
      ...state, account: {
        // ...state.account,
        ...action.payload,
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_DATERANGE: return {
      ...state, daterange: {
        // ...state.daterange,
        ...action.payload,
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_FILTER: return {
      ...state, filter: {
        // ...state.filter,
        ...action.payload,
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_LATEST_SYNC: return {
      ...state, latest_sync: action.payload || 0,
    };

      // DATA GROUP

    case TYPES.SET_DASHBOARD_INSTAGRAM_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          overview_stats: {
            // ...state.data.instagram.overview_stats,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_BAR_TOP_CITIES: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_bar_top_cities: {
            // ...state.data.instagram.chart_bar_top_cities,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_BAR_AGE_GENDER: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_bar_age_gender: {
            // ...state.data.instagram.chart_bar_age_gender,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_HEATMAP_FOLLOWERS_ONLINE: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_heatmap_followers_online: {
            // ...state.data.instagram.chart_heatmap_followers_online,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_GROWTH: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_line_growth: {
            // ...state.data.instagram.chart_line_growth,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_PROFILE_VIEWS: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_line_profile_views: {
            // ...state.data.instagram.chart_line_profile_views,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_REACH_IMPRESSIONS: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_line_reach_impressions: {
            // ...state.data.instagram.chart_line_reach_impressions,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_LIKE_COMMENT_SAVED: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_line_like_comment_saved: {
            // ...state.data.instagram.chart_line_like_comment_saved,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_LINE_REACH_BY_POST_MEDIA: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_line_reach_by_post_media: {
            // ...state.data.instagram.chart_line_reach_by_post_media,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_CHART_MAP_TOP_COUNTRIES: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          chart_map_top_countries: {
            // ...state.data.instagram.chart_map_top_countries,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_PREVIEW_ENGAGEMENTS: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          preview_engagements: {
            // ...state.data.instagram.preview_engagements,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_PREVIEW_REACH: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          preview_reach: {
            // ...state.data.instagram.preview_reach,
            ...action.payload,
          },
        },
      },
    };

    case TYPES.SET_DASHBOARD_INSTAGRAM_PREVIEW_STORIES: return {
      ...state,
      data: {
        ...state.data,
        instagram: {
          ...state.data.instagram,
          preview_stories: {
            // ...state.data.instagram.preview_stories,
            ...action.payload,
          },
        },
      },
    };

      // RESET DATA REDUCER

    case TYPES.RESET_DASHBOARD_INSTAGRAM_DATA: return {
      ...state, data: initialState.data,
    };

    case TYPES.RESET_DASHBOARD_INSTAGRAM_REDUCER: return {
      ...initialState,
    };
  };
};