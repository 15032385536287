import { cur, flt, int, pct } from './__config';
export default {
  sem: {
    clicks: { key: 'clicks', lbl: 'Link Clicks', fmt: int, adv: 0 },
    conversions: { key: 'conversions', lbl: 'Conversion', fmt: int, adv: 0 },
    conversion_rates: { key: 'conversion_rates', lbl: 'Conversion Rate', fmt: pct, adv: 0 },
    conversion_values: { key: 'conversion_values', lbl: 'Conversion Value', fmt: cur, adv: 0 },
    cost_per_conversions: { key: 'cost_per_conversions', lbl: 'Cost / Conversion', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR', fmt: pct, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    impression_abs_top: { key: 'impression_abs_top', lbl: 'Impressions (Abs. Top)', fmt: pct, adv: 0 },
    impression_top: { key: 'impression_top', lbl: 'Impressions (Top)', fmt: pct, adv: 0 },
    roas: { key: 'roas', lbl: 'ROAS', fmt: flt, adv: 0 },
    spending: { key: 'spending', lbl: 'Spend', fmt: cur, adv: 0 },
    cost: { key: 'spending', lbl: 'Cost', fmt: cur, adv: 0 },
    ad_group: { key: 'ad_group', lbl: 'Ad Group' },
    ad_strength: { key: 'ad_strength', lbl: 'Ad Strength' },
    match_type: { key: 'match_type', lbl: 'Match Type' }
  },
  gdn: {
    clicks: { key: 'clicks', lbl: 'Link Clicks', fmt: int, adv: 0 },
    conversions: { key: 'conversions', lbl: 'Conversion', fmt: int, adv: 0 },
    conversion_rates: { key: 'conversion_rates', lbl: 'Conversion Rate', fmt: pct, adv: 0 },
    conversion_values: { key: 'conversion_values', lbl: 'Conversion Value', fmt: cur, adv: 0 },
    cost_per_conversions: { key: 'cost_per_conversions', lbl: 'Cost / Conversion', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR', fmt: pct, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    roas: { key: 'roas', lbl: 'ROAS', fmt: flt, adv: 0 },
    spending: { key: 'spending', lbl: 'Spend', fmt: cur, adv: 0 },
    cost: { key: 'spending', lbl: 'Cost', fmt: cur, adv: 0 },
    views: { key: 'views', lbl: 'Views', fmt: int, adv: 0 },
    campaign: { key: 'campaign', lbl: 'Campaign' },
    ad_group: { key: 'ad_group', lbl: 'Ad Group' },
    ad_group_status: { key: 'ad_group_status', lbl: 'Ad Group Status' }
  },
  youtube: {
    clicks: { key: 'clicks', lbl: 'Link Clicks', fmt: int, adv: 0 },
    conversions: { key: 'conversions', lbl: 'Conversion', fmt: int, adv: 0 },
    conversion_rates: { key: 'conversion_rates', lbl: 'Conversion Rate', fmt: pct, adv: 0 },
    conversion_values: { key: 'conversion_values', lbl: 'Conversion Value', fmt: cur, adv: 0 },
    cost_per_conversions: { key: 'cost_per_conversions', lbl: 'Cost / Conversion', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    cpv: { key: 'cpv', lbl: 'CPV', fmt: cur, adv: 1 },
    engagements: { key: 'engagements', lbl: 'Engagement', fmt: int, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    roas: { key: 'roas', lbl: 'ROAS', fmt: flt, adv: 0 },
    spending: { key: 'spending', lbl: 'Spend', fmt: cur, adv: 0 },
    cost: { key: 'spending', lbl: 'Cost', fmt: cur, adv: 0 },
    views: { key: 'views', lbl: 'Views', fmt: int, adv: 0 },
    view_rates: { key: 'view_rates', lbl: 'View Rate', fmt: pct, adv: 0 },
    campaign: { key: 'campaign', lbl: 'Campaign' },
    type: { key: 'type', lbl: 'Type' },
    ad_group: { key: 'ad_group', lbl: 'Ad Group' }
  },
  discovery: {
    clicks: { key: 'clicks', lbl: 'Link Clicks', fmt: int, adv: 0 },
    conversions: { key: 'conversions', lbl: 'Conversion', fmt: int, adv: 0 },
    conversion_rates: { key: 'conversion_rates', lbl: 'Conversion Rate', fmt: pct, adv: 0 },
    conversion_values: { key: 'conversion_values', lbl: 'Conversion Value', fmt: cur, adv: 0 },
    cost_per_conversions: { key: 'cost_per_conversions', lbl: 'Cost / Conversion', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR', fmt: pct, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    roas: { key: 'roas', lbl: 'ROAS', fmt: flt, adv: 0 },
    spending: { key: 'spending', lbl: 'Spend', fmt: cur, adv: 0 },
    cost: { key: 'spending', lbl: 'Cost', fmt: cur, adv: 0 },
    views: { key: 'views', lbl: 'Views', fmt: int, adv: 0 }
  },
  performance_max: {
    clicks: { key: 'clicks', lbl: 'Link Clicks', fmt: int, adv: 0 },
    conversions: { key: 'conversions', lbl: 'Conversion', fmt: int, adv: 0 },
    conversion_rates: { key: 'conversion_rates', lbl: 'Conversion Rate', fmt: pct, adv: 0 },
    conversion_values: { key: 'conversion_values', lbl: 'Conversion Value', fmt: cur, adv: 0 },
    cost_per_conversions: { key: 'cost_per_conversions', lbl: 'Cost / Conversion', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR', fmt: pct, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    roas: { key: 'roas', lbl: 'ROAS', fmt: flt, adv: 0 },
    spending: { key: 'spending', lbl: 'Spend', fmt: cur, adv: 0 },
    cost: { key: 'spending', lbl: 'Cost', fmt: cur, adv: 0 }
  },
  smart_campaign: {
    clicks: { key: 'clicks', lbl: 'Link Clicks', fmt: int, adv: 0 },
    conversions: { key: 'conversions', lbl: 'Conversion', fmt: int, adv: 0 },
    conversion_rates: { key: 'conversion_rates', lbl: 'Conversion Rate', fmt: pct, adv: 0 },
    conversion_values: { key: 'conversion_values', lbl: 'Conversion Value', fmt: cur, adv: 0 },
    cost_per_conversions: { key: 'cost_per_conversions', lbl: 'Cost / Conversion', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR', fmt: pct, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    roas: { key: 'roas', lbl: 'ROAS', fmt: flt, adv: 0 },
    spending: { key: 'spending', lbl: 'Spend', fmt: cur, adv: 0 },
    cost: { key: 'spending', lbl: 'Cost', fmt: cur, adv: 0 }
  },
  app: {
    app_install: { key: 'app_install', lbl: 'App Install', fmt: int, adv: 0 },
    cost_per_install: { key: 'cost_per_install', lbl: 'Cost / Install', fmt: cur, adv: 1 },
    clicks: { key: 'clicks', lbl: 'Link Clicks', fmt: int, adv: 0 },
    conversions: { key: 'conversions', lbl: 'Conversion', fmt: int, adv: 0 },
    conversion_rates: { key: 'conversion_rates', lbl: 'Conversion Rate', fmt: pct, adv: 0 },
    conversion_values: { key: 'conversion_values', lbl: 'Conversion Value', fmt: cur, adv: 0 },
    cost_per_conversions: { key: 'cost_per_conversions', lbl: 'Cost / Conversion', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR', fmt: pct, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    roas: { key: 'roas', lbl: 'ROAS', fmt: flt, adv: 0 },
    spending: { key: 'spending', lbl: 'Spend', fmt: cur, adv: 0 },
    cost: { key: 'spending', lbl: 'Cost', fmt: cur, adv: 0 }
  },
};
