import path from './path';

import CustomReportPublic from '../containers/CustomReportPublic/CustomReportPublic.container';
import EmailVerivication from '../containers/EmailVerification/EmailVerification.container';
import PrivacyPolicy from '../containers/PrivacyPolicy/PrivacyPolicy.container';
import TermsOfServices from '../containers/TermsOfServices/TermsOfServices.container';

export const LANDINGPUB_ROUTE = [
  {
    id: path.landPubPath.customReportPublic,
    path: path.landPubPath.customReportPublic,
    component: CustomReportPublic,
    exact: true,
  },
  {
    id: path.landPubPath.emailVerivication,
    path: path.landPubPath.emailVerivication,
    component: EmailVerivication,
    exact: true,
  },
  {
    id: path.landPubPath.privacyPolicy,
    path: path.landPubPath.privacyPolicy,
    component: PrivacyPolicy,
    exact: true,
  },
  {
    id: path.landPubPath.termsOfServices,
    path: path.landPubPath.termsOfServices,
    component: TermsOfServices,
    exact: true,
  }
];
