import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import { initFacebookSdk } from './services/facebook.service';
import withClearCache from './cache';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './containers';

import './index.less';
import './assets/fontello/css/fontello.css';
 
const pckJson = require('../package.json');
const debugON = true;
Sentry.init({
  dsn: "https://3ac54358471e4b88bc3af446471dfed4@o1235887.ingest.sentry.io/6385972",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://stag-pro.bdd.ai',
        'https://preprod-pro.bdd.ai',
        'https://app.bdd.ai',
      ],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [''],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.ENV,
  release: `bddai-pro@${pckJson.version}`,
});

const ClearCacheComponent = withClearCache(App);

initFacebookSdk().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ClearCacheComponent />
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

serviceWorkerRegistration.register();
