import { connect } from 'react-redux';
import LandingUser from './LandingUser.component';

import {
  notificationError,
  loginMeta,
  loginGoogle,
  loginGoogleSuccess,
  loginEmail,
  registerUser,
  resetPassword,
} from '../../redux-v1/actions/auth/__common.actions';
import {
  getAccountConfig,
  getLastActivityUser,
  getUserOnboarding,
} from '../../redux-v1/actions/user/__common.actions';

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = {
  notificationError,
  loginMeta,
  loginGoogle,
  loginGoogleSuccess,
  loginEmail,
  getAccountConfig,
  getLastActivityUser,
  registerUser,
  resetPassword,
  getUserOnboarding,
};


export default connect(mapStateToProps, mapDispatchToProps)(LandingUser);
