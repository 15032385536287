import introJs from 'intro.js';
import { get } from 'lodash';

// Function: introduce
const defaultIntroduceArg = {
  tooltipClass: 'intro-guide',
  disableInteraction: true,
  showBullets: false,
  steps: [{
    element: null,
    title: 'Welcome!',
    intro: 'Go ahead and explore this feature further!',
    position: 'bottom',
  }],
  oncomplete: () => null,
}
export const introduce = (config=defaultIntroduceArg) => {
  const config_ = defaultIntroduceArg;

  introJs().setOptions({
    tooltipClass: get(config, 'tooltipClass') ? 
      get(config, 'tooltipClass') 
      : 
      config_.tooltipClass,

    disableInteraction: get(config, 'disableInteraction') ? 
      get(config, 'disableInteraction') 
      : 
      config_.disableInteraction,

    showBullets: get(config, 'showBullets') ? 
      get(config, 'showBullets') 
      : 
      config_.showBullets,

    steps: get(config, 'steps') ? 
      get(config, 'steps') 
      : 
      config_.steps,

  }).oncomplete(get(config, 'oncomplete') || config_.oncomplete).start()
} 

// Function: isRouteVisited
const defaultIsRouteVisitedArg = "/";
export const isRouteVisited = (route=defaultIsRouteVisitedArg) => {
  const visitedRoutes = JSON.parse(localStorage.getItem('VISITED_ROUTES'));
  const foundRoute = visitedRoutes.find(item => item === route);
  const ans = foundRoute !== undefined;
  return ans
}

// Function: addVisitedRoute
const defaultAddVisitedRouteArg = "/";
export const addVisitedRoute = (route=defaultAddVisitedRouteArg) => {
  let visitedRoutes = JSON.parse(localStorage.getItem('VISITED_ROUTES'));
  if (!visitedRoutes) visitedRoutes = [];
  if (!visitedRoutes.find(item => item === route) && route !== "/") visitedRoutes.push(route)
  localStorage.setItem('VISITED_ROUTES', JSON.stringify(visitedRoutes));
}