import React from 'react';
import get from 'lodash/get';
import { Card, Col, Typography, Form, Input, Button, Space, Row } from 'antd';
import { formatAvatar } from '../../utils-v1/string.utils';
import ModalConfirmation from '../../components/ModalConfirmation/ModalConfirmation.component';
import { ICON_SUCCESS } from '../../assets-v1/prev-assets';

import { DashboardLayout } from '../../components/layouts-backup';

import config from './Profile.component.config';
import './Profile.component.styles.less';

const { Paragraph, Title, Text } = Typography;

class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inOnEdit: false,
      confirmVisible: false,
    };
    console.log(props);
  }

  profileForm = React.createRef();

  // _onSubmitProfile = (value) => {
  //   if (this.state.inOnEdit) {
  //     this.props
  //       .updateUser(value)
  //       .then((resp) => {
  //         this.setState({
  //           confirmVisible: true,
  //         });
  //       })
  //       .catch((err) => {
  //         if (err === 'INVALID_PASSWORD')
  //           this.profileForm.current.setFields([
  //             {
  //               name: 'old_password',
  //               errors: ['Current Password is not valid'],
  //             },
  //           ]);
  //       });
  //   }
  // };

  _altOnSubmitProfile = (values={}) => {
    if (this.state.inOnEdit) {
      this.props.updateUser(values)
        .then((resp) => {
          this.props.getUserOnboarding().then(() => {
            this.setState({ confirmVisible: true });
            this.profileForm.current.resetFields();
          });
        })
        .catch((err) => {
          this.profileForm.current.setFields([
            { name: 'old_password', errors: [`${err}`]}
          ]);
        })
    }
  };

  _onCloseModal = () => {
    this.setState({ confirmVisible: false });
  };

  _onConfirmModal = () => {
    this.setState({
      confirmVisible: false,
      inOnEdit: false,
    });
  };

  renderBtnForm = () => {
    let comp = (
      <Button
        id="EditBtn"
        className="action-btn"
        type="primary"
        htmlType="button"
        onClick={(e) => {
          e.preventDefault();
          this.setState({ inOnEdit: true });
        }}
      >
        Edit
      </Button>
    );

    if (this.state.inOnEdit) comp = (<Row gutter={[4, 4]}>
      <Col sm={24} md={12} lg={12}>
        <Button
          id="CancelEditBtn"
          className="action-btn"
          type="default"
          htmlType="button"
          style={{ marginRight: 16 }}
          onClick={(e) => {
            e.preventDefault();
            this.profileForm.current.resetFields();
            this.setState({ inOnEdit: false });
          }}
        >
          Cancel
        </Button>
      </Col>
      <Col sm={24} md={12} lg={12}>
        <Button
          loading={this.props?.loading}
          type="primary"
          htmlType="submit"
          className="action-btn"
          onClick={() => {
            const values = { ...this.profileForm.current.getFieldsValue(), email: this.props?.data?.email };
            this._altOnSubmitProfile(values);
          }}
        >
          Save
        </Button>
      </Col>
    </Row>);

    return <div className="action-btn-wrapper">{comp}</div>;
  };

  renderModalConfirmation = () => (
    <ModalConfirmation
      visible={this.state.confirmVisible}
      config={{
        icon: ICON_SUCCESS,
        title: 'Sukses',
        description: 'Update Profile Berhasil',
        btnConfirmText: 'OK',
      }}
      onClose={this._onCloseModal}
      onConfirm={this._onConfirmModal}
    />
  );

  constructDashboardProps = () => ({
    accountSelectionProps: null,
    filterProps: null,
    history: this.props.history,
    location: this.props.location,
    rangePickerProps: null,
    syncDataProps: null,
    title: this.state.inOnEdit ? 'Edit User Profile' : 'User Profile',
  });

  render() {
    return (
      <DashboardLayout { ...this.constructDashboardProps() }>
        <div id="ProfilePage">
          <div
            style={{
              background: 'linear-gradient(90deg, #4CB8C4 0%, #3CD3AD 100%)',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              minHeight: 200,
            }}
          ></div>
          <div
            style={{
              backgroundColor: 'white',
              borderBottom: '1px solid #D0D5DD',
              borderLeft: '1px solid #D0D5DD',
              borderRight: '1px solid #D0D5DD',
              minHeight: 174,
              paddingBottom: 20,
              paddingLeft: 30,
              paddingTop: 80,
            }}
          >
            <div className="avatar-wrapper">
              <div className="avatar-content">{formatAvatar(this.props?.data?.name)?.toUpperCase()}</div>
            </div>
            <br />
            <Text style={{ fontSize: '2rem', fontWeight: 600 }}>{this.props?.data?.name}</Text>
            <br />
            <Text>{this.props?.data?.email}</Text>
          </div>
          <div
            style={{
              backgroundColor: 'white',
              borderBottom: '1px solid #D0D5DD',
              borderLeft: '1px solid #D0D5DD',
              borderRight: '1px solid #D0D5DD',
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              minHeight: 200,
              padding: '20px 30px',
            }}
          >
            {this.props?.data?.name && (
              <Form
                ref={this.profileForm}
                className="profile-form"
                layout="vertical"
                name="profile"
                initialValues={this.props?.data}
                // onFinish={(value) => console.log(value)}
              >
                <Row gutter={[16, 16]}>
                  <Col sm={24} md={14} lg={14}>
                    <Title level={3}>Basic Information</Title>
                    <Row gutter={[16, 16]}>
                      <Col sm={24} md={12} lg={12}>
                        <Form.Item
                          label="Full Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Full Name is required',
                            },
                          ]}
                        >
                          <Input
                            disabled={!this.state.inOnEdit}
                            placeholder="Full Name"
                            autoComplete="off"
                            className="input-text"
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={24} md={12} lg={12}>
                        <Form.Item
                          label="Phone Number"
                          name="phone"
                        >
                          <Input
                            type="number"
                            disabled={!this.state.inOnEdit}
                            placeholder="Phone Number"
                            autoComplete="off"
                            className="input-text"
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={24} md={12} lg={12}>
                        <Form.Item
                          label="Company"
                          name="company"
                        >
                          <Input
                            disabled={!this.state.inOnEdit}
                            placeholder="Company"
                            autoComplete="off"
                            className="input-text"
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={24} md={12} lg={12}>
                        <Form.Item
                          label="Job Title"
                          name="job_title"
                        >
                          <Input
                            disabled={!this.state.inOnEdit}
                            placeholder="Job Title"
                            autoComplete="off"
                            className="input-text"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col sm={24} md={8} lg={8}>
                    <Form.Item
                      hidden
                      label="e-mail"
                      name="email"
                    >
                      <Input
                        disabled
                        autoComplete="off"
                        className="input-text"
                      />
                    </Form.Item>
                  </Col> */}


                  <Col sm={24} md={10} lg={10}>
                    <Title level={3}>Change Password</Title>
                    <Row gutter={[16, 16]}>
                      <Col sm={24} md={24} lg={24}>
                        <Form.Item
                          label="Current Password"
                          name="old_password"
                          dependencies={['password']}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value && getFieldValue('password')) {
                                  return Promise.reject(
                                    new Error('Current Password is required'),
                                  );
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            disabled={!this.state.inOnEdit}
                            autoComplete="off"
                            placeholder="Current Password"
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={24} md={24} lg={24}>
                        <Form.Item
                          label="New Password"
                          name="password"
                          dependencies={['old_password']}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                let result;
                                if (!value && getFieldValue('old_password')) {
                                  result = Promise.reject(
                                    new Error('New Password is required'),
                                  );
                                } else if (
                                  getFieldValue('old_password') &&
                                  getFieldValue('old_password') === value
                                ) {
                                  result = Promise.reject(
                                    new Error(
                                      "New Password shouldn't equal with Current Password",
                                    ),
                                  );
                                } else {
                                  result = Promise.resolve();
                                }

                                return result;
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            disabled={!this.state.inOnEdit}
                            autoComplete="off"
                            placeholder="New Password"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={24} md={24} lg={24}>
                    <span style={{ float: 'right' }}>
                      {this.renderBtnForm()}
                    </span>
                  </Col>
                </Row>

                {/* <Form.Item style={{ textAlign: 'right', paddingRight: 30 }}>
                  {this.renderBtnForm()}
                </Form.Item> */}
              </Form>
            )}
          </div>
        </div>
        {this.renderModalConfirmation()}
      </DashboardLayout>
    );
  }
}

Profile.displayName = config.displayName;
Profile.defaultProps = config.defaultProps;
Profile.propTypes = config.propTypes;

export default Profile;
