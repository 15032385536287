import React from 'react';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  notification,
  Radio,
  Space,
  Switch,
  Typography,
} from 'antd';
import axios from 'axios';

import TYPES from '../../redux-v1/types';
import { generateDashboardProps, onMountFunction, renderEmptyState } from '../../utils-v1/platform-page.utils';
import { generateRequestParamsByFormat } from '../../utils-v1/request.utils';
import stringConstants from '../../constants-v1/string.constants';

import { DashboardLayoutGS } from '../../components/layouts-backup';

import Ga4Page from './Ga4Page/Ga4Page.component';
import UaPage from './UaPage/UaPage.component';

import config from './DashboardGoogleAnalytics.component.config';
import './DashboardGoogleAnalytics.component.styles.less';

const { Title, Text, Paragraph } = Typography;
const { key, name, alt, PLATFORM, useFilter } = stringConstants.googleAnalytics;
let source;

class DashboardGoogleAnalytics extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uiLoading: false,
    };
    source = axios.CancelToken.source();
  };

  componentDidMount() {
    onMountFunction(this, key);
  };

  componentWillUnmount() {
    if (source) { source.cancel('DashboardGoogleAnalytics axios cancel due to unmounting') };
  };

  fetchData = (format='') => {
    const reqParams = generateRequestParamsByFormat(key, this.props, format, { cancelToken: source.token });
    this.setState({ uiLoading: true });
    this.props.getAccountConfig()
      .finally(() => {
        this.setState({ uiLoading: false });
        let acctType;
        switch (this.props.account.details.type) {
          case 'ua': acctType = 'UA'; break;
          default: acctType = 'GA4'; break;
        };
        const reqList = config.requestConfig.filter(item => item.type.includes(acctType))
          .map(item => this.props.getPlatformData(item.type, reqParams[item.reqparamsformat]));
        if (this.props.account.account_id) {
          Promise.all(reqList)
            .then(res => console.log({ res }))
            .catch(err => console.log({ err }))
            // .finally(() => localStorage.setItem(`DASHBOARD_${PLATFORM}_DATA`, JSON.stringify(this.props.data)))
        };
      })
  };

  renderPageByAccountType = () => {
    const panelProps = { ...this.props, ...this.state };
    switch(this.props?.account?.details?.type) {
      case 'ga4': return <Ga4Page { ...panelProps } />;
      case 'ua': return <UaPage { ...panelProps } />;
      default: return <>
        <div style={{ borderRadius: 10 }}>
          <Paragraph style={{ marginTop: 30 }}>Please select an account type first!</Paragraph>
        </div>
      </>
    };
  };

  render() {
    return (
      <DashboardLayoutGS { ...generateDashboardProps(this, key, useFilter) }>
        <div id="DashboardGoogleAnalytics">
          {!this.props.account?.config_id || this.props.account?.details?.type === 'general' || !this.props.account?.details?.type ? renderEmptyState(this, key) : this.renderPageByAccountType()}
        </div>
      </DashboardLayoutGS>
    );
  }
}

DashboardGoogleAnalytics.displayName = config.displayName;
DashboardGoogleAnalytics.defaultProps = config.defaultProps;
DashboardGoogleAnalytics.propTypes = config.propTypes;

export default DashboardGoogleAnalytics;
