import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import {
  Button,
  Collapse,
  Image,
  Layout,
  Space,
  Typography,
  Avatar,
  Dropdown,
  Card,
  Input,
  Modal,
} from 'antd';
import { useHistory } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';

import {
  getSyncDataModalContent,
  renderSyncDataCutoffInfo,
  renderSyncDataError,
  renderSyncDataWarning,
} from '../../../utils-v1/sync-data.utils';
import {
  ICON_REFRESH_WHITE,
} from '../../../assets-v1/figma-icons';

import animations from '../../../utils-v1/animations';
import { formatAvatar } from '../../../utils-v1/string.utils';

import globalStateConstants from '../../../constants-v1/global-state.constants';
import stringConstants from '../../../constants-v1/string.constants';

import HeaderToolbar from './HeaderToolbar/HeaderToolbar.component';
import SideMenu from './SideMenu/SideMenu.component';
import { AccountSelectionGS, FilterGS } from '../../atoms';

import config from './DashboardLayout.component.config';
import './DashboardLayout.component.styles.less';

const { Content, Header } = Layout;
const { Title } = Typography;
const { Panel } = Collapse;

const DashboardLayout = ({
  accountSelectionProps = {
    defaultState: { selectedAccounts: [{ ...globalStateConstants.account.demo }] },
    extra: null,
    multiple: false,
    onApply: (accounts={}) => console.log({ accounts }),
    platformKey: stringConstants.demo.key,
    platformName: stringConstants.demo.name,
  } || null,
  // badgeProps = { count: 0 },
  children,
  filterProps = {
    defaultState: { ...globalStateConstants.filter.demo },
    onApply: (filters={}) => console.log({ filters }),
    platformKey: stringConstants.demo.key,
  } || null,
  history = { push: () => null, replace: () => null },
  location = { pathname: '' },
  notifications = {
    budgetTracker: [],
    googleAds: [],
    googleAnalytics: [],
    instagram: [],
    marketplace: [],
    meta: [],
    tiktok: [],
    website: [],
  },
  rangePickerProps = {
    defaultState: { ...globalStateConstants.daterange },
    onApply: (daterange={}) => console.log({ daterange }),
  } || null,
  syncDataProps = {
    account: {},
    latest_sync: 0,
    loading: false,
    onApply: () => null,
    synchronizingAccounts: [],
  } || null,
  title,
  ...contentProps
}) => {
  const [openSideMenu, setOpenSideMenu] = useState(false);

  const fullName = get({}, 'name', 'BDDAI');
  const firstName = fullName.split(' ')[0];
  const avatar = formatAvatar(firstName);
  // const history = useHistory();
  const [state, setState] = React.useState({
    visibleDrop: false,
    visibleSyncDataModal: false,
  });

  const renderSyncDataModal = () => (
    <Modal
      onCancel={() => {
        setState({ ...state, visibleSyncDataModal: false })
      }}
      onOk={() => {
        if (typeof syncDataProps?.onApply === 'function') syncDataProps?.onApply();
        setState({ ...state, visibleSyncDataModal: false });
      }}
      open={state.visibleSyncDataModal}
      title={<Title level={3}>Sync Data</Title>}
    >
      {getSyncDataModalContent(syncDataProps)}
    </Modal>
  );

  const countNotifications = () => {
    let notificationsArray = [];
    Object.entries(notifications).forEach(entry => {
      const [key, val] = [entry[0], entry[1]];
      notificationsArray = [ ...notificationsArray, ...val ];
    });
    return notificationsArray.length;
  };

  const constructHeaderToolbarProps = () => ({
    accountBoxProps: { history, location },
    accountSelectionProps,
    badgeProps: {
      count: countNotifications(),
      notifications,
    },
    filterProps,
    history,
    isMobileOnly,
    rangePickerProps,
    syncDataProps,
    title,
  });
  const constructSideMenuProps = () => ({
    closeMenu: () => setOpenSideMenu(false),
    history,
    location,
  });

  const syncDataButtonConfig = {
    children: <>&nbsp;&nbsp;Sync Data</>,
    id: 'syncDataButton',
    icon: <Image src={ICON_REFRESH_WHITE} alt="icon_refresh_white" preview={false} />,
    loading: syncDataProps?.loading,
    onClick: () => setState({ ...state, visibleSyncDataModal: true }),
    type: 'primary',
  };

  return (
    <Layout id="document-layout">
      <Layout id="dashboard-layout">
        <HeaderToolbar { ...constructHeaderToolbarProps() } />
        <Content style={{ animation: animations.popIn, ...contentProps?.style }} { ...contentProps }>
          <Space align="center" direction="horizontal" size={10} style={{ paddingBottom: 25 }} hidden={location?.pathname?.includes('binding') || location?.pathname?.includes('dashboard')}>
            {syncDataProps ? <Button { ...syncDataButtonConfig } /> : null}
            {accountSelectionProps ? <AccountSelectionGS { ...accountSelectionProps } /> : null}
            {filterProps ? <FilterGS { ...filterProps } /> : null}
          </Space>
          {syncDataProps?.account?.details?.sync ? renderSyncDataCutoffInfo(syncDataProps) : null}
          {syncDataProps ? renderSyncDataError(syncDataProps) : null}
          {syncDataProps ? renderSyncDataWarning(syncDataProps) : null}
          {children}
        </Content>
        {syncDataProps ? renderSyncDataModal() : null}
      </Layout>

      <SlidingPane
        className="sidebar-mobile"
        hideHeader
        width="324px"
        from="left"
        isOpen={openSideMenu}
        onRequestClose={() => setOpenSideMenu(false)}
      >
        <div><SideMenu { ...constructSideMenuProps() } /></div>
      </SlidingPane>
    </Layout>
  );
};

DashboardLayout.displayName = config.displayName;
DashboardLayout.defaultProps = config.defaultProps;
DashboardLayout.propTypes = config.propTypes;

export default DashboardLayout;
