import { addDays, addMonths, addWeeks, addYears, format } from 'date-fns';

const DAYS = {
  SHORT: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  LONG: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
};

const MONTHS = {
  SHORT: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  LONG: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

const TODAY = {
  RAW: new Date(),
  STRING: format(new Date(), 'yyyy-MM-dd'),
};

const YESTERDAY = {
  RAW: new Date(addDays(new Date(), -1)),
  STRING: format(new Date(addDays(new Date(), -1)), 'yyyy-MM-dd'),
};

const LAST_7_DAYS = {
  RAW: new Date(addDays(new Date(), -1)),
  STRINGSTART: format(new Date(addDays(new Date(), -7)), 'yyyy-MM-dd'),
  STRINGEND: format(new Date(addDays(new Date(), -1)), 'yyyy-MM-dd'),
};

const LAST_14_DAYS = {
  RAW: new Date(addDays(new Date(), -1)),
  STRINGSTART: format(new Date(addDays(new Date(), -14)), 'yyyy-MM-dd'),
  STRINGEND: format(new Date(addDays(new Date(), -8)), 'yyyy-MM-dd'),
};

const LAST_WEEK = {
  RAW: new Date(addWeeks(new Date(), -1)),
  STRING: format(new Date(addWeeks(new Date(), -1)), 'yyyy-MM-dd'),
};

const LAST_MONTH = {
  RAW: new Date(addMonths(new Date(), -1)),
  STRING: format(new Date(addMonths(new Date(), -1)), 'yyyy-MM-dd'),
};

const LAST_YEAR = {
  RAW: new Date(addYears(new Date(), -1)),
  STRING: format(new Date(addYears(new Date(), -1)), 'yyyy-MM-dd'),
};

export default {
  DAYS,
  MONTHS,
  TODAY,
  YESTERDAY,
  LAST_7_DAYS,
  LAST_14_DAYS,
  LAST_WEEK,
  LAST_MONTH,
  LAST_YEAR,
};
