import React from 'react';
import { Button, Card, Spin, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Slider from 'react-slick';
import { random } from 'lodash';
import Stats from '../Stats/Stats.component';

import config from './CarouselContentPreview.component.config';
import './CarouselContentPreview.component.styles.less';

const { Paragraph, Text, Title } = Typography;

class CarouselContentPreview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.state = {};
  };

  componentDidMount() {
    // console.log(`CarouselContentPreview is mounted...`);
  };

  componentWillUnmount() {
    // console.log(`CarouselContentPreview is unmounted...`);
  };

  next() {
    this.slider.slickNext();
  };
  prev() {
    this.slider.slickPrev();
  };

  render() {
    const configureStatsList = (statsList=[]) => {
      let output = [];
      if (Array.isArray(statsList)) {
        output = statsList?.length % 2 !== 0 ? [ ...statsList, {} ] : statsList;
      };
      return output;
    };

    const generateContentPreviewStatsListCardProps = (datum={}) => ({
      title: datum?.title,
      style: {
        margin: 'unset',
        boxShadow: 'unset',
        borderBottom: 'none',
        borderRadius: 0,
        marginBottom: 15,
      },
    });

    const renderCover = (datum={}) => {
      let cover;
      if(!datum?.src || !datum?.src?.data || datum?.src?.response === 'error') {
        cover = (<div style={{ height: 560, textAlign: 'center', padding: 50, paddingTop: 250 }}>
          {datum?.src?.data || `No Previews Available`}
        </div>);
      } else {
        let __htmlConfig = null;
        if (typeof datum?.src?.data === 'object') {
          __htmlConfig = [ datum?.src?.data ];
        };
        if (typeof datum?.src?.data === 'string' && datum?.src?.data?.toLowerCase()?.includes('iframe')) {
          __htmlConfig = datum?.src?.data;
        } else if ((datum?.type || '').toLowerCase() === 'video') {
          __htmlConfig = `<iframe style="height: 100%" src=${datum?.src?.data} title:"vid" />`;
        } else {
          __htmlConfig = `<div style="width: 100%; height: 300px; background-image: url('${datum?.src?.data}'); background-position: center; background-size: contain; background-repeat: no-repeat; margin: auto;" />`;
        };
        const coverProps = {
          id: 'carouselMedia',
          style: { fontSize: '0.7rem', height: /* '400px' || */ 'auto' },
          ...(typeof datum?.src?.data !== 'object' && { dangerouslySetInnerHTML: { __html: __htmlConfig } }),
          ...(typeof datum?.src?.data === 'object' && { children: datum?.src?.data }),
        };
        cover = (<div key={random()} { ...coverProps } />);
      };
      return cover;
    };

    const settings = {
      dots: true,
      infinite: false,
      ref: c => { this.slider = c },
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div id="CarouselContentPreview">
        <Paragraph hidden={!this.props?.loading} style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph>
        <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <div hidden={this.props?.loading}>
          {Array.isArray(this.props.data) && this.props.data?.length > 0 ? <Slider { ...settings }>
            {this.props.data.map((item, i) => (
              <div hidden={this.props?.loading} key={`carousel-${i+1}`}>
                <Card className="content-preview-card" cover={[renderCover(item)]}>
                  <Card className="content-preview-stats-list" { ...generateContentPreviewStatsListCardProps(item) }>
                    <Stats.List columns={2} statsList={configureStatsList(item?.statsList)} />
                  </Card>
                </Card>
              </div>
            ))}
          </Slider> : <div style={{ height: 200, textAlign: 'center' }}>
            <Paragraph style={{ marginTop: 70 }}>No Data</Paragraph>
          </div>}
          <div className="btn-carousel" hidden={!this.props.data || (Array.isArray(this.props.data) && this.props.data?.length === 0)}>
            <Button
              className="carousel-prev"
              type="primary"
              shape="circle"
              onClick={this.prev}
              icon={<LeftOutlined />}
            />
            <Button
              className="carousel-next"
              type="primary"
              shape="circle"
              onClick={this.next}
              icon={<RightOutlined />}
            />
          </div>
        </div>
      </div>
    )
  }
}

CarouselContentPreview.displayName = config.displayName;
CarouselContentPreview.propTypes = config.propTypes;
CarouselContentPreview.defaultProps = config.defaultProps;

export default CarouselContentPreview;