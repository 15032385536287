import { connect } from 'react-redux';
import CustomReport from './CustomReport.component';

import {
  getAccountConfig,
  getUserData,
  updateCoachmarkLocal,
} from '../../redux-v1/actions/user/__common.actions';
import {
  getNotifications,
} from '../../redux-v1/actions/dashboard/budget-tracker.actions';
import {
  getSavedReports,
  updateExistingReportConfig,
  setOpenedReport,
  getConfigList,
  getMetrics,
  getReportDetail,
  deleteReport,
  resetOpenedReport,
} from '../../redux-v1/actions/dashboard/custom-report.actions';

const mapStateToProps = (state) => ({
  accountsByPlatform: state.user.accounts_by_platform,
  ...state.dashboard.customReport,
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});

const mapDispatchToProps = {
  getAccountConfig,
  getUserData,
  updateCoachmarkLocal,

  getNotifications,

  getSavedReports,
  updateExistingReportConfig,
  getMetrics,
  setOpenedReport,
  getConfigList,
  getReportDetail,
  deleteReport,
  resetOpenedReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomReport);
