import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import {
  Col,
  Image,
  Layout,
  Menu,
  Modal,
  Row,
  Typography,
} from 'antd';

import { BDD_LOGIN_FORM_LOGO } from '../../../../assets-v1/images';
import { ICON_HOME, ICON_PLATFORM, ICON_BINDING, ICON_HELP, ICON_BUDGET, ICON_CR } from '../../../../assets-v1/figma-icons';
import {
  ICON_CLOSE,
} from '../../../../assets-v1/prev-assets';
import path from '../../../../navigation/path';

import config from './SideMenu.component.config';
import './SideMenu.component.styles.less';

const { confirm } = Modal;
const { Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

const SIDE_MENU_TOP = {
  summary: {
    title: 'Summary',
    icon: <ICON_HOME />,
    url: path.dashboardPath.summary,
  },
  platforms: {
    title: 'Platform Data',
    icon: <ICON_PLATFORM />,
    submenu: {
      meta_ads: {
        title: 'Meta Ads',
        // icon: 'meta',
        url: path.dashboardPath.meta,
      },
      tiktok_ads: {
        title: 'Tiktok Ads',
        // icon: 'tiktok',
        url: path.dashboardPath.tiktok,
      },
      google_ads: {
        title: 'Google Ads',
        // icon: 'google-ads',
        url: path.dashboardPath.googleAds,
      },
      google_analytics: {
        title: 'Google Analytics',
        // icon: 'google-analytics',
        url: path.dashboardPath.googleAnalytics,
      },
      instagram_insights: {
        title: 'Instagram Insights',
        // icon: 'instagram',
        url: path.dashboardPath.instagram,
      },
      website_data: {
        title: 'Website Data',
        // icon: 'website',
        url: path.dashboardPath.website,
      },
      marketplace_data: {
        title: 'Marketplace Data',
        // icon: 'marketplace',
        url: path.dashboardPath.marketplace,
      },
    },
  },
  budgetTracker: {
    title: 'Budget Tracker',
    icon: <ICON_BUDGET />,
    url: path.dashboardPath.budgetTracker,
  },
  customReport: {
    title: 'Custom Report',
    icon: <ICON_CR />,
    url: path.dashboardPath.customReport,
  },
};

const SIDE_MENU_BOTTOM = {
  account_binding: {
    title: 'Account Binding',
    icon: <ICON_BINDING />,
    // url: path.dashboardPath.binding,
    submenu: {
      meta: {
        title: 'Meta',
        url: path.dashboardPath.bindingMeta,
      },
      tiktok: {
        title: 'TikTok',
        url: path.dashboardPath.bindingTiktok,
      },
      googleAds: {
        title: 'Google Ads',
        url: path.dashboardPath.bindingGoogleAds,
      },
      googleAnalytics: {
        title: 'Google Analytics',
        url: path.dashboardPath.bindingGoogleAnalytics,
      },
      instagram: {
        title: 'Instagram',
        url: path.dashboardPath.bindingInstagram,
      },
      website: {
        title: 'Website',
        url: path.dashboardPath.bindingWebsite,
      },
      marketplace: {
        title: 'Marketplace',
        url: path.dashboardPath.bindingMarketplace,
      },
    },
  },
  docs: {
    title: 'Help',
    icon: <ICON_HELP />,
    url: '/docs',
  },
};

const SideMenu = ({ location, history, closeMenu }) => {
  const goto = {
    login: () => window.location.replace('/login'),
    dashboard: () => history.replace('/'),
    docs: () => window.open(`${window.location.origin}/docs`),
  };

  const onNavigate = (e) => {
    if (e.key.startsWith('docs') || window.location.pathname.endsWith('docs')) {
      goto.docs();
    } else {
      history.replace(`/${e.key}`);
    };

    if (closeMenu) {
      closeMenu();
    };
  };

  const renderSideMenuItem = ({ item, className, onClick }) => (
    <div id="menu-item" className={className} onClick={onClick}>
      <div className="menu-icon">
        {item.icon}
        {/* {typeof item.icon === 'string' ? <i className={`icon-${item.icon}`} /> : <>{item.icon}</>} */}
      </div>
      <p>{item.title}</p>
    </div>
  );

  const renderMenus = (menuObject) => (
    <Menu
      defaultSelectedKeys={[location.pathname.slice(1)]}
      selectedKeys={[location.pathname.slice(1)]}
      onClick={onNavigate}
      mode="inline"
      collapsed="false"
    >
      {Object.entries(menuObject).map(item => (item[1]?.submenu) ?
        <SubMenu
          key={item[1].title}
          icon={(item[1]?.icon && typeof item[1]?.icon === 'string') &&
            <i className={`icon-${item[1].icon}`} style={{ fontSize: 20 }} /> ||
            item[1].icon
          }
          title={item[1].title}
          popupOffset={[0, -25]}
        >
          {Object.entries(item[1].submenu).map(el => <Menu.Item key={el[1].url.slice(1)}>
            {el[1].title} <i hidden className="ant-menu-submenu-arrow" />
          </Menu.Item>)}
        </SubMenu>
        :
        <Menu.Item key={item[1].url.slice(1)} { ...(item[1].title === 'Summary' && { id: 'SummaryMenuItem' }) }>
          {renderSideMenuItem({ item: item[1] })}
        </Menu.Item>)}
    </Menu>
  );

  return (
    <Sider
      id="dashboard-side-menu"
      width={250}
      className="no-scroll-bar"
      // onMouseEnter={() => console.log({ history, location })}
    >
      <div className="section-menu">
        <div className="sidebar-title">
          {!isMobileOnly && <Row justify="center" align="middle" style={{ transform: 'translate(-10px, 0)' }}>
            <Col sm={24} md={24} lg={10} style={{ transform: 'translate(-10px, -2px)' }}>
              {!isMobileOnly && <Image
                src={BDD_LOGIN_FORM_LOGO}
                alt="bdd-logo"
                preview={false}
                className="bdd-logo"
                width={100}
                onClick={goto.dashboard}
              /> || null}
            </Col>
            <Col sm={24} md={24} lg={14}>
              <Text style={{ fontSize: '1.2rem', fontWeight: 600, color: 'white' }}>Base Data Dashboard</Text>
            </Col>
          </Row> || null}
          {isMobileOnly && <div
            className="close-sidebar"
            onClick={isMobileOnly ? closeMenu : () => {}}
          >
            <img src={ICON_CLOSE} alt="icon-close" />
          </div> || null}
        </div>

        <div id="sideMenuTop" style={{ paddingTop: 0 }}>
          <p className="group-title">DATA</p>
          {renderMenus(SIDE_MENU_TOP)}
        </div>

        <div id="sideMenuBottom" /* style={{ position: 'relative', bottom: 0 }} */>
          <p className="group-title">USER</p>
          {renderMenus(SIDE_MENU_BOTTOM)}
        </div>
      </div>
    </Sider>
  );
};

SideMenu.displayName = config.displayName;
SideMenu.defaultProps = config.defaultProps;
SideMenu.propTypes = config.propTypes;

export default SideMenu;
