import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import Table from '../../../components/Table/Table.component';
import CarouselContentPreview from '../../../components/CarouselContentPreview/CarouselContentPreview.component';
import TYPES from '../../../redux-v1/types';
import { formatDateDisplay } from '../../../utils-v1/daterange.utils';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { ICON_IMAGE, ICON_PIESLICE, ICON_WALLET } from '../../../assets-v1/figma-icons';
import metrics from '../../../constants-v1/metrics-config/tiktok';

import parentConfig from '../DashboardTiktok.component.config';
import config from './ConversionTiktokPage.component.config';
import './ConversionTiktokPage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_TIKTOK_CT_OVERVIEW_STATS);
const contentPreviewMetrics = {
  clicks: [
    metrics.conversion_tiktok.spend,
    metrics.conversion_tiktok.clicks,
    metrics.conversion_tiktok.cpc,
    metrics.conversion_tiktok.ctr,
  ],
  purchase: [
    metrics.conversion_tiktok.spend,
    metrics.conversion_tiktok.complete_payment_onsite,
    metrics.conversion_tiktok.complete_payment_value_onsite,
    metrics.conversion_tiktok.roas_complete_payment_onsite,
  ],
};
const performanceChartConfig = [
  {
    pair: [
      [metrics.conversion_tiktok.spend, 'Total Cost'],
    ],
    title: 'Total Cost',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.conversion_tiktok.cpm, 'CPM'],
      [metrics.conversion_tiktok.cpc, 'CPC'],
    ],
    title: 'CPM & CPC',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.conversion_tiktok.product_clicks_onsite, 'Product Clicks']
    ],
    title: 'Product Clicks',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    pair: [
      [metrics.conversion_tiktok.product_detail_page_view_onsite, 'Product Detail Page View']
    ],
    title: 'Product Detail Page View',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    pair: [
      [metrics.conversion_tiktok.roas_complete_payment_onsite, 'Complete Payment ROAS'],
    ],
    title: 'Complete Payment ROAS',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.conversion_tiktok.cvr, 'CVR (%)'],
    ],
    title: 'CVR',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.conversion_tiktok.add_to_cart_onsite, 'Add to Cart'],
      [metrics.conversion_tiktok.complete_payment_onsite, 'Complete Payment'],
      [metrics.conversion_tiktok.initiate_checkout_onsite, 'Initiate Checkout'],
    ],
    title: 'ATC, Complete Payment, Initiate Checkout',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
];

const ConversionTiktokPage = (props) => {
  const getValueFormatter = (fmt='') => getNumberFormatterByFormatCode(fmt, props?.account?.details?.currency);

  const statsListProps = {
    left: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_WALLET} alt="icon_wallet" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[0].lbl}
        </>,
        values: {
          current: props?.data?.conversion_tiktok?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          previous: props?.data?.conversion_tiktok?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          percentage: props?.data?.conversion_tiktok?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt),
      },
      loading: props?.data?.conversion_tiktok?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(1, 15)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.conversion_tiktok?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.conversion_tiktok?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.conversion_tiktok?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
    right: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[15]?.adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_PIESLICE} alt="icon_pieslice" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[15].lbl}
        </>,
        values: {
          current: props?.data?.conversion_tiktok?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[15]?.key],
          previous: props?.data?.conversion_tiktok?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[15]?.key],
          percentage: props?.data?.conversion_tiktok?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[15]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[15]?.fmt),
      },
      loading: props?.data?.conversion_tiktok?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(16)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.conversion_tiktok?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.conversion_tiktok?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.conversion_tiktok?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  }
  const chartState = props?.data?.conversion_tiktok?.charts;
  const formatChartTitle = (title='') => <><b>{title}</b></>
  const formatChartData = (keyLabelPairs=[]) => {
    const output = [];
    for (let i=0; i<keyLabelPairs.length; i+=1) {
      const keyLabelPair = keyLabelPairs[i];
      output.push({ entries: chartState?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1] });
      if (props?.daterange?.use_compare) {
        output.push({ entries: chartState?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare` });
      };
    };
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    data: formatChartData(performanceChartConfig[i].pair),
    title: formatChartTitle(performanceChartConfig[i].title),
    valueFormatter: performanceChartConfig[i].valueFormatter,
  }))
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => ({ key: `${i}`, label: performanceChartConfig[i].title, children: <Chart.Line { ...e } /> })),
  };

  const funnelProps = {
    chart: {
      ...sharedPerformanceChartProps,
      advancedConfig: { ...sharedPerformanceChartProps.advancedConfig, stacked: true },
      data: [
        {
          entries: Object.entries(props?.data?.conversion_tiktok?.funnel_chart?.data?.current || {})
            .map(entry => ([metrics.conversion_tiktok[entry?.[0]].lbl, entry?.[1]])),
          label: `${formatDateDisplay(props?.daterange?.date_start_current)} - ${formatDateDisplay(props?.daterange?.date_end_current)}`,
          color: '#51ab72'
        },
        ...(props?.daterange?.use_compare && [{
          entries: Object.entries(props?.data?.conversion_tiktok?.funnel_chart?.data?.previous || {})
            .map(entry => ([metrics.conversion_tiktok[entry?.[0]].lbl, entry?.[1]])),
          label: `${formatDateDisplay(props?.daterange?.date_start_compare)} - ${formatDateDisplay(props?.daterange?.date_end_compare)}`,
          color: '#F5BD00'
        }] || []),
      ],
      title: <>Bar Chart</>,
      loading: props?.data?.conversion_tiktok?.funnel_chart?.loading || props?.uiLoading,
    },
    table: {
      columns: [
        { key: 'index', title: 'Index', width: 50 },
        { key: 'current', title: 'Current', width: 50 },
        ...(props?.daterange?.use_compare && [{ key: 'previous', title: 'Previous', width: 50 }] || []),
      ],
      data: !props?.data?.conversion_tiktok?.funnel_table?.data?.current ? [] :
        Object.keys(props?.data?.conversion_tiktok?.funnel_table?.data?.current).map((e, idx) => ({
          index: requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_TIKTOK_CT_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].lbl,
          current: getValueFormatter(requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_TIKTOK_CT_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].fmt)(props?.data?.conversion_tiktok?.funnel_table?.data?.current?.[e]),
          ...(props?.daterange?.use_compare && { previous: getValueFormatter(requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_TIKTOK_CT_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].fmt)(props?.data?.conversion_tiktok?.funnel_table?.data?.previous?.[e]) }),
        })),
      loading: props?.data?.conversion_tiktok?.funnel_table?.loading || props?.uiLoading,
      noFilter: true,
      title: <span style={{ fontSize: 16, fontWeight: 700 }}>Table Component</span>,
    },
  };

  const getTableColumnsAndData = (
    tableKey='',
    dispatchType='',
    firstCol={ key: 'campaign', title: 'Campaign' },
  ) => ({
    columns: [{ ...firstCol, width: '200px', fixed: 'left' }]
      .concat(requestConfig.find(e => e.type === dispatchType)
        .reqparams.metricsConfig.map(e => ({ key: e.key, title: e.lbl, width: '200px' }))),
    data: (Array.isArray(props?.data?.conversion_tiktok?.[tableKey]?.data) && props?.data?.conversion_tiktok?.[tableKey]?.data || [])
      .map(e => {
        const rowData = { ...e };
        const metricKeys = Object.keys(e);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const matchingMetricConfig = requestConfig.find(f => f.type === dispatchType)
            .reqparams.metricsConfig.find(f => f.key === metricKey);
          rowData[metricKey] = getValueFormatter(matchingMetricConfig?.fmt)(rowData[metricKey]);
        };
        return rowData;
      }),
    loading: props?.data?.conversion_tiktok?.[tableKey]?.loading || props?.uiLoading,
  });

  const tabbedTablesDataBasedOnProps = {
    allowSort: true,
    tables: [
      {
        title: 'Campaign',
        ...getTableColumnsAndData(
          'table_campaign', TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_CAMPAIGN, { key: 'campaign', title: 'Campaign' }
        ),
      },
      {
        title: 'Objective',
        ...getTableColumnsAndData(
          'table_objective', TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_OBJECTIVE, { key: 'objective', title: 'Objective' }
        ),
      },
      {
        title: 'Audience',
        ...getTableColumnsAndData(
          'table_audience', TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_AUDIENCE, { key: 'name', title: 'Name' }
        ),
      },
    ],
    title: <b>Data Based On</b>,
    titlePlacement: 'out',
  };

  const contentPreviewProps = {
    clicks: {
      data: (props?.data?.conversion_tiktok?.preview_clicks?.data || [])?.map((e, i) => ({
        src: { ...e?.previews },
        statsList: contentPreviewMetrics.clicks?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.conversion_tiktok?.preview_clicks?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
        title: e?.ad_name,
      })),
      loading: props?.data?.conversion_tiktok?.preview_clicks?.loading || props?.uiLoading,
    },
    purchase: {
      data: (props?.data?.conversion_tiktok?.preview_purchase?.data || [])?.map((e, i) => ({
        src: { ...e?.previews },
        statsList: contentPreviewMetrics.purchase?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.conversion_tiktok?.preview_purchase?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
        title: e?.ad_name,
      })),
      loading: props?.data?.conversion_tiktok?.preview_purchase?.loading || props?.uiLoading,
    },
  };
  const contentPreviewTabsProps = {
    defaultActiveKey: 'purchase',
    items: [
      { key: 'clicks', label: 'Clicks', children: <CarouselContentPreview { ...contentPreviewProps.clicks } /> },
      { key: 'purchase', label: 'Purchase', children: <CarouselContentPreview { ...contentPreviewProps.purchase } /> },
    ],
  };

  return(
    <div id="TiktokConversionTiktok">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>TikTok Shop</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.left } />}
        </Col>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.right } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
        <Col sm={24} md={24} lg={12} id="TiktokConversionTiktokFunnelChart">
          {props?.data?.funnel_chart?.loading ? null : <Chart.Bar { ...funnelProps.chart } />}
        </Col>
        <Col sm={24} md={24} lg={12} id="TiktokConversionTiktokFunnelTable">
          {props?.data?.funnel_table?.loading ? null : <Table.Single { ...funnelProps.table } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.table_purchase?.loading ? null : <Table.Tabbed { ...tabbedTablesDataBasedOnProps } />}
        </Col>
        <Col sm={24} md={24} lg={24} id="ContentPreviewTabs">
          <Paragraph style={{ fontSize: 16, fontWeight: 700 }}>Content Based On</Paragraph>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...contentPreviewTabsProps } />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

ConversionTiktokPage.displayName = config.displayName;
ConversionTiktokPage.propTypes = config.propTypes;
ConversionTiktokPage.defaultProps = config.defaultProps;

export default ConversionTiktokPage;
