import { combineReducers } from 'redux';

import summary from './summary.reducer';
import googleAds from './google-ads.reducer';
import googleAnalytics from './google-analytics.reducer';
import instagram from './instagram.reducer';
import marketplace from './marketplace.reducer';
import meta from './meta.reducer';
import tiktok from './tiktok.reducer';
import website from './website.reducer';
import budgetTracker from './budget-tracker.reducer';
import customReport from './custom-report.reducer';

const dashboardReducer = {
  summary,
  googleAds,
  googleAnalytics,
  instagram,
  marketplace,
  meta,
  tiktok,
  website,
  budgetTracker,
  customReport,
};

export default combineReducers(dashboardReducer);