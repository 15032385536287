import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Row, Col, Typography } from 'antd';
import axios from 'axios';
import animations from '../../utils-v1/animations';

import TYPES from '../../redux-v1/types';

import { DashboardLayoutGS } from '../../components/layouts-backup';
import OnboardingPopUp from '../../components/Onboarding/OnboardingPopUp/OnboardingPopUp.component';
import stringConstants from '../../constants-v1/string.constants';

import GoogleAdsPanel from './GoogleAdsPanel/GoogleAdsPanel.component';
import GoogleAnalyticsPanel from './GoogleAnalyticsPanel/GoogleAnalyticsPanel.component';
import InstagramPanel from './InstagramPanel/InstagramPanel.component';
import MetaPanel from './MetaPanel/MetaPanel.component';
import MarketplacePanel from './MarketplacePanel/MarketplacePanel.component';
import TiktokPanel from './TiktokPanel/TiktokPanel.component';
import WebsitePanel from './WebsitePanel/WebsitePanel.component';

import path from '../../navigation/path'
import { introduce, addVisitedRoute } from '../../services/intro.service';
import { coachmark } from '../../utils-v1/coachmark.utils';
import { getToken } from '../../services/token.service';
import config from './Summary.component.config';
import './Summary.component.styles.less';

const { Title, Text } = Typography;
const ROUTE = "/summary";
const platformsWithMultipleAccountSelection = [
  // stringConstants.googleAds.key,
  // stringConstants.googleAnalytics.key,
  // stringConstants.instagram.key,
  // stringConstants.marketplace.key,
  stringConstants.meta.key,
  stringConstants.tiktok.key,
  // stringConstants.website.key,
];
const isMarketplaceOrWebsite = (_platform = '') => [stringConstants.marketplace.key, stringConstants.website.key].includes(_platform);
let source;

class Summary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    source = axios.CancelToken.source();
  };

  _introGuide = async () => {
    const lastStatus = await this.props.getStatusIntroGuide();
    const intro = lastStatus !== null ? lastStatus : this.props.isNewUser;

    setTimeout(() => {
      if (intro.needGuide) {
        introduce({
          steps: config.getIntroSteps(document),
          oncomplete: () => {
            this.props.updateIntroUserGuide(true);
            addVisitedRoute(ROUTE);
          },
        })
      }
    }, 1000);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (this.props.onBoarding.coachmark_status.summary === 0 && this.props.coachmark_local.summary === 0 && this.props.accounts_by_platform?.haveBinding === true) coachmark({
      steps: [
        {
          key: 1,
          element: "#SummaryMenuItem",
          title: 'All Account Performance',
          intro: 'You can now oversee the overall performance of ad accounts that you run!',
          position: 'bottom',
        },
        {
          key: 2,
          element: "#RangePickerBtn",
          title: 'New Date Range',
          intro: 'Now you can easily set up you preferred date range',
          position: 'bottom',
        },
        {
          key: 3,
          element: '#accountSelectionBtn',
          title: 'Multiple Accounts for Summary',
          intro: 'You can now select more than two accounts for Facebook & TikTok Ads on the Summary page',
          position: 'bottom',
        },
      ],
      oncomplete: () => this.props.updateCoachmarkLocal({ ...this.props.coachmark_local, summary: 1 }, this.props.onBoarding),
    });
    this.props.getAccountConfig()
      .then(() => {
        this.props.getSummaryConfig()
          .then(() => {
            if (this.props.visited === false) {
              setTimeout(() => this.getSummaryData('all'), 500)
            };
          })
          .finally(() => this.props.setDashboardSummaryState(TYPES.SET_DASHBOARD_SUMMARY_VISITED, {}));
      });
  };

  componentWillUnmount() {
    if (source) { source.cancel('DashboardSummary axios cancel due to unmounting') };
  };

  getSummaryData = (platformKey = '') => {
    const generateCommonRequestParams = (platform = '', extras = {}) => {
      const marketplaceOrWebsite = isMarketplaceOrWebsite(platform);
      const output = {
        account_ids: this.props?.[platform]?.accounts?.filter(account => account?.account_id)?.map(account => account?.account_id),
        breakdown_interval: this.props?.[platform]?.daterange?.breakdown_interval,
        config_ids: this.props?.[platform]?.accounts?.filter(account => account?.account_id)?.map(account => account?.config_id),
        date_start_current: this.props?.[platform]?.daterange?.date_start_current,
        date_end_current: this.props?.[platform]?.daterange?.date_end_current,
        date_start_compare: this.props?.[platform]?.daterange?.date_start_compare,
        date_end_compare: this.props?.[platform]?.daterange?.date_end_compare,
        group_by: this.props?.[platform]?.filter?.group_by,
        is_compare: this.props?.[platform]?.daterange?.is_compare,
        platform,
        platform_type: marketplaceOrWebsite ? this?.props?.[platform]?.accounts?.[0]?.details?.type : platform,
        cancelToken: extras?.cancelToken,
      };
      if (output.is_compare === false) { delete output.date_start_compare; delete output?.date_end_compare };
      return output;
    };
    const reqParams = {
      googleAds: generateCommonRequestParams(stringConstants.googleAds.key),
      googleAnalytics: generateCommonRequestParams(stringConstants.googleAnalytics.key),
      instagram: generateCommonRequestParams(stringConstants.instagram.key),
      marketplace: generateCommonRequestParams(stringConstants.marketplace.key),
      meta: generateCommonRequestParams(stringConstants.meta.key),
      tiktok: generateCommonRequestParams(stringConstants.tiktok.key),
      website: generateCommonRequestParams(stringConstants.website.key),
    };
    const platformStringConstants = Object.values(stringConstants).filter(stringConstant => stringConstant?.PLATFORM !== null);
    const reqList = [];
    for (let i = 0; i < platformStringConstants.length; i += 1) {
      const platformStringConstant = platformStringConstants[i];
      const { key, PLATFORM } = platformStringConstant;
      if (platformKey === key || platformKey === 'all') {
        reqList.push(this.props.getDashboardSummaryData(
          TYPES[`SET_DASHBOARD_SUMMARY_${PLATFORM.replace(' ', '_')}_DATA`], reqParams[key]
        ));
      };
    };
    Promise.all(reqList)
      .then(() => {
        // console.log('success');
      })
      .catch(() => {
        // console.log('failed');
      });
  };

  constructDashboardProps = () => ({
    accountSelectionProps: null,
    filterProps: null,
    history: this.props.history,
    location: this.props.location,
    // onMouseEnter: () => console.log(this.props),
    rangePickerProps: {
      defaultState: this.props.daterange,
      onApply: (daterange = {}) => {
        if (JSON.stringify(this.props.daterange) !== JSON.stringify(daterange)) {
          this.props.setDashboardSummaryState(TYPES.SET_DASHBOARD_SUMMARY_DATERANGE, { daterange });
          setTimeout(() => this.getSummaryData('all'), 500);
        };
      },
    },
    syncDataProps: null,
    title: 'Summary',
  });

  constructPanelProps = (platformKey = '') => ({
    accountSelectionProps: {
      defaultState: { selectedAccounts: this.props[platformKey].accounts },
      multiple: platformsWithMultipleAccountSelection.includes(platformKey),
      platformKey: stringConstants[platformKey].key,
      platformName: stringConstants[platformKey].name,
      onApply: (accounts = []) => {
        const marketplaceOrWebsite = isMarketplaceOrWebsite(platformKey);
        const selectedAccounts = platformsWithMultipleAccountSelection.includes(platformKey) ? accounts : [accounts]
        const { PLATFORM } = stringConstants[platformKey];
        this.props.setDashboardSummaryState(
          TYPES[`SET_DASHBOARD_SUMMARY_${PLATFORM.replace(' ', '_')}_ACCOUNTS`],
          {
            accounts: selectedAccounts,
            platform: platformKey,
            platform_type: marketplaceOrWebsite ? selectedAccounts?.[0]?.details?.type : platformKey,
            config_ids: (Array.isArray(accounts) ? accounts : [accounts])
              ?.filter(account => account?.config_id)?.map(account => account?.config_id),
          }
        );
        Promise.all(selectedAccounts.map(account => this.props.setDashboardSummaryAccounts(platformKey, { account })));
        setTimeout(() => this.getSummaryData(stringConstants[platformKey].key), 500);
      },
    },
    availableAccounts: this.props.accounts_by_platform[platformKey],
    hidden: Array.isArray(this.props.accounts_by_platform[platformKey] && this.props.accounts_by_platform[platformKey] || [])
      .length === 0,
    history: this.props.history,
    loading: this.props[platformKey]?.data?.loading,
    overlayClassName: 'summary-account-selection',
    platformProps: {
      ...this.props[platformKey],
      accounts: this.props[platformKey].accounts.filter(account => account?.account_id !== '')
    },
    ...this.props,
  });

  constructStatusPropsOnboarding = () => {
    let onboard = false;
    if (this.props.me?.onBoarding?.onboarding_status?.haveFinished === 0) {
      onboard = true;
      // } else if (this.props.accounts_by_platform?.haveBinding === false) {
      //   onboard = true;
    }
    return onboard;
  };
  constructOnboardingPopup = () => ({
    openModal: this.constructStatusPropsOnboarding(),
    content: 'onSummary',
    history: this.props.history,
    dots: true,
  });

  render() {
    return (
      <DashboardLayoutGS
        {...this.constructDashboardProps()}
      >
        <div id="summary" style={{ animation: animations.popIn }}>
          <Row gutter={[32, 32]} style={{ marginBottom: 50 }}>
            <Col sm={24} md={24} lg={24}>
              <MetaPanel {...this.constructPanelProps(stringConstants.meta.key)} />
            </Col>

            <Col sm={24} md={24} lg={24}>
              <InstagramPanel {...this.constructPanelProps(stringConstants.instagram.key)} />
            </Col>

            <Col sm={24} md={24} lg={24} xl={12}>
              <TiktokPanel {...this.constructPanelProps(stringConstants.tiktok.key)} />
            </Col>

            <Col sm={24} md={24} lg={24} xl={12}>
              <GoogleAdsPanel {...this.constructPanelProps(stringConstants.googleAds.key)} />
            </Col>

            <Col sm={24} md={24} lg={24} xl={12}>
              <GoogleAnalyticsPanel {...this.constructPanelProps(stringConstants.googleAnalytics.key)} />
            </Col>

            <Col sm={24} md={24} lg={24} xl={12}>
              <Row gutter={[15, 15]}>
                <Col sm={24} md={24} lg={24}>
                  <WebsitePanel {...this.constructPanelProps(stringConstants.website.key)} />
                </Col>

                <Col sm={24} md={24} lg={24}>
                  <MarketplacePanel {...this.constructPanelProps(stringConstants.marketplace.key)} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <OnboardingPopUp {...this.constructOnboardingPopup()} />
      </DashboardLayoutGS>
    );
  }
}

Summary.displayName = config.displayName;
Summary.defaultProps = config.defaultProps;
Summary.propTypes = config.propTypes;

export default Summary;
