import React from 'react';
import { Button } from 'antd';
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import {
  BG_WIDE_INFO,
} from '../../../assets-v1/images';

import config from './PersistentInfo.component.config';
import './PersistentInfo.component.styles.less';

const Block = ({ type='', message='', description='', closable=false }) => {
  const infoType = type  || `info`; // info, warning, danger

  const [hidden, setHidden] = React.useState(false);

  const renderHeaderContent = () => <>
    <Button id="closeBtn" className={`close-btn-${type}`} type="default" onClick={() => setHidden(true)} hidden={!closable}>
      <CloseCircleOutlined style={{ paddingTop: 10, transform: 'scale(1.3)' }} />
    </Button>
  </>;
  const renderMessage = () => <p className="info-message"><InfoCircleOutlined hidden /> {message || `PersistentInfo`}</p>
  const renderDescription = () => <div className="info-description">{description || `PersistentInfo`}</div>

  return(
    <>
      {hidden ? null :
        <div
          id="persistentInfoBlockWrapper"
          className={infoType}
          style={{
            background: `url(${BG_WIDE_INFO})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div id="infoHeader">
            {renderHeaderContent()}
          </div>
          <div id="persistentInfoContainer">
            {renderMessage()}
            {renderDescription()} 
          </div>
        </div>
      }
    </>
  );
};

const Float = ({ type='', message='', description='', closeBtnText='', onClose=()=>null }) => {
  const infoType = type  || `info`; // info, warning, danger

  const [hidden, setHidden] = React.useState(false);

  const renderFooterContent = () => <>
    <Button id="closeBtn" type="default" onClick={() => { setHidden(true); onClose() }}>
      {closeBtnText || 'Close'}
    </Button>
  </>;
  const renderMessage = () => <p className="info-message"><InfoCircleOutlined /> {message || `PersistentInfo`}</p>
  const renderDescription = () => <p className="info-description">{description || `PersistentInfo`}</p>

  return(
    <>
      <div id="persistentInfoFloatWrapper" className={`${infoType} ${hidden ? 'close' : 'open'}`}>
        <div id="persistentInfoContainer">
          {renderMessage()}
          {renderDescription()} 
          <div id="infoFooter">
            {renderFooterContent()}
          </div>
        </div>
      </div>
    </>
  );
};

const PersistentInfo = { Block, Float };

PersistentInfo.displayName = config.displayName;
PersistentInfo.propTypes = config.propTypes;
PersistentInfo.defaultProps = config.defaultProps;

export default PersistentInfo;