export default {
  // AUTH GROUP
  SET_AUTH_LOGIN_EMAIL: 'SET_AUTH_LOGIN_EMAIL',
  SET_AUTH_LOGIN_GOOGLE: 'SET_AUTH_LOGIN_GOOGLE',
  SET_AUTH_LOGIN_META: 'SET_AUTH_LOGIN_META',
  SET_AUTH_REGISTER: 'SET_AUTH_REGISTER',
  SET_AUTH_RESET_PASSWORD: 'SET_AUTH_RESET_PASSWORD',

  // BINDING GROUP
  SET_BINDING_BIND_GOOGLE_ADS: 'SET_BINDING_BIND_GOOGLE_ADS',
  SET_BINDING_BIND_GOOGLE_ANALYTICS: 'SET_BINDING_BIND_GOOGLE_ANALYTICS',
  SET_BINDING_BIND_INSTAGRAM: 'SET_BINDING_BIND_INSTAGRAM',
  SET_BINDING_BIND_MARKETPLACE: 'SET_BINDING_BIND_MARKETPLACE',
  SET_BINDING_BIND_META: 'SET_BINDING_BIND_META',
  SET_BINDING_BIND_TIKTOK: 'SET_BINDING_BIND_TIKTOK',
  SET_BINDING_BIND_WEBSITE: 'SET_BINDING_WEBSITE',
  SET_BINDING_UNBIND_ACCOUNT: 'SET_BINDING_UNBIND_ACCOUNT',
  SET_BINDING_UNBIND_ACCOUNT_CONFIRMATION: 'SET_BINDING_UNBIND_ACCOUNT_CONFIRMATION',

  // SUMMARY GROUP
  SET_DASHBOARD_SUMMARY_VISITED: 'SET_DASHBOARD_SUMMARY_VISITED',
  SET_DASHBOARD_SUMMARY_DATERANGE: 'SET_DASHBOARD_SUMMARY_DATERANGE',

  SET_DASHBOARD_SUMMARY_GOOGLE_ADS_ACCOUNTS: 'SET_DASHBOARD_SUMMAY_GOOGLE_ADS_ACCOUNTS',
  SET_DASHBOARD_SUMMARY_GOOGLE_ADS_DATERANGE: 'SET_DASHBOARD_SUMMARY_GOOGLE_ADS_DATERANGE',
  SET_DASHBOARD_SUMMARY_GOOGLE_ADS_FILTER: 'SET_DASHBOARD_SUMMARY_GOOGLE_ADS_FILTER',
  SET_DASHBOARD_SUMMARY_GOOGLE_ADS_DATA: 'SET_DASHBOARD_SUMMARY_GOOGLE_ADS_DATA',

  SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_ACCOUNTS: 'SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_ACCOUNTS',
  SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_DATERANGE: 'SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_DATERANGE',
  SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_FILTER: 'SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_FILTER',
  SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_DATA: 'SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_DATA',

  SET_DASHBOARD_SUMMARY_INSTAGRAM_ACCOUNTS: 'SET_DASHBOARD_SUMMARY_INSTAGRAM_ACCOUNTS',
  SET_DASHBOARD_SUMMARY_INSTAGRAM_DATERANGE: 'SET_DASHBOARD_SUMMARY_INSTAGRAM_DATERANGE',
  SET_DASHBOARD_SUMMARY_INSTAGRAM_FILTER: 'SET_DASHBOARD_SUMMARY_INSTAGRAM_FILTER',
  SET_DASHBOARD_SUMMARY_INSTAGRAM_DATA: 'SET_DASHBOARD_SUMMARY_INSTAGRAM_DATA',

  SET_DASHBOARD_SUMMARY_MARKETPLACE_ACCOUNTS: 'SET_DASHBOARD_SUMMARY_MARKETPLACE_ACCOUNTS',
  SET_DASHBOARD_SUMMARY_MARKETPLACE_DATERANGE: 'SET_DASHBOARD_SUMMARY_MARKETPLACE_DATERANGE',
  SET_DASHBOARD_SUMMARY_MARKETPLACE_FILTER: 'SET_DASHBOARD_SUMMARY_MARKETPLACE_FILTER',
  SET_DASHBOARD_SUMMARY_MARKETPLACE_DATA: 'SET_DASHBOARD_SUMMARY_MARKETPLACE_DATA',

  SET_DASHBOARD_SUMMARY_META_ACCOUNTS: 'SET_DASHBOARD_SUMMARY_META_ACCOUNTS',
  SET_DASHBOARD_SUMMARY_META_DATERANGE: 'SET_DASHBOARD_SUMMARY_META_DATERANGE',
  SET_DASHBOARD_SUMMARY_META_FILTER: 'SET_DASHBOARD_SUMMARY_META_FILTER',
  SET_DASHBOARD_SUMMARY_META_DATA: 'SET_DASHBOARD_SUMMARY_META_DATA',

  SET_DASHBOARD_SUMMARY_TIKTOK_ACCOUNTS: 'SET_DASHBOARD_SUMMARY_TIKTOK_ACCOUNTS',
  SET_DASHBOARD_SUMMARY_TIKTOK_DATERANGE: 'SET_DASHBOARD_SUMMARY_TIKTOK_DATERANGE',
  SET_DASHBOARD_SUMMARY_TIKTOK_FILTER: 'SET_DASHBOARD_SUMMARY_TIKTOK_FILTER',
  SET_DASHBOARD_SUMMARY_TIKTOK_DATA: 'SET_DASHBOARD_SUMMARY_TIKTOK_DATA',

  SET_DASHBOARD_SUMMARY_WEBSITE_ACCOUNTS: 'SET_DASHBOARD_SUMMARY_WEBSITE_ACCOUNTS',
  SET_DASHBOARD_SUMMARY_WEBSITE_DATERANGE: 'SET_DASHBOARD_SUMMARY_WEBSITE_DATERANGE',
  SET_DASHBOARD_SUMMARY_WEBSITE_FILTER: 'SET_DASHBOARD_SUMMARY_WEBSITE_FILTER',
  SET_DASHBOARD_SUMMARY_WEBSITE_DATA: 'SET_DASHBOARD_SUMMARY_WEBSITE_DATA',

  RESET_DASHBOARD_SUMMARY_REDUCER: 'RESET_DASHBOARD_SUMMARY_REDUCER',

  // PLATFORM DATA GROUP
  SET_DASHBOARD_GOOGLE_ADS_VISITED: 'SET_DASHBOARD_GOOGLE_ADS_VISITED',
  SET_DASHBOARD_GOOGLE_ADS_ACCOUNT: 'SET_DASHBOARD_GOOGLE_ADS_ACCOUNT',
  SET_DASHBOARD_GOOGLE_ADS_ACCOUNT_DETAILS_CURRENCY: 'SET_DASHBOARD_GOOGLE_ADS_ACCOUNT_DETAILS_CURRENCY',
  SET_DASHBOARD_GOOGLE_ADS_DATERANGE: 'SET_DASHBOARD_GOOGLE_ADS_DATERANGE',
  SET_DASHBOARD_GOOGLE_ADS_FILTER: 'SET_DASHBOARD_GOOGLE_ADS_FILTER',
  SET_DASHBOARD_GOOGLE_ADS_LATEST_SYNC: 'SET_DASHBOARD_GOOGLE_ADS_LATEST_SYNC',
  SET_DASHBOARD_GOOGLE_ADS_SEM_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ADS_SEM_OVERVIEW_STATS',
  SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_CLICKS_CONVERSIONS: 'SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_CLICKS_CONVERSIONS',
  SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_AGE: 'SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_AGE',
  SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_GENDER: 'SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_GENDER',
  SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_TOP_KEYWORDS: 'SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_TOP_KEYWORDS',
  SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_SEARCH_TERM_INSIGHTS: 'SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_SEARCH_TERM_INSIGHTS',
  SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_AD_PERFORMANCE: 'SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_AD_PERFORMANCE',
  SET_DASHBOARD_GOOGLE_ADS_SEM_PREVIEW: 'SET_DASHBOARD_GOOGLE_ADS_SEM_PREVIEW',
  SET_DASHBOARD_GOOGLE_ADS_GDN_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ADS_GDN_OVERVIEW_STATS',
  SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_CLICKS_IMPRESSIONS: 'SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_CLICK_IMPRESSIONS',
  SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_AGE: 'SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_AGE',
  SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_GENDER: 'SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_GENDER',
  SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AGE: 'SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AGE',
  SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_CAMPAIGN: 'SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_CAMPAIGN',
  SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AD_GROUP: 'SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AD_GROUP',
  SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AUDIENCE_SEGMENTATION: 'SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AUDIENCE_SEGMENTATION',
  SET_DASHBOARD_GOOGLE_ADS_GDN_PREVIEW: 'SET_DASHBOARD_GOOGLE_ADS_GDN_PREVIEW',
  SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_OVERVIEW_STATS',
  SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_IMPRESSIONS_VIEWS: 'SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_IMPRESSIONS_VIEWS',
  SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_AGE: 'SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_AGE',
  SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_GENDER: 'SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_GENDER',
  SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_AUDIENCE_SEGMENTATION: 'SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_AUDIENCE_SEGMENTATION',
  SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_AUDIENCE_SEGMENT: 'SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_AUDIENCE_SEGMENT',
  SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_YOUTUBE_AD_PERFORMANCE: 'SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_YOUTUBE_AD_PERFORMANCE',
  SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_PREVIEW: 'SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_PREVIEW',
  SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_OVERVIEW_STATS',
  SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_CLICKS_CONVERSIONS: 'SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_CLICKS_CONVERSIONS',
  SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_AGE: 'SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_AGE',
  SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_GENDER: 'SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_GENDER',
  SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_CAMPAIGN: 'SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_CAMPAIGN',
  SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_AD_PERFORMANCE: 'SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_AD_PERFORMANCE',
  SET_DASHBOARD_GOOGLE_ADS_PERFORMANCE_MAX_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ADS_PERFORMANCE_MAX_OVERVIEW_STATS',
  SET_DASHBOARD_GOOGLE_ADS_SMART_CAMPAIGN_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ADS_SMART_CAMPAIGN_OVERVIEW_STATS',
  SET_DASHBOARD_GOOGLE_ADS_APP_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ADS_APP_OVERVIEW_STATS',
  RESET_DASHBOARD_GOOGLE_ADS_DATA: 'RESET_DASHBOARD_GOOGLE_ADS_DATA',
  RESET_DASHBOARD_GOOGLE_ADS_REDUCER: 'RESET_DASHBOARD_GOOGLE_ADS_REDUCER',

  SET_DASHBOARD_GOOGLE_ANALYTICS_VISITED: 'SET_DASHBOARD_GOOGLE_ANALYTICS_VISITED',
  SET_DASHBOARD_GOOGLE_ANALYTICS_ACCOUNT: 'SET_DASHBOARD_GOOGLE_ANALYTICS_ACCOUNT',
  SET_DASHBOARD_GOOGLE_ANALYTICS_DATERANGE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_DATERANGE',
  SET_DASHBOARD_GOOGLE_ANALYTICS_FILTER: 'SET_DASHBOARD_GOOGLE_ANALYTICS_FILTER',
  SET_DASHBOARD_GOOGLE_ANALYTICS_LATEST_SYNC: 'SET_DASHBOARD_GOOGLE_ANALYTICS_LATEST_SYNC',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_OVERVIEW_STATS',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_SHOPPING_BEHAVIOUR: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_SHOPPING_BEHAVIOUR',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_VISITOR: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_VISITOR',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_PRODUCT_REVENUE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_PRODUCT_REVENUE',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_PRODUCT_RATE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_PRODUCT_RATE',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_PRODUCT_PERFORMANCE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_PRODUCT_PERFORMANCE',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_SOURCE_MEDIUM: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_SOURCE_MEDIUM',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_EXIT_PAGE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_EXIT_PAGE',
  SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_TOP_PAGE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_TOP_PAGE',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_OVERVIEW_STATS: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_OVERVIEW_STATS',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_TOP_COUNTRIES: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_TOP_COUNTRIES',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_TOP_CITIES: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_TOP_CITIES',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_GENDER: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_GENDER',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_AGE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_AGE',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_DEVICE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_DEVICE',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_USER_ACQUISITION: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_USER_ACQUISITION',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_TRAFFIC_ACQUISITION: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_TRAFFIC_ACQUISITION',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_PAGES_SCREEN: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_PAGES_SCREEN',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_FUNNEL_CHART: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_FUNNEL_CHART',
  SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_FUNNEL_TABLE: 'SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_FUNNEL_TABLE',
  RESET_DASHBOARD_GOOGLE_ANALYTICS_DATA: 'RESET_DASHBOARD_GOOGLE_ANALYTICS_DATA',
  RESET_DASHBOARD_GOOGLE_ANALYTICS_REDUCER: 'RESET_DASHBOARD_GOOGLE_ANALYTICS_REDUCER',

  SET_DASHBOARD_INSTAGRAM_VISITED: 'SET_DASHBOARD_INSTAGRAM_VISITED',
  SET_DASHBOARD_INSTAGRAM_ACCOUNT: 'SET_DASHBOARD_INSTAGRAM_ACCOUNT',
  SET_DASHBOARD_INSTAGRAM_DATERANGE: 'SET_DASHBOARINSTAGRAM_DATERANGE',
  SET_DASHBOARD_INSTAGRAM_FILTER: 'SET_DASHBOARD_INSTAGRAM_FILTER',
  SET_DASHBOARD_INSTAGRAM_LATEST_SYNC: 'SET_DASHBOARD_INSTAGRAM_LATEST_SYNC',
  SET_DASHBOARD_INSTAGRAM_OVERVIEW_STATS: 'SET_DASHBOARD_INSTAGRAM_OVERVIEW_STATS',
  SET_DASHBOARD_INSTAGRAM_CHART_BAR_TOP_CITIES: 'SET_DASHBOARD_INSTAGRAM_CHART_BAR_TOP_CITIES',
  SET_DASHBOARD_INSTAGRAM_CHART_BAR_AGE_GENDER: 'SET_DASHBOARD_INSTAGRAM_CHART_BAR_AGE_GENDER',
  SET_DASHBOARD_INSTAGRAM_CHART_HEATMAP_FOLLOWERS_ONLINE: 'SET_DASHBOARD_INSTAGRAM_CHART_HEATMAP_FOLLOWERS_ONLINE',
  SET_DASHBOARD_INSTAGRAM_CHART_LINE_GROWTH: 'SET_DASHBOARD_INSTAGRAM_CHART_LINE_GROWTH',
  SET_DASHBOARD_INSTAGRAM_CHART_LINE_PROFILE_VIEWS: 'SET_DASHBOARD_INSTAGRAM_CHART_LINE_PROFILE_VIEWS',
  SET_DASHBOARD_INSTAGRAM_CHART_LINE_REACH_IMPRESSIONS: 'SET_DASHBOARD_INSTAGRAM_CHART_LINE_REACH_IMPRESSIONS',
  SET_DASHBOARD_INSTAGRAM_CHART_LINE_LIKE_COMMENT_SAVED: 'SET_DASHBOARD_INSTAGRAM_CHART_LINE_LIKE_COMMENT_SAVED',
  SET_DASHBOARD_INSTAGRAM_CHART_LINE_REACH_BY_POST_MEDIA: 'SET_DASHBOARD_INSTAGRAM_CHART_LINE_REACH_BY_POST_MEDIA',
  SET_DASHBOARD_INSTAGRAM_CHART_MAP_TOP_COUNTRIES: 'SET_DASHBOARD_INSTAGRAM_CHART_MAP_TOP_COUNTRIES',
  SET_DASHBOARD_INSTAGRAM_PREVIEW_ENGAGEMENTS: 'SET_DASHBOARD_INSTAGRAM_PREVIEW_ENGAGEMENTS',
  SET_DASHBOARD_INSTAGRAM_PREVIEW_REACH: 'SET_DASHBOARD_INSTAGRAM_PREVIEW_REACH',
  SET_DASHBOARD_INSTAGRAM_PREVIEW_STORIES: 'SET_DASHBOARD_INSTAGRAM_PREVIEW_STORIES',
  RESET_DASHBOARD_INSTAGRAM_DATA: 'RESET_DASHBOARD_INSTAGRAM_DATA',
  RESET_DASHBOARD_INSTAGRAM_REDUCER: 'RESET_DASHBOARD_INSTAGRAM_REDUCER',

  SET_DASHBOARD_MARKETPLACE_VISITED: 'SET_DASHBOARD_MARKETPLACE_VISITED',
  SET_DASHBOARD_MARKETPLACE_ACCOUNT: 'SET_DASHBOARD_MARKETPLACE_ACCOUNT',
  SET_DASHBOARD_MARKETPLACE_DATERANGE: 'SET_DASHBOARD_MARKETPLACE_DATERANGE',
  SET_DASHBOARD_MARKETPLACE_FILTER: 'SET_DASHBOARD_MARKETPLACE_FILTER',
  SET_DASHBOARD_MARKETPLACE_LATEST_SYNC: 'SET_DASHBOARD_MARKETPLACE_LATEST_SYNC',
  SET_DASHBOARD_MARKETPLACE_OVERVIEW_STATS: 'SET_DASHBOARD_MARKETPLACE_OVERVIEW_STATS',
  SET_DASHBOARD_MARKETPLACE_PAID_ORDER_DISTRIBUTION_LIST: 'SET_DASHBOARD_MARKETPLACE_PAID_ORDER_DISTRIBUTION_LIST',
  SET_DASHBOARD_MARKETPLACE_PAID_ORDER_DISTRIBUTION_MAP: 'SET_DASHBOARD_MARKETPLACE_PAID_ORDER_DISTRIBUTION_MAP',
  SET_DASHBOARD_MARKETPLACE_CHARTS: 'SET_DASHBOARD_MARKETPLACE_CHARTS',
  RESET_DASHBOARD_MARKETPLACE_DATA: 'RESET_DASHBOARD_MARKETPLACE_DATA',
  RESET_DASHBOARD_MARKETPLACE_REDUCER: 'RESET_DASHBOARD_MARKETPLACE_REDUCER',

  SET_DASHBOARD_META_VISITED: 'SET_DASHBAORD_META_VISITED',
  SET_DASHBOARD_META_ACCOUNT: 'SET_DASHBOARD_META_ACCOUNT',
  SET_DASHBOARD_META_ACCOUNT_DETAILS_CURRENCY: 'SET_DASHBOARD_META_ACCOUNT_DETAILS_CURRENCY',
  SET_DASHBOARD_META_DATERANGE: 'SET_DASHBOARD_META_DATERANGE',
  SET_DASHBOARD_META_FILTER: 'SET_DASHBOARD_META_FILTER',
  SET_DASHBOARD_META_LATEST_SYNC: 'SET_DASHBOARD_META_LATEST_SYNC',
  SET_DASHBOARD_META_CONVERSION_OVERVIEW_STATS: 'SET_DASHBOARD_META_CONVERSION_OVERVIEW_STATS',
  SET_DASHBOARD_META_CONVERSION_CHARTS: 'SET_DASHBOARD_META_CONVERSION_CHARTS',
  SET_DASHBOARD_META_CONVERSION_FUNNEL_CHART: 'SET_DASHBOARD_META_CONVERSION_FUNNEL_CHART',
  SET_DASHBOARD_META_CONVERSION_FUNNEL_TABLE: 'SET_DASHBOARD_META_CONVERSION_FUNNEL_TABLE',
  SET_DASHBOARD_META_CONVERSION_TABLE_PURCHASE: 'SET_DASHBOARD_META_CONVERSION_TABLE_PURCHASE',
  SET_DASHBOARD_META_CONVERSION_TABLE_OBJECTIVE: 'SET_DASHBOARD_META_CONVERSION_TABLE_OBJECTIVE',
  SET_DASHBOARD_META_CONVERSION_TABLE_LINK_CLICKS: 'SET_DASHBOARD_META_CONVERSION_TABLE_LINK_CLICKS',
  SET_DASHBOARD_META_CONVERSION_TABLE_ENGAGEMENT: 'SET_DASHBOARD_META_CONVERSION_TABLE_ENGAGEMENT',
  SET_DASHBOARD_META_CONVERSION_TABLE_PLACEMENT: 'SET_DASHBOARD_META_CONVERSION_TABLE_PLACEMENT',
  SET_DASHBOARD_META_CONVERSION_TABLE_DELIVERY_PLATFORM: 'SET_DASHBOARD_META_CONVERSION_TABLE_DELIVERY_PLATFORM',
  SET_DASHBOARD_META_CONVERSION_TABLE_REGION: 'SET_DASHBOARD_META_CONVERSION_TABLE_REGION',
  SET_DASHBOARD_META_CONVERSION_TABLE_AGE: 'SET_DASHBOARD_META_CONVERSION_TABLE_AGE',
  SET_DASHBOARD_META_CONVERSION_TABLE_DEVICE_PLATFORM: 'SET_DASHBOARD_META_CONVERSION_TABLE_DEVICE_PLATFORM',
  SET_DASHBOARD_META_CONVERSION_PREVIEW_PURCHASE_ADS: 'SET_DASHBOARD_META_CONVERSION_PREVIEW_PURCHASE_ADS',
  SET_DASHBOARD_META_CONVERSION_PREVIEW_ENGAGEMENT_ADS: 'SET_DASHBOARD_META_CONVERSION_PREVIEW_ENGAGEMENT_ADS',
  SET_DASHBOARD_META_CONVERSION_PREVIEW_LINK_CLICK_ADS: 'SET_DASHBOARD_META_CONVERSION_PREVIEW_LINK_CLICK_ADS',
  SET_DASHBOARD_META_COLLABORATIVE_OVERVIEW_STATS: 'SET_DASHBOARD_META_COLLABORATIVE_OVERVIEW_STATS',
  SET_DASHBOARD_META_COLLABORATIVE_CHARTS: 'SET_DASHBOARD_META_COLLABORATIVE_CHARTS',
  SET_DASHBOARD_META_COLLABORATIVE_FUNNEL_CHART: 'SET_DASHBOARD_META_COLLABORATIVE_FUNNEL_CHART',
  SET_DASHBOARD_META_COLLABORATIVE_FUNNEL_TABLE: 'SET_DASHBOARD_META_COLLABORATIVE_FUNNEL_TABLE',
  SET_DASHBOARD_META_COLLABORATIVE_TABLE_CAMPAIGN: 'SET_DASHBOARD_META_COLLABORATIVE_TABLE_CAMPAIGN',
  SET_DASHBOARD_META_COLLABORATIVE_TABLE_OBJECTIVE: 'SET_DASHBOARD_META_COLLABORATIVE_TABLE_OBJECTIVE',
  SET_DASHBOARD_META_COLLABORATIVE_PREVIEW_PURCHASE_ADS: 'SET_DASHBOARD_META_COLLABORATIVE_PREVIEW_PURCHASE_ADS',
  SET_DASHBOARD_META_COLLABORATIVE_PREVIEW_CONTENT_VIEWS: 'SET_DASHBOARD_META_COLLABORATIVE_PREVIEW_CONTENT_VIEWS',
  SET_DASHBOARD_META_LEAD_OVERVIEW_STATS: 'SET_DASHBOARD_META_LEAD_OVERVIEW_STATS',
  SET_DASHBOARD_META_LEAD_CHARTS: 'SET_DASHBOARD_META_LEAD_CHARTS',
  SET_DASHBOARD_META_LEAD_TABLE_CONVERSION: 'SET_DASHBOARD_META_LEAD_TABLE_CONVERSION',
  SET_DASHBOARD_META_LEAD_TABLE_TRAFFIC: 'SET_DASHBOARD_META_LEAD_TABLE_TRAFFIC',
  SET_DASHBOARD_META_LEAD_TABLE_LEADS: 'SET_DASHBOARD_META_LEAD_TABLE_LEADS',
  SET_DASHBOARD_META_LEAD_TABLE_BRAND_AWARENESS: 'SET_DASHBOARD_META_LEAD_TABLE_BRAND_AWARENESS',
  SET_DASHBOARD_META_LEAD_PREVIEW_LEAD_ADS: 'SET_DASHBOARD_META_LEAD_PREVIEW_LEAD_ADS',
  SET_DASHBOARD_META_MESSAGES_OVERVIEW_STATS: 'SET_DASHBOARD_META_MESSAGES_OVERVIEW_STATS',
  SET_DASHBOARD_META_MESSAGES_CHARTS: 'SET_DASHBOARD_META_MESSAGES_CHARTS',
  SET_DASHBOARD_META_MESSAGES_TABLE_CAMPAIGN: 'SET_DASHBOARD_META_MESSAGES_TABLE_CAMPAIGN',
  SET_DASHBOARD_META_MESSAGES_TABLE_OBJECTIVE: 'SET_DASHBOARD_META_MESSAGES_TABLE_OBJECTIVE',
  SET_DASHBOARD_META_MESSAGES_PREVIEW_MESSAGES: 'SET_DASHBOARD_META_MESSAGES_PREVIEW_MESSAGES',
  RESET_DASHBOARD_META_DATA: 'RESET_DASHBOARD_META_DATA',
  RESET_DASHBOARD_META_REDUCER: 'RESET_DASHBOARD_META_REDUCER',

  SET_DASHBOARD_TIKTOK_VISITED: 'SET_DASHBOARD_TIKTOK_VISITED',
  SET_DASHBOARD_TIKTOK_ACCOUNT: 'SET_DASHBOARD_TIKTOK_ACCOUNT',
  SET_DASHBOARD_TIKTOK_ACCOUNT_DETAILS_CURRENCY: 'SET_DASHBOARD_TIKTOK_ACCOUNT_DETAILS_CURRENCY',
  SET_DASHBOARD_TIKTOK_DATERANGE: 'SET_DASHBOARD_TIKTOK_DATERANGE',
  SET_DASHBOARD_TIKTOK_FILTER: 'SET_DASHBOARD_TIKTOK_FILTER',
  SET_DASHBOARD_TIKTOK_LATEST_SYNC: 'SET_DASHBOARD_TIKTOK_LATEST_SYNC',
  SET_DASHBOARD_TIKTOK_DATA: 'SET_DASHBOARD_TIKTOK_DATA',
  SET_DASHBOARD_TIKTOK_CT_OVERVIEW_STATS: 'SET_DASHBOARD_TIKTOK_CT_OVERVIEW_STATS',
  SET_DASHBOARD_TIKTOK_CT_CHARTS: 'SET_DASHBOARD_TIKTOK_CT_CHARTS',
  SET_DASHBOARD_TIKTOK_CT_FUNNEL_CHART: 'SET_DASHBOARD_TIKTOK_CT_FUNNEL_CHART',
  SET_DASHBOARD_TIKTOK_CT_FUNNEL_TABLE: 'SET_DASHBOARD_TIKTOK_CT_FUNNEL_TABLE',
  SET_DASHBOARD_TIKTOK_CT_TABLE_CAMPAIGN: 'SET_DASHBOARD_CT_TABLE_CAMPAIGN',
  SET_DASHBOARD_TIKTOK_CT_TABLE_OBJECTIVE: 'SET_DASHBOARD_CT_TABLE_OBJECTIVE',
  SET_DASHBOARD_TIKTOK_CT_TABLE_AUDIENCE: 'SET_DASHBOARD_CT_TABLE_AUDIENCE',
  SET_DASHBOARD_TIKTOK_CT_PREVIEW_CLICKS: 'SET_DASHBOARD_TIKTOK_CT_PREVIEW_CLICKS',
  SET_DASHBOARD_TIKTOK_CT_PREVIEW_PURCHASE: 'SET_DASHBOARD_TIKTOK_CT_PREVIEW_PURCHASE',
  SET_DASHBOARD_TIKTOK_CW_OVERVIEW_STATS: 'SET_DASHBOARD_TIKTOK_CW_OVERVIEW_STATS',
  SET_DASHBOARD_TIKTOK_CW_CHARTS: 'SET_DASHBOARD_TIKTOK_CW_CHARTS',
  SET_DASHBOARD_TIKTOK_CW_FUNNEL_CHART: 'SET_DASHBOARD_TIKTOK_CW_FUNNEL_CHART',
  SET_DASHBOARD_TIKTOK_CW_FUNNEL_TABLE: 'SET_DASHBOARD_TIKTOK_CW_FUNNEL_TABLE',
  SET_DASHBOARD_TIKTOK_CW_TABLE_CAMPAIGN: 'SET_DASHBOARD_CW_TABLE_CAMPAIGN',
  SET_DASHBOARD_TIKTOK_CW_TABLE_OBJECTIVE: 'SET_DASHBOARD_CW_TABLE_OBJECTIVE',
  SET_DASHBOARD_TIKTOK_CW_TABLE_AUDIENCE: 'SET_DASHBOARD_CW_TABLE_AUDIENCE',
  SET_DASHBOARD_TIKTOK_CW_PREVIEW_REACH: 'SET_DASHBOARD_TIKTOK_CW_PREVIEW_REACH',
  SET_DASHBOARD_TIKTOK_CW_PREVIEW_TRAFFIC: 'SET_DASHBOARD_TIKTOK_CW_PREVIEW_TRAFFIC',
  SET_DASHBOARD_TIKTOK_CW_PREVIEW_CONVERSION: 'SET_DASHBOARD_TIKTOK_CW_PREVIEW_CONVERSION',
  SET_DASHBOARD_TIKTOK_CI_OVERVIEW_STATS: 'SET_DASHBOARD_TIKTOK_CI_OVERVIEW_STATS',
  SET_DASHBOARD_TIKTOK_CI_CHARTS: 'SET_DASHBOARD_TIKTOK_CI_CHARTS',
  SET_DASHBOARD_TIKTOK_CI_FUNNEL_CHART: 'SET_DASHBOARD_TIKTOK_CI_FUNNEL_CHART',
  SET_DASHBOARD_TIKTOK_CI_FUNNEL_TABLE: 'SET_DASHBOARD_TIKTOK_CI_FUNNEL_TABLE',
  SET_DASHBOARD_TIKTOK_CI_TABLE_CAMPAIGN: 'SET_DASHBOARD_CI_TABLE_CAMPAIGN',
  SET_DASHBOARD_TIKTOK_CI_TABLE_AUDIENCE: 'SET_DASHBOARD_CI_TABLE_AUDIENCE',
  SET_DASHBOARD_TIKTOK_CI_PREVIEW_FOLLOWERS: 'SET_DASHBOARD_TIKTOK_CI_PREVIEW_FOLLOWERS',
  SET_DASHBOARD_TIKTOK_CI_PREVIEW_PROFILE_VISITS: 'SET_DASHBOARD_TIKTOK_CI_PREVIEW_PROFILE_VISITS',
  RESET_DASHBOARD_TIKTOK_DATA: 'RESET_DASHBOARD_TIKTOK_DATA',
  RESET_DASHBOARD_TIKTOK_REDUCER: 'RESET_DASHBOARD_TIKTOK_REDUCER',

  SET_DASHBOARD_WEBSITE_VISITED: 'SET_DASHBOARD_WEBSITE_VISITED',
  SET_DASHBOARD_WEBSITE_ACCOUNT: 'SET_DASHBOARD_WEBSITE_ACCOUNT',
  SET_DASHBOARD_WEBSITE_DATERANGE: 'SET_DASHBOARD_WEBSITE_DATERANGE',
  SET_DASHBOARD_WEBSITE_FILTER: 'SET_DASHBOARD_WEBSITE_FILTER',
  SET_DASHBOARD_WEBSITE_LATEST_SYNC: 'SET_DASHBOARD_WEBSITE_LATEST_SYNC',
  SET_DASHBOARD_WEBSITE_OVERVIEW_STATS: 'SET_DASHBOARD_WEBSITE_OVERVIEW_STATS',
  SET_DASHBOARD_WEBSITE_PAID_ORDER_DISTRIBUTION_LIST: 'SET_DASHBOARD_WEBSITE_PAID_ORDER_DISTRIBUTION_LIST',
  SET_DASHBOARD_WEBSITE_PAID_ORDER_DISTRIBUTION_MAP: 'SET_DASHBOARD_WEBSITE_PAID_ORDER_DISTRIBUTION_MAP',
  SET_DASHBOARD_WEBSITE_CHARTS: 'SET_DASHBOARD_WEBSITE_CHARTS',
  RESET_DASHBOARD_WEBSITE_DATA: 'RESET_DASHBOARD_WEBSITE_DATA',
  RESET_DASHBOARD_WEBSITE_REDUCER: 'RESET_DASHBOARD_WEBSITE_REDUCER',

  // BUDGET TRACKER GROUP
  SET_BUDGET_TRACKER_SAVED_CONFIGURATIONS: 'SET_BUDGET_TRACKER_SAVED_CONFIGURATIONS',
  SET_BUDGET_TRACKER_CONFIGURATION_DETAILS: 'SET_BUDGET_TRACKER_CONFIGURATION_DETAILS',
  SET_BUDGET_TRACKER_CREATE_OR_UPDATE: 'SET_BUDGET_TRACKER_CREATE_OR_UPDATE',
  SET_BUDGET_TRACKER_REMOVE_BUDGET: 'SET_BUDGET_TRACKER_REMOVE_BUDGET',
  SET_BUDGET_TRACKER_NOTIFICATIONS: 'SET_BUDGET_TRACKER_NOTIFICATIONS',

  // CUSTOM REPORT GROUP
  SET_CUSTOM_REPORT_ALL_METRICS: 'SET_CUSTOM-REPORT_ALL_METRICS',
  SET_CUSTOM_REPORT_CREATE_REPORT_CONFIG: 'SET_CUSTOM_REPORT_CREATE_REPORT_CONFIG',
  SET_CUSTOM_REPORT_UPDATE_REPORT_CONFIG: 'SET_CUSTOM_REPORT_UPDATE_REPORT_CONFIG',
  SET_CUSTOM_REPORT_SAVED_REPORTS: 'SET_CUSTOM_REPORT_SAVED_REPORTS',
  SET_CUSTOM_REPORT_OPENED_REPORT: 'SET_CUSTOM_REPORT_OPENED_REPORT',
  SET_CUSTOM_REPORT_OPENED_REPORT_NAME: 'SET_CUSTOM_REPORT_OPENED_REPORT_NAME',
  SET_CUSTOM_REPORT_GET_CONFIG_LIST: 'SET_CUSTOM_REPORT_GET_CONFIG_LIST',
  SET_CUSTOM_REPORT_UPSERT_CARD: 'SET_CUSTOM_REPORT_UPSERT_CARD',
  SET_CUSTOM_REPORT_GET_CARD_DATA: 'SET_CUSTOM_REPORT_GET_CARD_DATA',
  SET_CUSTOM_REPORT_CONFIG_LIST: 'SET_CUSTOM_REPORT_CONFIG_LIST',
  SET_CUSTOM_REPORT_DELETE_CARD: 'SET_CUSTOM_REPORT_DELETE_CARD',
  SET_CUSTOM_REPORT_DELETE_REPORT: 'SET_CUSTOM_REPORT_DELETE_REPORT',
  SET_CUSTOM_REPORT_PUBLIC_REPORT: 'SET_CUSTOM_REPORT_PUBLIC_REPORT',
  RESET_CUSTOM_REPORT_OPENED_REPORT: 'RESET_CUSTOM_REPORT_OPENED_REPORT',

  // USER GROUP
  SET_USER_BINDING_ACCOUNT: 'SET_USER_BINDING_ACCOUNT',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER_DATA_: 'SET_USER_DATA_',
  SET_USER_INTRO_GUIDE: 'SET_USER_INTRO_GUIDE',
  SET_USER_COACHMARK_LOCAL: 'SET_USER_COACHMARK_LOCAL',
  SET_USER_ONBOARDING: 'SET_USER_ONBOARDING',
  SET_USER_ONBOARDING_BINDING: 'SET_USER_ONBOARDING_BINDING',
  SET_USER_NOTIFICATIONS_GOOGLE_ADS: 'SET_USER_NOTIFICATIONS_GOOGLE_ADS',
  SET_USER_NOTIFICATIONS_GOOGLE_ANALYTICS: 'SET_USER_NOTIFICATIONS_GOOGLE_ANALYTICS',
  SET_USER_NOTIFICATIONS_INSTAGRAM: 'SET_USER_NOTIFICATIONS_INSTAGRAM',
  SET_USER_NOTIFICATIONS_MARKETPLACE: 'SET_USER_NOTIFICATIONS_MARKETPLACE',
  SET_USER_NOTIFICATIONS_META: 'SET_USER_NOTIFICATIONS_META',
  SET_USER_NOTIFICATIONS_TIKTOK: 'SET_USER_NOTIFICATIONS_TIKTOK',
  SET_USER_NOTIFICATIONS_WEBSITE: 'SET_USER_NOTIFICATIONS_WEBSITE',
  SET_USER_NOTIFICATIONS_BUDGET_TRACKER: 'SET_USER_NOTIFICATIONS_BUDGET_TRACKER',
  SET_USER_NOTIFICATIONS_ACCOUNT_BINDING: 'SET_USER_NOTIFICATIONS_ACCOUNT_BINDING',
  SET_USER_SYNC_ACCOUNT_DATA: 'SET_USER_SYNC_ACCOUNT_DATA',
  RESET_USER_REDUCER: 'RESET_USER_REDUCER',
};