import PropTypes from 'prop-types';
const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `Table`;
const propTypes = {
  title: pt.node,
  text: pt.node,
};
const defaultProps = {};

const getTemplateProps = () => ({
  tableProps: {
    actions: { copy: { enabled: false }, expand: { enabled: false} },
    allowSort: false,
    bordered: false,
    columns: [{ key: '', sorter: {}, title: '', width: 0 }],
    data: [{ key: '' }],
    fixed: '',
    loading: false,
    noFilter: false,
    resizable: true,
    title: '',
    titlePlacement: 'in',
    summary: false,
  },
  tabbedTablesProps: {
    actions: { copy: { enabled: false }, expand: { enabled: false } },
    allowSort: false,
    bordered: false,
    noFilter: false,
    resizable: true,
    tables: [{
      columns: [{ key: '', title: '' }],
      data: [{ key: '' }],
      fixed: '',
      loading: false,
      title: '',
    }],
    title: '',
    titlePlacement: 'out',
  },
})

export default { displayName, propTypes, defaultProps, getTemplateProps };