import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const platformDataStruct = { data: {} , error: null, loading: false };

const initialState = {
  visited: false,
  daterange: { ...globalStateConstants.daterange },

  googleAds: {
    accounts: [{ ...globalStateConstants.account.googleAds }],
    daterange: { ...globalStateConstants.daterange },
    filter: { ...globalStateConstants.filter.googleAds },
    data: { ...platformDataStruct },
  },
  googleAnalytics: {
    accounts: [{ ...globalStateConstants.account.googleAnalytics }],
    daterange: { ...globalStateConstants.daterange },
    filter: { ...globalStateConstants.filter.googleAnalytics },
    data: { ...platformDataStruct },
  },
  instagram: {
    accounts: [{ ...globalStateConstants.account.instagram }],
    daterange: { ...globalStateConstants.daterange },
    filter: { ...globalStateConstants.filter.instagram },
    data: { ...platformDataStruct },
  },
  marketplace: {
    accounts: [{ ...globalStateConstants.account.marketplace }],
    daterange: { ...globalStateConstants.daterange },
    filter: { ...globalStateConstants.filter.marketplace },
    data: { ...platformDataStruct },
  },
  meta: {
    accounts: [{ ...globalStateConstants.account.meta }],
    daterange: { ...globalStateConstants.daterange },
    filter: { ...globalStateConstants.filter.meta },
    data: { ...platformDataStruct },
  },
  tiktok: {
    accounts: [{ ...globalStateConstants.account.tiktok }],
    daterange: { ...globalStateConstants.daterange },
    filter: { ...globalStateConstants.filter.tiktok },
    data: { ...platformDataStruct },
  },
  website: {
    accounts: [{ ...globalStateConstants.account.website }],
    daterange: { ...globalStateConstants.daterange },
    filter: { ...globalStateConstants.filter.website },
    data: { ...platformDataStruct },
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_DASHBOARD_SUMMARY_VISITED: return {
      ...state, visited: true,
    };

    case TYPES.SET_DASHBOARD_SUMMARY_DATERANGE: return {
      ...state, daterange: { ...action.payload.daterange },
      googleAds: { ...state.googleAds, daterange: { ...action.payload.daterange } },
      googleAnalytics: { ...state.googleAnalytics, daterange: { ...action.payload.daterange } },
      instagram: { ...state.instagram, daterange: { ...action.payload.daterange } },
      marketplace: { ...state.marketplace, daterange: { ...action.payload.daterange } },
      meta: { ...state.meta, daterange: { ...action.payload.daterange } },
      tiktok: { ...state.tiktok, daterange: { ...action.payload.daterange } },
      website: { ...state.website, daterange: { ...action.payload.daterange } },
    };

    case TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ADS_ACCOUNTS: return {
      ...state, googleAds: { ...state.googleAds, accounts: [ ...(action.payload.accounts || []) ] },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ADS_DATERANGE: return {
      ...state, googleAds: { ...state.googleAds, daterange: { ...action.payload.daterange } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ADS_FILTER: return {
      ...state, googleAds: { ...state.googleAds, filter: { ...action.payload.filter } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ADS_DATA: return {
      ...state, googleAds: { ...state.googleAds, data: { ...action.payload } },
    };

    case TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_ACCOUNTS: return {
      ...state, googleAnalytics: { ...state.googleAnalytics, accounts: [ ...(action.payload.accounts || []) ] },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_DATERANGE: return {
      ...state, googleAnalytics: { ...state.googleAnalytics, daterange: { ...action.payload.daterange } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_FILTER: return {
      ...state, googleAnalytics: { ...state.googleAnalytics, filter: { ...action.payload.filter } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_DATA: return {
      ...state, googleAnalytics: { ...state.googleAnalytics, data: { ...action.payload } },
    };

    case TYPES.SET_DASHBOARD_SUMMARY_INSTAGRAM_ACCOUNTS: return {
      ...state, instagram: { ...state.instagram, accounts: [ ...(action.payload.accounts || []) ] },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_INSTAGRAM_DATERANGE: return {
      ...state, instagram: { ...state.instagram, daterange: { ...action.payload.daterange } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_INSTAGRAM_FILTER: return {
      ...state, instagram: { ...state.instagram, filter: { ...action.payload.filter } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_INSTAGRAM_DATA: return {
      ...state, instagram: { ...state.instagram, data: { ...action.payload } },
    };

    case TYPES.SET_DASHBOARD_SUMMARY_MARKETPLACE_ACCOUNTS: return {
      ...state,
      marketplace: { ...state.marketplace, accounts:  [ ...(action.payload.accounts || []) ] },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_MARKETPLACE_DATERANGE: return {
      ...state, marketplace: { ...state.marketplace, daterange: { ...action.payload.daterange } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_MARKETPLACE_FILTER: return {
      ...state, marketplace: { ...state.marketplace, filter: { ...action.payload.filter } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_MARKETPLACE_DATA: return {
      ...state, marketplace: { ...state.marketplace, data: { ...action.payload } },
    };

    case TYPES.SET_DASHBOARD_SUMMARY_META_ACCOUNTS: return {
      ...state, meta: { ...state.meta, accounts: [ ...(action.payload.accounts || []) ] },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_META_DATERANGE: return {
      ...state, meta: { ...state.meta, daterange: { ...action.payload.daterange } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_META_FILTER: return {
      ...state, meta: { ...state.meta, filter: { ...action.payload.filter } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_META_DATA: return {
      ...state, meta: { ...state.meta, data: { ...action.payload } },
    };

    case TYPES.SET_DASHBOARD_SUMMARY_TIKTOK_ACCOUNTS: return {
      ...state, tiktok: { ...state.tiktok, accounts: [ ...(action.payload.accounts || []) ] },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_TIKTOK_DATERANGE: return {
      ...state, tiktok: { ...state.tiktok, daterange: { ...action.payload.daterange } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_TIKTOK_FILTER: return {
      ...state, tiktok: { ...state.tiktok, filter: { ...action.payload.filter } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_TIKTOK_DATA: return {
      ...state, tiktok: { ...state.tiktok, data: { ...action.payload } },
    };

    case TYPES.SET_DASHBOARD_SUMMARY_WEBSITE_ACCOUNTS: return {
      ...state, website: { ...state.website, accounts: [ ...(action.payload.accounts || []) ] },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_WEBSITE_DATERANGE: return {
      ...state, website: { ...state.website, daterange: { ...action.payload.daterange } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_WEBSITE_FILTER: return {
      ...state, website: { ...state.website, filter: { ...action.payload.filter } },
    };
    case TYPES.SET_DASHBOARD_SUMMARY_WEBSITE_DATA: return {
      ...state, website: { ...state.website, data: { ...action.payload } },
    };

    case TYPES.RESET_DASHBOARD_SUMMARY_REDUCER: return { ...initialState };
  };
};