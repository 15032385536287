import dateConstants from './date.constants';
import stringConstants from './string.constants';

const commonAccountFields = {
  account_id: '',
  account_name: 'Select an Account',
  config_id: '',
  details: {},
  platform: {},
  error: null,
  loading: false,
};
const commonDaterangeState = {
  breakdown_interval: 'day',
  date_start_current: dateConstants.LAST_7_DAYS.STRINGSTART,
  date_end_current: dateConstants.LAST_7_DAYS.STRINGEND,
  date_start_compare: dateConstants.LAST_14_DAYS.STRINGSTART,
  date_end_compare: dateConstants.LAST_14_DAYS.STRINGEND,
  preset_range_value: 'last 7 days',
  preset_range_current: 'last 7 days',
  preset_range_compare: 'previous period',
  use_compare: true,
};
const commonFilterState = { filter_by: '', filter_exclude: false, filter_value: [] };

export default {
  account: {
    demo: { ...commonAccountFields },
    googleAds: {
      ...commonAccountFields,
      details: { currency: 'IDR', type: 'sem', sync: false },
      platform: stringConstants.googleAds.key,
    },
    googleAnalytics: {
      ...commonAccountFields,
      details: { currency: 'IDR', type: 'ua', sync: false },
      platform: stringConstants.googleAnalytics.key,
    },
    instagram: {
      ...commonAccountFields,
      details: { currency: 'IDR', type: '', sync: false },
      platform: stringConstants.instagram.key,
    },
    marketplace: {
      ...commonAccountFields,
      details: { currency: 'IDR', type: 'shopee', sync: false },
      platform: stringConstants.marketplace.key,
    },
    meta: {
      ...commonAccountFields,
      details: { currency: 'IDR', type: 'conversion', sync: false },
      platform: stringConstants.meta.key,
    },
    tiktok: {
      ...commonAccountFields,
      details: { currency: 'IDR', type: 'conversion_tiktok', sync: false },
      platform: stringConstants.tiktok.key,
    },
    website: {
      ...commonAccountFields,
      details: { currency: 'IDR', type: 'shopify', sync: false },
      platform: stringConstants.website.key,
    },
  },
  daterange: {
    ...commonDaterangeState,
  },
  filter: {
    demo: { ...commonFilterState },
    googleAds: { ...commonFilterState },
    googleAnalytics: { ...commonFilterState },
    instagram: { ...commonFilterState },
    marketplace: { ...commonFilterState },
    meta: { ...commonFilterState },
    tiktok: { ...commonFilterState },
    website: { ...commonFilterState },
  },
};
