import React, { useEffect } from "react";
import { format } from "date-fns";
import {
  Layout,
  Typography,
  Row,
  Col,
  Card,
  Button,
  Image,
  Radio,
  Space,
  Spin,
  Modal,
} from "antd";
import animations from "../../utils-v1/animations";
import { AVATAR_MARKETPLACE, AVATAR_WEBSITE, ICON_ROCKET } from "../../assets-v1/figma-icons";
import { BDDAI_WITH_TEXT, ONBOARDING_9 } from "../../assets-v1/images";
import { extractParamFromUrl } from "../../utils-v1/request.utils";
import OnboardingPopUp from "../../components/Onboarding/OnboardingPopUp/OnboardingPopUp.component";

import FormWebsite from "./FormWebsite/FormWebsite.component";

import config from './OnboardingTour.component.config';
import './OnboardingTour.component.styles.less';

const { Text, Title } = Typography;

const OnboardingTour = ({ ...props }) => {
  const [state, setState] = React.useState({
    currentStep: 'main',
    selectionPlatform: 'meta',
    selectionMarketplace: null,
    waitingReturnCallback: false,
    openModalPopUp: false,
    haveFinished: false,
    onLoadingBinding: false,
  });
  const refWebsite = React.useRef();

  useEffect(() => {
    const lstorage = localStorage.getItem('ONBOARDING_TEMP');
    if (lstorage !== undefined) {
      const storageParse = JSON.parse(localStorage.getItem('ONBOARDING_TEMP'));

      setState((prevState) => {
        const current = { ...prevState };
        current.waitingReturnCallback = storageParse?.waitingReturnCallback !== undefined ? storageParse?.waitingReturnCallback : true;
        current.onLoadingBinding = storageParse?.onLoadingBinding;
        
        if (current.waitingReturnCallback) {
          current.selectionPlatform = storageParse?.platform;
          
          if (storageParse?.platform === 'marketplace') {
            current.currentStep = 'marketplace';
            current.selectionPlatform = storageParse?.platform;
            current.selectionMarketplace = storageParse?.marketplace;

            if (storageParse?.marketplace === 'shopee') {
              const shopeeData = {
                code: extractParamFromUrl('code'),
                shop_id: extractParamFromUrl('shop_id'),
                account_type: storageParse.marketplace,
              };
              props.bindMarketplace(shopeeData).then(() => {
                current.openModalPopUp = true;
                current.waitingReturnCallback = false;
              });
            }
  
          } else if (storageParse?.platform === 'tiktok') {
            current.currentStep = 'main';
            current.selectionPlatform = storageParse?.platform;
            current.selectionMarketplace = null;
            
            props.bindTiktok(extractParamFromUrl('auth_code')).then(() => {
              current.openModalPopUp = true;
              current.waitingReturnCallback = false;
            });
          } else if (storageParse?.platform === 'google-ads') {
            current.currentStep = 'main';
            current.selectionPlatform = storageParse?.platform;
            current.selectionMarketplace = null;

            props.bindGoogleAds(extractParamFromUrl('code'), window.location.href).then(() => {
              current.openModalPopUp = true;
              current.waitingReturnCallback = false;
            });
          } else if (storageParse?.platform === 'google-analytics') {
            current.currentStep = 'main';
            current.selectionPlatform = storageParse?.platform;
            current.selectionMarketplace = null;
            
            props.bindGoogleAnalytics(extractParamFromUrl('code'), window.location.href).then(() => {
              current.openModalPopUp = true;
              current.waitingReturnCallback = false;
            });
          }

        }

        if (current.waitingReturnCallback === false) {
          localStorage.removeItem('ONBOARDING_TEMP');
        }
        current.onLoadingBinding = false;
        return { ...current };
      });
    }
  }, [
    state.waitingReturnCallback,
  ]);

  const bindingPlatform = (dataExtends = null) => {

    setState((prevState) => {
      const current = { ...prevState };
      current.onLoadingBinding = true;

      return { ...current };
    });

    switch (state.selectionPlatform) {
      case 'meta':
        props.bindMeta().then(() => {
          setState((prevState) => {
            const current = { ...prevState };
            current.onLoadingBinding = false;
            current.openModalPopUp = true;
            current.waitingReturnCallback = true;
      
            return { ...current };
          });
        });
        break;
      case 'tiktok':
        props.getTiktokAuthUrl();
        setState((prevState) => {
          const current = { ...prevState };
          current.waitingReturnCallback = true;
          current.onLoadingBinding = false;
    
          return { ...current };
        });
        break;
      case 'google-ads':
        props.getGoogleAdsAuthUrl(window.location.href);
        setState((prevState) => {
          const current = { ...prevState };
          current.waitingReturnCallback = true;
          current.onLoadingBinding = false;
    
          return { ...current };
        });
        break;
      case 'google-analytics':
        props.getGoogleAnalyticsAuthUrl(window.location.href);
        setState((prevState) => {
          const current = { ...prevState };
          current.waitingReturnCallback = true;
          current.onLoadingBinding = false;
    
          return { ...current };
        });
        break;
      case 'instagram':
        props.bindInstagram().then(() => {
          setState((prevState) => {
            const current = { ...prevState };
            current.openModalPopUp = true;
            current.onLoadingBinding = false;
            current.waitingReturnCallback = true;
      
            return { ...current };
          });
        });;
        break;
      case 'website':
        props.bindWebsite(dataExtends).then(() => {
          setState((prevState) => {
            const current = { ...prevState };
            current.openModalPopUp = true;
            current.waitingReturnCallback = true;
            current.onLoadingBinding = false;
      
            return { ...current };
          });
        });;
        break;
      case 'marketplace':
        props.generateMarketplaceAuthUrl({
          account_type: state.selectionMarketplace,
          redirect: window.location.href,
        });
        setState((prevState) => {
          const current = { ...prevState };
          current.waitingReturnCallback = true;
          current.onLoadingBinding = false;
    
          return { ...current };
        });
        break;
      default:
        break;
    }
    localStorage.setItem('ONBOARDING_TEMP', JSON.stringify({
      platform: state.selectionPlatform,
      marketplace: state.selectionMarketplace,
      waitingReturnCallback: state.waitingReturnCallback,
      onLoadingBinding: false,
    }));
  }

  const getPreparingBindWebsite = () => {
    const fields = refWebsite.current.getFieldsValue();
    const data = {
      account_id: fields.account_id,
      account_name: fields.account_name,
      details: {
        type: fields.type,
        web_api_key: fields.web_api_key,
        web_api_password: fields.web_api_password
      }
    }
    bindingPlatform(data);
  };

  const nextStepMain = () => {
    const ignore = ['website', 'marketplace'];
    if (ignore.indexOf(state.selectionPlatform) >= 0) {
      setState((prevState) => {
        const current = { ...prevState };
        current.currentStep = current.selectionPlatform;
        return { ...current };
      });
    } else {
      bindingPlatform();
    }
  };

  const backToMainContent = () => {
    setState((prevState) => {
      const current = { ...prevState };
      current.currentStep = 'main';
      return { ...current };
    });
  };

  const selectedPlatform = (items) => {
    setState((prevState) => {
      const current = { ...prevState };
      current.selectionPlatform = items.key;
      localStorage.setItem('ONBOARDING_TEMP', JSON.stringify({ platform: current.selectionPlatform, marketplace: current.selectionMarketplace }));

      return { ...current };
    });
  };

  const selectedMarketplace = (items) => {
    setState((prevState) => {
      const current = { ...prevState };
      current.selectionMarketplace = items.key;

      return { ...current };
    });
  };

  const buttonSkipOnboard = () => (
    <Button
      key="btn-skip-main"
      type="link"
      className="btn-link-skip"
      onClick={() => {
        setState((prevState) => {
          const current = { ...prevState };
          current.openModalPopUp = false;
          current.haveFinished = true;
          current.currentStep = 'finish';
    
          return { ...current };
        });
      }}
    >
      Did you already use BDD.AI? <span className="span-link">Skip the tour</span>
    </Button>
  );

  const onFinishOnboarding = (pathForward) => {
    const userOnboarding = props?.user?.onBoarding;
    const onboarding = {
      ...userOnboarding,
      onboarding_status: {
        ...userOnboarding?.onboarding_status,
        haveFinished: 1,
      }
    }
    props?.updateUserOnboarding(onboarding).then(() => {
      props?.getUserOnboarding().then(() => {
        props?.getAccountConfig().then(() => {
          window.location.replace(`/${pathForward}`);
        });
      });
    });
  };

  const renderingContent = () => ({
    'main': {
      animation: animations.popIn,
      title: 'Pick One Available Platform',
      description: null,
      content: () => {
        const listPlatform = config.onboardPlatform;
        const renderContent = (
          <Row gutter={24}>
            {listPlatform.map((items) => (
              <Col xs={24} md={6} lg={6} xl={6} key={items.key}>
                <Card
                  key={items.key}
                  className={`card-onboarding-platform ${state.selectionPlatform === items.key && 'selected-card'}`}
                  onClick={() => selectedPlatform(items)}
                >
                  <div className="onboard-title">
                    <div className="logos-title">
                      <Image src={items.icon} preview={false} width={25} />
                      <Title level={3}>{items.platform}</Title>
                    </div>
                    <div className="selection">
                      <Radio checked={state.selectionPlatform === items.key} />
                    </div>
                  </div>
                  <div className="description">
                    <Text>{items.description}</Text>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        );

        return renderContent;
      },
      footer: [
        buttonSkipOnboard(),
        <Button
          key="btn-next-main"
          type="primary"
          onClick={() => nextStepMain()}
        >
          Next Step
        </Button>,
      ],
    },
    'website': {
      animation: animations.fadeInLeft,
      title: <>
        <Image src={AVATAR_WEBSITE} preview={false} width={30} style={{ position: 'relative', top: -3 }} />
        &nbsp;Add Website Account
      </>,
      description: 'Connect your Shopify, Presta Shop, Woo Commerce to BDD.AI by following these steps',
      content: () => <FormWebsite refWebsite={refWebsite} />,
      footer: [
        buttonSkipOnboard(),
        <Space key="space-website">
          <Button
            type="default"
            onClick={() => backToMainContent()}
          >
            Previous Step
          </Button>
          <Button
            type="primary"
            onClick={() => getPreparingBindWebsite()}
          >
            Next Step
          </Button>
        </Space>,
      ],
    },
    'marketplace': {
      animation: animations.fadeInLeft,
      title: <>
        <Image src={AVATAR_MARKETPLACE} preview={false} width={30} style={{ position: 'relative', top: -3 }} />
        &nbsp;Add Marketplace Account
      </>,
      description: 'Connect your Shopee account to BDD.AI by following this step',
      content: () => (
        <div id="formMarketplace">
          {config.onboardMarketplace.map((items) => (
            <Card
              key={items.key}
              className={`card-onboarding-marketplace ${state.selectionMarketplace === items.key && 'selected-card'}`}
              onClick={() => selectedMarketplace(items)}
            >
              <div className="onboard-marketplace">
                <Radio checked={state.selectionMarketplace === items.key} />
                <Image src={items.icon} preview={false} width={25} />
                <Title level={3}>{items.platform}</Title>
              </div>
            </Card>
          ))}
        </div>
      ),
      footer: [
        buttonSkipOnboard(),
        <Space key="space-marketplace">
          <Button
            type="default"
            onClick={() => backToMainContent()}
          >
            Previous Step
          </Button>
          <Button
            type="primary"
            onClick={() => bindingPlatform()}
          >
            Next Step
          </Button>
        </Space>,
      ],
    },
    'finish': {
      animation: animations.popIn,
      title: <Image src={ONBOARDING_9} preview={false} />,
      description: 'Congratulations on successfully completing your onboarding tour! Now you can explore more the app.',
      content: () => 
        <Button
          type="primary"
          style={{ height: 50, padding: '10px 30px' }}
          onClick={() => onFinishOnboarding('dashboard')}
        >
          Get Started &nbsp; <Image src={ICON_ROCKET} preview={false} width={25} />
        </Button>,
      footer: (
        <Text style={{ fontSize: 16, color: '#A6AAB1'}}>
          © Copyright
          BDD.AI {format(new Date(), 'yyyy ')}. All right reserved
        </Text>
      ),
    },
  });

  const renderMainContent = () => {
    const choosenContent = renderingContent()[`${state.currentStep}`];
    
    return (
      <div className="MainContent" style={{ animation: choosenContent?.animation }}>
        <div className="header-content">
          <Title level={1}>{choosenContent?.title}</Title>
          <Text>{choosenContent?.description}</Text>
        </div>
        <div className="content">
          {choosenContent?.content()}
        </div>
        <div className="footer" style={{ justifyContent: state.currentStep === 'finish' ? 'center' : 'space-between' }}>
          {choosenContent?.footer}
        </div>
      </div>
    );
  };

  const constructOnboardPopUp = {
    openModal: state.openModalPopUp,
    content: 'onTour',
    dots: false,
    skipPlatform: () => {
      setState((prevState) => {
        const current = { ...prevState };
        current.currentStep = 'finish';
        current.openModalPopUp = false;
        current.haveFinished = true;

        onFinishOnboarding(state.selectionPlatform);
        return { ...current };
      });
    },
    skipBudget: () => {
      setState((prevState) => {
        const current = { ...prevState };
        current.currentStep = 'finish';
        current.openModalPopUp = false;
        current.haveFinished = true;

        return { ...current };
      });
    },
    jumpToBudget: () => {
      setState((prevState) => {
        const current = { ...prevState };
        current.currentStep = 'finish';
        current.openModalPopUp = false;
        current.haveFinished = true;

        onFinishOnboarding(state.selectionPlatform);
        return { ...current };
      });
    },
  };

  return (
    <Layout id="OnboardingTour">
      <div className="logo-onboarding"><BDDAI_WITH_TEXT /></div>
      {renderMainContent()}
      <Modal
        className="loading-spin"
        centered
        closable={false}
        open={state.onLoadingBinding}
        footer={false}
        width={320}
      >
        <Space>
          <Spin size="default" />
          <div>Please wait, binding still working ...</div>
        </Space>
      </Modal>
      <OnboardingPopUp { ...constructOnboardPopUp } />
    </Layout>
  );
};

OnboardingTour.displayName = config.displayName;
OnboardingTour.propTypes = config.propTypes;
OnboardingTour.defaultProps = config.defaultProps;

export default OnboardingTour;
