import path from './path';

import LandingDashboard from '../containers/LandingDashboard/LandingDashboard.container';
import LandingUser from '../containers/LandingUser/LandingUser.container';
import LandingPublic from '../containers/LandingPublic/LandingPublic.container';
import LandingDocs from '../containers/LandingDocs/LandingDocs.container';

export const BASE_ROUTE = [
  {
    id: 'login',
    path: path.login,
    component: LandingUser,
    exact: true,
  },
  {
    id: 'forgot-password',
    path: path.forgotPassword,
    component: LandingUser,
    exact: true,
  },
  {
    id: 'register',
    path: path.register,
    component: LandingUser,
    exact: true,
  },
  {
    id: 'docs',
    path: path.docs,
    component: LandingDocs,
  },
  {
    id: 'public',
    path: path.public,
    component: LandingPublic,
  },
  {
    id: 'dashboard',
    path: path.dashboard,
    component: LandingDashboard,
  },
];
