import React from 'react';
import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  BINDING_FORM_CLOSE_ICON,
  ICON_EDIT,
  ICON_TRASH,
} from '../../../assets-v1/figma-icons';
import BindingForms from '../BindingForms/BindingForms.component';

import stringConstants from '../../../constants-v1/string.constants';

import config from './AccountCard.component.config';
import './AccountCard.component.style.less';

const { Title, Text, Paragraph } = Typography;
const platformsWithSyncStatus = [
  stringConstants.tiktok.key,
  stringConstants.marketplace.key,
  stringConstants.meta.key,
  stringConstants.website.key,
];

const AccountCard = ({
  account,
  icon,
  platformKey,
  platformName,
  onDelete,
  onEdit,
}) => {
  const [state, setState] = React.useState({
    modalDeleteVisibility: false,
    modalEditVisibility: false,
  });

  const renderModalDelete = () => (
    <Modal
      onCancel={() => setState({ ...state, modalDeleteVisibility: false })}
      onOk={() => { onDelete(account); setState({ ...state, modalDeleteVisibility: false }) }}
      open={state.modalDeleteVisibility}
      title={<div style={{ textAlign: 'center' }}>
        <Image className="img-delete" width={75} src={ICON_TRASH} alt="icon-delete" preview={false} />
        <Title level={3}>Confirm removal of account</Title>
      </div>}
    >
      <Paragraph>
        Are you sure you want to remove <b>{account?.account_name}</b> from your <b>{platformName}</b> accounts list?
      </Paragraph>
    </Modal>
  );

  const renderModalEdit = () => (
    <Modal
      closeIcon={<Image src={BINDING_FORM_CLOSE_ICON} alt="binding_form_close_icon" preview={false} />}
      okButtonProps={{ hidden: true }}
      onCancel={() => setState({ ...state, modalEditVisibility: false })}
      open={state.modalEditVisibility}
      title={<div style={{ textAlign: 'left' }}>
        <Title level={2}>Edit {platformName} Account</Title>
      </div>}
    >
      <BindingForms
        account={account}
        command="edit"
        onEdit={() => { onEdit(account); setState({ ...state, modalEditVisibility: false }) }}
        platformKey={platformKey}
      />
    </Modal>
  );

  return(
    <Card style={{ border: '1px solid #D0D5DD', borderRadius: 6, padding: 10, marginBottom: 10 }}>
      <div className="account-item">
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className={`account-item-logo ${platformKey}`} >
            <Avatar shape="circle" size={30} src={icon}/>
          </div>
          <div className="account-metadata">
            <Text ellipsis className="account-item-name">{account?.account_name}</Text>
            <Text ellipsis type="secondary" className="account-name-subname">{account?.account_id}</Text>
            {platformsWithSyncStatus.includes(account?.platform) && 
              <Text ellipsis className="account-sync-status" style={{ color: account?.details?.sync ? '#59BC7D' : '#FF683A' }}>
                {account?.details?.sync ? '' : 'not'} synchronized
              </Text>
            || null}
          </div>
        </div>
        <Space direction="horizontal" size={2}>
          <Button
            hidden={typeof onEdit !== 'function'}
            onClick={() => setState({ ...state, modalEditVisibility: true })}
            type="link"
          >
            <Image src={ICON_EDIT} alt="icon-pencil" preview={false} style={{ color: '#018FFC', position: 'relative', top: -2 }} />
            <Text style={{ color: '#018FFC', fontSize: 16, marginLeft: 10 }}>Edit</Text>
          </Button>
          <Button
            className="btn-link-delete"
            hidden={typeof onDelete !== 'function'}
            type="link"
            onClick={() => setState({ ...state, modalDeleteVisibility: true })}
          >
            <Image src={ICON_TRASH} alt="icon-delete" preview={false} style={{ color: '#F04438', position: 'relative', top: -2 }} />
            <span style={{ color: '#F04438', fontSize: 16, marginLeft: 10 }}>Delete</span>
          </Button>
        </Space>
      </div>
      {renderModalDelete()}
      {renderModalEdit()}
    </Card>
  );
};

AccountCard.displayName = config.displayName;
AccountCard.propTypes = config.propTypes;
AccountCard.defaultProps = config.defaultProps;

export default AccountCard;
