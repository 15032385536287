import PropTypes from 'prop-types';
import mapIndo from '@highcharts/map-collection/countries/id/id-all.geo.json';
import mapWorld from '@highcharts/map-collection/custom/world.geo.json';
import { categoriesFormatter, seriesFormatter, tooltipFormatter, reflowChart, getTickInterval } from '../../utils-v1/chart.utils';
import { bddColor, hexToRgb } from '../../utils-v1/colors.utils';

const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `Chart`;
const propTypes = {};
const defaultProps = {};

const { isArray } = Array;
const getColorSet = (daterange={}, dataset=[], type='bar') => {
  /* let withCompare = []; let withoutCompare =[];
  if (type === 'line') {
    if (Array.isArray(dataset) && dataset?.length === 2 && daterange?.use_compare) {
      withCompare = ['#59BC7D', '#018FFC'];
      withoutCompare = ['#59BC7D'];
    } else {
      withCompare = [
        '#59BC7D', '#3F8559',
        '#018FFC', '#0166B3',
        '#F5BD00', '#F5BD00',
        '#5900d9', '#ceb0fa',
        '#e85f35', '#ffd0c2',
      ];
      withoutCompare = withCompare.map((c, i) => (i%2 > 0) ? null : c).filter(c => c !== null);
    };
  } else {
    if (Array.isArray(dataset) && dataset?.length === 2 && daterange?.use_compare) {
      withCompare = ['#51ab72', '#dfac00']; // green, yellow
      withoutCompare = ['#51ab72'];
    } else {
      withCompare = [
        '#51ab72', '#ccead7', // green,
        '#dfac00', '#fcebb0', // yellow,
        '#0182e5', '#b0dcfe', // blue,
        '#5900d9', '#ceb0fa', // purple,
        '#e85f35', '#ffd0c2', // orange,
      ];
      withoutCompare = withCompare.map((c, i) => (i%2 > 0) ? null : c).filter(c => c !== null);
    };
  };
  return daterange?.use_compare ? withCompare : withoutCompare; */
  const colorset = [
    '#3C41CC',
    '#FF683A',
    '#018FFC',
    '#F5BD00',
    '#59BC7D',
    '#1B5468',
    '#CFB86A',
    '#9C6644',
    '#F28482',
    '#E63946',
  ];
  return colorset;
};

const constructTemplateProps = () => { /* EXPORT THIS */
  const sharedProps = {
    daterange: {},
    actions: { copy: { enabled: false }, expand: { enabled: false } },
    callback: { reflowChart },
    advancedConfig: { showValuesInChart: false },
    hover: { enabled: false },
    legend: { align: 'left', enabled: false, position: 'top' },
    loading: false,
    size: 300,
    title: '',
    tooltip: { crosshairs: true, enabled: false, shared: false },
    valueFormatter: (val=0) => val.toLocaleString('en'),
  };

  const detailedProps = {
    barProps: {
      ...sharedProps,
      advancedConfig: { ...sharedProps.advancedConfig, stacked: false },
      data: [{ color: '', label: '', entries: [] }],
      xAxis: { categories: { splitter: '/' }, title: '', visible: false },
      yAxis: { position: 'left', ticked: false, title: '', visible: false },
    },
    combinationProps: {
      ...sharedProps,
      data: [{ color: '', label: '', entries: [] }],
      xAxis: { categories: { splitter: '/' }, title: '', visible: false },
      yAxis: { position: 'left', ticked: false, title: '', visible: false },
    },
    donutProps: {
      ...sharedProps,
      data: [[]],
      // legend: { enabled: false },
    },
    heatmapProps: {
      ...sharedProps,
      advancedConfig: { ...sharedProps.advancedConfig, minColor: bddColor.WHITE, maxColor: bddColor.BLUE_MAIN },
      data: [[]],
      xAxis: { categories: { splitter: '/' }, title: '', visible: false },
      yAxis: { position: 'left', ticked: false, title: '', visible: false },
    },
    lineProps: {
      ...sharedProps,
      data: [{ color: '', label: '', entries: [] }],
      xAxis: { categories: { splitter: '/' }, title: '', visible: false },
      yAxis: { position: 'left', ticked: false, title: '', visible: false },
    },
    mapProps: {
      ...sharedProps,
      advancedConfig: { ...sharedProps.advancedConfig, showTable: false },
      data: { map: [], table: [] },
      mapType: '',
    },
  };

  return detailedProps;
};

const constructOptions = (type='', props={}, forDrawer=0) => { /* EXPORT THIS */
  const templateProps = constructTemplateProps();
  const identifier = `${type}Props`;
  const defProps = templateProps[identifier];
  const formatSeries = seriesFormatter?.[type];
  const formatTooltip = tooltipFormatter?.[type];
  let altType;
  switch (type) {
    default: altType = type; break;
    case 'bar': altType = 'column'; break;
    case 'donut': altType = 'pie'; break;
    case 'line': altType = 'spline'; break;
  };

  const innerSize = props?.advancedConfig?.innerSize || defProps?.advancedConfig?.innerSize;
  const isBarStacked = props?.advancedConfig?.stacked || defProps?.advancedConfig?.stacked;
  const isTooltipShared = props?.tooltip?.shared || defProps?.tooltip?.shared;
  const isYAxisTicked = props?.yAxis?.ticked || defProps?.yAxis?.ticked;
  const daterange = props?.daterange || defProps?.daterange;
  const enableLegend = props?.legend?.enabled || defProps?.legend?.enabled;
  const mapType = props?.mapType || defProps?.mapType;
  const rawSeriesSet = type === 'map' ? (isArray(props?.data?.map) && props?.data?.map || defProps?.data?.map)
    : (isArray(props?.data) && props?.data || defProps?.data);
  const showDonutPercentages = props?.advancedConfig?.showPercentage || defProps?.advancedConfig?.showPercentage;
  const showTable = props?.advancedConfig?.showTable || defProps?.advancedConfig?.showTable;
  const showValuesInChart = props?.advancedConfig?.showValuesInChart || defProps?.advancedConfig?.showValuesInChart;
  const tickInterval = props?.advancedConfig?.tickInterval || defProps?.advancedConfig?.tickInterval;
  const totalDonut = type === 'donut' && [0, ...rawSeriesSet?.map(e => e?.value || e?.[1])]?.reduce((s, e) => s + e) || 0;
  const xLabelSplitter = props?.xAxis?.categories?.splitter || defProps?.xAxis?.categories?.splitter;
  const valueFormatter = props?.valueFormatter || defProps?.valueFormatter;
  const sharedOptions = {
    accessibility: { point: { valueDescriptionFormat: '', /* '{index}. Age {xDescription}, {value}.', */ } },
    chart: {
      height: forDrawer ? (type !== 'donut' && `20%` || (daterange?.use_compare && `60%` || `50%`)) : props?.size,
      // numberFormatter: valueFormatter,
      type: altType,
      backgroundColor: forDrawer ? props?.backgroundDrawer : 'transparent', // props?.backgroundDefault,
    },
    credits: { enabled: false },
    lang: { noData: type === 'donut' ?
      `<span style="font-size:24px">No data to show</span>` : 
      `<span style="font-size:24px">No Data</span>`
    },
    legend: {
      align: props?.legend?.align || defProps?.legend?.align,
      enabled: type === 'donut' ? false : (props?.legend?.enabled || defProps?.legend?.enabled),
      verticalAlign: props?.legend?.position || defProps?.legend?.position,
      symbolPadding: 0,
      symbolWidth: 0,
      symbolHeight: 0,
      squareSymbol: false,
      itemDistance: 2,
      itemHoverStyle:{"color": "#000000"},
      useHTML: true,
      labelFormatter() {
        return `<div style="display: flex; align-items:center; padding: 2px 5px; border: 1px solid #E9E9E9; border-radius: 5px; margin-bottom: 2px;">
          <div style="width: 15px; height: 15px; border-radius: 5px; background-color: ${this.color}; margin-right: 10px;"></div>
          <div>${this.name}</div>
        </div>`;
      },
    },
    plotOptions: {
      series: {
        enableMouseTracking: props?.hover?.enabled || defProps?.hover?.enabled,
        states: { hover: { enabled: !(props?.tooltip?.shared || defProps?.tooltip?.shared) } },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            minHeight: props?.size * 1.2,
          },
        },
      ],
    },
    series: formatSeries({ colorSet: getColorSet(daterange, rawSeriesSet, type), enableLegend, mapType, rawSeriesSet, showValuesInChart, innerSize }),
    title: { text: '' },
    tooltip: {
      useHTML: true,
      crosshairs: true,
      padding: 1,
      formatter() {
        const data = this;
        return formatTooltip({
          colorSet: getColorSet(daterange, rawSeriesSet, type),
          data,
          rawSeriesSet,
          shared: isTooltipShared,
          totalDonut,
          showDonutPercentages,
          valueFormatter
        })
      },
      enabled: props?.tooltip?.enabled || defProps?.tooltip?.enabled,
      shared: props?.tooltip?.shared || defProps?.tooltip?.shared,
      style: {
        border: false,
      }
    },
  };

  let detailedOptions;
  switch (type) {
    default: detailedOptions = sharedOptions; break;

    case 'bar': detailedOptions = { ...sharedOptions,
      plotOptions: { ...sharedOptions.plotOptions,
        column: {
          ...(!isBarStacked && { borderRadius: 5 }),
          borderWidth: 0,
          dataLabels: {
            allowOverlap: true,
            enabled: showValuesInChart,
            useHTML: true,
            formatter() {
              const convertToRGB = hexToRgb(this.color);
              return `
              <div
                style="
                  width:auto;
                  height: auto;
                  border: 1px solid ${this.color};
                  border-radius: 15px;
                  padding: 0px 5px;
                  background-color:rgb(${convertToRGB.r}, ${convertToRGB.g}, ${convertToRGB.b}, 0.2);
                  color: ${this.color};
                  font-size: 10px;
                  font-weight: 500;
                  z-index:1;
                "
              >
                ${valueFormatter(this.y)}
              </div>`;
            }
          },
          pointPadding: 0.2,
          ...(isBarStacked && { stacking: 'normal' }),
        },
      },
      xAxis: {
        categories: categoriesFormatter.bar({ rawSeriesSet, splitter: xLabelSplitter }),
        crosshair: true,
        tickInterval: tickInterval || getTickInterval({ rawSeriesSet, forDrawer }),
        title: { text: props?.xAxis?.title || defProps?.xAxis?.title },
        visible: props?.xAxis?.visible || defProps?.xAxis?.visible,
        labels: { autoRotation: false },
      },
      yAxis: {
        gridLineColor: bddColor.WHITE_THIN_GRAY,
        min: 0, ...(props?.yAxis?.max && { max: props?.yAxis?.max }), 
        opposite: props?.yAxis?.position === 'right' || defProps?.yAxis?.position === 'right',
        title: { text: props?.yAxis?.title || defProps?.yAxis?.title },
        visible: props?.yAxis?.visible || defProps?.yAxis?.visible,
        ...(isBarStacked && { stackLabels: { enabled: false } || { labels: { enabled: isYAxisTicked } } }),
      },
    }; break;

    case 'combination': detailedOptions = { ...sharedOptions,
      plotOptions: { ...sharedOptions.plotOptions,
        column: {
          borderRadius: 5,
          dataLabels: {
            allowOverlap: true,
            enabled: showValuesInChart,
            useHTML: true,
            formatter() {
              const convertToRGB = hexToRgb(this.color);
              return `
              <div
                style="
                  width:auto;
                  height: auto;
                  border: 1px solid ${this.color};
                  border-radius: 15px;
                  padding: 0px 5px;
                  background-color:rgb(${convertToRGB.r}, ${convertToRGB.g}, ${convertToRGB.b}, 0.2);
                  color: ${this.color};
                  font-size: 10px;
                  font-weight: 500;
                  z-index:1;
                "
              >
                ${valueFormatter(this.y)}
              </div>`;
            }
          },
        },
        line: {
          dataLabels: {
            allowOverlap: true,
            enabled: showValuesInChart,
            useHTML: true,
            formatter() {
              const convertToRGB = hexToRgb(this.color);
              return `
              <div
                style="
                  width:auto;
                  height: auto;
                  border: 1px solid ${this.color};
                  border-radius: 15px;
                  padding: 0px 5px;
                  background-color:rgb(${convertToRGB.r}, ${convertToRGB.g}, ${convertToRGB.b}, 0.2);
                  color: ${this.color};
                  font-size: 10px;
                  font-weight: 500;
                  z-index:1;
                "
              >
                ${valueFormatter(this.y)}
              </div>`;
            }
          },
          lineWidth: 5,
          marker: { enabled: false },
        },
      },
      xAxis: {
        categories: categoriesFormatter.line({ rawSeriesSet, splitter: xLabelSplitter }),
        crosshair: true,
        tickInterval: tickInterval || getTickInterval({ rawSeriesSet, forDrawer }),
        title: { text: props?.xAxis?.title || defProps?.xAxis?.title },
        visible: props?.xAxis?.visible || defProps?.xAxis?.visible,
        labels: { autoRotation: false },
      },
      yAxis: [
        {
          gridLineColor: bddColor.WHITE_THIN_GRAY,
          labels: { enabled: props?.yAxis?.ticked || defProps?.yAxis?.ticked },
          min: 0,
          opposite: false,
          title: { text: props?.yAxis?.title || defProps?.yAxis?.title },
          visible: props?.yAxis?.visible || defProps?.yAxis?.visible,
        },
        {
          gridLineColor: bddColor.WHITE_THIN_GRAY,
          labels: { enabled: props?.yAxis?.ticked || defProps?.yAxis?.ticked },
          min: 0,
          opposite: true,
          title: { text: props?.yAxis?.title || defProps?.yAxis?.title },
          visible: props?.yAxis?.visible || defProps?.yAxis?.visible,
        },
      ],
    }; break;

    case 'donut': detailedOptions = { ...sharedOptions,
      plotOptions: { ...sharedOptions.plotOptions,
        pie: {
          borderRadius: 1,
          borderColor: null,
          borderWidth: 0,
          colors: Array.isArray(props?.data) && !props?.data?.map(e => e?.color)?.includes(undefined) ?
            props?.data?.map(e => e?.color) : getColorSet(daterange, rawSeriesSet, type),
          dataLabels: { enabled: showValuesInChart },
          pointPadding: 0.2,
          shadow: false,
        },
      },
    }; break;

    case 'heatmap': detailedOptions = { ...sharedOptions,
      colorAxis: {
        min: 0,
        minColor: props?.advancedConfig?.minColor || defProps?.advancedConfig?.minColor,
        maxColor: props?.advancedConfig?.maxColor || defProps?.advancedConfig?.maxColor,
      },
      xAxis: {
        categories: categoriesFormatter.heatmap({ rawSeriesSet }).x,
        title: { text: props?.xAxis?.title || defProps?.xAxis?.title },
        visible: props?.xAxis?.visible || defProps?.xAxis?.visible,
      },
      yAxis: {
        categories: categoriesFormatter.heatmap({ rawSeriesSet }).y,
        reversed: true,
        title: { text: props?.yAxis?.title || defProps?.yAxis?.title },
        visible: props?.yAxis?.visible || defProps?.yAxis?.visible,
      },
    }; break;

    case 'line': detailedOptions = { ...sharedOptions,
      plotOptions: { ...sharedOptions.plotOptions,
        spline: {
          borderRadius: 4,
          borderWidth: 1,
          dataLabels: {
            allowOverlap: true,
            enabled: showValuesInChart,
            useHTML: true,
            formatter() {
              const convertToRGB = hexToRgb(this.color);
              return `
              <div
                style="
                  width:auto;
                  height: auto;
                  border: 1px solid ${this.color};
                  border-radius: 15px;
                  padding: 0px 5px;
                  background-color:rgb(${convertToRGB.r}, ${convertToRGB.g}, ${convertToRGB.b}, 0.2);
                  color: ${this.color};
                  font-size: 10px;
                  font-weight: 500;
                  z-index:1;
                "
              >
                ${valueFormatter(this.y)}
              </div>`;
            }
          },
          lineWidth: 5,
          marker: { enabled: false },
          pointPadding: 0.2,
        },
      },
      xAxis: {
        categories: categoriesFormatter.line({ rawSeriesSet, splitter: xLabelSplitter }),
        crosshair: true,
        tickInterval: tickInterval || getTickInterval({ rawSeriesSet, forDrawer }),
        title: { text: props?.xAxis?.title || defProps?.xAxis?.title },
        visible: props?.xAxis?.visible || defProps?.xAxis?.visible,
        labels: { autoRotation: false },
      },
      yAxis: {
        gridLineColor: bddColor.WHITE_THIN_GRAY,
        labels: { enabled: props?.yAxis?.ticked || defProps?.yAxis?.ticked },
        min: 0,
        opposite: props?.yAxis?.position === 'right' || defProps?.yAxis?.position === 'right',
        title: { text: props?.yAxis?.title || defProps?.yAxis?.title },
        visible: props?.yAxis?.visible || defProps?.yAxis?.visible,
      },
    }; break;

    case 'map': detailedOptions = { ...sharedOptions,
      chart: {
        height: 360,
        numberFormatter: valueFormatter,
        type: altType,
        backgroundColor: forDrawer ? props?.backgroundDrawer : props?.backgroundDefault,
        map: mapType === 'world' ? 'countries/custom/world-eckert3' : 'countries/id/id-all'
      },
      colorAxis: {
        min: 0,
        stops: [
          [0, '#EFEFFF'],
          [0.5, '#51ab72'],
          [1, '#51ab72'],
        ]
      },
      mapNavigation: { enabled: true, buttonOptions: { verticalAlign: 'top' }, floating: true },
      tooltip: { headerFormat: '', pointFormat: '{point.name}<br/><b>{point.value}</b>' },
      legend: { align: 'right', verticalAlign: 'bottom', floating: true, backgroundColor: 'rgba(255, 255, 255, 0.8)' },
    }; break;
  };

  return detailedOptions;
};

export default {
  displayName,
  propTypes,
  defaultProps,

  getColorSet,
  constructOptions,
  constructTemplateProps,
};
