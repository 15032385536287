import PropTypes from 'prop-types';
import {
  ONBOARDING_1,
  ONBOARDING_2,
  ONBOARDING_3,
  ONBOARDING_4,
  ONBOARDING_5,
  ONBOARDING_7,
  ONBOARDING_8,
} from '../../../assets-v1/images';

const displayName = `OnboardingPopup`;
const propTypes = {};
const defaultProps = {};

const onBoardingContent = {
  'onSummary': [
    {
      key: 1,
      image: ONBOARDING_1,
      title: 'Welcome to the new BDD.AI',
      description: 'Simple. Fresh. New Features Available. Updated Metrics. Of All Platform, In One',
    },
    {
      key: 2,
      image: ONBOARDING_2,
      title: 'Manage all account performance',
      description: 'You can access and combine multiple ad accounts in one platform to oversee merged performance in our Summary Page',
    },
    {
      key: 3,
      image: ONBOARDING_3,
      title: 'More easier and efficient!',
      description: 'Clean Interface, Interactive Charts, Copy to Clipboard Feature, Easier to use',
    },
    {
      key: 4,
      image: ONBOARDING_4,
      title: 'Set and Manage your client budget',
      description: `Afraid of over budget in a month? Worry less, track your client's budgeting and get notified right away`,
    },
    {
      key: 5,
      image: ONBOARDING_5,
      title: 'First thing First, Bind Your Account',
      description: 'To completely access features in BDD.AI, you need to bind or connect your account first. This only took less than 2 minutes!',
    },
  ],
  'onTour': [
    {
      key: 1,
      image: ONBOARDING_7,
      title: 'Explore The Account Performance',
      description: 'Access an extensive data and metrics that represent the performance of your account here. Numbers, Charts, Content, All-in-One single page',
    },
    {
      key: 2,
      image: ONBOARDING_8,
      title: 'Now You Could Utilized Budget Tracker',
      description: 'Start creating a client profile to track their budget spending. No more spreadsheet!',
    },
  ],
};

export default { displayName, propTypes, defaultProps, onBoardingContent };