import { connect } from 'react-redux';
import DocsContent from './DocsContent.component';

const mapStateToProps = (state) => ({
  user: state.user.me.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DocsContent);
