import React from 'react';
import { Layout } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import { getToken, destroyToken } from '../../services/token.service';
import { LANDINGDOCS_ROUTE } from '../../navigation/landingDocs.route';
import ModalNotification from '../../components/ModalNotification/ModalNotification.component';
import { BDD_LOGO_MINI as sessionExpiredIcon } from '../../assets-v1/prev-assets';

import config from './LandingDocs.component.config';

export class LandingDocs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isTimeoutModalVisible: false,
    }
    console.log(this.props);
  }

  _redirectToLogin = () => {
    destroyToken();
    window.location.replace('/login');
  };

  _onUndefinedTokenId = () => {
    this.setState({isTimeoutModalVisible: true});
  }

  _getUserFailed = () => {
    if (getToken()) destroyToken();
    this.setState({isTimeoutModalVisible: true});
  }

  componentDidMount() {
    const { getUserData } = this.props;
    getUserData().catch(
      this._onUndefinedTokenId,
      this._getUserFailed
    )
  }

  renderModalNotification = () => (
    <ModalNotification
      visible={this.state.isTimeoutModalVisible}
      config={{
        icon: sessionExpiredIcon,
        title: "Session Expired",
        description: "Your session has expired. Please re-login.",
        btnFollowUpText: "Re-login",
      }}
      onFollowUp={this._redirectToLoginPage}
    />
  );

  render() {
    const { location, history } = this.props;
    return (
      <Layout>
        <Switch>
          {LANDINGDOCS_ROUTE.map((route) => (
            <Route key={route.id} {...route} />
          ))}
          <Redirect to="/docs" />
          {get(getToken(), 'id') !== undefined || getToken() === null ? "" : this._onUndefinedTokenId()}
        </Switch>
        {this.renderModalNotification()}
      </Layout>
    );
  }
}

LandingDocs.displayName = config.displayName;
LandingDocs.propTypes = config.propTypes;
LandingDocs.defaultProps = config.defaultProps;

export default LandingDocs;
