import _ from 'lodash';

export const extractParamFromUrl = (paramKey) => {
  const rawUrl = window.location.search || '';

  const objectURL = _.chain(rawUrl)
    .replace('?', '')
    .split('&')
    .map(_.partial(_.split, _, '=', 2))
    .fromPairs()
    .value();

  const paramValue = objectURL?.[paramKey] || '';

  return paramValue;
};

export const generateCommonRequestParams = (platform='', props={}, extras={}) => {
  const output = {
    account_id: props?.account?.account_id,
    breakdown_interval: props?.daterange?.breakdown_interval,
    date_start_current: props?.daterange?.date_start_current,
    date_end_current: props?.daterange?.date_end_current,
    date_start_compare: props?.daterange?.date_start_compare,
    date_end_compare: props?.daterange?.date_end_compare,
    // group_by: props?.filter?.group_by,
    is_compare: props?.daterange?.is_compare,
    platform,
    cancelToken: extras?.cancelToken,
    ...props?.filter,
  };
  if (output.is_compare === false) { delete output.date_start_compare; delete output?.date_end_compare };
  return output;
};

export const reqFormat = {
  periodic: 'formatPeriodic',
  summary: 'formatSummary',
  table: 'formatTable',
};

export const configureRequestFields = {
  formatPeriodic: (params={}) => {
    const output = { ...params };
    delete output?.group_by;
    return output;
  },
  formatSummary: (params={}) => {
    const output = { ...params };
    delete output?.breakdown_interval;
    delete output?.group_by;
    return output;
  },
  formatTable: (params={}) => {
    const output = { ...params };
    delete output?.breakdown_interval;
    delete output?.date_start_compare; delete output?.date_end_compare;
    delete output?.date_start_compared; delete output?.date_end_compared;
    delete output?.is_compare;
    return output;
  },
};

export const generateRequestParamsByFormat = (platform='', props={}, format='', extras={}) => {
  const commonReqParams = generateCommonRequestParams(platform, props, extras);
  return {
    commonReqParams,
    formatPeriodic: (format === 'all' || format === reqFormat.periodic) ?
      configureRequestFields.formatPeriodic(commonReqParams) : null,
    formatSummary: (format === 'all' || format === reqFormat.summary) ?
      configureRequestFields.formatSummary(commonReqParams) : null,
    formatTable: (format === 'all' || format === reqFormat.table) ?
      configureRequestFields.formatTable(commonReqParams) : null,
  };
};