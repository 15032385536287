const commonFields = {
  data: {},
  loadings: { bind: false, ui: false, unbind: false },
};

const bindingStructure = {
  googleAds: { ...commonFields },
  googleAnalytics: { ...commonFields },
  instagram: { ...commonFields },
  marketplace: { ...commonFields },
  meta: { ...commonFields },
  tiktok: { ...commonFields },
  website: { ...commonFields,
    editAccount: {},
    isUpdated: false,
    modalAddVisible: false,
    modalDeleteVisible: false,
    modalEditVisible: false,
  }
};

export default { bindingStructure };