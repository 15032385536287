import { cur, flt, int, pct } from './__config';
export default {
  instagram: {
    carousel_album: { key: 'carousel_album', lbl: 'Carousel Album', fmt: int, adv: 0 },
    comments: { key: 'comments', lbl: 'Comments', fmt: int, adv: 0 },
    email_contacts: { key: 'email_contacts', lbl: 'Email Contacts', fmt: int, adv: 0 },
    engagement: { key: 'engagement', lbl: 'Engagement', fmt: int, adv: 0 },
    engagement_rate: { key: 'engagement_rate', lbl: 'Engagement Rate (in %)', fmt: pct, adv: 0 },
    get_directions_clicks: { key: 'get_directions_clicks', lbl: 'Get Directions Clicks', fmt: int, adv: 0 },
    image: { key: 'image', lbl: 'Image', fmt: int, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    like: { key: 'like', lbl: 'Like', fmt: int, adv: 0 },
    likes: { key: 'likes', lbl: 'Likes', fmt: int, adv: 0 },
    phone_call_clicks: { key: 'phone_call_clicks', lbl: 'Phone Call Clicks', fmt: int, adv: 0 },
    profile_views: { key: 'profile_views', lbl: 'Profile Views', fmt: int, adv: 0 },
    reach: { key: 'reach', lbl: 'Reach', fmt: int, adv: 0 },
    saved: { key: 'saved', lbl: 'Saved', fmt: int, adv: 0 },
    text_message_clicks: { key: 'text_message_clicks', lbl: 'Text Message Clicks', fmt: int, adv: 0 },
    total_followers: { key: 'total_followers', lbl: 'Total Followers', fmt: int, adv: 0 },
    video: { key: 'video', lbl: 'Video', fmt: int, adv: 0 },
    video_views: { key: 'video_views', lbl: 'Video Views', fmt: int, adv: 0 },
    website_clicks: { key: 'website_clicks', lbl: 'Website Clicks', fmt: int, adv: 0 }
  }
};