import React from 'react';
import {
  Avatar,
  Button,
  List,
  Modal,
  Popover,
  Space,
  Typography,
} from 'antd';
import { DownOutlined, ExclamationCircleOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { formatAvatar } from '../../../utils-v1/string.utils';

import config from './AccountBox.component.config';
import './AccountBox.component.styles.less';

const { Title, Text } = Typography;

const AccountBox = ({
  email = '',
  history = { location: '', pathname: '', push: () => null, replace: () => null },
  location = { pathname: '' },
  name = '',
  ...props
}) => {
  // const getInitials = () => (name || '').split(' ').map(el => (el?.[0] || '').toUpperCase()).join('').slice(0, 2);
  const getInitials = () => formatAvatar(name).toUpperCase();

  const constructModalConfirmProps = () => ({
    centered: true,
    icon: <ExclamationCircleOutlined style={{ color: '#ea4e4e' }} />,
    content: 'Are you sure you want to log out?',
    onOk: () => { props.logout({ history }) },
  });

  const getPopoverContent = () => (
    <div style={{ padding: 10, width: 150 }}>
      <List
        dataSource={[
          { icon: <UserOutlined />, label: 'My Profile', onClick: () => history.push('/profile') },
          { icon: <LogoutOutlined />, label: 'Logout', onClick: () => Modal.confirm({ ...constructModalConfirmProps() }) },
        ]}
        renderItem={(item) => (
          <div style={{ padding: '10px 10px 5px 10px' }}>
            <Space
              className="account-box-menu-item"
              direction="horizontal"
              size={12}
              style={{ cursor: 'pointer' }}
              onClick={item.onClick}
            >
              <>{item.icon}</>
              <span style={{ fontSize: 14}}>{item.label}</span>
            </Space>
          </div>
        )}
      />
    </div>
  );

  const constructPopoverProps = () => ({
    content: getPopoverContent(),
    placement: 'bottom',
    trigger: 'hover',
  });

  return(<Popover { ...constructPopoverProps() } trigger="click">
    <Button
      id="accountBox"
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        height: 'max-content',
        maxWidth: 200,
        transform: 'translateY(-5px)',
        width: 'max-content',
        marginLeft: 10,
        boxShadow: 'unset'
      }}
    >
      <div className="content">
        <Avatar size="large" style={{ backgroundColor: '#018FFC', transform: 'translateY(2px)' }}>{getInitials()}</Avatar>
        <div className="user-info">
          <Text strong>{name}</Text>
          <Text ellipsis style={{ fontSize: 12 }}>{email}</Text>
        </div>
        <DownOutlined style={{ transform: 'translateY(7px)' }} />
      </div>
    </Button>
  </Popover>)
}

AccountBox.displayName = config.displayName;
AccountBox.propTypes = config.propTypes;
AccountBox.defaultProps = config.defaultProps;

export default AccountBox;
