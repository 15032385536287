import React from 'react';
import { Button, Image } from 'antd';
import { CopyOutlined, ExpandAltOutlined } from '@ant-design/icons';
import {
  ICON_DOWNLOAD,
  ICON_FILTER,
  ICON_SHARE,
} from '../../../assets-v1/figma-icons';

import config from './Buttons.component.config';
import './Buttons.component.styles.less';

const Apply = ({ disabled=false, hidden=false, loading=false, onClick=()=>null, className='' }) => (
  <Button
    disabled={disabled}
    hidden={hidden}
    className={`${className}${disabled ? 'btn-disabled' : ''}`}
    id="ApplyButton"
    onClick={typeof onClick === 'function' ? onClick : () => null}
    type="primary"
    loading={loading}
  >Apply</Button>
);

const CopyToClipboard = ({ disabled=false, hidden=false, loading=false, onClick=()=>null, className='' }) => (
  <Button
    disabled={disabled}
    hidden={hidden}
    className={`${className}${disabled ? 'btn-disabled' : ''}`}
    id="CopyToClipboardButton"
    onClick={typeof onClick === 'function' ? onClick : () => null}
    type="primary"
    size="middle"
    style={{ fontSize: 14, fontWeight: 700 }}
    loading={loading}
  ><CopyOutlined /> Copy to Clipboard</Button>
);

const Export = ({ disabled=false, hidden=false, loading=false, onClick=()=>null, className='' }) => (
  <Button
    disabled={disabled}
    hidden={hidden}
    className={`${className}${disabled ? 'btn-disabled' : ''}`}
    id="ExportButton"
    onClick={typeof onClick === 'function' ? onClick : () => null}
    type="primary"
    size="large"
    style={{ fontSize: 14, fontWeight: 500 }}
    loading={loading}
  ><ICON_DOWNLOAD />&nbsp;&nbsp;Export</Button>
);

const Filter = ({ disabled=false, hidden=false, loading=false, onClick=()=>null, className='' }) => (
  <Button
    disabled={disabled}
    hidden={hidden}
    className={`${className}${disabled ? 'btn-disabled' : ''}`}
    id="FilterButton"
    onClick={typeof onClick === 'function' ? onClick : () => null}
    style={{ fontSize: 14 }}
    loading={loading}
  ><Image src={ICON_FILTER} alt="icon-filter" preview={false} />&nbsp;Filter</Button>
);

const SeeDetail = ({ disabled=false, hidden=false, loading=false, onClick=()=>null, className='' }) => (
  <Button
    disabled={disabled}
    hidden={hidden}
    className={`${className}${disabled ? 'btn-disabled' : ''}`}
    icon={<ExpandAltOutlined />}
    id="SeeDetailButton"
    onClick={typeof onClick === 'function' ? onClick : () => null}
    style={{ fontSize: 14, fontWeight: 700 }}
    loading={loading}
  >See Detail</Button>
);

const Share = ({ disabled=false, hidden=false, loading=false, onClick=()=>null, className='' }) => (
  <Button
    disabled={disabled}
    hidden={hidden}
    className={`${className}${disabled ? 'btn-disabled' : ''}`}
    id="ShareButton"
    onClick={typeof onClick === 'function' ? onClick : () => null}
    size="large"
    style={{ fontSize: 14, fontWeight: 700 }}
    loading={loading}
  ><ICON_SHARE />&nbsp;&nbsp;Share</Button>
);

const Buttons = { Apply, CopyToClipboard, Export, Filter, SeeDetail, Share };

Buttons.displayName = config.displayName;
Buttons.propTypes = config.propTypes;
Buttons.defaultProps = config.defaultProps;

export default Buttons;
