import React from 'react';
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Image,
  Input,
  Layout,
  List,
  Popover,
  Space,
  Typography,
} from 'antd';
import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import { ICON_REFRESH_BLACK } from '../../../../assets-v1/figma-icons';

import { formatPercent } from '../../../../utils-v1/number.utils';
import { getLastEventTimeIndicator } from '../../../../utils-v1/time.utils';

import { AccountBoxGS, RangePickerGS } from '../../../atoms';

import stringConstants from '../../../../constants-v1/string.constants';

import config from './HeaderToolbar.component.config';
// import './HeaderToolbar.component.styles.less';

const { Header } = Layout;
const { Paragraph, Text, Title } = Typography;

const HeaderToolbar = ({
  accountBoxProps = { history: {}, location: {} },
  accountSelectionProps = {
    defaultState: {
      selectedAccounts: [],
    },
    extra: null,
    multiple: false,
    onApply: (accounts={}) => console.log({ accounts }),
    platformKey: '',
    platformName: '',
  },
  badgeProps = { count: 0, notifications: {} },
  filterProps = {
    defaultState: {
      filter_by: '',
      filter_value: [],
      group_by: '',
    },
    onApply: (filters={}) => console.log({ filters }),
    platformKey: '',
  },
  history = { push: () => null, replace: () => null },
  isMobileOnly = false,
  rangePickerProps = {
    defaultState: {
      breakdown_interval: '',
      date_start_current: '', date_end_current: '',
      date_start_compare: '', date_end_compare: '',
      use_compare: true,
    },
    onApply: (daterange={}) => console.log({ daterange }),
  },
  syncDataProps = {
    account: {},
    loading: false,
    onApply: () => null,
    synchronizingAccounts: [],
  } || null,
  title = '',
  ...otherProps
}) => {
  const [stateHeader, setStateHeader] = React.useState({
    badgePropsHeader: { ...badgeProps },
    openDrawerNotification: false,
  });
  const constructPopoverProps = () => {
    const sections = [];
    Object.entries(badgeProps?.notifications || {}).forEach(entry => {
      const [key, val] = [entry[0], entry[1]];
      if (val && Array.isArray(val) && val?.length > 0) {
        let sectionTitle; let goToUrl;
        switch(key) {
          case 'budgetTracker': sectionTitle = 'Budget Tracker'; goToUrl = 'budget-tracker'; break;
          case 'accountBinding': sectionTitle = 'Account Binding'; goToUrl = ''; break;
          default: sectionTitle = stringConstants?.[key]?.name; goToUrl = stringConstants?.[key]?.dash; break;
        };
        const sectionNotifications = (Array.isArray(val) ? val : [])?.map(notif => ({ ...notif, key, title: sectionTitle }));
        sections.push({ sectionTitle, sectionNotifications, goToUrl });
      };
    });
    const allNotifications = sections.map(section => section.sectionNotifications.map(notif => {
      let notifObj = {};
      if (notif?.key === 'accountBinding') notifObj = { ...notif, goToUrl: `binding-${notif?.platform}` };
      if (notif?.key === 'budgetTracker') notifObj = { ...notif, goToUrl: section.goToUrl };
      return(notifObj);
    })).flat();
    const popoverContent = (
      <List
        id="NotificationListPopup"
        bordered
        dataSource={allNotifications}
        renderItem={(item) => <List.Item onClick={() => history.push(item.goToUrl)}>
          {item?.key === 'budgetTracker' ? <Space direction="vertical" size={1}>
            <Space direction="horizontal">
              <div style={{ width: 15, height: 15, borderRadius: '50%', backgroundColor: (item?.type === 'danger' && '#FF683A') || (item?.type === 'warn' && '#F5BD00') || '#59BC7D' }}></div>
              <Text style={{ fontSize: 14, fontWeight: 700 }}>{item?.title}</Text>
            </Space>
            <Text style={{ fontSize: 12, fontWeight: 700 }}>{item?.brand_name}  ( {item?.account_name} )</Text>
            <Text style={{ color: '#999999', fontSize: 12 }}>{item?.message} <span style={{ fontWeight: 700 }}>( current: {formatPercent(item?.percentage_of_usage_budget || 0)} )</span></Text>
          </Space> : null}
          {item?.key === 'accountBinding' ? <Space direction="vertical" size={1}>
            <Space direction="horizontal">
              <div style={{ width: 15, height: 15, borderRadius: '50%', backgroundColor: '#F5BD00' }}></div>
              <Text style={{ fontSize: 14, fontWeight: 700 }}>Account Binding</Text>
            </Space>
            <Text style={{ fontSize: 12, fontWeight: 700 }}>Your {item?.platformName} binding token has expired</Text>
            <Text style={{ color: '#999999', fontSize: 12 }}>Click here to rebind your {item?.platformName} accounts</Text>
          </Space> : null}
        </List.Item>}
      />
    );
    return popoverContent;
  };
  const drawerNotificationDekstop = () => (
    <Drawer
      title="Notifications"
      key="rightDrawer"
      placement="right"
      closable={false}
      // onClose={onClose}
      open={stateHeader.openDrawerNotification}
      extra={
        <Space>
          <Button
            type="ghost"
            style={{ border: 'unset', boxShadow: 'unset', color: '#6B6B6B', fontSize: 14 }}
            size="small"
            onClick={() => setStateHeader({
              ...stateHeader,
              badgePropsHeader: {
                ...stateHeader.badgePropsHeader,
                count: 0,
              },
              openDrawerNotification: false,
            })}
          >
            Mark all as read
          </Button>
          <Button
            type="default"
            style={{ borderRadius: 50, backgroundColor: '#D0D5DD' }}
            icon={<CloseOutlined />}
            onClick={() => setStateHeader({ ...stateHeader, openDrawerNotification: false })}
          />
        </Space>
      }
    >
      <div>{constructPopoverProps()}</div>
    </Drawer>
  );

  const renderHeaderDesktop = () => (
    <Header>
      <div className="content-header" /* onMouseEnter={() => console.log({ badgeProps })} */>
        <Title level={2} style={{ fontWeight: 700, fontSize: 24, display: 'flex', alignItems: 'center' }}>
          {title}
          {otherProps?.location?.pathname?.includes('component-demo') ?
            <span style={{ fontWeight: 500, paddingLeft: '10vw' }}>Search Component : <Input
              allowClear
              size="large"
              style={{ border: '2px solid black', width: '20vw' }}
              onChange={otherProps?.onSearchInput}
            /></span> : null
          }
          &nbsp;&nbsp;&nbsp;
          <span
            hidden={accountBoxProps.location.pathname.match('binding')}
            style={{ backgroundColor: '#F0F2F4', borderRadius: 100, fontSize: 14, fontWeight: 500, padding: '5px 20px' }}
          >
            <Image src={ICON_REFRESH_BLACK} alt="icon_refresh_black" preview={false} width={20} />
            &nbsp;&nbsp;
            <span style={{ color: '#7D8085' }}>last updated:</span>
            &nbsp;&nbsp;
            <b>{getLastEventTimeIndicator(syncDataProps?.latest_sync )}</b>
          </span>
        </Title>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {rangePickerProps ? <RangePickerGS { ...rangePickerProps } /> : null}
        {/* notification badges */}
        <div onClick={() => setStateHeader({ ...stateHeader, openDrawerNotification: true })}>
          <Badge count={stateHeader.badgePropsHeader?.count || 0}/* count={badgeProps?.count} */ style={{ cursor: 'pointer' }}>
            <BellOutlined style={{ transform: 'scale(1.3)', cursor: 'pointer' }} />
          </Badge>
        </div>
        {/* <Popover { ...constructPopoverProps() } trigger="click">
        </Popover> */}
        {accountBoxProps ? <AccountBoxGS { ...accountBoxProps } /> : null}
      </div>
      {drawerNotificationDekstop()}
    </Header>
  ); 

  const renderHeaderMobile = () => (
    <div className="header-mobile">
      {/* <div className="header-nav">
        <div
          className="header-menu"
          onClick={() => {
            setOpenSideMenu(true);
          }}
        >
          <img src={ICON_MENU} alt="icon-menu" />
          <img src={BDD_LOGO_WHITE_MINI} alt="logo-mobile" className="header-logo" height={25} />
        </div>
        <div
          className="header-profile"
          onClick={() => {
            history.push('profile');
          }}
        >
          <Avatar shape="square">{avatar}</Avatar>
        </div>
      </div>
      <div className="options-menus">
        {otherProps?.showDateRange && (
          <div className="header-action-nav">
            {otherProps?.showDateRange ? <DateRangePickerGS {...otherProps} /> : null}
          </div>
        )}
        {otherProps?.showSelectionPlatform ? (
          <Dropdown
            className="btn-selection-account"
            placement="bottomRight"
            overlay={renderDropSelection}
            trigger={['click']}
            visible={state.visibleDrop}
            onVisibleChange={changeVisibleDrop}
          >
            <Button
              className="content-selection-button"
              icon={<i className="icon-gear" />}
            />
          </Dropdown>
        ) : null}
      </div> */}
    </div>
  );

  return(<>{isMobileOnly ? renderHeaderMobile() : renderHeaderDesktop()}</>)
}

HeaderToolbar.displayName = config.displayName;
HeaderToolbar.propTypes = config.propTypes;
HeaderToolbar.defaultProps = config.defaultProps;

export default HeaderToolbar;
