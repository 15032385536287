import React from 'react';
import { Typography } from 'antd';

import config from './CopyrightText.component.config';
import './CopyrightText.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const CopyrightText = ({ style={} }) => (
  <Paragraph
    style={{
      backgroundColor: 'transparent',
      color: '#7D8085',
      fontSize: '0.75rem',
      ...style,
    }}
  >©2023 Base Data Dashboard All right reserved</Paragraph>
);

CopyrightText.displayName = config.displayName;
CopyrightText.propTypes = config.propTypes;
CopyrightText.defaultProps = config.defaultProps;

export default CopyrightText;