import TYPES from '../../types';
import api2 from '../../../services/api2.service';
import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';

import { capsEveryWordStart } from '../../../utils-v1/string.utils';

const { ApiService2, setApi2ReqHeaders } = api2.getInstance();
const { BindingApiService } = apiBinding.getInstance();

export const getMetrics = () => async(dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_ALL_METRICS, payload });
  try {
    const { status, data: { data } } = await ApiService2.get('custom-report/get-metrics', { headers: setApi2ReqHeaders(getToken()) });
    const formattedData = {}
    Object.entries(data).forEach(entry => {
      formattedData[entry[0]] = entry[1].map(m => ({ ...m, label: capsEveryWordStart(m.label) }));
    });
    payload = { data: formattedData , loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_ALL_METRICS, payload });
    result = Promise.resolve(data);
  } catch(err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_ALL_METRICS, payload });
    result = Promise.reject(err);
  };
  return result;
};

export const getSavedReports = () => async(dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_SAVED_REPORTS, payload });
  try {
    const { status, data: { data } } = await ApiService2.get('custom-report/get-report-list', { headers: setApi2ReqHeaders(getToken(getToken())) });
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_SAVED_REPORTS, payload });
    result = Promise.resolve(payload);
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_SAVED_REPORTS, payload });
    result = Promise.reject(payload);
  };
  return result;
};

export const createReportConfig = (params={}) => async(dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_CREATE_REPORT_CONFIG, payload });
  try {
    const { status, data } = await ApiService2.post('custom-report/insert-report', params);
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_CREATE_REPORT_CONFIG, payload });
    result = Promise.resolve(data);
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_CREATE_REPORT_CONFIG, payload });
    result = Promise.reject(err);
  };
  return result;
};

export const setOpenedReport = (
  reportConfig={ id: null, accounts: [], account_ids: [], accountsByPlatform: {}, document_type: '', report_name: '' }
) => async(dispatch) => {
  let payload = { data: { ...reportConfig }, loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT, payload });
  try {
    if (reportConfig.id === null) {
      const { status, data: { data } } = await ApiService2.post('custom-report/insert-report', reportConfig);
      payload = { data: { ...reportConfig, ...data }, loading: false, error: null };
      dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT, payload });
      result = Promise.resolve(payload?.data);
    } else {
      payload = { data: { ...reportConfig }, loading: false, error: null };
      dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT, payload });
      result = Promise.resolve(payload?.data);
    };
  } catch (err) {
    payload = { data: { ...reportConfig }, loading: false, error: err };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT})
    result = Promise.reject(payload.error);
  };
  return result;
};

export const updateExistingReportConfig = (
  reportConfig={ id: null, report_name: '', cover_url: '' }
) => async (dispatch) => {
  let payload = { data: { ...reportConfig }, loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_UPDATE_REPORT_CONFIG, payload });
  try {
    const formattedReportConfig = { ...reportConfig, cr_id: reportConfig?.id };
    const { status, data: { data} } = await ApiService2.put('custom-report/update-report', formattedReportConfig);
    payload = { data: { ...reportConfig }, loading: false, error: null };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_UPDATE_REPORT_CONFIG, payload });
    result = Promise.resolve(payload?.data);
  } catch (err) {
    payload = { data: { ...reportConfig }, loading: false, error: err };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_UPDATE_REPORT_CONFIG, payload });
    result = Promise.reject(payload.error);
  };
  return result;
};

export const updateOpenedReportName = (
  reportConfig={ id: null, report_name: '' }
) => async(dispatch) => {
  // console.log('update opened report name', reportConfig)
  let payload = { data: { ...reportConfig }, loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT_NAME, payload });
  try {
    const formattedReportConfig = { ...reportConfig, cr_id: reportConfig.id };
    const { status, data: { data } } = await ApiService2.put('custom-report/update-report', formattedReportConfig);
    payload = { data: { ...reportConfig }, loading: false, error: null };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT_NAME, payload });
    result = Promise.resolve(payload?.data);
  } catch (err) {
    payload = { data: { ...reportConfig }, loading: false, error: err };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT_NAME, payload });
    result = Promise.reject(payload.error);
  };
  return result;
};

export const getConfigList = (reportID='') => async(dispatch) => {
  let payload = { data: [], loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_CONFIG_LIST, payload });
  try {
    const { status, data: { data } } = await ApiService2.get(`custom-report/get-card-list/${reportID}`, { headers: setApi2ReqHeaders(getToken(getToken())) });
    // console.log(`getConfigList`, data);
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_CONFIG_LIST, payload });
    result = Promise.resolve(payload.data);
  } catch (err) {
    payload = { data: [], error: err, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_CONFIG_LIST, payload });
    result = Promise.reject(payload.error);
  };
  return result;
};

export const upsertCard = (cardConfig={}) => async(dispatch) => {
  let payload = { data: { ...cardConfig }, loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_UPSERT_CARD, payload });
  try {
    const { status, data: { data } } = await ApiService2.post('custom-report/upsert-card', cardConfig);
    // console.log('upsertCard', { data });
    payload = { data: { ...cardConfig, ...data }, loading: false, error: null };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_UPSERT_CARD, payload });
    result = Promise.resolve(payload.data);
  } catch (err) {
    payload = { data: { ...cardConfig }, loading: false, error: err };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_UPSERT_CARD, payload });
    result = Promise.reject(payload.error);
  };
  return result;
};

export const getDataForCard = (cardID='') => async(dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_GET_CARD_DATA, payload });
  try {
    const { status, data: { data } } = await ApiService2.get(`custom-report/get-data/${cardID}`);
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_GET_CARD_DATA, payload });
    result = Promise.resolve(data);
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_GET_CARD_DATA, payload });
    result = Promise.reject(err);
  };
  return result;
};

export const getReportDetail = (reportID='') => async(dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT, payload });
  try {
    const { status, data } = await ApiService2.get(`custom-report/get-detail/${reportID}`);
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT, payload });
    result = Promise.resolve(data);
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_OPENED_REPORT, payload });
    result = Promise.reject(err);
  };
  return result;
};

export const deleteCard = (cardID='') => async(dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_DELETE_CARD, payload });
  try {
    const { status, data } = await ApiService2.delete(`custom-report/delete-card/${cardID}`);
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_DELETE_CARD, payload });
    result = Promise.resolve(data);
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_DELETE_CARD, payload });
    result = Promise.reject(err);
  };
  return result;
};

export const deleteReport = (reportID='') => async(dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_CUSTOM_REPORT_DELETE_REPORT, payload });
  try {
    const { status, data } = await ApiService2.delete(`custom-report/delete-report/${reportID}`, { data: { reportID } });
    payload = { data: { ...data, id: reportID }, loading: false };
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_DELETE_REPORT, payload });
    result = Promise.resolve(data);
  } catch (err) {
    payload = { error: err, loading: false };
    result = Promise.reject(err);
    dispatch({ type: TYPES.SET_CUSTOM_REPORT_DELETE_REPORT, payload });
  };
  return result;
};

export const resetOpenedReport = () => async(dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.RESET_CUSTOM_REPORT_OPENED_REPORT, payload });
  try {
    payload = { loading: false };
    dispatch({ type: TYPES.RESET_CUSTOM_REPORT_OPENED_REPORT, payload });
    result = Promise.resolve();
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.RESET_CUSTOM_REPORT_OPENED_REPORT, payload });
    result = Promise.reject(payload.error);
  };
  return result;
};

export const getPublicReportId = (reportID='') => async(dispacth) => {
  let result;
  try {
    const { status, data } = await ApiService2.get(`custom-report/get-share-link/${reportID}`);
    result = Promise.resolve(data);
  } catch (err) {
    result = Promise.reject(err);
  };
  return result;
}

export const getPublicReportDetail = (publicReportID='') => async(dispacth) => {
  let payload = { loading: true }; let result;
  dispacth({ type: TYPES.SET_CUSTOM_REPORT_PUBLIC_REPORT, payload });
  try {
    const { status, data: { data }} = await ApiService2.get(`custom-report/get-share-data/${publicReportID}`);
    console.log({ data })
    payload = { data, loading: false };
    dispacth({ type: TYPES.SET_CUSTOM_REPORT_PUBLIC_REPORT, payload });
    result = Promise.resolve(data);
  } catch (err) {
    payload = { error: err, loading: false };
    dispacth({ type: TYPES.SET_CUSTOM_REPORT_PUBLIC_REPORT, payload });
    result = Promise.reject(err);
  };
  return result;
};