import { cur, flt, int, pct } from './__config';
export default {
  conversion_tiktok: {
    conversion: { key: 'conversion', lbl: 'Conversion', fmt: int, adv: 0 },
    conversion_rate: { key: 'conversion_rate', lbl: 'CVR (%)', fmt: pct, adv: 0 },
    cost_per_conversion: { key: 'cost_per_conversion', lbl: 'CPA', fmt: cur, adv: 1 },
    cpa: { key: 'cpa', lbl: 'CPA', fmt: cur, adv: 1 },
    cvr: { key: 'cvr', lbl: 'CVR (%)', fmt: pct, adv: 0 },
    funnel_add_to_cart_onsite_to_initiate_checkout_onsite: { key: 'funnel_add_to_cart_onsite_to_initiate_checkout_onsite', lbl: 'Add to Cart >> Initiate Checkout', fmt: pct, adv: 0 },
    funnel_initiate_checkout_onsite_to_complete_payment_onsite: { key: 'funnel_initiate_checkout_onsite_to_complete_payment_onsite', lbl: 'Initiate Checkout >> Complete Payment', fmt: pct, adv: 0 },
    funnel_page_views_onsite_to_add_to_cart_onsite: { key: 'funnel_page_views_onsite_to_add_to_cart_onsite', lbl: 'Product Details Page Views >> ATC', fmt: pct, adv: 0 },
    live_views_onsite: { key: 'live_views_onsite', lbl: 'Live Views', fmt: int, adv: 0 },
    product_detail_page_view_onsite: { key: 'product_detail_page_view_onsite', lbl: 'Product Detail Page Views', fmt: int, adv: 0 },
    add_to_cart_onsite: { key: 'add_to_cart_onsite', lbl: 'Add to Cart', fmt: int, adv: 0 },
    add_to_cart_value_onsite: { key: 'add_to_cart_value_onsite', lbl: 'Add to Cart Value (IDR)', fmt: cur, adv: 0 },
    average_video_play: { key: 'average_video_play', lbl: 'Avg. Watch Time per Video View (in s)', fmt: flt, adv: 0 },
    average_video_play_per_user: { key: 'average_video_play_per_user', lbl: 'Avg. Watch Time per User (in s)', fmt: flt, adv: 0 },
    clicks: { key: 'clicks', lbl: 'Clicks', fmt: int, adv: 0 },
    complete_payment_onsite: { key: 'complete_payment_onsite', lbl: 'Complete Payment', fmt: int, adv: 0 },
    complete_payment_value_onsite: { key: 'complete_payment_value_onsite', lbl: 'Complete Payment Value (IDR)', fmt: int, adv: 0 },
    cost_per_1000_reached: { key: 'cost_per_1000_reached', lbl: 'Cost per 1000 People Reached', fmt: cur, adv: 1 },
    cost_per_add_to_cart_onsite: { key: 'cost_per_add_to_cart_onsite', lbl: 'Cost per Add to Cart', fmt: cur, adv: 1 },
    cost_per_complete_payment_onsite: { key: 'cost_per_complete_payment_onsite', lbl: 'Cost per Complete Payment', fmt: cur, adv: 1 },
    cost_per_initiate_checkout_onsite: { key: 'cost_per_initiate_checkout_onsite', lbl: 'Cost per Initiate Checkout', fmt: cur, adv: 0 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    engagements: { key: 'engagements', lbl: 'Total Engagement', fmt: int, adv: 0 },
    follows_community_interaction: { key: 'follows_community_interaction', lbl: 'Paid Followers', fmt: int, adv: 0 },
    frequency: { key: 'frequency', lbl: 'Frequency', fmt: flt, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    initiate_checkout_onsite: { key: 'initiate_checkout_onsite', lbl: 'Initiate Checkout', fmt: int, adv: 0 },
    initiate_checkout_value_onsite: { key: 'initiate_checkout_value_onsite', lbl: 'Initiate Checkout Value', fmt: cur, adv: 0 },
    live_effective_views_onsite: { key: 'live_effective_views_onsite', lbl: 'Effective Live Views', fmt: int, adv: 0 },
    live_product_clicks_onsite: { key: 'live_product_clicks_onsite', lbl: 'Live Product Clicks', fmt: int, adv: 0 },
    page_views_onsite: { key: 'page_views_onsite', lbl: 'Page View', fmt: int, adv: 0 },
    product_clicks_onsite: { key: 'product_clicks_onsite', lbl: 'Product Clicks', fmt: int, adv: 0 },
    profile_visits_community_interaction: { key: 'profile_visits_community_interaction', lbl: 'Paid Profile Visits', fmt: int, adv: 0 },
    reach: { key: 'reach', lbl: 'Reach', fmt: int, adv: 0 },
    roas_complete_payment_onsite: { key: 'roas_complete_payment_onsite', lbl: 'Complete Payment ROAS', fmt: flt, adv: 0 },
    spend: { key: 'spend', lbl: 'Total Cost', fmt: cur, adv: 0 },
    video_play_actions: { key: 'video_play_actions', lbl: 'Video Views', fmt: int, adv: 0 }
  },
  conversion_website: {
    funnel_add_to_cart_website_to_place_an_order_website: { key: 'funnel_add_to_cart_website_to_place_an_order_website', lbl: 'Add to Cart >> Place an Order', fmt: pct, adv: 0 },
    funnel_initiate_checkout_website_to_complete_payment_website: { key: 'funnel_initiate_checkout_website_to_complete_payment_website', lbl: 'Initiate Checkout >> Complete Payment', fmt: pct, adv: 0 },
    funnel_page_views_website_to_add_to_cart_website: { key: 'funnel_page_views_website_to_add_to_cart_website', lbl: 'Page Views >> ATC', fmt: pct, adv: 0 },
    funnel_place_an_order_website_to_complete_payment_website: { key: 'funnel_place_an_order_website_to_complete_payment_website', lbl: 'Place an Order >> Initiate Checkout', fmt: pct, adv: 0 },
    add_to_cart_value_website: { key: 'add_to_cart_value_website', lbl: 'Add to Cart Value (IDR)', fmt: cur, adv: 0 },
    add_to_cart_website: { key: 'add_to_cart_website', lbl: 'Add to Cart', fmt: int, adv: 0 },
    average_video_play: { key: 'average_video_play', lbl: 'Avg. Watch Time per Video View (in s)', fmt: flt, adv: 0 },
    average_video_play_per_user: { key: 'average_video_play_per_user', lbl: 'Avg. Watch Time per User (in s)', fmt: flt, adv: 0 },
    button_clicks_website: { key: 'button_clicks_website', lbl: 'Button Click', fmt: int, adv: 0 },
    button_clicks_value_website: { key: 'button_clicks_value_website', lbl: 'Total Button Click Value', fmt: int, adv: 0 },
    clicks: { key: 'clicks', lbl: 'Clicks', fmt: int, adv: 0 },
    complete_payment_website: { key: 'complete_payment_website', lbl: 'Complete Payment', fmt: int, adv: 0 },
    complete_payment_value_website: { key: 'complete_payment_value_website', lbl: 'Complete Payment Value (IDR)', fmt: int, adv: 0 },
    cost_per_1000_reached: { key: 'cost_per_1000_reached', lbl: 'Cost per 1000 People Reached', fmt: cur, adv: 1 },
    cost_per_add_to_cart_website: { key: 'cost_per_add_to_cart_website', lbl: 'Cost per Add to Cart', fmt: cur, adv: 1 },
    cost_per_button_clicks_website: { key: 'cost_per_button_clicks_website', lbl: 'Cost per Button Click', fmt: cur, adv: 1 },
    cost_per_complete_payment_website: { key: 'cost_per_complete_payment_website', lbl: 'Cost per Complete Payment', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    engagements: { key: 'engagements', lbl: 'Total Engagement', fmt: int, adv: 0 },
    follows_community_interaction: { key: 'follows_community_interaction', lbl: 'Paid Followers', fmt: int, adv: 0 },
    frequency: { key: 'frequency', lbl: 'Frequency', fmt: flt, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    initiate_checkout_website: { key: 'initiate_checkout_website', lbl: 'Initiate Checkout', fmt: int, adv: 0 },
    page_views_website: { key: 'page_views_website', lbl: 'Page View', fmt: int, adv: 0 },
    place_an_order_website: { key: 'place_an_order_website', lbl: 'Place an Order', fmt: int, adv: 0 },
    profile_visits_community_interaction: { key: 'profile_visits_community_interaction', lbl: 'Paid Profile Visits', fmt: int, adv: 0 },
    reach: { key: 'reach', lbl: 'Reach', fmt: int, adv: 0 },
    roas_complete_payment_website: { key: 'roas_complete_payment_website', lbl: 'Complete Payment ROAS', fmt: flt, adv: 0 },
    spend: { key: 'spend', lbl: 'Total Cost', fmt: cur, adv: 0 },
    video_play_actions: { key: 'video_play_actions', lbl: 'Video Views', fmt: int, adv: 0 },
    view_content_website: { key: 'view_content_website', lbl: 'View Content', fmt: int, adv: 0 }
  },
  community_interaction: {
    average_video_play: { key: 'average_video_play', lbl: 'Avg. Watch Time per Video View (in s)', fmt: flt, adv: 0 },
    average_video_play_per_user: { key: 'average_video_play_per_user', lbl: 'Avg. Watch Time per User (in s)', fmt: flt, adv: 0 },
    clicks: { key: 'clicks', lbl: 'Clicks', fmt: int, adv: 0 },
    comments_community_interaction: { key: 'comments_community_interaction', lbl: 'Paid Comments', fmt: int, adv: 0 },
    cost_per_1000_reached: { key: 'cost_per_1000_reached', lbl: 'Cost per 1000 People Reached', fmt: cur, adv: 1 },
    cost_per_paid_followers: { key: 'cost_per_paid_followers', lbl: 'Cost per Paid Followers', fmt: cur, adv: 1 },
    cost_per_paid_profile_visits: { key: 'cost_per_paid_profile_visits', lbl: 'Cost per Paid Profile Visit (IDR)', fmt: cur, adv: 0 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    engagements: { key: 'engagements', lbl: 'Total Engagement', fmt: int, adv: 0 },
    engagement_rate: { key: 'engagement_rate', lbl: 'Engagement Rate', fmt: pct, adv: 0 },
    follows_community_interaction: { key: 'follows_community_interaction', lbl: 'Paid Followers', fmt: int, adv: 0 },
    frequency: { key: 'frequency', lbl: 'Frequency', fmt: flt, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    likes_community_interaction: { key: 'likes_community_interaction', lbl: 'Paid Likes', fmt: int, adv: 0 },
    profile_visit_rate: { key: 'profile_visit_rate', lbl: 'Paid Profile Visits Rate', fmt: pct, adv: 0 },
    profile_visits_community_interaction: { key: 'profile_visits_community_interaction', lbl: 'Paid Profile Visits', fmt: int, adv: 0 },
    reach: { key: 'reach', lbl: 'Reach', fmt: int, adv: 0 },
    shares_community_interaction: { key: 'shares_community_interaction', lbl: 'Paid Shares', fmt: int, adv: 0 },
    spend: { key: 'spend', lbl: 'Total Cost', fmt: cur, adv: 0 },
    video_play_actions: { key: 'video_play_actions', lbl: 'Video Views', fmt: int, adv: 0 }
  }
};