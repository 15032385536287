import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  account: { ...globalStateConstants.account.googleAnalytics },
  daterange: { ...globalStateConstants.daterange },
  filter: { ...globalStateConstants.filter.googleAnalytics },
  latest_sync: 0,

  data: {
    ua: {
      overview_stats: { data: {}, error: null, loading: false },
      shopping_behaviour: { data: {}, error: null, loading: false },
      chart_donut_visitor: { data: {}, error: null, loading: false },
      chart_product_revenue: { data: {}, error: null, loading: false },
      chart_product_rate: { data: {}, error: null, loading: false },
      table_product_performance: { data: {}, error: null, loading: false },
      table_source_medium: { data: {}, error: null, loading: false },
      table_exit_page: { data: {}, error: null, loading: false },
      table_top_page: { data: {}, error: null, loading: false },
    },
    ga4: {
      overview_stats: { data: {}, error: null, loading: false },
      chart_bar_top_countries: { data: {}, error: null, loading: false },
      chart_bar_top_cities: { data: {}, error: null, loading: false },
      chart_donut_gender: { data: {}, error: null, loading: false },
      chart_donut_age: { data: {}, error: null, loading: false },
      chart_donut_device: { data: {}, error: null, loading: false },
      table_user_acquisition: { data: {}, error: null, loading: false },
      table_traffic_acquisition: { data: {}, error: null, loading: false },
      table_pages_screen: { data: {}, error: null, loading: false },
      funnel_chart: { data: {}, error: null, loading: false },
      funnel_table: { data: {}, error: null, loading: false },
    },
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_VISITED: return {
      ...state, visited: true,
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_ACCOUNT: return {
      ...state, account: { /* ...state.account, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_DATERANGE: return {
      ...state, daterange: { /* ...state.daterange, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_FILTER: return {
      ...state, filter: { /* ...state.filter, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_LATEST_SYNC: return {
      ...state, latest_sync: action.payload || 0,
    };

      // UA GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          overview_stats: { /* ...state.data.ua.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_SHOPPING_BEHAVIOUR: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          shopping_behaviour: { /* ...state.data.ua.shopping_behaviour, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_VISITOR: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          chart_donut_visitor: { /* ...state.data.ua.chart_donut_visitor, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_PRODUCT_REVENUE: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          chart_product_revenue: { /* ...state.data.ua.chart_product_revenue, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_PRODUCT_RATE: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          chart_product_rate: { /* ...state.data.ua.chart_product_rate, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_PRODUCT_PERFORMANCE: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          table_product_performance: { /* ...state.data.ua.table_product_performance, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_SOURCE_MEDIUM: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          table_source_medium: { /* ...state.data.ua.table_source_medium, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_EXIT_PAGE: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          table_exit_page: { /* ...state.data.ua.table_exit_page, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_TOP_PAGE: return {
      ...state,
      data: {
        ...state.data,
        ua: {
          ...state.data.ua,
          table_top_page: { /* ...state.data.ua.table_top_page, */ ...action.payload },
        },
      },
    };

      // GA4 GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          overview_stats: { /* ...state.data.ga4.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_TOP_COUNTRIES: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          chart_bar_top_countries: { /* ...state.data.ga4.chart_bar_top_countries, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_TOP_CITIES: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          chart_bar_top_cities: { /* ...state.data.ga4.chart_bar_top_cities, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_GENDER: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          chart_donut_gender: { /* ...state.data.ga4.chart_donut_gender, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_AGE: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          chart_donut_age: { /* ...state.data.ga4.chart_donut_age, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_DEVICE: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          chart_donut_device: { /* ...state.data.ga4.chart_donut_device, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_USER_ACQUISITION: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          table_user_acquisition: { /* ...state.data.ga4.table_user_acquisition, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_TRAFFIC_ACQUISITION: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          table_traffic_acquisition: { /* ...state.data.ga4.table_traffic_acquisition, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_PAGES_SCREEN: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          table_pages_screen: { /* ...state.data.ga4.table_pages_screen, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_FUNNEL_CHART: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          funnel_chart: { /* ...state.data.ga4.funnel_chart, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_FUNNEL_TABLE: return {
      ...state,
      data: {
        ...state.data,
        ga4: {
          ...state.data.ga4,
          funnel_table: { /* ...state.data.ga4.funnel_table, */ ...action.payload },
        },
      },
    };

      // RESET REDUCER

    case TYPES.RESET_DASHBOARD_GOOGLE_ADS_DATA: return {
      ...state, data: initialState.data,
    };

    case TYPES.RESET_DASHBOARD_GOOGLE_ANALYTICS_REDUCER: return {
      ...initialState,
    };
  };
};