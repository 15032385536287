import PropTypes from 'prop-types';

const displayName = 'LandingUser';
const propTypes = {
  history: PropTypes.object,
  login: PropTypes.func,
  resetPassword: PropTypes.func,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
