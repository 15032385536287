import PropTypes from 'prop-types';
const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `Workspace`;
const propTypes = {
  title: pt.node,
  text: pt.node,
};
const defaultProps = {};
const avatarBgColorSet = [
  '#FF683A',
  '#3C41CC',
  '#59BC7D',
  '#222222',
  '#018FFC',
];

const getVisualizationBasedOnVisID = (visID=null) => {
  let visName;
  switch(visID){
    case 0: visName = 'section'; break;
    case 1: visName = 'bar_chart'; break;
    case 2: visName = 'line_chart'; break;
    case 3: visName = 'combination_chart'; break;
    case 4: visName = 'donut_chart'; break;
    case 5: visName = 'plain_card'; break;
    case 6: visName = 'text_field'; break;
    case 7: visName = 'table'; break;
    default: visName = 'section'; break;
  };
  return { visualization_id: visID || 0, visualization_name: visName };
};
const setLayoutHeightBasedOnMaxY = (layout=[]) => {
  let maxY = Math.max(...layout.map(item => item.y));
  const maxH = Math.max(...layout.filter(item => item.y === maxY).map(item => item.h));
  if (maxY === -Infinity) maxY = 0;
  return 200*(maxY + maxH);
};
const setDefaultConfigState = (openedReport={}) => ({
  accounts: [],
  blend: false,
  card_id: null,
  cr_detail_id: null,
  cr_id: openedReport?.data?.id || null,
  data: null,
  date_end_compare: null,
  date_start_compare: null,
  date_end_current: null,
  date_start_current: null,
  description: '',
  filter_by: 'campaign',
  filter_exclude: false,
  filter_value: [],
  loading: false,
  show_total: true,
  position: {},
  time_frame: '',
  title: '',
  use_compare: false,
  visualization_id: null,
  visualization_name: null,
});
const setWorkspaceStateBasedOnConfig = (openedReport={}, availableMetrics={ googleAds: [], meta: [], tiktok: [] }) => {
  const sharedConfigFields = {
    accounts: [],
    blend: false,
    card_id: '',
    cr_detail_id: null,
    cr_id: openedReport.data.id,
    data: null,
    date_end_compare: null, // '2022-12-31',
    date_end_current: '2023-12-31',
    date_start_compare: null, // '2022-01-01',
    date_start_current: '2023-01-01',
    description: '',
    filter_by: 'campaign',
    filter_exclude: false,
    filter_value: [],
    loading: 'Upserting data. Please wait...',
    preset_range_compare: null, // 'previous period',
    preset_range_current: 'last year',
    preset_range_value: 'last year',
    position: {},
    show_total: true,
    time_frame: 'monthly',
    title: '',
    use_compare: false,
    visualization_id: null,
    visualization_name: null,
  };
  let configList = []; let height = 1450; let layout = [];
  if (Array.isArray(openedReport?.data?.configList) && openedReport?.data?.configList?.length > 0) {
    configList = [...openedReport?.data?.configList?.sort((a,b) => Number(a.position.i) - Number(b.position.i)).map(configItem => ({
      ...configItem, card_id: configItem?.position?.i, cr_id: openedReport?.data?.id, data: null, show_total: true,
      accounts: configItem?.accounts?.map(configAct => ({
        ...configAct,
        ...openedReport?.data?.accounts?.find(act => act?.account_id === configAct?.account_id),
      })),
    }))];
    layout = openedReport?.data?.configList?.map(configItem => ({ ...configItem?.position }));
    height = setLayoutHeightBasedOnMaxY(layout);
  } else {
    const getMetricsFromAvailableMetrics = (platform='', metricIDs=[]) => availableMetrics.data[platform].filter(m => metricIDs.includes(m.id));
    switch(openedReport.data.document_type) {
      default:
        layout = [
          { i: '0', x: 0, y: 0, w: 16, h: 1, minH: 1, minW: 16, isResizable: false },
          { i: '1', x: 0, y: 0, w: 4, h: 4, minH: 2, minW: 4, isResizable: true },
        ];
        break;
  
      case 'template': {
        // Overall spend section
        configList = [
          {
            ...sharedConfigFields, ...getVisualizationBasedOnVisID(0),
            description: `<br /><b>Total Ad Spend Overview</b>`,
            position: { i: 0, x: 0, y: 0, h: 1, w: 16, minH: 1, minW: 16, isResizable: false },
          },
          {
            ...sharedConfigFields, ...getVisualizationBasedOnVisID(7),
            accounts: openedReport.data.accounts.map(act => ({
              ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [1,37,70]),
            })),
            position: { i: 0, x: 0, y: 0,  h: 6, w: 16, minH: 4, minW: 16, isResizable: true },
            show_total: true,
          },
          {
            ...sharedConfigFields, ...getVisualizationBasedOnVisID(2),
            accounts: openedReport.data.accounts.map(act => ({
              ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [1,37,70]),
            })),
            position: { i: 0, x: 0, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
            title: 'Ad Spend',
          },
          {
            ...sharedConfigFields, ...getVisualizationBasedOnVisID(4),
            accounts: openedReport.data.accounts.map(act => ({
              ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [1,37,70]),
            })),
            position: { i: 0, x: 8, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
            title: 'Ad Spend',
          },
        ];
        // Meta Ads Regular accounts
        openedReport.data.accounts
          .filter(act => act.platform === 'meta' && ['conversion', null].includes(act.details.type))
          .forEach(act => configList.push(
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(0),
              description: `<br /><b>Meta Ads Regular</b> ${act.account_name}`,
              position: { i: 0, x: 0, y: 0, h: 1, w: 16, minH: 1, minW: 16, isResizable: false },
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(3),
              accounts: [{
                ...act, metrics: {
                  bar: getMetricsFromAvailableMetrics(act.platform, [2]),
                  line: getMetricsFromAvailableMetrics(act.platform, [1]),
                },
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'Ads Spend vs Reach',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(3),
              accounts: [{
                ...act, metrics: {
                  bar: getMetricsFromAvailableMetrics(act.platform, [7]),
                  line: getMetricsFromAvailableMetrics(act.platform, [1]),
                },
              }],
              position: { i: 0, x: 8, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'Ads Spend vs Link Clicks',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(1),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [9,6]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'CPC & CPM',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(2),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [1,15]),
              }],
              position: { i: 0, x: 8, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'Ads Spend vs Purchase Value',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(1),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [14,17]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'Purchase vs ATC',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(1),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [15,18]),
              }],
              position: { i: 0, x: 8, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'Purchase Value vs ATC Value',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(5),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [1,2,3,7,11,14,15,17,18,13,6,4,9]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 16, minH: 4, minW: 8, isResizable: true },
              title: 'Performance Summary',
            },
          ))
        // Meta Ads CPAS accounts
        openedReport.data.accounts
          .filter(act => act.platform === 'meta' && ['collaborative'].includes(act.details.type))
          .forEach(act => configList.push(
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(0),
              description: `<br /><b>Meta Ads CPAS</b> ${act.account_name}`,
              position: { i: 0, x: 0, y: 0, h: 1, w: 16, minH: 1, minW: 16, isResizable: false },
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(3),
              accounts: [{
                ...act, metrics: {
                  bar: getMetricsFromAvailableMetrics(act.platform, [1]),
                  line: getMetricsFromAvailableMetrics(act.platform, [7]),
                },
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'Ad Spend vs Link Click',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(2),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [29,14]),
              }],
              position: { i: 0, x: 8, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'View Content & Purchase'
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(2),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [15,17]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'Purchase Value vs ATC Value'
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(3),
              accounts: [{
                ...act, metrics: {
                  bar: getMetricsFromAvailableMetrics(act.platform, [1]),
                  line: getMetricsFromAvailableMetrics(act.platform, [22]),
                },
              }],
              position: { i: 0, x: 8, y: 0, h: 4, w: 8, minH: 4, minW: 8, isResizable: true },
              title: 'Ad Spend vs ROAS',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(5),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [1,2,3,7,23,24,26,27,22,29,4]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 16, minH: 4, minW: 8, isResizable: true },
              title: 'Performance Summary',
            },
          ))
        // Google Ads accounts
        const googleAdsChannelTypes = [
          { label: 'SEM', value: 'sem' },
          { label: 'GDN', value: 'gdn' },
          { label: 'YouTube', value: 'youtube' },
          { label: 'Discovery', value: 'discovery' },
          { label: 'Performance Max', value: 'performance_max' },
          { label: 'Smart Campaign', value: 'smart_campaign' },
          { label: 'App', value: 'app' },
        ];
        openedReport.data.accounts
          .filter(act => act.platform === 'googleAds')
          .forEach(act => configList.push(
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(0),
              description: `<b>Google Ads</b> ${act.account_id}<br /><span>Total Ad Spend Overview (All Channel)</span>`,
              position: { i: 0, x: 0, y: 0, h: 1, w: 16, minH: 1, minW: 16, isResizable: false },
            },
            /* {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(7),
              // accounts: [{
              //   ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [70])
              // }],
              accounts: googleAdsChannelTypes.map(t => t.value).map(channel => ({
                ...act, account_name: channel, metrics: getMetricsFromAvailableMetrics(act.platform, [70]),
              })),
              position: { i: 0, x: 0, y: 0, h: 6, w: 16, minH: 4, minW: 16, isResizable: true },
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(2),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [70]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 16, minW: 8, isResizable: true },
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(0),
              description: `<br /><span>Performance Overview (All Channel)</span>`,
              position: { i: 0, x: 0, y: 0, h: 1, w: 16, minH: 1, minW: 16, isResizable: false },
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(7),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [70,71,72,73,74,75,76,77,78,79]),
              }],
              position: { i: 0, x: 0, y: 0, h: 6, w: 16, minH: 4, minW: 16, isResizable: true },
            }, */
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(5),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [70,71,72,75,76,77]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 16, minH: 4, minW: 8, isResizable: true },
              title: 'Performance Summary',
            },
          ))
        // TikTok Ads accounts
        openedReport.data.accounts
          .filter(act => act.platform === 'tiktok')
          .forEach(act => configList.push(
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(0),
              description: `<br /><b>TikTok Ads</b> ${act.account_name}`,
              position: { i: 0, x: 0, y: 0, h: 1, w: 16, minH: 1, minW: 16, isResizable: false },
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(2),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [37,38,55,40,45]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 16, minH: 4, minW: 8, isResizable: true },
              title: 'Spending, Reach, Impressions, Link Clicks, Video Views',
            },
            {
              ...sharedConfigFields, ...getVisualizationBasedOnVisID(5),
              accounts: [{
                ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [37,49,52,62,41,40,50,59,60,65,64]),
              }],
              position: { i: 0, x: 0, y: 0, h: 4, w: 16, minH: 4, minW: 8, isResizable: true },
              title: 'Performance Summary',
            },
          ))
        // Overall summary section
        configList.push(
          {
            ...sharedConfigFields, ...getVisualizationBasedOnVisID(0),
            description: `<br /><b>All Channel Summary</b>`,
            position: { i: 0, x: 0, y: 0, h: 1, w: 16, minH: 1, minW: 16, isResizable: false },
          },
          {
            ...sharedConfigFields, ...getVisualizationBasedOnVisID(5),
            accounts: openedReport.data.accounts.map(act => ({
              ...act, metrics: getMetricsFromAvailableMetrics(act.platform, [
                /* googleAds */ 70,71,72,79,
                /* meta */ 1,2,3,7,8,11,17,26,18,27,14,23,15,24,13,22,
                /* tiktok */ 37,38,55,49,40,52,53,62,
              ])
            })),
            blend: true,
            position: { i: 0, x: 0, y: 0, h: 4, w: 16, minH: 4, minW: 8, isResizable: true },
            title: 'Performance Summary',
          },
          {
            ...sharedConfigFields, ...getVisualizationBasedOnVisID(0),
            description: `<br /><b>Conclusion & Recommendation</b>`,
            position: { i: 0, x: 0, y: 0, h: 1, w: 16, minH: 1, minW: 16, isResizable: false },
          },
          {
            ...sharedConfigFields, ...getVisualizationBasedOnVisID(6),
            description: `Edit text on side menu`,
            position: { i: 0, x: 0, y: 0, h: 2, w: 16, minH: 2, minW: 4, isResizable: true },
          },
        );

        // configList formatting
        const positions = configList.map(configItem => configItem.position);
        const formattedPositions = [];
        for (let p=0; p<positions.length; p+=1) {
          if (p === 0) {
            formattedPositions.push(positions[p]);
          } else {
            const [newPosition, oldPosition] = [positions[p], positions[p-1]];
            if (oldPosition.w === 16 || (oldPosition.x === 8 && oldPosition.w === 8)) {
              newPosition.y = oldPosition.y + oldPosition.h;
            } else if (oldPosition.w === newPosition.w) {
              newPosition.y = oldPosition.y;
            };
            formattedPositions.push(newPosition);
          };
        };
        configList = configList.map((configItem, idx) => ({
          ...configItem, card_id: `${idx}`, position: { ...formattedPositions[idx], i: `${idx}` },
        }));
        layout = configList.map(configItem => configItem.position).sort((a,b) => Number(a.i) - Number(b.i));
        height = setLayoutHeightBasedOnMaxY(layout);
        break;
      }
    };
  };
  return { configList, height, layout };
};

export default {
  displayName,
  propTypes,
  defaultProps,
  avatarBgColorSet,
  setLayoutHeightBasedOnMaxY,
  setDefaultConfigState,
  setWorkspaceStateBasedOnConfig,
};