import { connect } from 'react-redux';
import DashboardLayout from './DashboardLayout.component';

const mapStateToProps = (state) => ({
  ...state.user,
  // notifications: state.user.notifications,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
