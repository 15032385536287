import React from 'react';
import { Button, Card, Col, DatePicker, Image, Popover, Radio, Row, Select, Space, Switch, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  format,
  lastDayOfMonth,
  startOfWeek,
} from 'date-fns';
import Moment from 'moment';

import { dateToDateString, formatDateDisplay } from '../../../utils-v1/daterange.utils';
import { ICON_CALENDAR } from '../../../assets-v1/figma-icons';

import dateConstants from '../../../constants-v1/date.constants';
import globalStateConstants from '../../../constants-v1/global-state.constants';

import config from './RangePickerCreateReport.component.config';
import './RangePickerCreateReport.component.styles.less';

const { Text } = Typography;
const { TODAY, YESTERDAY, LAST_WEEK, LAST_MONTH, LAST_YEAR } = dateConstants;

const today = TODAY.RAW;
const yesterday = YESTERDAY.RAW;
const lastWeek = LAST_WEEK.RAW;
const lastMonth = LAST_MONTH.RAW;
const lastYear = LAST_YEAR.RAW;

const RangePickerCreateReport = ({
  defaultState = { ...globalStateConstants.daterange },
  onApply = () => null,
  ...props
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [presetRange, setPresetRange] = React.useState({
    value: defaultState?.preset_range_value || null,
    current: defaultState?.preset_range_current || null,
    compare: defaultState?.preset_range_compare || null,
  });
  const [rangePickerState, setRangePickerState] = React.useState({
    current: {
      open: false,
      date_start: dateToDateString(today),
      date_end: dateToDateString(today),
    },
    compare: {
      open: false,
      date_start: dateToDateString(yesterday),
      date_end: dateToDateString(yesterday),
    },
    custom_ranges: {
      current: {
        date_start: dateToDateString(today),
        date_end: dateToDateString(today),
      },
      compare: {
        date_start: dateToDateString(yesterday),
        date_end: dateToDateString(yesterday),
      },
    },
    display: {
      date_start: dateToDateString(today),
      date_end: dateToDateString(today),
    },
  });
  const [useCompare, setUseCompare] = React.useState(defaultState?.use_compare || false);

  React.useEffect(() => {
    setAffectedStatesByPresetRange(presetRange.value);
  }, []);

  const setAffectedStatesByPresetRange = (newPresetRangeValue='') => {
    switch (newPresetRangeValue) {
      case 'today':
        setPresetRange({ value: 'today', current: newPresetRangeValue, compare: 'previous period' });
        setRangePickerState({
          ...rangePickerState,
          current: {
            open: false,
            date_start: dateToDateString(today),
            date_end: dateToDateString(today),
          },
          compare: {
            open: false,
            date_start: dateToDateString(yesterday),
            date_end: dateToDateString(yesterday),
          },
        });
        break;
      case 'last 7 days':
        setPresetRange({ value: 'last 7 days', current: newPresetRangeValue, compare: 'previous period' });
        setRangePickerState({
          ...rangePickerState,
          current: {
            open: false,
            date_start: dateToDateString(addDays(today, -7)),
            date_end: dateToDateString(addDays(today, -1)),
          },
          compare: {
            open: false,
            date_start: dateToDateString(addDays(today, -14)),
            date_end: dateToDateString(addDays(today, -8)),
          },
        });
        break;
      case 'last week':
        setPresetRange({ value: 'last week', current: newPresetRangeValue, compare: 'previous period' });
        setRangePickerState({
          ...rangePickerState,
          current: {
            open: false,
            date_start: dateToDateString(startOfWeek(lastWeek)),
            date_end: dateToDateString(addDays(startOfWeek(lastWeek), 6)),
          },
          compare: {
            open: false,
            date_start: dateToDateString(startOfWeek(addWeeks(lastWeek, -1))),
            date_end: dateToDateString(addDays(startOfWeek(addWeeks(lastWeek, -1)), 6)),
          },
        });
        break;
      case 'last month':
        setPresetRange({ value: 'last month', current: newPresetRangeValue, compare: 'previous period' });
        setRangePickerState({
          ...rangePickerState,
          current: {
            open: false,
            date_start: format(lastMonth, 'yyyy-MM-01'),
            date_end: format(lastDayOfMonth(lastMonth), 'yyyy-MM-dd'),
          },
          compare: {
            open: false,
            date_start: format(addMonths(lastMonth, -1), 'yyyy-MM-01'),
            date_end: format(lastDayOfMonth(addMonths(lastMonth, -1)), 'yyyy-MM-dd'),
          },
        });
        break;
      case 'last year':
        setPresetRange({ value: 'last year', current: newPresetRangeValue, compare: 'previous period' });
        setRangePickerState({
          ...rangePickerState,
          current: {
            open: false,
            date_start: format(lastYear, 'yyyy-01-01'),
            date_end: format(lastYear, 'yyyy-12-31'),
          },
          compare: {
            open: false,
            date_start: format(addYears(lastYear, -1), 'yyyy-01-01'),
            date_end: format(addYears(lastYear, -1), 'yyyy-12-31'),
          },
        });
        break;
      case 'custom range':
        setPresetRange({
          value: 'custom range',
          current: `${format(new Date(rangePickerState.custom_ranges.current.date_start || dateToDateString(today)), 'dd MMM yyyy')} - ${format(new Date(rangePickerState.custom_ranges.current.date_end || dateToDateString(today)), 'dd MMM yyyy')}`,
          compare: `${format(new Date(rangePickerState.custom_ranges.compare.date_start || dateToDateString(yesterday)), 'dd MMM yyyy')} - ${format(new Date(rangePickerState.custom_ranges.compare.date_end || dateToDateString(yesterday)), 'dd MMM yyyy')}`,
        });
        setRangePickerState({
          ...rangePickerState,
          current: {
            open: false,
            date_start: rangePickerState.custom_ranges.current.date_start || dateToDateString(today),
            date_end: rangePickerState.custom_ranges.current.date_end || dateToDateString(today),
          },
          compare: {
            open: false,
            date_start: rangePickerState.custom_ranges.compare.date_start || dateToDateString(yesterday),
            date_end: rangePickerState.custom_ranges.compare.date_end || dateToDateString(yesterday),
          },
        });
        break;
      default:
        break;
    };
  };

  const onPresetRangeChange = (e) => setAffectedStatesByPresetRange(e.target.value);

  const onRangePickerChange = (rangePickerKey) => (dates=[], dateStrings=[]) => {
    const rawDates = Array.isArray(dates) && dates.map(date => date?._d) || [new Date(), new Date()];
    setRangePickerState({
      ...rangePickerState,
      custom_ranges: {
        ...rangePickerState.custom_ranges,
        [rangePickerKey]: { date_start: dateToDateString(rawDates[0]), date_end: dateToDateString(rawDates[1]) },
      },
      [rangePickerKey]: {
        open: !rangePickerState[rangePickerKey].open,
        date_start: dateToDateString(rawDates[0]),
        date_end: dateToDateString(rawDates[1]),
      },
    });
  };

  const getPopoverContent = () => (
    <Card id="rangePickerPopoverContent">
      <div id="rangePickerTitle" className="popover-inner-content">Select Range</div>
      <Space direction="vertical" size={12} className="popover-inner-content">
        <div
          onClick={() => setRangePickerState({
            ...rangePickerState,
            current: { ...rangePickerState.current, open: false },
            compare: { ...rangePickerState.compare, open: false },
          })}
        >
          <Radio.Group onChange={onPresetRangeChange} value={presetRange.value}>
            <Space direction="vertical" size={12}>
              <Radio value="today">Today</Radio>
              <Radio value="last 7 days">Last 7 Days</Radio>
              <Radio value="last week">Last Week</Radio>
              <Radio value="last month">Last Month</Radio>
              <Radio value="last year">Last Year</Radio>
              <Radio value="custom range">Custom Range</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div style={{ position: 'relative'}}>
          {(['current', 'compare']).map(key =>
            <Row
              className="popover-inner-content"
              hidden={(key === `compare` && useCompare === false) || presetRange.value !== 'custom range'}
              key={`${key}RangePicker`}
            >
              <Col sm={24} md={24} lg={24} style={{ marginLeft: 5 }}>
                <div hidden={!useCompare} className="range-picker-identifier">{key === `current` ? 'Current' : 'Previous'} Range</div>
                <div hidden={useCompare} className="range-picker-identifier">Select Date</div>
                <DatePicker.RangePicker
                  className={`${key}-range-picker`}
                  defaultValue={presetRange.value === 'custom range' ? [
                    Moment(rangePickerState.custom_ranges[key].date_start),
                    Moment(rangePickerState.custom_ranges[key].date_end),
                  ] : []}
                  disabled={presetRange.value !== 'custom range'}
                  onChange={onRangePickerChange(key)}
                  onClick={() => setRangePickerState({ ...rangePickerState, [key]: { ...rangePickerState[key], open: true } })}
                  placeholder={['start', 'end'].map(el => `${key} ${el}`)}
                  separator="-"
                  picker="date"
                  suffixIcon={<Image src={ICON_CALENDAR} alt="icon-calendar" preview={false} width={15} />}
                />
              </Col>
            </Row>
          )}
          <div hidden={!useCompare || presetRange.value !== 'custom range'} className="separator-compare"></div>
        </div>
      </Space>
      <div className="compare-selection" style={{ marginTop: 5 }}>
        <div className="popover-inner-content">
          Compare Selection
          <Switch defaultChecked={useCompare} onChange={() => setUseCompare(!useCompare)} style={{ float: 'right' }} />
        </div>
      </div>
      <div className="popover-inner-content">
        <Button
          id="applyButton"
          type="primary"
          onClick={() => {
            const presetRangeCurrentForCustomRange = `${format(new Date(rangePickerState.custom_ranges.current.date_start || dateToDateString(today)), 'dd MMM yyyy')} - ${format(new Date(rangePickerState.custom_ranges.current.date_end || dateToDateString(today)), 'dd MMM yyyy')}`;
            actions.onApply();
            if (onApply && typeof onApply === 'function') onApply({
              preset_range_value: presetRange.value,
              date_start_current: rangePickerState.current.date_start,
              date_end_current: rangePickerState.current.date_end,
              preset_range_current: presetRange.current.replaceAll('_',' '),
              use_compare: useCompare,
              ...(useCompare ? {
                date_start_compare: rangePickerState.compare.date_start,
                date_end_compare: rangePickerState.compare.date_end,
                preset_range_compare: presetRange.compare.replaceAll('_',' '),
              } : {
                date_start_compare: null,
                date_end_compare: null,
                preset_range_compare: null,
              })
            });
          }}
        >Apply</Button>
      </div>
    </Card>
  );

  const actions = {
    onApply: () => {
      setPopoverOpen(false);
      setRangePickerState({
        ...rangePickerState,
        display: { date_start: rangePickerState.current.date_start, date_end: rangePickerState.current.date_end },
      });
    },
    onCancel: () => {
      setPopoverOpen(false);
    },
  };

  const renderComponent = () => (<Popover
    overlayClassName="range-picker-create-report"
    content={getPopoverContent()}
    onOpenChange={(open) => {
      setPopoverOpen(open);
    }}
    open={popoverOpen}
    placement="bottomLeft"
    trigger="click"
  >
    <Button
      id="RangePickerCreateReportBtn"
      style={{ height: 35, width: '100%' }}
      onClick={() => setPopoverOpen(true)}
    >
      <span style={{ marginLeft: 10, marginRight: 10, marginBottom: 0, fontSize: 14 }} >
        {
          presetRange.value === 'custom range' ? (
            <span>
              {formatDateDisplay(rangePickerState.display.date_start)}
              {' - '}
              {formatDateDisplay(rangePickerState.display.date_end)}
            </span>
          ) : <>{presetRange.value || <span style={{ color: '#BABABA' }}>Select date range...</span>}</>
        }
      </span>
      <span style={{ float: 'right' }}>
        <DownOutlined style={{ color: '#BABABA', fontSize: 14, marginTop: 5, marginLeft: 0 }} />
      </span>
    </Button>
  </Popover>)

  return(<>
    <div id="RangePickerCreateReportWrapper">{renderComponent()}</div>
  </>);
};

RangePickerCreateReport.displayName = config.displayName;
RangePickerCreateReport.propTypes = config.propTypes;
RangePickerCreateReport.defaultProps = config.defaultProps;

export default RangePickerCreateReport;