import { connect } from 'react-redux';
import DashboardGoogleAnalytics from './DashboardGoogleAnalytics.component';

import { getAccountConfig, getLastActivityUser, getUserData, syncAccountData, updateCoachmarkLocal } from '../../redux-v1/actions/user/__common.actions';
import { setPlatformState, getPlatformData } from '../../redux-v1/actions/dashboard/__common.actions';

const mapStateToProps = (state) => ({
  ...state.dashboard.googleAnalytics,
  accountBinding: state.user.accounts_by_platform.googleAnalytics,
  synchronizingAccounts: state.user.synchronizingAccounts,
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});

const mapDispatchToProps = {
  getAccountConfig,
  getLastActivityUser,
  getUserData,
  syncAccountData,
  updateCoachmarkLocal,

  setPlatformState,
  getPlatformData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGoogleAnalytics);
