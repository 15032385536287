import PropTypes from 'prop-types';
import TYPES from '../../redux-v1/types';
import metrics from '../../constants-v1/metrics-config/googleAds';
import { reqFormat as rpf } from '../../utils-v1/request.utils';

const displayName = 'Google Adwords';
const propTypes = {
  columnData: PropTypes.array
};
const defaultProps = {
  dateRangeValue: PropTypes.object,
};

const requestConfig = [
  // SEM

  {
    endpoint: 'google-ads/data/overview',
    method: 'post',
    reqparams: {
      channel_type: 'search',
      report_type: 'campaign',
      metricsConfig: [
        metrics.sem.spending,
        metrics.sem.impressions,
        metrics.sem.impression_abs_top,
        metrics.sem.impression_top,
        metrics.sem.clicks,
        metrics.sem.cpc,
        metrics.sem.ctr,
        metrics.sem.conversions,
        metrics.sem.conversion_values,
        metrics.sem.cost_per_conversions,
        metrics.sem.conversion_rates,
        metrics.sem.roas,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_OVERVIEW_STATS,
  },
  {
    endpoint: 'google-ads/data/periodic',
    method: 'post',
    reqparams: {
      channel_type: 'search',
      report_type: 'campaign',
      metricsConfig: [
        metrics.sem.clicks,
        metrics.sem.conversions,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_CLICKS_CONVERSIONS,
  },
  {
    endpoint: 'google-ads/data/overview/age',
    method: 'post',
    reqparams: {
      channel_type: 'search',
      report_type: 'age',
      metricsConfig: [
        metrics.sem.clicks,
        metrics.sem.conversions,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_AGE,
  },
  {
    endpoint: 'google-ads/data/overview/gender',
    method: 'post',
    reqparams: {
      channel_type: 'search',
      report_type: 'gender',
      metricsConfig: [
        metrics.sem.clicks,
        metrics.sem.conversions,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_GENDER,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'search',
      report_type: 'keywords',
      metricsConfig: [
        // metrics.sem.match_type,
        metrics.sem.clicks,
        metrics.sem.impressions,
        metrics.sem.ctr,
        metrics.sem.cost,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_TOP_KEYWORDS,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'search',
      report_type: 'search_term',
      metricsConfig: [
        // metrics.sem.match_type,
        metrics.sem.clicks,
        metrics.sem.impressions,
        metrics.sem.ctr,
        metrics.sem.cost,
        metrics.sem.cpc,
        metrics.sem.conversions,
        metrics.sem.cost_per_conversions,
        metrics.sem.conversion_rates,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_SEARCH_TERM_INSIGHTS,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'search',
      report_type: 'ad_performance',
      metricsConfig: [
        // metrics.sem.ad_group,
        metrics.sem.conversions,
        metrics.sem.cost_per_conversions,
        metrics.sem.conversion_rates,
        // metrics.sem.ad_strength,
        metrics.sem.impression_top,
        metrics.sem.impression_abs_top,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_AD_PERFORMANCE,
  },
  {
    endpoint: 'google-ads/data/preview',
    method: 'post',
    reqparams: {
      channel_type: 'search',
      report_type: 'preview_sem',
      metricsConfig: [
        metrics.sem.clicks,
        metrics.sem.impressions,
        metrics.sem.spending,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_PREVIEW,
  },

  // GDN

  {
    endpoint: 'google-ads/data/overview',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'campaign',
      metricsConfig: [
        metrics.gdn.spending,
        metrics.gdn.impressions,
        metrics.gdn.clicks,
        metrics.gdn.cpc,
        metrics.gdn.ctr,
        metrics.gdn.conversions,
        metrics.gdn.conversion_values,
        metrics.gdn.cost_per_conversions,
        metrics.gdn.conversion_rates,
        metrics.gdn.roas,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_OVERVIEW_STATS,
  },
  {
    endpoint: 'google-ads/data/periodic',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'campaign',
      metricsConfig: [
        metrics.gdn.clicks,
        metrics.gdn.impressions,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_CLICKS_IMPRESSIONS,
  },
  {
    endpoint: 'google-ads/data/overview/age',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'age',
      metricsConfig: [
        metrics.gdn.clicks,
        metrics.gdn.views,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_AGE,
  },
  {
    endpoint: 'google-ads/data/overview/gender',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'gender',
      metricsConfig: [
        metrics.gdn.clicks,
        metrics.gdn.views,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_GENDER,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'age',
      metricsConfig: [
        metrics.gdn.cost,
        // metrics.gdn.campaign,
        // metrics.gdn.ad_group,
        // metrics.gdn.ad_group_status,
        metrics.gdn.clicks,
        metrics.gdn.impressions,
        metrics.gdn.ctr,
        metrics.gdn.cpc,
        metrics.gdn.conversions,
        metrics.gdn.conversion_rates,
        metrics.gdn.cost_per_conversions,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AGE,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'campaign',
      metricsConfig: [
        metrics.gdn.cost,
        metrics.gdn.clicks,
        metrics.gdn.impressions,
        metrics.gdn.ctr,
        metrics.gdn.cpc,
        metrics.gdn.conversions,
        metrics.gdn.conversion_rates,
        metrics.gdn.conversion_values,
        metrics.gdn.cost_per_conversions,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_CAMPAIGN,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'ad_group',
      metricsConfig: [
        metrics.gdn.cost,
        metrics.gdn.clicks,
        metrics.gdn.impressions,
        metrics.gdn.ctr,
        metrics.gdn.cpc,
        metrics.gdn.conversions,
        metrics.gdn.conversion_rates,
        metrics.gdn.cost_per_conversions,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AD_GROUP,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'audience_segment',
      metricsConfig: [
        metrics.gdn.cost,
        metrics.gdn.clicks,
        metrics.gdn.impressions,
        metrics.gdn.ctr,
        metrics.gdn.cpc,
        metrics.gdn.conversions,
        metrics.gdn.conversion_rates,
        metrics.gdn.cost_per_conversions,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AUDIENCE_SEGMENTATION,
  },
  {
    endpoint: 'google-ads/data/preview',
    method: 'post',
    reqparams: {
      channel_type: 'display',
      report_type: 'preview_gdn',
      metricsConfig: [
        metrics.gdn.clicks,
        metrics.gdn.impressions,
        metrics.gdn.spending,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_PREVIEW,
  },

  // YOUTUBE

  {
    endpoint: 'google-ads/data/overview',
    method: 'post',
    reqparams: {
      channel_type: 'video',
      report_type: 'campaign',
      metricsConfig: [
        metrics.youtube.spending,
        metrics.youtube.impressions,
        metrics.youtube.clicks,
        metrics.youtube.cpc,
        metrics.youtube.cpm,
        metrics.youtube.views,
        metrics.youtube.view_rates,
        metrics.youtube.cpv,
        metrics.youtube.conversions,
        metrics.youtube.conversion_values,
        metrics.youtube.cost_per_conversions,
        metrics.youtube.conversion_rates,
        metrics.youtube.roas,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_OVERVIEW_STATS,
  },
  {
    endpoint: 'google-ads/data/periodic',
    method: 'post',
    reqparams: {
      channel_type: 'video',
      report_type: 'campaign',
      metricsConfig: [
        metrics.youtube.impressions,
        metrics.youtube.views,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_IMPRESSIONS_VIEWS,
  },
  {
    endpoint: 'google-ads/data/overview/age',
    method: 'post',
    reqparams: {
      channel_type: 'video',
      report_type: 'age',
      metricsConfig: [
        metrics.youtube.clicks,
        metrics.youtube.views,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_AGE,
  },
  {
    endpoint: 'google-ads/data/overview/gender',
    method: 'post',
    reqparams: {
      channel_type: 'video',
      report_type: 'gender',
      metricsConfig: [
        metrics.youtube.clicks,
        metrics.youtube.views,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_GENDER,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'video',
      report_type: 'audience_segment',
      metricsConfig: [
        // metrics.youtube.ad_group,
        metrics.youtube.impressions,
        metrics.youtube.views,
        metrics.youtube.view_rates,
        metrics.youtube.cpv,
        metrics.youtube.cpm,
        metrics.youtube.cost,
        metrics.youtube.conversions,
        metrics.youtube.conversion_rates,
        metrics.youtube.cost_per_conversions,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_AUDIENCE_SEGMENTATION,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'video',
      report_type: 'audience_segment_ad_shown',
      metricsConfig: [
        // metrics.youtube.type,
        // metrics.youtube.campaign,
        // metrics.youtube.ad_group,
        metrics.youtube.impressions,
        metrics.youtube.views,
        metrics.youtube.view_rates,
        metrics.youtube.cpv,
        metrics.youtube.cpm,
        metrics.youtube.cost,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_AUDIENCE_SEGMENT,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'video',
      report_type: 'yt_ad_performance',
      metricsConfig: [
        metrics.youtube.impressions,
        metrics.youtube.views,
        metrics.youtube.view_rates,
        metrics.youtube.cpv,
        metrics.youtube.cpm,
        metrics.youtube.cost,
        metrics.youtube.clicks,
        metrics.youtube.engagements,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_YOUTUBE_AD_PERFORMANCE,
  },
  {
    endpoint: 'google-ads/data/preview',
    method: 'post',
    reqparams: {
      channel_type: 'video',
      report_type: 'preview_yt',
      metricsConfig: [
        metrics.youtube.clicks,
        metrics.youtube.impressions,
        metrics.youtube.spending,
        metrics.youtube.views,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_PREVIEW,
  },

  // DISCOVERY

  {
    endpoint: 'google-ads/data/overview',
    method: 'post',
    reqparams: {
      channel_type: 'discovery',
      report_type: 'campaign',
      metricsConfig: [
        metrics.discovery.spending,
        metrics.discovery.impressions,
        metrics.discovery.clicks,
        metrics.discovery.cpc,
        metrics.discovery.ctr,
        metrics.discovery.conversions,
        metrics.discovery.conversion_values,
        metrics.discovery.cost_per_conversions,
        metrics.discovery.conversion_rates,
        metrics.discovery.roas,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_OVERVIEW_STATS,
  },
  {
    endpoint: 'google-ads/data/periodic',
    method: 'post',
    reqparams: {
      channel_type: 'discovery',
      report_type: 'campaign',
      metricsConfig: [
        metrics.discovery.clicks,
        metrics.discovery.conversions,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_CLICKS_CONVERSIONS,
  },
  {
    endpoint: 'google-ads/data/overview/age',
    method: 'post',
    reqparams: {
      channel_type: 'discovery',
      report_type: 'age',
      metricsConfig: [
        metrics.discovery.clicks,
        metrics.discovery.views,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_AGE,
  },
  {
    endpoint: 'google-ads/data/overview/gender',
    method: 'post',
    reqparams: {
      channel_type: 'discovery',
      report_type: 'gender',
      metricsConfig: [
        metrics.discovery.clicks,
        metrics.discovery.views,
      ],
    },
    reqparamsformat: rpf.periodic,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_GENDER,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'discovery',
      report_type: 'campaign',
      metricsConfig: [
        metrics.discovery.cost,
        metrics.discovery.impressions,
        metrics.discovery.clicks,
        metrics.discovery.cpc,
        metrics.discovery.cpm,
        metrics.discovery.ctr,
        metrics.discovery.conversions,
        metrics.discovery.conversion_values,
        metrics.discovery.cost_per_conversions,
        metrics.discovery.conversion_rates,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_CAMPAIGN,
  },
  {
    endpoint: 'google-ads/data/table',
    method: 'post',
    reqparams: {
      channel_type: 'discovery',
      report_type: 'ad_performance',
      metricsConfig: [
        metrics.discovery.cost,
        metrics.discovery.impressions,
        metrics.discovery.clicks,
        metrics.discovery.cpc,
        metrics.discovery.cpm,
        metrics.discovery.ctr,
        metrics.discovery.conversions,
        metrics.discovery.conversion_values,
        metrics.discovery.cost_per_conversions,
        metrics.discovery.conversion_rates,
      ],
    },
    reqparamsformat: rpf.table,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_AD_PERFORMANCE,
  },

  // PERFORMANCE MAX

  {
    endpoint: 'google-ads/data/overview',
    method: 'post',
    reqparams: {
      channel_type: 'performance_max',
      report_type: 'campaign',
      metricsConfig: [
        metrics.performance_max.spending,
        metrics.performance_max.impressions,
        metrics.performance_max.clicks,
        metrics.performance_max.cpc,
        metrics.performance_max.ctr,
        metrics.performance_max.conversions,
        metrics.performance_max.conversion_values,
        metrics.performance_max.cost_per_conversions,
        metrics.performance_max.conversion_rates,
        metrics.performance_max.roas,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_PERFORMANCE_MAX_OVERVIEW_STATS,
  },

  // SMART CAMPAIGN

  {
    endpoint: 'google-ads/data/overview',
    method: 'post',
    reqparams: {
      channel_type: 'smart',
      report_type: 'campaign',
      metricsConfig: [
        metrics.smart_campaign.spending,
        metrics.smart_campaign.impressions,
        metrics.smart_campaign.clicks,
        metrics.smart_campaign.cpc,
        metrics.smart_campaign.ctr,
        metrics.smart_campaign.conversions,
        metrics.smart_campaign.conversion_values,
        metrics.smart_campaign.cost_per_conversions,
        metrics.smart_campaign.conversion_rates,
        metrics.smart_campaign.roas,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_SMART_CAMPAIGN_OVERVIEW_STATS,
  },

  // APP

  {
    endpoint: 'google-ads/data/overview',
    method: 'post',
    reqparams: {
      channel_type: 'multi_channel',
      report_type: 'campaign',
      metricsConfig: [
        metrics.app.spending,
        metrics.app.impressions,
        metrics.app.clicks,
        metrics.app.cpc,
        metrics.app.ctr,
        metrics.app.conversions,
        metrics.app.conversion_values,
        metrics.app.cost_per_conversions,
        metrics.app.conversion_rates,
        metrics.app.roas,
        metrics.app.app_install,
        metrics.app.cost_per_install,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ADS_APP_OVERVIEW_STATS,
  },
];

export default { displayName, propTypes, defaultProps, requestConfig };
