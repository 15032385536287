import React from "react";
import { Modal, Carousel, Button, Typography, Space, Image } from "antd";
import { ICON_ROCKET } from "../../../assets-v1/figma-icons";

import config from './OnboardingPopUp.component.config';
import './OnboardingPopUp.component.styles.less';

const { Title, Text } = Typography;

const OnboardingPopUp = ({ ...props }) => {
  const openModal = props?.openModal;
  const contentOnboarding = config.onBoardingContent[props?.content];
  const refCarousel = React.useRef();
  const [stateCarousel, setStateCarousel] = React.useState(0);

  const renderCarousel = () => (
    <Carousel
      ref={refCarousel}
      initialSlide={stateCarousel}
      dots={props?.dots}
      dotPosition="bottom"
      autoplay={false}
    >
      {contentOnboarding?.map((content) => (
        <div className="content-onboarding" key={content.key}>
          <div className="content-img" style={{ backgroundImage: `url(${content.image})` }} />
          <div className="content-text">
            <Title>{content.title}</Title>
            <Text>{content.description}</Text>
          </div>
        </div>
      ))}
    </Carousel>
  );

  const renderButtonNav = () => {
    let render = null;
    const content = props?.content;

    switch (content) {
      case 'onTour':
        render = (
          <div className="button-navigation">
            <Space>
              <Button
                type="link"
                onClick={() => props?.skipPlatform()}
                hidden={stateCarousel === 1}
              >
                Explore Platform Data
              </Button>
              <Button
                type="primary"
                className="default-button"
                onClick={() => {
                  const next = stateCarousel + 1;
                  setStateCarousel(next);
                  refCarousel.current.goTo(next);
                }}
                hidden={stateCarousel === 1}
              >
                Next Step
              </Button>
              <Button
                type="link"
                onClick={() => props?.skipBudget()}
                hidden={stateCarousel === 0}
              >
                Skip, and finish the tour
              </Button>
              <Button
                type="primary"
                className="default-button"
                onClick={() => props?.jumpToBudget()}
                hidden={stateCarousel === 0}
              >
                Create Budget
              </Button>
            </Space>
          </div>
        );
        break;
      default:
        render = (
          <div className="button-navigation">
            <Space>
              <Button
                type="default"
                className="btn-back"
                onClick={() => {
                  const back = stateCarousel - 1;
                  setStateCarousel(back);
                  refCarousel.current.goTo(back);
                }}
                hidden={!stateCarousel > 0 || stateCarousel === contentOnboarding?.length - 1}
              >
                Back
              </Button>
              <Button
                type="primary"
                className="btn-next"
                onClick={() => {
                  const next = stateCarousel + 1;
                  setStateCarousel(next);
                  refCarousel.current.goTo(next);
                }}
                hidden={stateCarousel === contentOnboarding?.length - 1}
              >
                Next
              </Button>
              <Button
                type="primary"
                hidden={stateCarousel === contentOnboarding?.length - 1 ? false : 'true' }
                onClick={() => {
                  props.history.replace('/onboarding')
                }}
              >
                Start Now
                <Image src={ICON_ROCKET} preview={false} width={20} style={{ marginLeft: 10 }} />
              </Button>
            </Space>
          </div>
        );
        break;
    }

    return render;
  };

  const renderModal = () => (
    <Modal
      className="OnboardingPopUp"
      open={openModal}
      closable={false}
      closeIcon={false}
      title={false}
      footer={false}
      centered
      width={680}
    >
      {renderCarousel()}
      {renderButtonNav()}
    </Modal>
  );

  return renderModal();
};

OnboardingPopUp.displayName = config.displayName;
OnboardingPopUp.propTypes = config.propTypes;
OnboardingPopUp.defaultProps = config.defaultProps;

export default OnboardingPopUp;
