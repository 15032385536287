import Docs from '../containers/Docs/Docs.container';
import DocsContent from '../containers/Docs/DocsContent/DocsContent.container';
import path from './path';

const { landDocsPath } = path;

export const LANDINGDOCS_ROUTE = [
  // About
  {
    id: 'index',
    path: landDocsPath.landing.url,
    component: Docs,
    exact: true,
  },
  {
    id: 'account-selection',
    path: landDocsPath["dashboard-feature"].children["account-selection"].url,
    component: DocsContent,
  },
  {
    id: 'date-picker',
    path: landDocsPath["dashboard-feature"].children["date-picker"].url,
    component: DocsContent,
  },
  {
    id: 'platform-data',
    path: landDocsPath["dashboard-feature"].children["platform-data"].url,
    component: DocsContent,
  },
  {
    id: 'data-filter',
    path: landDocsPath["dashboard-feature"].children["data-filter"].url,
    component: DocsContent,
  },
  // {
  //   id: 'summary-page',
  //   path: landDocsPath["dashboard-feature"].children["summary-page"].url,
  //   component: DocsContent,
  // },
  // {
  //   id: 'filter-metrics',
  //   path: landDocsPath["dashboard-feature"].children["filter-metrics"].url,
  //   component: DocsContent,
  // },
];
