import path from './path';

import Profile from '../containers/Profile/Profile.container';
import SummaryPage from '../containers/Summary/Summary.container';
import DashboardWebsite from '../containers/DashboardWebsite/DashboardWebsite.container';
import DashboardMarketplace from '../containers/DashboardMarketplace/DashboardMarketplace.container';
import DashboardMeta from '../containers/DashboardMeta/DashboardMeta.container';
import DashboardTiktok from '../containers/DashboardTiktok/DashboardTiktok.container';
import DashboardInstagram from '../containers/DashboardInstagram/DashboardInstagram.container';
import DashboardGoogleAnalytics from '../containers/DashboardGoogleAnalytics/DashboardGoogleAnalytics.container';
import DashboardGoogleAds from '../containers/DashboardGoogleAds/DashboardGoogleAds.container';
import AccountBinding from '../containers/AccountBinding/AccountBinding.container';
import BudgetTracker from '../containers/BudgetTracker/BudgetTracker.container';
import CustomReport from '../containers/CustomReport/CustomReport.container';
import CustomReportWorkspace from '../containers/CustomReport/Workspace/Workspace.container'; 
import OnboardingTour from '../containers/OnboardingTour/OnboardingTour.container';

export const DASHBOARD_ROUTE = [
  {
    id: 'index',
    path: path.dashboardPath.summary,
    component: SummaryPage,
    exact: true,
  },
  {
    id: path.dashboardPath.profile,
    path: path.dashboardPath.profile,
    component: Profile,
    exact: true,
  },
  {
    id: path.dashboardPath.googleAds,
    path: path.dashboardPath.googleAds,
    component: DashboardGoogleAds,
    exact: true,
  },
  {
    id: path.dashboardPath.googleAnalytics,
    path: path.dashboardPath.googleAnalytics,
    component: DashboardGoogleAnalytics,
    exact: true,
  },
  {
    id: path.dashboardPath.instagram,
    path: path.dashboardPath.instagram,
    component: DashboardInstagram,
    exact: true,
  },
  {
    id: path.dashboardPath.marketplace,
    path: path.dashboardPath.marketplace,
    component: DashboardMarketplace,
    exact: true,
  },
  {
    id: path.dashboardPath.meta,
    path: path.dashboardPath.meta,
    component: DashboardMeta,
    exact: true,
  },
  {
    id: path.dashboardPath.tiktok,
    path: path.dashboardPath.tiktok,
    component: DashboardTiktok,
    exact: true,
  },
  {
    id: path.dashboardPath.website,
    path: path.dashboardPath.website,
    component: DashboardWebsite,
    exact: true,
  },
  {
    id: path.dashboardPath.bindingGoogleAds,
    path: path.dashboardPath.bindingGoogleAds,
    component: AccountBinding,
    exact: true,
  },
  {
    id: path.dashboardPath.bindingGoogleAnalytics,
    path: path.dashboardPath.bindingGoogleAnalytics,
    component: AccountBinding,
    exact: true,
  },
  {
    id: path.dashboardPath.bindingInstagram,
    path: path.dashboardPath.bindingInstagram,
    component: AccountBinding,
    exact: true,
  },
  {
    id: path.dashboardPath.bindingMarketplace,
    path: path.dashboardPath.bindingMarketplace,
    component: AccountBinding,
    exact: true,
  },
  {
    id: path.dashboardPath.bindingMeta,
    path: path.dashboardPath.bindingMeta,
    component: AccountBinding,
    exact: true,
  },
  {
    id: path.dashboardPath.bindingTiktok,
    path: path.dashboardPath.bindingTiktok,
    component: AccountBinding,
    exact: true,
  },
  {
    id: path.dashboardPath.bindingWebsite,
    path: path.dashboardPath.bindingWebsite,
    component: AccountBinding,
    exact: true,
  },
  {
    id: path.dashboardPath.budgetTracker,
    path: path.dashboardPath.budgetTracker,
    component: BudgetTracker,
    exact: true,
  },
  {
    id: path.dashboardPath.customReport,
    path: path.dashboardPath.customReport,
    component: CustomReport,
    exact: true,
  },
  {
    id: path.dashboardPath.customReportWorkspace,
    path: path.dashboardPath.customReportWorkspace,
    component: CustomReportWorkspace,
    // exact: true,
  },
  {
    id: path.dashboardPath.onboarding,
    path: path.dashboardPath.onboarding,
    component: OnboardingTour,
    exact: true,
  },
];
