import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import config from './PreviewContent.component.config';
import './PreviewContent.component.styles.less';

const { Paragraph } = Typography;

const PreviewContent = ({ content, ...props }) => {
  const contentData = content?.data;
  return (
    <div id="PreviewContent">
      {contentData?.images?.length > 0 && <div className="content-images" style={{ backgroundImage: `url(${contentData?.images[0]?.url})`}} />}
      {contentData?.video && (
        <div className="content-video">
          <iframe title='title player' src={contentData?.video?.replaceAll('watch?v=', 'embed/')} allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" />
        </div>
      )}
      {contentData?.title && <div className="content-title">{contentData?.title}</div>}
      {contentData?.long_headline && <div className="content-long-headline">{contentData?.long_headline}</div>}
      {contentData?.headlines && <div className="content-headlines">
        <Paragraph ellipsis={{ rows: 4 }}>{contentData?.headlines?.join(', ')}</Paragraph>
      </div>}
      {contentData?.descriptions && <div className="content-descriptions">
        <Paragraph ellipsis={{ rows: 4 }}>{contentData?.descriptions?.join(', ')}</Paragraph>
      </div>}
    </div>
  );
};

PreviewContent.displayName = config.displayName;
PreviewContent.propTypes = config.propTypes;
PreviewContent.defaultProps = config.defaultProps;

export default PreviewContent;
