import React from 'react';
import { Layout } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LANDINGPUB_ROUTE } from '../../navigation/landingPublic.route';

import config from './LandingPublic.component.config';

class LandingPublic extends React.PureComponent {
  render() {
    return (
      <Layout>
        <Switch>
          {LANDINGPUB_ROUTE.map((route) => (
            <Route key={route.id} {...route} />
          ))}
          <Redirect to="/login" />
        </Switch>
      </Layout>
    );
  }
}

LandingPublic.displayName = config.displayName;
LandingPublic.defaultProps = config.defaultProps;
LandingPublic.propTypes = config.propTypes;

export default LandingPublic;
