import PropTypes from 'prop-types';
const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `Stats`;
const propTypes = {
  title: pt.node,
  text: pt.node,
};
const defaultProps = {};

const getTemplateProps = () => ({
  statsItemProps: {
    adverse: false,
    className: '',
    oneLiner: false,
    title: '',
    useCompare: false,
    valueFormatter: (value) => value,
    values: { current: null, percentage: null, previous: null },
  },
  statsListProps: {
    actions: { copy: { enabled: false }, expand: { enabled: false } },
    collapse: { enabled: false, sliceAt: 4 },
    columns: 2,
    headerStats: null,
    loading: false,
    statsList: [],
    title: '',
    useCompare: false,
  },
});

export default { displayName, propTypes, defaultProps, getTemplateProps };