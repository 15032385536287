import React from 'react';
import { format } from 'date-fns';
import { Image, Layout, Typography, Input, Row, Col, Button, List, Card } from 'antd';
import { SearchOutlined, RightOutlined } from '@ant-design/icons';
import { BDD_LOGIN_FORM_LOGO, BG_WIDE_HELP } from '../../assets-v1/images';
import { ICON_WHATSAPP } from '../../assets-v1/figma-icons';
import { AccountBoxGS } from '../../components/atoms';


import config from './Docs.component.config';
import './Docs.component.styles.less';
import animations from '../../utils-v1/animations';

const { Header, Footer } = Layout;
const { Title, Text } = Typography;
const bddai = <b>BDD-AI</b>;

class Docs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log(`About is mounted...`);
  }

  componentWillUnmount() {
    console.log(`About is unmounted...`);
  }

  constructLayoutProps = () => {
    const { location, history } = this.props;
    const { title, url } = config;
    return { location, history, title, url };
  }

  renderHeaderDesktop = () => (
    <Header id="header-help-landing">
      <div className="help-header">
        <Title level={2} style={{ fontWeight: 700, fontSize: 24, display: 'flex', alignItems: 'center' }}>
          <Image src={BDD_LOGIN_FORM_LOGO} preview={false} width={45} />
          &nbsp;Help
        </Title>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AccountBoxGS />
      </div>
    </Header>
  );

  render() {
    return (
      <Layout {...this.constructLayoutProps()}>
        {this.renderHeaderDesktop()}
        <Layout id="help-layout-landing" style={{ animation: animations.fadeIn }}>
          <div className="onboard-banner" style={{ backgroundImage: `url(${BG_WIDE_HELP})`}}>
            <Title level={1}>Hi {this.props.user.name}, we&lsquo;re here to help.</Title>
            <Input prefix={<SearchOutlined style={{ marginLeft: 10, color: '#A6AAB1' }} />} placeholder="How can we help?" size="large" style={{ width: '40%'}} />
          </div>
          <div className="content-board">
            <Row gutter={24}>
              <Col md={18} lg={18}>
                <Title level={2}>BDD.AI Help</Title>
                <div style={{ color: '#6B6B6B', width: '90%' }}>
                  First and foremost, welcome to BDD-AI dashboard web-app documentation. This documentation is intended to be used as user&lsquo;s guide to BDD-AI utilization. You can navigate around this documentation by using the side navigation bar or by clicking available linked text elements.
                  <br /><br />Top level menu of this documentation are as shown below. You can click on one to take yourself to the corresponding submenu.
                </div>
                <div>
                  <List
                    grid={{ gutter: 16, column: 3 }}
                    dataSource={[
                      { icon: BDD_LOGIN_FORM_LOGO, url: '/', title: 'Basic'},
                      { icon: BDD_LOGIN_FORM_LOGO, url: '/docs/date-picker', title: 'Dashboard Feature'},
                      { icon: BDD_LOGIN_FORM_LOGO, url: '/profile', title: 'Your Profile'},
                    ]}
                    renderItem={(item) => (
                      <List.Item>
                        <Card id="card-list-onboard">
                          <div className="item-doc">
                            <Image src={item.icon} preview={false} width={80} />
                            <Button type="link" className="btn-link-doc" onClick={() => this.props.history.push(item.url)}>{item.title} <RightOutlined style={{ fontSize: 12 }} /></Button>
                          </div>
                        </Card>
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
              <Col md={6} lg={6}>
                <Button className='button-whatsapp'>
                  <ICON_WHATSAPP />&nbsp;&nbsp;&nbsp;Contact Us
                </Button>
              </Col>
            </Row>
          </div>
        </Layout>
        <Footer style={{ textAlign: 'center', backgroundColor: '#fff', position: 'fixed', bottom: 0, width: '100%'}}>
          <Text className="footer">
            © {format(new Date(), 'yyyy ')}
            BDD AI All right reserved
          </Text>
        </Footer>
      </Layout>
    )
  }
}

Docs.displayName = config.displayName;
Docs.propTypes = config.propTypes;
Docs.defaultProps = config.defaultProps;

export default Docs;
