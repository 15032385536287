import React from 'react';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  notification,
  Radio,
  Space,
  Switch,
  Typography,
} from 'antd';
import axios from 'axios';

import TYPES from '../../redux-v1/types';
import { generateDashboardProps, onMountFunction, renderEmptyState } from '../../utils-v1/platform-page.utils';
import { generateRequestParamsByFormat } from '../../utils-v1/request.utils';
import stringConstants from '../../constants-v1/string.constants';

import { DashboardLayoutGS } from '../../components/layouts-backup';

import WebsitePage from './WebsitePage/WebsitePage.component';

import config from './DashboardWebsite.component.config';
import './DashboardWebsite.component.styles.less';

const { Title, Text, Paragraph } = Typography;
const { key, name, alt, PLATFORM, useFilter } = stringConstants.website;
let source;

class DashboardWebsite extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    source = axios.CancelToken.source();
  };

  componentDidMount() {
    onMountFunction(this, key);
  };

  componentWillUnmount() {
    if (source) { source.cancel('DashboardWebsite axios cancel dua to unmounting') };
  };

  fetchData = (format='') => {
    const reqParams = generateRequestParamsByFormat(key, this.props, format, { cancelToken: source.token });
    reqParams.formatSummary.type = this.props.account.details.type; // account type assignment
    const reqList = config.requestConfig.map(item => this.props.getPlatformData(item.type, reqParams[item.reqparamsformat]));
    if (this.props.account.account_id) {
      Promise.all(reqList)
        .then(res => console.log({ res }))
        .catch(err => console.log({ err }))
        .finally(() => localStorage.setItem(`DASHBOARD_${PLATFORM}_DATA`, JSON.stringify(this.props.data)))
    };
  };

  renderPageByAccountType = () => {
    switch(this.props?.account?.platform) {
      case 'website': return <WebsitePage { ...this.props } />;
      default: return <>
        <div style={{ borderRadius: 10 }}>
          <Paragraph style={{ marginTop: 30 }}>Please select an account type first!</Paragraph>
        </div>
      </>
    };
  };

  render() {
    return (
      <DashboardLayoutGS { ...generateDashboardProps(this, key, useFilter)}>
        <div id="DashboardWebsite">
          {!this.props.account?.config_id ? renderEmptyState(this, key) : this.renderPageByAccountType()}
        </div>
      </DashboardLayoutGS>
    );
  };
};

DashboardWebsite.displayName = config.displayName;
DashboardWebsite.defaultProps = config.defaultProps;
DashboardWebsite.propTypes = config.propTypes;

export default DashboardWebsite;
