import React from 'react';
import { Button, Image, Modal, Typography } from 'antd';
import BindingForms from '../BindingForms/BindingForms.component';

import {
  BINDING_FORM_CLOSE_ICON,
  ICON_REFRESH_WHITE,
  ICON_TRASH_WHITE,
} from '../../../assets-v1/figma-icons';

import config from './AccountListWrapperButtons.component.config';
import './AccountListWrapperButtons.component.styles.less';

const { Title, Text, Paragraph } = Typography;
const differentSetup = ['marketplace', 'website'];

const BindingWrapperButtons = ({
  accountList,
  btnIcon,
  onBindAccount,
  onBindAccountLoading,
  onRemoveAccount,
  onRemoveAccountLoading,
  platformKey,
  platformName,
}) => {
  const [state, setState] = React.useState({
    modalAddVisibility: false,
    modalUnbindVisibility: false,
  });

  const renderModalAdd = () => (
    <Modal
      closeIcon={<Image src={BINDING_FORM_CLOSE_ICON} alt="binding_form_close_icon" preview={false} />}
      footer={false}
      okButtonProps={{ hidden: true }}
      onCancel={() => setState({ ...state, modalAddVisibility: false })}
      open={state.modalAddVisibility}
      title={<div style={{ textAlign: 'left' }}>
        <Title level={2}>Add {platformKey === 'marketplace' ? 'Marketplace' : 'Website'} Account</Title>
      </div>}
    >
      <BindingForms
        account={{}}
        command="add"
        onAdd={(values) => {
          localStorage.setItem(platformKey === 'marketplace' ? 'TEMP_NEW_MARKETPLACE' : 'TEMP_NEW_WEBSITE', JSON.stringify(values));
          onBindAccount();
          setState({ ...state, modalAddVisibility: false })
        }}
        platformKey={platformKey}
      />
    </Modal>
  );

  const renderModalUnbind = () => (
    <Modal
      footer={null}
      onCancel={() => setState({ ...state, modalUnbindVisibility: false })}
      onOk={() => { onRemoveAccount(); setState({ ...state, modalUnbindVisibility: false })}}
      open={state.modalUnbindVisibility}
      title={<div style={{ textAlign: 'center' }}>
        <Title level={2}>Confirm Removal of All {platformName} Accounts</Title>
      </div>}
    >
      <Paragraph>Are you sure you want to remove all of your <b>{platformName}</b> connections?</Paragraph>
      <Paragraph style={{ textAlign: 'right' }}>
        <Button danger onClick={() => setState({ ...state, modalUnbindVisibility: false })}>Cancel</Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button type="primary" onClick={() => { onRemoveAccount(); setState({ ...state, modalUnbindVisibility: false })}}>Ok</Button>
      </Paragraph>
    </Modal>
  );
  
  const renderButtons = () => {
    const isDifferentSetup = differentSetup.includes(platformKey);
    switch (isDifferentSetup) {
      default: return(<>
        {Array.isArray(accountList) && accountList?.length > 0 ?
          <>
            <Button
              className="resync-button toolbar-button"
              icon={<Image src={ICON_REFRESH_WHITE} alt="icon_refresh_white" preview={false} style={{ position: 'relative', top: -2 }} />}
              hidden={!accountList || accountList?.length === 0}
              loading={onBindAccountLoading || false}
              onClick={onBindAccount}
              size="large"
            ><span style={{ fontSize: 16, marginLeft: 10 }}>Rebind</span></Button>

            <Button
              className="remove-button toolbar-button"
              icon={<Image src={ICON_TRASH_WHITE} alt="icon_trash_white" preview={false} style={{ position: 'relative', top: -2 }} />}
              hidden={!accountList || accountList?.length === 0}
              loading={onRemoveAccountLoading || false}
              onClick={() => setState({ ...state, modalUnbindVisibility: true })}
              size="large"
            ><span style={{ fontSize: 16, marginLeft: 10 }}>Remove All</span></Button>
          </>
          :
          <>
            <Button
              className={`bind-${platformKey}-button`}
              icon={<Image src={btnIcon} alt={`icon-${platformKey}`} preview={false} style={{ width: 25 }} />}
              loading={onBindAccountLoading || false}
              onClick={onBindAccount}
              size="large"
              style={{ height: 'max-content'}}
              type="primary"
            ><span style={{ fontSize: 16, marginLeft: '10px' }}>Connect With {platformName}</span></Button>
          </>
        }
      </>);

      case true: return(
        <Button
          className={`bind-${platformKey}-button`}
          loading={onBindAccountLoading || false}
          type="primary"
          size="large"
          onClick={() => setState({ ...state, modalAddVisibility: true })}
        ><span style={{ fontSize: 16 }}>Add {platformKey === 'marketplace' ? 'Marketplace' : platformName}</span></Button>
      );
    };
  };

  return(
    <div className="toolbar-button">
      {renderButtons()}
      {renderModalAdd() || null}
      {renderModalUnbind()}
    </div>
  )
}

BindingWrapperButtons.displayName = config.displayName;
BindingWrapperButtons.propTypes = config.propTypes;
BindingWrapperButtons.defaultProps = config.defaultProps;

export default BindingWrapperButtons;
