import React from 'react';

import config from './NewTag.component.config';
import './NewTag.component.styles.less';

const NewTag = () => (
  <span id="NewTag">New!</span>
); 

NewTag.displayName = config.displayName;
NewTag.propTypes = config.propTypes;
NewTag.defaultProps = config.defaultProps;

export default NewTag;