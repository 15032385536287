import { connect } from 'react-redux';
import DashboardTiktok from './DashboardTiktok.component';

import {
  getAccountConfig,
  getLastActivityUser,
  getUserData,
  syncAccountData,
  updateCoachmarkLocal,
} from '../../redux-v1/actions/user/__common.actions';
import { setPlatformState, getPlatformData } from '../../redux-v1/actions/dashboard/__common.actions';

const mapStateToProps = (state) => ({
  ...state.dashboard.tiktok,
  accountBinding: state.user.accounts_by_platform.tiktok,
  synchronizingAccounts: state.user.synchronizingAccounts,
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
  tokenStatus: state.user.accounts_by_platform.token_status.tiktok,
});

const mapDispatchToProps = {
  getAccountConfig,
  getLastActivityUser,
  getUserData,
  syncAccountData,
  updateCoachmarkLocal,

  setPlatformState,
  getPlatformData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTiktok);
