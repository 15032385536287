import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';
import TYPES from '../../types';

const { BindingApiService, setBindingApiReqHeaders } = apiBinding.getInstance();

export const getTiktokAuthUrl = () => async (dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_TIKTOK,
    payload: { loadings: { bind: true } },
  });

  let outcome;
  try {
    const { status, data } = await BindingApiService.get(
      `/binding/tiktok/get-auth-url?redirect_uri=${window.location.origin}/binding-tiktok`,
      { ...setBindingApiReqHeaders(getToken()) },
    );
    if (data) {
      dispatch({
        type: TYPES.SET_BINDING_BIND_TIKTOK,
        payload: { loadings: { bind: false } },
      });
      outcome = Promise.resolve(data?.data?.url);
      window.location.href = data?.data?.url;
    };
  } catch (err) {
    dispatch({
      type: TYPES.SET_BINDING_BIND_TIKTOK,
      payload: { loadings: { bind: false } },
    });
    outcome = Promise.reject(err);
  };
  return outcome;
};

export const bindTiktok = (payload) => async(dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_TIKTOK,
    payload: { loadings: { bind: true } },
  });

  let response;
  try{
    const { status, data } = await BindingApiService.post(
      `/binding/tiktok`,
      { auth_code: payload, user_id: getToken()?.id },
      { ...setBindingApiReqHeaders(getToken()) }
    );
    if (status === 200) {
      response = Promise.resolve();
      dispatch({
        type: TYPES.SET_BINDING_BIND_TIKTOK,
        payload: { loadings: { bind: false } },
      });
    };
  } catch (err) {
    // response = new Error("failed")
    response = Promise.reject();
    dispatch({
      type: TYPES.SET_BINDING_BIND_TIKTOK,
      payload: { loadings: { bind: false } },
    });
  };

  return response;
};
