import React from 'react';
import { Image, notification } from 'antd';
import {
  ICON_ALERT_ERROR,
  ICON_ALERT_INFO,
  ICON_ALERT_SUCCESS,
  ICON_ALERT_WARNING,
} from '../../../assets-v1/figma-icons';

import config from './Alert.component.config';
import './Alert.component.styles.less';

const Alert = {
  Error: (props) => notification.error({
    icon: <Image src={ICON_ALERT_ERROR} alt="icon_alert_error" preview={false} />,
    ...props,
  }),
  Info: (props) => notification.info({
    icon: <Image src={ICON_ALERT_INFO} alt="icon_alert_info" preview={false} />,
    ...props,
  }),
  Success: (props) => notification.success({
    icon: <Image src={ICON_ALERT_SUCCESS} alt="icon_alert_success" preview={false} />,
    ...props,
  }),
  Warning: (props) => notification.warning({
    icon: <Image src={ICON_ALERT_WARNING} alt="icon_alert_warning" preview={false} />,
    ...props,
  }),
};

Alert.displayName = config.displayName;
Alert.propTypes = config.propTypes;
Alert.defaultProps = config.defaultProps;

export default Alert;