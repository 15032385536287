import TYPES from '../../types';
import config from './__config';

const initialState = { ...config.authStructure, error: null };

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;
    case TYPES.SET_AUTH_LOGIN_META:
    case TYPES.SET_AUTH_LOGIN_GOOGLE: return {
      ...state,
      loadingGoogle: action?.payload?.loadingGoogle,
    };
    case TYPES.SET_AUTH_RESET_PASSWORD:
    case TYPES.SET_AUTH_LOGIN_EMAIL: return {
      ...state,
      loadingEmail: action?.payload?.loadingEmail,
    };
    case TYPES.SET_AUTH_REGISTER: return {
      ...state,
      loadingRegister: action?.payload?.loadingRegister,
    };
  }
};
