import Highcharts from 'highcharts';
import mapIndo from '@highcharts/map-collection/countries/id/id-all.geo.json';
import mapWorld from '@highcharts/map-collection/custom/world.geo.json';
import isDate from 'date-fns/isDate';
import { formatDateChartLabel } from './daterange.utils';
import { bddColor } from './colors.utils';
import { formatInteger } from './number.utils';

const BLACK = '#000000';

export const reflowChart = (chart) => {
  if (chart && chart?.reflow && typeof chart?.reflow === 'function') chart.reflow();
};

export const generateSeries = (arg={
  dataList: [{ label: '', color: bddColor.BLUE_MAIN, hexColor: bddColor.BLUE_MAIN, data: [], value: 0 }],
  entryFormatter: (entry=['', 0]) => entry,
}) => {
  const configureData = (item) => ({
    name: item?.name || item?.label || '', // bar, barComparison, donut, donutThickness,
    color: item?.color || item?.hexColor || bddColor.BLUE_MAIN, // bar, barComparison, donut, donutThickness,
    data: Array.isArray(item?.data) && item?.data.map(
      el => typeof arg?.entryFormatter === 'function' && arg?.entryFormatter(el) || el,
    ) || [], // bar, barComparison,
    fillColor: {
      linearGradient: [0, 0, 0, 300],
      stops: [
        [0, Highcharts.color(item?.color || item?.hexColor || bddColor.BLUE_MAIN).setOpacity(0.3).get('rgba')],
        [1, Highcharts.color(item?.color || item?.hexColor || bddColor.BLUE_MAIN).setOpacity(0).get('rgba')],
      ],
    }, // line
    y: Number(item?.value) || 0, // donut, donutThickness,
    z: Number(item?.value) || 0, // donutThickness,
  });

  const dataSeries = Array.isArray(arg?.dataList) && arg?.dataList.map(el => configureData(el)) || [];
  return dataSeries;
};

export const categoriesFormatter = {
  bar: (arg={ rawSeriesSet: [[]], splitter: '/' }) => {
    const { rawSeriesSet, splitter } = arg;
    const preOutput = [];
    if (Array.isArray(rawSeriesSet)) {
      for (let i=0; i<rawSeriesSet.length; i+=1) {
        const rawSeries = arg?.rawSeriesSet[i];
        const labels = [];
        if (Array.isArray(rawSeries?.entries)) {
          for (let j=0; j<rawSeries.entries.length; j+=1) {
            const datum = rawSeries.entries[j];
            labels.push(datum[0] || datum?.x || '')
          };
        };
        preOutput.push(labels);
      };
    };
    const output = preOutput.length > 1 && preOutput[0].map((el, idx) => {
      let category = `${formatDateChartLabel(el)}`;
      if (el !== preOutput[preOutput.length-1][idx]) {
        category = `${formatDateChartLabel(el)} ${splitter} ${formatDateChartLabel(preOutput[preOutput.length-1][idx])}`
      };
      return category;
    }) || preOutput[0];
    return output;
  },
  combination: (arg={ rawSeriesSet: [[]], splitter: '/' }) => {
    const { rawSeriesSet, splitter } = arg;
    const preOutput = [];
    if (Array.isArray(rawSeriesSet)) {
      for (let i=0; i<rawSeriesSet.length; i+=1) {
        const rawSeries = arg?.rawSeriesSet[i];
        const labels = [];
        if (Array.isArray(rawSeries?.entries)) {
          for (let j=0; j<rawSeries.entries.length; j+=1) {
            const datum = rawSeries.entries[j];
            labels.push(datum[0] || datum?.x || '')
          };
        };
        preOutput.push(labels);
      };
    };
    const output = preOutput.length > 1 && preOutput[0].map((el, idx) => {
      let category = `${formatDateChartLabel(el)}`;
      if (el !== preOutput[preOutput.length-1][idx]) {
        category = `${formatDateChartLabel(el)} ${splitter} ${formatDateChartLabel(preOutput[preOutput.length-1][idx])}`
      };
      return category;
    }) || preOutput[0];
    return output;
  },
  donut: (arg={ rawSeriesSet: [[]], splitter: '/' }) => {
    const { rawSeriesSet, splitter } = arg;
    const preOutput = [];
    if (Array.isArray(rawSeriesSet)) {
      for (let i=0; i<rawSeriesSet.length; i+=1) {
        const rawSeries = arg?.rawSeriesSet[i];
        const labels = [];
        if (Array.isArray(rawSeries?.entries)) {
          for (let j=0; j<rawSeries.entries.length; j+=1) {
            const datum = rawSeries.entries[j];
            labels.push(datum[0] || datum?.x || '')
          };
        };
        preOutput.push(labels);
      };
    };
    const output = preOutput.length > 1 && preOutput[0].map((el, idx) => {
      let category = `${formatDateChartLabel(el)}`;
      if (el !== preOutput[preOutput.length-1][idx]) {
        category = `${formatDateChartLabel(el)} ${splitter} ${formatDateChartLabel(preOutput[preOutput.length-1][idx])}`
      };
      return category;
    }) || preOutput[0];
    return output;
  },
  heatmap: (arg={ rawSeriesSet: [[]] }) => {
    const { rawSeriesSet } = arg;
    const output = { x: [], y: [] };
    if (Array.isArray(rawSeriesSet)) {
      output.x = [...new Set(rawSeriesSet.map(rawSeries => formatDateChartLabel(rawSeries?.x || rawSeries?.[0])))];
      output.y = [...new Set(rawSeriesSet.map(rawSeries => formatDateChartLabel(rawSeries?.y || rawSeries?.[1])))];
    };
    return output;
  },
  line: (arg={ rawSeriesSet: [[]], splitter: '/' }) => {
    const { rawSeriesSet, splitter } = arg;
    const preOutput = [];
    if (Array.isArray(rawSeriesSet)) {
      for (let i=0; i<rawSeriesSet.length; i+=1) {
        const rawSeries = rawSeriesSet[i];
        const labels = [];
        if (Array.isArray(rawSeries?.entries)) {
          for (let j=0; j<rawSeries.entries.length; j+=1) {
            const datum = rawSeries.entries[j];
            labels.push(datum[0] || datum?.x || '')
          };
        };
        preOutput.push(labels);
      };
    };
    const output = preOutput.length > 1 && preOutput[0].map((el, idx) => {
      let category = `${formatDateChartLabel(el)}`;
      if (el !== preOutput[preOutput.length-1][idx]) {
        category = `${formatDateChartLabel(el)} ${splitter} ${formatDateChartLabel(preOutput[preOutput.length-1][idx])}`
      };
      return category;
    }) || preOutput[0];
    return output;
  },
  map: () => ({}),
};

export const seriesFormatter = {
  bar: (arg={ rawSeriesSet: [[]], colorSet: [] }) => {
    const { rawSeriesSet, colorSet } = arg;
    const output = [];
    if (Array.isArray(rawSeriesSet)) {
      for (let i = 0; i < rawSeriesSet.length; i+=1) {
        const rawSeries = rawSeriesSet[i];
        const data = [];
        if (Array.isArray(rawSeries?.entries)) {
          for (let j = 0; j < rawSeries.entries.length; j+=1) {
            const datum = rawSeries.entries[j];
            data.push(datum[1] || datum?.y || 0);
          };
        };
        output.push({
          data,
          name: rawSeries?.label || `Series ${i}`,
          type: 'column',
          color: rawSeries?.color || colorSet[i],
        });
      };
    };
    return output;
  },
  combination: (arg={ rawSeries: [[]], colorSet: [] }) => {
    const { rawSeriesSet, colorSet } = arg;
    const output = [];
    if (Array.isArray(rawSeriesSet)) {
      for (let i = 0; i < rawSeriesSet.length; i+=1) {
        const rawSeries = rawSeriesSet[i];
        const data = [];
        if (Array.isArray(rawSeries?.entries)) {
          for (let j = 0; j < rawSeries.entries.length; j+=1) {
            const datum = rawSeries.entries[j];
            data.push(datum[1] || datum?.y || 0);
          };
        };
        output.push({
          data,
          yAxis: rawSeries?.type === 'bar' ? 0 : 1,
          name: rawSeries?.label || `Series ${i}`,
          type: rawSeries?.type === 'bar' ? 'column' : 'line',
          color: rawSeries?.color || colorSet[i],
        });
      };
    };
    return output;
  },
  donut: (arg={ rawSeriesSet: [[]], showValuesInChart: false, enableLegend: false, innerSize: '50%' }) => {
    const { rawSeriesSet, showValuesInChart, enableLegend, innerSize } = arg;
    const output = [];
    if (Array.isArray(rawSeriesSet)) {
      output.push({
        data: rawSeriesSet.map(entry => [entry?.label || entry?.[0], entry?.value || entry?.[1]]),
        dataLabels: { enabled: showValuesInChart },
        innerSize: innerSize || '65%',
        showInLegend: enableLegend,
        size: '100%',
        type: 'pie',
      });
    };
    return output;
  },
  heatmap: (arg={ showValuesInChart: false, rawSeriesSet: [[]] }) => {
    const { showValuesInChart, rawSeriesSet } = arg;
    const preOutput = [];
    if (Array.isArray(rawSeriesSet)) {
      const xLabels = [...new Set(rawSeriesSet.map(rawSeries => rawSeries?.x || rawSeries?.[0]))];
      const yLabels = [...new Set(rawSeriesSet.map(rawSeries => rawSeries?.y || rawSeries?.[1]))];
      for (let i=0; i<rawSeriesSet.length; i+=1) {
        const rawSeries = rawSeriesSet[i];
        preOutput.push([
          xLabels.indexOf(rawSeries?.x || rawSeries?.[0]),
          yLabels.indexOf(rawSeries?.y || rawSeries?.[1]),
          rawSeries?.value || rawSeries?.[2],
        ]);
      };
    };
    const output = [{
      borderWidth: 0.1,
      data: preOutput,
      dataLabels: { enabled: showValuesInChart },
    }]
    return output;
  },
  line: (arg={ rawSeriesSet: [[]], colorSet: [] }) => {
    const { rawSeriesSet, colorSet } = arg;
    const output = [];
    if (Array.isArray(rawSeriesSet)) {
      for (let i = 0; i < rawSeriesSet.length; i+=1) {
        const rawSeries = rawSeriesSet[i];
        const data = [];
        if (Array.isArray(rawSeries?.entries)) {
          for (let j = 0; j < rawSeries.entries.length; j+=1) {
            const datum = rawSeries.entries[j];
            data.push(datum[1] || datum?.y || 0);
          };
        };
        output.push({
          data,
          name: rawSeries?.label || `Series ${i}`,
          type: 'spline',
          color: rawSeries?.color || colorSet[i],
        });
      };
    };
    return output;
  },
  map: (arg={ rawSeriesSet: [], mapType: '' }) => {
    const { rawSeriesSet, mapType } = arg;
    const output = [{
      data: Array.isArray(rawSeriesSet) && rawSeriesSet || [],
      name: 'Basemap',
      mapData: mapType === 'world' ? mapWorld : mapIndo,
      borderColor: '#A0A0A0',
      borderWidth: 0,
      nullColor: '#f0f2f4',
      showInLegend: false,
      states: { hover: { color: '#59bc7d', enabled: true } },
      dataLabels: { enabled: true, format: '{point.value}' },
    }];
    return output;
  },
};

export const tooltipFormatter = {
  bar: (arg={ data: {}, colorSet: [], shared: false }) => {
    const { data, colorSet, shared } = arg;
    let tooltipContent = ``;
    if (shared) {
      const rendered = data.points.map(point => {
        const name = point?.series?.name;
        const color = point?.color;
        const number = point?.y;
        // return `<b style="color:${color}">${name}</b>: ${number?.toLocaleString('en')}`;
        return `<div style="display: flex; align-items: center; border-bottom: 1px solid #D0D5DD">
          <div style="width:15px; height: 15px; border-radius: 5px; background-color: ${color}; margin-right: 5px"></div>
          <div>
            <span style="font-size: 10px; color: #9CA0A6">${name}</span><br />
            <span style="font-size: 16px">${number?.toLocaleString('en')}</span>
          </div>
        </div>`;
      });
      tooltipContent = `<div style="z-index:9; background-color: #fff; padding: 2px 10px;">
      <span style="font-size: 14px; font-weight: 700; margin-bottom: 20px;">${data.x}</span><br />
        ${rendered.join('//').replaceAll('//', '<br />')}
      </div>`;
    } else {
      const name = data?.point?.series?.name;
      const color = data?.point?.color;
      const number = data?.point?.y;
      const rendered = `<div style="display: flex; align-items: center; border-bottom: 1px solid #D0D5DD">
      <div style="width:15px; height: 15px; border-radius: 5px; background-color: ${color}; margin-right: 5px"></div>
      <div>
        <span style="font-size: 10px; color: #9CA0A6">${name}</span><br />
        <span style="font-size: 16px">${number?.toLocaleString('en')}</span>
      </div>
    </div>`;
      tooltipContent = `<div style="z-index:9; background-color: #fff; padding: 2px 10px;">
      <span style="font-size: 14px; font-weight: 700; margin-bottom: 20px;">${data.x}</span><br />
        ${rendered}
      </div>`;
    };
    return tooltipContent;
  },
  combination: (arg={ data: {}, colorSet: [], shared: false }) => {
    const { data, colorSet, shared } = arg;
    let tooltipContent = ``;
    if (shared) {
      const rendered = data.points.map(point => {
        const name = point?.series?.name;
        const color = point?.color;
        const number = point?.y;
        // return `<b style="color:${color}">${name}</b>: ${number?.toLocaleString('en')}`;
        return `<div style="display: flex; align-items: center; border-bottom: 1px solid #D0D5DD">
          <div style="width:15px; height: 15px; border-radius: 5px; background-color: ${color}; margin-right: 5px"></div>
          <div>
            <span style="font-size: 10px; color: #9CA0A6">${name}</span><br />
            <span style="font-size: 16px">${number?.toLocaleString('en')}</span>
          </div>
        </div>`;
      });
      tooltipContent = `<div style="z-index:9; background-color: #fff; padding: 2px 10px;">
      <span style="font-size: 14px; font-weight: 700; margin-bottom: 20px;">${data.x}</span><br />
        ${rendered.join('//').replaceAll('//', '<br />')}
      </div>`;
    } else {
      const name = data?.point?.series?.name;
      const color = data?.point?.color;
      const number = data?.point?.y;
      const rendered = `<div style="display: flex; align-items: center; border-bottom: 1px solid #D0D5DD">
      <div style="width:15px; height: 15px; border-radius: 5px; background-color: ${color}; margin-right: 5px"></div>
      <div>
        <span style="font-size: 10px; color: #9CA0A6">${name}</span><br />
        <span style="font-size: 16px">${number?.toLocaleString('en')}</span>
      </div>
    </div>`;
      tooltipContent = `<div style="z-index:9; background-color: #fff; padding: 2px 10px;">
      <span style="font-size: 14px; font-weight: 700; margin-bottom: 20px;">${data.x}</span><br />
        ${rendered}
      </div>`;
    };
    return tooltipContent;
  },
  donut: (arg={ data: {}, colorSet: [], showDonutPercentages: false, totalDonut: 1 }) => {
    const { data, colorSet, showDonutPercentages, totalDonut  } = arg;
    const name = data?.point?.name;
    const color = data?.point?.color;
    const value = data?.y;
    const percentage = (100*value/totalDonut).toFixed(2);
    const rendered = `<b>Value: ${value?.toLocaleString('en')}</b>${showDonutPercentages ? `<br /><b>Percentage: ${percentage?.toLocaleString('en')}%</b>` : ``}`;
    return `<b style="color:${color || bddColor.BLACK}">${name}</b>:<br /><br />${rendered}`;
  },
  heatmap: (arg={ data: {}, rawSeriesSet: [[]] }) => {
    const { data, rawSeriesSet } = arg;
    const xName = categoriesFormatter.heatmap({ rawSeriesSet }).x[data?.point?.x];
    const yName = categoriesFormatter.heatmap({ rawSeriesSet }).y[data?.point?.y];
    const value = data?.point?.value;
    return `<div style="padding: 10px">
      <span>${yName} / ${xName}</span>
      <div><b>${value?.toLocaleString('en')}</b></div>
    </div>`;
  },
  line: (arg={ data: {}, colorSet: [], shared: false }) => {
    const { data, colorSet, shared } = arg;
    let tooltipContent = ``;
    if (shared) {
      const rendered = data.points.map(point => {
        const name = point?.series?.name;
        const color = point?.color;
        const number = point?.y;
        return `<div style="display: flex; align-items: center;">
          <div style="width:15px; height: 15px; border-radius: 5px; background-color: ${color}; margin-right: 5px"></div>
          <span style="font-size: 12px; color: #5E6063;">${name}: ${number?.toLocaleString('en')}</span><br />
      </div>`;
      });
      tooltipContent = `<div style="z-index:9; background-color: #fff; padding: 2px 10px;">
      <span style="font-size: 14px; font-weight: 700; margin-bottom: 20px;">${data.x}</span><br />
      <div style="margin-top: 10px">${rendered.join('//').replaceAll('//', '<br />')}</div>
      </div>`;
    } else {
      const name = data?.point?.series?.name;
      const color = data?.point?.color;
      const number = data?.point?.y;
      const rendered = `<div style="display: flex; align-items: center;">
        <div style="width:15px; height: 15px; border-radius: 5px; background-color: ${color}; margin-right: 5px"></div>
        <span style="font-size: 12px; color: #5E6063;">${name}: ${number?.toLocaleString('en')}</span><br />
      </div>`;
      tooltipContent = `<div style="z-index:9; background-color: #fff; padding: 2px 10px;">
        <span style="font-size: 14px; font-weight: 700; margin-bottom: 20px;">${data.x}</span><br />
        <div style="margin-top: 10px">${rendered}</div>
      </div>`;
    };
    return tooltipContent;
  },
  map: (arg) => ({}),
};

export const getTickInterval = (arg={ rawSeriesSet: [], forDrawer: false }) => {
  const { rawSeriesSet, forDrawer } = arg;
  let tick = 1;
  if (Array.isArray(rawSeriesSet) && Array.isArray(rawSeriesSet?.[0])) {
    const sampleTick = rawSeriesSet?.[0]?.[0]?.[0] || rawSeriesSet?.[0]?.[0]?.x;
    const isDateString = !(new Date(sampleTick).toString().includes('Jan 01 1970') || new Date(sampleTick) === 'Invalid Date');
    if (isDateString) {
      const entriesLength = rawSeriesSet?.[0]?.length;
      if (entriesLength > 60) tick = Math.round(entriesLength / 5);
      if (entriesLength > 10 ) tick = Math.round(entriesLength / 4);
    };
  };
  if (Array.isArray(rawSeriesSet) && Array.isArray(rawSeriesSet?.[0]?.entries)) {
    const sampleTick = rawSeriesSet?.[0]?.entries?.[0] || rawSeriesSet?.[0]?.[0]?.x;
    const isDateString = !(new Date(sampleTick).toString().includes('Jan 01 1970') || new Date(sampleTick) === 'Invalid Date');
    if (isDateString) {
      const entriesLength = rawSeriesSet?.[0]?.entries?.length;
      if (entriesLength > 60) tick = Math.round(entriesLength / 5);
      if (entriesLength > 10 ) tick = Math.round(entriesLength / 4);
    };
  };
  // if (forDrawer) tick = 0;
  return tick;
};
