import PropTypes from 'prop-types';

const displayName = 'AccountCard';
const propTypes = {  
  account: PropTypes.array,
  loading: PropTypes.bool,  
  type: PropTypes.string,
  modalDeleteFunc: PropTypes.func,
  withEditAction: PropTypes.bool,
  showModalEditAction: PropTypes.func
};
const defaultProps = {  
  account: [],
  loading: false,  
};

export default { displayName, propTypes, defaultProps };