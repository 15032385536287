export default {
  profile: {
    key: 'profile', name: 'Profile', alt: 'Profile', dash: 'profile', PLATFORM: null, useFilter: false,
  },

  googleAds: {
    key: 'googleAds', name: 'Google Ads', alt: 'GoogleAds', dash: 'google-ads', PLATFORM: 'GOOGLE_ADS', useFilter: true,
  },
  googleAnalytics: {
    key: 'googleAnalytics', name: 'Google Analytics', alt: 'GoogleAnalytics', dash: 'google-analytics', PLATFORM: 'GOOGLE ANALYTICS', useFilter: false,
  },
  instagram: {
    key: 'instagram', name: 'Instagram', alt: 'Instagram', dash: 'instagram', PLATFORM: 'INSTAGRAM', useFilter: false,
  },
  marketplace: {
    key: 'marketplace', name: 'Marketplace', alt: 'Marketplace', dash: 'marketplace', PLATFORM: 'MARKETPLACE', useFilter: false,
  },
  meta: {
    key: 'meta', name: 'Meta', alt: 'Meta', dash: 'meta', PLATFORM: 'META', useFilter: true,
  },
  tiktok: {
    key: 'tiktok', name: 'TikTok', alt: 'Tiktok', dash: 'tiktok', PLATFORM: 'TIKTOK', useFilter: true,
  },
  website: {
    key: 'website', name: 'Website', alt: 'Website', dash: 'website', PLATFORM: 'WEBSITE', useFilter: false,
  },
};