import React from 'react';
import { Button, Image, List, Modal, Typography } from 'antd';
import AccountCard from '../AccountCard/AccountCard.component';
import BindingForms from '../BindingForms/BindingForms.component';
import animations from '../../../utils-v1/animations';

import config from './AccountList.component.config';
import './AccountList.component.styles.less';

const { Title, Text } = Typography;
const MULTI_TYPE_PLATFORMS = ['marketplace', 'website'];

const AccountList = ({
  accountList,
  emptyContentConfig,
  filledContentConfig,
  onBindAccount,
  onBindAccountLoading,
  onDelete,
  onEdit,
  platformKey,
  platformName,
  searchedData
}) => {
  const [state, setState] = React.useState({
    modalAddVisibility: false,
  });

  const renderAccountCard = (account={}) => {
    const isMultiPlatform = MULTI_TYPE_PLATFORMS.includes(platformKey);
    const iconConfig = {
      alt: `icon-${(account?.details?.type) || filledContentConfig?.icon?.[platformKey]}`,
      preview: false,
      src: isMultiPlatform ?
        filledContentConfig?.icon?.[account?.details?.type] : filledContentConfig?.icon,
    };

    const constructAccountCardProps = () => ({
      account,
      icon: (<Image { ...iconConfig } />),
      ...(typeof onDelete === 'function' && { onDelete: () => { /* console.log({account}); */ onDelete(account) }}),
      ...(typeof onEdit === 'function' && { onEdit: () => onEdit(account) }),
      platformKey: isMultiPlatform ? (account?.platform || account?.details?.type) : platformKey,
      platformName,
    });

    return (<AccountCard { ...constructAccountCardProps() } />);
  };


  const renderFilledList = () => {
    const constructFilledListProps = () => ({
      grid: { gutter: 24, xs: 1, md: 1, lg: 2, xl: 2, xxl: 2 },
      dataSource: searchedData.length === 0 ? accountList : searchedData,
      key: `list-account-${platformKey}`,
      pagination: { position: 'bottom', pageSize: 12, showSizeChanger: false },
      renderItem: (item) => (
        <List.Item key={item.account_id}>
          {renderAccountCard(item)}
        </List.Item>
      ),
      style: { animation: animations?.popIn },
    });

    return(<List { ...constructFilledListProps() } />);
  };

  const renderEmptyBindButton = () => {
    let button;
    const buttonProps = {
      children: `Bind ${platformName} Account`,
      className: 'empty-content-bind-button',
      type: 'primary',
      hidden: true,
    };
    if (MULTI_TYPE_PLATFORMS.includes(platformKey)) {
      button = <Button onClick={() => setState({ ...state, modalAddVisibility: true })} { ...buttonProps } />;
    } else {
      button = <Button onClick={onBindAccount} loading={onBindAccountLoading} { ...buttonProps } />;
    };
    return button;
  }

  const renderEmptyList = () => (
    <div className="bindingEmptyContent">
      <div className="emptyContentIcon">
        <Image src={emptyContentConfig?.image} alt={`empty-${platformKey}-image`} preview={false} />
      </div>
      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '5vh' }}>
        <Title level={3}>There&apos;s Nothing Here...</Title>
        <Text type="secondary">Your list of accounts will appear here. Start with binding your account first.</Text>
      </div>
      <div style={{ marginTop: '5vh' }}>
        {renderEmptyBindButton()}
      </div>
    </div>
  );

  const renderModalAdd = () => (
    <Modal
      okButtonProps={{ hidden: true }}
      onCancel={() => setState({ ...state, modalAddVisibility: false })}
      open={state.modalAddVisibility}
      title={<div style={{ textAlign: 'left' }}>
        <Title level={2}>Add {platformKey === 'marketplace' ? 'Marketplace' : 'Website'} Account</Title>
      </div>}
    >
      <BindingForms
        account={{}}
        command="add"
        onAdd={(values) => {
          localStorage.setItem(platformKey === 'marketplace' ? 'TEMP_NEW_MARKETPLACE' : 'TEMP_NEW_WEBSITE', JSON.stringify(values));
          onBindAccount();
          setState({ ...state, modalAddVisibility: false });
        }}
        platformKey={platformKey}
      />
    </Modal>
  );

  return(<>
    <>{Array.isArray(accountList) && accountList.length > 0 ? renderFilledList() : renderEmptyList()}</>
    <>{renderModalAdd()}</>
  </>
  );
};

AccountList.displayName = config.displayName;
AccountList.propTypes = config.propTypes;
AccountList.defaultProps = config.defaultProps;

export default AccountList;
