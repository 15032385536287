import { cur, flt, int, pct } from './__config';
export default {
  conversion: {
    add_to_cart: { key: 'add_to_cart', lbl: 'Add to Cart', fmt: int, adv: 0 },
    add_to_cart_value: { key: 'add_to_cart_value', lbl: 'Add to Cart Value', fmt: cur, adv: 0 },
    avg_basket_price: { key: 'avg_basket_price', lbl: 'Avg. Basket Price', fmt: cur, adv: 0 },
    clicks: { key: 'clicks', lbl: 'Clicks (All)', fmt: int, adv: 0 },
    clicks_all: { key: 'clicks_all', lbl: 'Clicks (All)', fmt: int, adv: 0 },
    comment: { key: 'comment', lbl: 'Post Comment', fmt: int, adv: 0 },
    conversion_rate: { key: 'conversion_rate', lbl: 'Conversion Rate', fmt: pct, adv: 0 },
    cost_per_add_to_cart: { key: 'cost_per_add_to_cart', lbl: 'Cost per Add to Cart', fmt: cur, adv: 1 },
    cost_per_landing_page_view: { key: 'cost_per_landing_page_view', lbl: 'Cost per Landing Page View', fmt: cur, adv: 1 },
    cost_per_link_click: { key: 'cost_per_link_click', lbl: 'CPC (Link Click)', fmt: cur, adv: 1 },
    cost_per_lead: { key: 'cost_per_lead', lbl: 'Cost/Lead', fmt: cur, adv: 1 },
    cost_per_messaging_conv_started: { key: 'cost_per_messaging_conv_started', lbl: 'Cost per Msg. Conversation Started', fmt: cur, adv: 1 },
    cost_per_purchase: { key: 'cost_per_purchase', lbl: 'Cost per Purchase', fmt: cur, adv: 1 },
    cpc: { key: 'cpc', lbl: 'CPC', fmt: cur, adv: 1 },
    cpc_all: { key: 'cpc_all', lbl: 'CPC', fmt: cur, adv: 1 },
    cpc_link_click: { key: 'cpc_link_click', lbl: 'CPC (Link Click)', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    ctr: { key: 'ctr', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    ctr_all: { key: 'ctr_all', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    ctr_rate: { key: 'ctr_rate', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    frequency: { key: 'frequency', lbl: 'Frequency', fmt: flt, adv: 0 },
    funnel_landing_page_view_to_add_to_cart: { key: 'funnel_landing_page_view_to_add_to_cart', lbl: 'Landing Page View > Add to Cart', fmt: pct, adv: 0 },
    funnel_add_to_cart_to_initiate_checkout: { key: 'funnel_add_to_cart_to_initiate_checkout', lbl: 'Add to Cart > Initiate Checkout', fmt: pct, adv: 0 },
    funnel_initiate_checkout_to_purchase: { key: 'funnel_initiate_checkout_to_purchase', lbl: 'Initiate Checkout > Purchase', fmt: pct, adv: 0 },
    funnel_add_to_cart_to_purchase: { key: 'funnel_add_to_cart_to_purchase', lbl: 'Add to Cart > Purchase', fmt: pct, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    initiate_checkout: { key: 'initiate_checkout', lbl: 'Initiate Checkout', fmt: int, adv: 0 },
    landing_page_view: { key: 'landing_page_view', lbl: 'Landing Page View', fmt: int, adv: 0 },
    lead: { key: 'lead', lbl: 'Leads', fmt: int, adv: 0 },
    link_click: { key: 'link_click', lbl: 'Link Clicks', fmt: int, adv: 0 },
    messaging_conv_started: { key: 'messaging_conv_started', lbl: 'Msg. Conversation Started', fmt: int, adv: 0 },
    post: { key: 'post', lbl: 'Post Shares', fmt: int, adv: 0 },
    post_comment: { key: 'post_comment', lbl: 'Post Comment', fmt: int, adv: 0 },
    post_engagement: { key: 'post_engagement', lbl: 'Post Engagement', fmt: int, adv: 0 },
    post_reaction: { key: 'post_reaction', lbl: 'Post Reaction', fmt: int, adv: 0 },
    post_share: { key: 'post_share', lbl: 'Post Shares', fmt: int, adv: 0 },
    post_saves: { key: 'post_saves', lbl: 'Post Save', fmt: int, adv: 0 },
    purchase: { key: 'purchase', lbl: 'Purchase', fmt: int, adv: 0 },
    purchase_value: { key: 'purchase_value', lbl: 'Purchase Value', fmt: cur, adv: 0 },
    reach: { key: 'reach', lbl: 'Reach', fmt: int, adv: 0 },
    roas: { key: 'roas', lbl: 'ROAS', fmt: flt, adv: 0 },
    spend: { key: 'spend', lbl: 'Spend', fmt: cur, adv: 0 },
    thruplays: { key: 'thruplays', lbl: 'ThruPlay', fmt: int, adv: 0 },
    video_avg_time_watched_actions: { key: 'video_avg_time_watched_actions', lbl: 'Video Avg. Play Time', fmt: flt, adv: 0 },
    video_play: { key: 'video_play', lbl: 'Video Plays', fmt: int, adv: 0 },
    video_play_100: { key: 'video_play_100', lbl: 'Video Play at 100%', fmt: int, adv: 0},
    website_ctr: { key: 'website_ctr', lbl: 'CTR (%)', fmt: pct, adv: 0 }
  },
  collaborative: {
    add_to_cart: { key: 'add_to_cart', lbl: 'Add to Cart', fmt: int, adv: 0 },
    add_to_cart_shared_item: { key: 'add_to_cart_shared_item', lbl: 'Add to Cart Shared Items', fmt: int, adv: 0 },
    add_to_cart_value_shared_item: { key: 'add_to_cart_value_shared_item', lbl: 'Add to Cart Value Shared Items', fmt: cur, adv: 0 },
    clicks_all: { key: 'clicks_all', lbl: 'Clicks (All)', fmt: int, adv: 0 },
    content_view: { key: 'content_view', lbl: 'Content View', fmt: int, adv: 0 },
    content_view_shared_item: { key: 'content_view_shared_item', lbl: 'Content View Shared Items', fmt: int, adv: 0 },
    cost_per_add_to_cart_shared_item: { key: 'cost_per_add_to_cart_shared_item', lbl: 'Cost per Add to Cart Shared Items', fmt: cur, adv: 1 },
    cost_per_content_view_shared_item: { key: 'cost_per_content_view_shared_item', lbl: 'Cost per Content View Shared Items', fmt: cur, adv: 0 },
    cost_per_purchase_shared_item: { key: 'cost_per_purchase_shared_item', lbl: 'Cost per Purchase Shared Items', fmt: cur, adv: 0 },
    cpc_all: { key: 'cpc_all', lbl: 'CPC', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    ctr_all: { key: 'ctr_all', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    funnel_content_view_to_add_to_cart_shared_item: { key: 'funnel_content_view_to_add_to_cart_shared_item', lbl: 'Content View > Add to Cart (Shared Items)', fmt: pct, adv: 0 },
    funnel_add_to_cart_to_purchase_shared_item: { key: 'funnel_add_to_cart_to_purchase_shared_item', lbl: 'Add to Cart > Purchase (Shared Items)', fmt: pct, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    link_click: { key: 'link_click', lbl: 'Link Clicks', fmt: int, adv: 0 },
    purchase: { key: 'purchase', lbl: 'Purchase', fmt: int, adv: 0 },
    purchase_shared_item: { key: 'purchase_shared_item', lbl: 'Purchase Shared Items', fmt: int, adv: 0 },
    purchase_value_shared_item: { key: 'purchase_value_shared_item', lbl: 'Purchase Value Shared Items', fmt: cur, adv: 0 },
    reach: { key: 'reach', lbl: 'Reach', fmt: int, adv: 0 },
    roas_shared_item: { key: 'roas_shared_item', lbl: 'ROAS Shared Items', fmt: flt, adv: 0 },
    spend: { key: 'spend', lbl: 'Spend', fmt: cur, adv: 0 }
  },
  lead: {
    clicks_all: { key: 'clicks_all', lbl: 'Clicks (All)', fmt: int, adv: 0 },
    cost_per_estimated_recall_people: { key: 'cost_per_estimated_recall_people', lbl: 'Cost per Estimated Ad Recall Lift (people)', fmt: cur, adv: 1 },
    cost_per_landing_page_view: { key: 'cost_per_landing_page_view', lbl: 'Cost per Landing Page View', fmt: cur, adv: 1 },
    cost_per_lead: { key: 'cost_per_lead', lbl: 'Cost per Lead', fmt: cur, adv: 1 },
    cost_per_unique_link_click: { key: 'cost_per_unique_link_click', lbl: 'Cost per Unique Link Click', fmt: cur, adv: 1 },
    cost_per_outbound_click: { key: 'cost_per_outbound_click', lbl: 'Cost per Outbound Click', fmt: cur, adv: 1 },
    cost_per_1000_people_reach: { key: 'cost_per_1000_people_reach', lbl: 'Cost per 1000 People Reach', fmt: cur, adv: 1 },
    cpc_all: { key: 'cpc_all', lbl: 'CPC', fmt: cur, adv: 1 },
    cpc_link_click: { key: 'cpc_link_click', lbl: 'CPC Link Click', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    ctr_all: { key: 'ctr_all', lbl: 'CTR (All)', fmt: pct, adv: 0 },
    ctr_rate: { key: 'ctr_rate', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    estimated_ad_recallers: { key: 'estimated_ad_recallers', lbl: 'Estimated Ad Recall Lift (people)', fmt: int, adv: 0 },
    estimated_recall_lift_rate: { key: 'estimated_recall_lift_rate', lbl: 'Estimated Recall Rate', fmt: pct, adv: 0 },
    frequency: { key: 'frequency', lbl: 'Frequency', fmt: flt, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    landing_page_view: { key: 'landing_page_view', lbl: 'Landing Page View', fmt: int, adv: 0 },
    lead: { key: 'lead', lbl: 'Leads', fmt: int, adv: 0 },
    link_click: { key: 'link_click', lbl: 'Link Clicks', fmt: int, adv: 0 },
    outbound_click: { key: 'outbound_click', lbl: 'Outbound Click', fmt: int, adv: 0 },
    outbound_ctr: { key: 'outbound_ctr', lbl: 'Outbound CTR (%)', fmt: pct, adv: 0 },
    post_comment: { key: 'post_comment', lbl: 'Post Comment', fmt: int, adv: 0 },
    post_engagement: { key: 'post_engagement', lbl: 'Post Engagement', fmt: int, adv: 0 },
    post_reaction: { key: 'post_reaction', lbl: 'Post Reaction', fmt: int, adv: 0 },
    post_saves: { key: 'post_saves', lbl: 'Post Save', fmt: int, adv: 0 },
    photo_views: { key: 'photo_views', lbl: 'Photo View', fmt: int, adv: 0 },
    reach: { key: 'reach', lbl: 'Reach', fmt: int, adv: 0 },
    spend: { key: 'spend', lbl: 'Spend', fmt: cur, adv: 0 },
    thruplays: { key: 'thruplays', lbl: 'ThruPlay', fmt: int, adv: 0 },
    unique_ctr: { key: 'unique_ctr', lbl: 'Unique CTR (%)', fmt: pct, adv: 0 },
    unique_link_click: { key: 'unique_link_click', lbl: 'Unique Link Click', fmt: int, adv: 0 },
    unique_outbound_click: { key: 'unique_outbound_click', lbl: 'Unique Outbound Click', fmt: int, adv: 0 },
    unique_outbound_ctr: { key: 'unique_outbound_ctr', lbl: 'Unique Outbound CTR (%)', fmt: pct, adv: 0 },
    video_avg_time_watched_actions: { key: 'video_avg_time_watched_actions', lbl: 'Video Avg. Play Time', fmt: flt, adv: 0 },
    video_play: { key: 'video_play', lbl: 'Video Plays', fmt: int, adv: 0 },
    video_play_100: { key: 'video_play_100', lbl: 'Video Play at 100%', fmt: int, adv: 0},
  },
  messages: {
    clicks_all: { key: 'clicks_all', lbl: 'Clicks (All)', fmt: int, adv: 0 },
    cost_per_messaging_new: { key: 'cost_per_messaging_new', lbl: 'Cost per New Msg. Connection', fmt: cur, adv: 1 },
    cost_per_messaging_conv_started: { key: 'cost_per_messaging_conv_started', lbl: 'Cost per Messaging Convo. Started', fmt: cur, adv: 1 },
    cpc_all: { key: 'cpc_all', lbl: 'CPC', fmt: cur, adv: 1 },
    cpm: { key: 'cpm', lbl: 'CPM', fmt: cur, adv: 1 },
    ctr_all: { key: 'ctr_all', lbl: 'CTR (%)', fmt: pct, adv: 0 },
    frequency: { key: 'frequency', lbl: 'Frequency', fmt: flt, adv: 0 },
    impressions: { key: 'impressions', lbl: 'Impressions', fmt: int, adv: 0 },
    link_click: { key: 'link_click', lbl: 'Link Clicks', fmt: int, adv: 0 },
    messaging_new: { key: 'messaging_new', lbl: 'New Msg. Connection', fmt: int, adv: 0 },
    messaging_conv_started: { key: 'messaging_conv_started', lbl: 'Msg. Conversation Started', fmt: int, adv: 0 },
    reach: { key: 'reach', lbl: 'Reach', fmt: int, adv: 0 },
    spend: { key: 'spend', lbl: 'Spend', fmt: cur, adv: 0 }
  }
};
