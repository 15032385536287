import React from 'react';
import {
  Button,
  Col,
  Row,
  Spin,
  Typography,
} from 'antd';

import { AccountSelectionGS, EmptyImage, PlatformAvatar } from '../../../components/atoms';

import stringConstants from '../../../constants-v1/string.constants';

import { formatCurrency, formatBig, formatPercent } from '../../../utils-v1/number.utils';
import { renderArrow, renderPercentText } from '../../../utils-v1/summary-page.utils';

import config from './GoogleAnalyticsPanel.component.config';
// import './GoogleAnalyticsPanel.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const GoogleAnalyticsPanel = ({
  accountSelectionProps,
  availableAccounts,
  history,
  loading,
  platformProps,
}) => {
  const { daterange } = platformProps;
  const { data } = platformProps?.data;
  const acct = Object.keys(data || {});
  const acctData = data?.[acct]?.data;
  const vsInterval = platformProps?.daterange?.preset_range_compare || '-';

  const renderEmptyContent = () => (
    <div className="empty-content googleAnalytics">
      {loading ? <Paragraph style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph> : <>
        <EmptyImage platform="googleAnalytics" width={150} />
        <br />
        <>{availableAccounts?.length === 0 ? <>
          <Text className="line-1">You have not bind your {stringConstants.googleAnalytics.name} accounts yet</Text>
          <br />
          <Text className="line-2">You have to bind your account first to fully access BDD.AI</Text>
        </> : null}</>
        <>{availableAccounts?.length > 0 && platformProps?.accounts?.length === 0 ? <>
          <Text className="line-1">You have not selected accounts yet</Text>
          <br />
          <Text className="line-2">Please select a {stringConstants.googleAnalytics.name} account</Text>
        </>: null}</>
        <br /><br />
        <Button type="primary" className="bind-button" onClick={() => history.push('binding-google-analytics')} hidden={availableAccounts?.length > 0}>
          Bind {stringConstants.googleAnalytics.name} Account
        </Button>
      </>}
    </div>
  );

  const renderFilledContent = () => (<>
    <Row className="filled-content googleAnalytics">
      <Col className="filled-col radius-top" sm={24} md={24} lg={24}>
        <Paragraph className='title-metric'>Total Visitor</Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBig(acctData?.current?.totalVisitor)}</Text>
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBig(acctData?.previous?.totalVisitor)}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(Number(acctData?.percentage?.totalVisitor))}
          {renderPercentText(Number(acctData?.percentage?.totalVisitor))}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
      <Col className="filled-col" sm={24} md={24} lg={24}>
        <Paragraph className='title-metric'>Page View</Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBig(acctData?.current?.pageViews)}</Text>
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBig(acctData?.previous?.pageViews)}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(Number(acctData?.percentage?.pageViews))}
          {renderPercentText(Number(acctData?.percentage?.pageViews))}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
      <Col className="filled-col radius-bottom" sm={24} md={24} lg={24}>
        <Paragraph>
          <Text className='title-metric'>Bounce Rate</Text>
        </Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBig(acctData?.current?.bounceRate, 'flt')}</Text>
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBig(acctData?.previous?.bounceRate, 'flt')}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(Number(acctData?.percentage?.bounceRate, true))}
          {renderPercentText(Number(acctData?.percentage?.bounceRate))}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
    </Row>
  </>);

  const renderEmptyCondition = availableAccounts?.length === 0 || platformProps?.accounts?.length === 0 || loading;
  return(
    <div id="googleAnalyticsPanel">
      <div className="platform-title-summary">
        <div className="platform-title">
          <PlatformAvatar platform="googleAnalytics" width={38} />
          <Text>Google Analytics</Text>
        </div>
        <div hidden={availableAccounts?.length === 0}>
          <AccountSelectionGS { ...accountSelectionProps } />
        </div>
      </div>
      <div>
        {renderEmptyCondition ? renderEmptyContent() : renderFilledContent()}
      </div>
    </div>
  )
}

GoogleAnalyticsPanel.displayName = config.displayName;
GoogleAnalyticsPanel.propTypes = config.propTypes;
GoogleAnalyticsPanel.defaultProps = config.defaultProps;

export default GoogleAnalyticsPanel;
