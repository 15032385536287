import TYPES from '../../types';

const initialState = {
  me: {
    data: { email: '', name: '', role: '', token: '', uid: '' },
    isNewUser: { needGuide: true, haveBinding: false },
    onData: {},
    onBoarding: {
      user_id: 0,
      onboarding_status: {
        haveFinished: 0,
      },
      coachmark_status: {
        summary: 0,
        platform: 0
      },
    },
    loading: false,
  },
  accounts_by_platform: {
    error: null,
    loading: false,
    googleAds: [],
    googleAnalytics: [],
    instagram: [],
    marketplace: [],
    meta: [],
    tiktok: [],
    website: [],
  },
  notifications: {
    googleAds: [],
    googleAnalytics: [],
    instagram: [],
    marketplace: [],
    meta: [],
    tiktok: [],
    website: [],
    budgetTracker: [],
    accountBinding: [],
  },
  coachmark_local: {
    summary: 0,
    platformGeneral: 0,
    platformFilterInner: 0,
    platformRangepickerInner: 0,
    platformTableFilterInner: 0,
    platformCopyBtn: 0,
    platformTiktokContentPreview: 0,
    platformGoogleAdsAccountSelection: 0,
    budgetTracker: 0,
  },
  synchronizingAccounts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case TYPES.SET_USER_DATA:
      return {
        ...state,
        me: {
          ...state.me,
          ...action.payload,
        },
      };
    case TYPES.SET_USER_BINDING_ACCOUNT:
      return {
        ...state,
        accounts_by_platform: {
          ...state.accounts_by_platform,
          ...action.payload,
        },
      };
    case TYPES.SET_USER_SYNC_ACCOUNT_DATA:
      return {
        ...state,
        synchronizingAccounts: [
          ...state.synchronizingAccounts.filter(el => action.payload.map(e => e?.config_id).includes(el?.config_id) === false),
          ...action.payload,
        ].filter(item => item?.sync_data?.loading === true),
      };
    case TYPES.SET_USER_INTRO_GUIDE:
      return {
        ...state,
        me: {
          ...state.me,
          isNewUser: {
            ...state.me.isNewUser,
            ...action.payload,
          },
        },
      };
    case TYPES.SET_USER_DATA_:
      return {
        ...state,
        me: {
          ...state.me,
          onData: {
            ...state.me.onData,
            ...action.payload,
          },
        },
      };
    case TYPES.SET_USER_ONBOARDING:
      return {
        ...state,
        me: {
          ...state.me,
          onBoarding: {
            ...state.me.onBoarding,
            ...action.payload,
          },
        },
      };
    case TYPES.SET_USER_ONBOARDING_BINDING:
      return {
        ...state,
        me: {
          ...state.me,
          onBoarding: {
            ...state.me.onBoarding,
            onboarding_status: {
              ...state.me.onBoarding.onboarding_status,
              ...action.payload,
            }
          },
        },
      };

    case TYPES.SET_USER_NOTIFICATIONS_GOOGLE_ADS: return {
      ...state,
      notifications: {
        ...state.notifications,
        googleAds: [ /* ...state.notifications.googleAds, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_NOTIFICATIONS_GOOGLE_ANALYTICS: return {
      ...state,
      notifications: {
        ...state.notifications,
        googleAnalytics: [ /* ...state.notifications.googleAnalytics, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_NOTIFICATIONS_INSTAGRAM: return {
      ...state,
      notifications: {
        ...state.notifications,
        instagram: [ /* ...state.notifications.instagram, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_NOTIFICATIONS_MARKETPLACE: return {
      ...state,
      notifications: {
        ...state.notifications,
        marketplace: [ /* ...state.notifications.marketplace, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_NOTIFICATIONS_META: return {
      ...state,
      notifications: {
        ...state.notifications,
        meta: [ /* ...state.notifications.meta, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_NOTIFICATIONS_TIKTOK: return {
      ...state,
      notifications: {
        ...state.notifications,
        tiktok: [ /* ...state.notifications.tiktok, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_NOTIFICATIONS_WEBSITE: return {
      ...state,
      notifications: {
        ...state.notifications,
        website: [ /* ...state.notifications.website, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_NOTIFICATIONS_BUDGET_TRACKER: return {
      ...state,
      notifications: {
        ...state.notifications,
        budgetTracker: [ /* ...state.notifications.budgetTracker, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_NOTIFICATIONS_ACCOUNT_BINDING: return {
      ...state,
      notifications: {
        ...state.notifications,
        accountBinding: [ /* ...state.notifications.accountBinding, */ ...action.payload],
      },
    };
    case TYPES.SET_USER_COACHMARK_LOCAL: return {
      ...state, coachmark_local: { ...state.coachmark_local, ...action.payload },
    };
    case TYPES.RESET_USER_REDUCER: return { ...initialState };
  }
};
