import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useDimension = (refElement) => {
  const [dimensions, setdDimensions] = React.useState({height: 0, width: 0});
  const [mdMinWidth, lgMinWidth] = [600, 1000];
  const resizeObserverRef = React.useRef(null);

  let tempSize;
  if (dimensions.width > lgMinWidth) tempSize = "large";
  if (dimensions.width > mdMinWidth && dimensions.width < lgMinWidth) tempSize = "medium";
  if (dimensions.width < mdMinWidth) tempSize = "small";

  const [size, setSize] = React.useState(tempSize);

  React.useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        setdDimensions({ width, height });

        if (width > lgMinWidth) tempSize = "large";
        if (width > mdMinWidth && width < lgMinWidth) tempSize = "medium";
        if (width < mdMinWidth) tempSize = "small";
        setSize(tempSize);
      });
    });
    if (refElement.current) resizeObserverRef.current.observe(refElement.current);

    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [refElement]);

  // console.log({ ...dimensions, size });
  return { ...dimensions, size };
};

export const useKeyDown = (callback, keys) => {
  const onKeyDown = (event) => {
    const wasAnyKeyPressed = keys.some((key) => event.key === key);
    if (wasAnyKeyPressed) {
      event.preventDefault();
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};

export const useKeyUp = (callback, keys) => {
  const onKeyUp = (event) => {
    const wasAnyKeyPressed = keys.some((key) => event.key === key);
    if (wasAnyKeyPressed) {
      event.preventDefault();
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);
};

export const useWindowSize = () => {
  const [dimensions, setDimensions] = React.useState({height: window.innerHeight, width: window.innerWidth});
  const [minDesktopWidth, minTabletWidth] = [1200, 800];

  let tempDeviceType;
  if (dimensions.width > minDesktopWidth) tempDeviceType = "desktop";
  if (dimensions.width > minTabletWidth && window.innerWidth <= minDesktopWidth) tempDeviceType = "tablet"; 
  if (dimensions.width <= minTabletWidth) tempDeviceType = "mobile";

  const [deviceType, setDeviceType] = React.useState(tempDeviceType);

  React.useEffect(() => {
    const handleResize = () => {
      const [ height, width ] = [window.innerHeight, window.innerWidth];
      setDimensions({height, width});

      if (width > minDesktopWidth) tempDeviceType = "desktop";
      if (width > minTabletWidth && width <= minDesktopWidth) tempDeviceType = "tablet";
      if (width <= minTabletWidth) tempDeviceType = "mobile";

      setDeviceType(tempDeviceType)
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  // console.log([...size, type]);
  return { ...dimensions, deviceType };
};