import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  account: { ...globalStateConstants.account.website },
  daterange: { ...globalStateConstants.daterange },
  filter: { ...globalStateConstants.filter.website },
  latest_sync: 0,

  data: {
    website: {
      overview_stats: { data: {}, error: null, loading: false },
      paid_order_distribution_list: { data: {}, error: null, loading: false },
      paid_order_distribution_map: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
    },
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_DASHBOARD_WEBSITE_VISITED: return {
      ...state, visited: true,
    };

    case TYPES.SET_DASHBOARD_WEBSITE_ACCOUNT: return {
      ...state, account: { /* ...state.account, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_WEBSITE_DATERANGE: return {
      ...state, daterange: { /* ...state.daterange, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_WEBSITE_FILTER: return {
      ...state, filter: { /* ...state.filter, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_WEBSITE_LATEST_SYNC: return {
      ...state, latest_sync: action.payload || 0,
    };

      // WEBSITE GROUP

    case TYPES.SET_DASHBOARD_WEBSITE_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        website: {
          ...state.data.website,
          overview_stats: { /* ...state.data.website.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_WEBSITE_PAID_ORDER_DISTRIBUTION_LIST: return {
      ...state,
      data: {
        ...state.data,
        website: {
          ...state.data.website,
          paid_order_distribution_list: { /* ...state.data.website.paid_order_distribution_list, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_WEBSITE_PAID_ORDER_DISTRIBUTION_MAP: return {
      ...state,
      data: {
        ...state.data,
        website: {
          ...state.data.website,
          paid_order_distribution_map: { /* ...state.data.website.paid_order_distribution_map, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_WEBSITE_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        website: {
          ...state.data.website,
          charts: { /* ...state.data.website.charts, */ ...action.payload },
        },
      },
    };

      // RESET REDUCER

    case TYPES.RESET_DASHBOARD_WEBSITE_DATA: return {
      ...state, data: initialState.data,
    };

    case TYPES.RESET_DASHBOARD_WEBSITE_REDUCER: return {
      ...initialState,
    };
  };
};