import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import CarouselContentPreview from '../../../components/CarouselContentPreview/CarouselContentPreview.component';
import TYPES from '../../../redux-v1/types';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { ICON_IMAGE, ICON_PIESLICE, ICON_WALLET } from '../../../assets-v1/figma-icons';
import dateConstants from '../../../constants-v1/date.constants';
import metrics from '../../../constants-v1/metrics-config/instagram';

import parentConfig from '../DashboardInstagram.component.config';
import config from './InstagramPage.component.config';
import './InstagramPage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { DAYS } = dateConstants;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_INSTAGRAM_OVERVIEW_STATS);
const contentPreviewMetrics = {
  engagements: [
    metrics.instagram.like,
    metrics.instagram.comments,
    metrics.instagram.engagement,
    metrics.instagram.saved,
    metrics.instagram.video_views,
  ],
  reach: [
    metrics.instagram.likes,
    metrics.instagram.comments,
    metrics.instagram.reach,
    metrics.instagram.impressions,
  ],
  // stories: [
  //   metrics.instagram.reach,
  //   metrics.instagram.impressions,
  // ],
};
const performanceChartConfig = [
  {
    key: 'chart_line_profile_views',
    pair: [
      [metrics.instagram.profile_views, 'Profile Views', '#51ab72', '#f5bd00'],
    ],
    title: 'Profile Views',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    key: 'chart_line_like_comment_saved',
    pair: [
      [metrics.instagram.like, 'LPV', '#51ab72', '#90d2a8'],
      [metrics.instagram.comments, 'Comments', '#f5bd00', '#f8d354'],
      [metrics.instagram.saved, 'Saved'],
    ],
    title: 'Like, Comments & Saved',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    key: 'chart_line_reach_impressions',
    pair: [
      [metrics.instagram.reach, 'Reach', '#51ab72', '#90d2a8'],
      [metrics.instagram.impressions, 'Impressions', '#f5bd00', '#f8d354'],
    ],
    title: 'Reach & Impressions',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    key: 'chart_line_reach_by_post_media',
    pair: [
      [metrics.instagram.image, 'Image', '#51ab72', '#90d2a8'],
      [metrics.instagram.carousel_album, 'Carousel Album', '#f5bd00', '#f8d354'],
      [metrics.instagram.video, 'Video', '#0182e5', '#34a5fd'],
    ],
    title: 'Reach by Post Media',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
];

const InstagramPage = (props) => {
  const getValueFormatter = getNumberFormatterByFormatCode;

  const statsListProps = {
    left: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      // collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        // adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>Account Overview</>,
        // values: {
        //   current: props?.data?.instagram?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        //   previous: props?.data?.instagram?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        //   percentage: props?.data?.instagram?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        // },
        // valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt),
      },
      loading: props?.data?.instagram?.overview_stats?.loading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(0, 5)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.instagram?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.instagram?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.instagram?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
    right: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      // collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        // adverse: overviewConfig.reqparams.metricsConfig[9]?.adv,
        oneLiner: true,
        title: <>Overview Activity</>,
        // values: {
        //   current: props?.data?.instagram?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[10]?.key],
        //   previous: props?.data?.instagram?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[10]?.key],
        //   percentage: props?.data?.instagram?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[10]?.key],
        // },
        // valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[9]?.fmt),
      },
      loading: props?.data?.instagram?.overview_stats?.loading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(5)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.instagram?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.instagram?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.instagram?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  }
  const formatChartTitle = (title='') => <>{title}</>
  const formatChartData = (chartKey='', keyLabelPairs=[]) => {
    const output = [];
    if (chartKey.includes('reach_by_post_media')) {
      output.push({
        entries: (props?.data?.instagram?.[chartKey]?.data?.current?.value || [])
          ?.map(e => ([metrics.instagram?.[e?.[0]]?.lbl, e?.[1]])),
        label: 'Value',
        color: '#51ab72'
      });
      output.push({
        entries: (props?.data?.instagram?.[chartKey]?.data?.current?.average || [])
          ?.map(e => ([metrics.instagram?.[e?.[0]]?.lbl, e?.[1]])),
        label: 'Average',
        color: '#f5bd00'
      });
    } else {
      for (let i=0; i<keyLabelPairs.length; i+=1) {
        const keyLabelPair = keyLabelPairs[i];
        output.push({
          entries: props?.data?.instagram?.[chartKey]?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1], color: keyLabelPair[2]
        });
        if (props?.daterange?.use_compare && !chartKey.includes('reach_by_post_media')) {
          output.push({
            entries: props?.data?.instagram?.[chartKey]?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare`, color: keyLabelPair[3]
          });
        };
      };
    }
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    data: formatChartData(e.key, e.pair),
    title: formatChartTitle(e.title),
    loading: props?.data?.instagram?.[e.key]?.loading,
    valueFormatter: e.valueFormatter,
  }))
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => ({ key: `${i}`, label: e.title, children: i !== 3 ? <Chart.Line { ...e } /> : <Chart.Bar { ...e } /> })),
  };

  const chartLineGrowthProps = {
    ...sharedPerformanceChartProps,
    data: formatChartData(
      'chart_line_growth',
      [[metrics.instagram.total_followers, 'Total Followers', '#51ab72', '#f5bd00']]
    ),
    title: 'Growth',
    loading: props?.data?.instagram?.chart_line_growth?.loading,
  };
  const chartHeatmapFollowersOnlineProps = {
    ...sharedPerformanceChartProps,
    advancedConfig: { showValuesInChart: false, maxColor: '#51ab72', minColor: '#fff' },
    data: (props?.data?.instagram?.chart_heatmap_followers_online?.data || [])
      ?.map(e => ([e?.[0], DAYS.SHORT?.[e[1]], e?.[2]])),
    title: 'Followers Online',
    loading: props?.data?.instagram?.chart_heatmap_followers_online?.loading,
  };
  const chartBarTopCitiesProps = {
    ...sharedPerformanceChartProps,
    advancedConfig: { ...sharedPerformanceChartProps.advancedConfig, withAltDisplay: true },
    actions: { ...sharedPerformanceChartProps.actions, expand: { enabled: false } },
    data: {
      map: Object.entries(props?.data?.instagram?.chart_bar_top_cities?.data?.maps || {}),
      // bar: [{
      //   entries: Object.entries(props?.data?.instagram?.chart_bar_top_cities?.data?.list || {}),
      //   label: 'Top Cities',
      //   color: '#51ab72',
      // }],
      table: Object.entries(props?.data?.instagram?.chart_bar_top_cities?.data?.list || {})
        .sort((a, b) => b[1] - a[1]).slice(0, 10),
      collapse: { enabled: false },
    },
    title: 'Top Cities',
    loading: props?.data?.instagram?.chart_bar_top_cities?.loading,
  };
  const chartMapTopCountriesProps = {
    ...sharedPerformanceChartProps,
    advancedConfig: { ...sharedPerformanceChartProps.advancedConfig, withAltDisplay: true },
    actions: { ...sharedPerformanceChartProps.actions, expand: { enabled: false } },
    data: {
      map: Object.entries(props?.data?.instagram?.chart_map_top_countries?.data?.maps || {}),
      table: Object.entries(props?.data?.instagram?.chart_map_top_countries?.data?.list || {})
        .sort((a, b) => b[1] - a[1]).slice(0, 10),
      collapse: { enabled: false },
    },
    mapType: 'world',
    title: 'Top Countries',
    loading: props?.data?.instagram?.chart_map_top_countries?.loading,
  };
  const topRegionsTabsProps = {
    defaultActiveKey: '0',
    items: [
      { key: '0', label: 'Top Cities', children: <Chart.Map { ...chartBarTopCitiesProps } />},
      { key: '1', label: 'Top Countries', children: <Chart.Map { ...chartMapTopCountriesProps } />},
    ],
  };
  const chartBarAgeGenderProps = {
    ...sharedPerformanceChartProps,
    advancedConfig: { ...sharedPerformanceChartProps.advancedConfig, showValuesInChart: false, stacked: true },
    data: [
      { entries: Object.entries(props?.data?.instagram?.chart_bar_age_gender?.data?.female || {}), label: 'Female', color: '#51ab72' },
      { entries: Object.entries(props?.data?.instagram?.chart_bar_age_gender?.data?.male || {}), label: 'Male', color: '#f5bd00' },
      { entries: Object.entries(props?.data?.instagram?.chart_bar_age_gender?.data?.unknown || {}), label: 'Unknown', color: '#0182e5' }
    ],
    title: 'Age & Gender',
    loading: props?.data?.instagram?.chart_bar_age_gender?.loading,
  };

  const contentPreviewProps = {
    engagements: {
      data: (Array.isArray(props?.data?.instagram?.preview_engagements?.data) && props?.data?.instagram?.preview_engagements?.data || [])?.map((e, i) => ({
        src: { ...e?.previews },
        statsList: contentPreviewMetrics.engagements?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.instagram?.preview_engagements?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
        title: e?.name,
        type: e?.type,
      })),
      loading: props?.data?.instagram?.preview_engagements?.loading,
    },
    reach: {
      data: (Array.isArray(props?.data?.instagram?.preview_reach?.data) && props?.data?.instagram?.preview_reach?.data || [])?.map((e, i) => ({
        src: { ...e?.previews },
        statsList: contentPreviewMetrics.reach?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.instagram?.preview_reach?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
        title: e?.name,
        type: e?.type,
      })),
      loading: props?.data?.instagram?.preview_reach?.loading,
    },
    // stories: {
    //   data: (Array.isArray(props?.data?.instagram?.preview_stories?.data) && props?.data?.instagram?.preview_stories?.data || [])?.map((e, i) => ({
    //     src: { ...e?.previews },
    //     statsList: contentPreviewMetrics.stories?.map((item, idx) => ({
    //       adverse: item?.adv,
    //       title: <>{item?.lbl}</>,
    //       values: {
    //         current: props?.data?.instagram?.preview_stories?.data?.[i]?.[item.key],
    //       },
    //       valueFormatter: getValueFormatter(item?.fmt),
    //     })),
    //     title: e?.name,
    //   })),
    //   loading: props?.data?.instagram?.preview_stories_ads?.loading,
    // },
  };
  const contentPreviewTabsProps = {
    defaultActiveKey: 'purchase',
    items: [
      { key: 'engagements', label: 'Engagements', children: <CarouselContentPreview { ...contentPreviewProps.engagements } /> },
      { key: 'reach', label: 'Reach', children: <CarouselContentPreview { ...contentPreviewProps.reach } /> },
      // { key: 'stories', label: 'Stories', children: <CarouselContentPreview { ...contentPreviewProps.stories } /> },
    ],
  };

  return(
    <div id="InstagramInstagram">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>Instagram</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.left } />}
        </Col>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.right } />}
        </Col>
      </Row>
      <Paragraph level={3} style={{ margin: '15px 0', fontWeight: 550 }}>Followers Demography</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12} className="single-chart">
          <Chart.Line { ...chartLineGrowthProps } />
        </Col>
        <Col sm={24} md={24} lg={12} className="single-chart">
          <Chart.Heatmap { ...chartHeatmapFollowersOnlineProps } />
        </Col>
        <Col sm={24} md={24} lg={12} className="single-chart" id="TopRegionsSection">
          <Card style={{ borderRadius: 10, height: '100%' }}>
            <Tabs { ...topRegionsTabsProps } />
          </Card>
        </Col>
        <Col sm={24} md={24} lg={12} className="single-chart">
          <Chart.Bar { ...chartBarAgeGenderProps } />
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
      </Row>
      <Paragraph level={3} style={{ margin: '5px 0', fontWeight: 550 }}>Content Based On</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...contentPreviewTabsProps } />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

InstagramPage.displayName = config.displayName;
InstagramPage.propTypes = config.propTypes;
InstagramPage.defaultProps = config.defaultProps;

export default InstagramPage;
