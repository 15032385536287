import React from 'react';
import {
  Button,
  Card,
  Col,
  Drawer,
  Image,
  Input,
  List,
  Modal,
  notification,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import {
  CloseCircleFilled,
  SearchOutlined,
  UploadOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Buttons } from '../../components/atoms';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_META,
  AVATAR_TIKTOK,
  ICON_SWAP_VERTICAL,
  ICON_EDIT,
  ICON_ELLIPSIS,
  ICON_TRASH,
  ICON_TRASH_MODAL_DELETE,
  ICON_REPEAT,
} from '../../assets-v1/figma-icons';
import {
  EMPTY_BUDGET_IMAGE as EMPTY_CUSTOM_REPORT_IMAGE,
  CR_COVER_PLACEHOLDER_BLUE,
  CR_COVER_PLACEHOLDER_GREEN,
  CR_COVER_PLACEHOLDER_RED,
} from '../../assets-v1/images';
import { getLastEventTimeIndicator } from '../../utils-v1/time.utils';
import { uploadFileToS3Bucket } from '../../services/aws.service';

import { DashboardLayoutGS } from '../../components/layouts-backup';
import CreateReportConfigForm from './CreateReportConfigForm/CreateReportConfigForm.component';

import config from './CustomReport.component.config';
import './CustomReport.component.styles.less';
import { formatDateDisplay } from '../../utils-v1/daterange.utils';

const { Option } = Select;
const { Paragraph, Text, Title } = Typography;

class CustomReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deleteReportTarget: {},
      editReportConfigTarget: {},
      fileList: [],
      openModalDelete: false,
      searchText: '',
      showCreateReportConfigForm: false,
      showDrawer: false,
      sortBy: 'created_at',
      sortDirection: 'descending',
    };
  }

  componentDidMount() {
    console.log(`CustomReport is mounted...`);
    this.props.resetOpenedReport();
    this.props.getSavedReports();
    this.props.getMetrics();
  };

  componentWillUnmount() {
    console.log(`CustomReport is unmounted...`);
  };

  getUtilFuncs = () => ({
    onDeleteReport: (reportConfig={}) => {
      this.setState({ deleteReportTarget: {}, openModalDelete: false });
      this.props.deleteReport(reportConfig?.id)
      // .then(() => {
      //   this.props.getSavedReports();
      // });
    },
    onEditReport: (reportConfig={}) => {
      notification.warn({ message: 'Opening your report. Please wait...' })
      const flattenedAccountList = [
        ...this.props.accountsByPlatform?.googleAds,
        ...this.props.accountsByPlatform?.meta,
        ...this.props.accountsByPlatform?.tiktok,
      ];
      const accounts = reportConfig.account_ids.map(actID => flattenedAccountList.find(act => act?.account_id === actID));
      if (accounts.includes(undefined) || accounts.includes(null)) {
        notification.warn({
          message: 'Missing Account',
          description: 'One or more accounts within this report are not in your bindings. Please re-bind these accounts first!',
        });
      } else {
        const platforms = ['googleAds', 'meta', 'tiktok'];
        const accountsGroupedByPlatform = {};
        for (let i=0; i<platforms.length; i+=1) {
          const platform = platforms[i];
          accountsGroupedByPlatform[platform] = accounts.filter(act => act?.platform === platform);
        };
        const formattedReportConfig = {
          ...reportConfig, accounts, accountsByPlatform: accountsGroupedByPlatform, document_type: 'existing',
        };
        this.props.setOpenedReport({ ...formattedReportConfig })
          .then((setOpenedReportRes) => {
            this.props.getConfigList(setOpenedReportRes.id)
              .then(() => {
                this.setState({ showCreateReportConfigForm: false, showDrawer: false });
                this.props.history.push(`custom-report/workspace/${setOpenedReportRes.id}`);
              });
          });
      }
    },
  });

  constructDashboardProps = () => ({
    accountSelectionProps: null,
    filterProps: null,
    history: this.props.history,
    location: this.props.location,
    rangePickerProps: null,
    syncDataProps: null,
    title: `Custom Report`,
    // onMouseEnter: () => console.log({ props: this.props, state: this.state }),
  });

  renderToolbar = () => (
    <div id="CustomReportToolbar">
      <Space align="center" direction="horizontal" size={16}>
        <Input
          placeholder="Search..."
          prefix={<SearchOutlined style={{ color: '#A6AAB1', marginLeft: 10 }} />}
          style={{ borderRadius: 6 }}
          onChange={(e) => this.setState({ searchText: e.target.value })}
        />
        <div style={{ backgroundColor: '#FAFBFC', border: '1px solid #D0D5DD', borderRadius: 6 }}>
          <Tooltip
            overlayInnerStyle={{ fontSize: '0.8rem' }}
            title={`Current sort direction is ${this.state.sortDirection === 'descending' ? 'DESCENDING' : 'ASCENDING'}. Click here to set sort direction to ${this.state.sortDirection === 'descending' ? 'ASCENDING' : 'DESCENDING'}`}
          >
            <Image
              src={ICON_SWAP_VERTICAL}
              alt="icon_swap_vertical"
              preview={false}
              style={{
                cursor: 'pointer',
                padding: '0 5px',
                transform: `scaleX(${this.state.sortDirection === 'descending' ? -1 : 1 })`,
              }}
              onClick={() => {
                const { sortDirection } = this.state;
                const newSortDirection = sortDirection === 'descending' ? 'ascending' : 'descending';
                this.setState({ sortDirection: newSortDirection });
              }}
            />
          </Tooltip>
          <Select
            className="sorter-selection"
            value={this.state.sortBy.replaceAll('_',' ')}
            onChange={(value) => {
              this.setState({ sortBy: value })
            }}
          >
            {['report_name', 'created_at'].map((value) => <Option key={value} value={value}>{value.replaceAll('_',' ')}</Option>)}
          </Select>
        </div>
      </Space>
      <div style={{ float: 'right' }}>
        <Button
          type="primary"
          style={{ border: 'none' }}
          onClick={() => this.setState({ showDrawer: true, showCreateReportConfigForm: true })}
        >+ Add New Report</Button>
      </div>
    </div>
  );

  renderEmptyCustomReportList = () => (
    <div id="EmptyCustomReportList">
      <Image src={EMPTY_CUSTOM_REPORT_IMAGE} alt="empty_custom_report_image" preview={false} />
      <Paragraph className="empty-title">No Report, Create Report Now!</Paragraph>
      <Paragraph>
        Craft a personalized report tailored to your needs.<br />
        Create your customized report now.
      </Paragraph>
      <Button
        id="CreateNewReportBtn"
        type="primary"
        onClick={() => this.setState({ showDrawer: true, showCreateReportConfigForm: true })}
      >+ Create Report</Button>
    </div>
  );

  getCardCoverImage = (cardObj={}) => {
    const coverImageProps = {
      style: { height: 200 },
      onClick: () => this.getUtilFuncs().onEditReport(cardObj),
    };
    let coverImage;
    if (cardObj?.cover_url) {
      const formattedCoverUrl = `${cardObj?.cover_url?.split('?X-Amz-Algorithm')?.[0]}`;
      const formattedCoverAlt = formattedCoverUrl.split('/').reverse()[0];
      coverImage = <Image src={formattedCoverUrl} alt={`cover_image_${formattedCoverAlt}`} preview={false} { ...coverImageProps } />
    } else {
      if (cardObj?.id % 3 === 0) {
        coverImage = <CR_COVER_PLACEHOLDER_GREEN { ...coverImageProps } />
      } else if (cardObj?.id % 2 === 0) {
        coverImage = <CR_COVER_PLACEHOLDER_BLUE { ...coverImageProps } />
      } else {
        coverImage = <CR_COVER_PLACEHOLDER_RED { ...coverImageProps } />
      };
    };
    return coverImage;
  };

  renderFilledCustomReportList = () => (
    <div id="FilledCustomReportList">
      <div hidden={!this.props.saved_reports.loading} className="loading-container">
        <Spin size="large" />
        <Paragraph style={{ color: '#9CA0A6', fontSize: 14 }}>Fetching saved reports</Paragraph>
      </div>
      <div hidden={this.props.saved_reports.loading} style={{ padding: 25 }}>
        <List
          grid={{ gutter: 32, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
          dataSource={this.props.saved_reports.data
            ?.sort((a,b) => {
              let out;
              if (this.state.sortBy === 'report_name') {
                out = a.report_name.toLowerCase() > b.report_name.toLowerCase() ? 1 : -1;
              };
              if (this.state.sortBy === 'created_at') {
                out = new Date(a.created_at) - new Date(b.created_at);
              };
              if (!this.state.sortBy) out = 1;
              return out * (this.state.sortDirection === 'descending' ? -1 : 1);
            })
            ?.filter(item => item?.report_name?.toLowerCase()?.includes(this.state.searchText.toLowerCase()))}
          pagination={{ pageSize: 8, position: 'top', style: { marginBottom: 20, marginTop: 0 } }}
          renderItem={(item) => <List.Item>
            <Card cover={this.getCardCoverImage(item)}>
              <Paragraph style={{ fontSize: 18, fontWeight: 700, height: 50 }}>{item?.report_name}</Paragraph>
              <Paragraph style={{ color: '#A6AAB1', fontSize: 14 }}>
                Created at: {item.created_at.split('T').map((el, idx) => idx === 0 ? formatDateDisplay(new Date(el)) : ` at ${el}`).join()}<br />
                <ICON_REPEAT style={{ transform: 'translateY(4px)' }} />&nbsp;&nbsp;&nbsp;
                Last updated: {getLastEventTimeIndicator(new Date(item?.updated_at) || 0)}
              </Paragraph>
              <Paragraph style={{ textAlign: 'right' }}>
                <Popover
                  trigger="click"
                  placement="topRight"
                  content={
                    <div style={{ fontSize: 14, padding: 10 }}>
                      <List
                        dataSource={item?.account_ids?.map(accountID => {
                          const flattenedAccountList = [
                            ...(this.props.accountsByPlatform?.googleAds || []),
                            ...(this.props.accountsByPlatform?.meta || []),
                            ...(this.props.accountsByPlatform?.tiktok || []),
                          ];
                          return (flattenedAccountList.find(act => act?.account_id === accountID) || {})
                        })}
                        renderItem={(jtem={}, jdx=0) => <List.Item style={{ paddingBottom: 5, paddingTop: 5 }} key={`${jdx}${jtem}`}>
                          <Space direction="vertical" size="small">
                            <Text style={{ fontWeight: 600, ...(!jtem?.account_name && { color: '#FF4D4F' }) }}>
                              {jtem?.platform ? <Image
                                src={(jtem?.platform === 'googleAds' && AVATAR_GOOGLE_ADS) || (jtem?.platform === 'meta' && AVATAR_META) || AVATAR_TIKTOK}
                                alt="platform_avatar"
                                width={30}
                              /> : null} {jtem?.account_name}
                            </Text>
                            <Text style={{ color: '#777777', fontSize: 12, marginLeft: 33 }}>{jtem?.account_id}</Text>
                          </Space>
                        </List.Item>}
                      />
                    </div>
                  }
                >
                  <UnorderedListOutlined style={{ transform: 'scale(1.5) translateY(2px)' }} />
                </Popover>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Popover
                  trigger="click"
                  placement="topRight"
                  onOpenChange={(open) => {
                    this.setState({ editReportConfigTarget: open ? { ...item } : {}, fileList: [] })
                  }}
                  open={this.state.editReportConfigTarget?.id === item?.id}
                  overlayClassName="edit-report-config-popover"
                  content={
                    <div style={{ fontSize: 14, padding: 20, width: 350 }}>
                      <Paragraph style={{ fontSize: 16, fontWeight: 700 }}>Update Report Config</Paragraph>
                      <Text>Report Name</Text><br />
                      {this.state.editReportConfigTarget?.report_name ? <Input
                        defaultValue={this.state.editReportConfigTarget?.report_name}
                        onChange={(e) => {
                          const newReportName = e.target.value;
                          this.setState((prevState) => {
                            const currentState = { ...prevState };
                            currentState.editReportConfigTarget.report_name = newReportName
                            return { ...currentState };
                          })
                        }}
                      /> : null}<br /><br />
                      <Text>Cover Image</Text><br />
                      <Upload.Dragger { ...this.constructUploadCoverImageProps() }>
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                      </Upload.Dragger><br />
                      <Paragraph style={{ textAlign: 'center' }}>
                        <Buttons.Apply
                          onClick={() => {
                            if (!this.state.editReportConfigTarget?.report_name) {
                              notification.error({ message: 'Report Name cannot be left empty!' });
                            } else {
                              this.props.updateExistingReportConfig(this.state.editReportConfigTarget)
                                .finally(() => this.setState({ editReportConfigTarget: {} }))
                            }
                          }}
                        />
                      </Paragraph>
                    </div>
                  }
                >
                  <Image src={ICON_EDIT} alt="icon_edit" preview={false} width={25} onClick={() => this.setState({ editReportConfigTarget: { ...item } })} />
                </Popover>
                &nbsp;&nbsp;&nbsp;
                <Image src={ICON_TRASH} alt="icon_delete" preview={false} width={25} onClick={() => this.setState({ deleteReportTarget: item, openModalDelete: true })} />
              </Paragraph>
            </Card>
          </List.Item>}
        />
      </div>
    </div>
  );

  constructUploadCoverImageProps = () => ({
    name: 'files',
    listType: 'picture',
    customRequest: async(upload) => uploadFileToS3Bucket(
      'cover_images',
      upload,
      (tempData={}, originKey='', objectURL='') => {
        this.setState((prevState) => {
          const currentState = { ...prevState };
          currentState.editReportConfigTarget.cover_url = objectURL;
          currentState.fileList = [{ ...tempData, uid: originKey, url: objectURL, status: 'done' }];
          return { ...currentState };
        })
      },
    ),
    fileList: this.state.fileList,
    maxCount: 1,
    onChange: (e) => {
      this.setState({ fileList: e?.fileList });
    },
  });

  constructCreateReportConfigFormProps = () => ({
    ...this.props,
    onCreateReport: (reqParams={}) => {
      this.props.setOpenedReport(reqParams)
        .then((res) => {
          this.props.history.push(`custom-report/workspace/${res?.id || 0}`)
          this.setState({ showCreateReportConfigForm: false, showDrawer: false })
        });
    },
  });

  constructModalDeleteProps = () => ({
    children: <div style={{ textAlign: 'center' }}>
      <div><Image src={ICON_TRASH_MODAL_DELETE} alt="icon_del" preview={false} width={100} /></div>
      <br />
      <div>Are you sure you want to <b>delete</b> this report?</div>
    </div>,
    title: <><span style={{ fontWeight: 700 }}>{this.state.deleteReportTarget?.report_name}</span></>,
    onCancel: () => {
      this.setState({ deleteReportTarget: {}, openModalDelete: false });
    },
    onOk: () => {
      this.getUtilFuncs().onDeleteReport(this.state.deleteReportTarget);
    },
    okButtonProps: { type: 'danger' },
    open: this.state.openModalDelete,
  });

  renderDrawerCreateReport = () => (
    <Drawer
      className="create-new-report-drawer"
      placement="bottom"
      title="Create New Report"
      closable
      closeIcon={null}
      open={this.state.showDrawer}
      onClose={() => {
        this.setState({ showCreateReportConfigForm: false, showDrawer: false });
      }}
      height="100%"
      extra={<Space>
        <CloseCircleFilled
          style={{ backgroundColor: '#000', borderRadius: '50%', cursor: 'pointer', color: '#D0D5DD', transform: 'scale(1.1)' }}
          onClick={() => {
            this.setState({ showCreateReportConfigForm: false, showDrawer: false });
          }}
        />
      </Space>}
    >
      {this.state.showDrawer ? <div id="CreateNewReportDrawerWrapper">
        {this.state.showCreateReportConfigForm ? <CreateReportConfigForm { ...this.constructCreateReportConfigFormProps() } /> : null}
      </div> : null}
    </Drawer>
  );

  render() {
    return (
      <DashboardLayoutGS { ...this.constructDashboardProps() }>
        <div id="CustomReport">
          {this.renderToolbar()}
          {(!this.props.saved_reports.loading && this.props.saved_reports.data.length === 0) ? this.renderEmptyCustomReportList() : this.renderFilledCustomReportList()}
          {this.renderDrawerCreateReport()}
          <div style={{ display: 'none' }}>{this.state.sortBy} :: {this.state.sortDirection}</div>
        </div>
        <Modal { ...this.constructModalDeleteProps() } />
      </DashboardLayoutGS>
    );
  };
};

CustomReport.displayName = config.displayName;
CustomReport.propTypes = config.propTypes;
CustomReport.defaultProps = config.defaultProps;

export default CustomReport;