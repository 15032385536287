import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import Table from '../../../components/Table/Table.component';
import CarouselContentPreview from '../../../components/CarouselContentPreview/CarouselContentPreview.component';
import TYPES from '../../../redux-v1/types';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { ICON_IMAGE, ICON_PIESLICE, ICON_WALLET } from '../../../assets-v1/figma-icons';
import metrics from '../../../constants-v1/metrics-config/meta';

import parentConfig from '../DashboardMeta.component.config';
import config from './LeadPage.component.config';
import './LeadPage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_META_LEAD_OVERVIEW_STATS);
const contentPreviewMetrics = {
  lead_ads: [
    metrics.lead.spend,
    metrics.lead.lead,
    metrics.lead.cost_per_lead,
  ],
};
const performanceChartConfig = [
  {
    pair: [
      [metrics.lead.lead, 'Lead'],
      [metrics.lead.clicks_all, 'Clicks'],
    ],
    title: 'Lead & Clicks',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    pair: [
      [metrics.lead.cost_per_lead, 'Cost per Lead'],
    ],
    title: 'Cost per Lead',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.lead.cpc_all, 'Cost per Click'],
    ],
    title: 'Cost per Click',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
];

const LeadPage = (props) => {
  const { currency } = props?.account?.details;
  const getValueFormatter = (fmt='') => getNumberFormatterByFormatCode(fmt, currency);

  const statsListProps = {
    left: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_WALLET} alt="icon_wallet" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[0].lbl}
        </>,
        values: {
          current: props?.data?.lead?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          previous: props?.data?.lead?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          percentage: props?.data?.lead?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt),
      },
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(1, 9)?.map((item, idx) => ({
        adverse: item.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.lead?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.lead?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.lead?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
      loading: props?.data?.lead?.overview_stats?.loading || props?.uiLoading,
    },
    right: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: false },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[9].adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_PIESLICE} alt="icon_pieslice" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[9].lbl}
        </>,
        values: {
          current: props?.data?.lead?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[9]?.key],
          previous: props?.data?.lead?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[9]?.key],
          percentage: props?.data?.lead?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[9]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[9]?.fmt),
      },
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(10, 13)?.map((item, idx) => ({
        adverse: item.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.lead?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.lead?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.lead?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
      loading: props?.data?.lead?.overview_stats?.loading || props?.uiLoading,
    },
    bottom: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: false },
      columns: 4,
      daterange: props?.daterange,
      headerStats: {
        title: <>
          <Image src={ICON_IMAGE} alt="icon_image" preview={false} />&nbsp;&nbsp;
          Post & Media
        </>,
      },
      loading: props?.data?.lead?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(13)?.map((item, idx) => ({
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.lead?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.lead?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.lead?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  }
  const chartState = props?.data?.lead?.charts;
  const formatChartTitle = (title='') => <><b>{title}</b></>
  const formatChartData = (keyLabelPairs=[]) => {
    const output = [];
    for (let i=0; i<keyLabelPairs.length; i+=1) {
      const keyLabelPair = keyLabelPairs[i];
      output.push({ entries: chartState?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1]});
      if (props?.daterange?.use_compare) {
        output.push({ entries: chartState?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare`});
      }
    };
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    data: formatChartData(performanceChartConfig[i].pair),
    title: formatChartTitle(performanceChartConfig[i].title),
    loading: chartState?.loading || props?.uiLoading,
    valueFormatter: performanceChartConfig[i].valueFormatter,
  }))
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => ({ key: `${i}`, label: performanceChartConfig[i].title, children: <Chart.Line { ...e } /> })),
  };

  const getTableColumnsAndData = (
    tableKey='',
    dispatchType='',
    firstCol={ key: 'campaign', title: 'Campaign' },
  ) => ({
    columns: [{ ...firstCol, width: '200px', fixed: 'left' }]
      .concat(requestConfig.find(e => e.type === dispatchType)
        .reqparams.metricsConfig.map(e => ({ key: e.key, title: e.lbl, width: '200px' }))),
    data: (Array.isArray(props?.data?.lead?.[tableKey]?.data) && props?.data?.lead?.[tableKey]?.data || [])
      .map(e => {
        const rowData = { ...e };
        const metricKeys = Object.keys(e);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const matchingMetricConfig = requestConfig.find(f => f.type === dispatchType)
            .reqparams.metricsConfig.find(f => f.key === metricKey);
          rowData[metricKey] = getValueFormatter(matchingMetricConfig?.fmt)(rowData[metricKey]);
        };
        return rowData;
      }),
    loading: props?.data?.lead?.[tableKey]?.loading || props?.uiLoading,
  });

  const tabbedTablesDataBasedOnProps = {
    allowSort: true,
    tables: [
      {
        title: 'Conversion',
        ...getTableColumnsAndData(
          'table_lead', TYPES.SET_DASHBOARD_META_LEAD_TABLE_CONVERSION, { key: 'campaign', title: 'Campaign' }
        ),
      },
      {
        title: 'Traffic',
        ...getTableColumnsAndData(
          'table_traffic', TYPES.SET_DASHBOARD_META_LEAD_TABLE_TRAFFIC, { key: 'campaign', title: 'Campaign' }
        ),
      },
      {
        title: 'Leads',
        ...getTableColumnsAndData(
          'table_leads', TYPES.SET_DASHBOARD_META_LEAD_TABLE_LEADS, { key: 'campaign', title: 'Campaign' }
        ),
      },
      {
        title: 'Awareness',
        ...getTableColumnsAndData(
          'table_awareness', TYPES.SET_DASHBOARD_META_LEAD_TABLE_BRAND_AWARENESS, { key: 'campaign', title: 'Campaign' }
        ),
      },
    ],
    title: <b>Data Based On</b>,
    titlePlacement: 'out',
  };

  const contentPreviewProps = {
    lead_ads: {
      data: props?.data?.lead?.preview_lead_ads?.data?.map((e, i) => ({
        src: { ...e?.previews },
        statsList: contentPreviewMetrics.lead_ads?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.lead?.preview_lead_ads?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
        title: e?.ad_name,
      })),
    },
  };
  const contentPreviewTabsProps = {
    defaultActiveKey: 'lead_ads',
    items: [
      { key: 'lead_ads', label: 'Lead Ads', children: <CarouselContentPreview { ...contentPreviewProps.lead_ads } /> },
    ],
  };

  return(
    <div id="MetaLead">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>Meta Leads Ads</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12}>
          <Stats.List { ...statsListProps.left } />
        </Col>
        <Col sm={24} md={24} lg={12}>
          <Stats.List { ...statsListProps.right } />
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Stats.List { ...statsListProps.bottom } />
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Table.Tabbed { ...tabbedTablesDataBasedOnProps } />
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...contentPreviewTabsProps } />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

LeadPage.displayName = config.displayName;
LeadPage.propTypes = config.propTypes;
LeadPage.defaultProps = config.defaultProps;

export default LeadPage;
