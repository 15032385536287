/* eslint-disable react/prop-types */
import React from 'react';
import { Avatar, Button, Card, Col, Collapse, Drawer, Row, Space, Spin, Typography } from 'antd';
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowUpOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Buttons, CopyrightText } from '../atoms';
import { bddColor } from '../../utils-v1/colors.utils';
import { coachmark } from '../../utils-v1/coachmark.utils';
import { formatDateDisplay } from '../../utils-v1/daterange.utils';
import { copyImage } from '../../utils-v1/exports.utils';
import { updateCoachmarkLocal } from '../../redux-v1/actions/user/__common.actions';

import config from './Stats.component.config';
import './Stats.component.styles.less';

const { Paragraph, Title, Text } = Typography;
const { GRAY } = bddColor;
const [DANGER, DANGERSOFT, SAFE, SAFESOFT] = ['#FF683A', '#FFF0EB', '#59BC7D', 'rgba(89, 188, 125, 0.15)'];
const templateProps = config.getTemplateProps();
const { statsItemProps, statsListProps } = templateProps;
const mapStateToProps = (state) => ({
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});
const mapDispatchToProps = {
  updateCoachmarkLocal,
}

const StatsItem = (props={ ...statsItemProps }) => {
  const adverse = props?.adverse || statsItemProps?.adverse;
  const current = props?.values?.current;
  const formatter = props?.valueFormatter || statsItemProps?.valueFormatter;
  const loading = props?.loading;
  const oneLiner = props?.oneLiner || statsItemProps?.oneLiner;
  const percentage = props?.values?.percentage;
  const previous = props?.values?.previous;
  const title = props?.title || statsItemProps?.title;
  const trendColor = adverse && (percentage > 0 ? DANGER : SAFE) || (percentage > 0 ? SAFE : DANGER);
  const TrendIcon = percentage > 0 ? ArrowUpOutlined : ArrowDownOutlined;
  const useCompare = props?.useCompare || statsItemProps?.useCompare;

  const constructCardProps = () => ({
    bordered: false,
    style: { marginBottom: 5 },
  });

  const compareContent = <Text style={{ color: trendColor }}>
    {typeof percentage === 'number' ? <TrendIcon color={trendColor} /> : ''}
    {' '}
    {typeof percentage === 'number' ? <span>{Math.abs(percentage).toLocaleString('en', { maximumFractionDigits: 2 })} %</span> : ''}
    {'   '}
    {typeof previous === 'number' ? <span style={{ color: GRAY, fontWeight: 500 }}>vs {formatter(previous)}</span> : ''}
  </Text>;

  return(<Card id="StatsItemWrapper" { ...constructCardProps() }>
    <div className="stats-item-title">
      <Text>{title}</Text>
      {props?.asHeader ? <Buttons.SeeDetail onClick={props?.onExpand}/> : null}
    </div>
    <div className="stats-item-values">
      <div>
        <Text style={{ visibility: !loading ? 'visible' : 'hidden' }} className="current-value">{formatter(current)}</Text>
        {'   '}
        <span hidden={!props?.asHeader} style={{ visibility: useCompare && !loading ? 'visible' : 'hidden', fontSize: 14, ...((!useCompare || loading) ) && { display: 'none' } }}>
          {compareContent}
        </span>
        <div hidden={props?.asHeader} style={{ visibility: useCompare && !loading ? 'visible' : 'hidden', fontSize: 14, ...((!useCompare || loading) ) && { display: 'none' } }}>
          {compareContent}
        </div>
      </div>
    </div>
  </Card>)
};

const StatsListTitle = ({
  adverse = false,
  daterange = {},
  title = '',
  useCompare = false,
  valueFormatter = (val) => val,
  values = { current: null, percentage: null, previous: null },
}) => {
  const trendColor = adverse && (values?.percentage > 0 ? DANGER : SAFE) || (values?.percentage > 0 ? SAFE : DANGER);
  const trendBackgroundColor = adverse && (values?.percentage > 0 ? DANGERSOFT : SAFESOFT) || (values?.percentage > 0 ? SAFESOFT : DANGERSOFT);
  const TrendIcon = values?.percentage > 0 ? ArrowUpOutlined : ArrowDownOutlined;
  const formatter = typeof valueFormatter === 'function' ? valueFormatter : (val) => val;

  const invisibleCondition = values?.current === null;

  return(<Space direction="vertical">
    <Space direction="horizontal">
      <div style={{ fontSize: '1.2rem', fontWeight: 550 }}>{title}</div>
      {daterange?.use_compare ? <div
        hidden={invisibleCondition}
        style={{
          backgroundColor: trendBackgroundColor,
          border: `1px solid ${trendColor}`,
          borderRadius: 30,
          color: trendColor,
          fontWeight: 550,
          minWidth: 75,
          padding: 5,
          textAlign: 'center',
        }}
      >
        {typeof values?.percentage === 'number'? <>
          <TrendIcon color={trendColor} />&nbsp;
          <>{Math.abs(values?.percentage).toLocaleString('en', { maximumFractionDigits: 2 })} %</>
        </> : ''}
      </div> : null}
    </Space>
    <Card id="StatsListTitle" hidden={invisibleCondition}>
      <Space direction="horizontal" className={useCompare ? `spc-compare` : `spc-nocompare`}>
        <div className="current-stats" style={useCompare ? {} : { textAlign: 'center' }}>
          <Text className="line-text">Current</Text>
          <br />
          <Text className="stats-value">{formatter(typeof values?.current === 'number' ? values?.current : '-')}</Text>
          <br />
          <Text className="stats-date-range">
            {(daterange?.date_start_current && daterange?.date_end_current) ?
              `${formatDateDisplay(daterange?.date_start_current)} - ${formatDateDisplay(daterange?.date_end_current)}`
              : 'Current Daterange'
            }
          </Text>
        </div>
        <div hidden={!useCompare}><Avatar size={40}>VS</Avatar></div>
        <div hidden={!useCompare} className="previous-stats">
          <Text className="line-text">Previous</Text>
          <br />
          <Text className="stats-value">{formatter(typeof values?.previous === 'number' ? values?.previous : '-')}</Text>
          <br />
          <Text className="stats-date-range">
            {(daterange?.date_start_compare && daterange?.date_end_compare) ?
              `${formatDateDisplay(daterange?.date_start_compare)} - ${formatDateDisplay(daterange?.date_end_compare)}`
              : 'Previous Daterange'
            }
          </Text>
        </div>
      </Space>
    </Card>
  </Space>);
};

const StatsList = (props={ ...statsListProps }) => {
  const allowCopy = props?.actions?.copy?.enabled || statsListProps?.actions?.copy?.enabled;
  const allowExpand = props?.actions?.expand?.enabled || statsListProps?.actions?.expand?.enabled;
  const collapsible = props?.collapse?.enabled || statsListProps?.collapse?.enabled;
  const columns = props?.columns || statsListProps?.columns;
  const daterange = props?.daterange || {};
  const headerStats = props?.headerStats || statsListProps?.headerStats;
  const loading = props?.loading || statsListProps?.loading;
  const sliceAt = props?.collapse?.sliceAt || statsListProps?.collapse?.sliceAt;
  const statsList = Array.isArray(props?.statsList) && props?.statsList || statsListProps?.statsList;
  const title = props?.title || statsListProps?.title;
  const useCompare = props?.useCompare || statsListProps?.useCompare;

  const defaultActiveKey = 'no-content';
  const [visibleStatsList, hiddenStatsList] = [statsList.slice(0, sliceAt), statsList.slice(sliceAt)];
  const [activeKey, setActiveKey] = React.useState(defaultActiveKey);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const ref = React.createRef(null);

  const actions = {
    copy: () => {
      copyImage({ document, navigator, ref, window });
    },
    expand: () => {
      setShowDrawer(true);
    },
  };
  const funcs = {
    getCollapseHeader: (key='') => {
      const _key = key;
      return(<div style={{ fontWeight: 500, textAlign: 'center' }}>
        Show {key !== 'with-content' ? 'Less' : 'More'}
        &nbsp;&nbsp;
        {key !== 'with-content' ? <UpOutlined /> : <DownOutlined />}
      </div>);
    },
    renderDrawerStatsList: () => (<>
      {(statsList.length > 0) ? <Row gutter={[16, 16]} style={{ textAlign: 'left' }} justify="center">
        {statsList.map((statsItem, index) => <Col
          key={`statsItem-${index+1}`}
          xs={24}
          md={8}
          xl={6}
        >
          <Stats.Item useCompare={useCompare} { ...statsItem } />
        </Col>)}
      </Row> : null}
    </>),
    renderHiddenStatsList: () => {
      const hiddenSlice = collapsible ? hiddenStatsList : [];
      return(<>
        {collapsible ? <Collapse { ...constructCollapseProps() }>
          <Collapse.Panel { ...constructCollapsePanelProps('with-content') }>
            {(hiddenSlice.length > 0) ? <Row gutter={[8, 8]}>
              {hiddenSlice.map((statsItem, index) => <Col key={`statsItem-${index+1}`} xs={24} md={12} xl={24/columns}>
                <Stats.Item useCompare={useCompare} { ...statsItem } />
              </Col>)}
            </Row> : null}
          </Collapse.Panel>
          <Collapse.Panel { ...constructCollapsePanelProps('no-content') } />
        </Collapse> : null}
      </>);
    },
    renderVisibleStatsList: () => {
      const visibleSlice = collapsible ? visibleStatsList : statsList;
      return(<>
        {(visibleSlice.length > 0) ? <Row gutter={[8, 8]}>
          {visibleSlice.map((statsItem, index) => <Col key={`statsItem-${index+1}`} xs={24} md={12} xl={24/columns}>
            <Stats.Item useCompare={useCompare} { ...statsItem } />
          </Col>)}
        </Row> : null}
      </>)
    },
  };

  const constructCardProps = (forDrawer=0) => ({
    bordered: false,
    headStyle: { marginBottom: 5 },
    ref,
    style: { marginBottom: 5, minHeight: forDrawer ? '50vh' : 0 },
    ...((title || headerStats) && {
      title: headerStats ? (
        forDrawer && <StatsListTitle useCompare={useCompare} daterange={daterange} { ...headerStats } />
        || <Stats.Item asHeader loading={loading} useCompare={useCompare} onExpand={actions.expand} { ...headerStats } />
      ) : title,
    }),
  });
  const constructCollapseProps = () => ({ accordion: true, defaultActiveKey, ghost: true, onChange: (key) => setActiveKey(key) });
  const constructCollapsePanelProps = (key) => ({ header: funcs.getCollapseHeader(key), key, showArrow: false });
  const constructDrawerProps = () => ({
    afterOpenChange: (open) => {
      if (open && props?.onBoarding?.coachmark_status?.platform === 0 && props?.coachmark_local?.platformCopyBtn === 0) {
        coachmark({
          delay: 1000,
          steps: [{
            key: 1,
            element: '#CopyToClipboardButton',
            title: 'Goodbye Screenshot!',
            intro: 'Now you don’t have to screenshot anymore. Click this to copy to your clipboard',
            position: 'top',
          }],
          oncomplete: () => props?.updateCoachmarkLocal({ ...props?.coachmark_local, platformCopyBtn: 1 }, props?.onBoarding),
        });
      };
    },
    className: 'stats-list-drawer',
    closable: true,
    closeIcon: <Button
      icon={<ArrowLeftOutlined />}
      id="closeBtn"
      size="medium"
      type="link"
    >Back</Button>,
    children: <div style={{ textAlign: 'center' }}>{showDrawer ? renderComponent(1) : null}</div>,
    height: '100%',
    onClose: () => setShowDrawer(false),
    open: showDrawer,
    placement: 'bottom',
    width: '100%',
  });

  const renderComponent = (forDrawer=0) => (<>
    <Card id="StatsListWrapper" { ...constructCardProps(forDrawer) } style={{ ...(forDrawer && { width: useCompare ? '70vw' : '55vw', left: useCompare ? '15vw' : '22vw' } || {}) }}>
      {loading && <Paragraph style={{ marginTOp: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph>}
      {!loading && (forDrawer ? funcs.renderDrawerStatsList() : <>{funcs.renderVisibleStatsList()} {funcs.renderHiddenStatsList()}</>)}
    </Card>
    {forDrawer && <div style={{ position: 'fixed', bottom: 0, width: '100%', textAlign: 'center', padding: '20px 0 10px 0', background: 'white' }}>
      <Buttons.CopyToClipboard onClick={actions.copy} />
      <CopyrightText style={{ marginTop: 20 }} />
    </div> || null}
  </>);

  return(<>
    {renderComponent(0)}
    <Drawer { ...constructDrawerProps() } />
  </>);
};

const Stats = { Item: StatsItem, List: connect(mapStateToProps, mapDispatchToProps)(StatsList) };
Stats.displayName = config.displayName;
Stats.propTypes = config.propTypes;
Stats.defaultProps = config.defaultProps;

export default Stats;
