import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';
import { BorderOutlined } from '@ant-design/icons';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import Table from '../../../components/Table/Table.component';
import TYPES from '../../../redux-v1/types';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { capsFirstLetter } from '../../../utils-v1/string.utils';
import metrics from '../../../constants-v1/metrics-config/googleAnalytics';

import parentConfig from '../DashboardGoogleAnalytics.component.config';
import config from './UaPage.component.config';
import './UaPage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_OVERVIEW_STATS);
const performanceChartConfig = [
  {
    key: 'chart_product_rate',
    pair: [
      [metrics.ua.buy_rate, metrics.ua.buy_rate.lbl, '#51ab72', '#90d2a8'],
      [metrics.ua.cart_rate, metrics.ua.cart_rate.lbl, '#f5bd00', '#f8d354'],
    ],
    title: 'Product Rate',
  },
  {
    key: 'chart_product_revenue',
    pair: [
      [metrics.ua.all_sessions, metrics.ua.all_sessions.lbl, '#51ab72', '#90d2a8'],
      [metrics.ua.product_views, metrics.ua.product_views.lbl, '#f5bd00', '#f8d354'],
      [metrics.ua.add_to_cart, metrics.ua.add_to_cart.lbl, '#0182e5', '#34a5fd'],
      [metrics.ua.check_out, metrics.ua.check_out.lbl, '#6200ee', '#9654f4'],
      [metrics.ua.transactions, metrics.ua.transactions.lbl, '#0182e5', '#34a5fd'],
    ],
    title: 'Product Revenue',
  },
];
const tablesConfig = [
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_PRODUCT_PERFORMANCE,
    keyTitlePair: { key: 'product_name', title: 'Product Name' },
    tableKey: 'table_product_performance',
    tableTitle: 'Product Performance',
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_SOURCE_MEDIUM,
    keyTitlePair: { key: 'source_medium', title: 'Source / Medium' },
    tableKey: 'table_source_medium',
    tableTitle: 'Source / Medium',
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_EXIT_PAGE,
    keyTitlePair: { key: 'page', title: 'Page' },
    tableKey: 'table_exit_page',
    tableTitle: 'Exit Page',
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_TOP_PAGE,
    keyTitlePair: { key: 'page', title: 'Page' },
    tableKey: 'table_top_page',
    tableTitle: 'Top Page',
  },
];
const chartDonutColors = [
  '#018FFC',
  '#55B4FD',
  '#59BC7D',
  '#90D2A8',
  '#F5BD00',
  '#F8D354',
  '#FF683A',
  '#FF9A7B',
  '#3C41CC',
  '#7C80DD',
];

const UaPage = (props) => {
  const getValueFormatter = getNumberFormatterByFormatCode;

  const statsListProps = {
    left: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        // adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          Overview Activity
        </>,
        // values: {
        //   current: props?.data?.ua?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        //   previous: props?.data?.ua?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        //   percentage: props?.data?.ua?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        // },
        // valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt),
      },
      loading: props?.data?.ua?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(0)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.ua?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.ua?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.ua?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  }
  const formatChartTitle = (title='') => <>{title}</>
  const formatChartData = (chartKey='', keyLabelPairs=[]) => {
    const output = [];
    if (chartKey.includes('product_revenue')) {
      output.push({
        entries: (props?.data?.ua?.[chartKey]?.data?.current?.revenue || [])
          ?.map(e => ([metrics.ua?.[e?.[0]]?.lbl, e?.[1]])),
        label: 'Revenue',
        color: '#51ab72'
      });
      if (props?.daterange?.use_compare) output.push({
        entries: (props?.data?.ua?.[chartKey]?.data?.previous?.revenue || [])
          ?.map(e => ([metrics.ua?.[e?.[0]]?.lbl, e?.[1]])),
        label: 'Revenue (Compared)',
        color: '#f5bd00'
      });
    } else {
      for (let i=0; i<keyLabelPairs.length; i+=1) {
        const keyLabelPair = keyLabelPairs[i];
        output.push({
          entries: props?.data?.ua?.[chartKey]?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1], color: keyLabelPair[2]
        });
        if (props?.daterange?.use_compare && !chartKey.includes('product_revenue')) {
          output.push({
            entries: props?.data?.ua?.[chartKey]?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare`, color: keyLabelPair[3]
          });
        };
      };
    }
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    advancedConfig: { showValuesInChart: false, stacked: true },
    data: formatChartData(e.key, e.pair),
    title: formatChartTitle(e.title),
    loading: props?.data?.ua?.[e.key]?.loading || props?.uiLoading,
  }))
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => ({ key: `${i}`, label: e.title, children: <Chart.Bar { ...e } /> })),
  };

  const chartDonutVisitorProps = {
    // ...sharedPerformanceChartProps,
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    daterange: { ...sharedPerformanceChartProps.daterange },
    advancedConfig: { showValuesInChart: false },
    centerContent: `<div style="text-align:center">
      ${[0, ...Object.values(props?.data?.ga4?.chart_donut_visitor?.data?.current || {})].reduce((s,e) => s+e) ?
        `<p style="color:#7D8085">Total</p>
        <br />
        <p style="font-size:1.2em;font-weight:600">
          ${getValueFormatter('int')([0, ...Object.values(props?.data?.ga4?.chart_donut_visitor?.data?.current || {})].reduce((s,e) => s+e))}
        </p>` : ``
      }
    </div>`,
    hover: { enabled: true },
    legend: { enabled: true },
    loading: props?.data?.ua?.overview_stats?.loading || props?.uiLoading,
    size: 300,
    tooltip: { enabled: true, shared: false },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    data: {
      donut: Object.keys(props?.data?.ga4?.chart_donut_visitor?.data?.current || {}).map((e, i) => ({
        label: capsFirstLetter(e),
        value: props?.data?.ga4?.chart_donut_visitor?.data?.current?.[e] || 0,
        color: chartDonutColors?.[i],
      })),
      table: props?.data?.ga4?.chart_donut_visitor?.data,
      total: {
        current: [0, ...Object.values(props?.data?.ga4?.chart_donut_visitor?.data?.current || {})].reduce((s,e) => s+e),
        previous: [0, ...Object.values(props?.data?.ga4?.chart_donut_visitor?.data?.previous || {})].reduce((s,e) => s+e),
      },
    },
    listTitle: 'Total User for Visitor',
    title: 'Visitor',
  };

  const chartBarShoppingBehaviourProps = {
    ...sharedPerformanceChartProps,
    advancedConfig: { ...sharedPerformanceChartProps, stacked: true },
    data: [
      {
        entries: Object.entries(props?.data?.ua?.shopping_behaviour?.data?.new_visitor || {})
          ?.map(e => [metrics.ua[e?.[0]].lbl, e?.[1]]),
        label: 'New Visitors',
      },
      {
        entries: Object.entries(props?.data?.ua?.shopping_behaviour?.data?.returning_visitor || {})
          ?.map(e => [metrics.ua[e?.[0]].lbl, e?.[1]]),
        label: 'Returning Visitors',
      },
    ],
    loading: props?.data?.ua?.shopping_behaviour?.loading || props?.uiLoading,
  };

  const getTableColumnsAndData = (
    tableKey='',
    dispatchType='',
    firstCol={ key: 'campaign', title: 'Campaign' },
  ) => ({
    columns: [{ ...firstCol, width: 40, fixed: 'left' }]
      .concat(requestConfig.find(e => e.type === dispatchType)
        .reqparams.metricsConfig.map(e => ({ key: e.key, title: e.lbl, width: 20 }))),
    data: (Array.isArray(props?.data?.ua?.[tableKey]?.data) && props?.data?.ua?.[tableKey]?.data || [])
      .map(e => {
        const rowData = { ...e };
        const metricKeys = Object.keys(e);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const matchingMetricConfig = requestConfig.find(f => f.type === dispatchType)
            .reqparams.metricsConfig.find(f => f.key === metricKey);
          rowData[metricKey] = getValueFormatter(matchingMetricConfig?.fmt)(rowData[metricKey]);
        };
        return rowData;
      }),
    loading: props?.data?.ua?.[tableKey]?.loading || props?.uiLoading,
  });
  const tabbedTablesDataBasedOnProps = {
    allowSort: true,
    tables: tablesConfig.map(e => ({ title: e.tableTitle, ...getTableColumnsAndData(e.tableKey, e.dispatchType, e.keyTitlePair) })),
    title: <b>Data Based On</b>,
    titlePlacement: 'out',
  };

  return(
    <div id="Ua">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>Google Analytics (UA)</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.left } />}
        </Col>
        <Col sm={24} md={24} lg={12}>
          <Chart.Donut { ...chartDonutVisitorProps } />
        </Col>
      </Row>
      <Paragraph level={3} style={{ margin: '15px 0', fontWeight: 550 }}>Shopping Behaviour</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          <Chart.Bar { ...chartBarShoppingBehaviourProps } />
        </Col>
      </Row>
      <Paragraph level={3} style={{ margin: '5px 0', fontWeight: 550 }}>Product Performance</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          <Card>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          <Col sm={24} md={24} lg={24}>
            {props?.data?.table_purchase?.loading ? null : <Table.Tabbed { ...tabbedTablesDataBasedOnProps } />}
          </Col>
        </Col>
      </Row>
    </div>
  )
}

UaPage.displayName = config.displayName;
UaPage.propTypes = config.propTypes;
UaPage.defaultProps = config.defaultProps;

export default UaPage;
