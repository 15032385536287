import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  available_metrics: { data: {}, error: null, loading: false },
  saved_reports: { data: [], error: null, loading: false },
  opened_report: { data: { id: null, accounts: [], document_type: '', report_name: '', configList: [] }},
  upserted_card: { data: {}, loading: false, error: null },
  fethced_card_data: { data: {}, loading: false, error: null },
  deleted_card: { data: {}, loading: false, error: null },
  deleted_report: { data: {}, error: null, loading: false },
  public_report: { data: {}, error: null, loading: false },
};

export default (state=initialState, action) => {
  switch(action.type) {
    default: return state;

    case TYPES.SET_CUSTOM_REPORT_ALL_METRICS: return {
      ...state, available_metrics: { ...state.available_metrics, ...action.payload },
    };

    case TYPES.SET_CUSTOM_REPORT_SAVED_REPORTS: return {
      ...state, saved_reports: { ...state.saved_reports, ...action.payload },
    };

    case TYPES.SET_CUSTOM_REPORT_UPDATE_REPORT_CONFIG: return {
      ...state,
      saved_reports: {
        ...state.saved_reports,
        data: state.saved_reports.data.map(datum => datum?.id === action?.payload?.data?.id ? { ...datum, ...action?.payload?.data } : { ...datum }),
        error: action?.payload?.error,
        loading: action?.payload?.loading,
      },
    }

    case TYPES.SET_CUSTOM_REPORT_OPENED_REPORT: return {
      ...state, opened_report: { ...state.opened_report, ...action.payload },
    };

    case TYPES.SET_CUSTOM_REPORT_OPENED_REPORT_NAME: return {
      ...state, opened_report: { ...state.opened_report, data: { ...state.opened_report.data, report_name: action.payload.data.report_name }},
    };

    case TYPES.SET_CUSTOM_REPORT_CONFIG_LIST: return {
      ...state, opened_report: { ...state.opened_report, data: { ...state.opened_report.data, configList: [...action.payload.data] }},
    };

    case TYPES.SET_CUSTOM_REPORT_UPSERT_CARD: return {
      ...state, upsert_card: { ...state.upserted_card, ...action.payload },
    };

    case TYPES.SET_CUSTOM_REPORT_GET_CARD_DATA: return {
      ...state, fethced_card_data: { ...state.fethced_card_data, ...action.payload },
    };

    case TYPES.SET_CUSTOM_REPORT_DELETE_CARD: return {
      ...state, deleted_card: { ...state.deleted_card, ...action.payload },
    };

    case TYPES.SET_CUSTOM_REPORT_DELETE_REPORT: return {
      ...state,
      deleted_report: { ...state.deleted_report, ...action.payload },
      saved_reports: { ...state.saved_reports, data: state.saved_reports.data.filter(datum => datum?.id !== action?.payload?.data?.id)}
    };

    case TYPES.SET_CUSTOM_REPORT_PUBLIC_REPORT: return {
      ...state, public_report: { ...state.public_report, ...action.payload },
    };

    case TYPES.RESET_CUSTOM_REPORT_OPENED_REPORT: return {
      ...state, opened_report: { ...initialState.opened_report },
    }
  };
};