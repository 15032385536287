import { connect } from 'react-redux';
import EmailVerification from './EmailVerification.component';

import {
  resendRegistrationCredentials,
  processRegistrationCredentials,
} from '../../redux-v1/actions/auth/__common.actions';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  resendRegistrationCredentials,
  processRegistrationCredentials,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
