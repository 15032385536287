import React from 'react';
import { Typography } from 'antd';
import { PersistentInfo } from '../components/atoms';
import dateConstants from '../constants-v1/date.constants';
import stringConstants from '../constants-v1/string.constants';

const { Paragraph } = Typography;
const { MONTHS } = dateConstants;

/* Functions */

export const getSyncDataModalContent = (props={}) => {
  const { account } = props;
  const accountName = account.account_name;
  const platformName = stringConstants?.[account.platform]?.name;

  return (
    <>
      <Paragraph>
        Are you sure you want to re-synchronize data for <b><i>{platformName} Account</i> : {accountName}</b>?
      </Paragraph>
      <Paragraph>
        By proceeding, you&apos;ll be able to get the latest version of your data. 
        However, doing so might affect the amount of data-requests quota left. 
      </Paragraph>
      <Paragraph>
        <i>(
          also known as <b>rate-limit</b>; 
          the amount of data-requests you be able to perform, typically on a daily/weekly basis
        )</i>
      </Paragraph>
    </>
  );
};

export const isAccountSyncError = (props={}) => {
  const { account, synchronizingAccounts } = props;

  let ans = false;
  if (Array.isArray(synchronizingAccounts)) {
    const match = synchronizingAccounts.find(el => el?.config_id === account.config_id || el?.account_id === account.account_id);
    if (match?.error_details) ans = true;
  };
  return ans;
};

export const isAccountSynchronizing = (props={}) => {
  const { account, synchronizingAccounts } = props;

  let ans = false;
  if (Array.isArray(synchronizingAccounts)) {
    const match = synchronizingAccounts.find(el => el?.config_id === account.config_id || el?.account_id === account.account_id);
    if (match) ans = true;
  }
  return ans;
};

export const renderSyncDataCutoffInfo = (props={}) => {
  const ITEM = JSON.parse(localStorage.getItem('VISITED_PLATFORM'));
  const VISITED_PLATFORM = Array.isArray(ITEM) ? ITEM : [];
  if (!VISITED_PLATFORM.includes(window.location.pathname)) {
    const { account } = props;
    const accountName = account.account_name;
    const platformName = stringConstants?.[account.platform]?.name;

    const TODAY = new Date();
    const TOMORROW = new Date(new Date().setDate(new Date().getDate() + 1));

    const today = `${MONTHS.LONG[TODAY.getMonth()]} ${TODAY.getDate()}, ${TODAY.getFullYear()}`;
    const tomorrow = `${MONTHS.LONG[TOMORROW.getMonth()]} ${TOMORROW.getDate()}, ${TOMORROW.getFullYear()}`;
  
    let futureTime; let lastTime; let comparingPlatform;
    switch(account?.platform) {
      case stringConstants.meta.key:
        futureTime = '05.00 PM'; lastTime = '08.00 AM'; comparingPlatform = 'Facebook (GMT+0) business manager';
        break;
      case stringConstants.tiktok.key:
        futureTime = '05.00 PM'; lastTime = '08.00 AM'; comparingPlatform = 'TikTok (GMT+0) business manager';
        break;
      default:
        futureTime = '05.00 PM'; lastTime = '08.00 AM'; comparingPlatform = 'Facebook (GMT+0) business manager';
        break;
    };
    return(
      <PersistentInfo.Float
        closeBtnText="I understand"
        type="info"
        message={<b>Info: Cut-off data</b>}
        description={<>
          Data as of <b>{today}</b> will be updated on <b>{tomorrow}</b> at <b>{futureTime}</b>. There may be differences between the information displayed here and on the <b>{comparingPlatform}</b>. The current data is based on records up to <b>{today}</b>, last updated at <b>{lastTime}</b>.
        </>}
        onClose={() => {
          VISITED_PLATFORM.push(window.location.pathname);
          localStorage.setItem('VISITED_PLATFORM', JSON.stringify(VISITED_PLATFORM));
        }}
      />
    );
  };
  return null;
};

export const renderSyncDataError = (props={}) => {
  const { account, synchronizingAccounts } = props;
  const match = synchronizingAccounts.find(el => el?.config_id === account?.config_id || el?.account_id === account?.account_id);
  const accountName = account.account_name;
  const platformName = stringConstants?.[account.account_name]?.name;

  const isSyncError = isAccountSyncError(props);

  let comp = <></>;
  if (props?.syncDataFailed || isSyncError) {
    comp = (
      <PersistentInfo.Float
        closeBtnText="I understand"
        type="danger"
        message={<b>Error: Data Synchronization</b>}
        description={<>
          <span>
            <b>{platformName} ({accountName})</b> :
          </span>
          <br />
          <span>
            {match?.error_details?.message || `Data-synchronization failed. Please try again later.`}  
          </span>
          <br />
          <span hidden={!match?.error_details?.regain_access}>
            You can re-attempt another data-synchronization for this account on&nbsp;
            <b>{`${new Date(match?.sync_data?.unix_timestamp + match?.error_details?.regain_access*60*1000)}`}</b>.&nbsp;
            <b>Please DO NOT reload this tab on your browser or this timeout progress will be nullified!</b>
          </span>
        </>}
      />
    );
  };
  return comp;
};

export const renderSyncDataWarning = (props={}) => {
  const isSynchronizing = isAccountSynchronizing(props);
  const isSyncError = isAccountSyncError(props);

  let comp = <></>;
  if (isSynchronizing && !isSyncError) {
    comp = (
      <PersistentInfo.Float
        closeBtnText="I understand"
        type="warning"
        message={<b>Warning: Synchronizing Data</b>}
        description={<>
          This account is currently undergoing data-synchronization process.&nbsp;
          <b>Please DO NOT reload this tab on your browser or this process will be terminated!</b>
        </>}
      />
    );
  };
  return comp;
};