import { format, parse } from 'date-fns';

export const isCurrentDaterangeChanged = (refDaterange={}, newDaterange={}) => {
  let output = true;
  const isStartDateUnchanged = refDaterange?.date_start_current === newDaterange?.date_start_current;
  const isEndDateUnchanged = refDaterange?.date_end_current === newDaterange?.date_end_current;
  if (isStartDateUnchanged && isEndDateUnchanged) output = false;
  return output;
};

export const dateToDateString = (date) => {
  let output = '';
  try {
    output = format(date, 'yyyy-MM-dd');
  } catch {
    output = date;
  };
  return output;
};

export const formatDateDisplay = (date, customFormat='') => {
  let output = '';
  const dateFormat = customFormat || 'dd MMM yyyy';
  if (date) {
    if (typeof date === 'string') {
      try {
        output = format(new Date(date), dateFormat);
      } catch {
        output = date;
      };
    } else {
      output = format(date, dateFormat);
    };
  };
  return output;
};

export const formatDateChartLabel = (date) => {
  let output = '';
  if (date) {
    if (typeof date === 'string') {
      try {
        output = format(new Date(date), 'dd MMM');
      } catch {
        output = date;
      };
    } else {
      output = format(date, 'dd MMM');
    };
  };
  return output;
};