import { connect } from 'react-redux';
import AccountBinding from './AccountBinding.component';

import {
  getAccountConfig,
  getUserData,
} from '../../redux-v1/actions/user/__common.actions';

import {
  unbindAllAccounts,
  unbindSingleAccount,
} from '../../redux-v1/actions/binding/__common.actions';

import {
  setPlatformState,
} from '../../redux-v1/actions/dashboard/__common.actions';

import {
  getGoogleAdsAuthUrl,
  bindGoogleAds,
} from '../../redux-v1/actions/binding/google-ads.actions';

import {
  getGoogleAnalyticsAuthUrl,
  bindGoogleAnalytics,
} from '../../redux-v1/actions/binding/google-analytics.actions';

import {
  bindInstagram,
} from '../../redux-v1/actions/binding/instagram.actions';

import {
  generateMarketplaceAuthUrl,
  bindMarketplace,
} from '../../redux-v1/actions/binding/marketplace.actions';

import {
  bindMeta,
} from '../../redux-v1/actions/binding/meta.actions';

import {
  getTiktokAuthUrl,
  bindTiktok,
} from '../../redux-v1/actions/binding/tiktok.actions';

import {
  bindWebsite,
  updateWebsiteDetails,
} from '../../redux-v1/actions/binding/website.actions';

import stringConstants from '../../constants-v1/string.constants';

const mapStateToProps = (state) => ({
  googleAds: Array.isArray(state.user.accounts_by_platform.googleAds) ?
    state.user.accounts_by_platform.googleAds.map(e => ({ ...e, platform: stringConstants.googleAds.key })) : [],

  googleAnalytics: Array.isArray(state.user.accounts_by_platform.googleAnalytics) ?
    state.user.accounts_by_platform.googleAnalytics.map(e => ({ ...e, platform: stringConstants.googleAnalytics.key })) : [],

  instagram: Array.isArray(state.user.accounts_by_platform.instagram) ?
    state.user.accounts_by_platform.instagram.map(e => ({ ...e, platform: stringConstants.instagram.key })) : [],

  marketplace: Array.isArray(state.user.accounts_by_platform.marketplace) ?
    state.user.accounts_by_platform.marketplace.map(e => ({ ...e, platform: stringConstants.marketplace.key })) : [],

  meta: Array.isArray(state.user.accounts_by_platform.meta) ?
    state.user.accounts_by_platform.meta.map(e => ({ ...e, platform: stringConstants.meta.key })) : [],
  metaAccount: state.dashboard.meta.account,

  tiktok: Array.isArray(state.user.accounts_by_platform.tiktok) ?
    state.user.accounts_by_platform.tiktok.map(e => ({ ...e, platform: stringConstants.tiktok.key })) : [],

  website: Array.isArray(state.user.accounts_by_platform.website) ?
    state.user.accounts_by_platform.website.map(e => ({ ...e, platform: stringConstants.website.key })) : [],
});

const mapDispatchToProps = {
  /* ACCOUNT CONFIG GROUP */
  getAccountConfig,
  getUserData,

  /* GENERAL BINDING FUNCTIONALITY GROUP */
  unbindAllAccounts,
  unbindSingleAccount,
  setPlatformState,
  
  /* GOOGLE ADS GROUP */
  getGoogleAdsAuthUrl,
  bindGoogleAds,
  
  /* GOOGLE ANALYTICS GROUP */
  getGoogleAnalyticsAuthUrl,
  bindGoogleAnalytics,
  
  /* INSTAGRAM GROUP */
  bindInstagram,
  
  /* MARKETPLACE GROUP */
  generateMarketplaceAuthUrl,
  bindMarketplace,
  
  /* META GROUP */
  bindMeta,

  /* TIKTOK GROUP */
  getTiktokAuthUrl,
  bindTiktok,

  /* WEBSITE GROUP */
  bindWebsite,
  updateWebsiteDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBinding);
