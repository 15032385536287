import { cur, flt, int, pct } from './__config';
export default {
  ua: {
    add_to_cart: { key: 'add_to_cart', lbl: 'Add to Cart', fmt: int, adv: 0 },
    all_sessions: { key: 'all_sessions', lbl: 'All Sessions', fmt: int, adv: 0 },
    avgSessionDuration: { key: 'avgSessionDuration', lbl: 'Avg. Session Duration', fmt: flt, adv: 0 },
    avgTimeOnPage: { key: 'avgTimeOnPage', lbl: 'Avg. Time on Page', fmt: flt, adv: 0 },
    bounceRate: { key: 'bounceRate', lbl: 'Bounce Rate', fmt: flt, adv: 1 },
    buyToDetailRate: { key: 'buyToDetailRate', lbl: 'Buy to Detail Rate', fmt: int, adv: 0 },
    buy_rate: { key: 'buy_rate', lbl: 'Buy Rate', fmt: flt, adv: 0 },
    cartToDetailRate: { key: 'cartToDetailRate', lbl: 'Cart to Detail Rate', fmt: int, adv: 0 },
    cart_rate: { key: 'cart_rate', lbl: 'Cart Rate', fmt: flt, adv: 0 },
    check_out: { key: 'check_out', lbl: 'Check Out', fmt: int, adv: 0 },
    entrances: { key: 'entrances', lbl: 'Entrances', fmt: int, adv: 0 },
    exits: { key: 'exits', lbl: 'Exits', fmt: int, adv: 1 },
    exitRate: { key: 'exitRate', lbl: 'Exit Rate', fmt: flt, adv: 0 },
    itemQuantity: { key: 'itemQuantity', lbl: 'Item Qty.', fmt: int, adv: 0 },
    itemsPerPurchase: { key: 'itemsPerPurchase', lbl: 'Items / Purchase', fmt: int, adv: 0 },
    localItemRevenue: { key: 'localItemRevenue', lbl: 'Local Item Revenue', fmt: cur, adv: 0 },
    newUsers: { key: 'newUsers', lbl: 'New Users', fmt: int, adv: 0 },
    newVisitor: { key: 'newVisitor', lbl: 'New Visitor', fmt: int, adv: 0 },
    page: { key: 'page', lbl: 'Page', fmt: int, adv: 0 },
    pageValue: { key: 'pageValue', lbl: 'Page Value', fmt: int, adv: 0 },
    pageviews: { key: 'pageviews', lbl: 'Page Views', fmt: int, adv: 0 },
    pageViews: { key: 'pageViews', lbl: 'Page Views', fmt: int, adv: 0 },
    pageviewsPerSession: { key: 'pageviewsPerSession', lbl: 'Page Views / Session', fmt: flt, adv: 0 },
    product_name: { key: 'product_name', lbl: 'Product Name', fmt: int, adv: 0 },
    product_views: { key: 'product_views', lbl: 'Product Views', fmt: int, adv: 0 },
    returningVisitor: { key: 'returningVisitor', lbl: 'Returning Visitor', fmt: int, adv: 0 },
    revenue: { key: 'revenue', lbl: 'Revenue', fmt: cur, adv: 0 },
    revenuePerItem: { key: 'revenuePerItem', lbl: 'Revenue / Item', fmt: cur, adv: 0 },
    sessions: { key: 'sessions', lbl: 'Sessions', fmt: int, adv: 0 },
    sessionsPerUser: { key: 'sessionsPerUser', lbl: 'Session / User', fmt: int, adv: 0 },
    totalVisitor: { key: 'totalVisitor', lbl: 'Total Visitor', fmt: int, adv: 0 },
    transactions: { key: 'transactions', lbl: 'Transactions', fmt: int, adv: 0 },
    transactionsPerSession: { key: 'transactionsPerSession', lbl: 'Transactions / Session', fmt: int, adv: 0 },
    uniquePageViews: { key: 'uniquePageViews', lbl: 'Unique Page Views', fmt: int, adv: 0 },
    uniquePurchases: { key: 'uniquePurchases', lbl: 'Unique Purchases', fmt: int, adv: 0 },
    users: { key: 'users', lbl: 'Users', fmt: int, adv: 0 }
  },
  ga4: {
    active_users: { key: 'active_users', lbl: 'Active Users', fmt: int, adv: 0 },
    average_engagement_time: { key: 'average_engagement_time', lbl: 'Avg. Engagement Time', fmt: flt, adv: 0 },
    average_engagement_time_per_sessions: { key: 'average_engagement_time_per_sessions', lbl: 'Avg. Engagement Time / Session', fmt: flt, adv: 0 },
    average_session_duration: { key: 'average_session_duration', lbl: 'Avg. Session Duration', fmt: flt, adv: 0 },
    engaged_sessions: { key: 'engaged_sessions', lbl: 'Engaged Sessions', fmt: int, adv: 0 },
    conversions: { key: 'conversions', lbl: 'Conversions', fmt: int, adv: 0 },
    engagement_rate: { key: 'engagement_rate', lbl: 'Engagement Rate', fmt: flt, adv: 0 },
    engaged_session_per_user: { key: 'engaged_session_per_user', lbl: 'Engaged Session / User', fmt: int, adv: 0 },
    event_count: { key: 'event_count', lbl: 'Event Count', fmt: int, adv: 0 },
    events_per_session: { key: 'events_per_session', lbl: 'Events / Session', fmt: int, adv: 0 },
    exits: { key: 'exits', lbl: 'Exits', fmt: int, adv: 0 },
    items_added_to_cart: { key: 'items_added_to_cart', lbl: 'Add to Cart', fmt: int, adv: 0 },
    items_checked_out: { key: 'items_checked_out', lbl: 'Begin Checkout', fmt: int, adv: 0 },
    items_purchased: { key: 'items_purchased', lbl: 'Purchase', fmt: int, adv: 0 },
    items_viewed: { key: 'items_viewed', lbl: 'View Product', fmt: int, adv: 0 },
    new_users: { key: 'new_users', lbl: 'New Users', fmt: int, adv: 0 },
    returning_users: { key: 'returning_users', lbl: 'Returning Users', fmt: int, adv: 0 },
    screen_page_views: { key: 'screen_page_views', lbl: 'Screen Page Views', fmt: int, adv: 0 },
    screen_page_views_per_user: { key: 'screen_page_views_per_user', lbl: 'Screen Page Views / User', fmt: int, adv: 0 },
    sessions: { key: 'sessions', lbl: 'Sessions', fmt: int, adv: 0 },
    sessions_per_user: { key: 'sessions_per_user', lbl: 'Sessions / User', fmt: int, adv: 0 },
    total_revenue: { key: 'total_revenue', lbl: 'Total Revenue', fmt: cur, adv: 0 },
    total_users: { key: 'total_users', lbl: 'Total Users', fmt: int, adv: 0 },
    user_engagement_duration: { key: 'user_engagement_duration', lbl: 'User Engagement Duration', fmt: flt, adv: 0 },
  },
};