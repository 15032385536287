import axios from 'axios';
import { browserName, isIE } from 'react-device-detect';

import dashboardGoogleAdsConfig from '../../../containers/DashboardGoogleAds/DashboardGoogleAds.component.config';
import dashboardGoogleAnalyticsConfig from '../../../containers/DashboardGoogleAnalytics/DashboardGoogleAnalytics.component.config';
import dashboardInstagramConfig from '../../../containers/DashboardInstagram/DashboardInstagram.component.config';
import dashboardMarketplaceConfig from '../../../containers/DashboardMarketplace/DashboardMarketplace.component.config';
import dashboardMetaConfig from '../../../containers/DashboardMeta/DashboardMeta.component.config';
import dashboardTiktokConfig from '../../../containers/DashboardTiktok/DashboardTiktok.component.config';
import dashboardWebsiteConfig from '../../../containers/DashboardWebsite/DashboardWebsite.component.config';

import api2 from '../../../services/api2.service';
import apiBinding from '../../../services/api-binding.service';
import { getAccountConfig } from '../user/__common.actions';

import TYPES from '../../types';
import stringConstants from '../../../constants-v1/string.constants';

const { ApiService2 } = api2.getInstance();
const { BindingApiService } = apiBinding.getInstance();

const getDashboardPlatformConfig = (platform='') => {
  switch(platform) {
    default: return [];
    case stringConstants.googleAds.key: return dashboardGoogleAdsConfig;
    case stringConstants.googleAnalytics.key: return dashboardGoogleAnalyticsConfig;
    case stringConstants.instagram.key: return dashboardInstagramConfig;
    case stringConstants.marketplace.key: return dashboardMarketplaceConfig;
    case stringConstants.meta.key: return dashboardMetaConfig;
    case stringConstants.tiktok.key: return dashboardTiktokConfig;
    case stringConstants.website.key: return dashboardWebsiteConfig;
  }
};

export const setPlatformState = (dispatchType='', params={}) => async(dispatch) => {
  // console.log({ dispatchType, params })
  let payload; let result;
  try {
    if (dispatchType.includes('ACCOUNT')) {
      const { data: { ip } } = await axios.get("https://api.ipify.org/?format=json");
      const __ = await BindingApiService.post('user/activity', {
        browserName, ip, isIE, platform: params?.platform, account: { ...params }, cancelToken: params?.cancelToken,
      });
      // if (__?.status === 200) getAccountConfig();
    };
    payload = { ...params };
    result = Promise.resolve(payload);
  } catch (error) {
    payload = { ...params, error };
    result = Promise.reject(payload);
  };
  dispatch({ type: dispatchType, payload });
  return result;
};

export const getPlatformData = (dispatchType='', params={}) => async(dispatch) => {
  let result;
  if (params !== null) {
    const { PLATFORM } = stringConstants[params?.platform];
    const { requestConfig } = getDashboardPlatformConfig(params?.platform);
    const matchingRequestConfig = requestConfig.find(configuration => configuration.type === dispatchType);
    const { endpoint, method, reqparams, type } = matchingRequestConfig;
    let payload = { loading: true }; let latestSync = 0;
    dispatch({ type, payload });
    try {
      const requestHeaders = {};
      const requestParams = {
        ...params,
        ...reqparams,
        ...(reqparams?.metricsConfig && { metrics: reqparams?.metricsConfig.map(metric => metric.key) }),
      };
      delete requestParams?.metricsConfig;
      let fromBE;
      switch (method) {
        default: fromBE = { data: {} }; break;
        case 'post': fromBE = await ApiService2.post(endpoint, requestParams, requestHeaders); break;
        case 'get': fromBE = await ApiService2.get(endpoint, requestParams); break;
      };
      const { data } = fromBE;
      latestSync = (data?.latest_sync || 0) * 1000;
      payload = {
        data: (data?.data?.data !== undefined && data?.data?.data) ||
          (data?.data !== undefined && data?.data) ||
          data,
        error: null,
        loading: false,
      };
      result = Promise.resolve(payload);
    } catch (error) {
      payload = { error, loading: false };
      result = Promise.reject(payload);
    };
    if (latestSync) dispatch({ type: TYPES[`SET_DASHBOARD_${PLATFORM.replaceAll(' ','_')}_LATEST_SYNC`], payload: latestSync });
    dispatch({ type, payload });
  };
  return result;
};
