import React from 'react';
import { Image, Segmented } from 'antd';

import { ICON_SEGMENTED_LIST, ICON_SEGMENTED_MAP } from '../../../assets-v1/figma-icons';

import config from './IconSegmented.component.config';
// import './IconSegmented.component.styles.less';

const IconSegmented = ({
  className = '',
  defaultValue = 'list',
  hidden = false,
  onChange = () => null,
  options = ['list', 'chart'],
  style = {},
}) => {
  const constructOptions = () => {
    const output = [];
    if (options.includes('list')) output.push({
      value: 'list',
      icon: <Image
        src={ICON_SEGMENTED_LIST}
        alt="icon-segmented-list"
        preview={false}
      />,
    });
    if (options.includes('chart')) output.push({
      value: 'chart',
      icon: <Image
        src={ICON_SEGMENTED_MAP}
        alt="icon-segmented-map"
        preview={false}
      />
    });
    return output;
  };

  return(
    <Segmented
      className={className}
      defaultValue={defaultValue}
      hidden={hidden}
      onChange={onChange}
      options={constructOptions()}
      style={style}
    />
  );
};

IconSegmented.displayName = config.displayName;
IconSegmented.propTypes = config.propTypes;
IconSegmented.defaultProps = config.defaultProps;

export default IconSegmented;