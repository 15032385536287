import PropTypes from 'prop-types';
import TYPES from '../../redux-v1/types';
import metrics from '../../constants-v1/metrics-config/googleAnalytics';
import { reqFormat as rpf } from '../../utils-v1/request.utils';

const displayName = 'Google Analytics';
const propTypes = {};
const defaultProps = { dateRangeValue: PropTypes.object };

const requestConfig = [
  // UA group
  {
    endpoint: 'g-analytics/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ua.users,
        metrics.ua.newUsers,
        metrics.ua.sessions,
        metrics.ua.sessionsPerUser,
        metrics.ua.pageViews,
        metrics.ua.pageviewsPerSession,
        metrics.ua.avgSessionDuration,
        metrics.ua.bounceRate,
        metrics.ua.newVisitor,
        metrics.ua.returningVisitor,
        metrics.ua.totalVisitor,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_OVERVIEW_STATS,
  },
  {
    endpoint: 'g-analytics/data/overview/shopping-behaviour',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_SHOPPING_BEHAVIOUR,
  },
  {
    endpoint: 'g-analytics/data/overview/visitors',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_VISITOR,
  },
  {
    endpoint: 'g-analytics/data/periodic/product-performance-revenue',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_PRODUCT_REVENUE,
  },
  {
    endpoint: 'g-analytics/data/periodic/product-performance-rate',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_CHART_PRODUCT_RATE,
  },
  {
    endpoint: 'g-analytics/data/table/product-performance',
    method: 'post',
    reqparams: {
      metricsConfig: [
        // metrics.ua.product_name,
        metrics.ua.localItemRevenue,
        metrics.ua.uniquePurchases,
        metrics.ua.itemQuantity,
        metrics.ua.revenuePerItem,
        metrics.ua.itemsPerPurchase,
        metrics.ua.cartToDetailRate,
        metrics.ua.buyToDetailRate,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_PRODUCT_PERFORMANCE,
  },
  {
    endpoint: 'g-analytics/data/table/source-medium',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ua.users,
        metrics.ua.newUsers,
        metrics.ua.sessions,
        metrics.ua.bounceRate,
        metrics.ua.pageviewsPerSession,
        metrics.ua.avgSessionDuration,
        metrics.ua.transactionsPerSession,
        metrics.ua.transactions,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_SOURCE_MEDIUM,
  },
  {
    endpoint: 'g-analytics/data/table/exitpage',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ua.exits,
        metrics.ua.pageViews,
        metrics.ua.exitRate,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_EXIT_PAGE,
  },
  {
    endpoint: 'g-analytics/data/table/top-page',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ua.pageviews,
        metrics.ua.uniquePageViews,
        metrics.ua.avgTimeOnPage,
        metrics.ua.entrances,
        metrics.ua.bounceRate,
        metrics.ua.exitRate,
        metrics.ua.pageValue,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_UA_TABLE_TOP_PAGE,
  },

  // GA4 group
  {
    endpoint: 'g-analytics-4/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ga4.total_users,
        metrics.ga4.new_users,
        metrics.ga4.returning_users,
        metrics.ga4.event_count,
        metrics.ga4.average_engagement_time,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_OVERVIEW_STATS,
  },
  {
    endpoint: 'g-analytics-4/data/map/countries',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_TOP_COUNTRIES,
  },
  {
    endpoint: 'g-analytics-4/data/map/cities',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_TOP_CITIES,
  },
  {
    endpoint: 'g-analytics-4/data/overview/gender',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_GENDER,
  },
  {
    endpoint: 'g-analytics-4/data/overview/age',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_AGE,
  },
  {
    endpoint: 'g-analytics-4/data/overview/device',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_CHART_DEVICE,
  },
  {
    endpoint: 'g-analytics-4/data/table/user-acquisition-channel-group',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ga4.new_users,
        metrics.ga4.engaged_sessions,
        metrics.ga4.engagement_rate,
        metrics.ga4.engaged_session_per_user,
        metrics.ga4.average_engagement_time,
        metrics.ga4.event_count,
        metrics.ga4.conversions,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_USER_ACQUISITION,
  },
  {
    endpoint: 'g-analytics-4/data/table/traffic-acquisition-source-medium',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ga4.active_users,
        metrics.ga4.sessions,
        metrics.ga4.engaged_sessions,
        metrics.ga4.average_engagement_time_per_sessions,
        metrics.ga4.engaged_session_per_user,
        metrics.ga4.events_per_session,
        metrics.ga4.engagement_rate,
        metrics.ga4.event_count,
        // metrics.ga4.average_engagement_time,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_TRAFFIC_ACQUISITION,
  },
  {
    endpoint: 'g-analytics-4/data/table/page-screen',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ga4.screen_page_views,
        metrics.ga4.screen_page_views_per_user,
        metrics.ga4.average_engagement_time,
        metrics.ga4.event_count,
        metrics.ga4.exits,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_TABLE_PAGES_SCREEN,
  },
  {
    endpoint: 'g-analytics-4/data/overview/funnel',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_FUNNEL_CHART,
  },
  {
    endpoint: 'g-analytics-4/data/table/device-user-purchase',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.ga4.sessions,
        metrics.ga4.items_viewed,
        metrics.ga4.items_added_to_cart,
        metrics.ga4.items_checked_out,
        metrics.ga4.items_purchased,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_GA4_FUNNEL_TABLE,
  },
];

export default { displayName, propTypes, defaultProps, requestConfig };
