import axios from 'axios';
import { get } from 'lodash';
import api2 from './api2.service';
import { getToken, storeToken } from './token.service';

const { setApi2ReqHeaders } = api2.getInstance();

class BindingApiService {
  static INSTANCE;

  static getInstance = () => {
    if (!BindingApiService.INSTANCE)
      BindingApiService.INSTANCE = new BindingApiService();
    return BindingApiService.INSTANCE;
  };

  constructor() {
    this.BindingApiService = axios.create({
      baseURL: process.env.REST_BINDING_URL,
      timeout: 100000,
    });
  }

  setAuthToken = (token, dontStoreToken) => {
    let token_ = null;
    let uid_ = null;
    if (token) {
      token_ = token.token;
      uid_ = token.uid;
      setApi2ReqHeaders(token); // provide required headers if and only if user auth is successful
      this?.setBindingApiReqHeaders(token); // provide required headers if and only if user auth is successful
    }    
    this.BindingApiService.defaults.headers.common = {   
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",   
      Authorization: `Bearer ${token_}`,
      userID: uid_ || get(getToken(), 'id'),
    };
    if (!dontStoreToken) storeToken(token);
  };

  setBindingApiReqHeaders = (token) => {
    let token_ = null;
    let uid_ = null;
    if (token) {
      token_ = token.token;
      uid_ = token.uid;
    }
    this.BindingApiService.defaults.headers.common = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token_}`,
      userID: uid_ || get(getToken(), 'id'),
    }
  }
};

export default BindingApiService;