import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';
import TYPES from '../../types';

const { BindingApiService, setBindingApiReqHeaders } = apiBinding.getInstance();

const bindingReqParams = {
  params: {
    scopes: `https://www.googleapis.com/auth/analytics.readonly`,
    env: process.env.ENV,
  },
};

export const getGoogleAnalyticsAuthUrl = (redirectUrl = null) => async (dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_GOOGLE_ANALYTICS,
    payload: { loadings: { bind: true } },
  });

  let outcome;
  try {
    let directURL = `${window.location.origin}/binding-google-analytics`;
    if (redirectUrl !== null ) directURL = redirectUrl;
    const { status, data } = await BindingApiService.get(
      `/binding/google/get-auth-url?scopes=${bindingReqParams.params.scopes}&redirect_uri=${directURL}`,
    );
    if (data) {
      dispatch({
        type: TYPES.SET_BINDING_BIND_GOOGLE_ANALYTICS,
        payload: { loadings: { bind: false } },
      });
      outcome = Promise.resolve(data?.data?.url);
      window.location.href = data?.data?.url;
    };
  } catch (err) {
    dispatch({
      type: TYPES.SET_BINDING_BIND_GOOGLE_ANALYTICS,
      payload: { loadings: { bind: false } },
    });
    outcome = Promise.reject(err);
  };
  return outcome;
};

export const bindGoogleAnalytics = (params, redirectUrl = null) => async (dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_GOOGLE_ANALYTICS,
    payload: { loadings: { bind: true } },
  });

  let outcome;
  try {
    // eslint-disable-next-line camelcase
    let directURL = `${window.location.origin}/binding-google-analytics`;
    if (redirectUrl !== null ) directURL = redirectUrl;
    const { data } = await BindingApiService.post(
      `/binding/google/getTokenAuto`,
      { code: params, type: 'google_analytics', env: process.env.ENV, redirect: directURL },
      { ...setBindingApiReqHeaders(getToken()) },
    );
    const postReqBinding = {
      access_token: data?.data?.access_token,
      ini: data?.data?.ini_file,
      user_id: getToken()?.id,
    };
    
    await BindingApiService.post(
      `/binding/google-analytics`,
      postReqBinding,
      { ...setBindingApiReqHeaders(getToken()) },
    );

    dispatch({
      type: TYPES.SET_BINDING_BIND_GOOGLE_ANALYTICS,
      payload: { loadings: { bind: false } },
    });
    outcome = Promise.resolve(true);
  } catch (err) {
    dispatch({
      type: TYPES.SET_BINDING_BIND_GOOGLE_ANALYTICS,
      payload: { loadings: { bind: false } },
    });
    outcome = Promise.reject(err);
  };
  return outcome;
};
