import React from 'react';
import { isMobile } from 'react-device-detect';
import { format } from 'date-fns';
import get from 'lodash/get';
import {
  Button,
  Checkbox,
  Col,
  Layout,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Progress,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { GoogleLogin } from 'react-google-login';
import { Alert } from '../../components/atoms';
import {
  ICON_CHECK_PASSWORD_CONDITION,
  ICON_FAIL_PASSWORD_CONDITION,
  ICON_FACEBOOK_ALT,
  ICON_GOOGLE_ALT,
} from '../../assets-v1/figma-icons';
import { getToken } from '../../services/token.service';
import animations from '../../utils-v1/animations';

import { ICON_FACEBOOK, ICON_GOOGLE } from '../../assets-v1/prev-assets';
import {
  BDD_LOGIN_FORM_LOGO,
  FORGOT_PASSWORD_FORM_LOGO,
  ONBOARDING_ILLUSTRATION,
  LANDING_ACTION_SUCCESS_LOGO,
  BG_LOGIN
} from '../../assets-v1/images';
import TYPES from '../../redux-v1/types';

import config from './LandingUser.component.config';
import './LandingUser.component.styles.less';

const { Title, Text, Paragraph } = Typography;
const { Footer } = Layout;
class LandingUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginLoading: false,
      passwordText: '',
      passwordConfirmText: '',
      passwordHasAtLeast8Characters: false,
      passwordHasANumber: false,
      passwordHasACapitalLetter: false,
      passwordHasALowercaseLetter: false,
      passwordHasSpecialCharacters: false,

      passwordPercent: 0,
      passwordResetLoading: false,
      passwordResetSuccess: false,
      phoneCountryCode: '+62',
      registrationLoading: false,
      registrationSuccess: false,
    };
  }
  formRef = React.createRef();

  componentDidMount() {
    const availableToken = getToken();
    if (availableToken !== null && get(availableToken, 'token') !== undefined) {
      this.props.history.replace('/dashboard');
    } else {
      if (this.props.location.pathname === '/login/') this.props.history.replace('/login');
      if (this.props.location.pathname === '/forgot-password/') this.props.history.replace('/forgot-password');
      if (this.props.location.pathname === '/register/') this.props.history.replace('/register');
      localStorage.clear();
    };
  };

  onLoginSocmed = (isRegCompleted) => {
    if (!isRegCompleted) {
      this.props.history.push('register', { isFromLogin: true });
    } else {
      this.props.history.replace('/');
    }
  };

  onLoginMeta = () => {
    this.props.loginMeta().then(this.onLoginSocmed);
  };

  onLoginGoogleSuccess = (resultGoogle) => {
    this.props.loginGoogleSuccess(resultGoogle).then(this.onLoginSocmed);
  };

  onLoginGoogleFailed = () => {
    this.props.notificationError('Google');
  };

  onSubmitFunctions = () => ({
    forgotPassword: (values) => {
      this.setState({ passwordResetLoading: true });
      this.props.resetPassword(values)
        .then(() => {
          this.setState({ passwordResetSuccess: true });
        })
        .finally(() => {
          this.setState({ passwordResetLoading: false });
        });
    },
    login: (values) => {
      this.setState({ loginLoading: true });
      this.props.loginEmail(values)
        .then(() => {
          this.props.getLastActivityUser().then(() => {
            this.props.getAccountConfig().then(() => {
              this.props.getUserOnboarding().then(() => {
                setTimeout(() => {
                  this.setState({ loginLoading: false });
                  this.props.history.replace('/dashboard');
                }, 500);
              })
            });
          });
        })
        .catch((err) => {
          this.setState({ loginLoading: false });
          Alert.Error({ message: err?.response?.data?.detail?.message });
          if (err === 'INVALID_EMAIL_OR_PASSWORD')
            this.formRef.current.setFields([
              {
                name: 'email',
                errors: ['Provided email or password is invalid'],
              },
              {
                name: 'password',
                errors: ['Provided email or password is invalid'],
              },
            ]);
        })
        // .finally(() => {
        //   this.setState({ loginLoading: false });
        // });
    },
    register: (values) => {
      const {
        phoneCountryCode,
        passwordText,
        passwordConfirmText,
        passwordHasAtLeast8Characters,
        passwordHasANumber,
        passwordHasACapitalLetter,
        passwordHasALowercaseLetter,
        passwordHasSpecialCharacters,
      } = this.state;
      const passwordCheckStatus = [
        passwordHasAtLeast8Characters,
        passwordHasANumber,
        passwordHasACapitalLetter,
        passwordHasALowercaseLetter,
        passwordHasSpecialCharacters,
      ].map(isTrue => !!isTrue);
      const copiedValues = { ...values };
      copiedValues.phone = `${phoneCountryCode}${values.phone}`
      if (passwordText !== passwordConfirmText) {
        notification.error({
          message: `Password does not match`,
          description: `Please make sure you typed your password correctly!`,
        })
      } else if (passwordCheckStatus.includes(false)) {
        notification.error({
          message: `Please adjust your password`,
          description: `One or more required conditions for your password were not met`,
        })
      } else {
        // console.log('registration success')
        this.setState({ registrationLoading: true });
        this.props.registerUser(values)
          .then(() => {
            this.setState({ registrationSuccess: true });
          })
          .catch((error) => {
            Alert.Error({ message: error?.response?.data?.detail?.message })
          })
          .finally(() => {
            this.setState({ registrationLoading: false });
          });
      }
    },
  });

  renderGoogleBtn = (renderProps) => (
    <Button
      onClick={() => {
        renderProps.onClick();
        this.props.loginGoogle();
      }}
      loading={this.props.loadingGoogle}
      disabled={renderProps.disabled}
    >
      <img src={ICON_GOOGLE} alt="btn-google" />
      Google
    </Button>
  );

  renderSocmedButton = () => (
    <div className="row-social-btn">
      <Button
        loading={this.props.loadingFacebook}
        onClick={this.onLoginMeta}
      >
        <img src={ICON_FACEBOOK} alt="btn-facebook" />
        Facebook
      </Button>

      <GoogleLogin
        clientId={process.env.GOOGLE_CLIENT_ID}
        render={this.renderGoogleBtn}
        onSuccess={this.onLoginGoogleSuccess}
        onFailure={this.onLoginGoogleFailed}
        cookiePolicy="single_host_origin"
      />
    </div>
  );

  renderPasswordProgress = () => {
    const percent = this.state.passwordPercent;
    const [red, yellow, green] = ['#FF683A', '#F5BD00', '#59BC7D'];
    let strokeColor = []; let color = 'transparent'; let text = 'null';
    switch (percent) {
      default: break;
      case 20: strokeColor = [red]; color = red; text = 'Weak'; break;
      case 40: strokeColor = [yellow, yellow]; color = yellow; text = 'Medium'; break;
      case 60: strokeColor = [yellow, yellow, yellow]; color = yellow; text = 'Medium'; break;
      case 80: strokeColor = [green, green, green, green]; color = green; text = 'Good!'; break;
      case 100: strokeColor = [green, green, green, green, green]; color = green; text = 'Good!'; break; 
    };
    return (<div style={{ width: '100%' }}>
      <Progress percent={percent} steps={4} strokeColor={strokeColor} />
      <div style={{ textAlign: 'right', color, fontSize: 12, visibility: text ? 'visible' : 'hidden' }}>{text}</div>
      <Space direction="vertical">
        <div style={{ fontSize: 16, fontWeight: 600 }}>Password Must</div>
        <div><Image alt="password_status" preview={false} style={{ paddingRight: 10 }} src={this.state.passwordHasAtLeast8Characters ? ICON_CHECK_PASSWORD_CONDITION : ICON_FAIL_PASSWORD_CONDITION} /> Password has at least 8 characters</div>
        <div><Image alt="password_status" preview={false} style={{ paddingRight: 10 }} src={this.state.passwordHasANumber ? ICON_CHECK_PASSWORD_CONDITION : ICON_FAIL_PASSWORD_CONDITION} /> Password has a number</div>
        <div><Image alt="password_status" preview={false} style={{ paddingRight: 10 }} src={this.state.passwordHasACapitalLetter ? ICON_CHECK_PASSWORD_CONDITION : ICON_FAIL_PASSWORD_CONDITION} /> Password has a capital letter</div>
        <div><Image alt="password_status" preview={false} style={{ paddingRight: 10 }} src={this.state.passwordHasALowercaseLetter ? ICON_CHECK_PASSWORD_CONDITION : ICON_FAIL_PASSWORD_CONDITION} /> Password has a lowercase letter</div>
        <div><Image alt="password_status" preview={false} style={{ paddingRight: 10 }} src={this.state.passwordHasSpecialCharacters ? ICON_CHECK_PASSWORD_CONDITION : ICON_FAIL_PASSWORD_CONDITION} /> Password has special characters</div>
        <div><Image alt="password_status" preview={false} style={{ paddingRight: 10 }} src={this.state.passwordText === this.state.passwordConfirmText? ICON_CHECK_PASSWORD_CONDITION : ICON_FAIL_PASSWORD_CONDITION} /> Passwords match</div>
      </Space>
    </div>)
  };

  renderForms = () => ({
    forgotPassword: (<div id="forgot-password-form" hidden={this.props.location.pathname !== '/forgot-password'}>
      <div style={isMobile ? { textAlign: 'center' } : { textAlign: 'left' }}>
        <Image
          src={this.state.passwordResetSuccess ? LANDING_ACTION_SUCCESS_LOGO : FORGOT_PASSWORD_FORM_LOGO}
          alt={this.state.passwordResetSuccess ? 'reset_password_success_logo' : 'forgot_password_form_logo'}
          preview={false}
        />
        <div style={{ marginBottom: 20, marginTop: 30 }}>
          <Title level={1}>{this.state.passwordResetSuccess ? 'Request Sent!' : 'Forgot Password'}</Title>
          <Paragraph style={{ color: '#4E4E4E' }}>
            {this.state.passwordResetSuccess ?
              `We've sent you an emailcontaining a link that will allow you to reset your password.
              Once you receive the email, follow the instructions to change your password.`
              : `Please enter your email address to get password reset instructions`
            }
          </Paragraph>
        </div>
      </div>
      <Form
        hidden={this.state.passwordResetSuccess === true}
        className="form"
        layout="vertical"
        name="login"
        ref={this.formRef}
        onFinish={this.onSubmitFunctions().forgotPassword}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
            {
              type: 'email',
              message: 'Email is not valid',
            },
          ]}
        >
          <Input
            placeholder="you@example.com"
            autoComplete="off"
            className="input-text"
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            htmlType="submit"
            className="submit-btn"
            size="large"
            loading={this.state.passwordResetLoading}
            type="primary"
          >
            <Text style={{ color: 'white' }}>Send a Request</Text>
          </Button>
        </Form.Item>
      </Form>
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={() => this.props.history.push('login')}
        type={this.state.passwordResetSuccess ? 'primary' : 'link'}
        block
        style={{ borderRadius: 6, width: '100%' }}
      >
        Back to Login
      </Button>
    </div>),

    login: (<div id="login-form" hidden={this.props.location.pathname !== '/login'}>
      <div style={isMobile ? { textAlign: 'center' } : { textAlign: 'left' }}>
        <Image
          src={BDD_LOGIN_FORM_LOGO}
          alt="bdd_login_form_logo"
          preview={false}
          style={{ marginBottom: 20 }}
        />
        <div style={{ marginBottom: 20 }}>
          <Title level={1} style={{ fontWeight: 700 }}>Login</Title>
          <Paragraph style={{ color: '#4E4E4E', fontSize: 16 }}>Welcome back! Please enter your details</Paragraph>
        </div>
      </div>
      <Form
        className="form"
        layout="vertical"
        name="login"
        ref={this.formRef}
        onFinish={this.onSubmitFunctions().login}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
            {
              type: 'email',
              message: 'Email is not valid',
            },
          ]}
        >
          <Input
            placeholder="you@example.com"
            autoComplete="off"
            className="input-text"
            size="large"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Password is required',
            },
          ]}
        >
          <Input.Password autoComplete="off" placeholder="Enter your password" size="large" />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <Form.Item
            name="remember"
            valuePropName="checked"
            style={{ marginBottom: 5 }}
          >
            <Checkbox style={{ fontSize: 14 }}>Remember me</Checkbox>
          </Form.Item>
          <Button
            type="link"
            className="btn-forgot"
            onClick={() => this.props.history.push('forgot-password')}
            // onClick={() => this.setState({ showLoginForm: false, showForgotPasswordForm: true })}
          >
            Forgot your password?
          </Button>
        </div>
        <Form.Item>
          <Button
            block
            htmlType="submit"
            className="submit-btn"
            size="large"
            loading={this.state.loginLoading}
            type="primary"
          >
            <Text style={{ color: 'white', fontSize: 16, fontWeight: 700 }}>Sign In</Text>
          </Button>
        </Form.Item>
      </Form>
      <Paragraph hidden style={{ textAlign: 'center' }}>or sign in with</Paragraph>
      <Row hidden gutter={[16, 16]} style={{ textAlign: 'center' }}>
        <Col sm={24} md={12} lg={12}>
          <Button
            onClick={this.props.loginGoogle}
            style={{ minWidth: '100%' }}
          >
            <Image src={ICON_GOOGLE_ALT} alt="icon_google_alt" preview={false} />
          </Button>
        </Col>
        <Col sm={24} md={12} lg={12}>
          <Button
            onClick={this.props.loginMeta}
            style={{ minWidth: '100%' }}
          >
            <Image src={ICON_FACEBOOK_ALT} alt="icon_facebook_alt" preview={false} />
          </Button>
        </Col>
      </Row>
      <Paragraph style={{ textAlign: 'center' }}>
        Don&apos;t have an account? <Button className="footer-link-btn" type="link" onClick={() => this.props.history.push('register')} style={{ fontSize: 14, fontWeight: 'bold', padding: 0 }}>Sign up here</Button>
      </Paragraph>
    </div>),

    register: (<div id="register-form" hidden={this.props.location.pathname !== '/register'}>
      <div style={isMobile ? { textAlign: 'center' } : { textAlign: 'left' }}>
        <Image
          src={this.state.registrationSuccess ? LANDING_ACTION_SUCCESS_LOGO : BDD_LOGIN_FORM_LOGO}
          alt={this.state.registrationSuccess ? 'landing_action_success_logo' : 'bdd_login_form_logo'}
          preview={false}
          style={{ marginBottom: 40}}
        />
        <div style={{ marginBottom: 20 }}>
          <Title level={1} style={{ fontWeight: 700 }}>{this.state.registrationSuccess ? 'Your account has been created' : 'Create Your Account'}</Title>
          <Paragraph style={{ color: '#4E4E4E', fontSize: 16 }}>
            {this.state.registrationSuccess ?
              'Please check your email for verification' : 'Welcome back! Please enter your details'
            }
          </Paragraph>
        </div>
      </div>
      <Form
        hidden={this.state.registrationSuccess === true}
        className="form"
        layout="vertical"
        name="login"
        ref={this.formRef}
        onFinish={this.onSubmitFunctions().register}
      >
        <Row gutter={[16, 16]}>
          <Col sm={24} md={24} lg={12}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: 'First Name is required',
                }
              ]}
            >
              <Input placeholder="Enter first name" autoComplete="off" className="input-text" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Last Name is required',
                }
              ]}
            >
              <Input placeholder="Enter last name" autoComplete="off" className="input-text" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
            {
              type: 'email',
              message: 'Email is not valid',
            },
          ]}
        >
          <Input placeholder="Enter your email" autoComplete="off" className="input-text" />
        </Form.Item>
        <Form.Item
          label="Company"
          name="company"
          rules={[
            {
              required: true,
              message: 'Company name is required',
            }
          ]}
        >
          <Input placeholder="Enter company name" autoComplete="off" className="input-text" />
        </Form.Item>
        <Form.Item
          label="Job Title"
          name="job_title"
          rules={[
            {
              required: true,
              message: 'Job title is required',
            }
          ]}
        >
          <Input placeholder="Enter job title" autoComplete="off" className="input-text" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: 'Phone number is required',
            },
            {
              pattern: new RegExp(/^[0-9]*$/),
              message: 'Phone number must only contain digits',
            }
          ]}
        >
          <Input
            onKeyDown={(e) => {
              const pressedKey = e.key;
              const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'];
              for (let i=0; i< 10; i+=1) allowedKeys.push(`${i}`);
              if (!allowedKeys.includes(pressedKey)) e.preventDefault();
            }}
            placeholder="Enter phone number"
            autoComplete="off"
            className="input-text"
            addonBefore={<Select
              defaultValue={this.state.phoneCountryCode}
              onChange={(value) => this.setState({ phoneCountryCode: value })}
              options={[
                { label: '+62', value: '+62' },
                // { label: '+01', value: '+01' },
              ]}
            />}
          />
        </Form.Item>
        {/* <Form.Item
          label="Invitation Code"
          name="invitation_code"
          rules={[
            {
              required: true,
              message: 'Invitation code is required',
            },
          ]}
        >
          <Input placeholder="Enter Invitation Code" autoComplete="off" className="input-text" />
        </Form.Item> */}
        <Popover
          content={this.renderPasswordProgress()}
          overlayClassName="password-progress-popover-content"
          placement="bottom"
          trigger="click"
        >
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Password is required',
              },
            ]}
          >
            <Input.Password
              autoComplete="off"
              placeholder="Password (Minimum 8 character)"
              onChange={(e) => {
                const { passwordConfirmText } = this.state;
                const pwd = e.target.value;
                const pwdConditionsArr = [
                  pwd.length >= 8,
                  pwd.match(/[0-9]/g),
                  pwd.match(/[A-Z]/g),
                  pwd.match(/[a-z]/g),
                  pwd.match(/[^A-Za-z 0-9]/g),
                ];
                this.setState({
                  passwordText: pwd,
                  passwordHasAtLeast8Characters: pwd.length >= 8,
                  passwordHasANumber: pwd.match(/[0-9]/g),
                  passwordHasACapitalLetter: pwd.match(/[A-Z]/g),
                  passwordHasALowercaseLetter: pwd.match(/[a-z]/g),
                  passwordHasSpecialCharacters: pwd.match(/[^A-Za-z 0-9]/g),
                  passwordConfirmText,
                  passwordPercent: 100*(pwdConditionsArr.filter(cond => cond !== false && cond !== null).length)/(pwdConditionsArr.length),
                })
              }}
            />
          </Form.Item>
        </Popover>
        {/* this.renderPasswordProgress() */}
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          rules={[
            {
              required: true,
              message: 'Please confirm your password',
            },
          ]}
        >
          <Input.Password
            autoComplete="off"
            placeholder="Password (Minimum 8 character)"
            onChange={(e) => {
              this.setState({ passwordConfirmText: e.target.value })
            }}
          />
        </Form.Item>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
          <Paragraph style={{ fontSize: 14 }}>Do not have invitation code?</Paragraph>
          <Button type="link" style={{ fontSize: 14, fontWeight: 'bold' }}>Contact Us</Button>
        </div> */}
        <Form.Item>
          <Button
            block
            size="large"
            htmlType="submit"
            className="submit-btn"
            loading={this.state.registrationLoading}
            type="primary"
            style={{ marginTop: 20 }}
          >
            <Text style={{ color: 'white', fontSize: 16, fontWeight: 700 }}>Register</Text>
          </Button>
        </Form.Item>
      </Form>
      <Paragraph hidden style={{ textAlign: 'center' }}>or sign up with</Paragraph>
      <Row hidden gutter={[16, 16]} style={{ textAlign: 'center' }}>
        <Col sm={24} md={12} lg={12}>
          <Button
            onClick={this.props.loginGoogle}
            style={{ minWidth: '100%' }}
          >
            <Image src={ICON_GOOGLE_ALT} alt="icon_google_alt" preview={false} />
          </Button>
        </Col>
        <Col sm={24} md={12} lg={12}>
          <Button
            onClick={this.props.loginMeta}
            style={{ minWidth: '100%' }}
          >
            <Image src={ICON_FACEBOOK_ALT} alt="icon_facebook_alt" preview={false} />
          </Button>
        </Col>
      </Row><br />
      <Paragraph style={{ textAlign: 'center' }}>
        {this.state.registrationSuccess ? '' : 'Already have an account? '}
        <Button className="footer-link-btn" type="link" onClick={() => this.props.history.push('login')} style={{ fontWeight: 'bold' }}>Login here</Button>
      </Paragraph>
    </div>),
  });

  constructModalRegisterSuccessProps = () => ({
    title: <Text style={{ fontSize: 16, fontWeight: 600 }}>Success!</Text>,
    children: <Paragraph style={{ padding: 10 }}>
      Your registration is successfully processed. Please check your email for verification.
    </Paragraph>,
    cancelButtonProps: { style: { display: 'none' } },
    onOk: () => {
      this.setState({ registrationSuccess: false });
      this.props.history.replace('/login');
    },
    open: this.state.registrationSuccess,
  });

  render() {
    return (
      <div style={{ animation: animations.popUp }} id="landing-user-page">
        <Modal { ...this.constructModalRegisterSuccessProps() }/>
        <Row style={isMobile ? { display: 'block' } : { height: '100vh' }}>
          <Col sm={24} md={12} lg={12} style={isMobile ? { padding: 30 } : { padding: '5vh 12vw' }}>
            {this.renderForms().login}
            {this.renderForms().forgotPassword}
            {this.renderForms().register}
          </Col>
          <Col
            sm={24}
            md={12}
            lg={12}
            style={{
              background: `url(${BG_LOGIN})`,
              padding: '10vh 6vw',
              textAlign: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
            hidden={isMobile} // side background is hide in mobile view
          >
            <Image
              src={ONBOARDING_ILLUSTRATION}
              alt="onboarding_illustration"
              preview={false}
              style={{ width: '35vw'}}
            />
            <div style={{ transform: 'translateY(-5vh)', padding: '0 90px' }}>
              <Paragraph style={{ color: 'white', fontSize: 28, fontWeight: 700 }}>
                We expand brands through Measurable Digital Strategies
              </Paragraph>
              <Paragraph style={{ color: 'white', fontSize: '1rem' }}>
                Access all digital marketing dashboards in one page, easier than ever.
              </Paragraph>
            </div>
          </Col>
        </Row>
        {/* footer shown in mobile view only */}
        <Footer
          hidden={!isMobile}
          style={{
            textAlign: 'center',
            backgroundColor: '#fff',
            position: 'fixed',
            bottom: 0,
            width: '100%'
          }}
        >
          <Text className="footer" style={{ fontSize: 12, color: '#A6AAB1'}}>
            © Copyright
            BDD.AI {format(new Date(), 'yyyy ')}. All right reserved
          </Text>
        </Footer>
      </div>
    );
  }
}

LandingUser.displayName = config.displayName;
LandingUser.defaultProps = config.defaultProps;
LandingUser.propTypes = config.propTypes;

export default LandingUser;
