import React from 'react';
import { Button, Card, Col, Drawer, List, notification, Space, Spin, Row, Typography, Avatar } from 'antd';
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowUpOutlined, BorderOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoxPlot from 'highcharts/highcharts-more';
import HighchartsHeatMap from 'highcharts/modules/heatmap';
import HighchartsMap from 'highcharts/modules/map';
import { Buttons, CopyrightText, IconSegmented } from '../atoms';
import Stats from '../Stats/Stats.component';
import { coachmark } from '../../utils-v1/coachmark.utils';
import { formatDateDisplay } from '../../utils-v1/daterange.utils';
import { copyImage } from '../../utils-v1/exports.utils';
import { formatInteger, formatPercent } from '../../utils-v1/number.utils';
import { capsFirstLetter } from '../../utils-v1/string.utils';
import { updateCoachmarkLocal } from '../../redux-v1/actions/user/__common.actions';

import config from './Chart.component.config';
import './Chart.component.styles.less';

NoData(Highcharts);
HighchartsHeatMap(Highcharts);
HighchartsBoxPlot(Highcharts);
HighchartsMap(Highcharts);

const { Paragraph, Text, Title } = Typography;
const { success, error } = notification;
const { constructOptions, constructTemplateProps } = config;
const templateProps = constructTemplateProps();
const mapStateToProps = (state) => ({
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});
const mapDispatchToProps = {
  updateCoachmarkLocal,
};

const getCardProps = (props={}, actions={}, type='', forDrawer=0) => ({
  // onMouseEnter: () => console.log({ cardProps: props }),
  bordered: false,
  ...((props?.actions?.copy?.enabled || props?.actions?.expand?.enabled) && {
    extra: !forDrawer && <Space direction="horizontal" size={8}>
      <Buttons.SeeDetail
        disabled={props?.loading}
        hidden={!props?.actions?.expand?.enabled /* || type === 'map' */}
        onClick={actions.expand}
      />
      <IconSegmented
        className="chart-display-toggle"
        defaultValue="chart"
        hidden={!props?.advancedConfig?.withAltDisplay}
        onChange={actions.toggle}
      />
    </Space> || null,
  }),
  headStyle: { border: 'none' },
  id: `chart-${type}-wrapper`,
  style: { marginBotton: 0 },
  ...((props?.title || props?.subtitle) && {
    title: (type === 'map' && !!forDrawer) ? null : <><Title level={2} style={{ fontSize: 16}}>
      {props?.title || templateProps.title}&nbsp;
      {forDrawer ? (
        <Card id="chartDrawerTitle">
          <Space direction="horizontal">
            <div className="current-stats" style={{ textAlign: props?.daterange?.use_compare ? 'right' : 'center' }}>
              <Text className="line-text">Current</Text>
              <br />
              {(props?.daterange?.date_start_current && props?.daterange?.date_end_current) ?
                `${formatDateDisplay(props?.daterange?.date_start_current)} - ${formatDateDisplay(props?.daterange?.date_end_current)}`
                : '' // 'Current Daterange'
              }
            </div>
            <div><Avatar size={40} hidden={!props?.daterange?.use_compare}>VS</Avatar></div>
            <div className="previous-stats" style={{ textAlign: 'left' }} hidden={!props?.daterange?.use_compare}>
              <Text className="line-text">Previous</Text>
              <br />
              {formatDateDisplay(props?.daterange?.date_start_compare)} - {formatDateDisplay(props?.daterange?.date_end_compare)}
            </div>
          </Space>
        </Card>
      ) : (
        <>
          <span className="title-daterange-current" style={{ fontSize: 14 }}>
            {(props?.daterange?.date_start_current && props?.daterange?.date_end_current) ?
              `${formatDateDisplay(props?.daterange?.date_start_current)} - ${formatDateDisplay(props?.daterange?.date_end_current)}`
              : '' // 'Current Daterange'
            }
          </span>&nbsp;
          <span className="title-daterange-compare" style={{ fontSize: 14 }}>
            {(props?.daterange?.use_compare) ?
              `vs ${formatDateDisplay(props?.daterange?.date_start_compare)} - ${formatDateDisplay(props?.daterange?.date_end_compare)}`
              : '' // 'Previous Daterange'
            }
          </span>
        </>
      )}
    </Title><Title hidden={!props?.subtitle} level={3} style={{ fontSize: 14, lineHeight: '12px' }}>{props?.subtitle}</Title></>,
  }),
});
const getDrawerProps = (content=<></>, props={}, state={}, stateSetter=()=>{}) => ({
  afterOpenChange: (open) => {
    if (open && props?.onBoarding?.coachmark_status?.platform === 0 && props?.coachmark_local?.platformCopyBtn === 0) {
      coachmark({
        delay: 1000,
        steps: [{
          key: 1,
          element: '#CopyToClipboardButton',
          title: 'Goodbye Screenshot!',
          intro: 'Now you don’t have to screenshot anymore. Click this to copy to your clipboard',
          position: 'top',
        }],
        oncomplete: () => props?.updateCoachmarkLocal({ ...props?.coachmark_local, platformCopyBtn: 1 }, props?.onBoarding),
      });
    };
  },
  closable: true,
  closeIcon: <Button
    icon={<ArrowLeftOutlined />}
    id="closeBtn"
    size="medium"
    type="link"
  >Back</Button>,
  children: <div style={{ textAlign: 'center' }}>{state.showDrawer ? content : null}</div>,
  height: '100%',
  onClose: () => stateSetter({ ...state, showDrawer: false }),
  open: state.showDrawer,
  placement: 'bottom',
  width: '100%',
});
const getMapTableProps = (props={}, forDrawer=0) => ({
  bordered: !!forDrawer, // false,
  dataSource: Array.isArray(props?.data?.table) && props?.data?.table || [],
  renderItem: (item) => <List.Item>
    <span style={{ float: 'left' }}>{item?.key || item?.[0]}</span>
    <span style={{ float: 'right' }}>{item?.value || item?.[1]}</span>
  </List.Item>,
  size: 'small',
  style: { maxHeight: (forDrawer && `60vh`) || props?.size || 300, overflowY: 'scroll' },
});
const getRenderedComponent = (props={}, state={}, actions={}, cardPropsGenerator=()=>({}), ref={}, type='', forDrawer=0) => {
  let output = null;
  if (props?.plain) {
    output = <div className={`plain-chart-${type} ${props?.daterange?.use_compare ? 'compare' : 'no-compare'}`}>
      <HighchartsReact
        callback={props?.callback}
        constructorType={type === 'map' ? 'mapChart' : null}
        highcharts={Highcharts}
        options={{
          ...constructOptions(type, props, forDrawer),
          ...((type === 'donut' && props?.centerContent) && {
            title: { floating: true, verticalAlign: 'middle', text: props?.centerContent },
          }),
        }}
      />
    </div>
  } else {
    if (type === 'map') {
      const colsConfig = {
        left: forDrawer ? 6 : (!state.showChart && 24 || 0),
        right: forDrawer ? 18 : (state.showChart && 24 || 0),
      };
      output = <Card ref={ref} { ...cardPropsGenerator(forDrawer) } style={{ background: forDrawer ? props?.backgroundDrawer : props?.backgroundDefault }}>
        <Row gutter={[2, 2]} className={`card-chart-${type} ${props?.daterange?.use_compare ? 'compare' : 'no-compare'}`}>
          <Col sm={colsConfig.left} md={colsConfig.left} lg={colsConfig.left}>
            {!!forDrawer && <Title level={2}>{props?.title || templateProps?.title} Table</Title>}
            <List hidden={!forDrawer} { ...getMapTableProps(props, forDrawer) } style={{ marginLeft: 30 }} />
            {!forDrawer && <Stats.List
              collapse={props?.data?.collapse ? props?.data?.collapse : { enabled: true, sliceAt: 15 }}
              columns={4}
              headerStats={null}
              statsList={props?.data?.table?.map((e, i) => ({
                title: e?.[0],
                values: { current: e?.[1] },
                valueFormatter: formatInteger,
              }))}
              title={null}
            /> || null}
          </Col>
          <Col sm={colsConfig.right} md={colsConfig.right} lg={colsConfig.right}>
            {!!forDrawer && <Title level={2}>{props?.title || templateProps?.title} Map</Title>}
            <HighchartsReact
              callback={props?.callback}
              constructorType="mapChart"
              highcharts={Highcharts}
              options={{ ...constructOptions(type, props, forDrawer) }}
            />
          </Col>
        </Row>
      </Card>
    } else if (type === 'donut') {
      const colsConfig = {
        left: forDrawer ? (props?.daterange?.use_compare && 8 || 8) : 12,
        right: forDrawer ? (props?.daterange?.use_compare && 16 || 12) : 12,
      }
      output = <Card ref={ref} { ...cardPropsGenerator(forDrawer) } style={{ background: forDrawer ? props?.backgroundDrawer : props?.backgroundDefault }}>
        <Row /* align={forDrawer ? 'top' : 'middle'} */ gutter={[2, 2]} className={`card-chart-${type} ${props?.daterange?.use_compare ? 'compare' : 'no-compare'}`} style={props?.advancedConfig?.withAltDisplay && { marginTop: 30 }}>
          <Col sm={colsConfig.left} md={colsConfig.left} lg={colsConfig.left} offset={forDrawer && !props?.daterange?.use_compare ? 4 : 0} className="chart-donut-left-col">
            {forDrawer ? <Card bordered={false} style={{ marginLeft: 50 }}>
              <Paragraph style={{ fontSize: 20, fontWeight: 700, textAlign: 'left', padding: '15px 30px' }}>{props?.title}</Paragraph>
              <HighchartsReact
                callback={props?.callback}
                highcharts={Highcharts}
                options={{
                  ...constructOptions(type, { ...props, data: props?.data?.donut || props?.data }, forDrawer),
                  ...(props?.centerContent && { title: { floating: true, verticalAlign: 'middle', text: props?.centerContent } }),
                }}
              />
            </Card> : <HighchartsReact
              callback={props?.callback}
              highcharts={Highcharts}
              options={{
                ...constructOptions(type, { ...props, data: props?.data?.donut || props?.data }, forDrawer),
                ...(props?.centerContent && { title: { floating: true, verticalAlign: 'middle', text: props?.centerContent } }),
              }}
            />}
          </Col>
          <Col sm={colsConfig.right} md={colsConfig.right} lg={colsConfig.right} hidden={!window.location.pathname.includes('custom-report')} className="chart-donut-right-col-custom-report">
            {!forDrawer ? <Row gutter={[16, 16]}>
              {Object.keys(props?.data?.table?.current || {})?.slice(0,(props?.advancedConfig?.sliceAt || 4))?.map((e, i) => <Col sm={24} md={24} lg={12}>
                <Card style={{ border: '1px solid #D0D5DD', padding: '5px 10px', marginBottom: 0, width: 200 }}>
                  <span style={{ display: 'flex', alignItems: 'center', lineHeight: 2 }}>
                    <BorderOutlined style={{ backgroundColor: props?.data?.donut?.[i]?.color || config.getColorSet()?.[i], color: config.getColorSet()?.[i], height: 20, width: 20, borderRadius: 5, transform: 'translateY(-3px)' }} />
                    <div style={{ color: '#7D8085', fontSize: 16, marginLeft: 5 }}>
                      {capsFirstLetter(props?.data?.donut?.[i]?.label || e)?.length > 15 ? `${capsFirstLetter(props?.data?.donut?.[i]?.label || e)?.slice(0,15)}...` : capsFirstLetter(props?.data?.donut?.[i]?.label || e)}
                    </div>
                  </span>
                  <div style={{ paddingBottom: 10, marginLeft: 25 }}>
                    <Text style={{ fontSize: 18, fontWeight: 700 }}>{formatPercent(100*props?.data?.table?.current?.[e]/props?.data?.total?.current)}</Text>
                    <span hidden={!props?.daterange?.use_compare}>
                      &nbsp;&nbsp;{props?.data?.table?.percentage?.[e] > 0 ? <ArrowUpOutlined style={{ color: '#59BC7D' }} /> : <ArrowDownOutlined style={{ color: '#FF683A' }} />}&nbsp;&nbsp;
                      <Text style={{ color: props?.data?.table?.percentage?.[e] > 0 ? '#59BC7D' : '#FF683A' }}>{formatPercent(props?.data?.table?.percentage?.[e])}</Text>
                    </span>
                  </div>
                </Card>
              </Col>)}
              {Object.keys(props?.data?.table?.current || {})?.length > (props?.advancedConfig?.sliceAt || 4) ? <Text className="chart-donut-see-detail-text">click See Detail for more data</Text> : null}
            </Row> : null}
          </Col>
          <Col sm={colsConfig.right} md={colsConfig.right} lg={colsConfig.right} hidden={window.location.pathname.includes('custom-report')}>
            {!forDrawer ? <div>
              {Object.keys(props?.data?.table?.current || {})?.slice(0,4)?.map((e, i) => <Card style={{ border: '1px solid #D0D5DD', paddingLeft: 10, paddingTop: 5, marginRight: 20, width: '90%' }}>
                <span style={{ display: 'flex', alignItems: 'center', lineHeight: 2 }}>
                  <BorderOutlined style={{ backgroundColor: props?.data?.donut?.[i]?.color, color: 'transparent', height: 20, width: 20, borderRadius: 5 }} />
                  <Text style={{ color: '#7D8085', fontSize: 18, marginLeft: 5 }}>{capsFirstLetter(props?.data?.donut?.[i]?.label || e)}</Text>
                </span>
                <div style={{ paddingBottom: 10, marginLeft: 25 }}>
                  <Text style={{ fontSize: 24, fontWeight: 700 }}>{formatPercent(100*props?.data?.table?.current?.[e]/props?.data?.total?.current)}</Text>
                  <span hidden={!props?.daterange?.use_compare}>
                    &nbsp;&nbsp;{props?.data?.table?.percentage?.[e] > 0 ? <ArrowUpOutlined style={{ color: '#59BC7D' }} /> : <ArrowDownOutlined style={{ color: '#FF683A' }} />}&nbsp;&nbsp;
                    <Text style={{ color: props?.data?.table?.percentage?.[e] > 0 ? '#59BC7D' : '#FF683A' }}>{formatPercent(props?.data?.table?.percentage?.[e])}</Text>
                  </span>
                </div>
              </Card>)}
              {Object.keys(props?.data?.table?.current || {})?.length > 4 ? <Text>click See Detail for more data</Text> : null}
            </div> : null}
            {forDrawer ? <div style={{ margin: '0 50px' }}>
              <List
                bordered
                dataSource={[ ...Object.keys(props?.data?.table?.current || {}) ]}
                header={<div style={{ height: 50 }}>
                  <div style={{ fontSize: 16, fontWeight: 500, float: 'left', paddingTop: 10 }}>{props?.listTitle}</div>
                  <div style={{ float: 'right', textAlign: 'right' }}>
                    <Text style={{ fontSize: 16, fontWeight: 700 }}>{formatInteger(props?.data?.total?.current)}</Text>
                    <div hidden={!props?.daterange?.use_compare}>
                      {props?.data?.total?.current > props?.data?.total?.previous ? <ArrowUpOutlined style={{ color: '#59BC7D' }} /> : <ArrowDownOutlined style={{ color: '#FF683A' }} />}
                      &nbsp;&nbsp;<Text style={{ color: props?.data?.total?.current > props?.data?.total?.previous ? '#59BC7D' : '#FF683A' }}>{formatPercent(2.73)}</Text>
                      &nbsp;&nbsp;<Text style={{ color: '#7D8085' }}>vs {formatInteger(props?.data?.total?.previous)}</Text>
                    </div>
                  </div>
                </div>}
                renderItem={(item, i) => <List.Item>
                  <Row gutter={[2, 2]} align="middle">
                    <Col sm={24} md={12} lg={props?.daterange?.use_compare ? 8 : 12}>
                      <BorderOutlined style={{ backgroundColor: props?.data?.donut?.[i]?.color, color: 'transparent', height: 20, width: 20, borderRadius: 5 }} />
                      &nbsp;&nbsp;<Text>{capsFirstLetter(props?.data?.donut?.[i]?.label || item)}</Text>
                      <div style={{ marginLeft: 30 }}>
                        <Text style={{ fontWeight: 700 }}>{formatPercent(100*props?.data?.table?.current?.[item]/props?.data?.total?.current)}</Text>
                        <span hidden={!props?.daterange?.use_compare}>
                          &nbsp;&nbsp;{props?.data?.table?.percentage?.[item] > 0 ? <ArrowUpOutlined style={{ color: '#59BC7D' }} /> : <ArrowDownOutlined style={{ color: '#FF683A' }} />}&nbsp;&nbsp;
                          <Text style={{ color: props?.data?.table?.percentage?.[item] > 0 ? '#59BC7D' : '#FF683A' }}>{formatPercent(props?.data?.table?.percentage?.[item])}</Text>
                        </span>
                      </div>
                    </Col>
                    <Col sm={24} md={12} lg={props?.daterange?.use_compare ? 6 : 12}>
                      <Text style={{ color: '#6B6B6B' }}>{formatDateDisplay(props?.daterange?.date_start_current)} - {formatDateDisplay(props?.daterange?.date_end_current)}</Text>
                      <div style={{ fontSize: 16, fontWeight: 700 }}>{formatInteger(props?.data?.table?.current?.[item] || 0)}</div>
                    </Col>
                    <Col sm={24} md={12} lg={6} hidden={!props?.daterange?.use_compare}>
                      <Text style={{ color: '#6B6B6B' }}>{formatDateDisplay(props?.daterange?.date_start_compare)} - {formatDateDisplay(props?.daterange?.date_end_compare)}</Text>
                      <div style={{ fontSize: 16, fontWeight: 700 }}>{formatInteger(props?.data?.table?.previous?.[item] || 0)}</div>
                    </Col>
                    <Col sm={24} md={12} lg={4} hidden={!props?.daterange?.use_compare}>
                      <Text style={{ color: '#6B6B6B' }}>% Change</Text>
                      <div style={{ fontSize: 16, fontWeight: 700 }}>{formatPercent(props?.data?.table?.percentage?.[item] || 0)}</div>
                    </Col>
                  </Row>
                </List.Item>}
                style={forDrawer && !props?.daterange?.use_compare ? { width: '70%' } : {}}
              />
            </div> : null}
          </Col>
        </Row>
      </Card>
    } else if  (type === 'bar') {
      const colsConfig = {
        left: forDrawer ? 0 : (!state.showChart && 24 || 0),
        right: forDrawer ? 24 : (state.showChart && 24 || 0),
      };
      output = <Card ref={ref} { ...cardPropsGenerator(forDrawer) } style={{ background: forDrawer ? props?.backgroundDrawer : props?.backgroundDefault }}>
        <Row gutter={[2, 2]} className={`card-chart-${type} ${props?.daterange?.use_compare ? 'compare' : 'no-compare'}`}>
          <Col sm={colsConfig.left} md={colsConfig.left} lg={colsConfig.left}>
            {!forDrawer && <div style={props?.advancedConfig?.withAltDisplay && { marginTop: 30 }}><Stats.List
              collapse={props?.data?.collapse ? props?.data?.collapse : { enabled: true, sliceAt: 15 }}
              columns={4}
              headerStats={null}
              statsList={Object.keys(props?.data?.table?.current || {})?.map((e, i) => ({
                adverse: false,
                title: e,
                values: {
                  current: props?.data?.table?.current?.[e],
                  previous: props?.data?.table?.previous?.[e],
                  percentage: props?.data?.table?.percentage?.[e],
                },
                valueFormatter: formatInteger,
              }))}
              title={null}
              useCompare={props?.daterange?.use_compare}
            /></div> || null}
          </Col>
          <Col sm={colsConfig.right} md={colsConfig.right} lg={colsConfig.right}>
            <div style={props?.advancedConfig?.withAltDisplay && { marginTop: 30 }}><HighchartsReact
              callback={props?.callback}
              highcharts={Highcharts}
              options={{ ...constructOptions(type, { ...props, data: props?.data?.bar || props?.data }, forDrawer) }}
            /></div>
          </Col>
        </Row>
      </Card>
    } else {
      output = <Card ref={ref} { ...cardPropsGenerator(forDrawer) } style={{ background: forDrawer ? props?.backgroundDrawer : props?.backgroundDefault }}>
        <Row gutter={[2, 2]} className={`card-chart-${type} ${props?.daterange?.use_compare ? 'compare' : 'no-compare'}`}>
          <Col sm={24} md={24} lg={24}>
            <HighchartsReact
              callback={props?.callback}
              highcharts={Highcharts}
              options={{ ...constructOptions(type, props, forDrawer) }}
            />
          </Col>
        </Row>
      </Card>
    }
    output = <>
      {props?.loading ? <Card style={{ border: 'none', paddingTop: 30, textAlign: 'center' }}><Spin size="large" /></Card> : output}
      {forDrawer && <div style={{ position: 'fixed', bottom: 0, width: '100%', textAlign: 'center', padding: '20px 0 10px 0', background: 'white' }}>
        <Buttons.CopyToClipboard onClick={actions.copy} />
        <CopyrightText style={{ marginTop: 10 }}/>
      </div> || null}
    </>
  };
  return output;
};
const chartComponentGenerator = (pair={ key: '', name: '' }) => {
  const { key, name } = pair;
  const identifier = `${key}Props`;
  const defProps = templateProps[identifier];

  const funcComponent = (props={ ...defProps }) => {
    const [state, setState] = React.useState({ showDrawer: false, showChart: true });
    const ref = React.createRef();
    const actions = {
      copy: () => {
        copyImage({ document, navigator, ref, window })
        // .then(() => success({ message: 'Copied to clipboard' }))
        // .catch(() => error({ message: 'Unable to copy item to clipboard' }))
      },
      expand: () => {
        setState({ ...state, showDrawer: !state.showDrawer })
      },
      toggle: (value) => setState({ ...state, showChart: value === 'chart' })
    };
    const constructDrawerProps = () => getDrawerProps(returnComponent(1), props, state, setState);
    const constructCardProps = (forDrawer=0) => getCardProps(props, actions, key, forDrawer);
    const returnComponent = (forDrawer=0) => getRenderedComponent(props, state, actions, constructCardProps, ref, key, forDrawer);
    return(<>{returnComponent(0)} <Drawer { ...constructDrawerProps() } /></>);
  };

  return connect(mapStateToProps, mapDispatchToProps)(funcComponent);
};

const keysAndNames = [
  { key: 'bar', name: 'Bar' },
  { key: 'combination', name: 'Combination' },
  { key: 'donut', name: 'Donut' },
  { key: 'heatmap', name: 'Heatmap' },
  { key: 'line', name: 'Line' },
  { key: 'map', name: 'Map' },
];

const [ Bar, Combination, Donut, Heatmap, Line, Map ] = keysAndNames.map(pair => chartComponentGenerator(pair));
const Chart = { Bar, Combination, Donut, Heatmap, Line, Map };

Chart.displayName = config.displayName;
Chart.propTypes = config.propTypes;
Chart.defaultProps = config.defaultProps;

export default Chart;
