import React from 'react';
import { message, notification, Space, Spin } from 'antd';
import { DashboardLayoutGS } from '../../components/layouts-backup';
import animations from '../../utils-v1/animations';
import { extractParamFromUrl } from '../../utils-v1/request.utils';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_GOOGLE_ANALYTICS,
  AVATAR_INSTAGRAM,
  AVATAR_MARKETPLACE,
  AVATAR_META,
  AVATAR_TIKTOK,
  AVATAR_WEBSITE,
} from '../../assets-v1/figma-icons';
import {
  EMPTY_GOOGLE_ADS_IMAGE,
  EMPTY_GOOGLE_ANALYTICS_IMAGE,
  EMPTY_INSTAGRAM_IMAGE,
  EMPTY_MARKETPLACE_IMAGE,
  EMPTY_META_IMAGE,
  EMPTY_TIKTOK_IMAGE,
  EMPTY_WEBSITE_IMAGE,
} from '../../assets-v1/images';
import {
  ICON_PRESTASHOP,
  ICON_SHOPEE,
  ICON_SHOPIFY,
  ICON_TOKOPEDIA,
  ICON_WOOCOMMERCE,
} from '../../assets-v1/prev-assets';
import TYPES from '../../redux-v1/types';
import stringConstants from '../../constants-v1/string.constants';

import AccountListWrapper from './AccountListWrapper/AccountListWrapper.component';
import AccountListWrapperButtons from './AccountListWrapperButtons/AccountListWrapperButtons.component';
import AccountList from './AccountList/AccountList.component';

import config from './AccountBinding.component.config';
import './AccountBinding.component.styles.less';
import { Alert } from '../../components/atoms';

class AccountBinding extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountList: [],
      avatarSrc: null,
      emptyContentConfig: {},
      filledContentConfig: {},
      platformKey: '',
      platformName: '',
      searchedData: [],
      uiLoading: false,
      wrapperButtonsConfig: {},
      onBindFunction: () => null,
      onMountFunction: () => null,
    };
  };

  getConfigByPlatformURL = (platformURL='') => {
    const stringConstant = Object.values(stringConstants).find(el => platformURL.includes(el.dash));

    const platformKey = stringConstant.key;
    const platformName = stringConstant.name;
    const accountList = [ ...this.props[platformKey] ];

    let avatarSrc = null;
    let emptyContentConfig = {};
    let filledContentConfig = {};
    let wrapperButtonsConfig = {};
    let onBindFunction = () => null;
    let onMountFunction = () => null;

    if (platformURL.includes(stringConstants.googleAds.dash)) {
      avatarSrc = AVATAR_GOOGLE_ADS;
      emptyContentConfig = { image: EMPTY_GOOGLE_ADS_IMAGE };
      filledContentConfig = { icon: AVATAR_GOOGLE_ADS };
      wrapperButtonsConfig = { btnIcon: AVATAR_GOOGLE_ADS };
      onBindFunction = () => this.props.getGoogleAdsAuthUrl();
      onMountFunction = () => this.props.bindGoogleAds(extractParamFromUrl('code'));
    };
    if (platformURL.includes(stringConstants.googleAnalytics.dash)) {
      avatarSrc = AVATAR_GOOGLE_ANALYTICS;
      emptyContentConfig = { image: EMPTY_GOOGLE_ANALYTICS_IMAGE };
      filledContentConfig = { icon: AVATAR_GOOGLE_ANALYTICS };
      wrapperButtonsConfig = { btnIcon: AVATAR_GOOGLE_ANALYTICS };
      onBindFunction = () => this.props.getGoogleAnalyticsAuthUrl();
      onMountFunction = () => this.props.bindGoogleAnalytics(extractParamFromUrl('code'));
    };
    if (platformURL.includes(stringConstants.instagram.dash)) {
      avatarSrc = AVATAR_INSTAGRAM;
      emptyContentConfig = { image: EMPTY_INSTAGRAM_IMAGE };
      filledContentConfig = { icon: AVATAR_INSTAGRAM };
      wrapperButtonsConfig = { btnIcon: AVATAR_INSTAGRAM };
      onBindFunction = () => this.props.bindInstagram();
      onMountFunction = () => Promise.resolve(null);
    };
    if (platformURL.includes(stringConstants.marketplace.dash)) {
      avatarSrc = AVATAR_MARKETPLACE;
      emptyContentConfig = { image: EMPTY_MARKETPLACE_IMAGE };
      filledContentConfig = { icon: { shopee: ICON_SHOPEE, tokopedia: ICON_TOKOPEDIA }};
      wrapperButtonsConfig = { btnIcon: null };
      onBindFunction = () => this.props.generateMarketplaceAuthUrl(JSON.parse(localStorage.getItem('TEMP_NEW_MARKETPLACE')));
      onMountFunction = () => this.props.bindMarketplace({
        code: extractParamFromUrl('code'),
        shop_id: extractParamFromUrl('shop_id'),
        account_type: JSON.parse(localStorage.getItem('TEMP_NEW_MARKETPLACE'))?.type
      });
    };
    if (platformURL.includes(stringConstants.meta.dash)) {
      avatarSrc = AVATAR_META;
      emptyContentConfig = { image: EMPTY_META_IMAGE };
      filledContentConfig = { icon: AVATAR_META };
      wrapperButtonsConfig = { btnIcon: AVATAR_META };
      onBindFunction = () => this.props.bindMeta(this.props?.metaAccount);
      onMountFunction = () => Promise.resolve(null);
    };
    if (platformURL.includes(stringConstants.tiktok.dash)) {
      avatarSrc = AVATAR_TIKTOK;
      emptyContentConfig = { image: EMPTY_TIKTOK_IMAGE };
      filledContentConfig = { icon: AVATAR_TIKTOK };
      wrapperButtonsConfig = { btnIcon: AVATAR_TIKTOK };
      onBindFunction = () => this.props.getTiktokAuthUrl();
      onMountFunction = () => this.props.bindTiktok(extractParamFromUrl('auth_code'));
    };
    if (platformURL.includes(stringConstants.website.dash)) {
      avatarSrc = AVATAR_WEBSITE;
      emptyContentConfig = { image: EMPTY_WEBSITE_IMAGE };
      filledContentConfig = { icon: { prestashop: ICON_PRESTASHOP, shopify: ICON_SHOPIFY, woocommerce: ICON_WOOCOMMERCE }};
      wrapperButtonsConfig = { btnIcon: null };
      onBindFunction = () => this.props.bindWebsite(JSON.parse(localStorage.getItem('TEMP_NEW_WEBSITE')));
      onMountFunction = () => Promise.resolve(null);
    };

    return {
      platformKey,
      platformName,
      accountList,
      avatarSrc,
      emptyContentConfig: {
        ...emptyContentConfig,
        onBindAccount: this.onBindAccount,
        onBindAccountLoading: false,
      },
      filledContentConfig,
      wrapperButtonsConfig: {
        ...wrapperButtonsConfig,
        // accountList,
        onBindAccount: this.onBindAccount,
        onBindAccountLoading: false,
        onRemoveAccount: this.onRemoveAccount,
        onRemoveAccountLoading: false,
        platformKey,
        platformName,
      },
      onBindFunction,
      onMountFunction,
    };
  };

  onMount = () => {
    const { onMountFunction, platformName } = this.state;

    onMountFunction()
      .then(() => {
        this.props.getAccountConfig();
        Alert.Success({ message: `${platformName} account binding success!`});
      })
      .catch((error) => {
        Alert.Error({ message: `${platformName} account binding failed!`});
      })
      .finally(() => {
        localStorage.removeItem('TEMP_NEW_MARKETPLACE');
        localStorage.removeItem('TEMP_NEW_WEBSITE');
      });
  };

  onBindAccount = () => {
    const { onBindFunction, platformName, wrapperButtonsConfig } = this.state;
    this.setState({ wrapperButtonsConfig: { ...wrapperButtonsConfig, onBindAccountLoading: true } });
    this.setState({ uiLoading: true })

    onBindFunction()
      .then(() => {
        this.props.getAccountConfig();
        this.props.getUserData();
        Alert.Success({ message: `${platformName} account binding success!`});
      })
      .catch(() => {
        Alert.Error({ message: `${platformName} account binding failed!`});
      })
      .finally(() => {
        this.setState({ wrapperButtonsConfig: { ...wrapperButtonsConfig, onBindAccountLoading: false } });
        setTimeout(() => {
          this.setState({ uiLoading: false });
        }, 1000);
      });
  };

  onRemoveAccount = () => {
    const { platformKey, platformName, wrapperButtonsConfig } = this.state;
    this.setState({ wrapperButtonsConfig: { ...wrapperButtonsConfig, onRemoveAccountLoading: true } });

    const payload = { platform: platformKey };
    const { PLATFORM } = stringConstants[platformKey];
    const RESET_PLATFORM_REDUCER = TYPES[`RESET_DASHBOARD_${PLATFORM.replaceAll(' ','_')}_REDUCER`]

    this.props.unbindAllAccounts(platformKey)
      .then((res) => {
        this.props.setPlatformState(RESET_PLATFORM_REDUCER, payload)
        this.props.getAccountConfig()
          .then(() => {
            this.setState({ wrapperButtonsConfig: { ...wrapperButtonsConfig, accountList: [] }})
            message.success(`Successfully removed ${platformName} account!`);
          });
      })
      .catch((error) => {
        message.error(`Something when wrong when removing ${platformName} account.`);
      })
      .finally(() => this.setState({ wrapperButtonsConfig: { ...wrapperButtonsConfig, onRemoveAccountLoading: false } }));
  };

  componentDidMount() {
    // this.props.getUserData()
    this.setState({ uiLoading: true })
    this.props.getAccountConfig()
      .then(() => {
        const platformConfig = this.getConfigByPlatformURL(this.props?.location?.pathname);
        this.setState({ ...platformConfig });
        const { onMountFunction, platformName, wrapperButtonsConfig } = platformConfig;
        const haveParams = this.props.location.search.length > 0;
        if (haveParams) {
          this.setState({ wrapperButtonsConfig: { ...wrapperButtonsConfig, onBindAccountLoading: true } });
          onMountFunction().then(() => {
            this.props.getAccountConfig();
            Alert.Success({ message: `${platformName} account binding success!`});
          }).catch(() =>
            Alert.Error({ message: `${platformName} account binding failed!`})
          ).finally(() => {
            this.setState({ wrapperButtonsConfig: { ...wrapperButtonsConfig, onBindAccountLoading: false } });
            setTimeout(() => {
              this.setState({ uiLoading: false });
            }, 1000);
          });
        } else {
          this.props.getUserData();
          this.props.getAccountConfig();
        };
      })
      .finally(() => {
        setTimeout(() => this.setState({ uiLoading: false }), 1000);
      });
    // console.log(`AccountBinding is mounted...`);
  };

  // componentWillUnmount() {
  //   console.log(`AccountBinding is unmounted...`);
  // };

  constructDashboardProps = () => ({
    accountSelectionProps: null,
    filterProps: null,
    history: this.props.history,
    location: this.props.location,
    rangePickerProps: null,
    syncDataProps: null,
    title: `Account Binding`,
  });

  constructAccountListWrapperProps = () => ({
    avatarSrc: this.state.avatarSrc,
    button: <AccountListWrapperButtons { ...this.state.wrapperButtonsConfig } accountList={this.props[this.state.platformKey]} />,
    onChangeSortBy: (value='') => {
      const { platformKey, accountList } = this.state;
      let accountSorter;
      switch (value) {
        default: accountSorter = () => null; break;
        case 'name': accountSorter = (a, b) => ((a?.account_name?.toLowerCase() < b?.account_name?.toLowerCase()) && -1)
          || ((a?.account_name?.toLowerCase() > b?.account_name?.toLowerCase()) && 1)
          || 0; break;
        case 'type': accountSorter = (a, b) => ((a?.details?.type?.toLowerCase() < b?.details?.type?.toLowerCase()) && -1)
          || ((a?.details?.type?.toLowerCase() > b?.details?.type?.toLowerCase()) && 1)
          || 0; break;
      };
      this.setState({ accountList: value ? this.props?.[platformKey].sort(accountSorter) : accountList });
    },
    onSearch: (e) => {
      const { platformKey } = this.state;
      const filterAccounts = (account) => account?.account_name?.toLowerCase().includes(e.target.value.toLowerCase());
      this.setState({ searchedData: e.target.value === '' ? [] : this.props?.[platformKey].filter(filterAccounts) });
    },
    title: this.state.platformName,
    type: this.state.platformKey,
  });

  constructAccountListProps = () => ({
    ...this.state.wrapperButtonsConfig,
    accountList: Array.isArray(this.props[this.state.platformKey]) ? this.props[this.state.platformKey] : [],
    emptyContentConfig: this.state.emptyContentConfig,
    filledContentConfig: this.state.filledContentConfig,
    onDelete: (account={}) => {
      this.props.unbindSingleAccount(account)
        .then(() => {
          notification.success({
            message: `Success`,
            description: `${account?.account_name} (ID: ${account?.account_id})
              is removed from your ${this.state.platformName} connection`,
          });
          this.setState({ uiLoading: true });
          this.props.getAccountConfig()
            .then(() => this.setState({ uiLoading: false }));
        });
    },
    ...(this.state.platformKey === stringConstants.website.key && {
      onEdit: (account={}) => {
        this.props.updateWebsiteDetails(account)
          .then(() => {
            notification.success({
              message: `Success`,
              description: `Website: ${account?.account_name} (ID: ${account?.account_id})
                details are updated`,
            });
            this.setState({ uiLoading: true });
            this.props.getAccountConfig()
              .then(() => this.setState({ uiLoading: false }));
          })
      },
    }),
    platformKey: this.state.platformKey,
    platformName: this.state.platformName,
    searchedData: this.state.searchedData,
  });

  constructLoadingProps = () => ({
    text: `Fetching list of connected accounts, please wait...`,
    style: {
      background: 'transparent'
    }
  });
  renderLoadingText = () => {
    let txt = `Fetching list of connected accounts, please wait...`;
    if (this.state.wrapperButtonsConfig.onBindAccountLoading) txt = `Binding on progress. Please wait approx. 3-5 minutes...`;
    return txt;
  };

  render() {
    return (
      <DashboardLayoutGS { ...this.constructDashboardProps() }>
        <div style={{ animation: animations.popIn }}>
          {this.state.uiLoading && <div style={{ textAlign: 'center', height: '50vh' }}>
            {/* <Loading { ...this.constructLoadingProps() } /> */}
            <div style={{ position: 'absolute', top: '50%', left: '45%' }}>
              <Spin size="large" />
              <div style={{ marginTop: 20 }}>{this.renderLoadingText()}</div>
            </div>
          </div> || null}
          {!this.state.uiLoading && <AccountListWrapper { ...this.constructAccountListWrapperProps() }>
            <AccountList { ...this.constructAccountListProps() } />
          </AccountListWrapper> || null}
        </div>
      </DashboardLayoutGS>
    );
  };
};

AccountBinding.displayName = config.displayName;
AccountBinding.propTypes = config.propTypes;
AccountBinding.defaultProps = config.defaultProps;

export default AccountBinding;
