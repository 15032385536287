// import get from 'lodash/get';
const ACCOUNT_CONFIG_USER = 'ACCOUNT_CONFIG_USER';
const ACCOUNT_CONFIG_INTRO = 'ACCOUNT_CONFIG_INTRO';
const SYNCHRONIZING_ACCOUNTS = 'SYNCHRONIZING_ACCOUNTS';

export const storeAccountConfig = (account) => {
  if (account) {
    localStorage.setItem(ACCOUNT_CONFIG_USER, JSON.stringify(account));
  } else {
    localStorage.removeItem(ACCOUNT_CONFIG_USER);
  }
};

export const getLocalAccountConfig = () => {
  const account = localStorage.getItem(ACCOUNT_CONFIG_USER);
  const accountList = JSON.parse(account, '{}');
  return accountList;
};

export const storeSynchronizingAccounts = (accountList=[]) => {
  if (accountList) {
    localStorage.setItem(SYNCHRONIZING_ACCOUNTS, JSON.stringify(accountList));
  } else {
    localStorage.removeItem(SYNCHRONIZING_ACCOUNTS);
  }
};

export const getLocalSynchronizingAccounts = () => {
  const stringifiedAccountList = localStorage.getItem(SYNCHRONIZING_ACCOUNTS);
  const parsedAccountList = stringifiedAccountList && JSON.parse(stringifiedAccountList) || [];
  return parsedAccountList;

};

export const setCacheIntroGuide = (datas) => {
  if (datas) {
    localStorage.setItem(ACCOUNT_CONFIG_INTRO, JSON.stringify(datas));
  }
};

export const getCacheIntroGuide = () => {
  const account = localStorage.getItem(ACCOUNT_CONFIG_INTRO);
  const introGuide = JSON.parse(account, '{}');
  return introGuide;
};