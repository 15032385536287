import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  account: { ...globalStateConstants.account.googleAds },
  daterange: { ...globalStateConstants.daterange },
  filter: { ...globalStateConstants.filter.googleAds },
  latest_sync: 0,

  data: {
    sem: {
      overview_stats: { data: {}, error: null, loading: false },
      chart_clicks_conversions: { data: {}, error: null, loading: false },
      chart_age: { data: [], error: null, loading: false },
      chart_gender: { data: [], error: null, loading: false },
      table_sem_top_keywords: { data: [], error: null, loading: false },
      table_sem_search_term_insights: { data: [], error: null, loading: false },
      table_sem_ad_performance: { data: [], error: null, loading: false },
      preview: { data: [], error: null, loading: false },
    },
    gdn: {
      overview_stats: { data: {}, error: null, loading: false },
      chart_clicks_impressions: { data: {}, error: null, loading: false },
      chart_age: { data: [], error: null, loading: false },
      chart_gender: { data: [], error: null, loading: false },
      table_age: { data: [], error: null, loading: false },
      table_campaign: { data: [], error: null, loading: false },
      table_ad_group: { data: [], error: null, loading: false },
      table_audience_segmentation: { data: [], error: null, loading: false },
      preview: { data: [], error: null, loading: false },
    },
    youtube: {
      overview_stats: { data: {}, error: null, loading: false },
      chart_impressions_views: { data: {}, error: null, loading: false },
      chart_age: { data: [], error: null, loading: false },
      chart_gender: { data: [], error: null, loading: false },
      table_audience_segmentation: { data: [], error: null, loading: false },
      table_audience_segment: { data: [], error: null, loading: false },
      table_youtube_ad_performance: { data: [], error: null, loading: false },
      preview: { data: [], error: null, loading: false },
    },
    discovery: {
      overview_stats: { data: {}, error: null, loading: false },
      chart_clicks_conversions: { data: {}, error: null, loading: false },
      chart_age: { data: [], error: null, loading: false },
      chart_gender: { data: [], error: null, loading: false },
      table_campaign: { data: [], error: null, loading: false },
      table_ad_performance: { data: [], error: null, loading: false },
    },
    performance_max: {
      overview_stats: { data: {}, error: null, loading: false },
    },
    smart_campaign: {
      overview_stats: { data: {}, error: null, loading: false },
    },
    app: {
      overview_stats: { data: {}, error: null, loading: false },
    },
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_VISITED: return {
      ...state, visited: true,
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_ACCOUNT: return {
      ...state, account: { /* ...state.account, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_DATERANGE: return {
      ...state, daterange: { /* ...state.daterange, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_FILTER: return {
      ...state, filter: { /* ...state.filter, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_LATEST_SYNC: return {
      ...state, latest_sync: action.payload || 0,
    };

      // SEM GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        sem: {
          ...state.data.sem,
          overview_stats: { /* ...state.data.sem.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_CLICKS_CONVERSIONS: return {
      ...state,
      data: {
        ...state.data,
        sem: {
          ...state.data.sem,
          chart_clicks_conversions: { /* ...state.data.sem.chart_clicks_conversions, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_AGE: return {
      ...state,
      data: {
        ...state.data,
        sem: {
          ...state.data.sem,
          chart_age: { /* ...state.data.sem.chart_age, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_CHART_GENDER: return {
      ...state,
      data: {
        ...state.data,
        sem: {
          ...state.data.sem,
          chart_gender: { /* ...state.data.sem.chart_gender, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_TOP_KEYWORDS: return {
      ...state,
      data: {
        ...state.data,
        sem: {
          ...state.data.sem,
          table_sem_top_keywords: { /* ...state.data.sem.table_sem_top_keywords, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_SEARCH_TERM_INSIGHTS: return {
      ...state,
      data: {
        ...state.data,
        sem: {
          ...state.data.sem,
          table_sem_search_term_insights: { /* ...state.data.sem.table_sem_search_term_insights, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_TABLE_SEM_AD_PERFORMANCE: return {
      ...state,
      data: {
        ...state.data,
        sem: {
          ...state.data.sem,
          table_sem_ad_performance: { /* ...state.data.sem.table_sem_ad_performance, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SEM_PREVIEW: return {
      ...state,
      data: {
        ...state.data,
        sem: {
          ...state.data.sem,
          preview: { /* ...state.data.sem.preview, */ ...action.payload },
        },
      },
    };

      // GDN GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          overview_stats: { /* ...state.data.gdn.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_CLICKS_IMPRESSIONS: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          chart_clicks_impressions: { /* ...state.data.gdn.chart_clicks_impressions, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_AGE: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          chart_age: { /* ...state.data.gdn.chart_age, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_CHART_GENDER: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          chart_gender: { /* ...state.data.gdn.chart_gender, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AGE: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          table_age: { /* ...state.data.gdn.table_age, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_CAMPAIGN: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          table_campaign: { /* ...state.data.gdn.table_campaign, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AD_GROUP: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          table_ad_group: { /* ...state.data.gdn.table_ad_group, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AUDIENCE_SEGMENTATION: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          table_audience_segmentation: { /* ...state.data.gdn.table_audience_segmentation, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_PREVIEW: return {
      ...state,
      data: {
        ...state.data,
        gdn: {
          ...state.data.gdn,
          preview: { /* ...state.data.gdn.preview, */ ...action.payload },
        },
      },
    };

      // YOUTUBE GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        youtube: {
          ...state.data.youtube,
          overview_stats: { /* ...state.data.youtube.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_IMPRESSIONS_VIEWS: return {
      ...state,
      data: {
        ...state.data,
        youtube: {
          ...state.data.youtube,
          chart_impressions_views: { /* ...state.data.youtube.chart_impressions_views, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_AGE: return {
      ...state,
      data: {
        ...state.data,
        youtube: {
          ...state.data.youtube,
          chart_age: { /* ...state.data.youtube.chart_age, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_CHART_GENDER: return {
      ...state,
      data: {
        ...state.data,
        youtube: {
          ...state.data.youtube,
          chart_gender: { /* ...state.data.youtube.chart_gender, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_AUDIENCE_SEGMENTATION: return {
      ...state,
      data: {
        ...state.data,
        youtube: {
          ...state.data.youtube,
          table_audience_segmentation: { /* ...state.data.youtube.table_audience_segmentation, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_AUDIENCE_SEGMENT: return {
      ...state,
      data: {
        ...state.data,
        youtube: {
          ...state.data.youtube,
          table_audience_segment: { /* ...state.data.youtube.table_audience_segment, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_TABLE_YOUTUBE_AD_PERFORMANCE: return {
      ...state,
      data: {
        ...state.data,
        youtube: {
          ...state.data.youtube,
          table_youtube_ad_performance: { /* ...state.data.youtube.table_youtube_ad_performance, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_YOUTUBE_PREVIEW: return {
      ...state,
      data: {
        ...state.data,
        youtube: {
          ...state.data.youtube,
          preview: { /* ...state.data.youtube.preview, */ ...action.payload },
        },
      },
    };

      // DISCOVERY GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        discovery: {
          ...state.data.discovery,
          overview_stats: { /* ...state.data.discovery.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_CLICKS_CONVERSIONS: return {
      ...state,
      data: {
        ...state.data,
        discovery: {
          ...state.data.discovery,
          chart_clicks_conversions: { /* ...state.data.discovery.chart_clicks_conversions, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_AGE: return {
      ...state,
      data: {
        ...state.data,
        discovery: {
          ...state.data.discovery,
          chart_age: { /* ...state.data.discovery.chart_age, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_CHART_GENDER: return {
      ...state,
      data: {
        ...state.data,
        discovery: {
          ...state.data.discovery,
          chart_gender: { /* ...state.data.discovery.chart_gender, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_CAMPAIGN: return {
      ...state,
      data: {
        ...state.data,
        discovery: {
          ...state.data.discovery,
          table_campaign: { /* ...state.data.discovery.table_campaign, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_AD_PERFORMANCE: return {
      ...state,
      data: {
        ...state.data,
        discovery: {
          ...state.data.discovery,
          table_ad_performance: { /* ...state.data.discovery.table_ad_performance, */ ...action.payload },
        },
      },
    };

      // PERFORMANCE MAX GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_PERFORMANCE_MAX_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        performance_max: {
          ...state.data.performance_max,
          overview_stats: { /* ...state.data.performance_max.overview_stats, */ ...action.payload },
        },
      },
    };


      // SMART CAMPAIGN GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_SMART_CAMPAIGN_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        smart_campaign: {
          ...state.data.smart_campaign,
          overview_stats: { /* ...state.data.smart_campaign.overview_stats, */ ...action.payload },
        },
      },
    };

      // APP GROUP

    case TYPES.SET_DASHBOARD_GOOGLE_ADS_APP_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        app: {
          ...state.data.app,
          overview_stats: { /* ...state.data.app.overview_stats, */ ...action.payload },
        },
      },
    };

      // RESET DATA REDUCER

    case TYPES.RESET_DASHBOARD_GOOGLE_ADS_DATA: return {
      ...state, data: initialState.data,
    };

    case TYPES.RESET_DASHBOARD_GOOGLE_ADS_REDUCER: return {
      ...initialState,
    };
  };
};