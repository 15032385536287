import { getToken } from '../../../services/token.service';
import apiBinding from '../../../services/api-binding.service';
import TYPES from '../../types';

const { BindingApiService, setBindingApiReqHeaders } = apiBinding.getInstance();

export const bindMeta = (metaAccount={}) => async (dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_META,
    payload: { loadings: { bind: true } },
  });

  let outcome;
  try {
    const loginFacebook = await new Promise((resolve) => {
      window.FB.login((response) => resolve(response), {
        scope: 'email,public_profile,read_insights,pages_show_list,pages_read_engagement,ads_management',
      });
    });
    const token = loginFacebook?.authResponse?.accessToken;
    console.log({token})

    // dispatch({
    //   type: TYPES.SET_DASHBOARD_META_ACCOUNT,
    //   payload: { ...metaAccount, error: null, loading: false },
    // });

    const response = await BindingApiService.post(
      `/binding/meta`,
      { user_id: getToken()?.id, access_token: token },
      { ...setBindingApiReqHeaders(getToken()) },
    );

    if (response) {
      /* Get account configuration data */
      const { status, data } = await BindingApiService.get(
        `/account-configuration/get-data-by-user-id`,
        { ...setBindingApiReqHeaders(getToken()) },
      );

      dispatch({
        type: TYPES.SET_BINDING_BIND_META,
        payload: { loadings: { bind: false } },
      });

      dispatch({
        type: TYPES.SET_USER_BINDING_ACCOUNT,
        payload:{ ...data, error: null, loading: false },
      });
      outcome = Promise.resolve(data);
    }

  } catch (err) {
    dispatch({
      type: TYPES.SET_BINDING_BIND_META,
      payload: { loadings: { bind: false } },
    });
    outcome = Promise.reject(err);
  };

  return outcome;
};