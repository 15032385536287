import React from 'react';
import {
  Card,
  Checkbox,
  Divider,
  Input,
  Popover,
  Select,
  Space,
  Radio,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import Buttons from '../Buttons/Buttons.component';

import { coachmark } from '../../../utils-v1/coachmark.utils';

import stringConstants from '../../../constants-v1/string.constants';

import config from './Filter.component.config';
import './Filter.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const platformsWithAdSetAdNameOptions = [
  stringConstants.googleAds.key,
  stringConstants.googleAnalytics.key,
  stringConstants.instagram.key,
  // stringConstants.marketplace.key,
  stringConstants.meta.key,
  stringConstants.tiktok.key,
  // stringConstants.website.key,
];
const platformsWithObjectiveFilter = [
  stringConstants.meta.key,
  stringConstants.tiktok.key,
];

const Filter = ({
  defaultState = {
    filter_by: '',
    filter_exclude: false,
    filter_value: [],
  },
  onApply = (filters={}) => console.log({ filters }),
  platformKey = '',
  ...props
}) => {
  const [openCoachmark, setOpenCoachmark] = React.useState(false);

  const [state, setState] = React.useState({
    filter_by: defaultState.filter_by || 'campaign',
    filter_exclude: defaultState.filter_exclude || false,
    filter_value: defaultState.filter_value || [],
  });

  const [auxState, setAuxState] = React.useState({
    keyword_bdd: false,
    temp_input: '',
    popover_open: false || openCoachmark,
  });

  const getPopoverContent = () => (<Card id="filterContent">
    <Paragraph className="section-title" style={{ fontSize: 18, fontWeight: 700 }}>Filter</Paragraph>
    <Select
      defaultValue={defaultState.filter_by || state.filter_by}
      onChange={(value) => setState({ ...state, filter_by: value })}
      options={[
        { value: 'campaign', label: 'Campaign' },
        ...(platformsWithAdSetAdNameOptions.includes(platformKey) && [
          { value: 'ad_set', label: 'Ad Set Name' },
          { value: 'ad_name', label: 'Ad Name' },
        ] || []),
        ...(platformsWithObjectiveFilter.includes(platformKey) && [
          { value: 'objective', label: 'Objective' }
        ] || []),
      ]}
      style={{ width: '100%' }}
    />
    {state.filter_by !== 'objective' ? <>
      <Paragraph className="section-title" style={{ fontSize: 16 }}>Suggested Word</Paragraph>
      <Checkbox
        checked={auxState.keyword_bdd}
        defaultChecked={auxState.keyword_bdd}
        onChange={(e) => {
          const isChecked = e.target.checked;
          setAuxState({ ...auxState, keyword_bdd: isChecked });
          setState({
            ...state,
            filter_value: isChecked ? [...state.filter_value, 'bdd'] : [...state.filter_value].filter(el => el !== 'bdd'),
          })
        }}
        style={{ fontSize: 14 }}
      >BDD</Checkbox>
      <Divider style={{ color: '#D0D5DD', fontSize: 14 }}>or</Divider>
      <Paragraph className="section-title" style={{ color: '#7D8085', fontSize: 14 }}>Enter a word or more</Paragraph>
      <Input
        defaultValue={auxState.temp_input}
        value={auxState.temp_input}
        onChange={(e) => {
          setAuxState({ ...auxState, temp_input: e.target.value })
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.target.value) {
            setAuxState({
              ...auxState,
              keyword_bdd: [...state.filter_value].map(el => el.toLowerCase()).includes('bdd') || e.target.value.toLowerCase() === 'bdd',
              temp_input: '',
            })
            setState({ ...state, filter_value: [...state.filter_value, e.target.value] })
          };
        }}
        placeholder="press 'Enter' before apply"
        // prefix={<SearchOutlined style={{ color: '#A6AAB1', paddingLeft: 10 }} />}
        style={{ fontSize: 14 }}
      />
      <div className="chosen-campaign-section" style={{ marginTop: 10 }}>
        <Paragraph className="section-title" style={{ color: '#7D8085', fontSize: 14 }}>Chosen Words(s)</Paragraph>
        <div style={{ minHeight: 20 }}>
          {state.filter_value.map(tag => (
            <Tag
              closable
              // hidden={tag.toLowerCase() === 'bdd'}
              key={tag}
              onClose={() => {
                setState({ ...state, filter_value: [...state.filter_value].filter(el => el !== tag)})
              }}
              style={{ margin: 5, fontSize: 14 }}
            >{tag}</Tag>
          ))}
        </div>
        <div>
          <Checkbox
            checked={state.filter_exclude}
            defaultChecked={state.filter_exclude}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setState({ ...state, filter_exclude: isChecked });
            }}
            style={{ fontSize: 14 }}
          >Exclude</Checkbox>
        </div>
      </div>
    </> : <>
      <Paragraph className="section-title" style={{ fontSize: '1rem' }}>Objective</Paragraph>
      <Radio.Group onChange={(e) => setState({ ...state, filter_value: [e.target.value] })} defaultValue={state.filter_value?.[0]}>
        <Space direction="vertical">
          {config.getObjectivesByPlatform(platformKey).map(obj => <Radio value={obj.key}>{obj.label}</Radio>)}
        </Space>
      </Radio.Group>
    </>}
    <div style={{ paddingTop: 20, textAlign: 'right' }}>
      <Buttons.Apply
        disabled={state.filter_value.length === 0}
        onClick={() => {
          setAuxState({ ...auxState, popover_open: false });
          if (onApply && typeof onApply === 'function') onApply({ ...state });
        }}
        style={{ fontSize: 14 }}
      />
    </div>
  </Card>);

  const constructPopoverProps = () => ({
    children: <Buttons.Filter
      className={(defaultState?.filter_by !== 'objective' && defaultState?.filter_value?.length === 0) ? 'platform-filter-btn' : 'platform-filter-btn-applied'}
      onClick={() => {
        setAuxState({ ...auxState, popover_open: true });
        if (props.onBoarding.coachmark_status.platform === 0 && props.coachmark_local.platformFilterInner === 0) {
          setOpenCoachmark(true);
          coachmark({
            delay: 500,
            steps: [
              {
                key: 1,
                element: '#filterContent',
                title: `What's New`,
                intro: 'You can select “BDD” as the campaign name filter out the data you need! No more typing',
                position: 'bottom',
              },
              {
                key: 2,
                element: '.chosen-campaign-section',
                title: `What's New`,
                intro: 'The campaign name that you choose will appear here. you can add more than one and remove it as you wish',
                position: 'bottom',
              }
            ],
            oncomplete: () => {
              setOpenCoachmark(false);
              props.updateCoachmarkLocal({ ...props.coachmark_local, platformFilterInner: 1 }, props.onBoarding);
            },
          });
        };
      }}
    />,
    content: getPopoverContent(),
    onOpenChange: () => { if (!openCoachmark) setAuxState({ ...auxState, popover_open: !auxState.popover_open }) },
    open: auxState.popover_open,
    placement: 'bottom',
    trigger: 'click',
  });

  const onClearFilter = () => {
    setAuxState({ keyword_bdd: false, temp_input: '', popover_open: false });
    setState({ filter_by: 'campaign', filter_exclude: false, filter_value: [] });
    if (onApply && typeof onApply === 'function') onApply({ filter_by: 'campaign', filter_exclude: false, filter_value: [] });
  };

  return(<div id="PlatformFilter">
    <Popover { ...constructPopoverProps() } />
    {(defaultState?.filter_by !== 'objective' && defaultState?.filter_value?.length === 0) ? null :
      <Tooltip title="Clear filter"><CloseCircleOutlined onClick={onClearFilter} /></Tooltip>
    }
  </div>);
}

Filter.displayName = config.displayName;
Filter.propTypes = config.propTypes;
Filter.defaultProps = config.defaultProps;

export default Filter;