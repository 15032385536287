import React from 'react';
import {
  Spin,
  Typography,
} from 'antd';
import GridLayout from 'react-grid-layout';
import { formatDateDisplay } from '../../utils-v1/daterange.utils';

import CardContent from '../CustomReport/CardContent/CardContent.component';

import config from './CustomReportPublic.component.config';
import './CustomReportPublic.component.styles.less';

const { Text, Title, Paragraph } = Typography;

class CustomReportPublic extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccounts: [],
      availableMetrics: { googleAds: [], meta: [], tiktok: [] },
      layout: [],
      configList: [],
      uiLoading: true,
    };
  }

  componentDidMount() {
    console.log(`CustomReportPublic is mounted...`);
    if (this.props.history.location.pathname.endsWith('/')) {
      this.props.history.push(this.props.history.location.pathname.slice(0,-1))
    };
    const publicReportID = this.props.history.location.pathname.split('/').reverse()[0];
    this.props.getPublicReportDetail(publicReportID)
      .then((res) => {
        // console.log(res)
        const selectedAccounts = this.getSelectedAccountsFromRes(res);
        const availableMetrics = this.getAvailableMetricsFromRes(res);
        const configList = this.getConfigListFromRes(res);
        const layout = this.getLayoutFromRes(res);
        this.setState({ selectedAccounts, availableMetrics, configList, layout })
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.setState({ uiLoading: false }))
  }

  componentWillUnmount() {
    console.log(`CustomReportPublic is unmounted...`);
  }

  getAvailableMetricsFromRes = (res) => {
    const metrics = res?.content
      ?.map(item => item?.accounts?.map(act => Array.isArray(act?.metrics) ? [...act?.metrics] : [...act?.metrics?.bar, ...act?.metrics?.line])
        .flat(1)).flat(1);
    const metricIDs = [...new Set(metrics.map(m => m?.id))].filter(mID => mID);
    const availableMetricsFromRes = { googleAds: [], meta: [], tiktok: [] };
    for (let i=0; i<metricIDs.length; i+=1) {
      const metricID = metricIDs[i];
      const match = metrics.find(metric => metric?.id === metricID);
      availableMetricsFromRes[match?.metric_platform].push(match);
    };
    return availableMetricsFromRes;
  };

  getConfigListFromRes = (res) => {
    const selectedAccountsFromRes = this.getSelectedAccountsFromRes(res);
    const configListFromRes = res?.content?.map(item => ({
      ...item,
      accounts: item.accounts.map(act => ({
        ...selectedAccountsFromRes.find(el => el?.account_id === act?.account_id),
        ...act,
      })),
      show_total: true,
    }));
    return configListFromRes;
  };

  getLayoutFromRes = (res) => {
    const layoutFromRes = res?.content?.map(item => ({ ...item?.position, isResizable: false, isDraggable: false }));
    return layoutFromRes;
  };

  getSelectedAccountsFromRes = (res) => {
    const selectedAccountIDs = [...new Set(res?.content?.map(item => item?.accounts?.map(act => act.account_id)).flat(1).filter(actId => actId))];
    const selectedAccountsFromRes = selectedAccountIDs
      .map(actID => res?.content?.map(item => item?.accounts).flat(1).find(act => act?.account_id === actID));
    return selectedAccountsFromRes;
  };

  renderTitle = () => <h1>{this.props.title || `CustomReportPublic`}</h1>
  renderText = () => <p>{this.props.text || `CustomReportPublic`} is rendered</p>

  renderHeader = () => {
    const reportName = this.props.public_report?.data?.report_name || '';
    const createdAt = this.props.public_report?.data?.created_at || '';
    const updatedAt = this.props.public_report?.data?.updated_at || '';
    const createdAtText = createdAt.split(' ').map((substr, index) => index === 0 ? formatDateDisplay(substr) : `( ${substr} )`).join(' ');
    const updatedAtText = updatedAt.split(' ').map((substr, index) => index === 0 ? formatDateDisplay(substr) : `( ${substr} )`).join(' ');
    return(
      <header className="report-header">
        <Text style={{ fontSize: 18, fontWeight: 700 }}>{reportName || '-'}</Text><br />
        <Text style={{ fontSize: 14, fontWeight: 500 }}>Created at: <i>{createdAtText || '-'}</i></Text><br />
        <Text style={{ fontSize: 14, fontWeight: 500 }}>Updated at: <i>{updatedAtText || '-'}</i></Text>
      </header>
    );
  };
  renderPage = () => {
    let comp;
    if (this.state.uiLoading) {
      comp = <div className="loading-container">
        <Spin size="large" />
        <Paragraph style={{ color: '#9CA0A6', fontSize: 14 }}>Fetching data. Please wait...</Paragraph>
      </div>
    } else {
      comp = <div className="content-container">
        <GridLayout
          layout={this.state.layout}
          cols={16}
          rowHeight={110}
          width={window.innerWidth-100}
        >
          {this.state.layout.map((item, index) => 
            <div
              key={`${item?.i}`}
              className="grid-item"
              style={[0,null].includes(this.state.configList[index]?.visualization_id) ? {} : { backgroundColor: 'white' }}
            >
              {[0,null].includes(this.state.configList[index]?.visualization_id) ?
                /* eslint-disable-next-line react/no-danger */
                <div dangerouslySetInnerHTML={{ __html: this.state.configList[index]?.description }} style={{ fontSize: 18 }} /> :
                <CardContent
                  availableMetrics={this.state.availableMetrics}
                  selectedAccounts={this.state.selectedAccounts}
                  cardConfig={this.state.configList[index]}
                />
              }
            </div>
          )}
        </GridLayout>
      </div>
    };
    return comp;
  };

  render() {
    return (
      <div id="CustomReportPublic" /* onMouseEnter={() => console.log(this.props, this.state)} */>
        {this.renderHeader()}
        {this.renderPage()}
      </div>
    )
  }
}

CustomReportPublic.displayName = config.displayName;
CustomReportPublic.propTypes = config.propTypes;
CustomReportPublic.defaultProps = config.defaultProps;

export default CustomReportPublic;