import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import TYPES from '../../redux-v1/types';

const displayName = 'Summary';
const propTypes = {};
const defaultProps = {
  dateRangeValue: PropTypes.object,
  websiteAccount: PropTypes.object,
  marketplaceAccount: PropTypes.object,
  facebookAdAccount: PropTypes.object,
  facebookAdType: PropTypes.string,
  instagramAccount: PropTypes.object,
  ganalyticsAccount: PropTypes.object,
  gadwordsAccount: PropTypes.object,
};

const requestConfig = [
  {
    endpoint: 'summary/data/google-ads',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        { key: 'views_yt', label: 'Views from YT', format: 'int' },
        { key: 'clicks', label: 'Clicks from SEM', format: 'int' },
        { key: 'views_gdn', label: 'Views from GDN', format: 'int' },
      ],
    },
    type: TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ADS_DATA,
  },
  {
    endpoint: 'summary/data/google-analytics',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        { key: 'visitors', label: 'Total Visitors', format: 'int' },
        { key: 'views', label: 'Page View', format: 'int' },
        { key: 'bounce_rate', label: 'Bounce Rate', format: 'int' },
      ],
    },
    type: TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_DATA,
  },
  {
    endpoint: 'summary/data/instagram',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        { key: 'new_followers', label: 'New Followers', format: 'int' },
        { key: 'reach', label: 'Reach', format: 'flt' },
        { key: 'impressions', label: 'Impressions', format: 'flt' },
      ],
    },
    type: TYPES.SET_DASHBOARD_SUMMARY_INSTAGRAM_DATA,
  },
  {
    endpoint: 'summary/data/marketplace',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        { key: 'sales', label: 'Sales', format: 'cur' },
        { key: 'cancel', label: 'Cancel', format: 'cur' },
      ],
    },
    type: TYPES.SET_DASHBOARD_SUMMARY_MARKETPLACE_DATA,
  },
  {
    endpoint: 'summary/data/meta',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        { key: 'spend', label: 'Total Spend', format: 'cur' },
        { key: 'impressions', label: 'Total Impressions', format: 'flt' },
        { key: 'purchase_value', label: 'Total Purchase Value', format: 'cur' },
        { key: 'roas', label: 'ROAS', format: 'flt' },
        { key: 'cpc_all', label: 'Total CPC', format: 'cur' },
      ],
    },
    type: TYPES.SET_DASHBOARD_SUMMARY_META_DATA,
  },
  {
    endpoint: 'summary/data/tiktok',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        { key: 'spend', label: 'Total Cost', format: 'cur' },
        { key: 'complete_payment_value', label: 'Complete Payment Value', format: 'cur' },
        { key: 'video_play_actions', label: 'Total Video Viewers', format: 'int' },
      ],
    },
    type: TYPES.SET_DASHBOARD_SUMMARY_TIKTOK_DATA,
  },
  {
    endpoint: 'summary/data/website',
    method: 'post',
    reqparams: {
      format: 'summary',
      metricsConfig: [
        { key: 'sales', label: 'Sales', format: 'cur' },
        { key: 'cancel', label: 'Cancel', format: 'cur' },
      ],
    },
    type: TYPES.SET_DASHBOARD_SUMMARY_WEBSITE_DATA,
  },
];

export default { displayName, propTypes, defaultProps, requestConfig };
