import React from 'react';
import config from './TermsOfServices.component.config';
import './TermsOfServices.component.styles.less';

class TermsOfServices extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log(`TermsOfServices is mounted...`);
  }

  componentWillUnmount() {
    console.log(`TermsOfServices is unmounted...`);
  }

  render() {
    return (
      <div id="terms-of-services">
        <h1>Terms of Services</h1>
        <p className="abstract">
          General Terms and Conditions of Use of <b>BDD-AI</b>
        </p>

        <h2>1. General</h2>
        <p>
          1.1 These General Terms and Conditions of Use together with the Subscribe Now and&nbsp;
          Special Terms (as defined in the Subscribe Now), if any, constitute the entire &ldquo;Agreement&rdquo; between the&nbsp;
          Parties. This &ldquo;Agreement&rdquo; is an agreement between Two Parties. 
          These parties are &ldquo;Company&rdquo; and &ldquo;Customer&rdquo;. 
          These Parties are defined as: (a) the &ldquo;Company&rdquo; or &ldquo;<b>BDD-AI</b>&rdquo; is PT Boleh Dicoba Digital and/or&nbsp;
          <b>BDD-AI</b> and all services related to <b>BDD-AI</b>, then (b) the &ldquo;Customer&rdquo; is the Party who has agreed to &ldquo;&nbsp;
          Subscribe&rdquo; or &ldquo;Sign Up&rdquo; at <b>BDD-AI</b>. 
          The &ldquo;Subscribe&rdquo; or &ldquo;Sign Up&rdquo; is a form of approval and authorization from&nbsp;
          &ldquo;Customer&rdquo; when they subscribed to the service of <b>BDD-AI</b>. 1.2 Customer access to and use of <b>BDD-AI</b> and&nbsp;
          Company products and services purchased by the Customer as stated in &ldquo;Subscribe&rdquo;and&nbsp;
          &ldquo;Sign Up&rdquo; will be regulated in this Agreement. 
          <b>BDD-AI</b> provides subscription services as applicable, on and through the Company&apos;s domains. 
          This agreement will determine the agreement between the two parties in the form of&nbsp;
          &ldquo;Subscribe&rdquo;and &ldquo;Sign Up&rdquo;, unless agreed otherwise by both Parties in writing.
        </p>

        <h2>2. Right of Use</h2>
        <p>
          2.1 The Customer is authorized and permitted to access and use <b>BDD-AI</b> after receiving a Subscribe Confirmation, 
          or &ldquo;Sign Up&rdquo; after registering for a Company subscription, 
          and in accordance with all applicable laws and regulations and have agreed to accept the&nbsp;
          Company&apos;s privacy policy (located on our Privacy Policy page), 
          customers are also permitted to access and use any content regulations accessed by or provided therein. 
          Company reserves the right to make changes to the General Terms and Conditions of Use, policies and <b>BDD-AI</b> at&nbsp;
          any time and will notify customers of these updates and general terms via our website or email. 
          Customer cannot transfer to allow authorized users to use <b>BDD-AI</b> for internal-Customer needs; 
          Customer will be fully responsible and for management, authorization, monitoring, access control, actions, 
          inaction or otherwise, which Customer considers to be &ldquo;authorized users&rdquo;.
        </p>

        <h2>3. Prerequisites</h2>
        <p>
          3.1 Customer will be responsible for obtaining and maintaining all matters relating to <b>BDD-AI</b> and&nbsp;
          to pay all access service costs incurred when using <b>BDD-AI</b> in accordance with the&nbsp;
          price that the customer has subscribed.
        </p>

        <h2>4. Account and Password</h2>
        <p>
          4.1 Customer will receive login access to <b>BDD-AI</b> after subscribing. 
          Customer will have full responsibility for all actions related to the customer&apos;s subscription account itself. 
          If there is an unauthorized or suspicious use, the customer must immediately notify the Company.
        </p>

        <h2>5. Third Party Data Sources</h2>
        <p>
          5.1 <b>BDD-AI</b>&apos;s services in providing data to customers including links to third party data sources. 
          The customer is responsible for evaluating whether the existing Third Party Data Sources agree to be bound by&nbsp;
          the applicable provisions found therein. The company does not filter, audit, or store any Third Party Data Sources. 
          The company only provides data in accordance with customer requests such as the timeframe of data to be seen. 
          <b>BDD-AI</b> also does not own the data from the third party data sources.
          <br/>

            Customer agrees that it will not copy, modify, distribute, sell, take or exploit Third Party Data Sources. 
            Customer guarantees that all content and data available through <b>BDD-AI</b> by the Customer must comply with&nbsp;
            all applicable laws. The Company will stop and block the Customer&apos;s account if it violates the&nbsp;
            Company&apos;s or third party&apos;s intellectual property rights.
        </p>

        <h2>6. Terms and Fees</h2>
        <p>
        6.1 Customers must pay according to the fee and time that was chosen when subscribing. 
        6.2 If Customer cancel subscriptions before the subscribe period runs out there will be no refund. 
        6.3 Tax from the subscription fee will be paid by the customer since the fee does not include tax. 
        </p>
      </div>
    )
  }
}

TermsOfServices.displayName = config.displayName;
TermsOfServices.propTypes = config.propTypes;
TermsOfServices.defaultProps = config.defaultProps;

export default TermsOfServices;