import React from 'react';
import Moment from 'moment';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  notification,
  Radio,
  Space,
  Switch,
  Typography,
} from 'antd';
import axios from 'axios';

import TYPES from '../../redux-v1/types';
import { generateDashboardProps, onMountFunction, renderEmptyState } from '../../utils-v1/platform-page.utils';
import { generateRequestParamsByFormat } from '../../utils-v1/request.utils';
import stringConstants from '../../constants-v1/string.constants';

import { DashboardLayoutGS } from '../../components/layouts-backup';

import InstagramPage from './InstagramPage/InstagramPage.component';

import config from './DashboardInstagram.component.config';
import './DashboardInstagram.component.styles.less';

const { Paragraph, Title, Text } = Typography;
const { key, name, alt, PLATFORM, useFilter } = stringConstants.instagram;
let source;

class DashboardInstagram extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tokenModal: false,
    };
    source = axios.CancelToken.source();
  };

  componentDidMount() {
    const { tokenStatus } = this.props;
    this.setState({ tokenModal: tokenStatus !== 'active' });
    onMountFunction(this, key);
  };

  componentWillUnmount() {
    if (source) { source.cancel('DashboardInstagram axios cancel dua to unmounting') };
  };

  fetchData = (format='') => {
    const reqParams = generateRequestParamsByFormat(key, this.props, format, { cancelToken: source.token });
    const reqList = config.requestConfig.map(item => this.props.getPlatformData(item.type, reqParams[item.reqparamsformat]));
    if (this.props.account.account_id) {
      Promise.all(reqList)
        .then(res => console.log({ res }))
        .catch(err => console.log({ err }))
        .finally(() => localStorage.setItem(`DASHBOARD_${PLATFORM}_DATA`, JSON.stringify(this.props.data)))
    };
  };

  renderPageByAccountType = () => {
    switch(this.props?.account?.platform) {
      case 'instagram': return <InstagramPage { ...this.props } />;
      default: return <>
        <div style={{ borderRadius: 10 }}>
          <Paragraph style={{ marginTop: 30 }}>Please select an account type first!</Paragraph>
        </div>
      </>
    };
  };

  constructTokenModalProps = () => ({
    children: <Paragraph>
      Your Instagram account&apos;s token has reached time out. Try to re-bind your account so you can fully access BDD.AI. Would you like to rebind this account now?
    </Paragraph>,
    cancelText: 'Not Now',
    onCancel: () => {
      this.setState({ tokenModal: false });
    },
    okText: 'Yes',
    onOk: () => {
      this.props?.history?.push('/binding-instagram');
    },
    open: this.state.tokenModal,
  });

  render() {
    return (
      <DashboardLayoutGS { ...generateDashboardProps(this, key, useFilter) }>
        <div id="DashboardInstagram">
          {!this.props.account?.config_id ? renderEmptyState(this, key) : this.renderPageByAccountType()}
        </div>
        <Modal { ...this.constructTokenModalProps() } />
      </DashboardLayoutGS>
    );
  };
};

DashboardInstagram.displayName = config.displayName;
DashboardInstagram.defaultProps = config.defaultProps;
DashboardInstagram.propTypes = config.propTypes;

export default DashboardInstagram;
