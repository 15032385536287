import React from 'react';
import {
  Button,
  Col,
  Row,
  Spin,
  Typography,
} from 'antd';
import { BorderOutlined } from '@ant-design/icons';

import { AccountSelectionGS, EmptyImage, PlatformAvatar } from '../../../components/atoms';
import Chart from '../../../components/Chart/Chart.component';

import stringConstants from '../../../constants-v1/string.constants';

import { formatBig, formatBigcur, formatFloat, formatInteger, formatPercent } from '../../../utils-v1/number.utils';
import { renderArrow, renderPercentText } from '../../../utils-v1/summary-page.utils';

import config from './TiktokPanel.component.config';
// import './TiktokPanel.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const TiktokPanel = ({
  accountSelectionProps,
  availableAccounts,
  history,
  loading,
  platformProps,
}) => {
  const { accounts, daterange } = platformProps;
  const [completePaymentValue] = [{}];

  const accountTypes = ['conversion_tiktok', 'conversion_website', 'community_interaction'];
  accountTypes.forEach(accountType => {
    completePaymentValue[accountType] = accounts?.filter(account => account?.details?.type === accountType);
    completePaymentValue[accountType] = completePaymentValue[accountType]?.length > 0 ? completePaymentValue[accountType]
      ?.map(account => account?.account_id)
      ?.map(id => platformProps?.data?.data?.[id]?.data?.current?.complete_payment_value)
      ?.reduce((v,t) => v+t) : 0;
  });

  const { data } = platformProps?.data;
  const vsInterval = platformProps?.daterange?.preset_range_compare || '-';

  const renderEmptyContent = () => (
    <div className="empty-content tiktok">
      {loading ? <Paragraph style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph> : <>
        <EmptyImage platform="tiktok" width={150} />
        <br />
        <>{availableAccounts?.length === 0 ? <>
          <Text className="line-1">You have not bind your {stringConstants.tiktok.name} accounts yet</Text>
          <br />
          <Text className="line-2">You have to bind your account first to fully access BDD.AI</Text>
        </> : null}</>
        <>{availableAccounts?.length > 0 && platformProps?.accounts?.length === 0 ? <>
          <Text className="line-1">You have not selected accounts yet</Text>
          <br />
          <Text className="line-2">Please select a {stringConstants.tiktok.name} account</Text>
        </>: null}</>
        <br /><br />
        <Button type="primary" className="bind-button" onClick={() => history.push('binding-tiktok')} hidden={availableAccounts?.length > 0}>
          Bind {stringConstants.tiktok.name} Account
        </Button>
      </>}
    </div>
  );

  const getInvolvedAccountTypes = () => [ ...new Set(accounts?.map(account => account?.details?.type)) ];
  const renderLegendPanel = () => (
    <div className="chart-legend" style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', borderLeft: '1px solid #BBC0C7', marginLeft: 10 }}>
      {getInvolvedAccountTypes()?.map(accountType => {
        let renderLegend = '';
        switch (accountType) {
          case 'conversion_tiktok':
            renderLegend = (
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10, minWidth: 55 }}>
                <BorderOutlined style={{ backgroundColor: '#59BC7D', color: 'transparent', height: 15, width: 15, borderRadius: 5 }} />
                <Text style={{ color: '#54575c', marginLeft: 5, fontSize: 14, width: 100, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>TikTok Shop</Text>
              </span>
            );
            break;
          case 'conversion_website':
            renderLegend = (
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10, minWidth: 55 }}>
                <BorderOutlined style={{ backgroundColor: '#018FFC', color: 'transparent', height: 15, width: 15, borderRadius: 5 }} />
                <Text style={{ color: '#54575c', marginLeft: 5, fontSize: 14, width: 100, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>TikTok Website</Text>
              </span>
            );
            break;
          case 'community_interaction':
            renderLegend = (
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10, minWidth: 55 }}>
                <BorderOutlined style={{ backgroundColor: '#F5BD00', color: 'transparent', height: 15, width: 15, borderRadius: 5 }} />
                <Text style={{ color: '#54575c', marginLeft: 5, fontSize: 14, width: 100, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>Community Interaction</Text>
              </span>
            );
            break;

          default:
            break;
        }
        return renderLegend;
      })}
    </div>
  );

  const renderFilledContent = () => (<>
    <Row className="filled-content tiktok">
      <Col className="filled-col radius-top" sm={24} md={24} lg={24}>
        <Paragraph className='title-metric'>Total Cost</Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBigcur(data?.total?.current?.spend)}</Text>
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBigcur(data?.total?.previous?.spend)}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(data?.total?.percentage?.spend)}
          {renderPercentText(data?.total?.percentage?.spend)}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
      <Col className="filled-col" sm={24} md={24} lg={24}>
        <Row>
          <Col sm={24} md={18} lg={18}>
            <Paragraph className='title-metric'>Complete Payment Value</Paragraph>
            <Paragraph>
              <Text className="summary-current-value">{formatBigcur(data?.total?.current?.complete_payment_value)}</Text>
              <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBigcur(data?.total?.previous?.complete_payment_value)}</Text>
            </Paragraph>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Paragraph hidden={!daterange.use_compare}>
                {renderArrow(data?.total?.percentage?.complete_payment_value)}
                {renderPercentText(data?.total?.percentage?.complete_payment_value)}
                <Text className="summary-percentage-value"> vs {vsInterval}</Text>
              </Paragraph>
              {renderLegendPanel()}
            </div>
          </Col>
          <Col sm={24} md={6} lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div>
              <Chart.Donut
                plain
                data={[
                  { label: 'Tiktok Shop', value: completePaymentValue?.conversion_tiktok, color: '#59BC7D' },
                  { label: 'Tiktok Website', value: completePaymentValue?.conversion_website, color: '#018FFC' },
                  { label: 'Community Interaction', value: completePaymentValue?.community_interaction, color: '#F5BD00' },
                ]}
                size={105}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col className="filled-col radius-bottom" sm={24} md={24} lg={24}>
        <Paragraph className='title-metric'>Total Video Viewers</Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBig(data?.total?.current?.video_play_actions)}</Text>
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBig(data?.total?.previous?.video_play_actions)}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(data?.total?.percentage?.video_play_actions)}
          {renderPercentText(data?.total?.percentage?.video_play_actions)}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
    </Row>
  </>);

  const renderEmptyCondition = availableAccounts?.length === 0 || platformProps?.accounts?.length === 0 || loading;
  return(
    <div id="tiktokPanel">
      <div className="platform-title-summary">
        <div className="platform-title">
          <PlatformAvatar platform="tiktok" width={32} />
          <Text>TikTok</Text>
        </div>
        <div hidden={availableAccounts?.length === 0}>
          <AccountSelectionGS { ...accountSelectionProps } />
        </div>
      </div>
      <div>
        {renderEmptyCondition ? renderEmptyContent() : renderFilledContent()}
      </div>
    </div>
  )
}

TiktokPanel.displayName = config.displayName;
TiktokPanel.propTypes = config.propTypes;
TiktokPanel.defaultProps = config.defaultProps;

export default TiktokPanel;
