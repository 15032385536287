import { connect } from 'react-redux';
import AccountSelection from './AccountSelection.component';

import { syncAccountData } from '../../../redux-v1/actions/user/__common.actions';

const mapStateToProps = (state) => ({
  accountsByPlatform: state.user.accounts_by_platform,
  synchronizingAccounts: state.user.synchronizingAccounts,
  ...state.user.accounts_by_platform,
});

const mapDispatchToProps = {
  syncAccountData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelection);
