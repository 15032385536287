import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import Table from '../../../components/Table/Table.component';
import CarouselContentPreview from '../../../components/CarouselContentPreview/CarouselContentPreview.component';
import TYPES from '../../../redux-v1/types';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { ICON_IMAGE, ICON_PIESLICE, ICON_WALLET } from '../../../assets-v1/figma-icons';
import metrics from '../../../constants-v1/metrics-config/googleAds';

import PreviewContent from '../PreviewContent/PreviewContent.component';
import parentConfig from '../DashboardGoogleAds.component.config';
import config from './GdnPage.component.config';
import './GdnPage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_OVERVIEW_STATS);
const tablesConfig = [
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AGE,
    keyTitlePair: { key: 'age', title: 'Age' },
    tableKey: 'table_age',
    tableTitle: 'Age',
    tableListMetrics: [
      { key: 'age', lbl: 'Age' ,fixed: 'left' },
      metrics.gdn.cost,
      { key: 'campaign', lbl: 'Campaign' },
      { key: 'ad_group', lbl: 'Ad Group' },
      { key: 'ad_group_status', lbl: 'Status' },
      metrics.gdn.clicks,
      metrics.gdn.impressions,
      metrics.gdn.ctr,
      metrics.gdn.cpc,
      metrics.gdn.conversions,
      metrics.gdn.conversion_rates,
      metrics.gdn.cost_per_conversions,
    ]
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_CAMPAIGN,
    keyTitlePair: { key: 'campaign', title: 'Campaign' },
    tableKey: 'table_campaign',
    tableTitle: 'Campaign',
    tableListMetrics: [
      { key: 'campaign', lbl: 'Campaign' ,fixed: 'left' },
      metrics.gdn.cost,
      metrics.gdn.clicks,
      metrics.gdn.impressions,
      metrics.gdn.ctr,
      metrics.gdn.cpc,
      metrics.gdn.conversions,
      metrics.gdn.conversion_rates,
      metrics.gdn.conversion_values,
      metrics.gdn.cost_per_conversions,
    ]
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AD_GROUP,
    keyTitlePair: { key: 'ad_group', title: 'Ad Group' },
    tableKey: 'table_ad_group',
    tableTitle: 'Ad Group',
    tableListMetrics: [
      { key: 'ad_group', lbl: 'Ad Group' ,fixed: 'left' },
      metrics.gdn.cost,
      metrics.gdn.clicks,
      metrics.gdn.impressions,
      metrics.gdn.ctr,
      metrics.gdn.cpc,
      metrics.gdn.conversions,
      metrics.gdn.conversion_rates,
      metrics.gdn.cost_per_conversions,
    ]
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ADS_GDN_TABLE_AUDIENCE_SEGMENTATION,
    keyTitlePair: { key: 'audience_segment', title: 'Audience Segmentation' },
    tableKey: 'table_audience_segmentation',
    tableTitle: 'Audience Segmentation',
    tableListMetrics: [
      { key: 'audience_segment', lbl: 'Audience Segmentation' ,fixed: 'left' },
      metrics.gdn.cost,
      metrics.gdn.clicks,
      metrics.gdn.impressions,
      metrics.gdn.ctr,
      metrics.gdn.cpc,
      metrics.gdn.conversions,
      metrics.gdn.conversion_rates,
      metrics.gdn.cost_per_conversions,
    ]
  },
];
const contentPreviewMetrics = {
  gdn: [
    metrics.gdn.clicks,
    metrics.gdn.impressions,
    metrics.gdn.spending,
  ],
};
const performanceChartConfig = [
  {
    key: 'chart_clicks_impressions',
    pair: [
      [metrics.gdn.clicks, 'Clicks', '#51ab72', '#90d2a8'],
      [metrics.gdn.impressions, 'Impressions', '#f5bd00', '#f8d354'],
    ],
    title: 'Clicks & Impressions',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    key: 'chart_age',
    pair: [
      ['age', metrics.gdn.clicks, 'Clicks', '#51ab72', '#90d2a8'],
      ['age', metrics.gdn.conversions, 'Conversions', '#f5bd00', '#f8d354'],
    ],
    title: 'Age',
    valueFormatter: getNumberFormatterByFormatCode('int'),
    chart: 'bar',
  },
  {
    key: 'chart_gender',
    pair: [
      ['gender', metrics.gdn.clicks, 'Clicks', '#51ab72', '#90d2a8'],
      ['gender', metrics.gdn.conversions, 'Conversions', '#f5bd00', '#f8d354'],
    ],
    title: 'Gender',
    valueFormatter: getNumberFormatterByFormatCode('int'),
    chart: 'bar',
  },
];

const GdnPage = (props) => {
  const getValueFormatter = getNumberFormatterByFormatCode;

  const statsListProps = {
    middle: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 8 },
      columns: 4,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_WALLET} alt="icon_wallet" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[0].lbl}
        </>,
        values: {
          current: props?.data?.gdn?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          previous: props?.data?.gdn?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          percentage: props?.data?.gdn?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt, props?.account?.details?.currency),
      },
      loading: props?.data?.gdn?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(1)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.gdn?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.gdn?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.gdn?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt, props?.account?.details?.currency),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const getTableColumnsAndData = (
    tableKey='',
    dispatchType='',
    tableListMetrics=[],
  ) => ({
    // columns: [{ ...firstCol, width: '200px', fixed: 'left' }]
    //   .concat(requestConfig.find(e => e.type === dispatchType)
    //     .reqparams.metricsConfig.map(e => ({ key: e.key, title: e.lbl, width: '200px' }))),
    columns: tableListMetrics.map(entry => ({ key: entry?.key, title: entry?.lbl, fixed: entry?.fixed, width: '200px' })),
    data: (Array.isArray(props?.data?.gdn?.[tableKey]?.data) && props?.data?.gdn?.[tableKey]?.data || [])
      .map(e => {
        const rowData = { ...e };
        const metricKeys = Object.keys(e);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const matchingMetricConfig = requestConfig.find(f => f.type === dispatchType)
            .reqparams.metricsConfig.find(f => f.key === metricKey);
          rowData[metricKey] = getValueFormatter(matchingMetricConfig?.fmt, props?.account?.details?.currency)(rowData[metricKey]);
        };
        return rowData;
      }),
    loading: props?.data?.gdn?.[tableKey]?.loading || props?.uiLoading,
  });

  const tabbedTablesDataBasedOnProps = {
    allowSort: true,
    tables: tablesConfig.map(e => ({ title: e.tableTitle, ...getTableColumnsAndData(e.tableKey, e.dispatchType, e.tableListMetrics) })),
    title: <b>GDN Performance Insights</b>,
    titlePlacement: 'out',
  };

  const contentPreviewProps = {
    data: (Array.isArray(props?.data?.gdn?.preview?.data) && props?.data?.gdn?.preview?.data || [{}])?.map((e, i) => ({
      src: { data: <PreviewContent content={e?.previews} />},
      statsList: contentPreviewMetrics.gdn?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.gdn?.preview?.data?.[i]?.[item.key],
        },
        valueFormatter: getValueFormatter(item?.fmt, props?.account?.details?.currency),
      })),
      title: e?.campaign_name,
    })),
    loading: props?.data?.gdn?.preview?.loading || props?.uiLoading,
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  };
  const formatChartTitle = (title='') => <>{title}</>
  const formatChartData = (chartKey='', keyLabelPairs=[]) => {
    const output = [];
    for (let i=0; i<keyLabelPairs.length; i+=1) {
      const keyLabelPair = keyLabelPairs[i];
      if (chartKey === "chart_age" || chartKey === "chart_gender") { // enhancement for chart age and gender
        const propsData = props?.data?.gdn?.[chartKey]?.data;
        const groups = Object.keys(propsData?.current || {});
        const chartMetrics = ['Clicks', 'Impressions'];
        chartMetrics.forEach(metric => {
          output.push({
            label: `${metric} (current)`,
            // color: 'red',
            entries: groups.map(group => [(group || '')?.replaceAll('_',' '), propsData?.current?.[group]?.[metric.toLowerCase()]]),
          })
          if (props?.daterange?.use_compare) output.push({
            label: `${metric} (previous)`,
            // color: 'green',
            entries: groups.map(group => [(group || '')?.replaceAll('_',' '), propsData?.previous?.[group]?.[metric?.toLowerCase()]]),
          })
        });
        break;
      } else {
        output.push({
          entries: props?.data?.gdn?.[chartKey]?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1], color: keyLabelPair[2]
        });
        if (props?.daterange?.use_compare) {
          output.push({
            entries: props?.data?.gdn?.[chartKey]?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare`, color: keyLabelPair[3]
          });
        };
      }
    };
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    data: formatChartData(e.key, e.pair),
    title: formatChartTitle(e.title),
    chart: e.chart,
    loading: props?.data?.gdn?.[e.key]?.loading || props?.uiLoading,
    valueFormatter: e.valueFormatter,
  }));
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => {
        // enhancement for chart age and gende
        let children = <Chart.Line { ...e } />;
        if (e?.chart === 'bar' ) {
          const eExtends = {
            ...e,
            advancedConfig: {
              ...e.advancedConfig,
              stacked: true,
            },
          };
          children = <Chart.Bar { ...eExtends } />;
        }
        return { key: `${i}`, label: e.title, children };
      }),
  };

  return(
    <div id="GoogleAdsGdn">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>Google Ads - GDN</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.middle } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.table_purchase?.loading ? null : <Table.Tabbed { ...tabbedTablesDataBasedOnProps } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Paragraph style={{ fontWeight: 550 }}>Top Display Ads</Paragraph>
          <Card style={{ padding: 15 }}>
            <CarouselContentPreview { ...contentPreviewProps } />
          </Card>
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

GdnPage.displayName = config.displayName;
GdnPage.propTypes = config.propTypes;
GdnPage.defaultProps = config.defaultProps;

export default GdnPage;
