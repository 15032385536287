import { connect } from 'react-redux';
import Docs from './Docs.component';
import { getUserData } from '../../redux-v1/actions/account/__common.actions';

const mapStateToProps = (state) => ({
  user: state.user.me.data,
});

const mapDispatchToProps = {
  getUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Docs);
