import React from 'react';
import config from './PrivacyPolicy.component.config';
import './PrivacyPolicy.component.styles.less';

class PrivacyPolicy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log(`PrivacyPolicy is mounted...`);
  }

  componentWillUnmount() {
    console.log(`PrivacyPolicy is unmounted...`);
  }

  render() {
    return (
      <div id="privacy-policy">
        <h1>Privacy Policy</h1>
        <p className="abstract"></p>

        <h2>Scope</h2>
        <p>
          PLEASE READ THIS PRIVACY STATEMENT AND CAREFULLY NOTICE THE PRIVACY. 
          This Privacy Statement and Notice of Privacy Practices (&ldquo;Privacy Policy&rdquo;) describe the privacy policy&nbsp;
          of <b>BDD-AI</b> which will be described as &ldquo;We&rdquo; &ldquo;We&rdquo; or &ldquo;Company&rdquo;. 
          The Privacy Policy governs and explains the use of this website by you as a Customer. 
          This Privacy Policy is intended as a company privacy policy that applies to all data collection by the Company.
        </p>

        <h2>Changes and Updates to This Privacy Policy</h2>
        <p>
          As we have explained on the General Terms and Conditions page, 
          we reserve the right to revise or update this Privacy Policy at any time. 
          We will notify you of any changes to the Privacy Policy by posting a revised or updated Privacy Policy&nbsp;
          and &ldquo;Last Modified&rdquo; date on the Site, as well as sending an email notification of the latest changes. 
          After we notify you of new changes, your use of the BDD site is your agreement and acceptance of our new privacy policy. 
          You should read the Privacy Policy to be aware of any revisions or updates. 
          This Privacy Policy is included as part of the Terms and Conditions in connection with your use of the Site. 
          If you do not agree to the terms and conditions of this Privacy Policy, we will not provide your information to us. 
          If you do not agree to any of the terms of this Privacy Policy, 
          please do not continue to subscribe and use <b>BDD-AI</b>site.
        </p>

        <h2>Contact and Electronic Communications Opt Out</h2>
        <p>
          By giving your email to us, you have agreed to communicate with you via email such as to notify the&nbsp;
          latest information for your account or also to provide news about updates from our website. 
          If you do not wish to receive this email, you can unsubscribe to our newsletter anytime.
        </p>

        <h2>Descriptions of Products and Services</h2>
        <p>
          We provide products in the form of software and tools to provide services that support data integration, 
          data monitoring and data management for clients in various sectors of the economy and business, 
          including providing client support through our services for data management and&nbsp;
          facilitating the creation of reports that can help you grow your business. 
          We provide a visualization dashboard for our clients of different data sources and&nbsp;
          generate advanced reports for revenue opportunities.
        </p>

        <h2>Third Party Links and Websites</h2>
        <p>
          Our site can contain links to third party services. 
          You are responsible to read and understand the terms of use and&nbsp;
          privacy policy statements for each of these third parties.
        </p>

        <h2>How We Protect Your Information</h2>
        <p>
          You submit the information at your own risk. After we receive your integration and authorization, 
          we make the appropriate effort to ensure the security of our system. 
          We use a firewall to protect your information from unauthorized treatment. 
          However, this is not a guarantee that the information cannot be accessed, disclosed, altered, 
          or destroyed by breach of firewall or secure server software. 
          But, we also will not store your data, we only provide your data based on requests from the time you specify.
        </p>
        
      </div>
    )
  }
}

PrivacyPolicy.displayName = config.displayName;
PrivacyPolicy.propTypes = config.propTypes;
PrivacyPolicy.defaultProps = config.defaultProps;

export default PrivacyPolicy;