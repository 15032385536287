// CONSTANTS

const localeKey = 'en';

const localeFormatter = (number=0, locale='', maximumFractionDigits=0, minimumFractionDigits=0) => {
  let output = number;
  if (typeof number === 'number') {
    output = number.toLocaleString(locale, { maximumFractionDigits, minimumFractionDigits });
  };
  return output;
}

// EXPORTS

export const formatBig = (number=0, baseFormat='int') => {
  const [trillion, billion, million, thousand] = [1e12, 1e9, 1e6, 1e3];
  const magnitudes = [
    { key: 'thousand', value: 1e3, suffix: 'K' },
    { key: 'million', value: 1e6, suffix: 'M' },
    { key: 'billion', value: 1e9, suffix: 'B' },
    { key: 'trillion', value: 1e12, suffix: 'T' },
  ];
  let output = number;
  let divider = 1; let suffix = ''; let dec = baseFormat === 'int' ? 0 : 2;
  if (typeof number === 'number') {
    magnitudes.forEach(magnitude => {
      if (number/magnitude.value > 1) {
        divider = magnitude.value;
        suffix = magnitude.suffix || '';
        const greaterThanPointOne = (number/magnitude.value) - (Math.floor(number/magnitude.value)) >= 0.1;
        dec = greaterThanPointOne && magnitude.key !== 'thousand' ? 1 : 0;
      }
    });
    output = `${localeFormatter(number/divider, localeKey, dec, dec)}${suffix}`;
  };
  return output;
};

export const formatBigcur = (number=0, currency='') => {
  let output = number;
  if (typeof number === 'number') {
    output = `${currency || 'IDR'} ${formatBig(number, 'cur')}`
  };
  return output;
};

export const formatCurrency = (number=0, decimals=2, currency='') => {
  let output = number;
  if (typeof number === 'number') {
    output = `${currency || 'IDR'} ${localeFormatter(number, localeKey, decimals, decimals)}`;
  };
  return output;
};

export const formatInteger = (number=0) => {
  let output = number;
  if (typeof number === 'number') {
    output = localeFormatter(number, localeKey, 0, 0);
  };
  return output;
};

export const formatFloat = (number=0, decimals=2) => {
  let output = number;
  if (typeof number === 'number') {
    output = localeFormatter(number, localeKey, decimals, decimals);
  };
  return output;
};

export const formatPercent = (number=0, decimals=2) => {
  let output = number;
  if (typeof number === 'number') {
    output = `${localeFormatter(number, localeKey, decimals, decimals)} %`;
  };
  return output;
};

export const getNumberFormatterByFormatCode = (formatCode='int', currency='', withDecimal=true) => {
  switch(formatCode) {
    case 'big': return formatBig;
    case 'bcr': return formatBigcur;
    case 'cur': return (value = 0, decimal = withDecimal ? 2 : 0) => formatCurrency(value, decimal, currency);
    case 'flt': return formatFloat;
    case 'int': return formatInteger;
    case 'pct': return formatPercent;
    default: return (val) => val;
  };
};

export const unformatNumber = (formattedNumber='0') => {
  let output = 0;
  if (typeof formattedNumber === 'string') {
    output = Number(formattedNumber.replace(/[^\d.-]/g, ''));
    output = Number.isNaN(output) ? 0 : output;
  };
  return output;
};