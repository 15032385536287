import React from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Spin,
  Typography,
} from 'antd';
import { BorderOutlined } from '@ant-design/icons';

import { AccountSelectionGS, EmptyImage, PlatformAvatar } from '../../../components/atoms';
import Chart from '../../../components/Chart/Chart.component';

import stringConstants from '../../../constants-v1/string.constants';

import { formatBigcur, formatBig, formatPercent } from '../../../utils-v1/number.utils';
import { renderArrow, renderPercentText } from '../../../utils-v1/summary-page.utils';

import config from './MetaPanel.component.config';
// import './MetaPanel.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const MetaPanel = ({
  accountSelectionProps,
  availableAccounts,
  history,
  loading,
  platformProps,
  ...props
}) => {
  const { accounts, daterange } = platformProps;
  const [purchaseValue, roas, cpcAll] = [{}, {}, {}];

  const accountTypes = ['conversion', 'collaborative', 'lead', 'messages'];
  accountTypes.forEach(accountType => {
    purchaseValue[accountType] = accounts?.filter(account => account?.details?.type === accountType);
    purchaseValue[accountType] = purchaseValue[accountType]?.length > 0 ? purchaseValue[accountType]
      ?.map(account => account?.account_id)
      ?.map(id => platformProps?.data?.data?.[id]?.current?.purchase_value)
      ?.reduce((v,t) => v+t) : 0;
    roas[accountType] = accounts?.filter(account => account?.details?.type === accountType);
    roas[accountType] = roas[accountType]?.length > 0 ? roas[accountType]
      ?.map(account => account?.account_id)
      ?.map(id => platformProps?.data?.data?.[id]?.current?.roas)
      ?.reduce((v,t) => v+t) : 0;
    cpcAll[accountType] = accounts?.filter(account => account?.details?.type === accountType);
    cpcAll[accountType] = cpcAll[accountType]?.length > 0 ? cpcAll[accountType]
      ?.map(account => account?.account_id)
      ?.map(id => platformProps?.data?.data?.[id]?.current?.cpc_all)
      ?.reduce((v,t) => v+t) : 0;
  });

  const { data } = platformProps?.data;
  const vsInterval = platformProps?.daterange?.preset_range_compare || '-';

  const renderEmptyContent = () => (
    <div className="empty-content meta">
      {loading ? <Paragraph style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph> : <>
        <EmptyImage platform="meta" width={150} />
        <br />
        <>{availableAccounts?.length === 0 ? <>
          <Text className="line-1">You have not bind your {stringConstants.meta.name} accounts yet</Text>
          <br />
          <Text className="line-2">You have to bind your account first to fully access BDD.AI</Text>
        </> : null}</>
        <>{availableAccounts?.length > 0 && platformProps?.accounts?.length === 0 ? <>
          <Text className="line-1">You have not selected accounts yet</Text>
          <br />
          <Text className="line-2">Please select a {stringConstants.meta.name} account</Text>
        </>: null}</>
        <br /><br />
        <Button type="primary" className="bind-button" onClick={() => history.push('/binding-meta')} hidden={availableAccounts?.length > 0}>
          Bind {stringConstants.meta.name} Account
        </Button>
      </>}
    </div>
  );

  const getInvolvedAccountTypes = () => [ ...new Set(accounts?.map(account => account?.details?.type)) ];
  const renderLegendPanel = () => (
    <Col className="chart-legend" sm={24} md={24} lg={24} style={{ position: 'relative', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
      {getInvolvedAccountTypes()?.map(accountType => {
        let renderLegend = '';
        switch (accountType) {
          case 'conversion':
            renderLegend = (
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10, minWidth: 55 }}>
                <BorderOutlined style={{ backgroundColor: '#59BC7D', color: 'transparent', height: 15, width: 15, borderRadius: 5 }} />
                <Text style={{ color: '#54575c', marginLeft: 5, fontSize: 14 }}>Ads Reg</Text>
              </span>
            );
            break;
          case 'collaborative':
            renderLegend = (
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10, minWidth: 55 }}>
                <BorderOutlined style={{ backgroundColor: '#018FFC', color: 'transparent', height: 15, width: 15, borderRadius: 5 }} />
                <Text style={{ color: '#54575c', marginLeft: 5, fontSize: 14 }}>CPAS</Text>
              </span>
            );
            break;
          case 'lead':
            renderLegend = (
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10, minWidth: 55 }}>
                <BorderOutlined style={{ backgroundColor: '#F5BD00', color: 'transparent', height: 15, width: 15, borderRadius: 5 }} />
                <Text style={{ color: '#54575c', marginLeft: 5, fontSize: 14 }}>Lead</Text>
              </span>
            );
            break;
          case 'messages':
            renderLegend = (
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: 10, minWidth: 55 }}>
                <BorderOutlined style={{ backgroundColor: '#FF683A', color: 'transparent', height: 15, width: 15, borderRadius: 5 }} />
                <Text style={{ color: '#54575c', marginLeft: 5, fontSize: 14 }}>Messages</Text>
              </span>
            );
            break;

          default:
            break;
        }
        return renderLegend;
      })}
    </Col>
  );

  const renderFilledContent = () => (<>
    <Row className="filled-content meta">
      <Col className="filled-col radius-top-left" sm={24} md={12} lg={12}>
        <Paragraph className='title-metric'>Total Spend</Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBigcur(data?.total?.current?.spend)}</Text>
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBigcur(data?.total?.previous?.spend)}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(data?.total?.percentage?.spend)}
          {renderPercentText(data?.total?.percentage?.spend)}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
      <Col className="filled-col radius-top-right" sm={24} md={12} lg={12}>
        <Paragraph className='title-metric'>Total Impression</Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBig(data?.total?.current?.impressions)}</Text>
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBig(data?.total?.previous?.impressions)}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(data?.total?.percentage?.impressions)}
          {renderPercentText(data?.total?.percentage?.impressions)}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
      <Col className="filled-col chart-section radius-bottom-left" sm={24} md={12} lg={8}>
        <Row>
          <Col sm={24} md={12} lg={17}>
            <Paragraph className='title-metric'>Total Purchase Value</Paragraph>
            <Paragraph>
              <Text className="summary-current-value">{formatBigcur(data?.total?.current?.purchase_value)}</Text>
              <br />
              <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBigcur(data?.total?.previous?.purchase_value)}</Text>
            </Paragraph>
          </Col>
          <Col sm={24} md={12} lg={7} style={{ textAlign: 'right' }}>
            <div /* style={{ height: 'fit-content', position: 'absolute', top: '25%' }} */>
              <Chart.Donut
                plain
                data={[
                  { label: 'Ads Conversion', value: purchaseValue?.conversion, color: '#59BC7D' },
                  { label: 'Ads CPAS', value: purchaseValue?.collaborative, color: '#018FFC' },
                  { label: 'Ads Lead', value: purchaseValue?.lead, color: '#F5BD00' },
                  { label: 'Ads Messages', value: purchaseValue?.messages, color: '#FF683A', },
                ]}
                size={100}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ position: 'relative' }}>
          <Col sm={24} md={12} lg={12}>
            <Paragraph style={!daterange.use_compare && { visibility: 'hidden' }}>
              {renderArrow(data?.total?.percentage?.purchase_value)}
              {renderPercentText(data?.total?.percentage?.purchase_value)}
              <Text className="summary-percentage-value"> vs {vsInterval}</Text>
            </Paragraph>
          </Col>
          <Col sm={24} md={12} lg={12} style={{ textAlign: 'right' }}>
            {renderLegendPanel()}
          </Col>
        </Row>
      </Col>
      <Col className="filled-col chart-section" sm={24} md={12} lg={8}>
        <Row>
          <Col sm={24} md={12} lg={17}>
            <Paragraph className='title-metric'>ROAS</Paragraph>
            <Paragraph>
              <Text className="summary-current-value">{formatBig(data?.total?.current?.roas, 'flt')}</Text>
              <br />
              <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBig(data?.total?.previous?.roas, 'flt')}</Text>
            </Paragraph>
          </Col>
          <Col sm={24} md={12} lg={7} style={{ textAlign: 'right' }}>
            <div /* style={{ height: 'fit-content', position: 'absolute', top: '25%' }} */>
              <Chart.Donut
                plain
                data={[
                  { label: 'Ads Conversion', value: roas?.conversion, color: '#59BC7D' },
                  { label: 'Ads CPAS', value: roas?.collaborative, color: '#018FFC' },
                  { label: 'Ads Lead', value: roas?.lead, color: '#F5BD00' },
                  { label: 'Ads Messages', value: roas?.messages, color: '#FF683A', },
                ]}
                size={100}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ position: 'relative' }}>
          <Col sm={24} md={12} lg={12}>
            <Paragraph style={!daterange.use_compare && { visibility: 'hidden' }}>
              {renderArrow(data?.total?.percentage?.roas)}
              {renderPercentText(data?.total?.percentage?.roas)}
              <Text className="summary-percentage-value"> vs {vsInterval}</Text>
            </Paragraph>
          </Col>
          <Col sm={24} md={12} lg={12} style={{ textAlign: 'right' }}>
            {renderLegendPanel()}
          </Col>
        </Row>
      </Col>
      <Col className="filled-col chart-section radius-bottom-right" sm={24} md={12} lg={8}>
        <Row>
          <Col sm={24} md={12} lg={17}>
            <Paragraph className='title-metric'>Total CPC</Paragraph>
            <Paragraph>
              <Text className="summary-current-value">{formatBigcur(data?.total?.current?.cpc_all)}</Text>
              <br />
              <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBigcur(data?.total?.previous?.cpc_all)}</Text>
            </Paragraph>
          </Col>
          <Col sm={24} md={12} lg={7} style={{ textAlign: 'right' }}>
            <div /* style={{ height: 'fit-content', position: 'absolute', top: '25%' }} */>
              <Chart.Donut
                plain
                data={[
                  { label: 'Ads Conversion', value: cpcAll?.conversion, color: '#59BC7D' },
                  { label: 'Ads CPAS', value: cpcAll?.collaborative, color: '#018FFC' },
                  { label: 'Ads Lead', value: cpcAll?.lead, color: '#F5BD00' },
                  { label: 'Ads Messages', value: cpcAll?.messages, color: '#FF683A', },
                ]}
                size={100}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ position: 'relative' }}>
          <Col sm={24} md={12} lg={12}>
            <Paragraph style={!daterange.use_compare && { visibility: 'hidden' }}>
              {renderArrow(data?.total?.percentage?.cpc_all, true)}
              {renderPercentText(data?.total?.percentage?.cpc_all, true)}
              <Text className="summary-percentage-value"> vs {vsInterval}</Text>
            </Paragraph>
          </Col>
          <Col sm={24} md={12} lg={12} style={{ textAlign: 'right' }}>
            {renderLegendPanel()}
          </Col>
        </Row>
      </Col>
    </Row>
  </>);

  const renderEmptyCondition = availableAccounts?.length === 0 || platformProps?.accounts?.length === 0 || loading;
  const hiddenAccountSelectionGS = () => {
    let hiddenValue = true;
    if (props.coachmark_local.summary === 1 && props.onBoarding.coachmark_status.summary === 1) {
      hiddenValue = false;
    } else if (availableAccounts?.length === 0) {
      hiddenValue = true;
    } else {
      hiddenValue = false;
    }
    return hiddenValue;
  };
  return(
    <div id="metaPanel">
      <div className="platform-title-summary">
        <div className="platform-title">
          <PlatformAvatar platform="meta" width={32} />
          <Text>Meta</Text>
        </div>
        <div hidden={hiddenAccountSelectionGS()}>
          <AccountSelectionGS { ...accountSelectionProps } />
        </div>
      </div>
      <div>
        {renderEmptyCondition ? renderEmptyContent() : renderFilledContent()}
      </div>
    </div>
  )
}

MetaPanel.displayName = config.displayName;
MetaPanel.propTypes = config.propTypes;
MetaPanel.defaultProps = config.defaultProps;

export default MetaPanel;

