import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './reducers/auth/__combined.reducer';
import binding from './reducers/binding/__combined.reducer';
import dashboard from './reducers/dashboard/__combined.reducer';
import user from './reducers/user/__combined.reducer';

const persistConfig = {
  dashboard: { key: 'dashboard', storage },
  user: { key: 'user', storage },
};

const moduleState = {
  auth,
  binding,
  // dashboard,
  // user,
  dashboard: persistReducer(persistConfig.dashboard, dashboard),
  user: persistReducer(persistConfig.user, user),
};

export default combineReducers(moduleState);