import React, { useEffect } from "react";
import {
  Form,
  Select,
  Input,
} from "antd";

import config from './FormWebsite.component.config';

const { Option } = Select;

const FormWebsite = ({ refWebsite, ...props }) => (
  <div id="formWebsite">
    <Form
      ref={refWebsite}
      layout="vertical"
      onFinish={(values) => {
        const updatedAccount = {
          account_id: values.account_id,
          account_name: values.account_name,
          details: {
            type: values.type,
            web_api_key: values.web_api_key,
            web_api_password: values.web_api_password
          },
        };
        console.log(updatedAccount);
      }}
    >
      <Form.Item
        label="Website Category"
        name="type"
        rules={[
          { required: true },
        ]}
      >
        <Select placeholder="website category">
          <Option value="shopify">Shopify</Option>
          <Option value="presta_shop">Presta Shop</Option>
          <Option value="woo_commerce">Woo Commerce</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Website Name"
        name="account_name"
        rules={[
          { required: true },
        ]}
      >
        <Input placeholder="type here..." />
      </Form.Item>
      <Form.Item
        label="Website Link Address"
        name="account_id"
        rules={[
          { required: true },
        ]}
      >
        <Input placeholder="type here..." />
      </Form.Item>
      <Form.Item
        label="Website API Key"
        name="web_api_key"
        rules={[
          { required: true },
        ]}
      >
        <Input placeholder="type here..." />
      </Form.Item>
      <Form.Item
        label="Website API Password"
        name="web_api_password"
        rules={[
          { required: true },
        ]}
      >
        <Input.Password placeholder="type here..." />
      </Form.Item>
    </Form>
  </div>
);

FormWebsite.displayName = config.displayName;
FormWebsite.propTypes = config.propTypes;
FormWebsite.defaultProps = config.defaultProps;

export default FormWebsite;
