import React from 'react';
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Moment from 'moment';
import { EMPTY_BUDGET_ACCOUNT_SELECTION_IMAGE } from '../../../assets-v1/images';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_META,
  AVATAR_TIKTOK,
  CHECK_PLAIN,
  CHECK_SELECTED_ACCOUNT,
  ICON_CALENDAR,
  ICON_TRASH,
} from '../../../assets-v1/figma-icons';

import AccountSelectionBudgetTracker from '../AccountSelectionBudgetTracker/AccountSelectionBudgetTracker.component';

import dateConstants from '../../../constants-v1/date.constants';
import { dateToDateString, formatDateDisplay } from '../../../utils-v1/daterange.utils';
import { formatInteger, unformatNumber } from '../../../utils-v1/number.utils';

import config from './CreateBudget.component.config';
import './CreateBudget.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { TODAY } = dateConstants;
const today = TODAY.STRING

const CreateBudget = ({
  accountsByPlatform = {},
  defaultSetup = {},
  onCreateBudget = (params) => console.log(params),
}) => {
  const [payloadState, setPayloadState] = React.useState({
    brandName: defaultSetup?.brand_name || '',
    week_groupings: defaultSetup?.week_groupings || 0,
    date_start: defaultSetup?.date_start || '',
    date_end: defaultSetup?.date_end || '',
    accounts: defaultSetup?.accounts || [],
  });
  const [state, setState] = React.useState({
    isStepOneComplete: !!defaultSetup?.brand_name || false,
    isStepTwoComplete: !!(defaultSetup?.date_start && defaultSetup?.date_end) || false,
    isStepThreeComplete: !!defaultSetup?.accounts || false,
  });

  const flattenedAccountList = [
    ...accountsByPlatform?.googleAds,
    ...accountsByPlatform?.meta,
    ...accountsByPlatform?.tiktok,
  ];

  const getAccountSelectionProps = () => ({
    accountsByPlatform,
    onSelectAccount: (account={}) => {
      const { accounts } = payloadState;
      if (!accounts?.map(el => el?.account_id)?.includes(account?.account_id)) {
        const newAccounts = [ ...accounts, { ...account, budget: 0 }];
        setPayloadState({ ...payloadState, accounts: newAccounts });
        setState({ ...state, isStepThreeComplete: false });
      };
    },
    selectedAccounts: payloadState.accounts,
    children: <Button id="addPlatformAndAccountBtn">+ Add More Platform & Account</Button>,
  });

  const renderBrandNamePanel = () => (
    <div id="BrandNamePanel" className="panel-wrapper" onMouseDown={() => setState({ ...state, isStepOneComplete: false })}>
      <Paragraph id="BrandNameTitle" className="panel-title">
        <span className="check-step-wrapper" hidden={!state.isStepOneComplete}>
          <Image src={CHECK_SELECTED_ACCOUNT} alt="check_step" preview={false} width={32} />
        </span>
        <Avatar hidden={state.isStepOneComplete} className="number-avatar">1</Avatar>
        <span className="step-copy">
          {state.isStepOneComplete ? 'Brand Name Created' : 'Enter Brand Name'}
        </span>
      </Paragraph>
      <Text className="brand-name-top-text">Brand Name</Text>
      <Input
        defaultValue={payloadState.brandName}
        prefix={<Image hidden={!state.isStepOneComplete} src={CHECK_PLAIN} alt="check_prefix" preview={false} style={{ paddingLeft: 10 }} />}
        onChange={(e) => {
          setPayloadState({ ...payloadState, brandName: e.target.value });
        }}
        className={state.isStepOneComplete ? 'input-step-one-complete' : 'input-step-one-incomplete'}
      />
      <div className="panel-btn">
        <Button
          disabled={!payloadState.brandName}
          hidden={state.isStepOneComplete}
          type="primary"
          onClick={() => setState({ ...state, isStepOneComplete: true })}
        >Select date range period & week groupings</Button>
      </div>
    </div>
  );

  const renderDaterangePanel = () => (
    <div id="DaterangePanel" className="panel-wrapper">
      <Paragraph id="DaterangeTitle" className="panel-title">
        <span className="check-step-wrapper" hidden={!state.isStepTwoComplete}>
          <Image src={CHECK_SELECTED_ACCOUNT} alt="check_step" preview={false} width={32} />
        </span>
        <Avatar hidden={state.isStepTwoComplete} className="number-avatar">2</Avatar>
        <span className="step-copy">
          {state.isStepTwoComplete ? 'Date Range & Week Groupings Selected' : 'Select Date Range & Week Groupings'}
        </span>
      </Paragraph>
      <div hidden={!state.isStepOneComplete}>
        <DatePicker.RangePicker
          className="budget-range-picker"
          defaultValue={[
            payloadState?.date_start ? Moment(payloadState?.date_start) : null,
            payloadState?.date_end ? Moment(payloadState?.date_end) : null,
          ]}
          onChange={(dates=[], dateStrings=[]) => {
            const datePayload = dates?.map(date => date?.format('YYYY-MM-DD'));
            setPayloadState({ ...payloadState, date_start: datePayload[0], date_end: datePayload[1] });
            setState({ ...state, isStepTwoComplete: true });
          }}
          onMouseDown={() => setState({ ...state, isStepTwoComplete: false })}
          placeholder={['date start', 'date end']}
          separator="-"
          picker="date"
          prefixIcon={<Image hidden={!state.isStepTwoComplete} src={CHECK_PLAIN} alt="check_prefix" preview={false} style={{ paddingLeft: 10 }} />}
          suffixIcon={<Image src={ICON_CALENDAR} alt="icon-calendar" preview={false} width={15} />}
          popupClassName="budget-range-picker-popover"
          format={(value) => `${value.format('DD MMM YYYY')}`}
        />
        <Paragraph style={{ fontSize: 16, marginLeft: 15, marginTop: 15 }} hidden={!state.isStepTwoComplete || !payloadState.date_start || !payloadState.date_end}>
          Week Groupings:<br />
          <Radio.Group
            defaultValue={payloadState?.week_groupings || 0}
            onChange={(e) => {
              // console.log(e)
              setPayloadState({ ...payloadState, week_groupings: e.target.value })
            }}
          >
            <Space direction="vertical">
              <Radio value={0}>Calendar Week (Monday - Sunday)</Radio>
              <Radio value={1}>Date-Based Week (The first week is calculated from the 1st-7th of each month respectively)</Radio>
            </Space>
          </Radio.Group><br />
          <Paragraph style={{ color: '#7A7A7A', fontSize: 12, marginTop: 10 }}>
            <InfoCircleOutlined width={50} style={{ color: '#7A7A7A' }} />
            <i><span style={{ fontWeight: 600, marginLeft: 10 }}>
              You must select your preferred week groupings. This allows you to oversee the budget spent on each week based on your preference.
            </span></i>
          </Paragraph>
        </Paragraph>
      </div>
    </div>
  )

  const renderAccountSelectionPanel = () => (
    <div id="AccountSelectionPanel" className="panel-wrapper">
      <Paragraph id="AccountSelectionTitle" className="panel-title">
        <span className="check-step-wrapper" hidden={!state.isStepThreeComplete}>
          <Image src={CHECK_SELECTED_ACCOUNT} alt="check_step" preview={false} width={32} />
        </span>
        <Avatar hidden={state.isStepThreeComplete} className="number-avatar">3</Avatar>
        <span className="step-copy">
          {state.isStepThreeComplete ? 'Platform & Account Selected' : 'Select Platform & Account'}
        </span>
      </Paragraph>
      <div hidden={!state.isStepOneComplete || !state.isStepTwoComplete}>
        {renderEmptySelectedAccounts()}
        {renderFilledSelectedAccounts()}
      </div>
    </div>
  );

  const renderEmptySelectedAccounts = () => (
    <div hidden={payloadState.accounts.length > 0} className="empty-budget-selected-accounts">
      <Image src={EMPTY_BUDGET_ACCOUNT_SELECTION_IMAGE} alt="empty_budget_selection_account" preview={false} />
      <Paragraph className="empty-title">Please Select Platform and Account</Paragraph>
      <Paragraph>
        You can monitor the budget spending in this place.<br />
        Please add clients and create their budget first.
      </Paragraph>
      <AccountSelectionBudgetTracker { ...getAccountSelectionProps() } />
    </div>
  );

  const renderFilledSelectedAccounts = () => (
    <div hidden={payloadState.accounts.length === 0} className="filled-budget-selected-accounts">
      <Text style={{ color: '#9CA0A6' }}>Selected Accounts ({payloadState.accounts.length})</Text>
      <Row gutter={[12, 12]}>
        {payloadState.accounts.map(el => <Col sm={24} md={24} lg={24} key={el?.account_id}>
          {renderSelectedAccountItem(el)}
        </Col>)}
      </Row>
      <div style={{ textAlign: 'center' }}>
        <AccountSelectionBudgetTracker { ...getAccountSelectionProps() } />
      </div>
    </div>
  );

  const renderSelectedAccountItem = (account={}) => (
    <Card className="selected-account-item-card">
      <Row gutter={[12, 12]} style={{ marginBottom: 15 }}>
        <Col sm={20} md={20} lg={20}>
          <Image
            src={
              (account?.platform === 'meta' && AVATAR_META) ||
              (account?.platform === 'tiktok' && AVATAR_TIKTOK) ||
              (account?.platform === 'googleAds' && AVATAR_GOOGLE_ADS) || null
            }
            alt="platform_avatar"
            preview={false}
            width={25}
          />&nbsp;&nbsp;{account?.account_name}
        </Col>
        <Col sm={4} md={4} lg={4} style={{ textAlign: 'right' }}>
          <Image
            src={ICON_TRASH}
            alt="remove_account"
            onClick={() => {
              const { accounts } = payloadState;
              const newAccounts = accounts.filter(el => el?.account_id !== account?.account_id);
              setPayloadState({ ...payloadState, accounts: newAccounts });
            }}
            preview={false}
            style={{ cursor: 'pointer' }}
          />
        </Col>
      </Row>
      <InputNumber
        className="selected-account-budget-input"
        addonBefore={flattenedAccountList.find(act => act?.account_id === account?.account_id)?.details?.currency || 'IDR'}
        defaultValue={account?.budget}
        formatter={(value) => formatInteger(Number(value))}
        onChange={(value) => {
          // payloadState update
          const { accounts } = payloadState;
          const index = accounts.map(el => el?.account_id).indexOf(account?.account_id);
          const budgetedAccount = accounts?.[index];
          budgetedAccount.budget = Number(value);
          accounts.splice(index, 1, budgetedAccount);
          setPayloadState({ ...payloadState, accounts });
          // state update
          const isStepThreeComplete = accounts.map(el => el?.budget)
            ?.map(el => typeof el === 'number' && el > 0)?.reduce((s, e) => s && e);
          setState({ ...state, isStepThreeComplete });
        }}
      />
    </Card>
  );

  return(
    <div id="CreateBudgeting">
      <Paragraph className="main-title">Create Budgeting</Paragraph>
      {renderBrandNamePanel()}
      {renderDaterangePanel()}
      {renderAccountSelectionPanel()}
      <div className="check-accounts-again-text"></div>
      <Button
        id="createBudgetBtn"
        hidden={!state.isStepOneComplete}
        disabled={!state.isStepThreeComplete}
        type="primary"
        size="large"
        onClick={() => {
          const reqParams = {
            id: defaultSetup?.id || 0,
            brand_name: payloadState.brandName,
            week_groupings: payloadState.week_groupings,
            date_start: payloadState.date_start,
            date_end: payloadState.date_end,
            accounts: payloadState.accounts.map(acct => ({
              account_id: acct?.account_id,
              account_name: acct?.account_name,
              budget: acct?.budget,
            })),
          };
          // console.log({ reqParams })
          onCreateBudget(reqParams);
        }}
      >Create Budget</Button>
    </div>
  )
}

CreateBudget.displayName = config.displayName;
CreateBudget.propTypes = config.propTypes;
CreateBudget.defaultProps = config.defaultProps;

export default CreateBudget;