import React from 'react';
import {
  Button,
  Cascader,
  Dropdown,
  Image,
  Input,
  Popover,
  Radio,
  Space,
  Tag,
  Typography,
  Upload,
} from 'antd';
import {
  DownOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_META,
  AVATAR_TIKTOK,
  CHECK_PLAIN,
  CHECK_SELECTED_ACCOUNT,
  ICON_CALENDAR,
  ICON_TRASH,
  ICON_CR_BLOCKS_TEMPLATE_OPTION,
  ICON_CR_PLUS_BLANK_OPTION,
  CHECKED_VISUALIZATION as CHECKED_DOCUMENT_TYPE,
  CHECKED_VISUALIZATION_PLACEHOLDER as CHECKED_DOCUMENT_TYPE_PLACEHOLDER,
} from '../../../assets-v1/figma-icons';
import { capsEveryWordStart } from '../../../utils-v1/string.utils';
import { uploadFileToS3Bucket } from '../../../services/aws.service';

import AccountSelectionCreateReport from '../AccountSelectionCreateReport/AccountSelectionCreateReport.component';

import config from './CreateReportConfigForm.component.config';
import './CreateReportConfigForm.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const documentTypes = [
  {
    type: 'blank',
    icon: ICON_CR_PLUS_BLANK_OPTION,
    title: 'Blank Report',
    subtitle: 'Start from scratch',
  },
  {
    type: 'template',
    icon: ICON_CR_BLOCKS_TEMPLATE_OPTION,
    title: 'Template Report',
    subtitle: 'Using template by system',
  },
];

const CreateReportConfigForm = ({
  accountsByPlatform = {},
  defaultSetup = {},
  onCreateReport = (params={}) => console.log(params),
  ...props
}) => {
  const [payloadState, setPayloadState] = React.useState({
    report_name: defaultSetup?.report_name || '',
    accounts: defaultSetup?.accounts || [],
    document_type: defaultSetup.document_type || '',
    cover_url: '',
  });
  const [auxState, setAuxState] = React.useState({ fileList: [] });

  const getAccountSelectionProps = () => ({
    accountsByPlatform,
    onSelectAccount: (account={}) => {
      const { accounts } = payloadState;
      let newAccounts = [...accounts];
      const matchIndex = accounts.findIndex(el => el?.account_id === account?.account_id);
      if (matchIndex === -1) {
        newAccounts = [ ...accounts, { ...account }];
      } else {
        newAccounts = newAccounts.filter(el => el?.account_id !== account?.account_id);
      };
      setPayloadState({ ...payloadState, accounts: newAccounts });
    },
    selectedAccounts: payloadState.accounts,
    children: <Button id="addPlatformAndAccountBtn" style={{ height: 40, textAlign: 'left', width: '100%' }}>
      {payloadState.accounts.length === 0 ? <span style={{ color: '#BABABA' }}>+ Choose ad account...</span> : null}
      {payloadState.accounts.length > 0 ? <span>+ Add more ad account</span> : null}
      <span style={{ float: 'right' }}><DownOutlined style={{ color: '#BABABA' }} /></span>
    </Button>,
    placement: 'bottom',
  });

  const renderSelectedAccountItem = (account={}) => (
    <span style={{ fontSize: 14, lineHeight: 3 }}>
      <Image
        src={
          (account?.platform === 'meta' && AVATAR_META) ||
          (account?.platform === 'tiktok' && AVATAR_TIKTOK) ||
          (account?.platform === 'googleAds' && AVATAR_GOOGLE_ADS) || null
        }
        alt="platform_avatar"
        preview={false}
        width={25}
      />&nbsp;&nbsp;{account?.account_name}&nbsp;&nbsp;
    </span>
  );

  const constructUploadCoverImageProps = () => ({
    name: 'files',
    listType: 'picture',
    customRequest: async(upload) => uploadFileToS3Bucket(
      'cover_images',
      upload,
      (tempData={}, originKey='', objectURL='') => {
        setAuxState((prevAuxState) => {
          const currentAuxState = { ...prevAuxState };
          currentAuxState.fileList = [];
          currentAuxState.fileList.push({ ...tempData, uid: originKey, url: objectURL, status: 'done' });
          return { ...currentAuxState };
        });
        setPayloadState((prevPayloadState) => {
          const currentPayloadState = { ...prevPayloadState };
          currentPayloadState.cover_url = objectURL;
          return { ...currentPayloadState };
        });
      },
    ),
    fileList: auxState.fileList,
    maxCount: 1,
    onChange: (e) => {
      setAuxState({ ...auxState, fileList: e?.fileList });
    },
  });

  return(
    <div id="CreateReportConfigForm" /* onMouseEnter={() => console.log({ payloadState,auxState })} */ >
      <Paragraph className="form-field-name">Report Name</Paragraph>
      <Input
        defaultValue={payloadState.report_name}
        onChange={(e) => {
          setPayloadState({ ...payloadState, report_name: e.target.value });
        }}
      />
      <Paragraph className="form-field-name">Cover Image (<i>optional</i>)</Paragraph>
      <Upload.Dragger { ...constructUploadCoverImageProps() }>
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload.Dragger>
      <Paragraph className="form-field-name">Platform & Ad Account</Paragraph>
      <div style={payloadState.accounts.length > 0 && { marginBottom: 10, marginTop: 10 } || {}}>
        <Space size={[0, 'small']} style={payloadState.accounts.length === 0 && { display: 'none' } || {}} wrap>
          {payloadState.accounts.map((el, i) => <Tag
            className="selected-account-tag"
            closable 
            key={el?.account_id}
            onClose={() => {
              const { accounts } = payloadState;
              const newAccounts = accounts.filter(acct => acct?.account_id !== el?.account_id);
              setPayloadState({ ...payloadState, accounts: newAccounts });
            }}
            style={i > 1 && { display: 'none' } || { borderRadius: 30 }}
          >{renderSelectedAccountItem(el)}</Tag>)}
          <Tag
            className="selected-account-tag"
            key="more_selected_accounts"
            style={payloadState.accounts.length < 3 && { display: 'none' } || { borderRadius: 30, fontSize: 14, lineHeight: 3 }}
          ><div style={{ textAlign: 'center', width: 30 }}>+{payloadState.accounts.length-2}</div></Tag>
        </Space>
      </div>
      <AccountSelectionCreateReport { ...getAccountSelectionProps() } />
      <Paragraph className="form-field-name">How would you like to start?</Paragraph>
      <Radio.Group
        defaultValue={payloadState.document_type}
        onChange={(e) => setPayloadState({ ...payloadState, document_type: e.target.value })}
        value={payloadState.document_type}
      >
        <Space direction="horizontal" wrap>
          {documentTypes.map((documentType, index) => (
            <Radio className="document-type-radio" value={documentType.type}>
              <div
                className={documentType.type === payloadState.document_type ? `document-type-selected` : `document-type`}
              >
                {documentType.type === payloadState.document_type ?
                  <CHECKED_DOCUMENT_TYPE className="checked-document-type" /> :
                  <CHECKED_DOCUMENT_TYPE_PLACEHOLDER className="checked-document-type" />
                }
                <documentType.icon
                  className={documentType.type === payloadState.document_type ? `document-type-icon-selected` : `document-type-icon`}
                />
              </div>
              <div className="document-type-copytext">
                <Paragraph className="document-type-title">{documentType.title}</Paragraph>
                <Paragraph className="document-type-subtitle">{documentType.subtitle}</Paragraph>
              </div>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <div style={{ marginTop: 25 }}>
        <Button
          id="CreateReportBtn"
          type="primary"
          size="large"
          onClick={() => {
            // additional params generation
            const platforms = ['googleAds', 'meta', 'tiktok'];
            const accountsGroupedByPlatform = {};
            for (let i=0; i<platforms.length; i+=1) {
              const platform = platforms[i];
              accountsGroupedByPlatform[platform] = payloadState.accounts.filter(acct => acct.platform === platform);
            };
            const accountIds = payloadState.accounts.map(acct => acct.account_id);
            const reqParams = {
              id: defaultSetup?.id || null,
              account_ids: accountIds,
              accountsByPlatform: accountsGroupedByPlatform,
              ...payloadState,
            };
            onCreateReport(reqParams);
          }}
        >Create Report</Button>
      </div>
    </div>
  )
}

CreateReportConfigForm.displayName = config.displayName;
CreateReportConfigForm.propTypes = config.propTypes;
CreateReportConfigForm.defaultProps = config.defaultProps;

export default CreateReportConfigForm;