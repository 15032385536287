import React from 'react';
import { Button, Card, Col, Image, Input, List, Modal, notification, Popover, Row, Select, Space, Typography } from 'antd';
import { SearchOutlined, RightOutlined, CloseOutlined } from '@ant-design/icons';
import {
  CHECK_SELECTED_ACCOUNT,
  CHECK_SELECTED_ACCOUNT_PLACEHOLDER,
  ICON_PLUS_ACCOUNT_SELECTION,
  ICON_TRASH,
} from '../../../assets-v1/figma-icons';
import { bddColor } from '../../../utils-v1/colors.utils';
import { doesStrIncludeSubstr } from '../../../utils-v1/string.utils';
import stringConstants from '../../../constants-v1/string.constants';

import config from './AccountSelection.component.config';
import './AccountSelection.component.styles.less';

const { Paragraph, Title, Text } = Typography;
const { BORDER_GREEN, BLUE_MAIN, THIN_BLUE, THIN_GREEN, WHITE } = bddColor;
const platformsWithSyncStatus = [
  stringConstants.tiktok.key,
  stringConstants.marketplace.key,
  stringConstants.meta.key,
  stringConstants.website.key,
];

const AccountSelection = ({
  accountsByPlatform = { googleAds: [], googleAnalytics: [], instagram: [], marketplace: [], meta: [], tiktok: [], website: [] },
  extra = null,
  defaultState = {
    selectedAccounts: [],
  },
  hidden = false,
  multiple = false,
  onApply = (account) => console.log(account),
  overlayClassName = '',
  platformKey = '',
  platformName = '',
  syncAccountData = () => Promise.resolve(),
  synchronizingAccounts = [],
}) => {
  const [state, setState] = React.useState({
    displayedSelectedAccounts: [ ...defaultState?.selectedAccounts ]?.filter(account => account?.config_id),
    searchInput: '',
    selectedAccounts: [ ...defaultState?.selectedAccounts ]?.filter(account => account?.config_id),
    open: false,
    modalSync: false,
    syncLoading: false,
  });
  const accountList = Array.isArray(accountsByPlatform?.[platformKey]) ? accountsByPlatform?.[platformKey] : [];

  React.useEffect(() => {
    // console.log({ defaultState })
    if([ ...defaultState?.selectedAccounts ]?.filter(account => account?.config_id)) {
      setState({
        ...state,
        displayedSelectedAccounts: defaultState?.selectedAccounts,
        selectedAccounts: defaultState?.selectedAccounts,
      });
    };
  }, [defaultState?.selectedAccounts]);

  const getPopoverContent = () => (
    <Card bordered={false} style={{ width: '30vw' }} extra={extra}>
      <div style={{ paddingBottom: 5, margin: '15px' }}>
        <Input
          prefix={<SearchOutlined style={{ color: '#A6AAB1', paddingLeft: 8, width: 20 }} />}
          onChange={(e) => setState({ ...state, searchInput: e.target.value })}
          placeholder="Search an ad account ..."
        />
      </div>
      <List
        bordered={false}
        dataSource={accountList.filter(el => doesStrIncludeSubstr(el?.account_name, state?.searchInput))}
        renderItem={(item) => (
          <List.Item
            style={{
              ...(stateFunctions.isAccountSelected(item) && { borderBottom: `1px solid ${BORDER_GREEN}` }),
              backgroundColor: stateFunctions.isAccountSelected(item) ? THIN_GREEN : WHITE,
              cursor: 'pointer',
              padding: 15,
            }}
            onClick={() => stateFunctions.addOrRemoveAccount(item)}
          >
            <div style={{ width: '100%' }}>
              <Row>
                <Col sm={4} md={4} lg={4}>
                  {stateFunctions.isAccountSelected(item) ?
                    <>{renderFunctions.renderCheckSign({ transform: 'scale(0.75)' })}</>
                    : <>{renderFunctions.renderCheckSignPlaceholder({ transform: 'scale(0.75)' })}</>
                  }
                </Col>
                <Col sm={20} md={20} lg={20}>
                  {renderFunctions.formatAccountListItem(item)}
                </Col>
              </Row>
            </div>
          </List.Item>
        )}
        style={{ maxHeight: 250, overflowY: 'scroll', padingTop: 5 }}
      />
    </Card>
  );

  const constructModalSyncProps = () => ({
    className: 'account-selection-sync-modal',
    title: <Title level={3} style={{ fontSize: 18, paddingBottom: 0 }}>{state.selectedAccounts[0]?.account_name}</Title>,
    children: getModalSyncContent(),
    onCancel: () => {
      setState({ ...state, modalSync: false });
    },
    cancelText: 'Wait 1x24H',
    onOk: () => {
      setState({ ...state, syncLoading: true });
      syncAccountData(synchronizingAccounts, state.selectedAccounts[0])
        .then(() => {
          notification.success({
            message: 'Data synchronization success',
            description: '',
          });
          setState({ ...state, modalSync: false, open: false, displayedSelectedAccounts: state?.selectedAccounts, syncLoading: false });
          if (onApply && typeof onApply === 'function') onApply(multiple ? state.selectedAccounts : state.selectedAccounts[0]);
        })
        .catch(() => {
          setState({ ...state, modalSync: false });
          notification.error({
            message: 'Data synchronization failed',
            description: 'Please try again later',
          })
        })
        .finally(() => {
          setState({ ...state, modalSync: false });
        });
    },
    okButtonProps: {
      loading: state.syncLoading,
    },
    okText: 'Sync Now',
    open: state.modalSync,
  });

  const getModalSyncContent = () => {
    const account = { ...state.selectedAccounts[0] };
    return(<Paragraph>
      This account needs to be synchronized. Do you want to sync now?
    </Paragraph>)
  };

  const constructModalProps = () => ({
    className: 'account-selection-modal',
    title: (
      <Title level={3} style={{ fontSize: 24, paddingBottom: 0 }}>
        {platformName} Accounts
      </Title>
    ),
    children: getModalContent(),
    closeIcon: <CloseOutlined
      style={{
        fontSize: 20,
        color: 'black',
        backgroundColor: '#D0D5DD',
        borderRadius: 15,
        padding: 5
      }}
    />,
    open: state?.open,
    onCancel: () => {
      setState({ ...state, open: false });
    },
    cancelButtonProps: {
      disabled: true,
      style: {
        display: 'none',
      }
    },
    onOk: () => {
      if (!multiple && (!state.selectedAccounts[0]?.details?.type || state.selectedAccounts[0]?.details?.type === 'general')) {
        if (window.location.pathname === '/dashboard' && state.selectedAccounts[0]?.platform === 'googleAds') {
          const selectedGoogleAdsAccount = { ...state.selectedAccounts[0], details: { ...state.selectedAccounts[0]?.details, type: 'general' } };
          setState({ ...state, open: false, displayedSelectedAccounts: [selectedGoogleAdsAccount] });
          if (onApply && typeof onApply === 'function') onApply(selectedGoogleAdsAccount);
        } else {
          notification.warn({
            message: 'Please select an account type first!'
          });
        }
      } else if (multiple && state.selectedAccounts.map(acc => !acc?.details?.type)?.includes(true)) {
        notification.warn({
          message: 'One or more accounts are without type. Please select an account type first'
        });
      } else if (!multiple && window.location.pathname !== '/dashboard' && platformsWithSyncStatus.includes(state.selectedAccounts[0]?.platform) && !state.selectedAccounts[0]?.details?.sync) {
        setState({ ...state, modalSync: true });
      } else {
        setState({ ...state, open: false, displayedSelectedAccounts: state?.selectedAccounts });
        if (onApply && typeof onApply === 'function') onApply(multiple ? state.selectedAccounts : state.selectedAccounts[0]);
      };
    },
    okText: 'Set Account',
    okButtonProps: {
      block: true,
      disabled: state.selectedAccounts?.length === 0,
      style: {
        margin: '15px 0',
        height: 40,
        borderRadius: 8
      },
    },
    style: { minWidth: '30vw' },
  });

  const getModalContent = () => {
    let modalContent;
    if (multiple) {
      modalContent = <>
        <div style={{ textAlign: 'center' }}>
          <Popover
            content={getPopoverContent()}
            trigger="click"
            overlayClassName={overlayClassName}
            placement="bottomLeft"
          >
            {multiple ? (
              <Button
                icon={<Image className="plus-icon-add-account" src={ICON_PLUS_ACCOUNT_SELECTION} alt="plus_icon" preview={false} />}
                style={{
                  backgroundColor: THIN_BLUE,
                  color: '#018FFC',
                  fontWeight: 600,
                  border: '1px dashed #018FFC',
                  borderRadius: 6,
                  width: '90%',
                  height: 44,
                }}
                type="ghost"
              >Add or Remove {platformName} Account</Button>
            ) : (
              <Text style={{ fontSize: '1.2rem', fontWeight: 600 }}>Select an Account</Text>
            )}
          </Popover>
        </div>
        {state?.selectedAccounts?.filter(account => account?.config_id)?.length > 0 ?
          <List
            key="list-multiple"
            id="list-multiple"
            bordered={false}
            dataSource={state?.selectedAccounts?.filter(account => account?.config_id)}
            renderItem={(item) => <List.Item key={item?.config_id}>
              <div style={{ width: '100%' }}>
                <Row>
                  <Col sm={3} md={3} lg={3}>
                    {renderFunctions.renderCheckSign()}
                  </Col>
                  <Col sm={19} md={19} lg={19}>
                    {renderFunctions.formatAccountListItemWithType(item)}
                  </Col>
                  <Col sm={2} md={2} lg={2}>
                    <div style={{ position: 'relative', height: 55 }}>
                      <div
                        style={{ position: 'absolute', bottom: 0, right: 0, cursor: 'pointer' }}
                        onClick={() => {
                          const newSelectedAccounts = state.selectedAccounts.filter(acc => acc.config_id !== item?.config_id);
                          setState({ ...state, selectedAccounts: newSelectedAccounts });
                        }}
                      >
                        <Image src={ICON_TRASH} alt="icon_trash" preview={false} width={25} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </List.Item>}
            style={{ height: '350px', overflowY: 'scroll', marginTop: 10 }}
          />
          :
          <div style={{ height: '350px', overflowY: 'scroll', marginTop: 10 }}>
            <Paragraph style={{ paddingTop: 25, paddingLeft: 30 }}>
              Choose {multiple ? `one or more ${platformName} accounts` : `${platformName} account`}
            </Paragraph>
          </div>
        }
      </>
    } else {
      modalContent = <>
        <div style={{ padding: '0 15px 5px 15px' }}>
          <Input
            onChange={(e) => setState({ ...state, searchInput: e.target.value })}
            prefix={<SearchOutlined style={{ color: '#A6AAB1', paddingLeft: 8, width: 20 }} />}
            placeholder="Search an ad account ..."
          />
        </div>
        <List
          key="list-single"
          id="list-single"
          bordered={false}
          dataSource={accountList.filter(el => doesStrIncludeSubstr(el?.account_name, state?.searchInput))}
          renderItem={(item) => (
            <List.Item
              key={item?.config_id}
              style={{
                ...(stateFunctions.isAccountSelected(item) && { borderBottom: `1px solid ${BORDER_GREEN}` }),
                backgroundColor: stateFunctions.isAccountSelected(item) ? THIN_GREEN : WHITE,
                cursor: 'pointer',
                padding: 5,
              }}
              onClick={() => stateFunctions.isAccountSelected(item) ? null : stateFunctions.addOrRemoveAccount(item)}
            >
              <div style={{ width: '100%' }}>
                <Row>
                  <Col sm={3} md={3} lg={3}>
                    {stateFunctions.isAccountSelected(item) ?
                      <>{renderFunctions.renderCheckSign({ transform: 'scale(0.75)' })}</>
                      : <>{renderFunctions.renderCheckSignPlaceholder({ transform: 'scale(0.75)' })}</>
                    }
                  </Col>
                  <Col sm={21} md={21} lg={21}>
                    {renderFunctions.formatAccountListItemWithType(item)}
                  </Col>
                </Row>
              </div>
            </List.Item>
          )}
          style={{ height: '350px', overflowY: 'scroll', paddingTop: 5 }}
        />
      </>
    };
    return modalContent;
  };

  const renderFunctions = {
    formatAccountListItem: (account={}) => (
      <Space direction="vertical" size={2} style={{ width: '100%' }}>
        <Title level={3} style={{ fontSize: 16 }}>{account?.account_name}</Title>
        <Text style={{ color: '#999999', fontSize: 14 }}>{account?.account_id}</Text>
      </Space>
    ),
    formatAccountListItemWithType: (account={}) => (
      <Space direction="vertical" size={2} style={{ width: '100%' }}>
        <Title level={3} style={{ fontSize: 16 }}>{account?.account_name}</Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Text style={{ color: '#999999', fontSize: 14 }}>{account?.account_id}</Text>
          <Select
            defaultValue={account?.details?.type}
            disabled={!stateFunctions.isAccountSelected(account) || (['marketplace', 'website'].includes(account?.platform))}
            hidden={!config.getAccountTypes()?.[account?.platform]}
            onChange={(value) => stateFunctions.replaceAccountType(account, value)}
            options={config.getAccountTypes()?.[account?.platform]}
            placeholder="Select an account type"
            style={{
              fontSize: 12,
              visibility: window?.location?.pathname === '/dashboard' && account?.platform === 'googleAds' ? 'hidden' : 'visible',
            }}
          />
        </div>
      </Space>
    ),
    renderCheckSign: (style={}) => (
      <Image src={CHECK_SELECTED_ACCOUNT} alt="check_selected_account" preview={false} style={{ width: 46, height: 46 }}  />
    ),
    renderCheckSignPlaceholder: (style={}) => (
      <Image src={CHECK_SELECTED_ACCOUNT_PLACEHOLDER} alt="check_selected_account_placeholder" preview={false} style={{ width: 46, height: 46 }} />
    ),
  };

  const stateFunctions = {
    addOrRemoveAccount: (account={}) => {
      const selectedAccountIDs = state?.selectedAccounts.map(el => el?.config_id);
      let newSelectedAccounts = [];
      if (selectedAccountIDs.includes(account?.config_id)) {
        newSelectedAccounts = state?.selectedAccounts?.filter(el => el?.config_id !== account?.config_id);
      } else {
        newSelectedAccounts = multiple ? [...state?.selectedAccounts, account] : [account];
      };
      setState({ ...state, selectedAccounts: newSelectedAccounts });
    },
    isAccountSelected: (account) => state?.selectedAccounts?.map(el => el?.config_id)?.includes(account?.config_id),
    replaceAccountType: (account={}, accountType='') => {
      if (account?.details?.type !== accountType) {
        const newSelectedAccounts = [...state?.selectedAccounts];
        const index = newSelectedAccounts?.map(el => el?.config_id)?.indexOf(account?.config_id);
        const match = newSelectedAccounts?.find(el => el?.config_id === account?.config_id);
        match.details.type = accountType;
        newSelectedAccounts[index] = match;
        setState({ ...state, selectedAccounts: newSelectedAccounts });
      };
    },
  };

  const renderComponent = () => (<>
    <Modal { ...constructModalSyncProps() } />
    <Modal { ...constructModalProps() } />
    <Button id="accountSelectionBtn" onClick={() => setState({ ...state, open: !state?.open })} hidden={hidden}>
      <div className="state-text-account">
        {
          state?.displayedSelectedAccounts?.[0]?.details?.type ? (
            config.getAccountTypes()?.[state?.displayedSelectedAccounts?.[0]?.platform]
              ?.find(e => e?.value === state?.displayedSelectedAccounts?.[0]?.details?.type)?.label
          ) : null
        }
        {state?.displayedSelectedAccounts?.[0]?.details?.type ? ' | ' : null }
        {state?.displayedSelectedAccounts?.[0]?.account_name || 'Account Selection'}
        &nbsp;
      </div>
      <RightOutlined />
    </Button>
  </>);

  return(<>{renderComponent()}</>);
};

AccountSelection.displayName = config.displayName;
AccountSelection.propTypes = config.propTypes;
AccountSelection.defaultProps = config.defaultProps;

export default AccountSelection;
