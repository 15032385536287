import TYPES from '../../types';
import api2 from '../../../services/api2.service';
import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';

const { ApiService2, setApi2ReqHeaders } = api2.getInstance();
const { BindingApiService } = apiBinding.getInstance();

const getTotalWithReduce = (_config={}, platform='', key='') => {
  const preOutput = (Array.isArray(_config?.accounts) && _config?.accounts || [{ platform, [key]: 0 }])
    .filter(acct => acct?.platform === platform)?.map(acct => acct?.[key] || 0);
  const output = [0, ...preOutput]?.reduce((s,e) => s+e);
  return output;
}

export const getBudgetConfigurations = () => async (dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_BUDGET_TRACKER_SAVED_CONFIGURATIONS, payload });
  try {
    const { status, data } = await ApiService2.get('budget-tracker/get-budget-configuration', { headers: setApi2ReqHeaders(getToken()) });
    // console.log({ getBudgetConfigurations: data });
    const formattedData = (Array.isArray(data?.data) && data?.data || []).map(config => ({
      ...config,
      accounts: (Array.isArray(config?.accounts) && config?.accounts || []).map(account => ({
        ...account,
        budget_id: config?.id,
      })),
      budget_id: config?.id,
      summary: {
        ...config.summary,
        budget_id: config?.id,
        platforms: [...new Set((Array.isArray(config?.accounts) && config?.accounts || []).map(account => account?.platform))],
        progress: {
          overall: {
            percent: config.summary?.spend_to_date_rate,
            value: config.summary?.spend_to_date,
            total: config.summary?.budget,
          },
          googleAds: {
            percent: getTotalWithReduce(config, 'googleAds', 'spend_to_date_rate'),
            value: getTotalWithReduce(config, 'googleAds', 'spend_to_date'),
            total: getTotalWithReduce(config, 'googleAds', 'budget'),
          },
          meta: {
            percent: getTotalWithReduce(config, 'meta', 'spend_to_date_rate'),
            value: getTotalWithReduce(config, 'meta', 'spend_to_date'),
            total: getTotalWithReduce(config, 'meta', 'budget'),
          },
          tiktok: {
            percent: getTotalWithReduce(config, 'tiktok', 'spend_to_date_rate'),
            value: getTotalWithReduce(config, 'tiktok', 'spend_to_date'),
            total: getTotalWithReduce(config, 'tiktok', 'budget'),
          },
        },
      }
    }))
    payload = { data: formattedData, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_SAVED_CONFIGURATIONS, payload });
    result = Promise.resolve(payload);
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_SAVED_CONFIGURATIONS, payload });
    result = Promise.reject(payload);
  };
  return result;
};

export const getBudgetConfigurationDetails = (params={}) => async (dispatch) => {
  let payload = { loading: false }; let result;
  dispatch({ type: TYPES.SET_BUDGET_TRACKER_CONFIGURATION_DETAILS, payload });
  try {
    const { status, data } = await ApiService2.post('budget-tracker/get-data-budget', params);
    // console.log({ getBudgetConfigurationDetails: data });
    const formattedData = (Array.isArray(data?.data) && data?.data || []).map(config => ({
      ...config,
      accounts: (Array.isArray(config?.accounts) && config?.accounts || []).map(account => ({
        ...account,
        budget_id: config?.id,
        children: account?.week?.map(item => ({
          ...item, account_name: `Week ${item?.week}`,
        })),
      })),
      summary: {
        ...config.summary,
        budget_id: config?.id,
        platforms: [...new Set((Array.isArray(config?.accounts) && config?.accounts || []).map(account => account?.platform))],
        progress: {
          overall: {
            percent: config.summary?.spend_to_date_rate,
            value: config.summary?.spend_to_date,
            total: config.summary?.budget,
          },
          googleAds: {
            percent: getTotalWithReduce(config, 'googleAds', 'spend_to_date_rate'),
            value: getTotalWithReduce(config, 'googleAds', 'spend_to_date'),
            total: getTotalWithReduce(config, 'googleAds', 'budget'),
          },
          meta: {
            percent: getTotalWithReduce(config, 'meta', 'spend_to_date_rate'),
            value: getTotalWithReduce(config, 'meta', 'spend_to_date'),
            total: getTotalWithReduce(config, 'meta', 'budget'),
          },
          tiktok: {
            percent: getTotalWithReduce(config, 'tiktok', 'spend_to_date_rate'),
            value: getTotalWithReduce(config, 'tiktok', 'spend_to_date'),
            total: getTotalWithReduce(config, 'tiktok', 'budget'),
          },
        },
      }
    }))
    payload = { data: formattedData, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_CONFIGURATION_DETAILS, payload });
    result = Promise.resolve(payload);
  } catch(err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_CONFIGURATION_DETAILS, payload });
    result = Promise.reject(payload);
  };
  return result;
};

export const getNotifications = () => async (dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_BUDGET_TRACKER_NOTIFICATIONS, payload });
  try {
    const { status, data } = await ApiService2.get('budget-tracker/get-notification');
    // console.log({ data });
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_NOTIFICATIONS, payload });
    result = Promise.resolve(payload);
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_NOTIFICATIONS, payload });
    result = Promise.reject(payload);
  };
  return result;
};

export const createOrUpdateBudget = (params={}) => async (dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_BUDGET_TRACKER_CREATE_OR_UPDATE, payload });
  try {
    // console.log({ params })
    const { status, data } = await ApiService2.post('budget-tracker/create-update', params);
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_CREATE_OR_UPDATE, payload });
    result = Promise.resolve(payload);
  } catch (err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_CREATE_OR_UPDATE, payload });
    result = Promise.reject(payload);
  };
  return result;
};

export const removeBudget = (params={}) => async (dispatch) => {
  let payload = { loading: true }; let result;
  dispatch({ type: TYPES.SET_BUDGET_TRACKER_REMOVE_BUDGET, payload });
  try {
    const { status, data } = await ApiService2.delete('budget-tracker/remove-budget', { data: { ...params } });
    // console.log({ data });
    payload = { data, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_REMOVE_BUDGET, payload });
    result = Promise.resolve(payload);
  } catch(err) {
    payload = { error: err, loading: false };
    dispatch({ type: TYPES.SET_BUDGET_TRACKER_REMOVE_BUDGET, payload });
    result = Promise.reject(payload);
  };
  return result;
};