import { connect } from 'react-redux';
import CustomReportPublic from './CustomReportPublic.component';

import {
  getPublicReportDetail,
} from '../../redux-v1/actions/dashboard/custom-report.actions';

const mapStateToProps = (state) => ({
  accountsByPlatform: state.user.accounts_by_platform,
  ...state.dashboard.customReport,
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});

const mapDispatchToProps = {
  getPublicReportDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomReportPublic);
