import React from 'react';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  notification,
  Radio,
  Space,
  Switch,
  Typography,
} from 'antd';
import axios from 'axios';

import TYPES from '../../redux-v1/types';
import { coachmark } from '../../utils-v1/coachmark.utils';
import { generateDashboardProps, onMountFunction, renderEmptyState } from '../../utils-v1/platform-page.utils';
import { generateRequestParamsByFormat } from '../../utils-v1/request.utils';
import stringConstants from '../../constants-v1/string.constants';

import { DashboardLayoutGS } from '../../components/layouts-backup';

import ConversionTiktokPage from './ConversionTiktokPage/ConversionTiktokPage.component';
import ConversionWebsitePage from './ConversionWebsitePage/ConversionWebsitePage.component';
import CommunityInteractionPage from './CommunityInteractionPage/CommunityInteractionPage.component';

import config from './DashboardTiktok.component.config';
import './DashboardTiktok.component.styles.less';

const { Paragraph, Title, Text } = Typography;
const { key, name, alt, PLATFORM } = stringConstants.tiktok;
let source;

class DashboardTiktok extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tokenModal: false,
      uiLoading: false,
    };
    source = axios.CancelToken.source();
  };

  componentDidMount() {
    const { tokenStatus } = this.props;
    this.setState({ tokenModal: tokenStatus !== 'active' });
    onMountFunction(this, key);
    if (this.props.account?.config_id && this.props.accountBinding?.length > 0 && this.props.onBoarding.coachmark_status.platform === 0 && this.props.coachmark_local.platformTiktokContentPreview === 0) {
      coachmark({
        delay: 1500,
        steps: [
          {
            key: 1,
            element: '#ContentPreviewTabs',
            title: 'Top Content is Here!',
            intro: 'Wait is over. Preview the top contents that you need here',
            position: 'top',
          }
        ],
        oncomplete: () => this.props.updateCoachmarkLocal({ ...this.props.coachmark_local, platformTiktokContentPreview: 1 }, this.props.onBoarding)
      });
    };
  };

  componentWillUnmount() {
    if (source) { source.cancel('DashboardTiktok axios cancel due to unmounting') };
  };

  fetchData = (format='') => {
    const reqParams = generateRequestParamsByFormat(key, this.props, format, { cancelToken: source.token });
    this.setState({ uiLoading: true });
    this.props.getPlatformData(TYPES.SET_DASHBOARD_TIKTOK_ACCOUNT_DETAILS_CURRENCY, reqParams.formatSummary)
      .finally(() => {
        this.setState({ uiLoading: false });
        let acctType;
        switch(this.props.account.details.type) {
          case 'conversion_tiktok': acctType = 'CT'; break;
          case 'conversion_website': acctType = 'CW'; break;
          default: acctType = 'CI'; break;
        };
        const reqList = config.requestConfig.filter(item => item.type.includes(acctType))
          .map(item => this.props.getPlatformData(item.type, reqParams[item.reqparamsformat]));
        if (this.props.account.account_id) {
          Promise.all(reqList)
            .then(res => console.log({ res }))
            .catch(err => console.log({ err }))
            .finally(() => localStorage.setItem(`DASHBOARD_${PLATFORM}_DATA`, JSON.stringify(this.props.data)))
        };
      });
  };

  renderPageByAccountType = () => {
    const panelProps = { ...this.props, ...this.state };
    switch(this.props?.account?.details?.type) {
      case 'conversion_tiktok': return <ConversionTiktokPage { ...panelProps } />;
      case 'conversion_website': return <ConversionWebsitePage { ...panelProps } />;
      case 'community_interaction': return <CommunityInteractionPage { ...panelProps } />;
      default: return <>
        <div style={{ borderRadius: 10 }}>
          <Paragraph style={{ marginTop: 30 }}>Please select an account type first!</Paragraph>
        </div>
      </>
    };
  };

  constructTokenModalProps = () => ({
    children: <Paragraph>
      Your TikTok account&apos;s token has reached time out. Try to re-bind your account so you can fully access BDD.AI. Would you like to rebind this account now?
    </Paragraph>,
    cancelText: 'Not Now',
    onCancel: () => {
      this.setState({ tokenModal: false });
    },
    okText: 'Yes',
    onOk: () => {
      this.props?.history?.push('/binding-tiktok');
    },
    open: this.state.tokenModal,
  });

  render() {
    return (
      <DashboardLayoutGS { ...generateDashboardProps(this, key) }>
        <div id="DashboardTiktok">
          {!this.props.account?.config_id || !this.props.account?.details?.type ? renderEmptyState(this, key) : this.renderPageByAccountType()}
        </div>
        <Modal { ...this.constructTokenModalProps() } />
      </DashboardLayoutGS>
    );
  };
};

DashboardTiktok.displayName = config.displayName;
DashboardTiktok.propTypes = config.propTypes;
DashboardTiktok.defaultProps = config.defaultProps;

export default DashboardTiktok;