// Color palette
export const bddColor = {
  PRIMARY: '#3751ff',
  SECONDARY: '#ffbd40',
  WHITE: '#fff',
  ACCENT: '#3751ff',
  GRAY: '#9b9b9b',
  GREY_LIGHT: '#c5c7cd',
  GREY_DISABLED: '#ccc',
  BLACK: '#000',
  BLUE: '#1F36C1',
  WHITE_GRAY: '#f9f9f9',
  SMOOTH_WHITE: '#f8f7f9',
  WHITE_THIN_GRAY: '#e5e5e5',
  NAVY: '#283b61',
  SMOOTH_GRAY: '#9b9b9b',
  THIN_BLUE: '#ebf2ff',
  THIN_GREEN: '#EEF8F2',
  BLUE_MAIN: '#1F36C1',
  BLUE_MAIN_LIGHT: '#D2D6F2',
  PURPLE_MAIN: '#BC2EFF',
  PURPLE_MAIN_LIGHT: '#F8EAFF',
  ORANGE_MAIN: '#FF8700',
  ORANGE_MAIN_LIGHT: '#FFF3E5',
  SOFT_BLUE: '#F2F4FF',
  FACEBOOK_BLUE: '#3A589B',
  FACEBOOK_BLUE_ALTERNATE: '#4c6cb1',
  BORDER_LIGHT_GRAY: '#c0c0c0',
  BORDER_THIN_GRAY: '#dadada',
  BORDER_GRAY: '#d4d4d4',
  BORDER_GREEN: '#59BC7D',
  BORDER_CARD: '#eaeaea',
  BORDER: '#dfe0eb',
  BACKGROUND_WHITE: '#ffffff',
  BACKGROUND_LIGHT_WHITE: '#f1f1f1',
  BACKGROUND_LIGHT_WHITE_BLUE: '#fdfdfd',
  BACKGROUND_GRAY: '#363740',
  BACKGROUND_LIGHT_GRAY: '#F0F0F0B2',
  BACKGROUND_LIGHT_GRAY_2: '#e1e1e133',
  BACKGROUND_LIGHT_BLUE: '#ecf4ff',
  BACKGROUND_LIGHT_BLUE_2: '#1F36C12c',
  SUCCESS: '#26c867',
  WARNING: '#ffc107',
  DANGER: '#CF1322',
  DANGER_VAR: '#ee6565',
  SAFE: '#3F8600',
};

// Icon background-color library
export const iconBgColor = {
  TOKOPEDIA: '#eaffeb',
  SHOPEE: '#ffece5',
  SHOPIFY: '#f0ffdf',
  FACEBOOK_ADS: '#c7deff',
  GOOGLE_ADS: '#e3f2ff',
  GOOGLE_ANALYTIC: '#ffeeba',
  INSTAGRAM: '#ffeafe',
};

// Chart Color library
export const chartColor = [
  {
    current: '#3751ff',
    compared: '#ffbd40',
  },
  {
    current: '#EF7752',
    compared: '#05878a',
  },
  {
    current: '#e90052',
    compared: '#cbbeb5',
  }
];

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};