import React from 'react';
import { Button, notification, Typography } from 'antd';
import axios from 'axios';
import { EmptyImage, PlatformAvatar } from '../components/atoms';
import TYPES from '../redux-v1/types';
import { isCurrentDaterangeChanged } from './daterange.utils';
import { isFilterValueChanged } from './filter.utils';
import { findKeyListValues } from './object.utils';
import stringConstants from '../constants-v1/string.constants';

const { Paragraph, Text, Title } = Typography;

const platformsWithSyncData = [
  stringConstants.meta.key,
  stringConstants.tiktok.key,
];

export const generateDashboardProps = (
  _this={
    fetchData: () => null,
    props: {
      account: {}, data: {}, daterange: {}, filter: {}, visited: false,
      getPlatformData: () => null, setPlatformState: () => null,
      history: {}, location: {},
    },
    state: {},
    setState: () => null,
  },
  platform='',
  useFilter=true,
) => {
  const { PLATFORM, name } = stringConstants[platform];
  const SET_PLATFORM_ACCOUNT = TYPES[`SET_DASHBOARD_${PLATFORM.replace(' ','_')}_ACCOUNT`];
  const SET_PLATFORM_DATERANGE = TYPES[`SET_DASHBOARD_${PLATFORM.replace(' ','_')}_DATERANGE`];
  const SET_PLATFORM_FILTER = TYPES[`SET_DASHBOARD_${PLATFORM.replace(' ','_')}_FILTER`];

  let pageTitle = '';
  switch(name) {
    case 'Meta':
    case 'TikTok': pageTitle = `${name} Ads`; break;
    case 'Google Analytics':
    case 'Google Ads': pageTitle = `${name}`; break;
    case 'Instagram': pageTitle = `${name} Insights`; break;
    case 'Marketplace':
    case 'Website': pageTitle = `${name} Data`; break;
    default: break;
  };

  return {
    accountSelectionProps: {
      defaultState: { selectedAccounts: [_this.props.account] },
      multiple: false,
      onApply: (account={}) => {
        const source = axios.CancelToken.source();
        const checkChangesOn = {
          account_id: _this.props.account.account_id !== account.account_id,
          account_name: _this.props.account.account_name !== account.account_name,
          type: _this.props.account.details.type !== account.details.type,
        };
        _this.props.setPlatformState(SET_PLATFORM_ACCOUNT, { ...account, cancelToken: source.token }).then(() => {
          _this.setState({ uiLoading: true });
          _this.fetchData('all');
          // if (checkChangesOn.account_id) {
          //   _this.fetchData('all');
          // } else if (checkChangesOn.type) {
          //   _this.fetchData('all');
          // };
        });
      },
      platformKey: platform,
      platformName: name,
    },
    filterProps: useFilter ? {
      defaultState: _this.props.filter,
      onApply: (filter={}) => {
        const checkChangesOn = {
          filter_by: _this.props.filter.filter_by !== filter.filter_by,
          filter_value: isFilterValueChanged(_this.props.filter, filter),
        };
        _this.props.setPlatformState(SET_PLATFORM_FILTER, { ...filter }).then(() => {
          _this.setState({ uiLoading: true });
          _this.fetchData('all');
          // if (checkChangesOn.filter_by || checkChangesOn.filter_value) {
          //   _this.fetchData('all');
          // } else if (checkChangesOn.group_by) {
          //   _this.fetchData('formatTable');
          // };
        });
      },
      platformKey: platform,
    } : null,
    history: _this.props.history,
    location: _this.props.location,
    rangePickerProps: {
      defaultState: _this.props.daterange,
      onApply: (daterange={}) => {
        const checkChangesOn = {
          breakdown_interval: _this.props.daterange.breakdown_interval !== daterange.breakdown_interval,
          daterange_current: isCurrentDaterangeChanged(_this.props.daterange, daterange),
          use_compare: _this.props.daterange.use_compare !== daterange.use_compare,
        };
        _this.props.setPlatformState(SET_PLATFORM_DATERANGE, { ...daterange }).then(() => {
          // if (checkChangesOn.daterange_current || checkChangesOn.use_compare) {
          //   _this.setState({ uiLoading: true });
          //   _this.fetchData('all');
          // } else if (checkChangesOn.breakdown_interval) {
          //   _this.fetchData('formatPeriodic');
          _this.fetchData('all');
          // };
        });
      },
    },
    // onMouseEnter: () => { const { props, state } = _this; console.log({ props, state }) },
    syncDataProps: !platformsWithSyncData.includes(platform) ? null : {
      account: _this.props.account,
      latest_sync: _this.props.latest_sync,
      loading: _this.props.synchronizingAccounts
        .find(account => account?.config_id === _this.props.account.config_id)?.sync_data?.loading || false,
      onApply: () => {
        _this.props.syncAccountData(_this.props.synchronizingAccounts, _this.props.account)
          .then(() => {
            _this.fetchData('all');
          });
      },
      synchronizingAccounts: _this.props.synchronizingAccounts,
    },
    title: <><PlatformAvatar platform={platform} width={34} />&nbsp; {pageTitle}</>,
  };
};

export const onMountFunction = async (
  _this={
    fetchData: () => null,
    props: {
      account: {}, data: {}, daterange: {}, filter: {}, visited: false,
      accountBinding: [],
      getAccountConfig: () => null, getPlatformData: () => null, setPlatformState: () => null
    },
    state: {},
  },
  platform='',
) => {
  // scrollToTop
  window.scrollTo({ top: 0, behavior: 'smooth' });

  // per platform
  const { PLATFORM } = stringConstants[platform];
  const SET_DASHBOARD_PLATFORM_VISITED = TYPES[`SET_DASHBOARD_${PLATFORM.replace(' ','_')}_VISITED`];
  await _this.props.getAccountConfig()
    .then(() => {
      // console.log({ errors: findKeyListValues(_this.props.data, 'error') })
      const wasCanceled = findKeyListValues(_this.props.data, 'error')
        .find(error => typeof error?.message === 'string' && error?.message?.includes('axios cancel'));
      if (_this.props.visited === false || wasCanceled) {
        _this.props.setPlatformState(SET_DASHBOARD_PLATFORM_VISITED, {});
        _this.fetchData('all');
      };
    })
    .catch(() => notification.error({
      message: 'System Error',
      description: 'Unable to retrieve some user data',
    }));
};

export const renderEmptyState = (
  _this={ props: {} },
  platform='',
) => {
  const urlSec = stringConstants?.[platform]?.dash || '';
  return (
    <div id="platformPageEmptyState">
      <EmptyImage platform={platform} width={150} />
      <br />
      <Text className="line-1" strong>No data to show</Text>
      <br />
      {_this.props?.accountBinding?.length === 0 && !_this.props?.account?.config_id ? <>
        <Text>Looks like you have not bind your account yet.<br />Try to bind your account first.</Text>
        <br /><br />
        <Button type="primary" className="bind-button" onClick={() => _this?.props?.history?.push(`/binding-${urlSec}`)}>
          Bind {stringConstants?.[platform]?.name} Account
        </Button>
      </> : null}
      {_this.props?.accountBinding?.length > 0 && !_this.props?.account?.config_id ? <>
        <Text>Please select an account first!</Text>
      </> : null}
      {_this.props?.accountBinding?.length > 0 && [stringConstants.googleAds.key, stringConstants.meta.key, stringConstants.tiktok.key].includes(platform) && _this.props?.account?.config_id && (!_this.props?.account?.details?.type || _this.props?.account?.details?.type === 'general') ? <>
        <Text>Please select an account type first!</Text>
      </> : null}
    </div>
  );
};

export const getRequestCancelCondition = (props, prevProps) => {
  const isAccountChanged = (
    props?.account?.config_id !== prevProps?.account?.config_id || 
    props?.account?.details?.type !== prevProps?.account?.details?.type
  );
  const isDateChanged = (
    props?.daterange?.breakdown_interval !== prevProps?.daterange?.breakdown_interval ||
    props?.daterange?.date_start_current !== prevProps?.daterange?.date_start_current ||
    props?.daterange?.date_end_current !== prevProps?.daterange?.date_end_current ||
    props?.daterange?.date_start_compare !== prevProps?.daterange?.date_start_compare ||
    props?.daterange?.date_end_compare !== prevProps?.daterange?.date_end_compare ||
    props?.daterange?.preset_range_compare !== prevProps?.daterange?.preset_range_compare ||
    props?.daterange?.use_compare !== prevProps?.daterange?.use_compare 
  );
  // console.log({ isAccountChanged, isDateChanged });
  return isAccountChanged || isDateChanged;
}; 