// EXPORTS

export const areTheSameArray = (arr1=[], arr2=[]) => {
  let output = false;
  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    output = JSON.stringify(arr1) === JSON.stringify(arr2);
  };
  return output;
};

export const isEmptyArray = (arg=[]) => !(Array.isArray(arg) && arg?.length > 0);