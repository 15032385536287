import React from 'react';
import { Typography, Button, Modal } from 'antd';

import config from './ModalNotification.component.config';
import './ModalNotification.component.styles.less';

const { Title, Text } = Typography;
const ModalNotification = ({
  visible,
  config: { icon, title, description, btnFollowUpText },
  onClose,
  onFollowUp,
}) => (
  <Modal
    open={visible}
    footer={null}
    closeIcon={<i className="icon-close" />}
    onCancel={onClose}
    centered
  >
    <div id="modal-notification">
      <img src={icon} alt="icon-sent" />
      <Title level={4}>{title}</Title>
      <Text>{description}</Text>
      <Button type="primary" onClick={onFollowUp}>
        {btnFollowUpText}
      </Button>
    </div>
  </Modal>
);

ModalNotification.displayName = config.displayName;
ModalNotification.defaultProps = config.defaultProps;
ModalNotification.propTypes = config.propTypes;

export default ModalNotification;
