import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  account: { ...globalStateConstants.account.marketplace },
  daterange: { ...globalStateConstants.daterange },
  filter: { ...globalStateConstants.filter.marketplace },
  latest_sync: 0,

  data: {
    marketplace: {
      overview_stats: { data: {}, error: null, loading: false },
      paid_order_distribution_list: { data: {}, error: null, loading: false },
      paid_order_distribution_map: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
    },
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_DASHBOARD_MARKETPLACE_VISITED: return {
      ...state, visited: true,
    };

    case TYPES.SET_DASHBOARD_MARKETPLACE_ACCOUNT: return {
      ...state, account: { /* ...state.account, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_MARKETPLACE_DATERANGE: return {
      ...state, daterange: { /* ...state.daterange, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_MARKETPLACE_FILTER: return {
      ...state, filter: { /* ...state.filter, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_MARKETPLACE_LATEST_SYNC: return {
      ...state, latest_sync: action.payload || 0,
    };

      // MARKETPLACE GROUP

    case TYPES.SET_DASHBOARD_MARKETPLACE_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        marketplace: {
          ...state.data.marketplace,
          overview_stats: { /* ...state.data.marketplace.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_MARKETPLACE_PAID_ORDER_DISTRIBUTION_LIST: return {
      ...state,
      data: {
        ...state.data,
        marketplace: {
          ...state.data.marketplace,
          paid_order_distribution_list: { /* ...state.data.marketplace.paid_order_distribution_list, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_MARKETPLACE_PAID_ORDER_DISTRIBUTION_MAP: return {
      ...state,
      data: {
        ...state.data,
        marketplace: {
          ...state.data.marketplace,
          paid_order_distribution_map: { /* ...state.data.marketplace.paid_order_distribution_map, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_MARKETPLACE_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        marketplace: {
          ...state.data.marketplace,
          charts: { /* ...state.data.marketplace.charts, */ ...action.payload },
        },
      },
    };

      // RESET REDUCER

    case TYPES.RESET_DASHBOARD_MARKETPLACE_DATA: return {
      ...state, data: initialState.data,
    };

    case TYPES.RESET_DASHBOARD_MARKETPLACE_REDUCER: return {
      ...initialState,
    };
  };
};