import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import Table from '../../../components/Table/Table.component';
import CarouselContentPreview from '../../../components/CarouselContentPreview/CarouselContentPreview.component';
import TYPES from '../../../redux-v1/types';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { ICON_IMAGE, ICON_PIESLICE, ICON_WALLET } from '../../../assets-v1/figma-icons';
import metrics from '../../../constants-v1/metrics-config/googleAds';

import parentConfig from '../DashboardGoogleAds.component.config';
import config from './DiscoveryPage.component.config';
import './DiscoveryPage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_OVERVIEW_STATS);
const tablesConfig = [
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_CAMPAIGN,
    keyTitlePair: { key: 'campaign', title: 'Campaign' },
    tableKey: 'table_campaign',
    tableTitle: 'Campaign',
  },
  {
    dispatchType: TYPES.SET_DASHBOARD_GOOGLE_ADS_DISCOVERY_TABLE_AD_PERFORMANCE,
    keyTitlePair: { key: 'ad', title: 'Ad' },
    tableKey: 'table_ad_performance',
    tableTitle: 'Ad Performance',
  },
];
// const contentPreviewMetrics = {
//   discovery: [],
// };
const performanceChartConfig = [
  {
    key: 'chart_clicks_conversions',
    pair: [
      [metrics.discovery.clicks, 'Clicks', '#51ab72', '#90d2a8'],
      [metrics.discovery.conversions, 'Conversions', '#f5bd00', '#f8d354'],
    ],
    title: 'Clicks & Conversions',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    key: 'chart_age',
    pair: [
      ['age', metrics.discovery.clicks, 'Clicks', '#51ab72', '#90d2a8'],
      ['age', metrics.discovery.conversions, 'Conversions', '#f5bd00', '#f8d354'],
    ],
    title: 'Age',
    valueFormatter: getNumberFormatterByFormatCode('int'),
    chart: 'bar',
  },
  {
    key: 'chart_gender',
    pair: [
      ['gender', metrics.discovery.clicks, 'Clicks', '#51ab72', '#90d2a8'],
      ['gender', metrics.discovery.conversions, 'Conversions', '#f5bd00', '#f8d354'],
    ],
    title: 'Gender',
    valueFormatter: getNumberFormatterByFormatCode('int'),
    chart: 'bar',
  },
];

const DiscoveryPage = (props) => {
  const getValueFormatter = getNumberFormatterByFormatCode;

  const statsListProps = {
    middle: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 8 },
      columns: 4,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_WALLET} alt="icon_wallet" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[0].lbl}
        </>,
        values: {
          current: props?.data?.discovery?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          previous: props?.data?.discovery?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          percentage: props?.data?.discovery?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt, props?.account?.details?.currency),
      },
      loading: props?.data?.discovery?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(1)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.discovery?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.discovery?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.discovery?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt, props?.account?.details?.currency),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const getTableColumnsAndData = (
    tableKey='',
    dispatchType='',
    firstCol={ key: 'campaign', title: 'Campaign' },
  ) => ({
    columns: [{ ...firstCol, width: '200px', fixed: 'left' }]
      .concat(requestConfig.find(e => e.type === dispatchType)
        .reqparams.metricsConfig.map(e => ({ key: e.key, title: e.lbl, width: '200px' }))),
    data: (Array.isArray(props?.data?.discovery?.[tableKey]?.data) && props?.data?.discovery?.[tableKey]?.data || [])
      .map(e => {
        const rowData = { ...e };
        const metricKeys = Object.keys(e);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const matchingMetricConfig = requestConfig.find(f => f.type === dispatchType)
            .reqparams.metricsConfig.find(f => f.key === metricKey);
          rowData[metricKey] = getValueFormatter(matchingMetricConfig?.fmt, props?.account?.details?.currency)(rowData[metricKey]);
        };
        return rowData;
      }),
    loading: props?.data?.discovery?.[tableKey]?.loading || props?.uiLoading,
  });

  const tabbedTablesDataBasedOnProps = {
    allowSort: true,
    tables: tablesConfig.map(e => ({ title: e.tableTitle, ...getTableColumnsAndData(e.tableKey, e.dispatchType, e.keyTitlePair) })),
    title: <b>Discovery Performance Insights</b>,
    titlePlacement: 'out',
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  };
  const formatChartTitle = (title='') => <>{title}</>
  const formatChartData = (chartKey='', keyLabelPairs=[]) => {
    const output = [];
    for (let i=0; i<keyLabelPairs.length; i+=1) {
      const keyLabelPair = keyLabelPairs[i];
      if (chartKey === "chart_age" || chartKey === "chart_gender") { // enhancement for chart age and gender
        const propsData = props?.data?.discovery?.[chartKey]?.data;
        const groups = Object.keys(propsData?.current || {});
        const chartMetrics = ['Clicks', 'Views'];
        chartMetrics.forEach(metric => {
          output.push({
            label: `${metric} (current)`,
            // color: 'red',
            entries: groups.map(group => [(group || '')?.replaceAll('_',' '), propsData?.current?.[group]?.[metric.toLowerCase()]]),
          })
          if (props?.daterange?.use_compare) output.push({
            label: `${metric} (previous)`,
            // color: 'green',
            entries: groups.map(group => [(group || '')?.replaceAll('_',' '), propsData?.previous?.[group]?.[metric?.toLowerCase()]]),
          })
        });
        break;
      } else {
        output.push({
          entries: props?.data?.discovery?.[chartKey]?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1], color: keyLabelPair[2]
        });
        if (props?.daterange?.use_compare) {
          output.push({
            entries: props?.data?.discovery?.[chartKey]?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare`, color: keyLabelPair[3]
          });
        };
      }
    };
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    data: formatChartData(e.key, e.pair),
    title: formatChartTitle(e.title),
    chart: e.chart,
    loading: props?.data?.discovery?.[e.key]?.loading || props?.uiLoading,
    valueFormatter: e.valueFormatter,
  }));
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => {
        // enhancement for chart age and gende
        let children = <Chart.Line { ...e } />;
        if (e?.chart === 'bar' ) {
          const eExtends = {
            ...e,
            advancedConfig: {
              ...e.advancedConfig,
              stacked: true,
            },
          };
          children = <Chart.Bar { ...eExtends } />;
        }
        return { key: `${i}`, label: e.title, children };
      }),
  };

  return(
    <div id="GoogleAdsDiscovery" onMouseEnter={() => console.log({ props, performanceChartProps })}>
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>Google Ads - Discovery</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.middle } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.table_purchase?.loading ? null : <Table.Tabbed { ...tabbedTablesDataBasedOnProps } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

DiscoveryPage.displayName = config.displayName;
DiscoveryPage.propTypes = config.propTypes;
DiscoveryPage.defaultProps = config.defaultProps;

export default DiscoveryPage;
