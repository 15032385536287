import PropTypes from 'prop-types';
import TYPES from '../../redux-v1/types';
import metrics from '../../constants-v1/metrics-config/marketplace';
import { reqFormat as rpf } from '../../utils-v1/request.utils';

const displayName = 'Marketplace';
const propTypes = {};
const defaultProps = { dateRangeValue: PropTypes.object };
const requestConfig = [
  {
    endpoint: 'marketplace/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.marketplace.total_sales,
        metrics.marketplace.total_cancel,
        metrics.marketplace.total_pending,
        metrics.marketplace.total_shipping,
        metrics.marketplace.order_paid,
        metrics.marketplace.order_cancel,
        metrics.marketplace.order_pending,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_MARKETPLACE_OVERVIEW_STATS,
  },
  {
    endpoint: 'marketplace/data/overview/paid-order-distribution',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_MARKETPLACE_PAID_ORDER_DISTRIBUTION_LIST,
  },
  {
    endpoint: 'marketplace/data/map',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_MARKETPLACE_PAID_ORDER_DISTRIBUTION_MAP,
  },
  {
    endpoint: 'marketplace/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.marketplace.sales,
        metrics.marketplace.order,
        metrics.marketplace.aov,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_MARKETPLACE_CHARTS,
  },
];

export default { displayName, propTypes, defaultProps, requestConfig };
