import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Layout, Menu, Typography, Modal } from 'antd';
import { ICON_CLOSE } from '../../../../assets-v1/prev-assets';
import SideMenuDocsItem from './SideMenuDocsItem/SideMenuDocsItem.component';
import { getLastUrlTitleSegment } from '../../../../utils-v1/string.utils';
import { bddColor } from '../../../../utils-v1/colors.utils';

import config from './SideMenuDocs.component.config';
import './SideMenuDocs.component.styles.less';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { confirm } = Modal;
const { Title } = Typography;

const SideMenuDocs = ({location, history, closeMenu, routes}) => {

  const rootSubmenuKeys = ['dashboard-feature', 'dashboard-2'];
  const [openKeys, setOpenKeys] = React.useState(['dashboard-feature']);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onNavigate = (e) => {
    console.log('e', e);
    const findMenu = routes.find((items) => items.key === e.key);
    const findParrent = routes.find((items) => items.key === openKeys[0]);
    if (findMenu !== undefined) {
      history.replace(findMenu.url);
    } else {
      const findURL = findParrent.children[e.key].url;
      history.replace(findURL);
    }
    // const eKey = `/${e.key}`;
    // const haveSubmenu = routes.filter(item => item.url.includes(eKey) && item.url !== eKey);
    // if (haveSubmenu.length === 0) history.replace(eKey);

    // if (submenuVisibility[eKey] !== undefined) {
    //   const visibility = !submenuVisibility[eKey];
    //   setSubmenuVisibility({...submenuVisibility, [eKey]: visibility})
    // }

    // if (closeMenu) {
    //   closeMenu()
    // }
  }

  const renderSideMenuItems = (menuList, depth=1) => {
    let component = null;
    component = <Menu
      onClick={onNavigate}
      mode="inline"
      inlineCollapsed
      className="mainmenu-menu"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={depth > 1 ? {marginLeft: 25, borderLeft: `2px solid ${bddColor.BORDER_LIGHT_GRAY}`} : {}}
    >
      {menuList.map((item) => (
        <>
          {item.children ? (
            <Menu.SubMenu
              key={item.key}
              title={<div id="head-menu-item"><p>{item.title}</p></div>}
            >
              {Object.keys(item.children).map((key, index) => (
                <Menu.Item
                  hidden={item.children[key].hidden}
                  key={item.children[key].key}
                  className={location?.pathname === item.children[key]?.url ? `menu-active` : ``}
                >
                  <SideMenuDocsItem
                    item={{
                      title: item.children[key].title,
                      url: item.children[key].url,
                    }}
                  />
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item
              hidden={item.hidden}
              key={item.key}
              className={location?.pathname === item?.url ? `menu-active` : ``}
            >
              <SideMenuDocsItem
                item={{
                  title: item.title,
                  url: item.url,
                }}
              />
            </Menu.Item>
          )}
        </>
      ))}
    </Menu>

    return component;
  }

  return(
    <Sider
      id="docs-side-menu"
      width={300}
      className="no-scroll-bar"
      style={{
        overflow: 'auto',
      }}
    >
      <div className="section-menu">
        <div className="sidebar-title">
          {isMobileOnly && (
            <div
              className="close-sidebar"
              onClick={isMobileOnly? closeMenu : () => {}}
            >
              <img src={ICON_CLOSE} alt="icon-close" />
            </div>
          )}
        </div>
        {routes && renderSideMenuItems(routes)}

      </div>

    </Sider>
  )
}

SideMenuDocs.displayName = config.displayName;
SideMenuDocs.propTypes = config.propTypes;
SideMenuDocs.defaultProps = config.defaultProps;

export default SideMenuDocs;
