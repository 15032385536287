import TYPES from '../../types';
import config from './__config';

const initialState = config.bindingStructure.googleAds;

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_BINDING_BIND_GOOGLE_ADS: return {
      ...state,
      ...action.payload,
    };
  };
};