/* eslint-disable camelcase */
import { notification } from 'antd';
import get from 'lodash/get';

import api2 from '../../../services/api2.service';
import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';
import {
  storeAccountConfig,
  getLocalAccountConfig,
  storeSynchronizingAccounts,
  getLocalSynchronizingAccounts,
  setCacheIntroGuide,
  getCacheIntroGuide,
} from '../../../services/configAccount.service';
import TYPES from '../../types';
import stringConstants from '../../../constants-v1/string.constants';

const { ApiService2, setApi2ReqHeaders } = api2.getInstance();
const { BindingApiService, setAuthToken, setBindingApiReqHeaders } = apiBinding.getInstance();

export const getAccountConfig = (isUpdate = null) => async (dispatch) => {
  let payload; let result;

  if (isUpdate) {
    payload = { account: {}, loading: true };
    dispatch({ type: TYPES.SET_USER_BINDING_ACCOUNT, payload });
  }

  try {
    const { status, data: { data } } = await BindingApiService.get(
      `account-configuration/get-data-by-user-id`,
      { ...setBindingApiReqHeaders(getToken()) },
    );
    /* MORE FIELD ASSIGNMENTS */
    const platformKeys = [
      stringConstants.googleAds.key,
      stringConstants.googleAnalytics.key,
      stringConstants.instagram.key,
      stringConstants.marketplace.key,
      stringConstants.meta.key,
      stringConstants.tiktok.key,
      stringConstants.website.key,
      'token_status',
    ];
    if (data !== undefined) { // handle if user not have configuration exist
      for (let i = 0; i < platformKeys.length; i += 1) {
        const key = platformKeys[i];
        if (key !== 'token_status') {
          data[key] = Array.isArray(data?.[key]) ? data?.[key]?.map(account => ({ ...account, platform: key })) : [];
        } else {
          data[key] = { ...data?.token_status };
          dispatch({
            type: TYPES.SET_USER_NOTIFICATIONS_ACCOUNT_BINDING,
            payload: Object.entries(data?.token_status)
              ?.map(e => ({ platform: e?.[0], platformName: stringConstants?.[e?.[0]]?.name, status: e?.[1] }))
              ?.filter(e => e?.status !== 'active'),
          })
        }
      };
      payload = { ...data, error: null, loading: false, haveBinding: true };
      dispatch({
        type: TYPES.SET_USER_ONBOARDING_BINDING,
        payload: { haveBinding: 1 },
      });
    } else {
      payload = {
        error: null,
        loading: false,
        haveBinding: false,
        googleAds: [],
        googleAnalytics: [],
        instagram: [],
        marketplace: [],
        meta: [],
        tiktok: [],
        website: [],
      }
      dispatch({
        type: TYPES.SET_USER_ONBOARDING_BINDING,
        payload: { haveBinding: 0 },
      });
    }
    /* ******************** */
    result = Promise.resolve(payload);
  } catch (error) {
    payload = { error, loading: false };
    result = Promise.reject(payload);
  };
  dispatch({ type: TYPES.SET_USER_BINDING_ACCOUNT, payload });
  return result;
};

export const getSummaryConfig = () => async (dispatch) => {
  let payload; let result;
  try {
    const { status, data } = await BindingApiService.get(
      'sumreport/get',
      { ...setBindingApiReqHeaders(getToken()) },
    );
    payload = { data, error: null, loading: false };
    dispatch({
      type: TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ADS_ACCOUNTS,
      payload: { accounts: data?.data?.googleAds?.map(account => ({ ...account, platform: stringConstants.googleAds.key })) },
    });
    dispatch({
      type: TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_ACCOUNTS,
      payload: { accounts: data?.data?.googleAnalytics?.map(account => ({ ...account, platform: stringConstants.googleAnalytics.key })) },
    });
    dispatch({
      type: TYPES.SET_DASHBOARD_SUMMARY_INSTAGRAM_ACCOUNTS,
      payload: { accounts: data?.data?.instagram?.map(account => ({ ...account, platform: stringConstants.instagram.key })) },
    });
    dispatch({
      type: TYPES.SET_DASHBOARD_SUMMARY_MARKETPLACE_ACCOUNTS,
      payload: { accounts: data?.data?.marketplace?.map(account => ({ ...account, platform: stringConstants.marketplace.key })) },
    });
    dispatch({
      type: TYPES.SET_DASHBOARD_SUMMARY_META_ACCOUNTS,
      payload: { accounts: data?.data?.meta?.map(account => ({ ...account, platform: stringConstants.meta.key })) },
    });
    dispatch({
      type: TYPES.SET_DASHBOARD_SUMMARY_TIKTOK_ACCOUNTS,
      payload: { accounts: data?.data?.tiktok?.map(account => ({ ...account, platform: stringConstants.tiktok.key })) },
    });
    dispatch({
      type: TYPES.SET_DASHBOARD_SUMMARY_WEBSITE_ACCOUNTS,
      payload: { accounts: data?.data?.website?.map(account => ({ ...account, platform: stringConstants.website.key })) },
    });
    result = Promise.resolve(payload);
  } catch (error) {
    payload = { error, loading: false };
    result = Promise.reject(payload);
  };
  return result;
}

export const syncAccountData = (synchronizingAccounts = [], account = {}) => async (dispatch) => {
  // console.log({ account })
  const addedAccount = {
    ...account,
    sync_data: {
      ...account?.sync_data,
      loading: true,
      unix_timestamp: Date.now(),
      user_id: getToken()?.id,
    },
  };
  let newSynchronizingAccounts = [];

  setTimeout(() => {
    addedAccount.sync_data.loading = true;
    newSynchronizingAccounts = [...synchronizingAccounts, addedAccount].filter(item => item?.sync_data?.loading === true);
    storeSynchronizingAccounts(newSynchronizingAccounts)
    dispatch({ type: TYPES.SET_USER_SYNC_ACCOUNT_DATA, payload: newSynchronizingAccounts });
  }, 0);

  let outcome;
  try {
    const { status, data } = await BindingApiService.post(
      `/sync/${account?.platform}`,
      { account_id: addedAccount?.account_id },
      { ...setBindingApiReqHeaders(getToken()) },
    );
    if (status === 200) {
      setTimeout(() => {
        addedAccount.sync_data.loading = false;
        newSynchronizingAccounts = [...synchronizingAccounts, addedAccount].filter(item => item?.sync_data?.loading === true);
        storeSynchronizingAccounts(newSynchronizingAccounts)
        dispatch({ type: TYPES.SET_USER_SYNC_ACCOUNT_DATA, payload: newSynchronizingAccounts });
      }, 0 * 1000)
      outcome = Promise.resolve(addedAccount);
    };
  } catch (err) {
    outcome = Promise.reject(err);
    outcome.catch((e, __) => {
      // console.log({ e })
      setTimeout(() => {
        addedAccount.error_details = { ...e?.response?.data?.detail };
        newSynchronizingAccounts = [...synchronizingAccounts, addedAccount].filter(item => item?.sync_data?.loading === true);
        storeSynchronizingAccounts(newSynchronizingAccounts)
        dispatch({ type: TYPES.SET_USER_SYNC_ACCOUNT_DATA, payload: newSynchronizingAccounts });
      }, 5000);
      setTimeout(() => {
        addedAccount.sync_data.loading = false;
        newSynchronizingAccounts = [...synchronizingAccounts, addedAccount].filter(item => item?.sync_data?.loading === true);
        storeSynchronizingAccounts(newSynchronizingAccounts)
        dispatch({ type: TYPES.SET_USER_SYNC_ACCOUNT_DATA, payload: newSynchronizingAccounts });
      }, e?.response?.data?.detail?.regain_access || (1) * 10 * 1000);
    });
  };
  return outcome;
};

export const getLastActivityUser = () => async (dispatch) => {
  let result;
  const currentToken = getToken();
  const cacheIntro = getCacheIntroGuide();
  const uidCache = get(cacheIntro, 'uID') === undefined ? [] : get(cacheIntro, 'uID');
  try {
    const { status, data } = await BindingApiService.post(
      `user/last_activity`,
      { user_id: currentToken.id },
      { ...setBindingApiReqHeaders(getToken()) },
    );
    if (get(data, 'data') !== 'empty') {
      console.log({ fromBE: data?.data })
      data.data.forEach((account) => {
        switch (account.platform) {
          case 'googleAds':
            dispatch({
              type: TYPES.SET_DASHBOARD_GOOGLE_ADS_ACCOUNT,
              payload: { ...account, error: null, loading: false },
            }); break;
          case 'gadwords':
            dispatch({
              type: TYPES.SET_DASHBOARD_GOOGLE_ADS_ACCOUNT,
              payload: { ...account, error: null, loading: false },
              // payload: { ...account, details: { ...account?.details, type: 'sem' }, platform: 'googleAds', error: null, loading: false },
            }); break;

          case 'googleAnalytics':
            dispatch({
              type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_ACCOUNT,
              payload: { ...account, error: null, loading: false },
            }); break;
          case 'ganalytics':
            dispatch({
              type: TYPES.SET_DASHBOARD_GOOGLE_ANALYTICS_ACCOUNT,
              payload: { ...account, error: null, loading: false },
              // payload: { ...account, platform: 'googleAnalytics', error: null, loading: false },
            }); break;

          case 'instagram':
            dispatch({
              type: TYPES.SET_DASHBOARD_INSTAGRAM_ACCOUNT,
              payload: { ...account, error: null, loading: false },
            }); break;

          case 'marketplace':
            dispatch({
              type: TYPES.SET_DASHBOARD_MARKETPLACE_ACCOUNT,
              payload: { ...account, error: null, loading: false },
            }); break;

          case 'meta':
            dispatch({
              type: TYPES.SET_DASHBOARD_META_ACCOUNT,
              payload: { ...account, error: null, loading: false },
            }); break;

          case 'tiktok':
            dispatch({
              type: TYPES.SET_DASHBOARD_TIKTOK_ACCOUNT,
              payload: { ...account, error: null, loading: false },
              // payload: { ...account, details: { ...account.details, type: 'conversion_tiktok' }, error: null, loading: false },
            }); break;

          case 'website':
            dispatch({
              type: TYPES.SET_DASHBOARD_WEBSITE_ACCOUNT,
              payload: { ...account, error: null, loading: false },
            }); break;

          default: break;
        }
      });
    }

    if (uidCache.includes(currentToken.id) === false) {
      setCacheIntroGuide({
        uID: uidCache,
        needGuide: !data?.data,
        haveBinding: Array.isArray(data?.data) && data?.data?.length > 0,
      });
    }

    result = Promise.resolve();
  } catch (error) {
    result = Promise.reject(error);
  }
  return result;
};

export const getUserData = () => async (dispatch) => {
  let result;
  try {
    const currentToken = getToken();
    if (get(currentToken, 'token') === undefined)
      result = Promise.reject();

    if (get(currentToken, 'token') !== undefined) {
      setAuthToken(currentToken);
      const data = {
        token: currentToken.token,
        id: currentToken.id,
        name: currentToken.name,
        email: currentToken.email,
        role: currentToken.role,
      };
      dispatch({ type: TYPES.SET_USER_DATA, payload: { loading: false, data } });
      const configData = getLocalAccountConfig();
      dispatch({ type: TYPES.SET_USER_BINDING_ACCOUNT, payload: { account: configData } });
      result = Promise.resolve(true);
    }
  } catch (error) {
    result = Promise.reject(error);
  };
  return result;
};

export const updateUser = (userConfig) => async (dispatch) => {
  let result;
  dispatch({ type: TYPES.SET_USER_DATA, payload: { loading: true } });
  try {
    const { status, data } = await BindingApiService.put(
      `user/update`,
      { ...userConfig },
      { ...setBindingApiReqHeaders(getToken()) },
    );
    if (status === 200) {
      result = Promise.resolve();
      dispatch({ type: TYPES.SET_USER_DATA, payload: { loading: false } });
    } else {
      throw new Error('Failed');
    }
  } catch (error) {
    const {
      response: {
        data: { detail: { message } },
      },
    } = error;
    let errorMessage;
    result = Promise.reject(message);
    if (message) {
      errorMessage = {
        message: 'Error',
        description: `${message}`,
      };
    } else {
      errorMessage = {
        message: 'Error',
        description: `Failed to update user data`,
      };
    }
    notification.error(errorMessage);
    dispatch({ type: TYPES.SET_USER_DATA, payload: { loading: false } });
  };
  return result;
};

export const getStatusIntroGuide = () => async (dispatch) => {
  const cacheIntro = getCacheIntroGuide();
  dispatch({ type: TYPES.SET_USER_INTRO_GUIDE, payload: cacheIntro });
  return cacheIntro;
};

export const updateIntroUserGuide = (isDone) => async (dispatch) => {
  const currentToken = getToken();
  const cacheIntro = getCacheIntroGuide();
  const uidCache = get(cacheIntro, 'uID');

  let payload = {
    needGuide: true,
    haveBinding: false,
  };
  if (isDone) {
    const tempID = uidCache === undefined ? [] : uidCache;
    if (uidCache.includes(currentToken.id) === false) {
      tempID.push(currentToken.id);
    }
    payload = {
      uID: tempID,
      needGuide: false,
      haveBinding: true,
    };
  }
  setCacheIntroGuide(payload);
  dispatch({ type: TYPES.SET_USER_INTRO_GUIDE, payload });
};

export const getUserNotifications = () => async (dispatch) => {
  let result;
  try {
    const { status, data } = await ApiService2.get('budget-tracker/get-notification');
    dispatch({
      type: TYPES.SET_USER_NOTIFICATIONS_BUDGET_TRACKER,
      payload: Array.isArray(data?.data) ? data?.data : [],
    });
    result = Promise.resolve(data);
  } catch (err) {
    result = Promise.reject(err);
  };
  return result;
};

export const getUserOnboarding = () => async (dispatch) => {
  let result;
  try {
    const { status, data } = await BindingApiService.get('user/get-me');
    dispatch({
      type: TYPES.SET_USER_ONBOARDING,
      payload: data?.data ? data?.data : [],
    });
    dispatch({
      type: TYPES.SET_USER_DATA_,
      payload: data?.data,
    });
    result = Promise.resolve(data);
  } catch (err) {
    result = Promise.reject(err);
  };
  return result;
};

export const updateUserOnboarding = (payload) => async (dispatch) => {
  let result;
  try {
    const { status, data } = await BindingApiService.put(
      'user/update-get-me',
      { ...payload },
      { ...setBindingApiReqHeaders(getToken()) },
    );
    dispatch({
      type: TYPES.SET_USER_ONBOARDING,
      payload: data?.data ? data?.data : [],
    });
    result = Promise.resolve(data);
  } catch (err) {
    result = Promise.reject(err);
  };
  return result;
};

export const updateCoachmarkLocal = (localCoachmarkPayload = {}, onBoardingPayload = {}) => async (dispatch) => {
  const { coachmark_status: coachmarkStatus } = onBoardingPayload;
  let result = Promise.resolve({ ...onBoardingPayload });
  dispatch({ type: TYPES.SET_USER_COACHMARK_LOCAL, payload: localCoachmarkPayload });
  const { summary, budgetTracker, ...platform } = localCoachmarkPayload;
  const summaryCoachmarkStatus = summary;
  const budgetTrackerCoachmarkStatus = budgetTracker;
  const platformCoachmarkStatus = Object.values(platform).reduce((s, e) => s && e);
  if (summaryCoachmarkStatus === 1 || platformCoachmarkStatus === 1 || budgetTrackerCoachmarkStatus === 1) {
    const updatedOnboardingPayload = {
      onboarding_status: { ...onBoardingPayload?.onboarding_status },
      coachmark_status: {
        summary: coachmarkStatus?.summary || summaryCoachmarkStatus,
        platform: coachmarkStatus?.platform || platformCoachmarkStatus,
        budgetTracker: coachmarkStatus?.budgetTracker || budgetTrackerCoachmarkStatus,
      }
    };
    updateUserOnboarding(updatedOnboardingPayload)(dispatch).then(() => getUserOnboarding()(dispatch));
    result = Promise.resolve(updatedOnboardingPayload);
  };
  return result;
};