import introJs from 'intro.js';

export const coachmark = ({
  delay = 2000,
  disableInteraction = true,
  doneLabel = 'Close',
  tooltipClass = 'coachmark',
  showBullets = false,
  steps = [{ element: null, title: '', intro: '', position: '' }],
  oncomplete = () => console.log('coachmark complete')
}) => {
  const introJSX = introJs();
  setTimeout(() => {
    introJSX.setOptions({
      // presets
      showStepNumbers: true,
      skipLabel: steps?.length === 1 ? null : `<div
        id="skipButton"
        style="height:40px;color:#FFFFFF;border:1px solid #018FFC;border-radius:5px;cursor:pointer;position:absolute;top:166px;right:80px;line-height:33px;padding:0 5px;"
      >
        <span style="color:#018FFC;font-size:14px;font-weight:500;margin:0 10px">Skip</span>
      </div>`,
      nextLabel: 'Next',
      doneLabel,
      hidePrev: true,
      // from props
      tooltipClass,
      disableInteraction,
      showBullets,
      steps: (Array.isArray(steps) ? steps : [])
        .filter(step => !!step.element)
        .map((step, idx) => ({
          ...step,
          title: `<div style="vertical-align:baseline">
            <span style="font-size:20px;font-weight:700">${step.title}</span>
            <span style="float:right;font-size:16px;font-weight:500;color:#999999">${idx+1} of ${steps.length}</span>
          </div>`,
          intro: `<div style="font-size:16px;height:100px;color:#4E4E4E;">${step.intro}</div>`,
        })),
    }).onafterchange((step) => {
      let stepCurrent = 1;
      const key = steps?.find((item) => item.element === `#${step?.id}`);
      stepCurrent = Number(key?.key);
      try {
        document.querySelector('#skipButton').style.right = stepCurrent > 1 ? '150px' : '80px';
      } catch (err) {
        console.log(err)
      }
    }).onexit(() => {
      oncomplete();
    })/* .oncomplete(() => {
      oncomplete();
    }) */.start();
  }, delay);
};