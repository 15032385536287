import React from 'react';
import {
  Button,
  Drawer,
  Image,
  Space,
  Spin,
  Typography,
} from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  EMPTY_BUDGET_IMAGE,
} from '../../assets-v1/images';

import { DashboardLayoutGS } from '../../components/layouts-backup';
import { PersistentInfo } from '../../components/atoms';

import CreateBudget from './CreateBudget/CreateBudget.component';
import FilledBudgetPage from './FilledBudgetPage/FilledBudgetPage.component';

import config from './BudgetTracker.component.config';
import './BudgetTracker.component.styles.less';

const { Paragraph, Text, Title } = Typography;

class BudgetTracker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false,
      uiLoading: false,
      skeletonLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ uiLoading: true, skeletonLoading: true });
    this.props.getAccountConfig()
      .then(() => {
        this.props.getNotifications();
        this.executeDataFetching();
      })
      // .finally(() => {
      //   this.setState({ uiLoading: false });
      // })
  };

  executeDataFetching = () => {
    this.setState({ skeletonLoading: true });
    this.props.getBudgetConfigurations()
      .then((res) => {
        this.setState({ uiLoading: false });
        const configDetailsReqParams = { config_ids: res?.data?.map(datum => datum?.id) };
        this.props.getBudgetConfigurationDetails(configDetailsReqParams)
          .finally(() => {
            this.setState({ skeletonLoading: false });
          });
      });
  };

  // componentWillUnmount() {
  //   console.log(`BudgetTracker is unmounted...`);
  // }

  constructDashboardProps = () => ({
    accountSelectionProps: null,
    filterProps: null,
    // filterProps: {
    //   onApply: (fltr={}) => console.log({ fltr }),
    // },
    history: this.props.history,
    location: this.props.location,
    rangePickerProps: null,
    syncDataProps: null,
    title: `Budget Tracker`,
    // onMouseEnter: () => console.log(this.props),
  });

  // renderInitCreateBudgetingInfo = () => (<>
  //   {this.props?.saved_configurations?.data?.find(el => el?.set_new_budget) ? <PersistentInfo.Block
  //     message="Create the Budgeting"
  //     description="Welcome to Budget Tracker. Now you can monitor and set up alert of your client accounts."
  //     closable
  //   /> : null}
  // </>);

  // renderSetNewBudgetInfo = () => (<>
  //   {(Array.isArray(this.props?.saved_configurations?.data) ? this.props?.saved_configurations?.data : [])?.find(el => el?.set_new_budget) ? <PersistentInfo.Block
  //     message="Set New Budget for This Month"
  //     description="will reset your clients' budgets at the beginning of a new month. You can still use the clients and ad accounts information and set up the budget of each accounts."
  //     closable
  //   /> : null}
  // </>);

  renderSetNewBudgetInfo = () => {
    const { saved_configurations: savedConfigurations } = this.props;
    const { data: configurationsData } = savedConfigurations;
    const isSetNewBudget = (Array.isArray(configurationsData) ? configurationsData : [])?.find(el => el?.set_new_budget);
    return(<>
      {isSetNewBudget ? <PersistentInfo.Block
        message="Set New Budget for This Month"
        description="will reset your clients' budgets at the beginning of a new month. You can still use the clients and ad accounts information and set up the budget of each accounts."
        closable
      /> : null}
    </>);
  };

  renderEmptyBudget = () => (
    <div className="empty-budget-tracker">
      <Image src={EMPTY_BUDGET_IMAGE} alt="empty_budget_image" preview={false} />
      <Paragraph className="empty-title">No Client</Paragraph>
      <Paragraph>
        You can monitor the budget spending in this place.<br />
        Please add clients and create their budget first.
      </Paragraph>
      <Button id="createBudgetBtn" type="primary" onClick={() => this.setState({ showDrawer: true })}>
        Create Budget
      </Button>
    </div>
  );

  getPanelProps = () => ({ ...this.props, ...this.state });

  renderFilledBudget = () => (
    <FilledBudgetPage
      { ...this.getPanelProps() }
      onAddNewClient={() => this.setState({ showDrawer: true })}
      onUpdateBudget={(updatedConfigs=[]) => {
        const reqList = updatedConfigs?.map(el => this.props?.createOrUpdateBudget(el));
        Promise.all(reqList)
          .finally(() => this.executeDataFetching());
      }}
      onRemoveBudget={(params={}) => {
        this.props.removeBudget(params)
          .finally(() => this.executeDataFetching());
      }}
      onSetNewBudget={(params={}) => {
        this.props.createOrUpdateBudget(params)
          .finally(() => this.executeDataFetching());
      }}
    />
  );

  renderDrawerCreateBudget = () => (
    <Drawer
      className="create-budget-drawer"
      placement="bottom"
      closable
      closeIcon={null}
      open={this.state.showDrawer}
      onClose={() => this.setState({ showDrawer: false })}
      height="100%"
      extra={<Space>
        <CloseCircleFilled
          style={{ backgroundColor: '#000', borderRadius: '50%', cursor: 'pointer', color: '#D0D5DD', transform: 'scale(1.1)' }}
          onClick={() => this.setState({ showDrawer: false })}
        />
      </Space>}
    >
      {this.state.showDrawer ? <div id="CreateBudgetDrawerWrapper">
        <CreateBudget
          { ...this.getPanelProps() }
          onCreateBudget={(params={}) => {
            this.props.createOrUpdateBudget(params)
              .then(() => this.setState({ showDrawer: false }))
              .finally(() => { this.executeDataFetching() });
          }}
        />
      </div> : null}
    </Drawer>
  );

  renderPage = () => {
    let comp;
    if (this.props?.saved_configurations?.loading || this.state.uiLoading) {
      comp = <Paragraph style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph>
    } else {
      comp = this.props?.saved_configurations?.data?.length > 0 && !this.props?.saved_configurations?.loading ? this.renderFilledBudget() : this.renderEmptyBudget();
    }
    return comp;
  };

  render() {
    return (
      <DashboardLayoutGS { ...this.constructDashboardProps() }>
        <div id="BudgetTracker">
          {this.renderSetNewBudgetInfo()}
          {this.renderPage()}
          {this.renderDrawerCreateBudget()}
        </div>
      </DashboardLayoutGS>
    )
  }
}

BudgetTracker.displayName = config.displayName;
BudgetTracker.propTypes = config.propTypes;
BudgetTracker.defaultProps = config.defaultProps;

export default BudgetTracker;