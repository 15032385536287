import { connect } from 'react-redux';
import Filter from './Filter.component';

import {
  updateCoachmarkLocal,
} from '../../../redux-v1/actions/user/__common.actions';

const mapStateToProps = (state) => ({
  ...state.user,
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});

const mapDispatchToProps = {
  updateCoachmarkLocal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
