import React from 'react';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  notification,
  Radio,
  Space,
  Switch,
  Typography,
} from 'antd';
import axios from 'axios';

import TYPES from '../../redux-v1/types';
import { coachmark } from '../../utils-v1/coachmark.utils';
import { generateDashboardProps, onMountFunction, renderEmptyState } from '../../utils-v1/platform-page.utils';
import { generateRequestParamsByFormat } from '../../utils-v1/request.utils';
import stringConstants from '../../constants-v1/string.constants';

import { DashboardLayoutGS } from '../../components/layouts-backup';

import SemPage from './SemPage/SemPage.component';
import GdnPage from './GdnPage/GdnPage.component';
import YoutubePage from './YoutubePage/YoutubePage.component';
import DiscoveryPage from './DiscoveryPage/DiscoveryPage.component';
import PerformanceMaxPage from './PerformanceMaxPage/PerformanceMaxPage.component';
import SmartCampaignPage from './SmartCampaignPage/SmartCampaignPage.component';
import AppPage from './AppPage/AppPage.component';

import config from './DashboardGoogleAds.component.config';
import './DashboardGoogleAds.component.styles.less';

const { Title, Text, Paragraph } = Typography;
const { key, name, alt, PLATFORM } = stringConstants.googleAds;
let source;

class DashboardGoogleAds extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uiLoading: false,
    };
    source = axios.CancelToken.source();
  };

  componentDidMount() {
    onMountFunction(this, key);
    if (this.props.onBoarding.coachmark_status.platform === 0 && this.props.coachmark_local.platformGoogleAdsAccountSelection === 0) {
      coachmark({
        delay: 1000,
        steps: [{
          key: 1,
          element: '#accountSelectionBtn',
          title: 'More Metrics & Reports for You',
          intro: 'Now you have to select one account and it’s type to oversee the complete reports of Google Ads',
          position: 'bottom',
        }],
        oncomplete: () => this.props.updateCoachmarkLocal({ ...this.props.coachmark_local, platformGoogleAdsAccountSelection: 1 }, this.props.onBoarding)
      });
    };
  };

  componentWillUnmount() {
    if (source) { source.cancel('DashboardGoogleAds axios cancel due to unmounting') };
  };

  fetchData = (format='') => {
    const reqParams = generateRequestParamsByFormat(key, this.props, format, { cancelToken: source.token });
    this.setState({ uiLoading: true });
    this.props.getAccountConfig()
      .finally(() => {
        this.setState({ uiLoading: false });
        const acctType = (this.props.account.details.type || '').toUpperCase();
        const reqList = config.requestConfig.filter(item => item.type.includes(acctType))
          .map(item => this.props.getPlatformData(item.type, reqParams[item.reqparamsformat]));
        if (this.props.account.account_id) {
          Promise.all(reqList)
            .then(res => console.log({ res }))
            .catch(err => console.log({ err }))
            .finally(() => localStorage.setItem(`DASHBOARD_${PLATFORM}_DATA`, JSON.stringify(this.props.data)))
        };
      })
  };

  renderPageByAccountType = () => {
    const panelProps = { ...this.props, ...this.state };
    switch(this.props?.account?.details?.type) {
      case 'sem': return <SemPage { ...panelProps } />;
      case 'gdn': return <GdnPage { ...panelProps } />;
      case 'youtube': return <YoutubePage { ...panelProps } />;
      case 'discovery': return <DiscoveryPage { ...panelProps } />;
      case 'performance_max': return <PerformanceMaxPage { ...panelProps } />;
      case 'smart_campaign': return <SmartCampaignPage { ...panelProps } />;
      case 'app': return <AppPage { ...panelProps } />;
      default: return <>
        <div style={{ borderRadius: 10 }}>
          <Paragraph style={{ marginTop: 30 }}>Please select an account type first!</Paragraph>
        </div>
      </>
    };
  };

  render() {
    return (
      <DashboardLayoutGS { ...generateDashboardProps(this, key) }>
        <div id="DashboardGoogleAds">
          {!this.props.account?.config_id ? renderEmptyState(this, key) : this.renderPageByAccountType()}
        </div>
      </DashboardLayoutGS>
    );
  }
}

DashboardGoogleAds.displayName = config.displayName;
DashboardGoogleAds.defaultProps = config.defaultProps;
DashboardGoogleAds.propTypes = config.propTypes;

export default DashboardGoogleAds;
