import { notification } from 'antd';
import TYPES from '../../types';
import dashboardSummaryConfig from '../../../containers/Summary/Summary.component.config';
import api2 from '../../../services/api2.service';
import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';

const { ApiService2, setApi2ReqHeaders } = api2.getInstance();
const { BindingApiService, setBindingApiReqHeaders } = apiBinding.getInstance();

export const setDashboardSummaryState = (dispatchType='', params={}) => async(dispatch) => {
  let payload; let result;
  try {
    if (dispatchType.toLowerCase().includes('account')) {
      const __ = await BindingApiService.post(
        'sumreport/post',
        { platform: params?.platform, platform_type: params?.platform_type, config_ids: params?.config_ids });
      // if (__?.status === 200) getAccountConfig();
    };
    payload = { ...params };
    result = Promise.resolve(payload);
  } catch (error) {
    payload = { ...params, error };
    result = Promise.reject(payload);
  };
  dispatch({ type: dispatchType, payload });
  return result;
};

export const getDashboardSummaryData = (dispatchType='', params={}) => async(dispatch) => {
  let result;
  if (params !== null) {
    const { requestConfig } = dashboardSummaryConfig;
    const matchingRequestConfig = requestConfig.find(configuration => configuration.type === dispatchType);
    const { endpoint, method, reqparams, type } = matchingRequestConfig;
    let payload = { loading: true };
    dispatch({ type, payload });
    try {
      const requestHeaders = { cancelToken: params?.cancelToken };
      const requestParams = {
        ...params,
        ...reqparams,
        ...(reqparams?.metricsConfig && { metrics: reqparams?.metricsConfig.map(metric => metric.key) }),
      };
      delete requestParams?.metricsConfig;
      let fromBE;
      switch (method) {
        default: fromBE = { data: {} }; break;
        case 'post': fromBE = await ApiService2.post(endpoint, requestParams, requestHeaders); break;
        case 'get': fromBE = await ApiService2.get(endpoint, requestParams); break;
      };
      const { data } = fromBE;
      payload = { data: data?.data || data, error: null, loading: false };
      result = Promise.resolve(payload);
    } catch (error) {
      payload = { error, loading: false };
      result = Promise.reject(payload);
    };
    dispatch({ type, payload });
  };
  return result;
};

export const setDashboardSummaryAccounts = (platform='', params={}) => async(dispatch) => {
  let payload = { loading: true }; let result; // let type;
  // switch(platform) {
  //   default: type = ''; break;
  //   case 'googleAds': type = TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ADS_ACCOUNTS; break;
  //   case 'googleAnalytics': type = TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_ACCOUNTS; break;
  //   case 'instagram': type = TYPES.SET_DASHBOARD_SUMMARY_INSTAGRAM_ACCOUNTS; break;
  //   case 'marketplace': type = TYPES.SET_DASHBOARD_SUMMARY_MARKETPLACE_ACCOUNTS; break;
  //   case 'meta': type = TYPES.SET_DASHBOARD_SUMMARY_META_ACCOUNTS; break;
  //   case 'tiktok': type = TYPES.SET_DASHBOARD_SUMMARY_TIKTOK_ACCOUNTS; break;
  //   case 'website': type = TYPES.SET_DASHBOARD_SUMMARY_WEBSITE_ACCOUNTS; break;
  // };
  // dispatch({ type, payload });
  // console.log({ platform, params })
  try {
    const reqHeaders = { ...setBindingApiReqHeaders(getToken()) };
    const reqParams = {
      ...params,
      platform,
      user_id: getToken()?.id,
    };
    const __ = await BindingApiService.post(`user/activity`, reqParams, reqHeaders);
    payload = { account: params?.account, data: params?.accounts, error: null, loading: false };
    result = Promise.resolve(payload);
  } catch (error) {
    payload = { error, loading: false };
    result = Promise.reject(payload);
  };
  // dispatch({ type, payload });
  return result;
};

export const setDashboardSummaryDaterange = (platform='', params={}) => (dispatch) => {
  let payload; let result; let type;
  switch(platform) {
    default: type = ''; break;
    case 'googleAds': type = TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ADS_DATERANGE; break;
    case 'googleAnalytics': type = TYPES.SET_DASHBOARD_SUMMARY_GOOGLE_ANALYTICS_DATERANGE; break;
    case 'instagram': type = TYPES.SET_DASHBOARD_SUMMARY_INSTAGRAM_DATERANGE; break;
    case 'marketplace': type = TYPES.SET_DASHBOARD_SUMMARY_MARKETPLACE_DATERANGE; break;
    case 'meta': type = TYPES.SET_DASHBOARD_SUMMARY_META_DATERANGE; break;
    case 'tiktok': type = TYPES.SET_DASHBOARD_SUMMARY_TIKTOK_DATERANGE; break;
    case 'website': type = TYPES.SET_DASHBOARD_SUMMARY_WEBSITE_DATERANGE; break;
  };
  try {
    payload = { data: params, error: null };
    result = Promise.resolve(payload);
  } catch (error) {
    payload = { error };
    result = Promise.reject(payload);
  };
  dispatch({ type, payload })
  return result;
};
