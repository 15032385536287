import PropTypes from 'prop-types';
import path from '../../navigation/path';

const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const { landDocsPath } = path;
const { title, url } = landDocsPath?.landing;

const displayName = `Docs`;
const propTypes = {
  title: pt.node,
  url: pt.string,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps, title, url, landDocsPath };
