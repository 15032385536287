import React from 'react';
import { Layout, Typography, Image, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { useWindowSize } from '../../../hooks';
import path from '../../../navigation/path';
import { BDD_LOGIN_FORM_LOGO } from '../../../assets-v1/images';
import SideMenuDocs from './SideMenuDocs/SideMenuDocs.component';
import { AccountBoxGS } from '../../atoms';

import config from './DocsLayout.component.config';
import './DocsLayout.component.styles.less';

const { Content, Header } = Layout;
const { Text, Title } = Typography;
const { landDocsPath } = path;

const DocsLayout = ({children, location, history, title, ...props }) => {
  const { deviceType } = useWindowSize();
  const getAvailableUrls = (url) => {
    const urlElements = url.slice(1).split('/');
    const availableUrls = [];
    let tempUrlStr = '';
    for (let i = 0; i < urlElements.length; i+=1) {
      tempUrlStr += `/${urlElements[i]}`;
      availableUrls.push(tempUrlStr);
    }
    return availableUrls;
  }
  const availableUrls = getAvailableUrls(location.pathname);

  const generateInteractiveDirectory = (directory, urls) => {
    const dirElements = directory.split(' / ');
    const returnedDir = dirElements.map((el, idx) => (
      <>
        <span
          hidden={idx === 0}
          className={`dir-title${location.pathname === urls[idx] ? '-active' : ''}`}
          onClick={() => idx === 0 ? null : history.replace(urls[idx])}
        >
          {el}
        </span>
        {idx < dirElements.length - 1 && idx !== 0 ? ` / ` : ``}
      </>
    ));
    return returnedDir;
  }
  const dynamicHeaderTitle = generateInteractiveDirectory(title || 'title', availableUrls)

  const renderContactUsForm = () => {
    console.log(`renderContactUsForm executed`);
  }

  const renderHeaderDesktop = () => (
    <Header id="header-help-content">
      <div className="help-header">
        <Title level={2} style={{ fontWeight: 700, fontSize: 24, display: 'flex', alignItems: 'center' }}>
          <Image src={BDD_LOGIN_FORM_LOGO} preview={false} width={45} />
          &nbsp;Help
        </Title>
      </div>
      <div>
        <Input prefix={<SearchOutlined style={{ marginLeft: 10, color: '#A6AAB1' }} />} placeholder="How can we help?" size="large" style={{ width: 400 }} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AccountBoxGS />
      </div>
    </Header>
  );

  return(
    <Layout id="landing-docs">
      {renderHeaderDesktop()}
      <Layout style={{ marginTop: 80 }}>
        <SideMenuDocs location={location} history={history} routes={Object.values(landDocsPath)}/>
        <Content className={`content-${deviceType}`}>
          {children}
        </Content>
      </Layout>
      <Text hidden={2>0} className="footer">
        Copyright © {format(new Date(), 'yyyy')} Boleh Dicoba Digital. All rights
        reserved
      </Text>
    </Layout>
  )
}

DocsLayout.displayName = config.displayName;
DocsLayout.propTypes = config.propTypes;
DocsLayout.defaultProps = config.defaultProps;

export default DocsLayout;
