import { isEmptyArray } from './array.utils';

export const areFieldsEmpty = (arg={}) => {
  let ans = true;
  const keys = Object.keys(arg || {});
  for ( let i=0; i<keys.length; i+=1) {
    const key = keys[i];
    if (arg[key] && (!isEmptyArray(arg[key]) || !isEmptyObject(arg[key]))) {
      ans = false; break;
    };
  };
  return ans;
};

export const areTheSameObject = (obj1={}, obj2={}) => {
  let output = false;
  if ((typeof obj1 === 'object' && !Array.isArray(obj1)) && (typeof obj2 === 'object' && !Array.isArray(obj2))) {
    output = JSON.stringify(obj1) === JSON.stringify(obj2);
  };
  return output;
};

export const findKeyListValues = (obj={}, searchKey='', results = []) => {
  const r = results;
  Object.keys(obj || {}).forEach(key => {
    const value = key ? obj[key] : null;
    if(key === searchKey){
      r.push(value);
    }
    if(typeof value === 'object'){
      findKeyListValues(value, searchKey, r);
    }
  });
  return r;
};

export const findKeysMutateKeys = (obj={}, keys=[''], mutatorFunction=()=>null) => {
  Object.keys(obj).forEach(key => {
    if (keys.length > 0) {
      // eslint-disable-next-line no-param-reassign
      if (keys.includes(key) && mutatorFunction(key) !== null) key = mutatorFunction(key)
    }
    if (keys.length === 0) {
      // eslint-disable-next-line no-param-reassign
      if (mutatorFunction(key) !== null) key = mutatorFunction(key)
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      findKeysMutateKeys(obj[key], keys, mutatorFunction)
    }
  })
  return obj
};

export const findKeysMutateValues = (obj={}, keys=[''], mutatorFunction=()=>null) => {
  Object.keys(obj).forEach(key => {
    if (keys.length > 0) {
      // eslint-disable-next-line no-param-reassign
      if (keys.includes(key) && mutatorFunction(obj[key]) !== null) obj[key] = mutatorFunction(obj[key])
    }
    if (keys.length === 0) {
      // eslint-disable-next-line no-param-reassign
      if (mutatorFunction(obj[key]) !== null) obj[key] = mutatorFunction(obj[key])
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      findKeysMutateValues(obj[key], keys, mutatorFunction)
    }
  })
  return obj
};

export const getUniqueListByAttr = (arr, attr) => {
  const uniqueList = [];
  if (Array.isArray(arr)) {
    for (let i=0; i<arr.length; i+=1) {
      const item = typeof arr[i] === 'object' ? arr[i] : {};
      if (
        attr && 
        Object.keys(item).includes(attr) && 
        !uniqueList.find((entry) => entry[attr] === item[attr]) === true
      ) {
        uniqueList.push(item);
      };
    };
  };
  return uniqueList;
};

export const isEmptyObject = (arg={}) => {
  let ans = false;
  if (typeof arg === 'object' && Object.keys(arg).length === 0) ans = true;
  return ans;
};

export const keepNFirstEntries = (arg={}, n=0) => {
  const ans = {};
  if (typeof arg === 'object' && Object.keys(arg).length > n) {
    const entries = Object.entries(arg);
    for (let i=0; i<n; i+=1) {
      const [k, v] = entries[i];
      ans[k] = v;
    };
  };
  return ans;
}