import React from 'react';
import PropTypes from 'prop-types';
import path from '../../../navigation/path';

import {
  DOCS_ACCOUNT_SELECTION_1,
  DOCS_ACCOUNT_SELECTION_2,
  DOCS_ACCOUNT_SELECTION_3,
  DOCS_ACCOUNT_SELECTION_4,
  DOCS_DATA_FILTER_1,
  DOCS_DATA_FILTER_2,
  DOCS_DATA_FILTER_3,
  DOCS_DATE_PICKER_1,
  DOCS_DATE_PICKER_2,
  DOCS_DATE_PICKER_3,
  DOCS_PLATFORM_DATA_1,
  DOCS_SUMMARY_PAGE_1,
  DOCS_SUMMARY_PAGE_2
} from '../../../assets-v1/docs-images';

const { landDocsPath } = path;
const { title, url } = landDocsPath?.landing;

const displayName = `DocsContent`;
const propTypes = {};
const defaultProps = {};

const contentData = [
  // Account Selection
  {
    key: 'account-selection',
    title: 'Account Selection',
    tableContent: [
      {
        index: 1,
        key: 'section-1',
        title: 'Summary Page',
        isNavigation: true,
        description: '',
        image: DOCS_SUMMARY_PAGE_1,
        imageContent: [
          {
            index: 1,
            title: 'Summary Page',
            subtitle: `The new Summary Page of BDDAI allows you to oversee several accounts in a platform all at once. Data that is being shown on this page are high-level information. You can select multiple ad accounts and oversee their performance in total. Besides that, you can also oversee a single account on this Summary page, simply by only choose one account when selecting an account.`
          },
        ],
      },
      {
        index: null,
        key: 'section-2',
        title: null,
        isNavigation: false,
        description: '',
        image: DOCS_SUMMARY_PAGE_2,
        imageContent: [
          {
            index: 1,
            title: 'Select & Change Account',
            subtitle: `You can easily select and change the account that you want to oversee in this Summary Page by clicking on button highlighted by a red rectangle below.`,
          },
        ],
      },
      {
        index: 2,
        key: 'section-3',
        title: 'Select Account',
        isNavigation: true,
        description: 'After clicking on the account selection button, a pop up will appear.',
        image: DOCS_ACCOUNT_SELECTION_1,
        imageContent: [
          {
            index: 1,
            title: 'Add New Account',
            subtitle: `You can click on this button and another pop up will appear`,
          },
          {
            index: 2,
            title: 'Search Bar',
            subtitle: `You can utilize a search bar to find a specific account that you desire`,
          },
          {
            index: 3,
            title: 'List of Account',
            subtitle: `After clicking on the Add New Account button, this list of accounts will appear. You can simply scroll down or utilize the search bar that we provide`,
          },
        ],
      },
      {
        index: null,
        key: 'section-4',
        title: null,
        isNavigation: false,
        description: '',
        image: DOCS_ACCOUNT_SELECTION_2,
        imageContent: [
          {
            index: 4,
            title: 'Choose Account Type',
            subtitle: `After choosing the preferred account, you need to select the account type of this account. This step is applicable on all platforms (Meta Ads, TikTok Ads, and Google Ads)`,
          },
        ],
      },
    ]
  },

  // Date Picker
  {
    key: 'date-picker',
    title: 'Date Picker',
    tableContent: [
      {
        index: null,
        key: 'section-1',
        title: null,
        isNavigation: false,
        description: 'This feature allows user to select a date or date-range at which the user wanted data to be displayed on the dashboard. User can access the feature by clicking on the UI item highlighted by a red rectangle below.',
        image: DOCS_DATE_PICKER_1,
        imageContent: [
          {
            title: '',
            subtitle: 'The Date Range function is a powerful tool that allows you to define a specific time frame for your data or actions. It is particularly useful in filtering, searching, and analyzing information within a specified period.',
          },
        ],
      },
      {
        index: 1,
        key: 'single-range',
        title: 'Single Range',
        isNavigation: true,
        description: 'A single date range allows you to access specific dates and data that you need within the selected period. Use Single Date Range when you want to focus on data within a particular day or a specific point in time. This is perfect for analyzing daily or real-time events',
        image: DOCS_DATE_PICKER_2,
        imageContent: [
          {
            index: 1,
            title: 'Compare Data Toogle',
            subtitle: <span>Make sure that the <b>Compare Date</b> toggle is <b>OFF</b></span>,
          },
          {
            index: 2,
            title: 'Choose Option',
            subtitle: <span>You can simply choose pre-made set of date range provided by clicking on one of the options. If you want to select another date, choose <b>Custom Range</b></span>,
          },
          {
            index: 3,
            title: 'Select Date',
            subtitle: <span>Choose your desired date on <b>Select Date</b> field. You can either input the date manually or use the interactive calendar for visual selection</span>,
          },
          {
            index: 4,
            title: 'Data Showed in',
            subtitle: <span>Our data visualization includes various types of charts. You can select how you will see the data shown on charts in a day, weekly, or monthly occurrence. Then select <b>Apply</b></span>,
          },
        ],
      },
      {
        index: 2,
        key: 'compare-range',
        title: 'Compare Range',
        isNavigation: true,
        description: 'A Compare Range allows you to compare two selected date period in a single session. Use Comparison Date Range for identifying trends, improvements, or regressions over time',
        image: DOCS_DATE_PICKER_3,
        imageContent: [
          {
            index: 1,
            title: 'Compare Data Toogle',
            subtitle: <span>Different from Single Date Range, if you want to compare the data to process, make sure the Compare Date toggle is <b>ON</b>, simply by sliding the button to the right</span>,
          },
          {
            index: 2,
            title: 'Choose Option',
            subtitle: <span>You can simply choose pre-made set of date range provided by clicking on one of the options. If you want to select another date, choose <b>Custom Range</b></span>,
          },
          {
            title: 'Select Date',
            subtitle: <span>Choose your desired date on <b>Select Date</b> field. You can either input the date manually or use the interactive calendar for visual selection</span>,
          },
          {
            title: 'Data Showed in',
            subtitle: <span>Our data visualization includes various types of charts. You can select how you will see the data shown on charts in a day, weekly, or monthly occurrence. Then select <b>Apply</b></span>,
          },
        ],
      },
    ],
  },

  // platform data
  {
    key: 'platform-data',
    title: 'Platform Data',
    tableContent: [
      {
        index: 1,
        key: 'section-1',
        title: null,
        isNavigation: false,
        description: '',
        image: DOCS_PLATFORM_DATA_1,
        imageContent: [
          {
            index: 1,
            title: '',
            subtitle: `On Platform Data Page, we provide more detailed information and the performance of one single account according to its platform. A dropdown will appears whenever you click on the Platform Data page. You can access one of the available submenu/subpage.`,
          },
          {
            index: 2,
            title: '',
            subtitle: <>You can select a single account by clicking on the Select Account button that highlighted by a red rectangle below <br /><br /> You can follow the same steps as Account Selection on the Summary page. The only difference is that in this Platform Data page, you only able to select a single account.</>,
          },
        ],
      }
    ]
  },

  // data-filter 
  {
    key: 'data-filter',
    title: 'Data  Filter',
    tableContent: [
      {
        index: null,
        key: 'section-1',
        title: null,
        isNavigation: false,
        description: <span>
          General Filter Feature is used to filter out the data based on the selected requirements. Here in BDD.AI we divide General Filter into two categories: 
          <br />1. Filter by Campaign
          <br />2. Filter by Objetive
          <br /><br />
        </span>,
        image: false,
        imageContent: [],
      },
      {
        index: 1,
        key: 'filter-by-campaign',
        title: 'Filter by Campaign',
        isNavigation: true,
        description: 'Filter by Campaign allows you to filter out the data that you need using the Campaign name. Some of our clients create their own campaign, thus if you want to separate the performance of your ads from the client side, you can utilize this feature.',
        image: DOCS_DATA_FILTER_1,
        imageContent: [
          {
            title: 'Filter Button',
            subtitle: <span>To apply this feature, click on the <b>Filter</b> button on top of the page</span>,
          },
          {
            title: 'Select Category',
            subtitle: <span>On the dropdown, there are two available options that you can choose. Select <b>Campaign</b></span>,
          },
          {
            title: 'Premade Campaign Name',
            subtitle: 'Since most of the campaigns that are run by BDD will have “BDD” on the title, we create a premade option where you can select this as a value to filter out from the data.',
          },
          {
            title: 'Type a Campaign name (other value)',
            subtitle: <span>You can also add another value to filter out by simply typing on the field here, and press <b>ENTER</b>. There are no limitation of values that can be filter out</span>,
          },
          {
            title: 'List of Choosen Campaign',
            subtitle: 'The value that you already add to the field earlier will appears here. You can filter out more than one value here. If you want to delete a value, simply click the X button next to the value name.',
          },
        ],
      },
      {
        index: 2,
        key: 'filter-by-objective',
        title: 'Filter by Objective',
        isNavigation: true,
        description: 'Filter by Objective allows you to filter out the data that you need based on the objectives on the specific Platform. Objective showed in Meta Ads and TikTok Ads may differ.',
        image: DOCS_DATA_FILTER_2,
        imageContent: [
          {
            title: 'Filter Button',
            subtitle: <span>To apply this feature, click on the <b>Filter</b> button on top of the page.</span>,
          },
          {
            title: 'Select Category',
            subtitle: <span>On the dropdown, there are two available options that you can choose Select <b>Objective</b></span>,
          },
          {
            title: 'Objective Type',
            subtitle: 'A list of objective will show and you only can select one of them.',
          },
        ],
      },
      {
        index: null,
        key: 'filter-for-metrics',
        title: 'Filter for Metrics',
        isNavigation: false,
        description: `We understand that sometimes there are too many metrics that we might think are important to us. A filter feature is a solution where you can select the metrics that you want to show, as you wish.`,
        image: DOCS_DATA_FILTER_3,
        imageContent: [
          {
            index: 1,
            title: 'Categories',
            subtitle: `On each submenu of Platform Data, scroll down until you see a table consist of various data sets. In this table, there are various type of categories that you can easily slide between the tabs. Select the preferred categories by clicking on one tab`,
          },
          {
            index: 2,
            title: 'Filter Button',
            subtitle: `When you are done with selecting the category, to filter out the metrics you want to show, click on the Filter button that highlighted by a red rectangle above`,
          },
          {
            index: 3,
            title: 'Select Metrics',
            subtitle: `You can select the metrics that you want to show by ticking or untick the boxes provided`,
          },
        ],
      },
    ]
  },

  // summary page
  /* {
    key: 'summary-page',
    title: 'Summary Page',
    tableContent: [
      {
        key: 'section-1',
        title: null,
        isNavigation: false,
        description: 'The new Summary Page of BDDAI allows you to oversee several accounts in a platform all at once. Data that is being shown on this page are high-level information. You can select multiple ad accounts and oversee their performance in total. Besides that, you can also oversee a single account on this Summary page, simply by only choose one account when selecting an account',
        image: DOCS_SUMM_PAGE_1,
        imageContent: [],
      },
      {
        key: 'section-2',
        title: null,
        isNavigation: false,
        description: 'You can easily select and change the account that you want to oversee in this Summary Page by clicking on button highlighted by a red rectangle below.',
        image: DOCS_SUMM_PAGE_2,
        imageContent: [],
      },
      {
        key: 'section-3',
        title: null,
        isNavigation: false,
        description: 'After clicking on the account selection button, a pop up will appear. ',
        image: DOCS_SUMM_PAGE_3,
        imageContent: [
          {
            title: 'Add New Account',
            subtitle: 'You can click on this button and another pop up will appear'
          },
          {
            title: 'Search Bar',
            subtitle: 'You can utilize a search bar to find a specific account that you desire'
          },
          {
            title: 'List of Accounts',
            subtitle: <span>After clicking on the <b>Add New Account</b> button, this list of accounts will appears. You can simply scroll down or utilize the search bar that we provide</span>
          },
        ],
      },
      {
        key: 'section-4',
        title: null,
        isNavigation: false,
        description: '',
        image: DOCS_SUMM_PAGE_4,
        imageContent: [
          {
            title: 'Choose Account Type',
            subtitle: 'After choosing the preferred account, you need to select the account type of this account. This step is applicable on all platforms (Meta Ads, TikTok Ads, and Google Ads)'
          }
        ],
      },
    ]
  }, */

  // filter metrics
  /* {
    key: 'filter-metrics',
    title: 'Filter for Metrics',
    tableContent: [
      {
        key: 'section-1',
        title: null,
        isNavigation: false,
        description: 'We understand that sometimes there are too many metrics that we might think are important to us. A filter feature is a solution where you can select the metrics that you want to show, as you wish.',
        image: DOCS_FILTER_3,
        imageContent: [
          {
            title: 'Categories',
            subtitle: <span>On each submenu of Platform Data, scroll down until you see a table consist of various data sets. In this table, there are various type of categories that you can easily slide between the tabs. Select the preferred categories by clicking on <b>one tab</b>.</span>,
          },
          {
            title: 'Filter Button',
            subtitle: <span>When you are done with selecting the category, to filter out the metrics you want to show, click on the <b>Filter</b> button that highlighted by a red rectangle above</span>,
          },
          {
            title: 'Select Metrics',
            subtitle: 'You can select the metrics that you want to show by ticking or untick the boxes provided.',
          },
        ],
      }
    ]
  }, */
];

export default { displayName, propTypes, defaultProps, title, url, landDocsPath, contentData };
