import React from 'react';
import { get } from 'lodash';
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  Image,
  Input,
  InputNumber,
  Modal,
  Progress,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { BellOutlined, CheckOutlined, DownOutlined, InfoCircleOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_META,
  AVATAR_TIKTOK,
  ICON_SWAP_VERTICAL,
  ICON_TRASH,
  ICON_TRASH_MODAL_DELETE,
  ICON_PLUS_ACCOUNT_SELECTION_ALT,
  ICON_EDIT,
} from '../../../assets-v1/figma-icons';
import {
  BUDGET_DOT_DANGER,
  BUDGET_DOT_SAFE,
  BUDGET_DOT_WARN,
} from '../../../assets-v1/images';
import { useKeyUp } from '../../../hooks';
import { coachmark } from '../../../utils-v1/coachmark.utils';
import { formatDateDisplay } from '../../../utils-v1/daterange.utils';
import { formatCurrency, formatFloat, formatInteger, formatPercent, unformatNumber } from '../../../utils-v1/number.utils';

import AccountSelectionBudgetTracker from '../AccountSelectionBudgetTracker/AccountSelectionBudgetTracker.component';
import CreateBudget from '../CreateBudget/CreateBudget.component';
import { MultiColorProgress, PersistentInfo } from '../../../components/atoms';

import config from './FilledBudgetPage.component.config';
import './FilledBudgetPage.component.styles.less';

const { Option } = Select;
const { Paragraph, Text, Title } = Typography;

const FilledBudgetPage = ({
  onAddNewClient = () => null,
  onUpdateBudget = (params) => console.log(params),
  onRemoveBudget = (params) => console.log(params),
  onSetNewBudget = (params) => console.log(params),
  ...props
}) => {
  const {
    notifications: budgetNotifications,
    saved_configurations: savedConfigurations,
    skeletonLoading,
  } = props;
  const [searchText, setSearchText] = React.useState('');
  const [sortBy, setSortBy] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState('');
  const [editedConfigs, setEditedConfigs] = React.useState([]);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [targetRemoveBudget, setTargetRemoveBudget] = React.useState({});
  const [showModalCreate, setShowModalCreate] = React.useState(false);
  const [targetCreateBudget, setTargetCreateBudget] = React.useState({});

  const flattenedAccountList = [
    ...props?.accountsByPlatform?.googleAds,
    ...props?.accountsByPlatform?.meta,
    ...props?.accountsByPlatform?.tiktok,
  ];

  useKeyUp(() => {
    cancelSubmitConfig();
  }, ['Escape']);

  useKeyUp(() => {
    const saveChangesBtn = document.getElementById("SaveChangesBtn");
    saveChangesBtn.focus();
    saveChangesBtn.click();
  }, ['Enter'])

  const cancelSubmitConfig = () => {
    setEditedConfigs((ec) => []);
  };
  const handleSubmitConfig = () => {
    const payload = editedConfigs?.map(editedConfig => {
      const uniqueAccountIDs = [...new Set(editedConfig?.accounts?.map(acct => acct?.account_id))];
      const payloadAccounts = [];
      uniqueAccountIDs.forEach(id =>  {
        const payloadAccount = editedConfig?.accounts?.find(acct => acct?.account_id === id);
        payloadAccounts.push(payloadAccount);
      });
      return { ...editedConfig, accounts: [...payloadAccounts] };
    });
    // console.log({ payload })
    onUpdateBudget(payload);
    setEditedConfigs([]);
  };

  const renderNotifications = () => {
    const notifications = (Array.isArray(budgetNotifications) ? budgetNotifications : [])
      .map((notif, idx) => <Paragraph className="notification-item">
        {idx+1}. <b>{notif?.brand_name}</b> &gt; {uiFuncs.getAvatarByPlatform(notif?.platform)} <em>{notif?.account_name}</em> :&nbsp;
        {notif?.message}
      </Paragraph>);
    let comp = null;
    if (notifications.length > 0) comp = <PersistentInfo.Block
      closable
      message="Budget Notifications"
      description={notifications}
    />
    return comp;
  };

  const renderToolbar = () => (
    <div id="BudgetTrackerToolbar">
      <Space align="center" direction="horizontal" size={16}>
        <Input
          placeholder="Search..."
          prefix={<SearchOutlined style={{ color: '#A6AAB1', marginLeft: 10 }} />}
          style={{ borderRadius: 6 }}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div style={{ backgroundColor: '#FAFBFC', border: '1px solid #D0D5DD', borderRadius: 6 }}>
          <Tooltip
            overlayInnerStyle={{ fontSize: '0.7rem' }}
            title={`Click here to set sort direction to ${sortDirection === 'descending' ? 'ascending' : 'descending'}`}
          >
            <Image
              src={ICON_SWAP_VERTICAL}
              alt="icon_swap_vertical"
              preview={false}
              style={{
                cursor: 'pointer',
                padding: '0 5px',
                transform: `scaleX(${sortDirection === 'descending' ? -1 : 1 })`,
              }}
              onClick={() => {
                const newSortDirection = sortDirection === 'descending' ? 'ascending' : 'descending';
                setSortDirection(newSortDirection);
              }}
            />
          </Tooltip>
          <Select
            className="sorter-selection"
            defaultValue="Sort By"
            onChange={(value) => setSortBy(value)}
          >
            <Option value="brand_name">Client</Option>
            <Option value="summary.budget">Total Budget</Option>
            <Option value="summary.spend_to_date">Total Spend to Date</Option>
            <Option value="summary.remaining_budget">Total Remaining</Option>
            <Option value="summary.spend_to_date_rate">Spending Percentage</Option>
          </Select>
        </div>
        {/* <Buttons.Filter /> */}
        <Button
          id="SaveChangesBtn"
          hidden={editedConfigs.length < 1}
          type="primary"
          onClick={() => handleSubmitConfig()}
        >Save Changes</Button>
        <Button
          hidden={editedConfigs.length < 1}
          type="danger"
          onClick={() => cancelSubmitConfig()}
        >Cancel</Button>
      </Space>
      <div style={{ float: 'right' }}>
        <Button type="primary" style={{ border: 'none' }} onClick={onAddNewClient}>+ Add New Client</Button>
      </div>
    </div>
  );

  const uiFuncs = {
    getAvatarByPlatform: (platform='', width=20, style={}) => {
      let src;
      switch(platform) {
        case 'googleAds': src = AVATAR_GOOGLE_ADS; break;
        case 'meta': src = AVATAR_META; break;
        case 'tiktok': src = AVATAR_TIKTOK; break;
        default: src = null; break;
      }
      return <Image src={src} alt="platform_avatar" preview={false} width={width} style={style} />
    },
    getColorIndicatorByPercent: (percent='') => {
      let background; let text;
      if (percent < 60) {
        background = '#EEF8F2'; text = '#59BC7D';
      } else if (percent >= 60 && percent < 80) {
        background = '#FEF8E6'; text = '#F5BD00';
      } else {
        background = '#FFF0EB'; text = '#FF683A';
      };
      return { background, text };
    },
    getThresholdDropdownMenu: (record={}) => {
      const editedConfig = editedConfigs.find(el => (el?.id === record?.id));
      const indexOfEditedConfig = editedConfigs.map(el => el?.id).indexOf(record?.id);
      const percentage = record?.summary?.spend_to_date_rate || 0;
      const threshold = editedConfig?.budget_threshold || record?.budget_threshold || 50;
      const menu = [
        { percent: 50, src: BUDGET_DOT_SAFE, alt: 'dot_safe', isWithinThisRange: percentage < 50 },
        { percent: 75, src: BUDGET_DOT_WARN, alt: 'dot_warn', isWithinThisRange: percentage >= 50 && percentage < 90 },
        { percent: 90, src: BUDGET_DOT_DANGER, alt: 'dot_danger', isWithinThisRange: percentage >= 90 },
      ];
      const output = menu.map((item, j) => ({
        key: `${j+1}`,
        label: <Space
          direction="horizontal"
          style={{
            borderRadius: 6,
            cursor: 'pointer',
            backgroundColor: threshold === item.percent ? '#E9E9E9' : 'transparent',
            padding: '3px 7px',
          }}
          onClick={() => {
            const newEditedConfigs = [ ...editedConfigs ];
            if (!editedConfig) {
              newEditedConfigs.push({ ...record, budget_threshold: item.percent });
            } else {
              newEditedConfigs.splice(indexOfEditedConfig, 1, { ...editedConfig, budget_threshold: item.percent });
            };
            setEditedConfigs(newEditedConfigs);
          }}
        >
          <CheckOutlined color="#4E4E4E" style={{ visibility: threshold === item.percent ? 'visible' : 'hidden' }} />
          <div className="threshold">{item.percent} %</div>
          <Image src={item.src} alt={item.alt} preview={false} />
        </Space>,
      }));
      return output;
    },
    renderSkeleton: () => (
      <Skeleton active paragraph={{ rows: 0 }} />
    ),
  };

  const utilFuncs = {
    getAccountSelectionProps: (record={}) => {
      const editedConfig = editedConfigs.find(el => (el?.id === record?.id));
      const indexOfEditedConfig = editedConfigs.map(el => el?.id).indexOf(record?.id);
      const { accounts } = record;
      return {
        accountsByPlatform: { ...props.accountsByPlatform },
        onSelectAccount: (account={}) => {
          if (!accounts?.map(el => el?.account_id)?.includes(account?.account_id)) {
            const newAccount = {
              account_id: account?.account_id,
              account_name: account?.account_name,
              budget: 0,
              budget_id: record?.id,
              date_start: formatDateDisplay(accounts?.[0]?.date_start, 'dd MMM'),
              date_end: formatDateDisplay(accounts?.[0]?.date_end),
              expected: 0,
              platform: account?.platform,
              remaining_budget: 0,
              spend_to_date: 0,
              spend_to_date_rate: 0,
            };
            const newAccounts = [ ...accounts, { ...newAccount }];
            const newPlatforms = [ ...new Set(newAccounts.map(acct => acct?.platform)) ];
            const newEditedConfigs = [ ...editedConfigs ];
            if (!editedConfig) {
              newEditedConfigs.push({
                ...record,
                accounts: [ ...newAccounts ],
                summary: { ...record.summary, platforms: [ ...newPlatforms ] },
              });
            } else {
              newEditedConfigs.splice(indexOfEditedConfig, 1, {
                ...editedConfig,
                accounts: [ ...newAccounts ],
                summary: { ...record.summary, platforms: [ ...newPlatforms ] },
              });
            };
            setEditedConfigs(newEditedConfigs);
          };
        },
        selectedAccounts: [ ...(editedConfig?.accounts || accounts) ],
      };
    },
  };

  const outerTableColumns = [
    {
      title: 'Client',
      dataIndex: 'brand_name',
      key: 'brand_name',
      render: (entry, record) => {
        const editedConfig = editedConfigs.find(el => (el?.id === record?.id));
        const indexOfEditedConfig = editedConfigs.map(el => el?.id).indexOf(record?.id);
        return(<span style={{ fontSize: 16 }} className="editable-client-name">
          {!editedConfig?.updateBrandName ? <Text
            style={{ cursor: 'pointer', fontWeight: 550 }}
            onClick={() => {
              if (!record?.set_new_budget) {
                const newEditedConfigs = [ ...editedConfigs ];
                if (!editedConfig) {
                  newEditedConfigs.push({ ...record, updateBrandName: true });
                } else {
                  newEditedConfigs.splice(indexOfEditedConfig, 1, { ...editedConfig, updateBrandName: true });
                };
                setEditedConfigs(newEditedConfigs);
              }
            }}
          >
            {entry || 'brand_name'}
            &nbsp;&nbsp;<Image src={ICON_EDIT} alt="icon_edit" preview={false} style={{ transform: 'translateY(-2px)' }}/>
          </Text> : null}
          {editedConfig?.updateBrandName ? <Input
            defaultValue={entry}
            onBlur={(e) => {
              editedConfig.brand_name = e.target.value;
              const newEditedConfigs = [ ...editedConfigs ].splice(indexOfEditedConfig, 1, editedConfig);
              setEditedConfigs(newEditedConfigs)
            }}
          /> : null}
        </span>)
      },
    },
    {
      title: 'Platform',
      dataIndex: 'platforms',
      key: 'platforms',
      render: (entry, record) => {
        const editedConfig = editedConfigs.find(el => (el?.id === record?.id));
        const platforms = editedConfig ? editedConfig?.summary?.platforms : record?.summary?.platforms;
        return(<div style={{ cursor: 'pointer', textAlign: 'left' }}>
          <AccountSelectionBudgetTracker { ...utilFuncs.getAccountSelectionProps(record) }>
            {[...platforms, 'add']?.map((el, j) => {
              const style = { transform: `translateX(-${12*j}px)` };
              if (el !== 'add') {
                return <>{uiFuncs.getAvatarByPlatform(el, 30, style)}</>
              };
              return <><Image
                src={ICON_PLUS_ACCOUNT_SELECTION_ALT}
                alt="icon_add_account"
                preview={false}
                width={30}
                style={style}
              /></>
            })}
          </AccountSelectionBudgetTracker>
        </div>);
      },
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      render: (entry, record) => {
        let rendered = uiFuncs.renderSkeleton();
        if (!skeletonLoading) {
          rendered = <MultiColorProgress
            chunks={[
              { color: '#018FFC', label: 'Meta', ...record?.summary?.progress?.meta },
              { color: '#222222', label: 'TikTok', ...record?.summary?.progress?.tiktok },
              { color: '#59BC7D', label: 'Google Ads', ...record?.summary?.progress?.googleAds },
            ]}
          />;
        };
        return rendered;
      },
    },
    {
      title: <span className="total-budget-col">Total Budget</span>,
      dataIndex: 'total_budget',
      key: 'total_budget',
      render: (entry, record) => {
        let rendered = uiFuncs.renderSkeleton();
        if (!skeletonLoading) {
          rendered = record?.set_new_budget ? '-' : formatCurrency(
            record?.total_budget,
            2,
            flattenedAccountList.find(act => act?.account_id === record?.account_id)?.details?.currency || 'IDR'
          );
        };
        return rendered;
      },
    },
    {
      title: <span className="total-spend-to-date-col">Total Spend to Date</span>,
      dataIndex: 'spend_to_date',
      key: 'spend_to_date',
      render: (entry, record) => {
        let rendered = uiFuncs.renderSkeleton();
        if (!skeletonLoading) {
          rendered = record?.set_new_budget ? '-' : formatCurrency(
            record?.total_spend_to_date,
            2,
            flattenedAccountList.find(act => act?.account_id === record?.account_id)?.details?.currency || 'IDR'
          );
        };
        return rendered;
      },
    },
    {
      title: <span className="total-remaining-col">Total Remaining</span>,
      dataIndex: 'remaining_budget',
      key: 'remaining_budget',
      render: (entry, record) => {
        let rendered = uiFuncs.renderSkeleton();
        if (!skeletonLoading) {
          rendered = record?.set_new_budget ? '-' : formatCurrency(
            record?.total_remaining_budget,
            2,
            flattenedAccountList.find(act => act?.account_id === record?.account_id)?.details?.currency || 'IDR'
          );
        };
        return rendered;
      },
    },
    {
      title: <span className="spending-percentage-col">Spending Percentage</span>,
      dataIndex: 'spend_to_date_rate',
      key: 'spend_to_date_rate',
      render: (entry, record) => {
        let rendered = uiFuncs.renderSkeleton();
        if (!skeletonLoading) {
          rendered = <Row gutter={[12, 12]}>
            <Col sm={24} md={24} lg={12}>
              <Progress percent={record?.set_new_budget ? 0 : record?.total_spend_to_date_rate} strokeColor="#FF683A" showInfo={false} />
            </Col>
            <Col sm={24} md={24} lg={12}>
              {formatPercent(record?.set_new_budget ? 0 : record?.total_spend_to_date_rate)}
            </Col>
          </Row>;
        };
        return rendered;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record={}) => (
        <Space align="center" direction="horizontal">
          <div
            hidden={record?.set_new_budget}
            style={{ cursor: 'pointer' }}
          >
            <Dropdown
              menu={{ items: uiFuncs.getThresholdDropdownMenu(record) }}
              overlayClassName="budget-indicator-dropdown"
              placement="bottomRight"
              trigger={["click"]}
            >
              <BellOutlined className="bell-notif-action" style={{ color: '#999999', transform: 'scale(1.3)' }} />
            </Dropdown>
          </div>
          <div
            hidden={record?.set_new_budget}
            style={{ cursor: 'pointer' }}
            onClick={() => { setTargetRemoveBudget(record); setShowModalDelete(true) }}
          >
            <Image src={ICON_TRASH} alt="icon_trash" preview={false} width={20} style={{ transform: 'translateY(-2px)' }} />
          </div>
          <div
            hidden={!record?.set_new_budget}
          >
            <Button
              id="SetNewBudgetActionBtn"
              onClick={() => { setTargetCreateBudget(record); setShowModalCreate(true) }}
            >+ Set New Budget</Button>
          </div>
        </Space>
      ),
    },
  ];

  const renderModalRemoveBudget = (record={}) => (
    <Modal
      className="remove-budget-modal"
      open={showModalDelete}
      onOk={() => {
        onRemoveBudget({ budget_id: record?.id, ...(record?.account_id && { account_id: record?.account_id }) })
        setTargetRemoveBudget({});
        setShowModalDelete(false);
      }}
      onCancel={() => {
        setTargetRemoveBudget({});
        setShowModalDelete(false);
      }}
    >
      <div style={{ textAlign: 'center', paddingTop: 50 }}>
        <Image src={ICON_TRASH_MODAL_DELETE} alt="modal_delete_icon" preview={false} />
        <Paragraph style={{ fontWeight: 700, fontSize: '1.2rem', marginTop: 20 }}>
          Are you sure you want to delete data for
          {record?.account_name ? <>&nbsp;<em>{record?.account_name}</em> of</> : ' '}
          <em>{record?.brand_name}</em>?
        </Paragraph>
        <Paragraph>
          Deleting this client will prevent you from tracking the spending of their accounts. 
          You should create a new configuration if you wish to track their accounts again.
        </Paragraph>
      </div>
    </Modal>
  );

  const renderModalCreateBudget = (record={}) => {
    const constructModalProps = () => ({
      className: 'create-budget-modal',
      open: showModalCreate,
      onCancel: () => {
        setShowModalCreate(false);
        setTargetCreateBudget({});
      },
      children: <div id="CreateBudgetModalWrapper">
        {record ? <CreateBudget
          { ...props }
          defaultSetup={record}
          onCreateBudget={(params={}) => {
            onSetNewBudget(params);
            setShowModalCreate(false);
            setTimeout(() => setTargetCreateBudget({}), 500);
          }}
        /> : null}
      </div>,
    });

    return(<>
      {(Object.keys(record)?.length === 0) ? null : <Modal { ...constructModalProps() } />}
    </>);
  };

  const innerTableColumns = [
    {
      title: 'Ad Accounts',
      dataIndex: 'account_name',
      key: 'account_name',
      render: (entry, record) => <>
        {!Array.isArray(record?.week) ? <span style={{ marginLeft: 0 }} /> : uiFuncs.getAvatarByPlatform(record?.platform)}
        <span style={(Array.isArray(record?.week) || !record?.week) ? { fontSize: 14, fontWeight: 700, marginLeft: 5 } : {}}>{entry}</span>
      </>,
      onCell: (record) => ({
        rowSpan: 1, // record?.entry_length || 0,
      }),
      width: 350,
      fixed: 'left',
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      render: (entry, record) => <span style={{ /* ...(!Array.isArray(record?.week) && { */ marginLeft: 0 /* }) */ }}>
        <span style={{ ...((Array.isArray(record?.week) || !record?.week) && { fontSize: 14, fontWeight: 700 }) }}>{formatDateDisplay(record?.date_start, 'dd MMM')}</span>&nbsp;-&nbsp; 
        <span style={{ ...((Array.isArray(record?.week) || !record?.week) && { fontSize: 14, fontWeight: 700 }) }}>{formatDateDisplay(record?.date_end)}</span>
      </span>,
      width: 250,
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      key: 'budget',
      render: (entry, record) => {
        const editedConfig = editedConfigs.find(el => el?.id === record?.id);
        const editedAccount = { ...(editedConfig?.accounts || []).find(el => el?.account_id === record?.account_id) };
        const editedAccountNonCopy = (editedConfig?.accounts || []).find(el => el?.account_id === record?.account_id);
        const indexOfEditedAccount = (editedConfigs?.accounts || []).map(el => el?.account_id).indexOf(record?.account_id);
        if (Array.isArray(record?.week) || !record?.week) {
          return(<span className="editable-budget">
            {!editedAccount?.updateBudget ? <Text
              style={/* (!Array.isArray(record?.week) || record?.week) ? {} : */ { cursor: 'pointer', fontSize: 14, fontWeight: 700 }}
              onClick={() => {
                if (!record?.set_new_budget && (Array.isArray(record?.week) || !record?.week)) {
                  const newEditedConfigs = !editedConfig ?
                    [ ...editedConfigs, { ...arrangeOuterTableData().find(el => el?.id === record?.id) } ]
                    : [ ...editedConfigs ];
                  const newEditedConfig = newEditedConfigs.find(el => el?.id === record?.id);
                  const newEditedAccount = (newEditedConfig?.accounts || []).find(el => el?.account_id === record?.account_id)
                  newEditedAccount.updateBudget = true;
                  setEditedConfigs(newEditedConfigs);
                }
              }}
            >
              {formatCurrency(
                record?.set_new_budget ? '-' : entry,
                2,
                flattenedAccountList.find(act => act?.account_id === editedAccount?.account_id)?.details?.currency || 'IDR'
              )}
              &nbsp;&nbsp;<Image /* hidden={!Array.isArray(record?.week)} */ src={ICON_EDIT} alt="icon_edit" preview={false} style={{ transform: 'translateY(-2px)' }}/>
            </Text> : null}
            {editedAccount?.updateBudget ? <InputNumber
              addonBefore={flattenedAccountList.find(act => act?.account_id === editedAccount?.account_id)?.details?.currency || 'IDR'}
              defaultValue={Number(entry)}
              formatter={(value) => formatInteger(Number(value))}
              onBlur={(e) => {
                editedAccountNonCopy.budget = unformatNumber(e.target.value);
              }}
            /> : null}
          </span>)
        };
        return(<span className="editable-budget">
          <Text>
            {formatCurrency(
              record?.set_new_budget ? '-' : entry,
              2,
              flattenedAccountList.find(act => act?.account_id === editedAccount?.account_id)?.details?.currency || 'IDR'
            )}
          </Text>
        </span>)
      },
      width: 250,
    },
    {
      title: 'Spend to Date',
      dataIndex: 'spend_to_date',
      key: 'spend_to_date',
      render: (entry, record) => <Text style={!Array.isArray(record?.week) ? {} : { fontSize: 14, fontWeight: 700 }}>
        {formatCurrency(
          entry,
          2,
          flattenedAccountList.find(act => act?.account_id === record?.account_id)?.details?.currency || 'IDR'
        )}
      </Text>,
      width: 250,
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining_budget',
      key: 'remaining_budget',
      render: (entry, record) => <Text style={!Array.isArray(record?.week) ? {} : { fontSize: 14, fontWeight: 700 }}>
        {formatCurrency(
          entry,
          2,
          flattenedAccountList.find(act => act?.account_id === record?.account_id)?.details?.currency || 'IDR'
        )}
      </Text>,
      width: 250,
    },
    {
      title: 'Expected Daily Spend',
      dataIndex: 'expected',
      key: 'expected',
      render: (entry, record) => <Text style={!Array.isArray(record?.week) ? {} : { fontSize: 14, fontWeight: 700 }}>
        {formatCurrency(
          entry,
          2,
          flattenedAccountList.find(act => act?.account_id === record?.account_id)?.details?.currency || 'IDR'
        )}
      </Text>,
      width: 250,
    },
    {
      title: 'Spend to Date (%)',
      dataIndex: 'spend_to_date_rate',
      key: 'spend_to_date_rate',
      render: (entry, record) => {
        const colors = uiFuncs.getColorIndicatorByPercent(record?.set_new_budget ? 0 : (entry || 0));
        return(
          <Tooltip
            overlayInnerStyle={{ fontSize: '0.8rem' }}
            placement="right"
            title={
              ((Array.isArray(record?.week)) && `This percentage is a reflection of this account's spending minus the budget allocation.`) ||
              ((record?.week === 1) && ` This percentage is a reflection of the budget allocation minus the first week's spend.`) ||
              ((record?.week === 2) && `This percentage is the result of reducing the amount spent this week minus the remaining budget from the previous week. This applies to the following weeks.`)
            }
          >
            <Space direction="horizontal" align="center">
              <div style={{ textAlign: 'center' }}>
                <div
                  style={{
                    backgroundColor: colors.background,
                    borderRadius: 100,
                    padding: '3px 7px',
                    width: 'fit-content',
                  }}
                >
                  <Text style={{ color: colors.text, ...(!Array.isArray(record?.week) ? {} : { fontSize: 14, fontWeight: 700 }) }}>
                    {formatPercent(record?.set_new_budget ? 0 : (entry || 0))}
                  </Text>
                </div>
              </div>
              <InfoCircleOutlined
                width={50}
                style={{ color: '#7D8085', transform: 'translateX(3px)' }}
                hidden={![1,2].includes(record?.week) && !Array.isArray(record?.week)}
              />
            </Space>
          </Tooltip>
        )
      },
      width: 250,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record={}) => (
        <Space align="center" direction="horizontal">
          <div
            style={{ cursor: 'pointer', visibility: !Array.isArray(record?.week) ? 'hidden' : 'visible' }}
            onClick={() => { setTargetRemoveBudget(record); setShowModalDelete(true) }}
          >
            <Image src={ICON_TRASH} alt="icon_trash" preview={false} width={20} style={{ transform: 'translateY(-2px)' }} />
          </div>
        </Space>
      ),
      width: 100,
    },
  ];

  const arrangeOuterTableData = () => savedConfigurations?.data?.map((datum, j) => ({ ...datum, key: `${j}` }))
    ?.filter(datum => (datum?.brand_name || '')?.toLowerCase()?.includes(searchText?.toLowerCase()));
  const arrangedOuterTableData = sortBy ?
    arrangeOuterTableData().sort((a, b) => {
      const [rawA, rawB] = [get(a, sortBy), get(b, sortBy)];
      const [_a, _b] = [rawA, rawB].map(el => typeof el === 'string' ? el?.toLowerCase() : el);
      let output = 0;
      if (_a > _b) output = sortDirection !== 'descending' ? 1 : -1;
      if (_a < _b) output = sortDirection !== 'descending' ? -1 : 1;
      return output;
    }) : arrangeOuterTableData();

  const mouseEnterCoachmark = () => {
    const { loading } = savedConfigurations;
    const { uiLoading } = props;
    const condition = props?.location?.pathname === '/budget-tracker' && !loading && !uiLoading && savedConfigurations?.data?.length > 0 && props.coachmark_local?.budgetTracker === 0 && props.onBoarding?.coachmark_status?.budgetTracker === 0;
    if (condition) {
      setTimeout(() => {
        coachmark({
          delay: 250,
          doneLabel: 'Try It!',
          steps: [
            {
              element: '.editable-client-name',
              title: 'Easy Editing',
              intro: 'You can edit your client’s name by clicking on the name directly',
              position: 'right',
            },
          ],
          oncomplete: () => {
            if (condition) coachmark({
              delay: 250,
              steps: [
                {
                  element: '.total-budget-col',
                  title: 'Definition',
                  intro: 'Total Budget is the accumulation of each ad’s account budget set for this month',
                  position: 'bottom',
                },
                {
                  element: '.total-spend-to-date-col',
                  title: 'Definition',
                  intro: 'Total Spend to Date is the actual amount of money that is being used from the beginning of the month until today',
                  position: 'bottom',
                },
                {
                  element: '.total-remaining-col',
                  title: 'Definition',
                  intro: 'Total Remaining is the amount of budget left to spend for this month',
                  position: 'bottom',
                },
                {
                  element: '.spending-percentage-col',
                  title: 'Definition',
                  intro: 'Spending Percentage is how many % of the budget spent up until today in percentage value',
                  position: 'bottom',
                },
                {
                  element: '.bell-notif-action',
                  title: 'Get Notified Easily',
                  intro: 'Get notified whenever each of your ad accounts already reaches a certain spending percentage',
                  position: 'left',
                }
              ],
              oncomplete: () => {
                if (condition) coachmark({
                  delay: 250,
                  doneLabel: 'Try It!',
                  steps: [
                    {
                      element: '.editable-budget',
                      title: 'Easy Editing',
                      intro: 'You can edit the budget sets for each ad accounts by clicking on the numbers directly',
                      position: 'bottom',
                    },
                  ],
                  oncomplete: () => {
                    props.updateCoachmarkLocal({ ...props.coachmark_local, budgetTracker: 1 }, props.onBoarding)
                  },
                })
              },
            })
          },
        })
      }, 250);
    };
  };

  return(
    <div className="filled-budget-page" onMouseEnter={() => { mouseEnterCoachmark() }}>
      {renderNotifications()}
      {savedConfigurations?.loading ? <Paragraph style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph> :
        <Card id="SavedBudgetTracks" bordered={false} style={{ backgroundColor: 'transparent' }}>
          {renderToolbar()}
          <Table
            className="outer-table"
            style={{ marginTop: 25, borderRadius: 20, width: '100%' }}
            expandable={{
              /* eslint react/prop-types: 0 */
              defaultExpandedRowKeys: props?.coachmark_local?.budgetTracker === 0 && props?.onBoarding?.coachmark_status?.budgetTracker === 0 ? ['0'] : [],
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpOutlined onClick={e => onExpand(record, e)} />
                ) : (
                  <DownOutlined onClick={e => onExpand(record, e)} />
                ),
              expandedRowRender: (record) => {
                const editedConfig = editedConfigs.find(el => (el?.id === record?.id));
                const indexOfEditedConfig = editedConfigs.map(el => el?.id).indexOf(record?.id);
                let accounts = Array.isArray(record?.accounts) ? record?.accounts : [];
                if (editedConfig) accounts = Array.isArray(editedConfig?.accounts) ? editedConfig?.accounts : [];
                const arrangeInnerTableData = () => {
                  const accountedAccountIds = [];
                  const indexedAccounts = [];
                  let j = 0;
                  for (let i=0; i<accounts?.length; i+=1) {
                    const account = accounts[i];
                    const preOutput = { ...account, brand_name: record?.brand_name, id: record?.id, entry_length: 0 };
                    const output = { ...preOutput };
                    if (accountedAccountIds?.includes(account?.account_id)) {
                      j += 1;
                      output.account_name = `Week ${j}`;
                      output.key = `x.${j}`;
                    } else {
                      j = 0;
                      accountedAccountIds.push(account?.account_id);
                      output.key = `${i}`;
                      output.entry_length = accounts?.filter(acct => acct?.account_id === account?.account_id)?.length;
                    };
                    indexedAccounts.push(output);
                  };
                  return indexedAccounts;
                };
                const arrangedInnerTableData = arrangeInnerTableData();
                return(
                  <Table
                    className="inner-table"
                    loading={skeletonLoading}
                    style={{ marginBottom: 25, marginRight: 15, marginTop: 25, borderRadius: 20, width: '73vw' }}
                    columns={innerTableColumns}
                    dataSource={arrangedInnerTableData}
                    pagination={false}
                    scroll={{ y: 400 }}
                  />
                );
              },
              indentSize: 0,
            }}
            columns={outerTableColumns.map((col, i) => ({ ...col, width: col?.width || '200px' }))}
            dataSource={arrangedOuterTableData}
            pagination={false}
            // scroll={{ y: 800 }}
          />
        </Card>
      }
      {renderModalRemoveBudget(targetRemoveBudget)}
      {renderModalCreateBudget(targetCreateBudget)}
    </div>
  )
}

FilledBudgetPage.displayName = config.displayName;
FilledBudgetPage.propTypes = config.propTypes;
FilledBudgetPage.defaultProps = config.defaultProps;

export default FilledBudgetPage;