import React from 'react';
import { notification } from 'antd';
import html2canvas from 'html2canvas';

export const downloadImage = async (arg={ document: {}, ref: {}, title: '', window: {} }) => {
  const element = arg?.ref?.current;
  let outcome;
  html2canvas(element)
    .then(canvas => {
      const data = canvas.toDataURL(`image/png`);
      const link = arg?.document?.createElement(`a`);
      if (typeof link === 'object' && typeof link?.download === 'string') {
        link.href = data;
        link.download = arg?.title && typeof arg?.title === 'string' ? `${arg.title.replaceAll(' ','_')}.png` : `image.png`;
        link?.click();
      } else {
        arg?.window?.open(data);
      };
      outcome = Promise.resolve();
    })
    .catch(err => {
      outcome = Promise.reject;
    });
  return outcome;
};

export const copyImage = async (arg={ document: {}, navigator: {}, ref: {}, title: '', window: {} }) => {
  notification.warning({ message: 'Copying item to clipboard, please wait...' });
  const element = arg?.ref?.current;
  let outcome;
  arg?.navigator?.permissions?.query({ name: 'clipboard-write' })
    .then((result) => {
      console.log('permissions', result);
      if (result.state === 'granted' || result.state === 'prompt') {
        html2canvas(element)
          .then(canvas => {
            canvas.toBlob(blob => {
              const writtenItem = [new window.ClipboardItem({ [blob?.type || 'blobType']: blob })];
              arg?.navigator?.clipboard?.write(writtenItem);
              outcome = Promise.resolve();
              notification.success({ message: 'Item is copied to clipboard' });
            });
          })
          .catch(err => {
            console.log('error', err);
            outcome = Promise.reject();
            notification.error({ message: 'Unable to copy item to clipboard', description: JSON.stringify(err) });
          });
      };
    })
    .catch(err => {
      console.log('error', err);
      outcome = Promise.reject();
      notification.error({ message: 'Unable to copy item to clipboard due to "clipboard-write" permission', description: JSON.stringify(err) });
    });
  return outcome;
};