export const getLastEventTimeIndicator = (time=new Date(0).valueOf()) => {
  const lastTime = time?.valueOf() && !Number.isNaN(time?.valueOf()) && typeof time?.valueOf() === 'number' ?
    new Date(time).valueOf()/(1000*60) : 0;
  const currentTime = new Date().valueOf()/(1000*60);
  const dTime = currentTime - lastTime;

  const isPlural = (num=0) => num > 1 ? 's' : '';

  let output = '';
  if (dTime < 1) {
    output = 'just now'
  } else if (dTime >= 1 && dTime < 60) {
    output = `${Math.floor(dTime)} minute${isPlural(Math.floor(dTime))} ago`;
  } else if (dTime >= 60 && dTime < (24*60)) {
    output = `${Math.floor(dTime/60)} hour${isPlural(Math.floor(dTime/60))} ago`;
  } else if (dTime >= (24*60) && dTime < (7*24*60)) {
    output = `${Math.floor(dTime/(24*60))} day${isPlural(Math.floor(dTime/(24*60)))} ago`;
  } else if (dTime >= (7*24*60) && dTime < (200*7*24*60)) {
    output = `${Math.floor(dTime/(7*24*60))} week${isPlural(Math.floor(dTime/(7*24*60)))} ago`;
  } else if (dTime >= (200*7*24*60)) {
    output = '-';
  };
  return output;
};