import AWS from 'aws-sdk';
import axios from 'axios';

const region = process.env.S3_REGION;
const bucketName = process.env.S3_BUCKETNAME;
const accessKeyId = process.env.S3_ACCESS_KEY_ID;
const secretAccessKey = process.env.S3_SECRET_ACCESS_KEY;

export const awsS3 = new AWS.S3({
  region,
  accessKeyId,
  secretAccessKey,
  signatureVersion: 'v4',
});

export const generateS3UploadUrl = async (
  pathS3,
  fileObject,
  fileOption,
  storeToBucket,
) => {
  let filename = '';
  if (fileOption === 'no-option') {
    const fileExt = fileObject.name.split('.').pop();
    const imageName = Math.floor(Math.random() * 9999999999999).toString(16);
    filename = `${imageName}.${fileExt}`;
  } else {
    filename = fileOption;
  }
  // const bucket = storeToBucket === 'bucket-private' ? bucketName : bucketNameCertif;
  const params = {
    Bucket: `${bucketName}/${pathS3}`,
    Key: filename,
    Expires: 60,
  };
  // console.log('params', params);
  const uploadUrl = await awsS3.getSignedUrlPromise('putObject', params);
  // console.log('uploadUrl', uploadUrl);
  return uploadUrl;
};

export const executeS3UploadUrl = async (url, file) => {
  await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: file,
  });
  return url.split('?')[0];
};

export const putObjectAwsS3Bucket = async (
  pathS3,
  fileObject,
  fileExtend,
  callback,
  storeToBucket,
) => {
  await generateS3UploadUrl(pathS3, fileObject, fileExtend, storeToBucket).then(
    async (urlEndpoint) => {
      // console.log('urlEndpoint', urlEndpoint)
      await executeS3UploadUrl(urlEndpoint, fileObject).then((originKey) => {
        // console.log('originKey', originKey)
        callback(originKey);
      });
    },
  );
};

const getSignedURLEndpoint = async (
  keyObject,
  operation,
  storeToBucket = false,
) => {
  // const bucket = storeToBucket === 'bucket-private' ? bucketName : bucketNameCertif;

  const options = {
    Bucket: bucketName,
    Key: keyObject,
    Expires: 3600,
  };

  const url = await awsS3.getSignedUrlPromise(operation, options);
  return url;
};

export const getObjectAwsS3Bucket = async (
  keyObject,
  callback,
  storeToBucket = false,
) => {
  await getSignedURLEndpoint(keyObject, 'getObject', storeToBucket).then(
    async (url) => {
      callback(url);
    },
  );
};

export const uploadFileToS3Bucket = async(
  bucketname='',
  upload={ file: {} },
  callback=(tempData={}, originKey='', objectURL='') => console.log({ tempData, originKey, objectURL })
) => {
  const tempData = { uid: upload?.file?.uid, name: upload?.file?.name };
  await putObjectAwsS3Bucket(
    `${bucketname || 'cover_images'}`,
    upload?.file,
    'no-option',
    (originKey) => {
      const keyBanner = originKey.split('amazonaws.com/')[1];
      getObjectAwsS3Bucket(
        keyBanner.replaceAll('+',' '),
        (objectURL) => {
          callback(tempData, originKey, objectURL);
        },
        'bucket-public',
      )
    },
    'bucket-public',
  )
};