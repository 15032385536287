import { connect } from 'react-redux';
import BudgetTracker from './BudgetTracker.component';

import {
  getAccountConfig,
  getUserData,
  updateCoachmarkLocal,
} from '../../redux-v1/actions/user/__common.actions';
import {
  createOrUpdateBudget,
  getBudgetConfigurations,
  getNotifications,
  getBudgetConfigurationDetails,
  removeBudget,
} from '../../redux-v1/actions/dashboard/budget-tracker.actions';

const mapStateToProps = (state) => ({
  accountsByPlatform: state.user.accounts_by_platform,
  ...state.dashboard.budgetTracker,
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});

const mapDispatchToProps = {
  getAccountConfig,
  getUserData,
  updateCoachmarkLocal,

  createOrUpdateBudget,
  getBudgetConfigurations,
  getNotifications,
  getBudgetConfigurationDetails,
  removeBudget,
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetTracker);
