import { connect } from 'react-redux';
import AccountBox from './AccountBox.component';
import { logout } from '../../../redux-v1/actions/auth/__common.actions';

const mapStateToProps = (state) => ({
  ...state.user.me.data,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBox);
