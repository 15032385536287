import PropTypes from 'prop-types';
const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `AccountSelection`;
const propTypes = {
  title: pt.node,
  text: pt.node,
};
const defaultProps = {};

const templateProps = { onApply: (arg) => arg, platform: 'meta' };

const getTemplateAccountLists = () => {
  const platforms = ['meta', 'googleAds', 'googleAnalytics', 'instagram', 'marketplace', 'tiktok', 'website'];
  const output = {};
  for (let i=0; i<platforms.length; i+=1) {
    const platform = platforms[i];
    output[platform] = platform.split('').map((e, j) => ({
      account_name: `${e.replace(e[0], e[0].toUpperCase())}acct ${j+1}`,
      account_id: `${platform}-${j+1}`,
      account_platform: platform,
      account_type: (platform === 'meta' && 'conversions')
        || (platform === 'tiktok' && 'conversion_tiktok')
        || (platform === 'googleAnalytics' && (j%2 === 0 ? 'ua' : 'ga4'))
        || (platform === 'marketplace' && (j%2 === 0 ? 'shopee' : 'tokopedia'))
        || (platform === 'website' && (j%2 === 0 ? 'shopify' : 'woocommerce'))
        || null,
    }));
  };
  return output;
};

const getAccountTypes = () => ({
  googleAds: [
    // { label: 'General', value: 'general' },
    { label: 'SEM', value: 'sem' },
    { label: 'GDN', value: 'gdn' },
    { label: 'YouTube', value: 'youtube' },
    { label: 'Discovery', value: 'discovery' },
    { label: 'Performance Max', value: 'performance_max' },
    { label: 'Smart Campaign', value: 'smart_campaign' },
    { label: 'App', value: 'app' },
  ],
  googleAnalytics: [
    { label: 'UA', value: 'ua' },
    { label: 'GA-4', value: 'ga4' },
  ],
  marketplace: [
    { label: 'Shopee', value: 'shopee' },
    // { label: 'Tokopedia', value: 'tokopedia' },
  ],
  meta: [
    { label: 'Conversion', value: 'conversion' },
    { label: 'Collaborative (CPAS)', value: 'collaborative' },
    { label: 'Lead', value: 'lead' },
    { label: 'Messages', value: 'messages' },
  ],
  tiktok: [
    { label: 'Tiktok Shop', value: 'conversion_tiktok' },
    { label: 'Conversion Website', value: 'conversion_website' },
    { label: 'Community Interaction', value: 'community_interaction' },
  ],
  website: [
    { label: 'Shopify', value: 'shopify' },
    { label: 'PrestaShop', value: 'prestashop' },
    { label: 'WooCommerce', value: 'woocommerce' },
  ],
});

export default { displayName, propTypes, defaultProps, templateProps, getAccountTypes, getTemplateAccountLists };