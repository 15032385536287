import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import TYPES from '../../../redux-v1/types';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import metrics from '../../../constants-v1/metrics-config/marketplace';

import parentConfig from '../DashboardMarketplace.component.config';
import config from './MarketplacePage.component.config';
import './MarketplacePage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_MARKETPLACE_OVERVIEW_STATS);
const performanceChartConfig = [
  {
    pair: [
      [metrics.marketplace.sales, 'Sales', '#51ab72', '#f5bd00'],
    ],
    title: 'Sales',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    pair: [
      [metrics.marketplace.order, 'Order', '#51ab72', '#f5bd00'],
    ],
    title: 'Order',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    pair: [
      [metrics.marketplace.aov, 'Avg. Order', '#51ab72', '#f5bd00'],
    ],
    title: 'Avg. Order',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
];

const MarketplacePage = (props) => {
  const getValueFormatter = getNumberFormatterByFormatCode;

  const statsListProps = {
    left: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      // collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        // adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          Overview Summary
        </>,
        // values: {
        //   current: props?.data?.marketplace?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        //   previous: props?.data?.marketplace?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        //   percentage: props?.data?.marketplace?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        // },
        // valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt),
      },
      loading: props?.data?.marketplace?.overview_stats?.loading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(0)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.marketplace?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.marketplace?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.marketplace?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  }
  const formatChartTitle = (title='') => <>{title}</>
  const formatChartData = (keyLabelPairs=[]) => {
    const output = [];
    for (let i=0; i<keyLabelPairs.length; i+=1) {
      const keyLabelPair = keyLabelPairs[i];
      output.push({
        entries: props?.data?.marketplace?.charts?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1], color: keyLabelPair[2]
      });
      if (props?.daterange?.use_compare) {
        output.push({
          entries: props?.data?.marketplace?.charts?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare`, color: keyLabelPair[3]
        });
      };
    };
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    data: formatChartData(e.pair),
    title: formatChartTitle(e.title),
    loading: props?.data?.marketplace?.charts?.loading,
    valueFormatter: e.valueFormatter,
  }))
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => ({ key: `${i}`, label: e.title, children: i !== 3 ? <Chart.Line { ...e } /> : <Chart.Bar { ...e } /> })),
  };

  const chartMapPaidOrderDistributionProps = {
    ...sharedPerformanceChartProps,
    advancedConfig: { ...sharedPerformanceChartProps.advancedConfig, showTable: true },
    data: {
      map: Object.entries(props?.data?.marketplace?.paid_order_distribution_map?.data?.maps || {}),
      table: Object.entries(props?.data?.marketplace?.paid_order_distribution_list?.data || {}),
    },
    daterange: {},
    mapType: 'id',
    loading: props?.data?.marketplace?.paid_order_distribution_map?.loading,
    title: 'Paid Order Distribution',
  };
  return(
    <div id="MarketplaceMarketplace">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 550 }}>Marketplace</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.left } />}
        </Col>
        <Col sm={24} md={24} lg={12}>
          <Card style={{ height: '100%' }}>
            <Chart.Map { ...chartMapPaidOrderDistributionProps } />
          </Card>
        </Col>
      </Row>
      <Paragraph level={3} style={{ margin: '15px 0', fontWeight: 550 }}>Performance Chart</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

MarketplacePage.displayName = config.displayName;
MarketplacePage.propTypes = config.propTypes;
MarketplacePage.defaultProps = config.defaultProps;

export default MarketplacePage;
