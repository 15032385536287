import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  saved_configurations: { data: [], error: null, loading: false },
  single_configuration: { data: {}, error: null, loading: false },
  create_or_update: { data: {}, error: null, loading: false },
  remove_budget: { data: {}, error: null, loading: false },
  notifications: { data: [], error: null, loading: false },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_BUDGET_TRACKER_CREATE_OR_UPDATE: return {
      ...state, create_or_update: { ...state.create_or_update, ...action.payload },
    };

    case TYPES.SET_BUDGET_TRACKER_SAVED_CONFIGURATIONS: return {
      ...state, saved_configurations: { ...state.saved_configurations, ...action.payload },
    };

    case TYPES.SET_BUDGET_TRACKER_CONFIGURATION_DETAILS: return {
      // ...state, single_configuration: { ...state.single_configuration, ...action.payload },
      ...state, saved_configurations: { ...state.saved_configurations, ...action.payload },
    };

    case TYPES.SET_BUDGET_TRACKER_REMOVE_BUDGET: return {
      ...state, remove_budget: { ...state.remove_budget, ...action.payload },
    };

    case TYPES.SET_BUDGET_TRACKER_NOTIFICATIONS: return {
      ...state, notifications: { ...state.notifications, ...action.payload },
    };
  }
};