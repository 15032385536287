import stringConstants from '../constants-v1/string.constants';

const basePath = {
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  dashboard: '/',
  dashboardBinding: '/binding-',
  config: '/config',
  public: '/pub/',
  docs: '/docs',
};

const dashboardPath = {
  // USER
  profile: `${basePath.dashboard}${stringConstants.profile.dash}`,
  // SUMMARY
  summary: `${basePath.dashboard}dashboard`,
  // PLATFORM DATA
  googleAds: `${basePath.dashboard}${stringConstants.googleAds.dash}`,
  googleAnalytics: `${basePath.dashboard}${stringConstants.googleAnalytics.dash}`,
  instagram: `${basePath.dashboard}${stringConstants.instagram.dash}`,
  marketplace: `${basePath.dashboard}${stringConstants.marketplace.dash}`,
  meta: `${basePath.dashboard}${stringConstants.meta.dash}`,
  tiktok: `${basePath.dashboard}${stringConstants.tiktok.dash}`,
  website: `${basePath.dashboard}${stringConstants.website.dash}`,
  // ACCOUNT BINDING
  bindingGoogleAds: `${basePath.dashboardBinding}${stringConstants.googleAds.dash}`,
  bindingGoogleAnalytics: `${basePath.dashboardBinding}${stringConstants.googleAnalytics.dash}`,
  bindingInstagram: `${basePath.dashboardBinding}${stringConstants.instagram.dash}`,
  bindingMarketplace: `${basePath.dashboardBinding}${stringConstants.marketplace.dash}`,
  bindingMeta: `${basePath.dashboardBinding}${stringConstants.meta.dash}`,
  bindingTiktok: `${basePath.dashboardBinding}${stringConstants.tiktok.dash}`,
  bindingWebsite: `${basePath.dashboardBinding}${stringConstants.website.dash}`,
  // OTHER FEATURES
  budgetTracker: `${basePath.dashboard}budget-tracker`,
  customReport: `${basePath.dashboard}custom-report`,
  customReportWorkspace: `${basePath.dashboard}custom-report/workspace/:id`,
  // ONBOARDING
  onboarding: `${basePath.dashboard}onboarding`,
};

const landPubPath = {
  clientKpi: `${basePath.public}crk`,
  customReportPublic: `${basePath.public}crp/:id`,
  emailVerivication: `${basePath.public}verification`,
  privacyPolicy: `${basePath.public}privacy-policy`,
  termsOfServices: `${basePath.public}terms-of-services`,
};

const landDocsPath = {
  'landing': {
    title: `Docs`,
    url: `${basePath.docs}`,
    hidden: true,
  },
  'dashboard-feature': {
    title: `DASHBOARD FEATURE`,
    key: `dashboard-feature`,
    url: null,
    children: {
      'account-selection': {
        key: `account-selection`,
        title: `Account Selection`,
        url: `${basePath.docs}/account-selection`,
        hidden: false,
      },
      'date-picker': {
        key: `date-picker`,
        title: `Date Picker`,
        url: `${basePath.docs}/date-picker`,
        hidden: false,
      },
      'platform-data': {
        key: `platform-data`,
        title: `Platform Data`,
        url: `${basePath.docs}/platform-data`,
        hidden: false,
      },
      'data-filter': {
        key: `data-filter`,
        title: `Data Filter`,
        url: `${basePath.docs}/data-filter`,
        hidden: false,
      },
    }
  },
}

export default {
  ...basePath,
  dashboardPath,
  landPubPath,
  landDocsPath,
};
