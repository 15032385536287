import { connect } from 'react-redux';
import LandingDocs from './LandingDocs.component';
import { getUserData } from '../../redux-v1/actions/user/__common.actions';

const mapStateToProps = (state) => ({
  user: state.user.me.data,
});

const mapDispatchToProps = {
  getUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingDocs);
