import { connect } from 'react-redux';
import Profile from './Profile.component';

import { updateUser, getUserOnboarding } from '../../redux-v1/actions/user/__common.actions';

const mapStateToProps = (state) => ({
  data: state.user.me.onData,
  me: state.user.me,
});

const mapDispatchToProps = { updateUser, getUserOnboarding };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
