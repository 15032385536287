import React from 'react';
import { Card, Col, Image, Row, Tabs, Typography } from 'antd';

import Stats from '../../../components/Stats/Stats.component';
import Chart from '../../../components/Chart/Chart.component';
import Table from '../../../components/Table/Table.component';
import CarouselContentPreview from '../../../components/CarouselContentPreview/CarouselContentPreview.component';
import TYPES from '../../../redux-v1/types';
import { formatDateDisplay } from '../../../utils-v1/daterange.utils';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';
import { ICON_IMAGE, ICON_PIESLICE, ICON_WALLET } from '../../../assets-v1/figma-icons';
import metrics from '../../../constants-v1/metrics-config/meta';

import parentConfig from '../DashboardMeta.component.config';
import config from './ConversionPage.component.config';
import './ConversionPage.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { requestConfig } = parentConfig;
const overviewConfig = requestConfig.find(item => item.type === TYPES.SET_DASHBOARD_META_CONVERSION_OVERVIEW_STATS);
const contentPreviewMetrics = {
  purchase_ads: [
    metrics.conversion.spend,
    metrics.conversion.purchase,
    metrics.conversion.purchase_value,
    metrics.conversion.roas,
  ],
  engagement_ads: [
    metrics.conversion.post_reaction,
    metrics.conversion.post_engagement,
    metrics.conversion.post_comment,
    metrics.conversion.post_saves,
    metrics.conversion.post_share,
  ],
  link_clicks_ads: [
    metrics.conversion.spend,
    metrics.conversion.reach,
    metrics.conversion.impressions,
    metrics.conversion.link_click,
    metrics.conversion.cpc_link_click,
  ],
};
const performanceChartConfig = [
  {
    pair: [
      [metrics.conversion.cpm, 'CPM'],
      [metrics.conversion.cpc_link_click, 'CPC'],
    ],
    title: 'CPM & CPC',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.conversion.landing_page_view, 'LPV'],
      [metrics.conversion.link_click, 'Clicks'],
      [metrics.conversion.lead, 'Lead'],
    ],
    title: 'LPV, Clicks & Lead',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    pair: [
      [metrics.conversion.add_to_cart, 'ATC'],
      [metrics.conversion.purchase, 'Purchase'],
    ],
    title: 'ATC & Purchase',
    valueFormatter: getNumberFormatterByFormatCode('int'),
  },
  {
    pair: [
      [metrics.conversion.purchase_value, 'Purchase Value']
    ],
    title: 'Purchase Value',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.conversion.roas, 'ROAS'],
    ],
    title: 'ROAS',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
  {
    pair: [
      [metrics.conversion.spend, 'Spend'],
    ],
    title: 'Spending',
    valueFormatter: getNumberFormatterByFormatCode('flt'),
  },
];

const ConversionPage = (props) => {
  const { currency } = props?.account?.details;
  const getValueFormatter = (fmt='') => getNumberFormatterByFormatCode(fmt, currency);

  const statsListProps = {
    left: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[0].adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_WALLET} alt="icon_wallet" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[0].lbl}
        </>,
        values: {
          current: props?.data?.conversion?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          previous: props?.data?.conversion?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
          percentage: props?.data?.conversion?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[0]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[0]?.fmt),
      },
      loading: props?.data?.conversion?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(1, 11)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.conversion?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.conversion?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.conversion?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
    right: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: true, sliceAt: 6 },
      columns: 2,
      daterange: props?.daterange,
      headerStats: {
        adverse: overviewConfig.reqparams.metricsConfig[11]?.adv,
        oneLiner: true,
        title: <>
          <Image src={ICON_PIESLICE} alt="icon_pieslice" preview={false} />&nbsp;&nbsp;
          {overviewConfig.reqparams.metricsConfig[11].lbl}
        </>,
        values: {
          current: props?.data?.conversion?.overview_stats?.data?.current?.[overviewConfig?.reqparams?.metricsConfig[11]?.key],
          previous: props?.data?.conversion?.overview_stats?.data?.previous?.[overviewConfig?.reqparams?.metricsConfig[11]?.key],
          percentage: props?.data?.conversion?.overview_stats?.data?.percentage?.[overviewConfig?.reqparams?.metricsConfig[11]?.key],
        },
        valueFormatter: getValueFormatter(overviewConfig?.reqparams?.metricsConfig[11]?.fmt),
      },
      loading: props?.data?.conversion?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(12, 20)?.map((item, idx) => ({
        adverse: item?.adv,
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.conversion?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.conversion?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.conversion?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
    bottom: {
      actions: { copy: { enabled: true }, expand: { enabled: true } },
      collapse: { enabled: false },
      columns: 4,
      daterange: props?.daterange,
      headerStats: {
        title: <>
          <Image src={ICON_IMAGE} alt="icon_image" preview={false} />&nbsp;&nbsp;
          Post & Media
        </>,
      },
      loading: props?.data?.conversion?.overview_stats?.loading || props?.uiLoading,
      statsList: overviewConfig?.reqparams?.metricsConfig?.slice(20)?.map((item, idx) => ({
        title: <>{item?.lbl}</>,
        values: {
          current: props?.data?.conversion?.overview_stats?.data?.current?.[item?.key],
          previous: props?.data?.conversion?.overview_stats?.data?.previous?.[item?.key],
          percentage: props?.data?.conversion?.overview_stats?.data?.percentage?.[item?.key],
        },
        valueFormatter: getValueFormatter(item?.fmt),
      })),
      useCompare: props?.daterange?.use_compare,
    },
  };

  const sharedPerformanceChartProps = {
    actions: { copy: { enabled: true }, expand: { enabled: true } },
    advancedConfig: { showValuesInChart: true },
    daterange: props?.daterange,
    hover: { enabled: true },
    legend: { enabled: true },
    size: 300,
    tooltip: { enabled: true, shared: true },
    xAxis: { title: '', visible: true },
    yAxis: { ticked: true, title: '', visible: true },
    backgroundDefault: '#ffffff',
    backgroundDrawer: '#F8F9FA'
  }
  const chartState = props?.data?.conversion?.charts;
  const formatChartTitle = (title='') => <><b>{title}</b></>
  const formatChartData = (keyLabelPairs=[]) => {
    const output = [];
    for (let i=0; i<keyLabelPairs.length; i+=1) {
      const keyLabelPair = keyLabelPairs[i];
      output.push({ entries: chartState?.data?.current?.[keyLabelPair[0].key] || [], label: keyLabelPair[1]});
      if (props?.daterange?.use_compare) {
        output.push({ entries: chartState?.data?.previous?.[keyLabelPair[0].key] || [], label: `${keyLabelPair[1]} Compare`});
      };
    };
    return output;
  };
  const performanceChartProps = performanceChartConfig.map((e, i) => ({
    ...sharedPerformanceChartProps,
    data: formatChartData(performanceChartConfig[i].pair),
    title: formatChartTitle(performanceChartConfig[i].title),
    valueFormatter: performanceChartConfig[i].valueFormatter,
    loading: chartState?.loading || props?.uiLoading,
  }))
  const performanceChartTabsProps = {
    defaultActiveKey: '0',
    items: performanceChartProps
      .map((e, i) => ({ key: `${i}`, label: performanceChartConfig[i].title, children: <Chart.Line { ...e } /> })),
  };

  const funnelProps = {
    chart: {
      ...sharedPerformanceChartProps,
      data: [
        {
          entries: Object.entries(props?.data?.conversion?.funnel_chart?.data?.current || {})
            .map(entry => ([metrics.conversion[entry?.[0]].lbl, entry?.[1]])),
          color: '#51ab72',
          label: `${formatDateDisplay(props?.daterange?.date_start_current)} - ${formatDateDisplay(props?.daterange?.date_end_current)}`,
          // label: 'Funnel',
        },
        ...(props?.daterange?.use_compare && [{
          entries: Object.entries(props?.data?.conversion?.funnel_chart?.data?.previous || {})
            .map(entry => ([metrics.conversion[entry?.[0]].lbl, entry?.[1]])),
          color: '#F5BD00',
          label: `${formatDateDisplay(props?.daterange?.date_start_compare)} - ${formatDateDisplay(props?.daterange?.date_end_compare)}`,
        }] || []),
      ],
      title: <>Bar Chart</>,
      loading: props?.data?.conversion?.funnel_chart?.loading || props?.uiLoading,
    },
    table: {
      columns: [
        { key: 'index', title: 'Index', width: 50 },
        { key: 'current', title: 'Current', width: 50 },
        ...(props?.daterange?.use_compare && [{ key: 'previous', title: 'Previous', width: 50 }] || []),
      ],
      data: !props?.data?.conversion?.funnel_table?.data?.current ? [] :
        Object.keys(props?.data?.conversion?.funnel_table?.data?.current).map((e, idx) => ({
          index: requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_META_CONVERSION_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].lbl,
          current: getValueFormatter(requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_META_CONVERSION_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].fmt)(props?.data?.conversion?.funnel_table?.data?.current?.[e]),
          ...(props?.daterange?.use_compare && { previous: getValueFormatter(requestConfig.find(f => f.type === TYPES.SET_DASHBOARD_META_CONVERSION_FUNNEL_TABLE)
            .reqparams.metricsConfig[idx].fmt)(props?.data?.conversion?.funnel_table?.data?.previous?.[e]) }),
        })),
      loading: props?.data?.conversion?.funnel_table?.loading || props?.uiLoading,
      noFilter: true,
      title: <span style={{ fontSize: 16, fontWeight: 700 }}>Table Component</span>,
    },
  };

  const getTableColumnsAndData = (
    tableKey='',
    dispatchType='',
    firstCol={ key: 'campaign', title: 'Campaign' },
  ) => ({
    columns: [{ ...firstCol, width: '200px', fixed: 'left' }]
      .concat(requestConfig.find(e => e.type === dispatchType)
        .reqparams.metricsConfig.map(e => ({ key: e.key, title: e.lbl, width: '200px' }))),
    data: (Array.isArray(props?.data?.conversion?.[tableKey]?.data) && props?.data?.conversion?.[tableKey]?.data || [])
      .map(e => {
        const rowData = { ...e };
        const metricKeys = Object.keys(e);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const matchingMetricConfig = requestConfig.find(f => f.type === dispatchType)
            .reqparams.metricsConfig.find(f => f.key === metricKey);
          rowData[metricKey] = getValueFormatter(matchingMetricConfig?.fmt)(rowData[metricKey]);
        };
        return rowData;
      }),
    loading: props?.data?.conversion?.[tableKey]?.loading || props?.uiLoading,
  });

  const tabbedTablesDataBasedOnProps = {
    allowSort: true,
    tables: [
      {
        title: 'Purchased',
        ...getTableColumnsAndData(
          'table_purchase', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_PURCHASE, { key: 'campaign', title: 'Campaign' }
        ),
      },
      {
        title: 'Objective',
        ...getTableColumnsAndData(
          'table_objective', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_OBJECTIVE, { key: 'objective', title: 'Objective' }
        ),
      },
      {
        title: 'Link Clicks',
        ...getTableColumnsAndData(
          'table_link_clicks', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_LINK_CLICKS, { key: 'campaign', title: 'Campaign' }
        ),
      },
      {
        title: 'Engagement',
        ...getTableColumnsAndData(
          'table_engagement', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_ENGAGEMENT, { key: 'campaign', title: 'Campaign' }
        )
      },
      {
        title: 'Placement',
        ...getTableColumnsAndData(
          'table_placement', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_PLACEMENT, { key: 'placement', title: 'Placement' }
        ),
      },
      {
        title: 'Platform',
        ...getTableColumnsAndData(
          'table_delivery_platform', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_DELIVERY_PLATFORM, { key: 'delivery_platform', title: 'Publisher Platform' }
        ),
      },
      {
        title: 'Region',
        ...getTableColumnsAndData(
          'table_region', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_REGION, { key: 'region', title: 'Region' }
        ),
      },
      {
        title: 'Age',
        ...getTableColumnsAndData(
          'table_age', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_AGE, { key: 'age', title: 'Age' }
        ),
      },
      {
        title: 'Devices',
        ...getTableColumnsAndData(
          'table_device_platform', TYPES.SET_DASHBOARD_META_CONVERSION_TABLE_DEVICE_PLATFORM, { key: 'device', title: 'Device' }
        ),
      },
    ],
    title: <b>Data Based On</b>,
    titlePlacement: 'out',
  };

  const contentPreviewProps = {
    purchase_ads: {
      data: props?.data?.conversion?.preview_purchase_ads?.data?.map((e, i) => ({
        src: { ...e?.previews },
        title: e?.ad_name,
        statsList: contentPreviewMetrics.purchase_ads?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.conversion?.preview_purchase_ads?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
      })),
      loading: props?.data?.conversion?.preview_purchase_ads?.loading || props?.uiLoading,
    },
    engagement_ads: {
      data: props?.data?.conversion?.preview_engagement_ads?.data?.map((e, i) => ({
        src: { ...e?.previews },
        title: e?.ad_name,
        statsList: contentPreviewMetrics.engagement_ads?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.conversion?.preview_engagement_ads?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
      })),
      loading: props?.data?.conversion?.preview_engagement_ads?.loading || props?.uiLoading,
    },
    link_clicks_ads: {
      data: props?.data?.conversion?.preview_link_clicks_ads?.data?.map((e, i) => ({
        src: { ...e?.previews },
        title: e?.ad_name,
        statsList: contentPreviewMetrics.link_clicks_ads?.map((item, idx) => ({
          adverse: item?.adv,
          title: <>{item?.lbl}</>,
          values: {
            current: props?.data?.conversion?.preview_link_clicks_ads?.data?.[i]?.[item.key],
          },
          valueFormatter: getValueFormatter(item?.fmt),
        })),
      })),
      loading: props?.data?.conversion?.preview_link_clicks_ads?.loading || props?.uiLoading,
    },
  };
  const contentPreviewTabsProps = {
    defaultActiveKey: 'purchase',
    items: [
      { key: 'purchase', label: 'Purchase Ads', children: <CarouselContentPreview { ...contentPreviewProps.purchase_ads } /> },
      { key: 'engagement', label: 'Engagement Ads', children: <CarouselContentPreview { ...contentPreviewProps.engagement_ads } /> },
      { key: 'link_clicks', label: 'Link Clicks Ads', children: <CarouselContentPreview { ...contentPreviewProps.link_clicks_ads } /> },
    ],
  };

  return(
    <div id="MetaConversion">
      <div style={{ marginBottom: 20 }}>
        <Title level={2} style={{ fontWeight: 700 }}>Meta Conversion Ads</Title>
        <Paragraph level={3}>{props?.account?.account_name}</Paragraph>
      </div>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.left } />}
        </Col>
        <Col sm={24} md={24} lg={12}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.right } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.overview_stats?.loading ? null : <Stats.List { ...statsListProps.bottom } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...performanceChartTabsProps } />
          </Card>
        </Col>
      </Row>
      <Paragraph style={{ fontSize: 16, fontWeight: 700 }}>Funnel Data</Paragraph>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={24} lg={12} id="MetaConversionFunnelChart">
          {props?.data?.funnel_chart?.loading ? null : <Chart.Bar { ...funnelProps.chart } />}
        </Col>
        <Col sm={24} md={24} lg={12} id="MetaConversionFunnelTable">
          {props?.data?.funnel_table?.loading ? null : <Table.Single { ...funnelProps.table } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          {props?.data?.table_purchase?.loading ? null : <Table.Tabbed { ...tabbedTablesDataBasedOnProps } />}
        </Col>
        <Col sm={24} md={24} lg={24}>
          <Card style={{ borderRadius: 10 }}>
            <Tabs { ...contentPreviewTabsProps } />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

ConversionPage.displayName = config.displayName;
ConversionPage.propTypes = config.propTypes;
ConversionPage.defaultProps = config.defaultProps;

export default ConversionPage;
