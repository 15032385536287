import React from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Drawer,
  Dropdown,
  Image,
  Input,
  Layout,
  List,
  notification,
  Popover,
  Radio,
  Row,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  ArrowLeftOutlined,
  CloseCircleFilled,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import GridLayout from 'react-grid-layout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import html2canvas from 'html2canvas';
import JsPdf from 'jspdf';
import path from 'path';
import { Buttons } from '../../../components/atoms';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_META,
  AVATAR_TIKTOK,
  ICON_ADD_MORE_ACCOUNT,
  ICON_AUTOSAVE,
  ICON_CR_PLUS_EMPTY_CARD,
  CHECKED_VISUALIZATION,
  CHECKED_VISUALIZATION_PLACEHOLDER,
  ICON_CR_BAR_CHART,
  ICON_CR_LINE_CHART,
  ICON_CR_COMBINATION_CHART,
  ICON_CR_DONUT_CHART,
  ICON_CR_PLAIN_CARD,
  ICON_CR_TEXT_FIELD,
  ICON_CR_TABLE,
  ICON_TRASH,
  ICON_EXTERNAL_LINK,
  ICON_DOCUMENT,
} from '../../../assets-v1/figma-icons';
import { BDD_REPORT_COVER_REFERENCE } from '../../../assets-v1/images';
import { formatDateDisplay } from '../../../utils-v1/daterange.utils';
import { formatAvatar, removeHtmlTags } from '../../../utils-v1/string.utils';

import AccountMetricSelectionCreateReport from '../AccountMetricSelectionCreateReport/AccountMetricSelectionCreateReport.component';
import CardContent from '../CardContent/CardContent.component';
import RangePickerCreateReport from '../RangePickerCreateReport/RangePickerCreateReport.component';

import config from './Workspace.component.config';
import './Workspace.component.styles.less';

const { Content, Footer, Header, Sider } = Layout;
const { Paragraph, Text, Title } = Typography;

const visualizationConfigs = [
  { id: 0, type: 'section', label: 'Section', icon: null },
  { id: 1, type: 'bar_chart', label: 'Bar Chart', icon: ICON_CR_BAR_CHART },
  { id: 2, type: 'line_chart', label: 'Line Chart', icon: ICON_CR_LINE_CHART },
  { id: 3, type: 'combination_chart', label: 'Combination', icon: ICON_CR_COMBINATION_CHART },
  { id: 4, type: 'donut_chart', label: 'Donut Chart', icon: ICON_CR_DONUT_CHART },
  { id: 5, type: 'plain_card', label: 'Plain Card', icon: ICON_CR_PLAIN_CARD },
  { id: 6, type: 'text_field', label: 'Text Field', icon: ICON_CR_TEXT_FIELD },
  { id: 7, type: 'table', label: 'Table', icon: ICON_CR_TABLE },
];
const commonGridItemProperties = { h: 4, w: 4, minH: 4, minW: 4, /* maxH: 4 */ maxW: 16, isResizable: true };
const commonSectionProperties = { h: 1, w: 16, minH: 0.5, minW: 16, maxH: 1, maxW: 16, isResizable: false };

const Workspace = ({
  /* eslint-disable-next-line camelcase */
  available_metrics = { data: { googleAds: [], meta: [], tiktok: [] } },
  history,
  ...props
}) => {
  const {
    opened_report: openedReport,
  } = props;
  const userAccounts = [{ ...props?.me?.data }];

  const [headerState, setHeaderState] = React.useState({
    reportName: openedReport?.data?.report_name || 'Untitled',
    edit: false,
    disablePDF: false,
    loadPDF: false,
  });
  const [subheaderState, setSubheaderState] = React.useState({
    accountListSelectedPlatform: 'meta',
    accountListSearchText: '',
    loadPublicUrl: false,
  });
  const [sidemenuState, setSidemenuState] = React.useState({ open: false });
  const [workspaceState, setWorkspaceState] = React.useState({
    configList: [], height: 1450, layout: [], mounted: false,
  });
  const [configState, setConfigState] = React.useState({
    accounts: [],
    blend: false,
    card_id: null,
    cr_id: null,
    data: null,
    date_end_compare: null,
    date_start_compare: null,
    date_end_current: null,
    date_start_current: null,
    description: '',
    filter_by: 'campaign',
    filter_exclude: false,
    filter_value: [],
    loading: false,
    show_total: true,
    position: {},
    time_frame: '',
    title: '',
    use_compare: false,
    visualization_id: null,
    visualization_name: null,
  });
  const [filterState, setFilterState] = React.useState({
    open: false,
    search: '',
    filter_by: 'campaign',
    filter_exclude: false,
    filter_value: [],
  });
  const [createCardValidationMsg, setCreateCardValidationMsg] = React.useState('');
  const ref = React.createRef();

  React.useEffect(() => {
    // console.log('no dependency', configState)
    const newWorkspaceState = config.setWorkspaceStateBasedOnConfig(openedReport, available_metrics);
    setWorkspaceState({ ...newWorkspaceState, mounted: true });
    const newConfigState = config.setDefaultConfigState(openedReport)
    setConfigState({ ...newConfigState });
  },[]);

  React.useEffect(() => {
    if (workspaceState.mounted) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      if (Array.isArray(workspaceState.configList) && workspaceState.configList.length > 0) {
        notification.warn({
          message: `Fetching data for this report`,
          description: `This may take up to a few minutes. Please wait...`,
          duration: 10,
        })
        const reqs = [];
        for (let i=0; i<workspaceState.configList.length; i+=1) {
          const configItem = workspaceState.configList[i];
          if (configItem.visualization_id /* !== 0 */) {
            console.log(configItem?.card_id)
            reqs.push(comm.upsertData(configItem, workspaceState.configList, true, configItem?.visualization_id /* !== 0 */ ))
          };
        };
      };
    }
  }, [workspaceState.mounted]);

  React.useEffect(() => {
    const disablePDF = workspaceState.configList.filter(configItem => configItem?.visualization_id).map(configItem => configItem?.loading)?.filter(loading => loading).length > 0;
    setHeaderState({ ...headerState, disablePDF })
  }, [workspaceState.configList.filter(configItem => configItem?.visualization_id).map(configItem => configItem?.loading)?.filter(loading => loading).length]);

  const actions = {
    onAddItem: (type='card') => {
      const { layout } = workspaceState; let newLayout = [...layout];
      if (layout.length === 0) {
        newLayout = [{ ...(type === 'card' ? commonGridItemProperties : commonSectionProperties), x: 0, y: 0, i: '0' }];
        setWorkspaceState({ ...workspaceState, layout: [...newLayout] });
        return;
      };
      const missingItemIndex = layout.findIndex((item, index) => Number(item.i) !== index);
      const i = missingItemIndex > -1 ? `${missingItemIndex}` : `${layout.length}`;
      const ySet = [...new Set(layout.map(item => item.y))];
      const maxY = Math.max(...ySet);
      const itemsWithMaxY = layout.filter(item => item.y === maxY);
      const maxH = Math.max(...itemsWithMaxY.map(item => item.h));
      if (type === 'section') {
        newLayout = [...layout, { ...commonSectionProperties, x: 0, y: maxY + maxH, i }].sort((a,b) => Number(a.i) - Number(b.i));
        setWorkspaceState({ ...workspaceState, layout: [...newLayout] });
        return;
      };
      const itemWithMaxYMaxX = itemsWithMaxY.sort((a,b) => Number(b.x) - Number(a.x))[0];
      const { x: lastItemX, w: lastItemW } = itemWithMaxYMaxX;
      const newX = lastItemX + lastItemW > 12 ? 0 : lastItemX + lastItemW;
      const newY = lastItemX + lastItemW > 12 ? maxY + maxH : maxY;
      newLayout = [...layout, { ...commonGridItemProperties, x: newX, y: newY, i }].sort((a,b) => Number(a.i) - Number(b.i));
      setWorkspaceState({ ...workspaceState, layout: [...newLayout] });
    },
    onPopulateCard: () => {
      const { configList, layout } = workspaceState;
      const indexMatch = configList.findIndex(item => item?.card_id === configState?.card_id);
      let newConfigList = [...configList];
      if(indexMatch > -1) {
        newConfigList.splice(indexMatch, 1, { ...configState });
      } else {
        newConfigList = [...configList, { ...configState }];
      };
      setSidemenuState({ ...sidemenuState, open: false });
      setWorkspaceState({ ...workspaceState, configList: newConfigList });
    },
    onDeleteCard: (cardID='') => {
      const { configList, layout } = workspaceState;
      const match = configList.find(item => item?.card_id === `${cardID}`);
      if (match) {
        match.loading = 'Deleting card. Please wait...';
        match.visualization_id = match.visualization_id || 0;
        match.visualization_name = match.visualization_name || 'section';
        props.deleteCard(match.cr_detail_id)
          .then(() => {
            const newLayout = layout.filter(item => item.i !== `${cardID}`);
            const newConfigList = configList.filter(item => item?.card_id !== `${cardID}`);
            setWorkspaceState({ ...workspaceState, configList: [...newConfigList], layout: [...newLayout] });
          })
          .finally(() =>  {
            match.loading = false
          });
      } else {
        const newLayout = layout.filter(item => item.i !== `${cardID}`);
        const newConfigList = configList.filter(item => item?.card_id !== `${cardID}`);
        setWorkspaceState({ ...workspaceState, configList: [...newConfigList], layout: [...newLayout] });
      }
    },
    onDuplicateCard: (cardID='') => {
      const { configList, layout } = workspaceState;
      let newConfigList = [...configList]; let newLayout = [...layout];
      const match = configList.find(item => item?.card_id === `${cardID}`);
      if (match && match?.position) {
        const { position } = match;
        const missingItemIndex = layout.findIndex((item, index) => Number(item.i) !== index);
        const i = missingItemIndex > -1 ? `${missingItemIndex}` : `${layout.length}`;
        const newX = position.x; // position.x + position.w > 12 ? 0 : position.x + position.w;
        const newY =  position.y + position.h; // position.x + position.w > 12 ? position.y + position.h : position.h;
        const newPosition = { ...position, x: newX, y: newY, i };
        newLayout = [...layout, { ...newPosition }].sort((a,b) => Number(a.i) - Number(b.i));
        const newConfigItem = {
          ...match,
          title: match?.title ? `${match?.title} (duplicate)` : `(duplicate ^)`,
          cr_detail_id: null,
          card_id: i,
          position: { ...newPosition },
        };
        newConfigList = [...configList, { ...newConfigItem }].sort((a,b) => Number(a.card_id) - Number(b.card_id));
        comm.upsertData(newConfigItem, newConfigList, false, false);
        setWorkspaceState({
          ...workspaceState,
          configList: [...newConfigList],
          height: config.setLayoutHeightBasedOnMaxY([...newLayout]),
          layout: [...newLayout],
        });
      };
    },
    onEditCard: (cardID='', cardResizable=false) => {
      if (cardResizable) setSidemenuState({ ...sidemenuState, open: true });
      const { configList } = workspaceState;
      const match = configList.find(item => item?.card_id === `${cardID}`);
      if (match) {
        setConfigState({ ...match });
        setFilterState({
          filter_by: match?.filter_by,
          filter_exclude: match?.filter_exclude,
          filter_value: [...match?.filter_value],
        });
      } else {
        setConfigState({ ...configState, card_id: `${cardID}` });
        setFilterState({
          filter_by: 'campaign',
          filter_exclude: false,
          filter_value: [],
        })
      }
    },
    onLayoutChange: (layout=[]) => {
      const { configList } = workspaceState;
      const newConfigList = configList.filter(item => item?.card_id !== null).map(item => ({ ...item, position: { ...layout.find(el => el?.i === item?.card_id) } }));
      // handle on delete card > upsert data
      if (layout.length === workspaceState.layout.length) comm.upsertData(configState, newConfigList, configState.visualization_id !== null);
      // change workspace height
      const newLayoutH = config.setLayoutHeightBasedOnMaxY(layout);
      setWorkspaceState({ ...workspaceState, configList: newConfigList, height: newLayoutH, layout });
    },
    onResize: (layout=[], oldItem={}, newItem={}) => {
      // console.log({ oldItem, newItem });
      const xx = null;
    },
    onExportToPDF: () => {
      notification.warn({ message: 'Generating PDF. Please wait...' });
      setHeaderState({ ...headerState, loadPDF: true });
      const fileName = `${headerState.reportName}.pdf`;
      const font = { name: 'helvetica', sm: 8, md: 12, lg: 20, xl: 30, bold: 'bold', italic: 'italic', normal: 'normal' };
      const pdf = new JsPdf('landscape', 'px', 'a4', true);
      const pageHeight = pdf.internal.pageSize.height;
      const pageWidth = pdf.internal.pageSize.width;
      // ADD TITLE PAGE
      const coverImg = document.getElementById('ReportCoverImg');
      coverImg.style.display = 'block';
      html2canvas(coverImg, { scale: 2 })
        .then((canvas) => {
          const coverImgWidth = pageWidth;
          const hwImageRatio = coverImg?.offsetHeight/coverImg?.offsetWidth;
          const coverImgHeight = coverImgWidth * hwImageRatio;
          const formattedCoverImg = canvas.toDataURL('image/png');
          const marginX = (pageWidth - coverImgWidth)/2;
          const marginY = (pageHeight - coverImgHeight)/2;
          coverImg.style.display = 'none';
          pdf
            .addImage(formattedCoverImg, 'PNG', 0, marginY, coverImgWidth, coverImgHeight)
            .setTextColor(255,255,255)
            .setFont(font.name, font.italic).setFontSize(font.md)
            .text(`Created at: ${formatDateDisplay(new Date())}`, 0.8*pageWidth, 2*marginY)
            .setFont(font.name, font.bold).setFontSize(font.xl)
            .text(headerState.reportName, 0.4*pageWidth, 0.45*pageHeight)
            .setFont(font.name, font.normal).setFontSize(font.sm)
            .text(`Copyright © ${new Date().getFullYear()}, Boleh Dicoba Digital`, 0.42*pageWidth, marginY+coverImgHeight-10)
            .setFont(font.name, font.normal).setFontSize(font.md)
            .setTextColor(0,0,0)
        })
      // ADD PAGES
      const pages = workspaceState.configList
        .map(item => ({
          content: document.getElementById(`Content-${item.card_id}`) || document.getElementById('ExportButton'),
          description: document.getElementById(`Description-${item.card_id}`)?.innerHTML || item?.description,
          title: item?.title || '',
          vis_id: item.visualization_id,
        }))
        // .filter((item, idx) => idx < 1)
      const addPages = async(pageArr) => {
        for (let i=0; i<pageArr.length; i+=1) {
          const page = pageArr[i];
          let result;
          /* eslint-disable-next-line no-await-in-loop */
          await html2canvas(page.content, { scale: 2 })
            .then((canvas) => {
              pdf.addPage()
              if ([null,0,6].includes(page.vis_id)) {
                if (page.vis_id === 6) {
                  pdf
                    .setFont(font.name, font.bold).setFontSize(font.md)
                    .text(page.title, 0.1*pageWidth, 0.1*pageHeight)
                    .setFont(font.name, font.normal).setFontSize(font.md)
                    .text(removeHtmlTags(page.description), 0.1*pageWidth, 0.15*pageHeight)
                    .setFont(font.name, font.normal).setFontSize(font.md)
                } else {
                  pdf
                    .setFont(font.name, font.bold).setFontSize(font.lg)
                    .text(removeHtmlTags(page.description), 100, 200)
                    .setFont(font.name, font.normal).setFontSize(font.md)
                }
              } else {
                const imgWidth = 550;
                const hwImageRatio = page.content?.offsetHeight/page.content.offsetWidth;
                const imgHeight = imgWidth * hwImageRatio;
                const img = canvas.toDataURL('image/png');
                const marginX = (pageWidth - imgWidth)/2;
                const marginY = (pageHeight - imgHeight)/(page.description ? 4 : 2);
                pdf.addImage(img, 'PNG', marginX, marginY, imgWidth, imgHeight)
                pdf
                  .setFont(font.name, font.normal).setFontSize(font.md)
                  .text(page.description, marginX, 10+marginY+imgHeight)
                  .setFont(font.name, font.normal).setFontSize(font.md)
              }
            }).then(() => { result = Promise.resolve() })
        }
        return Promise.resolve()
      }
      addPages(pages).finally(() => {
        notification.success({ message: 'Successfully generated PDF!' })
        pdf.save(fileName)
        setHeaderState({ ...headerState, loadPDF: false });
      })
    },
  };

  const comm = {
    upsertData: (newConfigState={}, newConfigList=[], refetch=true, withLoading=true) => {
      if (newConfigState?.card_id) {
        const cardConfig = newConfigList.find(item => item.card_id === newConfigState.card_id);
        const cardConfigIndex = newConfigList.findIndex(item => item.card_id === newConfigState.card_id);
        if (cardConfig?.position?.i) {
          cardConfig.loading = withLoading && 'Upserting data. Please wait...';
          props.upsertCard(cardConfig)
            .then((upsertCardRes) => {
              cardConfig.cr_detail_id = upsertCardRes.cr_detail_id;
              if (refetch) {
                cardConfig.loading = 'Fetching data. Please wait...';
                props.getDataForCard(cardConfig.cr_detail_id)
                  .then((getDataForCardRes) => {
                    cardConfig.data = getDataForCardRes;
                  })
                  .catch((getDataForCardErr) => {
                    console.error(getDataForCardErr);
                    // notification.error({
                    //   message: `Failed to get data for card: ${cardConfig.title}`,
                    //   description: getDataForCardErr?.response?.data?.message || 'Something went wrong. Please try again later.'
                    // })
                  })
                  .finally(() => {
                    cardConfig.loading = false;
                    const { configList } = workspaceState;
                    const updatedConfigList = [...configList]; 
                    updatedConfigList.splice(cardConfigIndex, 1, cardConfig);
                    setWorkspaceState({ ...workspaceState, configList: updatedConfigList });
                  })
              } else {
                cardConfig.loading = false;
              }
            })
            .catch((upsertCardErr) => {
              console.error('upsertCard err', upsertCardErr)
            });
        }
      };
    },
  };

  const utilFuncs = {
    adjustLayoutBasedOnVisualization: () => {
      const { configList, layout } = workspaceState;
      const chartItems = configList.filter(item => [1,2,3,4].includes(item?.visualization_id)).map(item => item?.card_id);
      const statsItems = configList.filter(item => item?.visualization_id === 5).map(item => item?.card_id);
      const fieldItems = configList.filter(item => item?.visualization_id === 6).map(item => item?.card_id);
      const tableItems = configList.filter(item => item?.visualization_id === 7).map(item => item?.card_id);
      const { h, w, ...otherGridItemProperties } = commonGridItemProperties;
      const newLayout = layout.map(item => {
        let output = { ...item, ...(item.isResizable && otherGridItemProperties || commonSectionProperties) };
        if (chartItems.includes(item?.i)) output = { ...output, h: item.h >= 4 ? item.h : 4, minH: 4, w: item.w >= 8 ? item.w : 8, minW: 8 };
        if (statsItems.includes(item?.i)) output = { ...output, h: item.h >= 3 ? item.h : 4, minH: 3, w: item.w >= 8 ? item.w : 16, minW: 8 };
        if (tableItems.includes(item?.i)) output = { ...output, h: item.h >= 5 ? item.h : 8, minH: 4, w: item.w >= 16 ? item.w : 16, minW: 16 };
        if (fieldItems.includes(item?.i)) output = { ...output, h: item.h >= 1 ? item.h : 4, minH: 1, w: item.w >= 8 ? item.w : 8, minW: 8 };
        return output;
      });
      setWorkspaceState({ ...workspaceState, layout: newLayout });
    },
    validateCreateCard: () => {
      const {
        accounts,
        date_end_compare: dateEndCompare,
        date_end_current: dateEndCurrent,
        date_start_compare: dateStartCompare,
        date_start_current: dateStartCurrent,
        use_compare: useCompare,
        time_frame: timeFrame,
        visualization_id: visualizationID,
      } = configState;
      let output = true; let message = '';
      if (visualizationID !== 6) {
        if (!timeFrame) {
          message = `You haven't selected any time frame!`;
          output = false;
        }
        if (!dateStartCurrent || !dateEndCurrent) {
          message = `You haven't selected any start date and/or an end date!`;
          output = false;
        }
        if (useCompare && (!dateStartCompare || !dateEndCompare)) {
          message = `You haven't selected any start date and/or an end date to be compared with!`;
          output = false;
        };
        if (accounts.length === 0 ) {
          output = false;
          message = `You have not selected any account!`;
        } else {
          for (let i=0; i<accounts.length; i+=1) {
            const account = accounts[i];
            const metrics = visualizationID === 3 ? [...account.metrics.bar, ...account.metrics.line] : [...account.metrics];
            if (metrics.length === 0) message = `You haven't selected metrics for account: ${account.account_name}!`;
          };
          if (message) output = false;
        };
        if (visualizationID === null) {
          message = `You haven't selected any type of visualization!`;
          output = false;
        };
      };
      // if (message) notification.error({ message, duration: 10, placement: 'topLeft', style: { zIndex: 1200 } });
      setCreateCardValidationMsg(output ? '' : message);
      return output;
    },

    // PROPS GENERATOR GROUP
    getAccountMetricSelectionProps: (chartType='') => ({
      availableMetrics: available_metrics?.data,
      accountsByPlatform: { ...openedReport?.data?.accountsByPlatform },
      blend: configState.blend,
      chartType,
      onSelectAccount: (account={}) => {
        const { accounts, visualization_id: visualizationID } = configState;
        const selectedAccountPlatforms = [...new Set(accounts.map(act => act?.platform))];
        const attr = selectedAccountPlatforms.length > 1 ? 'key' : 'id';
        let newAccounts = [...accounts];
        const matchIndex = accounts.findIndex(el => el?.account_id === account?.account_id);
        if (matchIndex > -1) {
          newAccounts.splice(matchIndex, 1);
        } else {
          if ([1,2,4,5,7].includes(visualizationID)) {
            if (accounts.length >= 1) {
              newAccounts = [...newAccounts, account];
              newAccounts = newAccounts.map(act => ({
                ...act, metrics: newAccounts?.[0]?.metrics?.map(el => available_metrics?.data?.[act?.platform]
                  ?.find(m => el?.[attr] === m?.[attr]))?.filter((m, idx) => newAccounts.length > 1 ? idx === 0 : m),
              }))
            } else {
              newAccounts = [account];
            };
          } else if (visualizationID === 3) {
            if (accounts.length >= 1) {
              newAccounts = [...newAccounts, account];
              newAccounts = newAccounts.map(act => ({
                ...act, metrics: {
                  bar: newAccounts?.[0]?.metrics?.bar?.map(el => available_metrics?.data?.[act?.platform]
                    ?.find(m => el?.[attr] === m?.[attr]))?.filter((m, idx) => newAccounts.length > 1 ? idx === 0 : m),
                  line: newAccounts?.[0]?.metrics?.line?.map(el => available_metrics?.data?.[act?.platform]
                    ?.find(m => el?.[attr] === m?.[attr]))?.filter((m, idx) => newAccounts.length > 1 ? idx === 0 : m),
                },
              }))
            } else {
              newAccounts = [{ ...account, metrics: { bar: [], line: [] } }];
            }
          } else {
            newAccounts.push({ ...account, metrics: visualizationID === 3 ? { line: [], bar: [] } : [] });
          };
        };
        setConfigState({ ...configState, accounts: [...newAccounts] });
      },
      onSelectMetric: (account={}, metric={}, type='line') => {
        const { accounts, visualization_id: visualizationID } = configState;
        const selectedAccountPlatforms = [...new Set(accounts.map(act => act?.platform))];
        const attr = selectedAccountPlatforms.length > 1 ? 'key' : 'id';
        let newAccounts = [...accounts];
        const matchAccountIndex = accounts.findIndex(el => el?.account_id === account?.account_id);
        if (matchAccountIndex > -1) {
          const matchAccount = accounts?.[matchAccountIndex];
          const { metrics } = matchAccount;
          let newMetrics;
          if ([1,2,4,5,7].includes(visualizationID)) {
            newMetrics = [...metrics];
            const matchMetricIndex = metrics?.findIndex(el => el?.[attr] === metric?.[attr]);
            if (accounts.length > 1) {
              newMetrics = matchMetricIndex > -1 ? newMetrics.filter(el => el?.[attr] !== metric?.[attr]) : [metric];
            } else {
              if (matchMetricIndex > -1) {
                newMetrics.splice(matchMetricIndex, 1);
              } else {
                newMetrics.push(metric);
              };
            };
            newAccounts = newAccounts.map(act => ({ ...act, metrics: newMetrics?.map(el => available_metrics?.data?.[act?.platform]?.find(m => el?.[attr] === m?.[attr])) }));
          } else if (visualizationID === 3) {
            newMetrics = { ...metrics };
            const matchMetricIndex = newMetrics?.[type]?.findIndex(el => el?.[attr] === metric?.[attr]);
            if (matchMetricIndex > -1) {
              newMetrics = {
                bar: type === 'bar' ? newMetrics?.bar?.filter((m,i) => i !== matchMetricIndex) : newMetrics?.bar,
                line: type === 'line' ? newMetrics?.line?.filter((m,i) => i !== matchMetricIndex) : newMetrics?.line,
              };
            } else {
              newMetrics = {
                bar: type === 'bar' ? [...newMetrics?.bar, metric] : newMetrics?.bar,
                line: type === 'line' ? [...newMetrics?.line, metric] : newMetrics?.line,
              };
            };
            newAccounts = newAccounts.map(act => ({ ...act, metrics: {
              bar: newMetrics?.bar?.map(el => available_metrics?.data?.[act?.platform]?.find(m => el?.[attr] === m?.[attr])),
              line: newMetrics?.line?.map(el => available_metrics?.data?.[act?.platform]?.find(m => el?.[attr] === m?.[attr]))
            } }));
          } else {
            newMetrics = [...metrics];
            const matchMetricIndex = metrics.findIndex(el => el?.[attr] === metric?.[attr]);
            if (matchMetricIndex > -1) {
              newMetrics.splice(matchMetricIndex, 1);
            } else {
              newMetrics.push(metric);
            };
          };
          setConfigState({ ...configState, accounts: [...newAccounts] });
        }
      },
      selectedAccounts: chartType ? configState.accounts?.filter(acct => Array.isArray(acct?.metrics?.[chartType])) : configState.accounts,
      children: <Button id="addAccountsAndMetricsBtn" disabled={!configState.visualization_id} style={{ fontSize: 14, height: 35, textAlign: 'left', width: '100%' }}>
        {configState.accounts.length === 0 ? <span style={{ color: '#BABABA' }}>+ Choose Ad Accounts & Metrics...</span> : null}
        {configState.accounts.length > 0 ? <span>+ Add more Ad Accounts & Metrics</span> : null}
        <span hidden style={{ float: 'right' }}><DownOutlined style={{ color: '#BABABA' }} /></span>
      </Button>,
      placement: 'bottom',
    }),
    getAccountSelectionProps: () => ({
      accountsByPlatform: { ...openedReport?.data?.accountsByPlatform },
      onSelectAccount: (account={}) => {
        const { accounts } = configState;
        if (!accounts?.map(el => el?.account_id)?.includes(account?.account_id)) {
          const newAccounts = [ ...accounts, { ...account }];
          setConfigState({ ...configState, accounts: newAccounts });
        };
      },
      selectedAccounts: configState.accounts,
      children: <Button id="addPlatformAndAccountBtn" style={{ fontSize: 14, height: 35, textAlign: 'left', width: '100%' }}>
        {configState.accounts.length === 0 ? <span style={{ color: '#BABABA' }}>+ Choose ad account...</span> : null}
        {configState.accounts.length > 0 ? <span>+ Add more ad account</span> : null}
        <span hidden style={{ float: 'right' }}><DownOutlined style={{ color: '#BABABA' }} /></span>
      </Button>,
      placement: 'bottom',
    }),
    getFilterPopoverProps: () => ({
      open: filterState.open,
      trigger: 'click',
      overlayClassName: 'card-config-filter',
      content: <div style={{ fontSize: 14, padding: 15, width: 350 }}>
        <Text>Filter by: <b>{filterState.filter_by}</b></Text>&nbsp;&nbsp;
        <Divider type="vertical" />&nbsp;&nbsp;
        <Text>Exclude: <Checkbox defaultChecked={filterState.filter_exclude} onChange={(e) => setFilterState({ ...filterState, filter_exclude: e.target.checked })} /></Text><br style={{ marginBottom: 10 }} />
        <Text>Filter Keywords (press enter to append keyword): </Text><br />
        <Input
          allowClear
          value={filterState.search}
          onChange={(e) => {
            setFilterState({ ...filterState, search: e.target.value });
          }}
          onBlur={(e) => {
            const newFilterValue = [...new Set(filterState.filter_value)];
            if (!newFilterValue.includes(e.target.value.toLowerCase())) newFilterValue.push(e.target.value.toLowerCase());
            setFilterState({ ...filterState, filter_value: [...newFilterValue], search: '' });
          }}
          onPressEnter={(e) => {
            const newFilterValue = [...new Set(filterState.filter_value)];
            if (!newFilterValue.includes(e.target.value.toLowerCase())) newFilterValue.push(e.target.value.toLowerCase());
            setFilterState({ ...filterState, filter_value: [...newFilterValue], search: '' });
          }}
          style={{ marginBottom: 10, width: 300 }}
        /><br />
        <Space direction="horizontal" wrap style={{ marginBottom: 10 }}>
          {filterState.filter_value.map(val => (
            <Tag
              closable
              onClose={() => {
                const newFilterValue = [...new Set(filterState.filter_value)].filter(item => item !== val);
                setFilterState({ ...filterState, filter_value: [...newFilterValue] });
              }}
            >{val}</Tag>
          ))}
        </Space><br />
        <Buttons.Apply
          onClick={() => {
            console.log('apply')
            setConfigState({ ...configState, ...filterState });
            setFilterState({ ...filterState, search: '', open: false });
          }}
        />
      </div>,
      onClose: () => {
        setFilterState({
          filter_by: configState.filter_by,
          filter_exclude: configState.filter_exclude,
          filter_value: [...configState.filter_value],
        })
      },
    }),
    getRangePickerProps: () => ({
      defaultState: { ...configState },
      onApply: (rangePickerState={}) => {
        setConfigState({
          ...configState,
          ...rangePickerState,
        })
      },
    }),
    getTimeFrameSelectionProps: () => ({
      children: <Button style={{ fontSize: 14, height: 35, textAlign: 'left', width: '100%' }}>
        {!configState.time_frame ? <span style={{ color: '#BABABA' }}>Select time frame...</span> : null}
        {configState.time_frame ? <span>{configState.time_frame}</span> : null}
        <span style={{ float: 'right' }}><DownOutlined style={{ color: '#BABABA' }} /></span>
      </Button>,
      menu: {
        defaultValue: configState.time_frame,
        items: ['daily', 'weekly', 'monthly'].map(item => ({ label: item, key: item })),
        onClick: (item={}) => {
          setConfigState({ ...configState, time_frame: item.key });
        },
      },
      trigger: 'click',
    }),
    getTitleInputProps: () => ({
      defaultValue: configState.title,
      placeholder: 'title...',
      onBlur: (e) => {
        setConfigState({ ...configState, title: e.target.value });
      },
    }),

    // DETAILED COMPONENT RENDERING GROUP
    getAvatarByPlatform: (platform='meta') => {
      switch(platform) {
        case 'googleAds': return AVATAR_GOOGLE_ADS;
        case 'tiktok': return AVATAR_TIKTOK;
        default: return AVATAR_META;
      }
    },
    renderEmptyCard: (cardID='', cardResizable=false) => (
      <div className={`grid-item-card ${cardResizable === false ? 'section-item' : ''}`} key={`${cardID}`} id={`Card-${cardID}`}>
        <div className="item-card-header">
          <Image
            src={ICON_TRASH}
            alt="icon_trash"
            preview={false}
            width={25}
            onClick={() => actions.onDeleteCard(cardID)}
          />
        </div>
        <div hidden={cardResizable === true} style={{ fontSize: 20, textAlign: 'left', width: '80%' }} >
          <CKEditor
            editor={ClassicEditor}
            data={
              workspaceState.configList.find(item => item.card_id === `${cardID}`)?.description ||
              `<b>Section Name</b><br /><span>Sub-section</span>`
            }
            onBlur={(event, editor) => {
              const data = editor.getData();
              const newConfigState = { ...configState, description: data };
              const { configList } = workspaceState;
              const indexMatch = configList.findIndex(item => item?.card_id === configState?.card_id);
              let newConfigList = [...configList];
              if (indexMatch > -1) {
                newConfigList.splice(indexMatch, 1, { ...newConfigState });
              } else {
                newConfigList = [...configList, { ...newConfigState }];
              };
              setWorkspaceState({ ...workspaceState, configList: newConfigList });
              if (data !== configList?.[indexMatch]?.description) {
                comm.upsertData(newConfigState, newConfigList, false, false);
              };
              setConfigState({ ...config.setDefaultConfigState(openedReport) })
            }}
            onFocus={(event, editor) => {
              const { layout } = workspaceState;
              const position = layout.find(item => item.i === `${cardID}`);
              if (position) {
                const data = editor.getData();
                const newConfigState = workspaceState.configList.find(item => item.card_id === `${cardID}`)
                  || config.setDefaultConfigState(openedReport);
                setConfigState({ ...newConfigState, card_id: `${cardID}`, description: data, position, visualization_id: 0, visualization_name: 'section' });
              }
            }}
          />
        </div>
        <div hidden={cardResizable === false} className="item-card-body">
          <Space
            direction="vertical"
            size="small"
            style={{ cursor: 'pointer', textAlign: 'center' }}
            onClick={() => actions.onEditCard(`${cardID}`, cardResizable)}
          >
            <ICON_CR_PLUS_EMPTY_CARD />
            <Text style={{ color: '#018FFC', fontSize: 14 }}>Add New Component</Text>
            <Text>{cardID}</Text>
          </Space>
        </div>
      </div>
    ),
    renderFilledCard: (cardID='', cardResizable=false) => (
      <div
        className="grid-item-card populated-card"
        key={`${cardID}`}
        id={`Card-${cardID}`}
      >
        <CardContent
          cardConfig={{ ...workspaceState.configList.find(item => item?.card_id === `${cardID}`)}}
          adjustLayoutFunc={() => utilFuncs.adjustLayoutBasedOnVisualization()}
          upsertDataFunc={() => comm.upsertData(configState, workspaceState.configList)}
          onEdit={() => actions.onEditCard(`${cardID}`, cardResizable)}
          onDelete={() => actions.onDeleteCard(`${cardID}`)}
          onDuplicate={() => actions.onDuplicateCard(`${cardID}`)}
          onFocusDescription={() => {
            setTimeout(() => {
              const match = workspaceState.configList.find(item => item?.card_id === `${cardID}`);
              setConfigState({ ...match });
            }, 500);
          }}
          onBlurDescription={(description='') => {
            const { configList } = workspaceState;
            const newConfigList = [...configList];
            const matchIndex = configList.findIndex(item => item?.card_id === `${cardID}`);
            newConfigList.splice(matchIndex, 1, { ...configState, description });
            if (description !== configList?.[matchIndex]?.description) comm.upsertData(configState, newConfigList, false, false)
            setWorkspaceState({ ...workspaceState, configList: [...newConfigList] });
          }}
          availableMetrics={available_metrics?.data}
          selectedAccounts={openedReport?.data?.accounts}
        />
      </div>
    ),
    renderRTE: () => (
      <CKEditor
        editor={ClassicEditor}
        data={configState?.description}
        onBlur={(event, editor) => {
          const data = editor.getData();
          setConfigState({ ...configState, description: data })
        }}
        config={{
          placeholder: 'Insert body text here...',
          toolbar: {
            items: [
              'undo', 'redo', '|',
              'outdent', 'indent', '|',
              'fontSize', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
              'bold', 'italic', 'underline', 'subscript', 'superscript', '|',
              'bulletedList', 'numberedList', '|',
            ],
          },
          fontFamily: {
            options: [
              'default',
              'Arial, Helvetica, sans-serif',
              'Courier New, Courier, monospace',
              'Georgia, serif',
              'Lucida Sans Unicode, Lucida Grande, sans-serif',
              'Tahoma, Geneva, sans-serif',
              'Times New Roman, Times, serif',
              'Trebuchet MS, Helvetica, sans-serif',
              'Verdana, Geneva, sans-serif'
            ],
            supportAllValues: true
          },
          fontSize: {
            options: [ 10, 12, 14, 'default', 18, 20, 22 ],
            supportAllValues: true
          },
        }}
      />
    ),
    renderSelectedAccountsPopoverContent: () => (
      <div>
        <div style={{ marginBottom: 15 }}>
          <span style={{ fontSize: 18 }}>Ad Account</span>
          <span style={{ float: 'right' }}>
            <Popover
              content={<>
                <Radio.Group
                  defaultValue={subheaderState.accountListSelectedPlatform}
                  onChange={(e) => setSubheaderState({ ...subheaderState, accountListSelectedPlatform: e.target.value })}
                  value={subheaderState.accountListSelectedPlatform}
                >
                  <Space direction="vertical" size="middle">
                    <Radio value="meta">Meta</Radio>
                    <Radio value="googleAds">Google Ads</Radio>
                    <Radio value="tiktok">TikTok</Radio>
                  </Space>
                </Radio.Group>
              </>}
              overlayClassName="selected-accounts-platform-popover"
              trigger="click"
              placement="bottom"
            >
              <Button type="default" style={{ fontSize: 14 }}>
                <span style={{ color: '#9CA0A6'}}>Platform:</span>&nbsp;
                <span style={{ transform: 'translateY(-1px)' }}>
                  <Image
                    src={utilFuncs.getAvatarByPlatform(subheaderState.accountListSelectedPlatform)}
                    alt="platform_account"
                    preview={false}
                    width={15}
                  />
                </span>&nbsp;
                <span>Meta</span>
              </Button>
            </Popover>
          </span>
        </div>
        <Input
          defaultValue={null}
          onChange={(e) => {
            setSubheaderState({ ...subheaderState, accountListSearchText: e.target.value });
          }}
          placeholder="Search or Enter Ad Account"
          prefix={<SearchOutlined style={{ color: '#BABABA', marginLeft: 10 }} />}
        />
        <Paragraph style={{ color: '#999999', fontSize: 14, marginTop: 8 }}>
          Total&nbsp;
          {openedReport?.data?.accounts?.filter(
            account => (account?.platform === subheaderState.accountListSelectedPlatform)
          )?.length}
          &nbsp;Ad Accounts
        </Paragraph>
        <List
          renderItem={(item) => <List.Item>
            <Space direction="horizontal" size="large">
              <Image
                src={utilFuncs.getAvatarByPlatform(subheaderState.accountListSelectedPlatform)}
                alt="platform_account"
                preview={false}
                width={40}
              />
              <Space direction="vertical" size="small">
                <Text style={{ fontSize: 16 }}>{item?.account_name}</Text>
                <Text style={{ color: '#999999', fontSize: 14 }}>{item?.account_id}</Text>
              </Space>
            </Space>
          </List.Item>}
          dataSource={openedReport?.data?.accounts?.filter(
            account => (account?.platform === subheaderState.accountListSelectedPlatform) && (account?.account_name?.toLowerCase()?.includes(subheaderState.accountListSearchText.toLowerCase()))
          )}
        />
      </div>
    ),
    renderSelectedAccountTags: (chartType='') => (
      <Space size={[0, 'small']} style={configState.accounts.length === 0 && { display: 'none' } || { marginBottom: 10 }} wrap>
        {configState.accounts.filter(el => configState.visualization_id === 3 ? Array.isArray(el?.metrics?.[chartType]) : el).map((el, i) => (
          <Popover
            content={<List
              dataSource={(configState.visualization_id === 3 ? el?.metrics?.[chartType] : el?.metrics)
                ?.map(m => available_metrics?.data?.[el?.platform]?.find(met => met?.id === m?.id)?.label)
              }
              renderItem={(item) => <List.Item style={{ fontSize: 14 }}>{item}</List.Item>}
              header={<Text style={{ fontWeight: 700 }}>Metrics {chartType ? `(${chartType})` : null}</Text>}
              size="small"
            />}
            overlayInnerStyle={{ padding: '4px 12px', width: 150 }}
            overlayStyle={{ zIndex: 1090 }}
          >
            <Tag
              className="selected-account-tag"
              key={el?.account_id}
              style={i > 1 && { display: 'none' } || { borderRadius: 30 }}
            >
              <span style={{ fontSize: 14 }}>
                <Image
                  src={utilFuncs.getAvatarByPlatform(el?.platform)}
                  alt="platform_avatar"
                  preview={false}
                  width={18}
                  style={{ transform: 'translateY(-1px)' }}
                />&nbsp;&nbsp;{el?.account_name}&nbsp;&nbsp;
              </span>
            </Tag>
          </Popover>
        ))}
        <Popover
          content={<div>
            {configState.accounts.filter((acct, idx) => idx > 1).filter(acct => configState.visualization_id === 3 ? Array.isArray(acct?.metrics?.[chartType]) : acct).map((acct, idx) => (<>
              <Divider type="horizontal" style={{ margin: '6px  0' }} hidden={idx === 0} />
              <List
                header={<span style={{ fontSize: 14 }}>
                  <Image
                    src={utilFuncs.getAvatarByPlatform(acct?.platform)}
                    alt="platform_avatar"
                    preview={false}
                    width={18}
                    style={{ transform: 'translateY(-1px)' }}
                  />&nbsp;&nbsp;{acct?.account_name}&nbsp;&nbsp;
                </span>}
                dataSource={(configState.visualization_id === 3 ? acct?.metrics?.[chartType] : acct?.metrics)
                  ?.map(m => available_metrics?.data?.[acct?.platform]?.find(met => met?.id === m?.id)?.label)
                }
                renderItem={(item, num) => <List.Item style={{ fontSize: 14 }}>{num+1}. {item}</List.Item>}
                size="small"
              />
            </>))}
          </div>}
          overlayInnerStyle={{ padding: '4px 12px' }}
          overlayStyle={{ zIndex: 1090 }}
          placement="topLeft"
        >
          <Tag
            className="selected-account-tag"
            key="more_selected_accounts"
            style={configState.accounts.length < 3 && { display: 'none' } || { borderRadius: 30, fontSize: 14, lineHeight: 1.6 }}
          ><div style={{ textAlign: 'center', width: 15 }}>+{configState.accounts.length-2}</div></Tag>
        </Popover>
      </Space>
    ),
    renderVisualizationSelection: () => (
      <Radio.Group
        defaultValue={configState.visualization_id}
        onChange={(e) => {
          const { accounts, description, visualization_id: visID } = configState;
          setConfigState({
            ...configState,
            visualization_id: Number(e.target.value),
            visualization_name: visualizationConfigs.find(el => Number(el.id) === Number(e.target.value)).type,
            accounts: accounts.length > 0 ? accounts.map(act => ({
              ...act, metrics: ((visID === 3 && e.target.value !== 3) && [] || ((visID !== 3 && e.target.value === 3) && { bar: [], line: [] } || act.metrics)),
            })) : [],
            description: (visID === 6 && e.target.value !== 6) && '' || ((visID !== 6 && e.target.value === 6) && '' || description),
          })}
        }
        value={configState.visualization_id}
      >
        <Space direction="horizontal" wrap>
          {visualizationConfigs.filter(visualizationConfig => visualizationConfig.id > 0).map((visualizationConfig, index) => (
            <Radio value={visualizationConfig.id}>
              <div
                className={`visualization ${configState.visualization_id === visualizationConfig.id ? 'visualization-selected' : ''}`}
              >
                {configState.visualization_id === visualizationConfig.id ?
                  <CHECKED_VISUALIZATION className="checked-visualization" /> :
                  <CHECKED_VISUALIZATION_PLACEHOLDER className="checked-visualization" />
                }
                <visualizationConfig.icon
                  className={configState.visualization_id === visualizationConfig.id ? 'visualization-icon-selected' : 'visualization-icon'}
                />
              </div>
              <div
                className={`visualization-type-label${configState.visualization_id === visualizationConfig.id ? '-selected' : ''}`}
                style={{ textAlign: 'center' }}
              >{visualizationConfig.label}</div>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    ),
  };
  const getHeaderContent = () => (
    <Row gutter={[16, 16]}>
      <Col sm={24} md={24} lg={12}>
        <Space direction="horizontal" size="large">
          <ArrowLeftOutlined
            onClick={() => {
              // props.updateOpenedReportName({ ...openedReport?.data, last_updated: Date.now()});
              const { configList } = workspaceState;
              Promise.all(configList.map(item => comm.upsertData(item, configList, false, true)))
                .then(() => {
                  /* eslint-disable no-param-reassign */
                  history.location.pathname = '/custom-report';
                  history.push('custom-report');
                })
            }}
          />
          <span id="ReportName">
            {headerState.edit ? <Input
              defaultValue={headerState.reportName}
              onPressEnter={() => {
                props.updateOpenedReportName({ ...openedReport?.data, report_name: headerState.reportName });
                setHeaderState({ ...headerState, edit: false });
              }}
              onBlur={() => {
                props.updateOpenedReportName({ ...openedReport?.data, report_name: headerState.reportName });
                setHeaderState({ ...headerState, edit: false })
              }}
              onChange={(e) => {
                setHeaderState({ ...headerState, reportName: e.target.value })
              }}
              value={headerState.reportName}
            /> : <span
              onClick={() => setHeaderState({ ...headerState, edit: true })}
            >{headerState.reportName}</span>}
          </span>
        </Space>
        <Divider type="vertical" />
        <ICON_AUTOSAVE style={{ transform: 'translateY(10px)' }} />
        <span style={{ color: '#9CA0A6', fontSize: 12, marginLeft: 5 }}>Changes Autosaved!</span>
        <Divider type="vertical" />
        <Button
          type="default"
          onClick={() => actions.onAddItem('card')}
        >+ Add Card</Button>
        <Divider type="vertical" />
        <Button
          type="default"
          onClick={() => actions.onAddItem('section')}
        >+ Add Section</Button>
        <Divider type="vertical" />
        <Button
          danger
          onClick={() => {
            setWorkspaceState({ ...workspaceState, layout: [], configList: [] });
            document.getElementById('CustomReportWorkspaceContent').scrollTo({ y: 0, behavior: 'smooth' });
          }}
        >- Clear Workspace</Button>
      </Col>
      <Col sm={24} md={24} lg={12} style={{ textAlign: 'right' }}>
        <Space direction="horizontal" size="large">
          <div
            style={{ cursor: 'pointer', transform: 'translateY(-2px)' }}
          >
            {userAccounts.map((userAccount, index) => {
              if (userAccount.name) {
                return(
                  <Avatar
                    size="large"
                    style={{
                      backgroundColor: config.avatarBgColorSet[index],
                      color: '#FFFFFF',
                      fontSize: 16,
                      fontWeight: 700,
                      transform: `translateX(${(userAccounts.length-index)*15}px) translateY(-2px)`,
                      zIndex: (userAccounts.length-index)*100,
                    }}
                  >{formatAvatar(userAccount.name)}</Avatar>
                );
              };
              return (
                <ICON_ADD_MORE_ACCOUNT style={{ visibility: 'hidden', transform: 'translateY(15px)' }} />
              );
            })}
          </div>
          <span hidden><Buttons.Share /></span>
          <span><Buttons.Export onClick={actions.onExportToPDF} loading={headerState.loadPDF} disabled={headerState.disablePDF} /></span>
        </Space>
      </Col>
    </Row>
  );
  const getSubheaderContent = () => (
    <Row gutter={[16, 16]}>
      <Col sm={24} md={24} lg={12}>
        <Space direction="horizontal" size="middle">
          <Popover
            content={utilFuncs.renderSelectedAccountsPopoverContent()}
            overlayClassName="workspace-selected-accounts-popover"
            placement="bottomRight"
            trigger="click"
          >
            <Button
              type="text"
              icon={<ICON_DOCUMENT style={{ transform: 'translateY(4px)'}} />}
            >
              &nbsp;<span style={{ color: '#A6AAB1' }}>Ad Account:</span>&nbsp;&nbsp;
              <span>{openedReport?.data?.accounts?.length} Accounts</span>&nbsp;&nbsp;
              <DownOutlined style={{ color: '#BABABA', transform: 'translateY(1px)' }} />
            </Button>
          </Popover>
        </Space>
      </Col>
      <Col sm={24} md={24} lg={12} style={{ textAlign: 'right' }}>
        <Button
          loading={subheaderState.loadPublicUrl}
          type="default"
          onClick={() => {
            setSubheaderState({ ...subheaderState, loadPublicUrl: true });
            props.getPublicReportId(openedReport?.data?.id)
              .then((res) => {
                const url = `${window.location.origin}/pub/crp/${res?.data}`
                navigator.clipboard.writeText(`${url}`);
                notification.success({
                  message: 'Success',
                  description: 'Public preview link is successfully copied to clipboard!',
                })
              })
              .catch((err) => {
                // console.error(err);
                notification.error({
                  message: 'Error',
                  description: 'Failed to copy public preview link',
                })
              })
              .finally(() => setSubheaderState({ ...subheaderState, loadPublicUrl: false }))
          }}
          icon={<ICON_EXTERNAL_LINK style={{ transform: 'translateY(3px)' }} />}
        >&nbsp;&nbsp;Copy & Preview for Public</Button>
      </Col>
    </Row>
  );
  const getSideMenuContent = () => (<>
    <div hidden={!createCardValidationMsg} className="create-card-error-message">{createCardValidationMsg}</div>
    <div className="blend-data-prompt-section" /* onMouseEnter={() => console.log({ configState })} */ >
      <Paragraph style={{ fontSize: 14 }}>
        Blend Data Account?
        <span style={{ float: 'right' }}>
          <Switch
            defaultChecked={configState.blend}
            onChange={(on) => {
              setConfigState({ ...configState, blend: on });
            }}
            size="small"
            style={{ transform: 'scale(1.1)' }}
          />
        </span>
      </Paragraph>
      <Text style={{ color: '#9CA0A6', fontSize: 12 }}>
        You can blend your account data with 1 metric between many ad accounts
      </Text>
    </div>
    <div className="card-configuration-section">
      <Text>Title</Text><br />
      <Input { ...utilFuncs.getTitleInputProps() } />
      <br />
      <Text>Card Visualization</Text><br />
      {utilFuncs.renderVisualizationSelection()}
      <div style={{ marginTop: 10 }}><Popover { ...utilFuncs.getFilterPopoverProps() }><Buttons.Filter onClick={() => setFilterState({ ...filterState, open: true })} /></Popover></div>
      {configState.visualization_id === 6 ? <>
        <Text>Body Text</Text><br />
        {utilFuncs.renderRTE()}
      </> : <>
        <div hidden={configState.visualization_id === null} style={{ backgroundColor: '#FCEBB0', marginTop: 5, padding: 5 }}>
          <Text style={{ lineHeight: 1 }}>
            <span hidden={!([1,2,3,4,5,7].includes(configState.visualization_id))}>
              You can select either <b>1 Account & multiple Metrics</b> or <b>multiple Accounts & 1 Metric</b>, including <b>1 Account & 1 Metric</b>.
              <br /><span hidden={![4,7].includes(configState.visualization_id)}><b>Comparison between 2 date ranges is not available!</b></span>
            </span>
            <span hidden={configState.visualization_id !== 8}>
              You can select <b>multiple Accounts & multiple Metrics</b>, including <b>1 Account & 1 Metric</b>
            </span>
          </Text>
        </div>
        {configState.visualization_id === 7 && <>
          <Text>Show Total:</Text>&nbsp;&nbsp;
          <Checkbox
            defaultChecked={configState.show_total}
            onChange={(e) => setConfigState({ ...configState, show_total: e.target.checked })}
          /><br />
        </> || null}
        {configState.visualization_id === 3 && <>
          <Text>Bar Chart</Text><br />
          {utilFuncs.renderSelectedAccountTags('bar')}
          <AccountMetricSelectionCreateReport { ...utilFuncs.getAccountMetricSelectionProps('bar') } />
          <Text>Line Chart</Text><br />
          {utilFuncs.renderSelectedAccountTags('line')}
          <AccountMetricSelectionCreateReport { ...utilFuncs.getAccountMetricSelectionProps('line') } />
        </> || <>
          <Text>Add Ad Accounts & Metrics</Text>&nbsp;&nbsp;
          <Text hidden={configState.visualization_id} style={{ color: '#FF683A', fontWeight: 700 }}>( Select a visualization type first! )</Text><br />
          {utilFuncs.renderSelectedAccountTags()}
          <AccountMetricSelectionCreateReport { ...utilFuncs.getAccountMetricSelectionProps() } />
        </>}
        <Text>Date Range</Text><br />
        <RangePickerCreateReport { ...utilFuncs.getRangePickerProps() } />
        <Text>Time Frame</Text><br />
        <Dropdown { ...utilFuncs.getTimeFrameSelectionProps() } />
      </>}
    </div>
    <footer className="sidemenu-content-footer">
      <Button
        type="primary"
        size="large"
        style={{ width: '100%' }}
        onClick={() => {
          if (utilFuncs.validateCreateCard()) {
            actions.onPopulateCard();
            // setConfigState({ ...config.setDefaultConfigState(openedReport) })
          };
        }}
      >Create Card</Button>
    </footer>
  </>);
  const getWorkspaceContent = () => (
    <div ref={ref}>
      <GridLayout
        className="workspace-layout"
        layout={workspaceState.layout}
        cols={16}
        rowHeight={110}
        width={window.innerWidth-100}
        onLayoutChange={actions.onLayoutChange}
        onResize={actions.onResize}
      >
        {workspaceState.layout.map((card, id) => {
          const cardID = Number(card.i);
          const cardResizable = card.isResizable;
          const cardHasConfig = workspaceState.configList.find(item => item?.card_id === `${cardID}`);
          return cardHasConfig && cardResizable ? utilFuncs.renderFilledCard(cardID, cardResizable) : utilFuncs.renderEmptyCard(cardID, cardResizable);
        })}
      </GridLayout>
    </div>
  );

  return(
    <Layout id="CustomReportWorkspaceLayout" style={{ height: workspaceState.height, minHeight: '100vh' }}>
      <Header id="CustomReportWorkspaceHeader">{getHeaderContent()}</Header>
      <Layout /* onMouseEnter={() => console.log({ workspaceState })} */ >
        <Header id="CustomReportWorkspaceSubheader">{getSubheaderContent()}</Header>
        <Content id="CustomReportWorkspaceContent" style={{ height: workspaceState.height }}>{getWorkspaceContent()}</Content>
        <Image id="ReportCoverImg" src={BDD_REPORT_COVER_REFERENCE} alt="report_cover" preview={false} />
      </Layout>
      <Drawer
        className="custom-report-workspace-sidemenu"
        closable
        closeIcon={null}
        destroyOnClose
        extra={<Space>
          <CloseCircleFilled
            style={{ backgroundColor: '#000', borderRadius: '50%', cursor: 'pointer', color: '#D0D5DD', transform: 'scale(1.1)' }}
            onClick={() => {
              setSidemenuState({ ...sidemenuState, open: false });
            }}
          />
        </Space>}
        onClose={() => {
          setSidemenuState({ ...sidemenuState, open: false });
          setConfigState({ ...config.setDefaultConfigState(openedReport) });
        }}
        open={sidemenuState.open}
        placement="right"
        title="Add Data Component"
        width={550}
      >{getSideMenuContent()}</Drawer>
    </Layout>
  );
};

Workspace.displayName = config.displayName;
Workspace.propTypes = config.propTypes;
Workspace.defaultProps = config.defaultProps;

export default Workspace;