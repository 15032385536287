import React from 'react';
import { Col, Image, Input, List, Popover, Radio, Row, Space, Tag, Typography } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_META,
  AVATAR_TIKTOK,
  CHECK_SELECTED_ACCOUNT,
  CHECK_SELECTED_ACCOUNT_PLACEHOLDER,
  ICON_PLUS_ACCOUNT_SELECTION_ALT,
} from '../../../assets-v1/figma-icons';

import config from './AccountMetricSelectionCreateReport.component.config';
import './AccountMetricSelectionCreateReport.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const AccountMetricSelectionCreateReport = ({
  accountsByPlatform = {},
  availableMetrics = { googleAds: [], meta: [], tiktok: [] },
  blend = false,
  chartType = '',
  children = null,
  onSelectAccount = (acct) => console.log(acct),
  onSelectMetric = (metric) => console.log(metric),
  placement = 'right',
  selectedAccounts = [],
  trigger = 'click',
  ...props
}) => {
  const [platform, setPlatform] = React.useState('meta');
  const [searchAccount, setSearchAccount] = React.useState('');
  const [searchMetric, setSearchMetric] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const platformSelections = [
    { key: 'meta', avatar: AVATAR_META, text: 'Facebook' },
    { key: 'tiktok', avatar: AVATAR_TIKTOK, text: 'TikTok' },
    { key: 'googleAds', avatar: AVATAR_GOOGLE_ADS, text: 'Google Ads' },
  ];
  const getPopoverContent = () => (
    <div id="AccountMetricSelectionCreateReport">
      <Paragraph className="section-title">Selection Account</Paragraph>
      <Radio.Group name="platformSelection" defaultValue={platform} onChange={(e) => setPlatform(e.target.value)}>
        <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
          {platformSelections.map(el => <Col sm={24} md={24} lg={12} key={el.key}>
            <div className="platform-selection-cols">
              <Radio value={el.key}>
                <Image src={el.avatar} alt="platform_avatar" preview={false} width={25} />
                &nbsp;&nbsp;{el.text}
              </Radio>
            </div>
          </Col>)}
        </Row>
      </Radio.Group>
      <Paragraph className="section-title">Select Ad Account</Paragraph>
      <Input
        placeholder="Search or enter Ad Account"
        prefix={<SearchOutlined style={{ color: '#A6AAB1', paddingLeft: 10 }} />}
        defaultValue={searchAccount}
        onChange={(e) => setSearchAccount(e.target.value)}
      />
      <Text style={{ color: '#999999', fontSize: '0.9rem' }}>
        {accountsByPlatform?.[platform]?.length} Ad Account on {platformSelections?.find(el => el?.key === platform)?.text}
      </Text>
      <br />
      <List
        dataSource={accountsByPlatform?.[platform]
          ?.filter(el => el?.account_name?.toLowerCase()?.includes(searchAccount?.toLowerCase()))
        }
        renderItem={(item) => (
          <Popover
            content={<div style={{ padding: 15 }}>
              <Paragraph style={{ fontSize: 16 }}>
                Search Metrics <span hidden={!blend} style={{ color: '#59BC7D', float: 'right' }}>(Blend On)</span>
              </Paragraph>
              <Input
                placeholder="Search metric"
                prefix={<SearchOutlined style={{ color: '#A6AAB1', paddingLeft: 10 }} />}
                defaultValue={searchMetric}
                onChange={(e) => setSearchMetric(e.target.value)}
              />
              <Text style={{ color: '#999999', fontSize: '0.9rem' }}>
                {availableMetrics?.[platform]?.length} Metrics
              </Text><br />
              <List
                dataSource={
                  availableMetrics?.[platform]?.filter(metric => blend ? metric?.blend : metric?.id)
                    ?.filter(metric => metric?.label?.toLowerCase()?.includes(searchMetric?.toLowerCase()))
                }
                renderItem={(jtem) => <List.Item
                  style={{ cursor: 'pointer' }}
                  onClick={() => onSelectMetric({ ...item, metrics: item?.metrics || [] }, jtem, chartType)}
                >
                  <Space direction="horizontal" size="middle">
                    <Image
                      src={(chartType ? selectedAccounts?.find(el => el?.account_id === item.account_id)?.metrics?.[chartType] : selectedAccounts?.find(el => el?.account_id === item.account_id)?.metrics)?.find(el => el?.id === jtem?.id) ?
                        CHECK_SELECTED_ACCOUNT : CHECK_SELECTED_ACCOUNT_PLACEHOLDER
                      }
                      alt={(chartType ? selectedAccounts?.find(el => el?.account_id === item.account_id)?.metrics?.[chartType] : selectedAccounts?.find(el => el?.account_id === item.account_id)?.metrics)?.find(el => el?.id === jtem?.id) ?
                        'checked_account' : 'unchecked_account'
                      }
                      preview={false}
                      width={44}
                    />
                    <Space direction="vertical" size="small">
                      <Text style={{ fontSize: 16 }}>{jtem?.label}</Text>
                      <Text style={{ color: '#999999', fontSize: 14 }}>{item?.account_name}</Text>
                    </Space>
                  </Space>
                </List.Item>}
                style={{ height: 300, overflowY: 'scroll' }}
              />
            </div>}
            placement="left"
            trigger="hover"
            overlayClassName="account-metric-selection-popover"
            overlayStyle={selectedAccounts?.map(account => account?.account_id)?.includes(item?.account_id) ? {} : { display: 'none' }}
          >
            <List.Item
              className="account-selection-list-item"
              onClick={() => { onSelectAccount({ ...item, metrics: item?.metrics || [] }); /* setOpen(false) */ }}
            >
              <Space direction="vertical">
                <Space direction="horizontal" size={16}>
                  <Image
                    src={selectedAccounts?.map(account => account?.account_id)?.includes(item?.account_id) ?
                      CHECK_SELECTED_ACCOUNT : CHECK_SELECTED_ACCOUNT_PLACEHOLDER
                    }
                    alt={selectedAccounts?.map(account => account?.account_id)?.includes(item?.account_id) ?
                      'checked_account' : 'unchecked_account'
                    }
                    preview={false}
                    width={46}
                  />
                  <Space direction="vertical" size={1}>
                    <Text>{item?.account_name}</Text>
                    <Text style={{ fontSize: '0.8rem', color: '#999999' }}>{item?.account_id}</Text>
                  </Space>
                </Space>
                <Space direction="horizontal" wrap>
                  {(chartType ? selectedAccounts?.find(el => el?.account_id === item.account_id)?.metrics?.[chartType] : selectedAccounts?.find(el => el?.account_id === item.account_id)?.metrics)?.map(m => <Tag>{m?.label}</Tag>)}
                </Space>
              </Space>
            </List.Item>
          </Popover>
        )}
        style={{ height: 300, overflowY: 'scroll' }}
      />
    </div>
  );

  const constructPopoverProps = () => ({
    children,
    content: getPopoverContent(),
    placement,
    trigger,
    open,
    onOpenChange: (opened=false) => setOpen(opened),
    overlayClassName: 'account-metric-selection-create-report',
  })

  return(
    <Popover { ...constructPopoverProps() } />
  );
};

AccountMetricSelectionCreateReport.displayName = config.displayName;
AccountMetricSelectionCreateReport.propTypes = config.propTypes;
AccountMetricSelectionCreateReport.defaultProps = config.defaultProps;

export default AccountMetricSelectionCreateReport;