import PropTypes from 'prop-types';
const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `Buttons`;
const propTypes = {
  hidden: pt.bool,
  onClick: pt.func,
  title: pt.node,
  text: pt.node,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };