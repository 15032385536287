import PropTypes from 'prop-types';
import TYPES from '../../redux-v1/types';
import metrics from '../../constants-v1/metrics-config/website';
import { reqFormat as rpf } from '../../utils-v1/request.utils';

const pt = PropTypes;
const { oneOf, oneOfType }= pt;

const displayName = 'Website';
const propTypes = {};
const defaultProps = { dateRangeValue: PropTypes.object };
const requestConfig = [
  {
    endpoint: 'website/data/overview',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.website.today_sales,
        metrics.website.total_cancel,
        metrics.website.total_pending,
        metrics.website.total_shipping,
        metrics.website.order_paid,
        metrics.website.order_cancel,
        metrics.website.order_pending,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_WEBSITE_OVERVIEW_STATS,
  },
  {
    endpoint: 'website/data/overview/paid-order-distribution',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_WEBSITE_PAID_ORDER_DISTRIBUTION_LIST,
  },
  {
    endpoint: 'website/data/map',
    method: 'post',
    reqparams: {
      metricsConfig: [],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_WEBSITE_PAID_ORDER_DISTRIBUTION_MAP,
  },
  {
    endpoint: 'website/data/periodic',
    method: 'post',
    reqparams: {
      metricsConfig: [
        metrics.website.sales,
        metrics.website.order,
        metrics.website.aov,
      ],
    },
    reqparamsformat: rpf.summary,
    type: TYPES.SET_DASHBOARD_WEBSITE_CHARTS,
  },
];

export default { displayName, propTypes, defaultProps, requestConfig };
