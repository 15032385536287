import PropTypes from 'prop-types';
const pt = PropTypes;
const { oneOf, oneOfType } = pt;

const displayName = `MessagesPage`;
const propTypes = {
  title: pt.node,
  text: pt.node,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };