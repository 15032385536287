import React from 'react';
import config from './SideMenuDocsItem.component.config';
// import './SideMenuDocsItem.component.styles.less';

const SideMenuDocsItem = ({item, className, onClick}) => (
  <div id="menu-item" className={className} onClick={onClick}>
    <p>{item.title}</p>
  </div>
);

SideMenuDocsItem.displayName = config.displayName;
SideMenuDocsItem.propTypes = config.propTypes;
SideMenuDocsItem.defaultProps = config.defaultProps;

export default SideMenuDocsItem;
