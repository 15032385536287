import get from 'lodash/get';
// import jwtDecode from 'jwt-decode';

const AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';

export const storeToken = (token) => {
  const ttl = 86400000; // time to live 1 day in ms
  const now = new Date();
  if (token) {
    const exp = now.getTime() + ttl;
    const token_ = token.token;
    const item = { 
      token: token_,
      expiry: exp,
      id: token.uid ? token.uid : token.id,
      role: token.role,
      name: token.name,
      email: token.email,
    };
    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(item));
    if (!localStorage.getItem('VISITED_ROUTES')) localStorage.setItem('VISITED_ROUTES', JSON.stringify([])) // initialize VISITED_ROUTES array
  } else {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
};

export const getToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const item = JSON.parse(token, '{}');
  const now = new Date();
  let respToken = null;

  if (now.getTime() > get(item, 'expiry')) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    respToken = null;
  } else {
    respToken =  {
      token: get(item, 'token'),
      id: get(item, 'id'),
      role: get(item, 'role'),
      name: get(item, 'name'),
      email: get(item, 'email'),
    };
  }
  return respToken;
};

export const destroyToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  // window.location.reload('/login');
};
