import React from 'react';
import {
  Button,
  Col,
  Row,
  Spin,
  Typography,
} from 'antd';

import { AccountSelectionGS, PlatformAvatar } from '../../../components/atoms';

import stringConstants from '../../../constants-v1/string.constants';

import { formatBigcur, formatFloat, formatInteger, formatPercent } from '../../../utils-v1/number.utils';
import { renderArrow, renderPercentText } from '../../../utils-v1/summary-page.utils';

import config from './MarketplacePanel.component.config';
// import './MarketplacePanel.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const MarketplacePanel = ({
  accountSelectionProps,
  availableAccounts,
  history,
  loading,
  platformProps,
}) => {
  const { daterange } = platformProps;
  const { data } = platformProps?.data;
  const acct = Object.keys(data || {});
  const acctData = data?.[acct]?.data;
  const vsInterval = platformProps?.daterange?.preset_range_compare || '-';

  const renderEmptyContent = () => (
    <div className="empty-content marketplace">
      {loading ? <Paragraph style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph> : <>
        <>{availableAccounts?.length === 0 ? <>
          <Text className="line-1">You have not bind your {stringConstants.marketplace.name} accounts yet</Text>
          <br />
          <Text className="line-2">You have to bind your account first to fully access BDD.AI</Text>
        </> : null}</>
        <>{availableAccounts?.length > 0 && platformProps?.accounts?.length === 0 ? <>
          <Text className="line-1">You have not selected accounts yet</Text>
          <br />
          <Text className="line-2">Please select a {stringConstants.marketplace.name} account</Text>
        </>: null}</>
        <br /><br />
        <Button type="primary" className="bind-button" onClick={() => history.push('binding-marketplace')} hidden={availableAccounts?.length > 0}>
          Bind {stringConstants.marketplace.name} Account
        </Button>
      </>}
    </div>
  );

  const renderFilledContent = () => (<>
    <Row className="filled-content marketplace">
      <Col className="filled-col radius-left" sm={24} md={12} lg={12}>
        <Paragraph className='title-metric'>Sales</Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBigcur(acctData?.current?.total_sales)}</Text><br />
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBigcur(acctData?.previous?.total_sales)}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(acctData?.percentage?.total_sales)}
          {renderPercentText(acctData?.percentage?.total_sales)}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
      <Col className="filled-col radius-right" sm={24} md={12} lg={12}>
        <Paragraph className='title-metric'>Cancel</Paragraph>
        <Paragraph>
          <Text className="summary-current-value">{formatBigcur(acctData?.current?.total_cancel)}</Text><br />
          <Text hidden={!daterange.use_compare} className="summary-compared-value"> vs {formatBigcur(acctData?.previous?.total_cancel)}</Text>
        </Paragraph>
        <Paragraph hidden={!daterange.use_compare}>
          {renderArrow(acctData?.percentage?.total_cancel, true)}
          {renderPercentText(acctData?.percentage?.total_cancel, true)}
          <Text className="summary-percentage-value"> vs {vsInterval}</Text>
        </Paragraph>
      </Col>
    </Row>
  </>);

  const renderEmptyCondition = availableAccounts?.length === 0 || platformProps?.accounts?.length === 0 || loading;
  return(
    <div id="marketplacePanel">
      <div className="platform-title-summary">
        <div className="platform-title">
          <PlatformAvatar platform="marketplace" width={40} />
          <Text>Marketplace</Text>
        </div>
        <div hidden={availableAccounts?.length === 0}>
          <AccountSelectionGS { ...accountSelectionProps } />
        </div>
      </div>
      <div>
        {renderEmptyCondition ? renderEmptyContent() : renderFilledContent()}
      </div>
    </div>
  )
}

MarketplacePanel.displayName = config.displayName;
MarketplacePanel.propTypes = config.propTypes;
MarketplacePanel.defaultProps = config.defaultProps;

export default MarketplacePanel;
