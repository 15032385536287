import { connect } from 'react-redux';
import OnboardingTour from './OnboardingTour.component';

import { bindMeta } from '../../redux-v1/actions/binding/meta.actions';
import { bindTiktok, getTiktokAuthUrl } from '../../redux-v1/actions/binding/tiktok.actions';
import { bindGoogleAds, getGoogleAdsAuthUrl } from '../../redux-v1/actions/binding/google-ads.actions';
import { bindGoogleAnalytics, getGoogleAnalyticsAuthUrl } from '../../redux-v1/actions/binding/google-analytics.actions';
import { bindInstagram } from '../../redux-v1/actions/binding/instagram.actions';
import { bindMarketplace, generateMarketplaceAuthUrl } from '../../redux-v1/actions/binding/marketplace.actions';
import { bindWebsite } from '../../redux-v1/actions/binding/website.actions';
import { getAccountConfig, updateUserOnboarding, getUserOnboarding } from '../../redux-v1/actions/user/__common.actions';

const mapStateToProps = (state) => ({
  user: state.user.me,
});

const mapDispatchToProps = {
  bindMeta,
  getTiktokAuthUrl,
  bindTiktok,
  getGoogleAdsAuthUrl,
  bindGoogleAds,
  getGoogleAnalyticsAuthUrl,
  bindGoogleAnalytics,
  bindInstagram,
  bindWebsite,
  generateMarketplaceAuthUrl,
  bindMarketplace,
  getAccountConfig,
  getUserOnboarding,
  updateUserOnboarding,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTour);
