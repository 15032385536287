import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';

const { BindingApiService, setBindingApiReqHeaders } = apiBinding.getInstance();


export const unbindAllAccounts = (platform='') => async (dispatch) => {
  let PLATFORM; let alias;
  switch (platform) {
    default: PLATFORM = ''; alias = ''; break;
    case 'googleAds': PLATFORM = 'GOOGLE_ADS'; alias = 'gadwords'; break;
    case 'googleAnalytics': PLATFORM = 'GOOGLE_ANALYTICS'; alias = 'ganalytics'; break;
    case 'instagram': PLATFORM = 'INSTAGRAM'; alias = 'instagram'; break;
    case 'marketplace': PLATFORM = 'MARKETPLACE'; alias = 'marketplace'; break;
    case 'meta': PLATFORM = 'META'; alias = 'facebook'; break;
    case 'tiktok': PLATFORM = 'TIKTOK'; alias = 'tiktok'; break;
    case 'website': PLATFORM = 'WEBSITE'; alias = 'website'; break;
  }
  // dispatch({ type: TYPES.SET_BINDING_UNBIND_ACCOUNT, payload: { loadings: { unbind: true } } });
  // dispatch({ type: TYPES[`SET_BINDING_BIND_${PLATFORM}`], payload: { loadings: { unbind: true } } });
  // console.log({ platform, PLATFORM, alias })

  let response;
  try {
    const { status, data } = await BindingApiService.delete(
      `/account-configuration/deletebyuserid`,
      {
        ...setBindingApiReqHeaders(getToken()),
        data: { account_type: alias, platform },
      },
    );
    if (status === 200) {
      // dispatch({ type: TYPES.SET_BINDING_UNBIND_ACCOUNT, payload: { loadings: { unbind: false } } });
      // dispatch({ type: TYPES[`SET_BINDING_BIND_${PLATFORM}`], payload: { loadings: { unbind: false } } });
      response = Promise.resolve("Success");
    };
  } catch (err) {
    response = Promise.reject(new Error("failed"));
  };
  return response;
};

export const unbindSingleAccount = (account={}) => async (dispatch) => {
  // dispatch({ type: TYPES.SET_BINDING_UNBIND_ACCOUNT_CONFIRMATION, payload: { btnLoading: true } });
  let response;
  try{
    const { status, data } = await BindingApiService.delete(
      `/account-configuration/delete-by-id`,
      {
        ...setBindingApiReqHeaders(getToken()),
        data: { id: account?.config_id },
      },
    );
    if (status === 200) {
      response = Promise.resolve("Success");
    };
    // dispatch({
    //   type: TYPES.SET_BINDING_UNBIND_ACCOUNT_CONFIRMATION,
    //   payload: { isVisible: false, accountId: null, btnLoading: false, isDone: true },
    // });
    // dispatch(getAccountConfig(true));
  } catch (err) {
    response = Promise.reject(new Error("failed"));
  };
  return response;
};