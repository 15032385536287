/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Card, Checkbox, Drawer, Input, notification, Popover, Space, Spin, Tabs, Table as AntTable, Typography } from 'antd';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { Resizable } from 'react-resizable';
import { connect } from 'react-redux';

import { Buttons } from '../atoms';

import { coachmark } from '../../utils-v1/coachmark.utils';
import { copyImage } from '../../utils-v1/exports.utils';
import { unformatNumber } from '../../utils-v1/number.utils';
import { areFieldsEmpty } from '../../utils-v1/object.utils';

import { updateCoachmarkLocal } from '../../redux-v1/actions/user/__common.actions';

import config from './Table.component.config';
import './Table.component.styles.less';

const { error, success } = notification;
const { Paragraph, Title } = Typography;
const templateProps = config.getTemplateProps();
const { tableProps, tabbedTablesProps } = templateProps;
const mapStateToProps = (state) => ({
  coachmark_local: state.user.coachmark_local,
  onBoarding: state.user.me.onBoarding,
});
const mapDispatchToProps = {
  updateCoachmarkLocal,
};

const ampasCol = { key: 'ampas', dataIndex: 'ampas', title: '' };

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th { ...restProps } />;
  };

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    ><th { ...restProps } /></Resizable>
  );
};

const Single = (props={ ...tableProps }) => {
  let columns = Array.isArray(props?.columns) ? [...props?.columns, ampasCol] : tableProps.columns;
  const totalWidth = columns.map(col => col?.width || 0).reduce((s,t) => s+t)-1;
  if (areFieldsEmpty(columns.filter(col => col?.key !== ampasCol.key)[0])) columns = [];
  let data = Array.isArray(props?.data) ? props?.data : tableProps.data;
  if (areFieldsEmpty(data[0])) data = [];
  const allowSort = props?.allowSort || tableProps?.allowSort;
  const bordered = props?.bordered || tableProps?.bordered;
  const loading = props?.loading || tableProps?.loading;
  const noFilter = props?.noFilter || tableProps?.noFilter;
  const title = props?.title || tableProps.title;
  const titlePlacement = props?.titlePlacement || tableProps.titlePlacement;
  const resizable = props?.resizable || tableProps.resizable;
  const summary = props?.summary || tableProps?.summary;

  const [columnsState, setColumnsState] = React.useState([...columns]);
  const [searchText, setSearchText] = React.useState('');
  const [showColumns, setShowColumns] = React.useState(columns.map(e => e?.key));
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [coachmarkOpen, setCoachmarkOpen] = React.useState(false);
  const ref = React.createRef(null);

  React.useEffect(() => {
    const antdTable = document.getElementById('AntdTable');
    const newColumnsState = columnsState.map(col => ({
      ...col,
      ...(col?.key !== ampasCol.key && { width: col?.width*antdTable.offsetWidth/totalWidth }),
    }));
    setColumnsState(newColumnsState)
  }, []);

  const constructCardProps = (forDrawer=0) => ({
    bordered: false,
    headStyle: { border: 'none' },
    ref,
    ...(titlePlacement === 'in' && { title: <>{title} <span><Popover { ...constructPopoverProps() } /></span></> }),
  });
  const constructCheckboxGroupProps = () => ({
    children: <Space direction="vertical" size={4}>
      {columnsState.map((column, idx) => (<>
        {(typeof column?.title === 'string' && column?.title?.toLowerCase()?.includes(searchText.toLowerCase()) && idx !== 0) ?
          <Checkbox disabled={idx === 0} value={column?.key}>
            {column?.title}
          </Checkbox> : null
        }
      </>))}
    </Space>,
    value: showColumns,
    onChange: (shownColumns) => {
      setShowColumns([showColumns[0], ...shownColumns]);
    },
  });
  const constructDrawerProps = () => ({
    closable: true,
    closeIcon: <Button danger size="medium" type="link"><b>Close (Esc)</b></Button>,
    children: <div style={{ textAlign: 'center' }}>{showDrawer ? renderComponent(1) : null}</div>,
    height: '100%',
    onClose: () => setShowDrawer(false),
    open: showDrawer,
    placement: 'bottom',
    width: '100%',
  });
  const constructPopoverProps = () => ({
    onOpenChange: (open) => {
      if (open && !coachmarkOpen && props?.coachmark_local?.platformTableFilterInner === 0 && props?.onBoarding?.coachmark_status?.platform === 0) {
        setCoachmarkOpen(true);
        coachmark({
          delay: 300,
          steps: [{
            key: 1,
            element: '#TableFilter',
            title: 'Select Your Metrics',
            intro: 'You can now select your preferred metrics to show in these tables goodbye endless scroll',
            position: 'right',
          }],
          oncomplete: () => {
            props.updateCoachmarkLocal({ ...props.coachmark_local, platformTableFilterInner: 1 }, props.onBoarding);
          },
        });
      } else {
        if (coachmarkOpen) { setPopoverOpen(true) } else { setPopoverOpen(false) };
        setCoachmarkOpen(false);
      };
    },
    open: popoverOpen,
    children: noFilter ? null : <Buttons.Filter className="table-filter-btn" onClick={() => setPopoverOpen(true)}/>,
    content: <Card id="TableFilter" title="Filter">
      <Paragraph level={2}>Metrics to show</Paragraph>
      <Input value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search metrics" suffix={<SearchOutlined />} style={{ padding: '0px 15px'}} />
      <Checkbox.Group { ...constructCheckboxGroupProps() } />
    </Card>,
    placement: 'bottom',
    trigger: 'click',
  });
  const constructAntTableProps = () => ({
    bordered,
    columns: columnsState
      .filter(column => showColumns.includes(column?.key))
      .map((column, idx) => ({
        ...column,
        dataIndex: column?.key,
        ...(allowSort && { sorter: idx > 0 ?
          (a, b) => unformatNumber(a?.[column?.key]) - unformatNumber(b?.[column?.key])
          : (a, b) => a?.[column?.key]?.toLowerCase()?.localeCompare(b?.[column?.key]?.toLowerCase())
        }),
        ...(resizable && {
          onHeaderCell: (col) => ({
            width: col.width,
            onResize: (e, { size }) => {
              setColumnsState((oldColumns) => {
                const newColumns = [...oldColumns];
                newColumns[idx] = { ...newColumns[idx], width: size.width };
                return newColumns;
              })
            },
          }),
        })
      })),
    dataSource: data,
    loading,
    pagination: { position: ['bottomCenter'] },
    scroll: { x: 1300 },
    ...(summary && {
      summary: (pageData) => (
        <AntTable.Summary.Row style={{ backgroundColor: '#D0D5DD' }}>
          {Object.keys(summary).map((key, idx) => <AntTable.Summary.Cell index={key}>{summary[key]}</AntTable.Summary.Cell>)}
          {resizable ? <AntTable.Summary.Cell index={ampasCol.key}></AntTable.Summary.Cell> : null}
        </AntTable.Summary.Row>
      ),
    })
  });

  const actions = {
    copy: () => {
      copyImage({ document, navigator, ref, title });
    },
    expand: () => {
      setShowDrawer(true);
    },
  };

  const renderComponent = (forDrawer=0) => (<>
    {titlePlacement === 'out' ? <>
      {title}{title && <>&nbsp;&nbsp;</>}<span><Popover { ...constructPopoverProps() } /></span><br /><br />
    </> : null}
    <Card id="tableWrapper" { ...constructCardProps(forDrawer) }>
      <Paragraph hidden={!loading} style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph>
      <AntTable
        id="AntdTable"
        bordered={bordered}
        components={{ header: { cell: ResizableTitle } }}
        style={loading ? { visibility: 'hidden' } : {}} { ...constructAntTableProps() } pagination={props?.pagination}
      />
    </Card>
    {forDrawer && <div style={{ textAlign: 'center', marginBottom: 10 }}>
      <Button type="primary" onClick={actions.copy}>
        <CopyOutlined /> Copy to Clipboard
      </Button>
    </div> || null}
  </>);

  return(<>{renderComponent(0)} <Drawer { ...constructDrawerProps() } /></>);
};

const Tabbed = (props={ ...tabbedTablesProps }) => {
  const allowSort = props?.allowSort || tabbedTablesProps?.allowSort;
  const bordered = props?.bordered || tabbedTablesProps?.bordered;
  const noFilter = props?.noFilter || tabbedTablesProps?.noFilter;
  const resizable = props?.resizable || tabbedTablesProps?.resizable;
  let tables = Array.isArray(props?.tables) ? props?.tables : tabbedTablesProps?.tables;
  tables = tables.map(table => ({
    ...table,
    columns: [...(Array.isArray(table?.columns) ? table?.columns?.map(col => ({
      ...col, width: (`${col?.width}`.includes('px') || `${col?.width}`.includes('%')) ? unformatNumber(col?.width) : col?.width,
    })): []), ampasCol],
  })).map(table => ({ ...table, totalWidth: table?.columns?.map(col => col?.width || 0)?.reduce((s,t) => s+t)-1 }));
  const title = props.title || tabbedTablesProps.title;
  const titlePlacement = props.titlePlacement || tabbedTablesProps.titlePlacement;

  const [activeKey, setActiveKey] = React.useState('0');
  const [searchText, setSearchText] = React.useState('');
  const [showColumns, setShowColumns] = React.useState(tables.map(table => table?.columns.map(e => e?.key)));
  const [columnsState, setColumnsState] = React.useState([...tables.map(table => table?.columns)]);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [coachmarkOpen, setCoachmarkOpen] = React.useState(false);

  const ref = tables.map((e, i) => React.createRef(null));

  React.useEffect(() => {
    const antdTable = document.getElementById(`AntTableTab${activeKey}`);
    const antdTableWidth = antdTable.offsetWidth;
    const newColumnsState = columnsState.map((cols, idx) => cols.map(col => ({
      ...col,
      ...(col?.key !== ampasCol.key && {
        width: col?.width*(antdTableWidth > tables?.[idx]?.totalWidth ? antdTableWidth/tables?.[idx]?.totalWidth : 1)
      }),
    })));
    setColumnsState(newColumnsState);
  }, [activeKey]);

  const constructCardProps = (forDrawer=0) => ({
    bordered: false,
    headStyle: { border: 'none' },
    ...(titlePlacement === 'in' && { title: <>{title} <span><Popover { ...constructPopoverProps() } /></span></> }),
  });
  const constructCheckboxGroupProps = () => ({
    children: <Space direction="vertical" size={4}>
      {tables[activeKey]?.columns?.map((column, idx) => (<>
        {(typeof column?.title === 'string' && column?.title?.toLowerCase()?.includes(searchText.toLowerCase()) && idx !==0) ?
          <Checkbox disabled={idx === 0} value={column?.key} style={idx === 0 ? { display: 'none' } : {}}>
            {column?.title}
          </Checkbox> : null}
      </>))}
    </Space>,
    value: showColumns[activeKey],
    onChange: (shownColumns) => {
      const newShownColumns = [ ...showColumns ];
      newShownColumns[activeKey] = [ showColumns[activeKey][0], ...shownColumns ];
      setShowColumns(newShownColumns);
    },
  });
  const constructDrawerProps = () => ({
    closable: true,
    closeIcon: <Button danger size="medium" type="link"><b>Close (Esc)</b></Button>,
    children: <div style={{ textAlign: 'center' }}>{showDrawer ? renderComponent(1) : null}</div>,
    height: '100%',
    onClose: () => setShowDrawer(false),
    open: showDrawer,
    placement: 'bottom',
    width: '100%',
  });
  const constructPopoverProps = (tableIndex) => ({
    onOpenChange: (open) => {
      if (open && !coachmarkOpen && props?.coachmark_local?.platformTableFilterInner === 0 && props?.onBoarding?.coachmark_status?.platform === 0) {
        setCoachmarkOpen(true);
        coachmark({
          delay: 300,
          steps: [{
            key: 1,
            element: '#TableFilter',
            title: 'Select Your Metrics',
            intro: 'You can now select your preferred metrics to show in these tables goodbye endless scroll',
            position: 'right',
          }],
          oncomplete: () => {
            props.updateCoachmarkLocal({ ...props.coachmark_local, platformTableFilterInner: 1 }, props.onBoarding);
          },
        });
      } else {
        if (open && !coachmarkOpen) {
          setPopoverOpen(true);
        } else if (coachmarkOpen) {
          setPopoverOpen(true);
        } else {
          setPopoverOpen(open)
        }
        setCoachmarkOpen(false);
      };
    },
    open: popoverOpen,
    children: noFilter ? null : <Buttons.Filter className="table-filter-btn" onClick={() => setPopoverOpen(true)}/>,
    content: <Card id="TableFilter" title="Filter">
      <Paragraph level={2}>Metrics to show</Paragraph>
      <Input value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search metrics" prefix={<SearchOutlined />} style={{ padding: '0px 15px'}} />
      <Checkbox.Group { ...constructCheckboxGroupProps() } />
    </Card>,
    placement: 'bottom',
    trigger: 'click',
  });
  const constructAntTableProps = (columns, data) => ({
    bordered,
    columns: columnsState?.[activeKey]
      .filter(column => showColumns[activeKey].includes(column?.key))
      .map((column, idx) => ({
        ...column,
        dataIndex: column?.key,
        ...(allowSort && { sorter: idx > 0 ?
          (a, b) => unformatNumber(a?.[column?.key]) - unformatNumber(b?.[column?.key])
          : (a, b) => a?.[column?.key]?.toLowerCase()?.localeCompare(b?.[column?.key]?.toLowerCase()) }),
        ...(resizable && {
          onHeaderCell: (col) => ({
            width: col.width,
            onResize: (e, { size }) => {
              setColumnsState((oldColumns) => {
                const newColumns = [...oldColumns];
                newColumns[activeKey][idx] = { ...newColumns[activeKey][idx], width: size.width };
                return newColumns;
              })
            },
          }),
        })
      })),
    dataSource: data,
    pagination: { position: ['bottomCenter'] },
    scroll: { x: 1300 },
  });
  const constructTabsProps = () => ({
    defaultActiveKey: activeKey,
    items: tables.map((table, i) => ({
      children: <Card bordered={false} ref={ref[i]} title={table?.title}>
        <Paragraph hidden={!table?.loading} style={{ marginTop: 25, textAlign: 'center' }}><Spin size="large" /></Paragraph>
        <AntTable
          id={`AntTableTab${activeKey}`}
          className="ant-table-within-tab"
          bordered={bordered}
          components={{ header: { cell: ResizableTitle } }}
          style={table?.loading ? { visibility: 'hidden' } : {}} { ...constructAntTableProps(table?.columns, table?.data) } 
        />
      </Card>,
      key: `${i}`,
      label: table?.title
    })),
    onChange: (key) => {
      setActiveKey(key);
      setSearchText('');
    },
  });

  const actions = {
    copy: () => {
      copyImage({ document, navigator, ref: ref[activeKey], title })
        .then(() => success({ message: 'Copied to clipboard' }))
        .catch(() => error({ message: 'Unable to copy item to clipboard' }));
    },
    expand: () => {
      setShowDrawer(true);
    },
  };

  const renderComponent = (forDrawer=0) => (<>
    <div style={{ marginTop: 20 }}>
      {titlePlacement === 'out' ? <>
        {title}{title && <>&nbsp;&nbsp;</>}<span><Popover { ...constructPopoverProps() } /></span><br /><br />
      </> : null}
    </div>
    <Card id="tabbedTablesWrapper" { ...constructCardProps(forDrawer) }>
      <Tabs { ...constructTabsProps() } />
    </Card>
    {forDrawer && <div style={{ textAlign: 'center', marginBottom: 10 }}>
      <Button type="primary" onClick={actions.copy}>
        <CopyOutlined /> Copy to Clipboard
      </Button>
    </div> || null}
  </>);

  return(<>{renderComponent(0)} <Drawer { ...constructDrawerProps() } /></>);
};

export default {
  Single: connect(mapStateToProps, mapDispatchToProps)(Single),
  Tabbed: connect(mapStateToProps, mapDispatchToProps)(Tabbed),
};
