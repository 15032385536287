import { combineReducers } from 'redux';

import googleAds from './google-ads.reducer';
import googleAnalytics from './google-analytics.reducer';
import instagram from './instagram.reducer';
import marketplace from './marketplace.reducer';
import meta from './meta.reducer';
import tiktok from './tiktok.reducer';
import website from './website.reducer';

const bindingSlice = {
  googleAds,
  googleAnalytics,
  instagram,
  marketplace,
  meta,
  tiktok,
  website,
};

export default combineReducers(bindingSlice);