import React from 'react';
import {
  Button,
  Dropdown,
  Input,
  Image,
  Modal,
  notification,
  Radio,
  Space,
  Switch,
  Typography,
} from 'antd';
import axios from 'axios';
import { EMPTY_META_IMAGE } from '../../assets-v1/images';

import TYPES from '../../redux-v1/types';
import { coachmark } from '../../utils-v1/coachmark.utils';
import { generateDashboardProps, getRequestCancelCondition, onMountFunction, renderEmptyState } from '../../utils-v1/platform-page.utils';
import { generateRequestParamsByFormat } from '../../utils-v1/request.utils';
import stringConstants from '../../constants-v1/string.constants';

import { DashboardLayoutGS } from '../../components/layouts-backup';

import ConversionPage from './ConversionPage/ConversionPage.component';
import CollaborativePage from './CollaborativePage/CollaborativePage.component';
import LeadPage from './LeadPage/LeadPage.component';
import MessagesPage from './MessagesPage/MessagesPage.component';

import config from './DashboardMeta.component.config';
import './DashboardMeta.component.styles.less';

const { Paragraph, Text, Title } = Typography;
const { key, name, alt, PLATFORM } = stringConstants.meta;
let source;

class DashboardMeta extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tokenModal: false,
      uiLoading: false,
    };
    source = axios.CancelToken.source();
  };

  componentDidMount() {
    const { tokenStatus } = this.props;
    this.setState({ tokenModal: tokenStatus !== 'active'});
    onMountFunction(this, key);
    if (this.props.account?.config_id && this.props.accountBinding.length > 0 && this.props.onBoarding.coachmark_status.platform === 0 && this.props.coachmark_local.platformGeneral === 0) {
      coachmark({
        delay: 1500,
        steps: [
          {
            key: 1,
            element: '.platform-filter-btn',
            title: 'Filter Effortlessly',
            intro: 'Now you can filter the data that you need based on objectives and campaign name',
            position: 'bottom',
          },
          {
            key: 2,
            element: '#SeeDetailButton',
            title: 'Expand the Details',
            intro: 'You can now have access to the full screen details that you need',
            position: 'bottom',
          },
          {
            key: 3,
            element: '.ant-tabs-nav',
            title: 'Chart that You Need',
            intro: 'Slide beetween the tabs to oversee the kind of chart that you need',
            position: 'bottom',
          },
        ],
        oncomplete: () => {
          this.props.updateCoachmarkLocal({ ...this.props.coachmark_local, platformGeneral: 1 }, this.props.onBoarding)
        },
      });
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const shouldCancelRequest = getRequestCancelCondition(this.props, prevProps);
    // console.log({ shouldCancelRequest, source });
    if (shouldCancelRequest && source) { source.cancel('DashboardMeta axios cancel due to config changes') };
  }

  componentWillUnmount() {
    if (source) { source.cancel('DashboardMeta axios cancel due to unmounting') };
  };

  fetchData = (format='') => {
    const reqParams = generateRequestParamsByFormat(key, this.props, format, { cancelToken: source.token });
    this.setState({ uiLoading: true });
    this.props.getAccountConfig()
      .finally(() => {
        this.setState({ uiLoading: false });
        const acctType = (this.props.account.details.type || '').toUpperCase();
        const reqList = config.requestConfig.filter(item => item.type.includes(acctType))
          .map(item => this.props.getPlatformData(item.type, reqParams[item.reqparamsformat]));
        if (this.props.account.account_id) {
          Promise.all(reqList)
            .then(res => console.log({ res }))
            .catch(err => console.log({ err }))
            .finally(() => localStorage.setItem(`DASHBOARD_${PLATFORM}_DATA`, JSON.stringify(this.props.data)))
        };
      });
  };

  renderPageByAccountType = () => {
    const panelProps = { ...this.props, ...this.state };
    switch(this.props?.account?.details?.type) {
      case 'conversion': return <ConversionPage { ...panelProps } />;
      case 'collaborative': return <CollaborativePage { ...panelProps } />;
      case 'lead': return <LeadPage { ...panelProps } />;
      case 'messages': return <MessagesPage { ...panelProps } />;
      default: return <>
        <div style={{ borderRadius: 10 }}>
          <Paragraph style={{ marginTop: 30 }}>Please select an account type first!</Paragraph>
        </div>
      </>
    };
  };

  constructTokenModalProps = () => ({
    children: <Paragraph>
      Your Meta account&apos;s token has reached time out. Try to re-bind your account so you can fully access BDD.AI. Would you like to rebind this account now?
    </Paragraph>,
    cancelText: 'Not Now',
    onCancel: () => {
      this.setState({ tokenModal: false });
    },
    okText: 'Yes',
    onOk: () => {
      this.props?.history?.push('/binding-meta');
    },
    open: this.state.tokenModal,
  });

  render() {
    return (
      <DashboardLayoutGS { ...generateDashboardProps(this, key) }>
        <div id="DashboardMeta">
          {!this.props.account?.config_id || !this.props.account?.details?.type ? renderEmptyState(this, key) : this.renderPageByAccountType()}
        </div>
        <Modal { ...this.constructTokenModalProps() } />
      </DashboardLayoutGS>
    );
  };
};

DashboardMeta.displayName = config.displayName;
DashboardMeta.defaultProps = config.defaultProps;
DashboardMeta.propTypes = config.propTypes;

export default DashboardMeta;
