import React from 'react';
import {
  Button,
  Image,
  Layout,
  Spin,
  Typography,
  notification,
} from 'antd';
import {
  EMAIL_VERIFICATION_LINK_EXPIRED,
  LOGO_TYPE_BDDAI,
} from '../../assets-v1/images';
import {
  extractParamFromUrl,
} from '../../utils-v1/request.utils';

import config from './EmailVerification.component.config';
import './EmailVerification.component.styles.less';

const { Content, Header, Footer } = Layout;
const { Paragraph, Text, Title } = Typography;

class EmailVerificationExpired extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingProcessRegistrationCredentials: true,
      loadingResendRegistrationCredentials: false,
    };
  }

  componentDidMount() {
    console.log(`EmailVerificationExpired is mounted...`);
    this.props.processRegistrationCredentials(extractParamFromUrl('credential'))
      .then(() => {
        notification.success({
          message: `Success`,
          description: `Activation success! Redirecting to login page...`,
        });
        setTimeout(() => {
          this.props.history.replace('/login');
        }, 3000);
      })
      .catch(() => {
        this.setState({ loadingProcessRegistrationCredentials: false });
      })
  }

  componentWillUnmount() {
    console.log(`EmailVerificationExpired is unmounted...`);
  }

  renderTitle = () => <h1>{this.props.title || `EmailVerificationExpired`}</h1>
  renderText = () => <p>{this.props.text || `EmailVerificationExpired`} is rendered</p>

  render() {
    return (
      <Layout id="EmailVerificationExpiredLayout">
        <Header>
          <Image src={LOGO_TYPE_BDDAI} alt="logo_type_bddai" preview={false} />
        </Header>
        <Content>
          <div id="centeredContent">
            {!this.state.loadingProcessRegistrationCredentials ? <div style={{ textAlign: 'center', padding: 30 }}>
              <Image src={EMAIL_VERIFICATION_LINK_EXPIRED} alt="email_verification_link_expired" preview={false} />
              <Paragraph style={{ fontSize: 20, fontWeight: 700, marginTop: 25 }}>
                Email verification link expired
              </Paragraph>
              <Paragraph style={{ fontSize: 14, fontWeight: 400 }}>
                Looks like the verification link has expired. Not to worry, we can send the link again
              </Paragraph>
              <Button
                id="resendVerificationLinkBtn"
                type="primary"
                loading={this.state.loadingResendRegistrationCredentials}
                style={{ fontSize: 16, fontWeight: 600, marginTop: 25 }}
                onClick={() => {
                  this.setState({ loadingResendRegistrationCredentials: true });
                  this.props.resendRegistrationCredentials(extractParamFromUrl("credential"))
                    .then(() => {
                      notification.success({
                        message: 'Success',
                        description: 'Please check your email for the extended activation code',
                      })
                    })
                    .catch(() => {
                      notification.error({
                        message: 'Error',
                        description: 'Failed to extend the activation code',
                      })
                    })
                    .finally(() => {
                      this.setState({ loadingResendRegistrationCredentials: false });
                    })
                }}
              >
                Resend Verification Link
              </Button>
              <Paragraph style={{ marginTop: 35 }}>
                you can re-enter your email&nbsp;
                <span id="reenterEmailBtn" onClick={() => console.log("here")}>
                  here
                </span>
                &nbsp;if the one you entered was incorrect.
              </Paragraph>
            </div> : <div id="centeredLoading">
              <Spin size="large" />
              <Text style={{ marginLeft: 15 }}>Authentication in progress, please wait...</Text>
            </div>}
          </div>
        </Content>
        <Footer>
          footer
        </Footer>
      </Layout>
    )
  }
}

EmailVerificationExpired.displayName = config.displayName;
EmailVerificationExpired.propTypes = config.propTypes;
EmailVerificationExpired.defaultProps = config.defaultProps;

export default EmailVerificationExpired;