import React from 'react';
import { Image } from 'antd';

import {
  EMPTY_BUDGET_IMAGE,
  EMPTY_GOOGLE_ADS_IMAGE,
  EMPTY_GOOGLE_ANALYTICS_IMAGE,
  EMPTY_INSTAGRAM_IMAGE,
  EMPTY_MARKETPLACE_IMAGE,
  EMPTY_META_IMAGE,
  EMPTY_TIKTOK_IMAGE,
  EMPTY_WEBSITE_IMAGE,
} from '../../../assets-v1/images';

import config from './EmptyImage.component.config';
// import './EmptyImage.component.styles.less';

const EmptyImage = ({ platform='meta', width=200, style={} }) => {
  const getImgSrc = () => {
    switch(platform) {
      case 'budget': return EMPTY_BUDGET_IMAGE;
      case 'googleAds': return EMPTY_GOOGLE_ADS_IMAGE;
      case 'googleAnalytics': return EMPTY_GOOGLE_ANALYTICS_IMAGE;
      case 'instagram': return EMPTY_INSTAGRAM_IMAGE;
      case 'marketplace': return EMPTY_MARKETPLACE_IMAGE;
      case 'meta': return EMPTY_META_IMAGE;
      case 'tiktok': return EMPTY_TIKTOK_IMAGE;
      default: return EMPTY_WEBSITE_IMAGE;
    };
  };

  return(
    <Image src={getImgSrc()} alt={`empty-${platform}-img`} width={width} style={style} preview={false} />
  );
};

EmptyImage.displayName = config.displayName;
EmptyImage.propTypes = config.propTypes;
EmptyImage.defaultProps = config.defaultProps;

export default EmptyImage;