import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';
import TYPES from '../../types';

const { BindingApiService, setBindingApiReqHeaders } = apiBinding.getInstance();

export const bindInstagram = () => async (dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_INSTAGRAM,
    payload: { loadings: { bind: true } },
  });

  let outcome;
  try {
    const loginFacebook = await new Promise((resolve) => {
      window.FB.login((response) => resolve(response), {
        scope: 'email,read_insights,pages_show_list,ads_management,instagram_basic,instagram_manage_insights,public_profile,pages_read_engagement',
      });
    });
    const token = loginFacebook?.authResponse?.accessToken;
    
    const response = await BindingApiService.post(
      `/binding/instagram`,
      { user_id: getToken()?.id, access_token: token },
      { ...setBindingApiReqHeaders(getToken()) },
    );

    if(response){
      /* Get account configuration data */
      const { status, data } = await BindingApiService.get(
        `/account-configuration/get-data-by-user-id`,
        { ...setBindingApiReqHeaders(getToken()) },
      );

      dispatch({
        type: TYPES.SET_BINDING_BIND_INSTAGRAM,
        payload: { loadings: { bind: false } },
      });
      dispatch({
        type: TYPES.SET_USER_BINDING_ACCOUNT,
        payload: { ...data, error: null, loading: false },
      });
      outcome = Promise.resolve(data);
    };
  } catch (err) {
    dispatch({
      type: TYPES.SET_BINDING_BIND_INSTAGRAM,
      payload: { loadings: { bind: false } },
    });
    outcome = Promise.reject(err);
  };

  return outcome;
};