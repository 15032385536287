import React from 'react';
import { Typography } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { formatPercent } from './number.utils';

const { Text } = Typography;

const [green, red] = ['#59BC7D', '#FF683A'];

export const renderArrow = (value=0, reverse=false) => {
  let arrow = <ArrowDownOutlined style={{ color: reverse ? green : red }} />
  if (value > 0) arrow = <ArrowUpOutlined style={{ color: reverse ? red : green }} />
  return arrow;
};

export const renderPercentText = (value=0, reverse=false) => {
  let percentText;
  if (value <= 0) {
    percentText = <Text style={{ color: reverse ? green : red }}>{formatPercent(value).replaceAll('-','')}</Text>;
  } else {
    percentText = <Text style={{ color: reverse ? red : green }}>{formatPercent(value)}</Text>;
  };
  return percentText;
};