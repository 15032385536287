import React from 'react';
import { Image } from 'antd';

import {
  AVATAR_GOOGLE_ADS,
  AVATAR_GOOGLE_ANALYTICS,
  AVATAR_INSTAGRAM,
  AVATAR_LAZADA,
  AVATAR_MARKETPLACE,
  AVATAR_META,
  AVATAR_SHOPEE,
  AVATAR_TIKTOK,
  AVATAR_TOKOPEDIA,
  AVATAR_WEBSITE,
} from '../../../assets-v1/figma-icons';

import config from './PlatformAvatar.component.config';
// import './PlatformAvatar.component.styles.less';

const PlatformAvatar = ({ platform='meta', width=20, style={} }) => {
  const getImgSrc = () => {
    switch (platform) {
      case 'googleAds': return AVATAR_GOOGLE_ADS;
      case 'googleAnalytics': return AVATAR_GOOGLE_ANALYTICS;
      case 'instagram': return AVATAR_INSTAGRAM;
      case 'lazada': return AVATAR_LAZADA;
      case 'marketplace': return AVATAR_MARKETPLACE;
      case 'meta': return AVATAR_META;
      case 'shopee': return AVATAR_SHOPEE;
      case 'tiktok': return AVATAR_TIKTOK;
      case 'tokopedia': return AVATAR_TOKOPEDIA;
      default: return AVATAR_WEBSITE;
    };
  };

  return(
    <Image src={getImgSrc()} alt={`avatar-${platform}`} width={width} preview={false} style={style} />
  );
};

PlatformAvatar.displayName = config.displayName;
PlatformAvatar.propTypes = config.propTypes;
PlatformAvatar.defaultProps = config.defaultProps;

export default PlatformAvatar;