export const initFacebookSdk = () =>
  new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: process.env.FACEBOOK_APP_VERSION,
      });

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus(() => {
        resolve();
      });

      window.FB.AppEvents.logPageView();
    };

    // load facebook sdk script
    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
