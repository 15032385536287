import React from 'react';
import {
  Button,
  Card,
  Col,
  Input,
  Image,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import { ICON_SWAP_VERTICAL } from '../../../assets-v1/figma-icons';

import config from './AccountListWrapper.component.config';
import './AccountListWrapper.component.styles.less';

const { Option } = Select;
const { Text } = Typography;

const AccountListWrapper = ({
  avatarSrc,
  button,
  children,
  onChangeSortBy,
  onSearch,
  title,
}) => {
  const renderToolbar = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <Space align="center" direction="horizontal" size={16}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Image src={avatarSrc} alt="platform_avatar" preview={false} style={{ transform: 'scale(0.9)', position: 'relative', top: -2 }} />
          <Text style={{ fontSize: '1.2rem', marginLeft: 5 }}>{title}</Text>
        </div>
        <Input placeholder="Search..." prefix={<SearchOutlined />} style={{ padding: '0px 10px' }} onChange={onSearch} />
        <div style={{ backgroundColor: '#FAFBFC', border: '1px solid #D0D5DD', borderRadius: 6 }}>
          <Image src={ICON_SWAP_VERTICAL} alt="icon_swap_vertical" preview={false} style={{ paddingLeft: 10 }}/>
          <Select
            className="sorter-selection"
            defaultValue="Sort By"
            onChange={onChangeSortBy}
          >
            <Option value="name"><span style={{ fontSize: 14 }}>Name</span></Option>
            <Option value="type"><span style={{ fontSize: 14 }}>Type</span></Option>
          </Select>
        </div>
      </Space>
      <div>{button}</div>
    </div>
  )

  return(
    <Card style={{ backgroundColor: 'transparent', border: 'unset' }}>
      {renderToolbar()}
      <div className="list-content-binding">{children}</div>
    </Card>
  );
};

AccountListWrapper.propTypes = config.propTypes;
AccountListWrapper.defaultProps = config.defaultProps;
AccountListWrapper.displayName = config.displayName;

export default AccountListWrapper;
