/* eslint-disable camelcase */
import { notification } from 'antd';

import apiBinding from '../../../services/api-binding.service';
import { destroyToken } from '../../../services/token.service';
import TYPES from '../../types';
import { persistor } from '../../store';

const { BindingApiService, setAuthToken, setBindingApiReqHeaders } = apiBinding.getInstance();

export const notificationError = (type) => (dispatch) => {
  notification.error({
    message: 'Error',
    description: `Failed to log in using ${type} Account`,
  });
  dispatch({ type: TYPES.SET_AUTH_LOGIN_GOOGLE, loading: false });
  dispatch({ type: TYPES.SET_AUTH_LOGIN_META, loading: false });
};

export const loginMeta = () => async (dispatch) => {
  console.log('login meta');
  const result = Promise.resolve();
  // let result;
  // let isPasswordCreated;
  // dispatch({ type: TYPES.SET_AUTH_LOGIN_META, payload: { loadingMeta: true } });
  // try {
  //   const loginStatus = await new Promise(window.FB.getLoginStatus);
  //   let credential = loginStatus?.authResponse?.accessToken;
  //   if (loginStatus.status !== 'connected') {
  //     const loginResult = await new Promise(window.FB.login);
  //     credential = loginResult?.authResponse?.accessToken;
  //   }
  // 
  //   const MetaDataPayload = {
  //     credential_type: META,
  //     credential_metadata: JSON.stringify({
  //       token: credential,
  //     }),
  //     app: 'BDD_PUBLIC',
  //   };
  // 
  //   const {
  //     data: {
  //       token,
  //       is_password_created,
  //       is_company_category_created,
  //       is_company_name_created,
  //     },
  //   } = await apiService.post('auth/third_party', MetaDataPayload);
  //   const isRegCompleted =
  //     is_password_created &&
  //     is_company_category_created &&
  //     is_company_name_created;
  //   isPasswordCreated = is_password_created;
  //   setAuthToken(token, !isRegCompleted);
  // 
  //   result = Promise.resolve(isRegCompleted);
  // } catch (error) {
  //   result = Promise.reject(error);
  //   notificationError('Meta')(dispatch);
  // } finally {
  //   dispatch({ type: TYPES.SET_AUTH_LOGIN_META, payload: { loadingMeta: false, isPasswordCreated } });
  // }

  return result;
};

export const loginGoogle = () => (dispatch) => {
  dispatch({ type: TYPES.SET_AUTH_LOGIN_GOOGLE, payload: { loadingGoogle: true } });
};

export const loginGoogleSuccess = (resultGoogle) => async (dispatch) => {
  console.log('login google success');
  const result = Promise.resolve();
  // let result;
  // let isPasswordCreated;
  // try {
  //   const googleDataPayload = {
  //     credential_type: 'GOOGLE',
  //     credential_metadata: JSON.stringify({
  //       token: resultGoogle?.Zb?.id_token,
  //     }),
  //     app: 'BDD_PUBLIC',
  //   };
  // 
  //   const {
  //     data: {
  //       token,
  //       is_password_created,
  //       is_company_category_created,
  //       is_company_name_created,
  //     },
  //   } = await apiService.post('auth/third_party', googleDataPayload);
  //   const isRegCompleted =
  //     is_password_created &&
  //     is_company_category_created &&
  //     is_company_name_created;
  //   isPasswordCreated = is_password_created;
  //   setAuthToken(token, !isRegCompleted);
  // 
  //   result = Promise.resolve(isRegCompleted);
  // } catch (error) {
  //   result = Promise.reject(error);
  //   notificationError('Google')(dispatch);
  // } finally {
  //   dispatch({ type: TYPES.SET_AUTH_LOGIN_GOOGLE, payload: { loadingGoogle: false, isPasswordCreated } });
  // }

  return result;
};

export const loginEmail = (formValue) => async (dispatch) => {
  setAuthToken(null);
  let result;
  dispatch({ type: TYPES.SET_AUTH_LOGIN_EMAIL, loading: true });
  try {
    const { status, data } = await BindingApiService.post(
      `user/signin`,
      { ...formValue, app: 'BDDAI_PRO' },
      { ...setBindingApiReqHeaders() },
    );
    if (Number(data?.code) === 200 || Number(status) === 200) {
      setAuthToken(data?.data);
      localStorage.setItem('VISITED_PLATFORM', JSON.stringify([]));
      dispatch({ type: TYPES.SET_USER_DATA_, payload: data?.data });
      result = Promise.resolve();
    } else {
      // notificationError('Email')(dispatch);
      result = Promise.reject(data);
    }
  } catch (error) {
    result = Promise.reject(error);
    // notificationError('Email')(dispatch);
  } finally {
    dispatch({ type: TYPES.SET_AUTH_LOGIN_EMAIL, loading: false });
  }

  return result;
};

export const registerEmail = (formValue) => async (dispatch) => {
  let result;
  dispatch({ type: TYPES.SET_AUTH_REGISTER, loading: true });
  try {
    const { status, data } = await BindingApiService.post(
      `user/register`,
      { ...formValue },
      { ...setBindingApiReqHeaders() },
    );
    if (status === 201) {
      result = Promise.resolve();
    } else {
      throw new Error('failed');
    }
  } catch (error) {
    const {
      response: {
        data: { error_code },
      },
    } = error;
    result = Promise.reject(error);
    let errorMessage;
    if (error_code === 'LAST_LOGIN_META') {
      errorMessage = {
        message: 'Email already in use',
        description: 'Please Log In using your Meta Account',
      };
    } else if (error_code === 'LAST_LOGIN_GOOGLE') {
      errorMessage = {
        message: 'Email already in use',
        description: 'Please Log In using your Google Account',
      };
    } else {
      errorMessage = {
        message: 'Error',
        description: `Failed to register using Email Account`,
      };
    }
    notification.error(errorMessage);
  } finally {
    dispatch({ type: TYPES.SET_AUTH_REGISTER, loading: false });
  }

  return result;
};

export const processRegistrationCredentials = (credential='') => async(dispatch) => {
  let result;
  try {
    const { status, data } = await BindingApiService.post(
      `user/auth`,
      { credential },
      // { ...setBindingApiReqHeaders() }
    );
    result = Promise.resolve(data);
  } catch (err) {
    result = Promise.reject(err);
  };
  return result;
};

export const resendRegistrationCredentials = (credential='') => async(dispatch) => {
  let result;
  try {
    const { status, data } = await BindingApiService.post(
      `invitation-code/extend-activation-code`,
      { credential },
      // { ...setBindingApiReqHeaders() }
    );
    result = Promise.resolve(data);
  } catch (err) {
    result = Promise.reject(err);
  };
  return result;
};

// const completeCompany = async (data) => {
//   let result;
//   try {
//     const { status: updateStatus } = await apiService.put('users', data);
//     if (updateStatus === 201) {
//       result = Promise.resolve();
//     } else {
//       throw new Error('failed');
//     }
//   } catch (error) {
//     result = Promise.reject(error);
//   }
// 
//   return result;
// };
// 
// export const completeRegister = (
//   { password, company_name, company_category },
//   isPasswordCreated,
// ) => async (dispatch) => {
//   let result;
//   dispatch({ type: TYPES.SET_AUTH_REGISTER, loading: true });
//   try {
//     if (!isPasswordCreated) {
//       const { status } = await apiService.post('users/password', {
//         password,
//       });
//       if (status === 200) {
//         result = completeCompany({
//           company_name,
//           company_category,
//         });
//       } else {
//         throw new Error('failed');
//       }
//     } else {
//       result = completeCompany({
//         company_name,
//         company_category,
//       });
//     }
//   } catch (error) {
//     result = Promise.reject(error);
//     notification.error({
//       message: 'Error',
//       description: `Failed to complete register`,
//     });
//   } finally {
//     dispatch({ type: TYPES.SET_AUTH_REGISTER, loading: false });
//   }
// 
//   return result;
// };

export const resetPassword = (formValue) => async (dispatch) => {
  let result;
  dispatch({ type: TYPES.SET_AUTH_RESET_PASSWORD, loading: true });
  try {
    const { status, data } = await BindingApiService.post(
      `user/resetting`,
      { ...formValue },
      { ...setBindingApiReqHeaders() },
    );
    if (status === 200) {
      result = Promise.resolve();
    } else {
      throw new Error('failed');
    }
  } catch (error) {
    const {
      response: {
        data: { error_code },
      },
    } = error;
    result = Promise.reject(error_code);
    notification.error({
      message: 'Error',
      description: `Failed to reset your password`,
    });
  } finally {
    dispatch({ type: TYPES.SET_AUTH_RESET_PASSWORD, loading: false });
  }

  return result;
};

export const registerUser = (formValue) => async(dispatch) => {  
  dispatch({ type: TYPES.SET_AUTH_REGISTER, loading: true });
  let result;
  try {
    const { status, data } = await BindingApiService.post(
      `user/register`,
      { ...formValue },
      { ...setBindingApiReqHeaders() },
    );
    if (Number(data?.code) >= 400 || Number(status) > 400) {
      result = Promise.reject(data)
    }else{
      result = data
    }
  }catch(error) {
    result = Promise.reject(error)
  } finally {
    dispatch({type: TYPES.SET_AUTH_REGISTER, loading: false});
  }
  return result;
}

export const logout = (props={}) => async(dispatch) => {
  const RESETS = [
    TYPES.RESET_DASHBOARD_GOOGLE_ADS_REDUCER,
    TYPES.RESET_DASHBOARD_GOOGLE_ANALYTICS_REDUCER,
    TYPES.RESET_DASHBOARD_INSTAGRAM_REDUCER,
    TYPES.RESET_DASHBOARD_MARKETPLACE_REDUCER,
    TYPES.RESET_DASHBOARD_META_REDUCER,
    TYPES.RESET_DASHBOARD_TIKTOK_REDUCER,
    TYPES.RESET_DASHBOARD_WEBSITE_REDUCER,
    TYPES.RESET_DASHBOARD_SUMMARY_REDUCER,
    TYPES.RESET_USER_REDUCER,
  ];
  RESETS.forEach(RESET => {
    dispatch({ type: RESET, payload: null })
  });
  destroyToken();
  localStorage.clear();
  persistor.flush().then(() => persistor.purge());
  props.history.push('/login');
};