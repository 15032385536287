import apiBinding from '../../../services/api-binding.service';
import { getToken } from '../../../services/token.service';
import TYPES from '../../types';

const { BindingApiService, setBindingApiReqHeaders } = apiBinding.getInstance();

export const generateMarketplaceAuthUrl = (params) => async (dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_MARKETPLACE,
    payload: { loadings: { bind: true } },
  });

  let endpoint;
  const redirectParam = params?.redirect !== null || params?.redirect !== undefined ? `&redirect=${params?.redirect}` : ''; 
  switch (params?.account_type) {
    default:
      endpoint = `/binding/shopeev2/get-auth-url?api_type=${process.env.ENV === 'PRODUCTION' ? 'production' : 'testing'}&env=${process.env.ENV}${redirectParam}`;
      break; // shopee
    case 'tokopedia': endpoint = ``; break;
  };

  let outcome;
  try {
    const { status, data } = endpoint ? await BindingApiService.get(
      endpoint,
      { ...setBindingApiReqHeaders(getToken()) },
    ) : { status: undefined, data: undefined };

    if (data) {
      dispatch({
        type: TYPES.SET_BINDING_BIND_MARKETPLACE,
        payload: { loadings: { bind: false } },
      });
      outcome = Promise.resolve(data.data.url);
      window.location.href = data.data.url;
    };
  } catch (err) {
    dispatch({
      type: TYPES.SET_BINDING_BIND_MARKETPLACE,
      payload: { loadings: { bind: false } },
    });
    outcome = Promise.reject(err);
  };
  return outcome;
};

// eslint-disable-next-line camelcase
export const bindMarketplace = (params) => async (dispatch) => {
  dispatch({
    type: TYPES.SET_BINDING_BIND_MARKETPLACE,
    payload: { loadings: { bind: true } },
  });

  let endpoint;
  switch (params.account_type) {
    default: endpoint = `/binding/shopeev2`; break; // shopee
    case 'tokopedia': endpoint = `/binding/tokopedia`; break;
  };

  let outcome;
  try {
    const { status, data } = await BindingApiService.post(
      endpoint,
      { code: params?.code || ``, shop_id: params?.shop_id || ``, api_type: process.env.ENV === 'PRODUCTION' ? 'production' : 'testing' },
      { ...setBindingApiReqHeaders(getToken()) },
    );
    if (data) {
      dispatch({
        type: TYPES.SET_BINDING_BIND_MARKETPLACE,
        payload: { loadings: { bind: false } },
      });
      outcome = Promise.resolve(data);
    };
  } catch (err) {
    dispatch({
      type: TYPES.SET_BINDING_BIND_MARKETPLACE,
      payload: { loadings: { bind: false } },
    });
    outcome = Promise.reject(err);
  };
  return outcome;
};
