import { connect } from 'react-redux';
import Workspace from './Workspace.component';

import {
  updateOpenedReportName,
  upsertCard,
  getDataForCard,
  deleteCard,
  getPublicReportId,
} from '../../../redux-v1/actions/dashboard/custom-report.actions';

const mapStateToProps = (state) => ({
  // accountsByPlatform: state.user.accounts_by_platform,
  ...state.dashboard.customReport,
  coachmark_local: state.user.coachmark_local,
  me: state.user.me,
  onBoarding: state.user.me.onBoarding,
});

const mapDispatchToProps = {
  updateOpenedReportName,
  upsertCard,
  getDataForCard,
  deleteCard,
  getPublicReportId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);