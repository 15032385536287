import globalStateConstants from '../../../constants-v1/global-state.constants';
import TYPES from '../../types';

const initialState = {
  visited: false,

  account: { ...globalStateConstants.account.tiktok },
  daterange: { ...globalStateConstants.daterange },
  filter: { ...globalStateConstants.filter.tiktok },
  latest_sync: 0,

  data: {
    conversion_tiktok: {
      overview_stats: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
      funnel_chart: { data: {}, error: null, loading: false },
      funnel_table: { data: {}, error: null, loading: false },
      table_campaign: { data: [], error: null, loading: false },
      table_objective: { data: [], error: null, loading: false },
      table_audience: { data: [], error: null, loading: false },
      preview_clicks: { data: [], error: null, loading: false },
      preview_purchase: { data: [], error: null, loading: false },
    },
    conversion_website: {
      overview_stats: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
      funnel_chart: { data: {}, error: null, loading: false },
      funnel_table: { data: {}, error: null, loading: false },
      table_campaign: { data: [], error: null, loading: false },
      table_objective: { data: [], error: null, loading: false },
      table_audience: { data: [], error: null, loading: false },
      preview_reach: { data: [], error: null, loading: false },
      preview_traffic: { data: [], error: null, loading: false },
      preview_conversion: { data: [], error: null, loading: false },
    },
    community_interaction: {
      overview_stats: { data: {}, error: null, loading: false },
      charts: { data: {}, error: null, loading: false },
      funnel_chart: { data: {}, error: null, loading: false },
      funnel_table: { data: {}, error: null, loading: false },
      table_campaign: { data: [], error: null, loading: false },
      table_audience: { data: [], error: null, loading: false },
      preview_followers: { data: [], error: null, loading: false },
      preview_profile_visits: { data: [], error: null, loading: false },
    },
  },
};

export default (state=initialState, action) => {
  switch (action.type) {
    default: return state;

    case TYPES.SET_DASHBOARD_TIKTOK_VISITED: return {
      ...state, visited: true,
    };

    case TYPES.SET_DASHBOARD_TIKTOK_ACCOUNT: return {
      ...state, account: { /* ...state.account, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_DATERANGE: return {
      ...state, daterange: { /* ...state.daterange, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_FILTER: return {
      ...state, filter: { /* ...state.filter, */ ...action.payload },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_LATEST_SYNC: return {
      ...state, latest_sync: action.payload || 0,
    };

    case TYPES.SET_DASHBOARD_TIKTOK_ACCOUNT_DETAILS_CURRENCY: return {
      ...state,
      account: {
        ...state.account,
        details: { ...state.account.details, currency: action.payload?.data?.current?.currency || state.account.details.currency },
      },
    };

      // CONVERSION TIKTOK GROUP

    case TYPES.SET_DASHBOARD_TIKTOK_CT_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          overview_stats: { /* ...state.data.conversion_tiktok.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CT_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          charts: { /* ...state.data.conversion_tiktok.charts, */ ...action.payload },
        },
      },
    };
    
    case TYPES.SET_DASHBOARD_TIKTOK_CT_FUNNEL_CHART: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          funnel_chart: { /* ...state.data.conversion_tiktok.funnel_chart, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CT_FUNNEL_TABLE: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          funnel_table: { /* ...state.data.conversion_tiktok.funnel_table, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_CAMPAIGN: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          table_campaign: { /* ...state.data.conversion_tiktok.table_campaign, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_OBJECTIVE: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          table_objective: { /* ...state.data.conversion_tiktok.table_objective, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CT_TABLE_AUDIENCE: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          table_audience: { /* ...state.data.conversion_tiktok.table_audience, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CT_PREVIEW_CLICKS: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          preview_clicks: { /* ...state.data.conversion_tiktok.preview_clicks, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CT_PREVIEW_PURCHASE: return {
      ...state,
      data: {
        ...state.data,
        conversion_tiktok: {
          ...state.data.conversion_tiktok,
          preview_purchase: { /* ...state.data.conversion_tiktok.preview_purchase, */ ...action.payload },
        },
      },
    };

      // CONVERSION WEBSITE GROUP

    case TYPES.SET_DASHBOARD_TIKTOK_CW_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          overview_stats: { /* ...state.data.conversion_website.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          charts: { /* ...state.data.conversion_website.charts, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_FUNNEL_CHART: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          funnel_chart: { /* ...state.data.conversion_website.funnel_chart, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_FUNNEL_TABLE: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          funnel_table: { /* ...state.data.conversion_website.funnel_table, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_TABLE_CAMPAIGN: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          table_campaign: { /* ...state.data.conversion_website.table_campaign, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_TABLE_OBJECTIVE: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          table_objective: { /* ...state.data.conversion_website.table_objective, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_TABLE_AUDIENCE: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          table_audience: { /* ...state.data.conversion_website.table_audience, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_PREVIEW_REACH: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          preview_reach: { /* ...state.data.conversion_tiktok.preview_reach, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_PREVIEW_TRAFFIC: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          preview_traffic: { /* ...state.data.conversion_tiktok.preview_traffic, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CW_PREVIEW_CONVERSION: return {
      ...state,
      data: {
        ...state.data,
        conversion_website: {
          ...state.data.conversion_website,
          preview_conversion: { /* ...state.data.conversion_tiktok.preview_conversion, */ ...action.payload },
        },
      },
    };

      // COMMUNITY INTERACTION GROUP

    case TYPES.SET_DASHBOARD_TIKTOK_CI_OVERVIEW_STATS: return {
      ...state,
      data: {
        ...state.data,
        community_interaction: {
          ...state.data.community_interaction,
          overview_stats: { /* ...state.data.community_interaction.overview_stats, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CI_CHARTS: return {
      ...state,
      data: {
        ...state.data,
        community_interaction: {
          ...state.data.community_interaction,
          charts: { /* ...state.data.community_interaction.charts, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CI_FUNNEL_CHART: return {
      ...state,
      data: {
        ...state.data,
        community_interaction: {
          ...state.data.community_interaction,
          funnel_chart: { /* ...state.data.community_interaction.funnel_chart, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CI_FUNNEL_TABLE: return {
      ...state,
      data: {
        ...state.data,
        community_interaction: {
          ...state.data.community_interaction,
          funnel_table: { /* ...state.data.community_interaction.funnel_table, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CI_TABLE_CAMPAIGN: return {
      ...state,
      data: {
        ...state.data,
        community_interaction: {
          ...state.data.community_interaction,
          table_campaign: { /* ...state.data.community_interaction.table_campaign, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CI_TABLE_AUDIENCE: return {
      ...state,
      data: {
        ...state.data,
        community_interaction: {
          ...state.data.community_interaction,
          table_audience: { /* ...state.data.community_interaction.table_audience, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CI_PREVIEW_FOLLOWERS: return {
      ...state,
      data: {
        ...state.data,
        community_interaction: {
          ...state.data.community_interaction,
          preview_followers: { /* ...state.data.conversion_tiktok.preview_followers, */ ...action.payload },
        },
      },
    };

    case TYPES.SET_DASHBOARD_TIKTOK_CI_PREVIEW_PROFILE_VISITS: return {
      ...state,
      data: {
        ...state.data,
        community_interaction: {
          ...state.data.community_interaction,
          preview_profile_visits: { /* ...state.data.conversion_tiktok.preview_profile_visits, */ ...action.payload },
        },
      },
    };

      // RESET DATA REDUCER

    case TYPES.RESET_DASHBOARD_TIKTOK_DATA: return {
      ...state, data: initialState.data,
    };

    case TYPES.RESET_DASHBOARD_TIKTOK_REDUCER: return {
      ...initialState,
    };
  };
};