import React from 'react';
import {
  Image,
  Input,
  Modal,
  Popover,
  Spin,
  Typography,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import {
  AVATAR_GOOGLE_ADS,
  AVATAR_META,
  AVATAR_TIKTOK,
  ICON_ELLIPSIS,
  ICON_CR_DELETE_CARD,
  ICON_CR_EDIT_CARD,
} from '../../../assets-v1/figma-icons';
import { formatDateDisplay } from '../../../utils-v1/daterange.utils';
import { getNumberFormatterByFormatCode } from '../../../utils-v1/number.utils';

import Chart from '../../../components/Chart/Chart.component';
import Stats from '../../../components/Stats/Stats.component';
import Table from '../../../components/Table/Table.component';

import dateConstants from '../../../constants-v1/date.constants';

import config from './CardContent.component.config';
import './CardContent.component.styles.less';

const { Paragraph, Text, Title } = Typography;

const CardContent = ({
  cardConfig = {
    loading: false,
    card_id: '',
    data: null,
    description: '',
    position: {},
    title: '',
    visualization_id: 0,
    visualization_name: '',
  },
  adjustLayoutFunc = () => console.log('useEffect adjustLayout ran'),
  upsertDataFunc = () => console.log('useEffect upsertData ran'),
  onEdit = (cardID='') => console.log(`edit card ${cardID}`),
  onDelete = (cardID='') => console.log(`delete card ${cardID}`),
  onDuplicate = (cardID='') => console.log(`duplicate card ${cardID}`),
  onFocusDescription = () => null,
  onBlurDescription = () => null,
  availableMetrics = {},
  selectedAccounts = [],
  ...props
}) => {
  const [state, setState] = React.useState({ statsCols: 8 });
  React.useEffect(() => {
    adjustLayoutFunc();
    const newStatsCols = (cardConfig?.position?.w > 12 && 8) || (cardConfig?.position?.w > 8 && 6) || 4;
    setState({ ...state, statsCols: newStatsCols });
  }, [
    cardConfig?.visualization_id,
    cardConfig?.position?.h,
    cardConfig?.position?.w,
  ]);
  React.useEffect(() => {
    upsertDataFunc();
  }, [
    cardConfig?.visualization_id,
    cardConfig?.accounts,
    cardConfig?.filter_by,
    cardConfig?.filter_exclude,
    cardConfig?.filter_value,
    cardConfig?.date_end_compare,
    cardConfig?.date_end_current,
    cardConfig?.date_start_compare,
    cardConfig?.date_start_current,
    cardConfig?.use_compare,
    cardConfig?.time_frame,
  ]);

  const constructDaterange = () => ({
    date_start_current: cardConfig?.date_start_current,
    date_end_current: cardConfig?.date_end_current,
    date_start_compare: cardConfig?.date_start_compare,
    date_end_compare: cardConfig?.date_end_compare,
    use_compare: cardConfig?.use_compare,
  });
  const getAvatarByPlatform = (platform='meta', width=14) => {
    let src;
    switch(platform) {
      default: src = AVATAR_META; break;
      case 'googleAds': src = AVATAR_GOOGLE_ADS; break;
      case 'tiktok': src = AVATAR_TIKTOK; break;
    };
    return `<img src="${src}" alt="platform_avatar" width="${width}px" style="transform:translateY(-1px)" />`;
  };
  const formatCardData = (visID=null) => {
    const { accounts, data, date_start_compare: dateStartCompare } = cardConfig;
    let output = [];
    if (visID === 1 || visID === 2) {
      output = [];
      if (cardConfig?.blend) {
        const flattenedMetricList = [...availableMetrics?.googleAds, ...availableMetrics?.meta, ...availableMetrics?.tiktok];
        const metricKeys = Object.keys(data?.blended_accounts?.current);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const metric = flattenedMetricList.find(m => m?.key === metricKey || m?.value === metricKey);
          output.push({
            entries: data?.blended_accounts?.current?.[metricKey],
            label: `<b>${metric?.label}</b>${dateStartCompare ? ' (current)' : ''}`,
          });
          if (dateStartCompare) output.push({
            entries: data?.blended_accounts?.previous?.[metric?.value],
            label: `<b>${metric?.label}</b> (compare)`,
          });
        }
      } else {
        for (let i=0; i<accounts.length; i+=1) {
          const account = accounts[i];
          const { account_name: accountName, details: { currency }, platform } = selectedAccounts?.find(act => act?.account_id === account?.account_id);
          const { metrics } = account;
          for (let j=0; j<metrics.length; j+=1) {
            const metric = metrics[j];
            output.push({
              entries: data?.[account?.account_id]?.current?.[metric?.value],
              label: accounts.length > 1 ?
                `<b>${getAvatarByPlatform(platform)} ${accountName}</b>${dateStartCompare ? ' (current)' : ''}` :
                `<b>${metric?.label}</b> </i>${dateStartCompare ? ' (current)' : ''}`,
            });
            if (dateStartCompare) output.push({
              entries: data?.[account?.account_id]?.previous?.[metric?.value],
              label: accounts.length > 1 ?
                `<b>${getAvatarByPlatform(platform)} ${accountName}</b> (compare)` :
                `<b>${metric?.label}</b> (compare)`,
            });
          };
        };
      }
    };
    if (visID === 3) {
      output = [];
      if (cardConfig?.blend) {
        let flattenedBarMetrics = []; let flattenedLineMetrics = [];
        accounts.forEach(act => {
          flattenedBarMetrics = [...flattenedBarMetrics, ...act?.metrics?.bar];
          flattenedLineMetrics = [...flattenedLineMetrics, ...act?.metrics?.line];
        });
        const flattenedMetricList = [...availableMetrics?.googleAds, ...availableMetrics?.meta, ...availableMetrics?.tiktok];
        const metricKeys = Object.keys(data?.blended_accounts?.current);
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const metric = flattenedMetricList.find(m => m?.key === metricKey || m?.value === metricKey);
          output.push({
            type: flattenedBarMetrics?.find(m => m?.key === metric || m?.value === metric) ? 'bar' : 'line',
            entries: data?.blended_accounts?.current?.[metricKey],
            label: `<b>${metric?.label}</b>${dateStartCompare ? ' (current)' : ''}`,
          });
          if (dateStartCompare) output.push({
            type: flattenedBarMetrics?.find(m => m?.key === metric || m?.value === metric) ? 'bar' : 'line',
            entries: data?.blended_accounts?.previous?.[metric?.value],
            label: `<b>${metric?.label}</b> (compare)`,
          });
        }
      } else {
        for (let i=0; i<accounts.length; i+=1) {
          const account = accounts[i];
          const { account_name: accountName, details: { currency }, platform } = selectedAccounts?.find(act => act?.account_id === account?.account_id);
          const { metrics } = account;
          const types = ['bar', 'line'];
          for (let t=0; t<types.length; t+=1) {
            const type = types[t];
            for (let j=0; j<metrics[type].length; j+=1) {
              const metric = metrics[type][j];
              output.push({
                type,
                entries: data?.[account?.account_id]?.[type]?.current?.[metric?.value],
                label: accounts.length > 1 ?
                  `<b>${getAvatarByPlatform(platform)} ${accountName}</b>${dateStartCompare ? ' (current)' : ''}` :
                  `<b>${metric?.label}</b> </i>${dateStartCompare ? ' (current)' : ''}`,
              });
              if (dateStartCompare) output.push({
                type,
                entries: data?.[account?.account_id]?.[type]?.previous?.[metric?.value],
                label: accounts.length > 1 ?
                  `<b>${getAvatarByPlatform(platform)} ${accountName}</b> (compare)` :
                  `<b>${metric?.label}</b> (compare)`,
              });
            };
          };
        };
      }
    };
    if (visID === 4) {
      const donut = []; const table = { current: {} }; const total = { current: 0, previous: 0 };
      for (let i=0; i<accounts.length; i+=1) {
        const account = accounts[i];
        const { account_name: accountName, details: { currency }, platform } = selectedAccounts?.find(act => act?.account_id === account?.account_id);
        const { metrics } = account;
        for (let j=0; j<metrics.length; j+=1) {
          const metric = metrics[j];
          donut.push({
            label: accounts.length === 1 ? metric?.label : accountName,
            value: data?.[account?.account_id]?.current?.[metric?.value],
          })
          table.current[accounts.length === 1 ? metric?.label : accountName] = data?.[account?.account_id]?.current?.[metric?.value];
          total.current += data?.[account?.account_id]?.current?.[metric?.value];
        };
      };
      output = { donut, table, total };
    };
    if (visID === 5) {
      output = [];
      if (cardConfig?.blend) {
        const flattenedMetricList = [...availableMetrics?.googleAds, ...availableMetrics?.meta, ...availableMetrics?.tiktok];
        const metricKeys = Object.keys(data?.blended_accounts?.current || {});
        for (let i=0; i<metricKeys.length; i+=1) {
          const metricKey = metricKeys[i];
          const metric = flattenedMetricList.find(m => m?.key === metricKey || m?.value === metricKey);
          output.push({
            adverse: metric?.adv,
            oneLiner: false,
            title: <><span style={{ fontSize: 14 }}>{(metric?.label || '').toUpperCase()}</span></>,
            values: {
              current: data?.blended_accounts?.current?.[metricKey],
              previous: data?.blended_accounts?.previous?.[metricKey],
              percentage: data?.blended_accounts?.percentage?.[metricKey],
            },
            valueFormatter: getNumberFormatterByFormatCode(metric?.fmt, 'IDR', metric?.decimal),
          });
        };
      } else {
        for (let i=0; i<accounts.length; i+=1) {
          const account = accounts[i];
          const { account_name: accountName, details: { currency } } = selectedAccounts?.find(act => act?.account_id === account?.account_id);
          const { metrics } = account;
          for (let j=0; j<metrics.length; j+=1) {
            const metric = metrics[j];
            output.push({
              adverse: metric?.adv,
              oneLiner: false,
              title: <><span style={{ fontSize: 14 }}>
                {accounts.length > 1 ? accountName : (metric?.label || '')?.toUpperCase()}
              </span></>,
              values: {
                current: data?.[account?.account_id]?.current?.[metric?.value],
                previous: data?.[account?.account_id]?.previous?.[metric?.value] || null,
                percentage: data?.[account?.account_id]?.percentage?.[metric?.value] || null,
              },
              valueFormatter: getNumberFormatterByFormatCode(metric?.fmt, currency, metric?.decimal),
            });
          }
        };
      }
    };
    if (visID === 7) {
      const cWidth = 100;
      output = {
        columns: [{ fixed: 'left', key: 'index', title: 'Index', width: cWidth }],
        data: [],
        pagination: { pageSize: 14, position: ['topCenter'] },
        resizable: true,
      };
      const months = dateConstants.MONTHS.SHORT;
      for (let i=0; i<accounts.length; i+=1) {
        const account = accounts[i];
        const { account_name: accountName, details: { currency } } = selectedAccounts?.find(act => act?.account_id === account?.account_id);
        const { metrics } = account;
        for (let j=0; j<metrics.length; j+=1) {
          const metric = metrics[j];
          output.columns.push({
            dataIndex: accounts.length > 1 ? account?.account_id : metric?.value,
            key: accounts.length > 1 ? account?.account_id : metric?.value,
            title: accounts.length > 1 ? accountName : metric?.label,
            width: cWidth,
          });
        };
      };
      if (data && Array.isArray(Object.values(data || {})?.[0])) {
        output.data = new Array(Math.max(...Object.values(data || {}).map(datum => datum.length))).fill({});
        Object.entries(data).forEach(entry => {
          const [actID, vals] = entry;
          vals.forEach((val, valI) => {
            const { index, ...rest } = val;
            const { metrics, details: { currency } } = accounts?.[0];
            let datum = { key: `${valI}`, index: index.split('-').length === 2 && months[valI] || (index.split('-'.length === 3 && formatDateDisplay(index) || index)) };
            if (accounts.length > 1) {
              const [met, num] = Object.entries(rest)[0];
              datum = { ...datum, [actID]: getNumberFormatterByFormatCode(metrics?.[0]?.fmt, currency, metrics?.[0]?.decimal)(num) };
            } else {
              const formattedRest = {};
              Object.entries(rest || {}).forEach(metricItem => {
                const [met, num] = metricItem;
                const matchMetric = metrics?.filter(el => el?.value === met)?.[0];
                formattedRest[met] = getNumberFormatterByFormatCode(matchMetric?.fmt, currency, matchMetric?.decimal)(num);
              });
              datum = { ...datum, ...formattedRest };
            };
            if (datum?.["Grand Total"]) delete datum["Grand Total"]
            output.data[valI] = { ...output.data[valI], ...datum };
          })
        });
        output.pagination.pageSize = output.data.length === 13 && 12 || (output.data.length > 13 && 7 || output.data.length);
        output.summary = cardConfig?.show_total ? { index: 'Total', ...output.data[output.data.length-1] } : false;
        delete output.summary.key; delete output.summary.total; if (output?.summary?.["Grand Total"]) delete output.summary["Grand Total"];
        output.data = cardConfig?.show_total ? output.data.filter((datum, idx) => idx !== output.data.length-1) : output.data;
      };
      // console.log('visID 7', output)
    };
    // console.log(`visID: ${visID}`, output)
    return output;
  };

  const constructHeaderEllipsisPopoverProps = () => ({
    children: <ICON_ELLIPSIS className="card-header-ellipsis-icon" />,
    content: <ul>
      <li style={{ color: '#222222' }}>
        <div onClick={onEdit}>
          <ICON_CR_EDIT_CARD className="card-header-popover-menu-icon" />&nbsp;&nbsp;Edit Card
        </div>
      </li>
      <li style={{ color: '#018FFC' }}>
        <div onClick={onDuplicate}>
          <CopyOutlined style={{ transform: 'scale(1.2)', width: 24 }} />&nbsp;&nbsp;Duplicate Card
        </div>
      </li>
      <li style={{ color: '#F04438' }}>
        <div onClick={onDelete}>
          <ICON_CR_DELETE_CARD className="card-header-popover-menu-icon" />&nbsp;&nbsp;Delete Card
        </div>
      </li>
    </ul>,
    overlayClassName: 'card-header-ellipsis-popover',
    placement: 'bottomLeft',
    trigger: 'hover',
  });

  const renderComponent = () => {
    switch(cardConfig?.visualization_id) {
      default: return null;

      case 1: return(
        <Chart.Bar
          actions={{ copy: { enabled: false }, expand: { enabled: false } }}
          advancedConfig={{ showValuesInChart: true }}
          data={formatCardData(1)}
          daterange={constructDaterange()}
          hover={{ enabled: true }}
          legend={{ enabled: true }}
          size={300}
          title={<>{cardConfig?.title || ''}</>}
          tooltip={{ enabled: true }}
          xAxis={{ title: '', visible: true }}
          yAxis={{ ticked: true, title: '',  visible: true }}
        />
      );

      case 2: return(
        <Chart.Line
          actions={{ copy: { enabled: false }, expand: { enabled: false } }}
          advancedConfig={{ showValuesInChart: true }}
          data={formatCardData(2)}
          daterange={constructDaterange()}
          hover={{ enabled: true }}
          legend={{ enabled: true }}
          size={300}
          title={<>{cardConfig?.title || ''}</>}
          tooltip={{ enabled: true }}
          xAxis={{ title: '', visible: true }}
          yAxis={{ ticked: true, title: '',  visible: true }}
        />
      );

      case 3: return(
        <Chart.Combination
          actions={{ copy: { enabled: false }, expand: { enabled: false } }}
          advancedConfig={{ showValuesInChart: true }}
          data={formatCardData(3)}
          daterange={constructDaterange()}
          hover={{ enabled: true }}
          legend={{ enabled: true }}
          size={300}
          title={<>{cardConfig?.title || ''}</>}
          tooltip={{ enabled: true }}
          xAxis={{ title: '', visible: true }}
          yAxis={{ ticked: true, title: '',  visible: true }}
        />
      );

      case 4: return(
        <Chart.Donut
          actions={{ copy: { enabled: false }, expand: { enabled: false } }}
          advancedConfig={{ showValuesInChart: true, sliceAt: 6 }}
          data={formatCardData(4)}
          daterange={constructDaterange()}
          hover={{ enabled: true }}
          legend={{ enabled: true }}
          size={300}
          title={<>{cardConfig?.title || ''}</>}
          tooltip={{ enabled: true, shared: false }}
        />
      );

      case 5: return(
        <Stats.List
          actions={{ copy: { enabled: false }, expand: { enabled: false } }}
          collapse={{ enabled: false, sliceAt: 6 }}
          columns={state.statsCols}
          daterange={constructDaterange()}
          title={<>{cardConfig?.title || ''}</>}
          statsList={formatCardData(5)}
          useCompare={!!cardConfig?.date_start_compare}
        />
      );

      case 6: return(
        <div>
          <div style={{ fontWeight: 700, height: 30, padding: ' 5px 12px' }}>{cardConfig?.title || ''}</div>
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: cardConfig?.description || '' }}
            style={{ marginTop: 5, padding: '10px 12px', textAlign: 'justify' }}
          />
        </div>
      );

      case 7: return(
        <Table.Single
          actions={{ copy: { enabled: false }, expand: { enabled: false } }}
          // allowSort
          daterange={constructDaterange()}
          noFilter
          // pagination={{ pageSize: 14, position: ['topCenter'] }}
          title={<>{cardConfig?.title || ''}</>}
          { ...formatCardData(7) }
        />
      );
    };
  };

  const renderLoadingScreen = () => (
    <div hidden={!cardConfig?.loading} className="loading-container">
      <Spin size="large" />
      <Paragraph style={{ color: '#9CA0A6', fontSize: 14 }}>{cardConfig?.loading}</Paragraph>
    </div>
  );

  const renderDescriptionSection = () => (
    <Input.TextArea
      id={`Description-${cardConfig?.card_id}`}
      autoSize={{ minRows: 2, maxRows: 6 }}
      defaultValue={cardConfig?.description || ''}
      disabled={window.location.pathname.includes('pub')}
      onBlur={(e) => onBlurDescription(e.target.value)}
      onFocus={(e) => onFocusDescription(e.target.value)}
      placeholder={window.location.pathname.includes('pub') ? 'No description provided' : '+ Add short description here...'}
      style={{
        ...(cardConfig?.loading && { visibility: 'hidden '}),
        ...(window.location.pathname.includes('pub') && { border: 'none', backgroundColor: 'white' })
      }}
    />
  );

  return(
    <div id="CustomReportCardContent" /* onMouseEnter={() => console.log({ cardConfig })} */ >
      <Popover { ...constructHeaderEllipsisPopoverProps() } />
      <div id={`Content-${cardConfig?.card_id}`} className="card-main-content">
        {cardConfig?.loading ? renderLoadingScreen() : renderComponent()}
      </div>
      <div className="card-description-section" hidden={cardConfig?.visualization_id === 6}>
        {renderDescriptionSection()}
      </div>
    </div>
  );
};

CardContent.displayName = config.displayName;
CardContent.propTypes = config.propTypes;
CardContent.defaultProps = config.defaultProps;

export default CardContent;